import { IUserNotification } from "../../models/user";
import { Avatar, notification, Space } from "antd";
import moment from "moment";
import { generateAvatarColor, getInitials } from "../../helpers/functions";

export const openNotification = (notif: IUserNotification) => {
  // Options
  const args = {
    message: (
      <div>
        <Space direction="vertical" style={{ transform: "translateY(-6px)", width: "100%" }}>
          <div>
            <div style={{ fontSize: 13, opacity: 0.75, marginBottom: -12 }}>
              {moment(notif.notification.created_at).format()} {" - "}
              {notif.notification.sender?.name || "-"}
            </div>
          </div>
          <div style={{ fontSize: 18, fontWeight: 500, opacity: 0.85 }}>
            {notif.notification.header}
          </div>
        </Space>
      </div>
    ),
    description: (
      <div>
        <p style={{ opacity: 0.85, fontWeight: 500, color: "#434343" }}>
          {notif.notification.text}
        </p>
      </div>
    ),
    icon: !notif.notification.sender ? (
      <Avatar />
    ) : (
      <Avatar style={{ backgroundColor: generateAvatarColor(notif.notification.sender.name) }}>
        {getInitials(notif.notification.sender.name)}
      </Avatar>
    ),
    duration: 4.5,
    rtl: false,
    style: {
      width: 480,
      paddingTop: 28,
    },
  };

  notification.config({
    placement: "bottomRight",
    bottom: 50,
    rtl: false,
    duration: 4.5,
  });

  notification.info(args);
};
