import { Col, Row } from "antd";
import React from "react";
import Materials from "./Materials";
import { IRentDetails } from "../../../models/clients";
import RentNotes from "./RentNotes";

interface IProps {
  rentDetails: IRentDetails;
}

const Spec: React.FC<IProps> = ({ rentDetails }) => {
  return (
    <div id="asset-details-spec" style={{ paddingTop: 8 }}>
      <Row gutter={[24, 12]}>
        {/* Materials */}
        <Col md={24} xl={12}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <Materials rentDetails={rentDetails} />
          </div>
        </Col>

        {/* Sub assets */}
        <Col md={24} xl={12}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <RentNotes rentDetails={rentDetails} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Spec;
