import React, { useState, useEffect, useRef } from "react";
import { t } from "i18next";

// Antd
import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Typography, List, Modal, Input, message, Divider, Form } from "antd";

// Rest
import { ICON_SIZE, TRANSLATION_KEY } from "../../../../../helpers/consts";
import { ISupplierCategory } from "../../../../../models/supplier";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { getSupplierCategoriesXHR } from "../../../../../store/reducers/supplier/actionCreator";
import { unstable_batchedUpdates } from "react-dom";
import Card from "./SupplierCategoryCard";
import { IApiResponse } from "../../../../../models";
import { supplierSlice } from "../../../../../store/reducers/supplier";
import api, { failedQueue, isRefreshing } from "../../../../../services";
import { RootState } from "../../../../../store";

const SupplierCategory: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();

  const inputRef = useRef<Input>(null);

  // Variables
  const [loading, set_loading] = useState<boolean>(false);
  const [categoryName, set_categoryName] = useState<string>("");
  const [category, set_category] = useState<ISupplierCategory>();

  // Methods
  useEffect(() => {
    getSupplierCategoriesXHR({}, dispatch);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus({ cursor: "end" });
    }, 300);
  }, [category]);

  const { getSupplierCategoriesStatus, supplierCategories } = useAppSelector(
    (state: RootState) => state.supplierReducer,
  );

  const addCategory = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.post<IApiResponse<ISupplierCategory>>(
        `/suppliers/categories/`,
        { name: categoryName },
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        const arr: ISupplierCategory[] = [...supplierCategories];
        arr.push(response.data.results);

        dispatch(
          supplierSlice.actions.getSupplierCategoriesSuccess({
            results: arr,
            message: "",
          }),
        );
        set_categoryName("");
        set_category(undefined);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => addCategory());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onActionUpdate = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.put<IApiResponse<ISupplierCategory>>(
        `/suppliers/categories/${category?.id}/`,
        { name: categoryName },
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        const arr: ISupplierCategory[] = [...supplierCategories];
        const index = arr.findIndex((item) => item.id === response.data.results?.id);
        arr.splice(index, 1, response.data.results);

        dispatch(
          supplierSlice.actions.getSupplierCategoriesSuccess({
            results: arr,
            message: "",
          }),
        );
        set_category(undefined);
        set_categoryName("");
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onActionUpdate());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onDelete = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/suppliers/categories/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr: ISupplierCategory[] = [...supplierCategories];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      dispatch(
        supplierSlice.actions.getSupplierCategoriesSuccess({
          results: arr,
          message: "",
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  return (
    <div>
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.supplierCategories)}
        </Typography.Title>
        <Button
          onClick={() => {
            set_category({ name: "", id: 0 });
          }}
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
        />
      </div>

      <Divider style={{ marginTop: 10 }} />

      <List
        dataSource={supplierCategories}
        loading={loading || getSupplierCategoriesStatus === "loading"}
        renderItem={(x: ISupplierCategory) => (
          <Card
            item={x}
            onDelete={onDelete}
            onUpdate={() =>
              unstable_batchedUpdates(() => {
                set_category(x);
                set_categoryName(x.name);
              }, [])
            }
          />
        )}
      />

      <Modal
        destroyOnClose={true}
        title={
          category?.id === 0
            ? t(TRANSLATION_KEY.newSupplierCategory)
            : t(TRANSLATION_KEY.editSupplierCategory)
        }
        okButtonProps={{ disabled: !categoryName }}
        okText={t(TRANSLATION_KEY.save)}
        cancelText={t(TRANSLATION_KEY.cancel)}
        visible={!!category}
        onCancel={() => {
          set_category(undefined);

          set_categoryName("");
        }}
        footer={null}
      >
        <Form
          onFinish={() => {
            if (category?.id === 0) {
              addCategory();
            } else {
              onActionUpdate();
            }
          }}
        >
          <Input.Group size="large">
            <Input
              ref={inputRef}
              value={categoryName}
              onChange={({ target: { value } }) => {
                set_categoryName(value);
              }}
            />
            <Button style={{ marginTop: 24 }} type="primary" htmlType="submit">
              {t(TRANSLATION_KEY.save)}
            </Button>
          </Input.Group>
        </Form>
      </Modal>
    </div>
  );
};

export default SupplierCategory;
