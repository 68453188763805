import React, { ChangeEvent, useEffect, useState } from "react";

// UI
import { Input, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import {
  IMaintenanceCategory,
  IMaintenanceType,
  MaintenanceStatusTypes,
} from "../../../models/maintenances";
import { ILocation } from "../../../models";
import { ISupplier } from "../../../models/supplier";
import { IAccount } from "../../../models/user";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { t } from "i18next";
import { getSuppliersXHR } from "../../../store/reducers/supplier/actionCreator";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { accountsXHR } from "../../../store/reducers/accounts/actionCreators";
import { getMaintenancesCategoriesXHR } from "../../../store/reducers/maintenance/actionCreator";

// Rest

let POSSIBLE_STATUSES: MaintenanceStatusTypes[] = ["assigned"];

export interface IFilters {
  types: number[];
  locations: number[];
  categories: number[];
  statuses: MaintenanceStatusTypes[];
  suppliers: number[];
  accounts: number[];
  search: string;
}

interface IProps {
  //   onChange: (val: IFilters) => void;
  getData: (f: IFilters | undefined, setFilters: (f: IFilters) => void) => void;
}

const Filters: React.FC<IProps> = ({ getData }) => {
  // Variables

  const [filters, set_filters] = useState<IFilters>({
    types: [],
    locations: [],
    categories: [],
    statuses: [],
    suppliers: [],
    accounts: [],
    search: "",
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    getData(undefined, (filters) => {
      set_filters(filters);
    });
  }, []);

  const { suppliers } = useAppSelector((state) => state.supplierReducer);
  const { accounts } = useAppSelector((state) => state.accountsReducer);
  const { maintenanceCategories, maintenancesTypes } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const { locations } = useAppSelector((state) => state.settingsReducer);

  useEffect(() => {
    getMaintenancesCategoriesXHR(
      {
        successCallback: (res) => {},
        errorCallback: (error) => {
          console.log(error);
        },
      },
      dispatch,
    );
    accountsXHR(
      {
        queryParams: {
          limit: 9999,
          order_by: "id",
        },
        successCallback: (res) => {},
        errorCallback: (error) => {
          console.log(error);
        },
      },
      dispatch,
    );
    getSuppliersXHR(
      {
        successCallback: (res) => {},
        errorCallback: (error) => {
          console.log(error);
        },
      },
      dispatch,
    );
  }, []);

  // Methods

  // Content
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 12,
        marginTop: 12,
      }}
    >
      <Select
        onChange={(e: number[]) => getData({ ...filters, types: e }, set_filters)}
        placeholder={t(TRANSLATION_KEY.selectType)}
        value={filters.types}
        style={{ overflow: "auto", width: "calc(16% - 6px)", marginRight: 12 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {maintenancesTypes.map((item: IMaintenanceType, index: number) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e: number[]) => getData({ ...filters, categories: e }, set_filters)}
        placeholder={t(TRANSLATION_KEY.selectCategory)}
        value={filters.categories}
        style={{ overflow: "auto", width: "calc(16% - 6px)", marginRight: 12 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {maintenanceCategories.map((item: IMaintenanceCategory, index: number) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e: number[]) => getData({ ...filters, locations: e }, set_filters)}
        placeholder={t(TRANSLATION_KEY.selectLocation)}
        value={filters.locations}
        style={{ overflow: "auto", width: "calc(16% - 6px)", marginRight: 12, height: 34 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {locations.map((item: ILocation, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>

      <Select
        style={{ overflow: "auto", width: "calc(16% - 6px)", marginRight: 12, height: 34 }}
        allowClear
        value={filters.accounts}
        placeholder={t(TRANSLATION_KEY.selectUser)}
        mode="multiple"
        showSearch
        optionFilterProp="name"
        maxTagCount="responsive"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e: number[]) => getData({ ...filters, accounts: e }, set_filters)}
      >
        {accounts.map((item: IAccount, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        style={{ overflow: "auto", width: "calc(16% - 6px)", marginRight: 12, height: 34 }}
        allowClear
        value={filters.suppliers}
        placeholder={t(TRANSLATION_KEY.selectSupplier)}
        mode="multiple"
        showSearch
        optionFilterProp="name"
        maxTagCount="responsive"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e: number[]) => getData({ ...filters, suppliers: e }, set_filters)}
      >
        {suppliers.map((item: ISupplier, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>

      <Select
        style={{ overflow: "auto", width: "calc(16% - 6px)", marginRight: 12, height: 34 }}
        allowClear
        value={filters.statuses}
        placeholder={t(TRANSLATION_KEY.selectStatus)}
        mode="multiple"
        showSearch
        optionFilterProp="name"
        maxTagCount="responsive"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => getData({ ...filters, statuses: e }, set_filters)}
      >
        {POSSIBLE_STATUSES.map((item: MaintenanceStatusTypes, index: number) => (
          <Select.Option key={index} value={item}>
            {t(item)}
          </Select.Option>
        ))}
      </Select>
      <Input.Search
        style={{
          maxWidth: 180,
        }}
        value={filters.search}
        allowClear
        onChange={(v) => {
          getData({ ...filters, search: v.target.value?.toLocaleLowerCase() || "" }, set_filters);
        }}
      />
    </div>
  );
};

export default Filters;
