import { Button, Form, Input, message, TreeSelect } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { createTreeSelect, setItemsWithoutParentToHighestNode } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IRequestPanel } from "../models/settings";
import { getAssetXHR } from "../store/reducers/asstes/actionCreators";
import {
  createRequestPanelXHR,
  updateRequestPanelXHR,
} from "../store/reducers/settings/actionCreator";

interface IProps {
  onClose: () => void;
  requestPanel: IRequestPanel;
}

export interface IRequestPanelFormValues {
  name: string;
  assets: number[];
}

const WorkRequestPanel: React.FC<IProps> = ({ onClose, requestPanel }) => {
  const [form] = Form.useForm();

  const { assetList } = useAppSelector((state) => state.assetReducer);
  const { createRequestPanelStatus, updateRequestPanelStatus } = useAppSelector(
    (state) => state.settingsReducer,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAssetXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  const onFinish = async (values: IRequestPanelFormValues) => {
    if (requestPanel.id === 0) {
      createRequestPanelXHR(
        {
          body: { ...values, assets: values.assets.map((x) => +x) },
          successCallback: () => {
            onClose();
          },
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        },
        dispatch,
      );
    } else {
      updateRequestPanelXHR(
        {
          id: requestPanel.id,
          body: { ...values, assets: values.assets.map((x) => +x) },
          successCallback: () => {
            onClose();
          },
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        },
        dispatch,
      );
    }
  };

  const tree = createTreeSelect(
    setItemsWithoutParentToHighestNode(assetList).map((x) => ({
      title: x.path,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );
  return (
    <>
      <Form
        initialValues={{ ...requestPanel, assets: requestPanel.assets.map((x) => x.id.toString()) }}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: t(TRANSLATION_KEY.filedRequired),
            },
          ]}
          name={"name"}
          label={t(TRANSLATION_KEY.name)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t(TRANSLATION_KEY.filedRequired),
            },
          ]}
          name="assets"
          label={t(TRANSLATION_KEY.asset)}
        >
          <TreeSelect
            treeNodeFilterProp="title"
            showSearch
            multiple
            filterTreeNode={(search, item: any) => {
              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
            treeData={tree}
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={
              createRequestPanelStatus === "loading" || updateRequestPanelStatus === "loading"
            }
            type="primary"
            htmlType="submit"
          >
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default WorkRequestPanel;
