import { LeftOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tabs } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AppRoutes } from "../../navigation/routes";
import { getPartnerDetailsXHR } from "../../store/reducers/partner/actionCreators";
import { colors } from "../../styles/colors";

const { TabPane } = Tabs;

const Partner: React.FC = () => {
  const [removeLoading, set_removeLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { getPartnerDetailsStatus, partner } = useAppSelector((state) => state.partnerReducer);

  useEffect(() => {
    getPartnerDetailsXHR({ id }, dispatch);
  }, []);

  const removePartner = () => {};

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  function onBackClick(): void {
    navigate("/app/asset");
  }

  return (
    <>
      <div>
        <div className="spaceBetweenRow" style={{ padding: 15, paddingLeft: 0 }}>
          <div className="spaceBetweenRow">
            <Button
              onClick={onBackClick}
              shape="circle"
              type="text"
              icon={<LeftOutlined />}
              size="middle"
              style={{ marginRight: 12 }}
            />
            <div style={{ fontSize: 20, fontWeight: 500 }}>{partner.name}</div>
          </div>
          <div className="spaceBetweenRow">
            <Popconfirm
              onConfirm={removePartner}
              title={t(TRANSLATION_KEY.continueWithAction)}
              cancelText={t(TRANSLATION_KEY.no)}
              okText={t(TRANSLATION_KEY.yes)}
            >
              <Button type="primary" danger loading={removeLoading}>
                {t(TRANSLATION_KEY.delete)}
              </Button>
            </Popconfirm>
          </div>
        </div>

        <Tabs activeKey={location.pathname.split("/")[4]} onChange={onTabChange}>
          {AppRoutes("allroutes")
            .children?.find((x) => x.path === "partners/:id")
            ?.children?.map((x) => (
              <TabPane
                tab={
                  <Link style={{ color: colors.globalFontColor }} to={x.path}>
                    {t(x.path || "spec")}
                  </Link>
                }
                key={x.path}
              />
            ))}
        </Tabs>
      </div>

      <Spin spinning={getPartnerDetailsStatus === "loading"}>
        <Outlet />
      </Spin>
    </>
  );
};

export default Partner;
