import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./reducers/user";
import accountsReducer from "./reducers/accounts";
import assetReducer from "./reducers/asstes";
import settingsReducer from "./reducers/settings";
import warehouseReducer from "./reducers/warehouse";
import partnerReducer from "./reducers/partner";
import maintenanceReducer from "./reducers/maintenance";
import supplierReducer from "./reducers/supplier";
import dashboardReducer from "./reducers/dashboard";
import clientsReducer from "./reducers/clients";

export let store = configureStore({
  reducer: {
    userReducer,
    accountsReducer,
    assetReducer,
    settingsReducer,
    warehouseReducer,
    partnerReducer,
    maintenanceReducer,
    supplierReducer,
    dashboardReducer,
    clientsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
