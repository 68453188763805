import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../models";
import { BaseListItem, IAsset } from "../../../models/asset";
import { IClient, IClientDetails, IRent, IRentDetails } from "../../../models/clients";

interface IClientsReducer {
  updateRentOrderStatus: string;
  updateRentNoteStatus: string;
  createRentNoteStatus: string;
  rentDetails: IRentDetails;
  getRentOrderDetailsStatus: string;
  updateRentOrderDescriptionStatus: string;
  getAssetRentOrdersStatus: string;
  updateClientStatus: string;
  assetToRent: IAsset[];
  getAssetToRentStatus: string;
  closeRentOrderStatus: string;
  getRentedAssetsStatus: string;
  rents: IRent[];
  rentAssetStatus: string;
  client: IClientDetails;
  createClientStatus: string;
  getClientsStatus: string;
  clients: IClient[];
  getClientStatus: string;
  clientAsset: BaseListItem[];
  getClientAssetStatus: string;
  availableClientAsset: BaseListItem[];
  getAvailableClientAssetStatus: string;
}

const initialState = {
  updateRentOrderStatus: "",
  updateRentNoteStatus: "",
  createRentNoteStatus: "",
  rentDetails: {
    materials: [],
    notes: [],
    custom_id: "",
    id: 0,
    asset: undefined,
    created_at: "",
    closed_at: null,
    created_by: null,
    closed_by: null,
    real_end: null,
    order_type: "rent",
    description: "",
    status: "active",
    location: {
      id: 0,
      name: "",
    },
    client: undefined,
    rent_order_custom_fields_v2: {},
  },
  getRentOrderDetailsStatus: "loading",
  updateRentOrderDescriptionStatus: "",
  getAssetRentOrdersStatus: "",
  updateClientStatus: "",
  assetToRent: [],
  getAssetToRentStatus: "",
  closeRentOrderStatus: "",
  getRentedAssetsStatus: "loading",
  rents: [],
  rentAssetStatus: "",
  getClientStatus: "",
  client: {
    custom_fields_v2: {},
    custom_id: "",
    email: "",
    phone_number: "",
    address: "",
    description: "",
    materials: [],
    name: "",
    id: 0,
    is_archived: false,
  },
  getClientsStatus: "loading",
  createClientStatus: "",
  clients: [],
  getClientAssetStatus: "",
  clientAsset: [],
  availableClientAsset: [],
  getAvailableClientAssetStatus: "",
} as IClientsReducer;

export const clientsSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },

    // -----

    getClients(state, action: PayloadAction<{ loading: string }>) {
      state.getClientsStatus = action.payload.loading;
    },
    getClientsSuccess(
      state,
      action: PayloadAction<
        IApiResponse<IClient[]> & { mergeData: boolean; noSaveToStore: boolean }
      >,
    ) {
      state.getClientsStatus = "";

      if (!action.payload.results || action.payload.noSaveToStore) {
        return;
      }
      if (action.payload.mergeData) {
        state.clients = [...state.clients, ...action.payload.results];
      } else {
        state.clients = action.payload.results;
      }
    },
    getClientsFail(state, action: PayloadAction<string>) {
      state.getClientsStatus = action.payload;
    },

    // -----

    createClient(state) {
      state.createClientStatus = "loading";
    },
    createClientSuccess(state, action: PayloadAction<IApiResponse<IClient>>) {
      if (!action.payload.results) return;
      state.clients.push(action.payload.results);
      state.createClientStatus = "";
    },
    createClientFail(state, action: PayloadAction<string>) {
      state.createClientStatus = action.payload;
    },

    // -----

    getClient(state) {
      state.getClientStatus = "loading";
    },
    getClientSuccess(state, action: PayloadAction<IApiResponse<IClientDetails>>) {
      if (!action.payload.results) return;
      state.client = action.payload.results;
      state.getClientStatus = "";
    },
    getClientFail(state, action: PayloadAction<string>) {
      state.getClientStatus = action.payload;
    },

    rentAsset(state) {
      state.rentAssetStatus = "loading";
    },
    rentAssetSuccess_v2(state, action: PayloadAction<IApiResponse<IRent>>) {
      if (!action.payload.results) return;
      let tmp = [...state.rents];
      tmp.unshift(action.payload.results);
      state.rents = tmp;
      state.rentAssetStatus = "";
    },
    rentAssetSuccess(state, action: PayloadAction<IApiResponse<BaseListItem[]>>) {
      if (!action.payload.results) return;
      state.clientAsset = action.payload.results;
      state.rentAssetStatus = "";
    },
    rentAssetFail(state, action: PayloadAction<string>) {
      state.rentAssetStatus = action.payload;
    },

    // -----

    getRentedAssets(state, action: PayloadAction<string>) {
      state.getRentedAssetsStatus = action.payload;
    },
    getRentedAssetsSuccess(
      state,
      action: PayloadAction<IApiResponse<IRent[]> & { mergeData: boolean; noSaveToStore: boolean }>,
    ) {
      state.getRentedAssetsStatus = "";
      if (!action.payload.results || action.payload.noSaveToStore) return;
      if (action.payload.mergeData) {
        state.rents = [...state.rents, ...action.payload.results];
      } else {
        state.rents = action.payload.results;
      }
    },
    getRentedAssetsFail(state, action: PayloadAction<string>) {
      state.getRentedAssetsStatus = action.payload;
    },

    // -----

    closeRentOrder(state) {
      state.closeRentOrderStatus = "loading";
    },
    closeRentOrderSuccess(state, action: PayloadAction<IApiResponse<IRentDetails>>) {
      if (!action.payload.results) return;
      let tmpIndex = state.rents.findIndex((rent) => rent.id === action.payload.results?.id);
      if (tmpIndex !== -1) state.rents[tmpIndex] = action.payload.results;
      state.rentDetails = action.payload.results;
      state.closeRentOrderStatus = "";
    },
    closeRentOrderFail(state, action: PayloadAction<string>) {
      state.closeRentOrderStatus = action.payload;
    },

    // -----

    getAssetToRentXHR(state) {
      state.getAssetToRentStatus = "loading";
    },
    getAssetToRentXHRSuccess(state, action: PayloadAction<IApiResponse<IAsset[]>>) {
      if (!action.payload.results) return;
      state.assetToRent = action.payload.results;
      state.getAssetToRentStatus = "";
    },
    getAssetToRentXHRFail(state, action: PayloadAction<string>) {
      state.getAssetToRentStatus = action.payload;
    },

    updateClient(state) {
      state.updateClientStatus = "loading";
    },
    updateClientSuccess(state, action: PayloadAction<IApiResponse<IClientDetails>>) {
      if (!action.payload.results) return;
      state.client = action.payload.results;
      state.updateClientStatus = "";
    },
    updateClientFail(state, action: PayloadAction<string>) {
      state.updateClientStatus = action.payload;
    },

    // -----

    getAssetRentOrders(state) {
      state.getAssetRentOrdersStatus = "loading";
    },
    getAssetRentOrdersSuccess(state, action: PayloadAction<IApiResponse<IRent[]>>) {
      if (!action.payload.results) return;
      state.rents = action.payload.results;
      state.getAssetRentOrdersStatus = "";
    },
    getAssetRentOrdersFail(state, action: PayloadAction<string>) {
      state.getAssetRentOrdersStatus = action.payload;
    },

    // -----

    getClientAsset(state) {
      state.getClientAssetStatus = "loading";
    },
    getClientAssetSuccess(state, action: PayloadAction<IApiResponse<BaseListItem[]>>) {
      if (!action.payload.results) return;
      state.clientAsset = action.payload.results;
      state.getClientAssetStatus = "";
    },
    getClientAssetFail(state, action: PayloadAction<string>) {
      state.getClientAssetStatus = action.payload;
    },

    // -----

    getAvailableClientAsset(state) {
      state.getAvailableClientAssetStatus = "loading";
    },
    getAvailableClientAssetSuccess(state, action: PayloadAction<IApiResponse<BaseListItem[]>>) {
      if (!action.payload.results) return;
      state.availableClientAsset = action.payload.results;
      state.getAvailableClientAssetStatus = "";
    },
    getAvailableClientAssetFail(state, action: PayloadAction<string>) {
      state.getAvailableClientAssetStatus = action.payload;
    },

    updateRentOrderDescription(state) {
      state.updateRentOrderDescriptionStatus = "loading";
    },
    updateRentOrderDescriptionSuccess(state, action: PayloadAction<IApiResponse<IRent>>) {
      if (!action.payload.results) return;
      let tmpIndex = state.rents.findIndex((rent) => rent.id === action.payload.results?.id);
      if (tmpIndex !== -1) state.rents[tmpIndex] = action.payload.results;
      state.updateRentOrderDescriptionStatus = "";
    },
    updateRentOrderDescriptionFail(state, action: PayloadAction<string>) {
      state.updateRentOrderDescriptionStatus = action.payload;
    },
    getRentOrderDetails(state) {
      state.getRentOrderDetailsStatus = "loading";
    },
    getRentOrderDetailsSuccess(state, action: PayloadAction<IApiResponse<IRentDetails>>) {
      if (!action.payload.results) return;
      state.rentDetails = action.payload.results;
      state.getRentOrderDetailsStatus = "";
    },
    getRentOrderDetailsFail(state, action: PayloadAction<string>) {
      state.getRentOrderDetailsStatus = action.payload;
    },

    createRentNote(state) {
      state.createRentNoteStatus = "loading";
    },
    createRentNoteSuccess(state, action: PayloadAction<IApiResponse<IRentDetails>>) {
      if (!action.payload.results) return;
      state.rentDetails = action.payload.results;
      state.createRentNoteStatus = "";
    },
    createRentNoteFail(state, action: PayloadAction<string>) {
      state.createRentNoteStatus = action.payload;
    },

    updateRentNote(state) {
      state.updateRentNoteStatus = "loading";
    },
    updateRentNoteSuccess(state, action: PayloadAction<IApiResponse<IRentDetails>>) {
      if (!action.payload.results) return;
      state.rentDetails = action.payload.results;
      state.updateRentNoteStatus = "";
    },
    updateRentNoteFail(state, action: PayloadAction<string>) {
      state.updateRentNoteStatus = action.payload;
    },

    updateRentOrder(state) {
      state.updateRentOrderStatus = "loading";
    },
    updateRentOrderSuccess(state, action: PayloadAction<IApiResponse<IRentDetails>>) {
      if (!action.payload.results) return;
      state.rentDetails = action.payload.results;
      state.updateRentOrderStatus = "";
    },
    updateRentOrderFail(state, action: PayloadAction<string>) {
      state.updateRentOrderStatus = action.payload;
    },
  },
});

export default clientsSlice.reducer;
