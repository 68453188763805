import React from "react";
import { IWorkRequest } from "../../../models/maintenances";
import { Avatar, Button, List, Popconfirm, Space, Tooltip, message } from "antd";
import moment from "moment";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { DeleteOutlined, PlusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import api, { failedQueue, isRefreshing } from "../../../services";
import { IAddMaintenance } from "./index";
import RequirePermission from "../../../components/RequirePermission";

interface IProps {
  workRequests: IWorkRequest;
  onClose: () => void;
  onGoAddMaintenance: (data: IAddMaintenance) => void;
  onDeleteWorkRequest: (workRequest: number | null, setLoading: (id: number) => void) => void;
}

const Card: React.FC<IProps> = ({
  workRequests,
  onClose,
  onGoAddMaintenance,
  onDeleteWorkRequest,
}) => {
  const [loading, setLoading] = React.useState<number>(-1);

  return (
    <List.Item
      style={{
        backgroundColor: workRequests.created_order ? "#ffffff00" : "#91d5ff20",
        marginBottom: 8,
        border: "none",
        padding: "16px",
        borderRadius: 4,
      }}
      extra={
        <Space>
          <Tooltip title={t(TRANSLATION_KEY.addMaintenance)}>
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                onGoAddMaintenance({
                  assetId: workRequests.asset?.id || null,
                  description: workRequests.description,
                  workRequestId: workRequests.id,
                })
              }
              type="primary"
              shape="circle"
              style={{ marginTop: 3, float: "right" }}
            />
          </Tooltip>

          <RequirePermission
            requiredPermissionCode={["manage_wo"]}
            children={
              <Popconfirm
                onConfirm={() => {
                  onDeleteWorkRequest(workRequests.id, (id) => setLoading(id));
                }}
                title={t(TRANSLATION_KEY.continueWithAction)}
              >
                <Button
                  loading={loading === workRequests.id}
                  icon={<DeleteOutlined />}
                  type="default"
                  shape="circle"
                  danger
                  style={{ marginTop: 3, float: "right" }}
                />
              </Popconfirm>
            }
          />
        </Space>
      }
    >
      <List.Item.Meta
        style={{ width: "100%" }}
        avatar={<Avatar style={{ backgroundColor: "green" }}>{"WR"}</Avatar>}
        title={
          <div style={{ display: "flex", flexDirection: "column", marginTop: -4 }}>
            <div style={{ display: "flex", gap: 4, fontSize: 14 }}>
              <div style={{ opacity: 0.5 }}>{workRequests.phone_number}</div>
              <div
                style={{ color: "#0353ef", fontSize: 12, display: "flex", gap: 4, marginLeft: 8 }}
              >
                <div style={{ fontSize: 5 }}>●</div>
                {moment(workRequests.created_at).format("LLL")}
              </div>
            </div>
            <div>{workRequests.description}</div>
          </div>
        }
        description={workRequests.description}
      />
    </List.Item>
  );
};

export default Card;
