import { useState, useRef } from "react";

// Antd
import { Tag, Input } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { t } from "i18next";

/**
 * @param value initialValue passed by parent Form.Item.
 * @param onChange a callback for Form.Item to read its child component's value.
 */

type IProps = {
  value?: string;
  onChange?: (properties: string[]) => void;
};

export default function TaskForm({ onChange }: IProps) {
  // Refs
  const inputRef: any = useRef(null);

  // Variables
  const [tags, set_tags] = useState<string[]>([]);
  const [inputVisible, set_inputVisible] = useState<boolean>(false);
  const [inputValue, set_inputValue] = useState<string>("");

  // Methods
  function handleClose(removedTag: string) {
    let _tags = tags.filter((tag) => tag !== removedTag);
    set_tags(_tags);
    if (typeof onChange === "function") {
      onChange(_tags);
    }
  }

  function showInput() {
    set_inputVisible(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  }

  function handleInputChange(e: any) {
    set_inputValue(e.target.value);
  }

  function handleInputConfirm() {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      let newTag: string = inputValue;
      let arr = [...tags];
      arr.push(newTag);
      set_tags(arr);
      if (typeof onChange === "function") {
        onChange(arr);
      }
    }
    set_inputVisible(false);
    set_inputValue("");
  }

  const forMap = (tag: string) => {
    const tagElem = (
      <Tag
        className="tag"
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );

    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  return (
    <>
      <div id="tagInput" style={{ marginBottom: 16 }}>
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            style={{ width: 100, height: 32, marginRight: 8 }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag style={{ cursor: "pointer" }} onClick={showInput} className="input-tag">
            <PlusOutlined /> {t(TRANSLATION_KEY.addNew)}
          </Tag>
        )}
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: "from",
            duration: 100,
          }}
          onEnd={(e: any) => {
            if (e.type === "appear" || e.type === "enter") {
              e.target.style = "display: inline-block";
            }
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
    </>
  );
}
