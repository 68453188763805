import { FilterFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, message, Spin, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IApiResponse, ILocation } from "../../../../../models";
import api, { failedQueue, isRefreshing } from "../../../../../services";
import { settingsSlice } from "../../../../../store/reducers/settings";
import { getLocationsXHR } from "../../../../../store/reducers/settings/actionCreator";
import Card from "./../components/card";

const Locations: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [isModalVisible, set_isModalVisible] = useState<boolean>(false);
  const [search, set_search] = useState<string>("");
  const [update, set_update] = useState<ILocation>();
  const [loading, set_loading] = useState<boolean>(false);
  const [isUpdating, set_isUpdating] = useState(false);
  const [locationName, set_locationName] = useState("");
  const { locations } = useAppSelector((state) => state.settingsReducer);
  const [newLocation, set_newLocation] = useState<string>("");

  useEffect(() => {
    getLocationsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  const onDelete = async (id: number) => {
    set_loading(true);

    let token = await localStorage.getItem("token");

    try {
      let response = api.delete<string>(`/settings/locations/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr = [...locations];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      dispatch(settingsSlice.actions.getLocationsSuccess({ results: arr, message: "" }));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onActionUpdate = async () => {
    set_loading(true);

    let token = await localStorage.getItem("token");

    try {
      let response = await api.put<IApiResponse<ILocation>>(
        `/settings/locations/${update?.id}/`,
        { name: locationName },
        {
          headers: { Authorization: "Bearer " + token },
        },
      );

      if (response.data.results) {
        const arr = [...locations];
        const index = arr.findIndex((x) => x.id === update?.id);
        arr[index] = response.data.results;

        dispatch(settingsSlice.actions.getLocationsSuccess({ results: arr, message: "" }));
        set_update(undefined);
        set_isModalVisible(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onActionUpdate());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const addLocation = async () => {
    set_loading(true);

    let token = await localStorage.getItem("token");

    try {
      let response = await api.post<IApiResponse<ILocation>>(
        `/settings/locations/`,
        { name: newLocation },
        {
          headers: { Authorization: "Bearer " + token },
        },
      );

      if (response.data.results) {
        const arr = [...locations];
        arr.push(response.data.results);
        set_search("");
        dispatch(settingsSlice.actions.getLocationsSuccess({ results: arr, message: "" }));
        set_newLocation("");
        set_isModalVisible(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => addLocation());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  let filtredData: ILocation[] = locations;

  if (search) {
    filtredData = locations.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={5} style={{ paddingTop: 10 }}>
          {t(TRANSLATION_KEY.locations)}
        </Typography.Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <Input.Search
            onChange={({ target: { value } }) => set_search(value)}
            value={search || ""}
            allowClear
            style={{ marginRight: 18 }}
            placeholder={t(TRANSLATION_KEY.searchLocations)}
          />

          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              set_newLocation("");
              set_isModalVisible(true);
              set_isUpdating(false);
            }}
            // disabled={!search}
            shape="circle"
            icon={<PlusOutlined />}
          />
        </div>
      </div>
      <Divider style={{ marginTop: 18 }} />
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {filtredData.map((item, i) => (
            <div key={i} style={{ marginRight: 12 }}>
              <Card
                item={item}
                onUpdate={(location: ILocation) => {
                  unstable_batchedUpdates(() => {
                    set_isModalVisible(true);
                    set_update(location);
                    set_locationName(location.name);
                    set_isUpdating(true);
                  }, []);
                }}
                onDelete={onDelete}
              />
            </div>
          ))}
        </div>
      </Spin>
      <Modal
        confirmLoading={loading}
        footer={null}
        visible={isModalVisible}
        closable={true}
        title={isUpdating ? t(TRANSLATION_KEY.editLocationName) : t(TRANSLATION_KEY.addLocation)}
        onCancel={() => (set_update(undefined), set_newLocation(""), set_isModalVisible(false))}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input
            value={isUpdating ? locationName : newLocation}
            onChange={({ target: { value } }) => {
              if (isUpdating) {
                set_locationName(value);
              } else {
                set_newLocation(value);
              }
            }}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 24,
            }}
          >
            <Button
              type="primary"
              loading={loading}
              onClick={() => (isUpdating ? onActionUpdate() : addLocation())}
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
            <Button
              onClick={() => (
                set_update(undefined), set_newLocation(""), set_isModalVisible(false)
              )}
              type="dashed"
            >
              {t(TRANSLATION_KEY.return)}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Locations;
