import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { ILocation } from "../../../../../models";
import { IMaintenanceType } from "../../../../../models/maintenances";
import { motion } from "framer-motion";
import { CardVariant } from "../../CheckList";
import { useState } from "react";

interface IProps {
  item: IMaintenanceType;
  onDelete: (id: number) => void;
  onUpdate: (category: IMaintenanceType) => void;
}

const Card: React.FC<IProps> = ({ item, onDelete, onUpdate }) => {
  // Variables
  const [variant, set_variant] = useState<CardVariant>("hidden");

  return (
    <motion.div
      onHoverStart={() => set_variant("visible")}
      onHoverEnd={() => set_variant("hidden")}
      style={{ padding: "4px 8px", paddingRight: 4, minHeight: 40 }}
      className="spaceBetweenRow locationCard"
    >
      <div className="flexRowCenterStart">
        <div
          style={{
            width: 16,
            height: 16,
            borderRadius: 16,
            marginRight: 12,
            backgroundColor: item.color,
          }}
        />
        <div>{t(item.trans_key || item.name)}</div>
      </div>
      <motion.div
        className="spaceBetweenRow"
        initial="hidden"
        animate={variant}
        variants={variants}
        transition={{ ease: "anticipate", duration: 0.35 }}
      >
        {!item.static && (
          <Button onClick={() => onUpdate(item)} type="link" icon={<EditOutlined />} />
        )}
        <div style={{ width: 6 }} />
        {!item.static && (
          <Popconfirm
            onConfirm={() => onDelete(item.id)}
            title={t(TRANSLATION_KEY.continueWithAction)}
          >
            <Button danger type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Card;

const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
