import { message } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks";

interface IProps {
  childern: JSX.Element;
}

const UserLoggedInRoute: React.FC<IProps> = ({ childern }) => {
  const auth: boolean = useAppSelector((state) => !!state.userReducer.user?.token.access);

  return auth ? <Navigate replace to="/app/dashboard" /> : childern;
};

export default UserLoggedInRoute;
