import React, { useEffect, useRef, useState } from "react";

import { Button, Form, Input, message, Select, Space, Typography, Upload } from "antd";

import {
  CUSTOM_FIELD_HALF_KEY,
  IMAGE_TYPES,
  MATERIAL_EXTENSIONS,
  TRANSLATION_KEY,
} from "../helpers/consts";
import { t } from "i18next";
import { IFormSupplier, ISupplier, ISupplierCategory } from "../models/supplier";
import {
  addSupplierXHR,
  getSupplierCategoriesXHR,
  updateSupplierXHR,
} from "../store/reducers/supplier/actionCreator";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  getCompanyCusstomFieldsXHR,
  getCompanySettingsXHR,
} from "../store/reducers/settings/actionCreator";
import { ButtonSaveType, IApiResponse } from "../models";
import { ICompanyCustomFields, ICompanySettings, ICustomValue } from "../models/settings";
import { compressImage, generateCustomFieldValues } from "../helpers/functions";
import GenerateForCustomFields from "./GenerateForCustomFields";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../components/SelectTagAdd";
import { supplierSlice } from "../store/reducers/supplier";
import { RcFile } from "antd/lib/upload";
import FilesComponent from "../components/Files";
import { IFile } from "../models/user";
import { UploadOutlined } from "@ant-design/icons";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";

const { Option } = Select;

interface IProps {
  supplier: null | ISupplier;
  close: () => void;
}

const SupplierForm: React.FC<IProps> = ({ supplier, close }) => {
  // Hooks
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);

  const [loadingSave, set_loadingSave] = React.useState<ButtonSaveType>();
  const inputNameRef = useRef<Input>(null);

  // Variables
  const [files, set_files] = useState<RcFile[]>([]);
  const [addCategoryVisible, set_addCategoryVisible] = useState<boolean>(false);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const [selectedCategory, set_selectedCategory] = useState<number>();

  const initialValues: IFormSupplier = {
    name: "",
    address: "",
    category: null,
    description: "",
    email: "",
    phone_number: "",
    custom_id: null,
    custom_fields_v2: {},
  };

  if (supplier) {
    initialValues.name = supplier.name;
    initialValues.address = supplier.address;
    initialValues.category = supplier.category?.id || null;
    initialValues.description = supplier.description;
    initialValues.email = supplier.email;
    initialValues.phone_number = supplier.phone_number;
    initialValues.custom_id = supplier.custom_id;
  }

  // Methods
  useEffect(() => {
    getSupplierCategoriesXHR({}, dispatch);
    if (supplier) {
      set_selectedCategory(supplier.category?.id);
    }
  }, []);

  const { supplierCategories, addSupplierStatus, updateSupplierStatus } = useAppSelector(
    (state) => state.supplierReducer,
  );

  const onFinish = (
    values: IFormSupplier & {
      [key: string]: ICustomValue[];
    },
  ) => {
    const formData = new FormData();

    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    requests.then(() => {
      if (supplier) {
        formData.append(
          "data",
          JSON.stringify({
            ...values,
            custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.suppliers),
            category: values.category || null,
            custom_id: values.custom_id || null,
            custom_fields: [],
            email: values.email || null,
          }),
        );

        if (supplier) {
          updateSupplierXHR(
            {
              id: supplier.id.toString(),
              body: formData,
              errorCallback: (err) => {
                if (err.response.data?.message?.custom_field) {
                  message.error(
                    t(err.response.data.message.message || "").replace(
                      "$_dynamic_column",
                      err.response.data.message.custom_field,
                    ),
                  );
                  return;
                }
                message.error(t(TRANSLATION_KEY.errorOnSaveData));
              },
              successCallback: () => close(),
            },
            dispatch,
          );
          return;
        }
      }
      formData.append(
        "data",
        JSON.stringify({
          ...values,
          email: values.email || null,
          custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.suppliers),
          custom_fields: [],
          category: values.category || null,
        }),
      );

      addSupplierXHR(
        {
          body: formData,
          errorCallback: (err) => {
            if (err.response.data?.message?.custom_field) {
              message.error(
                t(err.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  err.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          successCallback: () => {
            if (loadingSave === "saveAndAdd") {
              form.resetFields();
              inputNameRef.current?.focus();
              set_loadingSave(undefined);
            } else {
              close();
            }
          },
        },
        dispatch,
      );
    });
  };

  const addTagSwitch = (): JSX.Element | undefined => {
    if (addCategoryVisible) {
      return (
        <SelectTagAdd
          title={t(TRANSLATION_KEY.newSupplierCategory)}
          url="suppliers/categories/"
          set_visible={set_addCategoryVisible}
          visible={addCategoryVisible}
          successCallback={(tag: Tag) => {
            const data = [...supplierCategories];
            data.push({ id: tag.id, name: tag.name });
            const resFake: IApiResponse<ISupplierCategory[]> = {
              message: "",
              results: data,
            };
            set_selectedCategory(tag.id);
            dispatch(supplierSlice.actions.getSupplierCategoriesSuccess(resFake));
            form.setFieldsValue({ category: tag.id });
          }}
        />
      );
    }
    return undefined;
  };

  // Materials
  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  return (
    <>
      <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
        {supplier && (
          <Form.Item
            name="custom_id"
            label={t(TRANSLATION_KEY.code)}
            // rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label={t(TRANSLATION_KEY.name)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <Input ref={inputNameRef} />
        </Form.Item>
        <Form.Item
          name="email"
          label={t(TRANSLATION_KEY.email)}
          rules={[{ type: "email", message: t(TRANSLATION_KEY.enterCorrectEmail) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="address" label={t(TRANSLATION_KEY.address)}>
          <Input />
        </Form.Item>

        <Form.Item name={"category"} label={t(TRANSLATION_KEY.category)}>
          <Select
            allowClear
            onChange={(value) => set_selectedCategory(value)}
            notFoundContent={
              <ButtonAddSelectTag
                addingVisible={addCategoryVisible}
                set_addingVisible={set_addCategoryVisible}
              />
            }
          >
            {supplierCategories.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.name}
              </Option>
            ))}

            <Select.OptGroup
              label={
                <ButtonAddSelectTag
                  addingVisible={addCategoryVisible}
                  set_addingVisible={set_addCategoryVisible}
                />
              }
            />
          </Select>
        </Form.Item>

        <Form.Item name="phone_number" label={t(TRANSLATION_KEY.phoneNumber)}>
          <Input />
        </Form.Item>

        <Form.Item name="description" label={t(TRANSLATION_KEY.description)}>
          <Input.TextArea rows={6} />
        </Form.Item>

        {/* Dynamic columns */}
        {companyCustomFieldsV2 && (
          <GenerateForCustomFieldsV2
            values={supplier?.custom_fields_v2 || {}}
            condition={selectedCategory || undefined}
            customFields={companyCustomFieldsV2.suppliers}
            form={form}
          />
        )}

        <div style={{ display: !!supplier ? "none" : "block" }}>
          <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
            <Typography.Title level={5}>{t(TRANSLATION_KEY.documents)}</Typography.Title>
            <Upload
              multiple={true}
              accept={MATERIAL_EXTENSIONS}
              beforeUpload={beforeUpload}
              showUploadList={false}
            >
              <Button type="primary" shape="circle" icon={<UploadOutlined />} />
            </Upload>
          </div>

          {/* Files list */}
          <FilesComponent
            files={previewFiles}
            onDelete={onRemove}
            hideEditButton
            size="small"
            layout="list"
          />
        </div>

        <Form.Item>
          <Space>
            <Button
              onClick={() => {
                set_loadingSave("save");
              }}
              loading={
                (addSupplierStatus === "loading" || updateSupplierStatus === "loading") &&
                loadingSave === "save"
              }
              htmlType="submit"
              type="primary"
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
            <Button
              style={{
                display: supplier ? "none" : "block",
              }}
              onClick={() => {
                set_loadingSave("saveAndAdd");
              }}
              loading={
                (addSupplierStatus === "loading" || updateSupplierStatus === "loading") &&
                loadingSave === "saveAndAdd"
              }
              htmlType="submit"
              type="primary"
            >
              {t(TRANSLATION_KEY.saveAndAdd)}
            </Button>
          </Space>
        </Form.Item>
      </Form>

      {addTagSwitch()}
    </>
  );
};

export default SupplierForm;
