import { CheckCircleFilled, CheckOutlined, FileProtectOutlined } from "@ant-design/icons";
import { Badge, Button, Modal, Space, Progress } from "antd";
import { useEffect, useState } from "react";
import { IMaintenanceDetails } from "../../../models/maintenances";
import DigitalSignatureForm from "../Form";
import DigitalSignaturePreview from "../Preview";
import { UseDigitalSignature } from "../useDigitalSignature";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { t } from "i18next";

type Props = {
  maintenance: IMaintenanceDetails;
  digitalSignature: UseDigitalSignature;
};

const DigitalSignatureModal = ({ maintenance, digitalSignature }: Props) => {
  // Variables
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (digitalSignature.state === "success") {
      setModalVisible(false);
    }
  }, [digitalSignature.state]);

  return (
    <Space>
      <Badge
        count={maintenance.is_signed ? <CheckCircleFilled style={{ color: "#52c41a" }} /> : 0}
        showZero={false}
        color="green"
      >
        <Button
          loading={digitalSignature.state === "started"}
          onClick={() => setModalVisible(true)}
          icon={<FileProtectOutlined />}
        />
      </Badge>

      <Modal
        footer={null}
        width={520}
        centered
        destroyOnClose={true}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        title={t(TRANSLATION_KEY.electronicWorkOrderSignature)}
      >
        {maintenance.is_signed ? (
          <DigitalSignaturePreview maintenance={maintenance} />
        ) : (
          <DigitalSignatureForm maintenance={maintenance} digitalSignature={digitalSignature} />
        )}
      </Modal>
    </Space>
  );
};

export default DigitalSignatureModal;
