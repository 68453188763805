import { Button, Input, message, Modal, Popconfirm, Typography } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RequirePermission from "../../../../components/RequirePermission";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IMaintenanceDetails } from "../../../../models/maintenances";
import { cancelMaintenanceXHR } from "../../../../store/reducers/maintenance/actionCreator";

interface IProps {
  maintenance: IMaintenanceDetails;
}

const Reject: React.FC<IProps> = ({ maintenance }) => {
  const [reasonText, set_reasonText] = useState("");
  const dispatch = useAppDispatch();
  const { cancelMaintenanceStatus, calendarMaintenances } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const navigate = useNavigate();

  return (
    <>
      <Popconfirm
        title={t(TRANSLATION_KEY.continueWithAction)}
        onConfirm={() =>
          cancelMaintenanceXHR(
            {
              successCallback: () => {
                navigate("/app/maintenances");
              },
              errorCallback: () => {
                message.error(t(TRANSLATION_KEY.errorOnSaveData));
              },
              body: { order_info: maintenance.id, text: reasonText },
            },
            dispatch,
          )
        }
      >
        <Button type="primary" danger loading={cancelMaintenanceStatus === "loading"}>
          {t(TRANSLATION_KEY.reject)}
        </Button>
      </Popconfirm>
    </>
  );
};

export default Reject;
