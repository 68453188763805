import { Form, FormInstance, Select, message } from "antd";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getClientXHR, getClientsXHR } from "../store/reducers/clients/actionCreators";
import { debounce } from "../helpers/functions";
import { ILoadMoreParams } from "../models";
import { IClient } from "../models/clients";
import { CLIENTS_LIMIT_SIZE } from "../pages/Clients/pages/ClientsList";

interface IProps {
  tagRender?: (props: {
    label: React.ReactNode;
    value: any;
    disabled: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
  }) => React.ReactElement;
  form: FormInstance | undefined;
  name: string;
  label: string;
  includeInactive: boolean;
  onChange: (value: number | number[] | undefined) => void;
  mode: "multiple" | "tags" | undefined;
  style: React.CSSProperties | undefined;
  value: number | number[] | undefined;
  includeArchived: boolean;
  placeholder?: string;
  bordered?: boolean;
  required?: boolean;
  disabled?: boolean;
  excludeWithoutClient?: boolean;
}

interface ExposedFunctions {
  callMissingClient: (missId: number) => void;
}

const ClientsSelect: React.ForwardRefRenderFunction<ExposedFunctions, IProps> = (
  {
    bordered,
    form,
    name,
    label,
    mode,
    style,
    placeholder = t(TRANSLATION_KEY.selectClient),
    onChange,
    tagRender,
    value,
    includeInactive,
    required,
    disabled,
    excludeWithoutClient,
    includeArchived,
  },
  ref,
) => {
  const [getClientsStatus, set_getClientsStatus] = useState("");
  const dispatch = useAppDispatch();
  const [search, set_search] = React.useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [clients, set_clients] = useState<IClient[]>([]);
  const [loadMoreParams, setLoadMoreParams] = useState<ILoadMoreParams>({
    offset: 0,
    limit: CLIENTS_LIMIT_SIZE,
  });

  useEffect(() => {
    getClients("", loadMoreParams, false);
  }, []);

  const getClients = (search: string, loadMoreParams: ILoadMoreParams, mergeData: boolean) => {
    set_getClientsStatus("loading");
    getClientsXHR(
      {
        loading: "inCLientsSelect",
        noSaveToStore: true,
        mergeData,
        queryParams: {
          search,
          offset: loadMoreParams.offset,
          limit: loadMoreParams.limit,
          is_active: includeInactive ? "true,false" : "true",
          status: includeArchived ? "active,archived" : "active",
        },
        successCallback: (res) => {
          if (!res.results) return;
          if (mergeData) {
            set_clients([...clients, ...res.results]);
          } else {
            if (
              t(TRANSLATION_KEY.withoutClient).toLowerCase().includes(search.toLowerCase()) ||
              search === ""
            ) {
              if (excludeWithoutClient) {
                set_clients(res.results);
              } else {
                set_clients([
                  ...res.results,
                  {
                    custom_fields_v2: {},
                    name: t(TRANSLATION_KEY.withoutClient),
                    id: 0,
                    custom_id: "",
                    email: "",
                    phone_number: "",
                    address: "",
                    description: "",
                    is_active: false,
                    is_archived: false,
                  },
                ]);
              }
            } else {
              set_clients(res.results);
            }
          }
          setLoadMoreParams({
            ...loadMoreParams,
            offset: loadMoreParams.offset + CLIENTS_LIMIT_SIZE,
          });
          if (res.results && res.results?.length < CLIENTS_LIMIT_SIZE) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          set_getClientsStatus("");
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          set_getClientsStatus("");
        },
      },
      dispatch,
    );
  };

  const debouncedGetClientAsset = useCallback(debounce<typeof getClients>(getClients, 300), []);

  const handlePopupScroll = (e) => {
    if (!hasMore) return;
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      // Reached the bottom of the dropdown, load more options
      getClients(search, loadMoreParams, true);
      // Simulate loading for demonstration purpose
    }
  };

  const callMissingClients = (miss: number) => {
    getClientXHR(
      {
        loading: "inClientsSelect",
        id: miss,
        successCallback: (res) => {
          let tmpClients = [...clients];
          tmpClients.push({
            custom_fields_v2: {},
            name: res.results?.name || "",
            id: res.results?.id || 0,
            custom_id: res.results?.custom_id || "",
            email: res.results?.email || "",
            phone_number: res.results?.phone_number || "",
            address: res.results?.address || "",
            description: res.results?.description || "",
            is_active: res.results?.is_active || false,
            is_archived: res.results?.is_archived || false,
          });
          set_clients(tmpClients);
        },
      },
      dispatch,
    );
  };

  useImperativeHandle(ref, () => ({
    callMissingClient: (missId: number) => {
      if (clients.find((x) => x.id === missId)) return;
      callMissingClients(missId);
    },
  }));

  useEffect(() => {
    if (Array.isArray(value)) {
      if (value.length === 0) return;
      let missings: number[] = [];
      value.forEach((val) => {
        if (!clients.find((x) => x.id === val)) {
          missings.push(val);
        }
      });
      missings.forEach((miss) => {
        callMissingClients(miss);
      });
    } else if (value) {
      if (!clients.find((x) => x.id === value)) {
        callMissingClients(value);
      }
    }
  }, [value]);

  const select = useMemo(
    () => (
      <Select
        bordered={bordered}
        mode={mode}
        tagRender={tagRender}
        // onDeselect={onDeselect}
        value={value}
        placeholder={placeholder}
        style={style}
        filterOption={false}
        loading={getClientsStatus === "loading"}
        disabled={disabled}
        onBlur={() => {
          getClients("", { ...loadMoreParams, offset: 0 }, false);
        }}
        onChange={(va: number[] | number) => {
          onChange(va);
        }}
        onSearch={(value) => {
          set_search(value);
          debouncedGetClientAsset(value, { ...loadMoreParams, offset: 0 }, false);
        }}
        allowClear
        showSearch
        onPopupScroll={handlePopupScroll}
        options={clients.map((x) => ({ label: x.name, value: x.id }))}
      />
    ),
    [
      clients,
      getClientsStatus,
      debouncedGetClientAsset,
      handlePopupScroll,
      loadMoreParams,
      mode,
      onChange,
      style,
    ],
  );

  if (!name) {
    // ako nije u pitanju forma
    return select;
  }

  return (
    <Form.Item
      rules={[
        {
          required: required,
          message: t(TRANSLATION_KEY.filedRequired),
        },
      ]}
      label={label}
      name={name}
    >
      {select}
    </Form.Item>
  );
};
export default forwardRef(ClientsSelect);
