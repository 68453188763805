import { Button, Form, Input, message, Row, Col, Typography, Divider } from "antd";
import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse } from "../../models";
import { IUser } from "../../models/user";
import {
  confirmAndSetPasswordXHR,
  new_way_logoutXHR,
  setMqttClient,
} from "../../store/reducers/user/actionCreators";

const RegistrationWithToken: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const { loginStatus } = useAppSelector((state) => state.userReducer);

  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    new_way_logoutXHR(null, dispatch);
  }, []);

  const onSave = (values: { password: string; confirm: string }): void => {
    confirmAndSetPasswordXHR(
      {
        body: {
          reg_token: searchParams.get("reg_token") || "",
          password: values.password,
          isMobile,
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: (data) => {
          setNewPasswordCallback(data);
        },
      },
      dispatch,
    );
  };

  function setNewPasswordCallback(data: IApiResponse<IUser>) {
    if (isMobile) {
      window.location.replace("https://play.google.com/store/apps/details?id=io.metrikon");
    } else {
      if (data.results) {
        setMqttClient({ body: data.results.account }, dispatch);
      }
      navigate("/app", { replace: true });
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        minWidth: "100vw",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Row style={{ width: "100%", height: "100%", minHeight: "100vh" }}>
        <Col xs={0} lg={0}>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#001529",
            }}
          />
        </Col>
        <Col
          xs={24}
          lg={24}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              maxWidth: 450,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: 9,
            }}
          >
            <img
              style={{ width: 64, height: 64, marginBottom: 12, marginTop: -32 }}
              src={require("../../assets/icons/auth_logo.png")}
            />
            <Typography.Title
              level={1}
              style={{ color: "#434343", fontWeight: 500, textAlign: "center" }}
            >
              {t(TRANSLATION_KEY.setPassword)}
            </Typography.Title>

            <Typography.Title
              level={5}
              style={{
                paddingBottom: 24,
                color: "#434343aa",
                fontWeight: 300,
                textAlign: "center",
              }}
            >
              {t(TRANSLATION_KEY.setPasswordToContinue)}
            </Typography.Title>
            <Form style={{ width: "100%" }} form={form} onFinish={onSave} layout="vertical">
              <Form.Item
                name="password"
                label={t(TRANSLATION_KEY.password)}
                rules={[
                  {
                    required: true,
                    message: t(TRANSLATION_KEY.filedRequired),
                  },
                ]}
                hasFeedback
              >
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item
                name="confirm"
                label={t(TRANSLATION_KEY.confirmPassword)}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: t(TRANSLATION_KEY.pleaseConfirmPassword),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t(TRANSLATION_KEY.passwordAreNotMAtch)));
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Divider />

              <Form.Item>
                <Button
                  loading={loginStatus === "loading"}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  {t(TRANSLATION_KEY.save)}
                </Button>
              </Form.Item>

              <div style={{ width: 480 }} />
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegistrationWithToken;
