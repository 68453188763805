import { Spin } from "antd";
import { useEffect } from "react";
import { useAppSelector } from "../../../../hooks";
import Finish from "./finish";
import Pause from "./pause";
import Start from "./start";
import Take from "./take";

interface IProps {
  onClose: () => void;
}

const Comands: React.FC<IProps> = ({ onClose }) => {
  const {
    maintenanceDetails,
    takeMaintenanceStatus,
    startMaintenanceStatus,
    onHoldMaintenanceStatus,
    finishMaintenanceStatus,
  } = useAppSelector((state) => state.maintenanceReducer);

  const { user } = useAppSelector((state) => state.userReducer);

  const isExecutor = maintenanceDetails.executors.find((x) => x.account?.id === user.account.id);

  let showStart = true;

  let tmp = maintenanceDetails.flows.filter((x) => x.executor?.account?.id === user.account.id);

  if (tmp[tmp.length - 1]?.end === null) {
    showStart = false;
  }

  return (
    <Spin
      spinning={
        takeMaintenanceStatus === "loading" ||
        startMaintenanceStatus === "loading" ||
        onHoldMaintenanceStatus === "loading" ||
        finishMaintenanceStatus === "loading"
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "flex-start",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {!isExecutor && (
          <div style={{ marginRight: 12, marginBottom: 12 }}>
            <Take onClose={onClose} maintenance={maintenanceDetails} />
          </div>
        )}

        {showStart && (
          <div style={{ marginRight: 12, marginBottom: 12 }}>
            <Start isOpenOrder={false} onClose={onClose} maintenance={maintenanceDetails} />
          </div>
        )}
        {isExecutor?.is_working ? (
          <div style={{ marginRight: 12, marginBottom: 12 }}>
            <Pause onClose={onClose} maintenance={maintenanceDetails} />
          </div>
        ) : null}

        <div style={{ marginRight: 12, marginBottom: 12 }}>
          <Finish isOpenOrder={false} onClose={onClose} maintenance={maintenanceDetails} />
        </div>
      </div>
    </Spin>
  );
};

export default Comands;
