import { useState, useRef } from "react";

// Antd
import { Tag, Input, List, Button, Space, Typography } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { HolderOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../helpers/consts";
import ReactDragListView from "react-drag-listview";

/**
 * @param value initialValue passed by parent Form.Item.
 * @param onChange a callback for Form.Item to read its child component's value.
 */

type IProps = {
  value?: string[];
  onChange?: (properties: string[]) => void;
};

export const SelectTagInput: React.FC<IProps> = ({ onChange, value }) => {
  // Refs
  const inputRef: any = useRef(null);

  // Variables
  const [tags, set_tags] = useState<string[]>(value || []);
  const [inputVisible, set_inputVisible] = useState<boolean>(false);
  const [inputValue, set_inputValue] = useState<string>("");

  // Methods
  function handleClose(removedTag: string) {
    let _tags = tags.filter((tag) => tag !== removedTag);
    set_tags(_tags);
    if (typeof onChange === "function") {
      onChange(_tags);
    }
  }

  function showInput() {
    set_inputVisible(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  }

  function handleInputChange(e: any) {
    e.preventDefault();
    set_inputValue(e.target.value);
    showInput();
  }

  function handleInputConfirm(e) {
    e.preventDefault();
    if (inputValue && tags.indexOf(inputValue) === -1) {
      let newTag: string = inputValue;
      let arr = [...tags];
      arr.push(newTag);
      set_tags(arr);
      if (typeof onChange === "function") {
        onChange(arr);
      }
    }
    set_inputVisible(false);
    set_inputValue("");
  }

  const onDragEnd = async (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    const items = [...tags];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    onChange && onChange(items);
    set_tags(items);
  };

  return (
    <>
      <div
        id="tagInput"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Input
          placeholder={t(TRANSLATION_KEY.typeSelectData)}
          ref={inputRef}
          type="text"
          style={{ width: "100%", height: 32, marginRight: 8 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />

        <ReactDragListView nodeSelector=".ant-list-item.draggble" onDragEnd={onDragEnd}>
          <List
            style={{
              display: tags.length > 0 ? "block" : "none",
              borderRadius: 3,
              width: "100%",
            }}
            dataSource={tags}
            renderItem={(item, index) => {
              return (
                <List.Item
                  style={{ padding: "3px 0" }}
                  className="ListItemCustomClass draggble"
                  actions={[
                    <Button
                      type="text"
                      onClick={() => {
                        handleClose(item);
                      }}
                    >
                      <MinusCircleOutlined />
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    description={
                      <Space>
                        <HolderOutlined />
                        <Typography.Text
                          style={{ maxWidth: 240 }}
                          ellipsis={{
                            tooltip: item,
                          }}
                        >
                          {item}
                        </Typography.Text>
                      </Space>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </ReactDragListView>
      </div>
    </>
  );
};
