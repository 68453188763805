import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import CustomFieldFormV2, {
  FormModal,
  FormType,
  getFormSetup,
} from "../../../../../componentsform/CustomFieldsFormV2";
import { useAppSelector } from "../../../../../hooks";
import { CustomFieldV2 } from "../../../../../models/settings";
import Account from "./Account";

const { TabPane } = Tabs;

const Clients: React.FC = () => {
  const [modalVisible, set_modalVisible] = useState<FormModal>();

  const { roles } = useAppSelector((state) => state.settingsReducer);

  const [activeKey, set_activeKey] = useState<FormType>("account");

  let formSetup = useMemo(
    () =>
      getFormSetup(
        modalVisible?.type as FormType,
        modalVisible?.customField,
        roles.map((x) => ({
          id: x.id,
          name: x.rolename,
        })),
      ),
    [activeKey, modalVisible, roles],
  );

  return (
    <div>
      <CustomFieldFormV2
        modalProps={{
          visible: modalVisible !== undefined,
          onCancel: () => set_modalVisible(undefined),
          title: formSetup.title,
          destroyOnClose: true,
        }}
        formType={modalVisible?.type || "account"}
        data={modalVisible?.customField}
        condition={formSetup.condition}
      />
      <Tabs
        size="small"
        tabPosition="top"
        defaultActiveKey="asset"
        tabBarExtraContent={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              set_modalVisible({
                type: activeKey,
                customField: {} as CustomFieldV2,
              });
            }}
            type="primary"
            shape="circle"
            style={{ marginBottom: 8, float: "right" }}
          />
        }
      >
        <TabPane tab={t(TRANSLATION_KEY.humanResources)} key="humanresources">
          <Account onEdit={set_modalVisible} roles={roles} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Clients;
