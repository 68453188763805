import { Button, Input, message } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IRentDetails, RentNote } from "../models/clients";
import { createRentNoteXHR, updateRentNoteXHR } from "../store/reducers/clients/actionCreators";

interface IProps {
  rentDetails: IRentDetails;
  close: () => void;
  note?: RentNote;
}

const RentNoteForm: React.FC<IProps> = ({ rentDetails, close, note }) => {
  const [text, set_text] = useState("");

  const dispatch = useAppDispatch();

  const { createRentNoteStatus } = useAppSelector((state) => state.clientsReducer);
  useEffect(() => {
    if (note) {
      set_text(note.note);
    }
  }, []);

  const updateReport = async (id: number) => {
    updateRentNoteXHR(
      {
        id,
        body: {
          note: text,
        },
        errorCallback: (error) => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          close();
        },
      },
      dispatch,
    );
  };

  const saveReport = () => {
    createRentNoteXHR(
      {
        id: rentDetails.id,
        body: {
          note: text,
        },
        errorCallback: (error) => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          close();
        },
      },
      dispatch,
    );
  };

  return (
    <>
      <div>
        <Input.TextArea
          rows={6}
          value={text}
          onChange={({ target: { value } }) => {
            set_text(value);
          }}
        />
        <div style={{ height: 45 }} />

        <Button
          type="primary"
          disabled={!text}
          loading={createRentNoteStatus === "loading"}
          onClick={() => {
            if (note?.id !== 0) {
              updateReport(note?.id || 0);
            } else {
              saveReport();
            }
          }}
        >
          {t(TRANSLATION_KEY.save)}
        </Button>
      </div>
    </>
  );
};

export default RentNoteForm;
