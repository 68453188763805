import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Antd
import {
  Descriptions,
  Row,
  Col,
  Button,
  Divider,
  Spin,
  PageHeader,
  Typography,
  Space,
  Result,
} from "antd";

// Translation
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { getSupplierXHR } from "../../../store/reducers/supplier/actionCreator";
import { supplierSlice } from "../../../store/reducers/supplier";
import CustomFieldsSpecGenerator from "../../../components/CustomFieldsSpecGenerator";

interface IProps {
  id: string | number | undefined;
}

const SupplierModalPreview = ({ id }: IProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.userReducer);
  const { supplier, getSupplierStatus } = useAppSelector((state) => state.supplierReducer);

  // Methods
  useEffect(() => {
    getSupplierXHR({ id }, dispatch);
    return () => {
      dispatch(supplierSlice.actions.resetSupplierStatus());
    };
  }, []);

  const validate = (value: string | null | undefined) => value || "-";
  const handleNavigate = () => navigate(`/app/suppliers/${supplier?.id}`);

  if (getSupplierStatus === "error") {
    return <Result status="warning" title={t(TRANSLATION_KEY.dataNotFound)} />;
  }

  return (
    <Spin spinning={getSupplierStatus === "loading"}>
      <div style={{ width: "100%", padding: 12, position: "relative" }}>
        {/* Page header */}
        <PageHeader
          style={{ padding: 0, marginBottom: 20 }}
          className="custom-page-header"
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.supplier)} </Typography.Text>
              <Typography.Text strong>#{supplier?.custom_id}</Typography.Text>
            </div>
          }
          extra={[
            <Button type="primary" onClick={handleNavigate}>
              {t(TRANSLATION_KEY.seeMore)}
            </Button>,
          ]}
        >
          <Space direction="vertical" style={{ width: "100%", marginTop: -40 }}>
            <Typography.Title level={3}>{supplier?.name}</Typography.Title>
            <Descriptions size="small" column={2}>
              <Descriptions.Item label={t(TRANSLATION_KEY.email)}>
                {validate(supplier?.email)}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.category)}>
                {validate(supplier?.category?.name)}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.phoneNumber)}>
                {validate(supplier?.phone_number)}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.address)}>
                {validate(supplier?.address)}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.description)}>
                <Typography.Text
                  style={{ maxWidth: 300 }}
                  ellipsis={{ tooltip: supplier.description }}
                >
                  {validate(supplier?.description)}
                </Typography.Text>
              </Descriptions.Item>
              {Object.keys(supplier?.custom_fields_v2).map((key, index) => {
                if (!supplier) {
                  return null;
                }
                let tmp = supplier["custom_fields_v2"];
                console.log(tmp);
                if (!tmp) {
                  return null;
                }
                return CustomFieldsSpecGenerator({
                  condition: supplier.category?.id,
                  formType: "suppliers",
                  data: supplier.custom_fields_v2,
                });
              })}
            </Descriptions>
          </Space>
        </PageHeader>
      </div>
    </Spin>
  );
};

export default SupplierModalPreview;
