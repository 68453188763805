import React from "react";

import { Form, message, Input, Button } from "antd";
import { changePasswordXHR } from "../store/reducers/user/actionCreators";
import { useAppDispatch, useAppSelector } from "../hooks";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../helpers/consts";

interface IChangePasword {
  old_password: string;
  new_password: string;
  confirm: string;
}

interface IProps {
  close: () => void;
}

const ChangePasword: React.FC<IProps> = ({ close }) => {
  // Hooks
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  // Variables
  const {
    changePasswordStatus,
    user: { account },
  } = useAppSelector((state) => state.userReducer);

  const onFinish = (values: IChangePasword) => {
    changePasswordXHR(
      {
        id: account.id,
        body: values,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => close(),
      },
      dispatch,
    );
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        name={"old_password"}
        label={t(TRANSLATION_KEY.oldPassword)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="new_password"
        label={t(TRANSLATION_KEY.password)}
        rules={[
          {
            required: true,
            message: t(TRANSLATION_KEY.filedRequired),
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={t(TRANSLATION_KEY.confirmPassword)}
        dependencies={["new_password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: t(TRANSLATION_KEY.filedRequired),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("new_password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t(TRANSLATION_KEY.passwordAreNotMAtch)));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button loading={changePasswordStatus === "loading"} type="primary" htmlType="submit">
          {t(TRANSLATION_KEY.save)}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasword;
