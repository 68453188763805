import { Alert, Button, Form, Input, message } from "antd";
import { t } from "i18next";
import React from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { CreateViewXHRResponseType, TableView, TableViewCategories } from "../models/user";
import { createViewXHR, updateViewXHR } from "../store/reducers/user/actionCreators";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IApiResponse } from "../models";

interface IProps {
  onClose: (view: TableView) => void;
  view: TableView;
  viewCategory: TableViewCategories;
}

const ViewForm: React.FC<IProps> = ({ view, onClose, viewCategory }) => {
  const [form] = Form.useForm<{ name: string }>();

  const { createViewStatus } = useAppSelector((state) => state.userReducer);

  const dispatch = useAppDispatch();

  const close = (res: IApiResponse<CreateViewXHRResponseType>, nameOfViewOnForm: string) => {
    if (!res.results) return;
    let _views = res.results[viewCategory];
    let _view = _views.find((v) => v.name === nameOfViewOnForm);
    if (!_view) return;
    onClose(_view);
  };

  const onFinish = (values: { name: string }) => {
    if (view.id !== 0) {
      updateViewXHR(
        {
          id: view.id,
          body: {
            ...view,
            name: values.name,
          },
          successCallback: (res) => {
            close(res, values.name);
          },
          errorCallback: (error) => {
            if (error?.response?.data?.message === "invalid_name") {
              message.error(t(error?.response?.data?.message));
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
        },
        dispatch,
      );
      return;
    }
    createViewXHR(
      {
        body: {
          category: viewCategory,
          name: values.name,
        },
        successCallback: (res) => {
          close(res, values.name);
        },
        errorCallback: (error) => {
          if (error?.response?.data?.message === "invalid_name") {
            message.error(t(error?.response?.data?.message));
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
      },
      dispatch,
    );
  };

  return (
    <div>
      <Form onFinish={onFinish} form={form} initialValues={view} layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: t(TRANSLATION_KEY.filedRequired),
            },
          ]}
          label={t(TRANSLATION_KEY.name)}
          name="name"
        >
          <Input autoFocus={true} />
        </Form.Item>
        <Form.Item>
          <Button loading={createViewStatus === "loading"} type="primary" htmlType="submit">
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ViewForm;
