import React, { CSSProperties, FC, forwardRef, HTMLAttributes } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem: FC<ItemProps> = (props) => {
  // Hooks
  const { setNodeRef, isDragging, attributes, listeners, transform, transition } = useSortable({
    id: props.id,
  });

  // Styles
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      {...props}
      {...attributes}
      {...listeners}
    >
      {props.children}
    </Item>
  );
};

export default SortableItem;

// Item wrapper
export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ id, withOpacity, isDragging, style, ...props }, ref) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? "0.5" : "1",
      cursor: isDragging ? "grabbing" : "grab",
      boxShadow: isDragging ? "0px 0px 16px 2px rgba(0,0,0,0.15)" : "none",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style,
    };

    return (
      <div ref={ref} style={inlineStyles} {...props}>
        {props.children}
      </div>
    );
  },
);
