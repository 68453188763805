import { ShareAltOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  message,
  Spin,
  Upload,
  Modal,
  Input,
  Checkbox,
  Typography,
  Empty,
  Divider,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FilesComponent, { MediaType } from "../../../components/Files";
import RequirePermission from "../../../components/RequirePermission";
import { MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../../../helpers/consts";
import { filterFilesByExtension } from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse } from "../../../models";
import { IAsset } from "../../../models/asset";
import { IFile } from "../../../models/user";
import api, { failedQueue, isRefreshing } from "../../../services";
import { assetSlice } from "../../../store/reducers/asstes";
import ShareMaterials from "../components/ShareMaterials";

// Search data
export type Data = IFile & { search: string };
const mapData = (materials: IFile[]): Data[] => {
  return materials.map((x) => ({
    ...x,
    search: x.name.toLowerCase(),
  }));
};

const Materials: React.FC = () => {
  // Variables
  const { id } = useParams();
  const [search, set_search] = useState<string>("");
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [filters, set_filters] = useState<MediaType[]>([]);
  const [loading, set_loading] = useState<boolean>(false);
  const { asset } = useAppSelector((state) => state.assetReducer);
  const dispatch = useAppDispatch();
  const [loadingUpdate, set_loadingUpdate] = useState<boolean>(false);
  const [updateVisible, set_updateVisible] = useState<IFile>();
  const [title, set_title] = useState<string>();
  const [data, set_data] = useState<Data[]>([]);
  const [files, set_files] = useState<RcFile[]>([]);
  const [fileNames, set_fileNames] = useState<string[]>([]);
  const [uploadTrigger, set_uploadTrigger] = useState<boolean>(false);

  useEffect(() => {
    set_data(mapData(asset.files));
  }, [asset.files]);

  useEffect(() => {
    // If uploadTrigger is true, call onUpload function
    if (uploadTrigger) {
      onUpload();
    }
  }, [uploadTrigger]);

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting file names
    let arr = [...fileNames];
    files.forEach((element: RcFile) => {
      arr.push(element.name);
    });
    set_fileNames(arr);

    // Setting uploadTrigger to true => useEffect will call onUpload function
    set_uploadTrigger(true);

    return false;
  };

  // Upload function, called in useEffect by uploadTrigger
  const onUpload = async () => {
    // Reseting uploadTrigger
    set_uploadTrigger(false);
    set_loading(true);
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    files.forEach((element: RcFile) => {
      formData.append("files", element);
      formData.append("data", JSON.stringify({ name: element.name }));
    });

    try {
      let response = await api.post<IApiResponse<IFile>>(
        `/assets/assets/${id}/upload_file/`,
        formData,
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        let arr = [...asset.files];
        let final = arr.concat(response.data.results); // Merge two arrays
        const fakeRes: IApiResponse<IAsset> = {
          results: { ...asset, files: final },
          message: "",
        };
        dispatch(assetSlice.actions.getSingleAssetSuccess(fakeRes));
        set_fileNames([]);
        set_files([]);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onUpload());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onDeleteFile = async (id: number) => {
    set_loading(true);
    const token = await localStorage.getItem("token");
    try {
      let response = await api.delete<IApiResponse<string>>(`/assets/files/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      let arr: IFile[] = [...asset.files];

      let index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);
      const fakeRes: IApiResponse<IAsset> = {
        results: { ...asset, files: arr },
        message: "",
      };

      dispatch(assetSlice.actions.getSingleAssetSuccess(fakeRes));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDeleteFile(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_loading(false);
  };

  const onUpdateTitle = async () => {
    set_loadingUpdate(true);
    const token = await localStorage.getItem("token");
    try {
      let response = await api.put<IApiResponse<IFile>>(
        `/assets/files/${updateVisible?.id}/`,
        { name: title },
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        let arr: IFile[] = [...asset.files];
        let index = arr.findIndex((x) => x.id === updateVisible?.id);
        arr[index] = response.data.results;
        const fakeRes: IApiResponse<IAsset> = {
          results: { ...asset, files: arr },
          message: "",
        };

        dispatch(assetSlice.actions.getSingleAssetSuccess(fakeRes));
        set_updateVisible(undefined);
      }
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onUpdateTitle());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_loadingUpdate(false);
  };

  // Checkboxes
  function onChange(checkedValues: any) {
    set_filters(checkedValues);
  }

  const options = [
    ...(data.some((item) => item.extension === "mp4")
      ? [{ label: t(TRANSLATION_KEY.video), value: "video" }]
      : []),
    ...(data.some((item) => item.extension === "pdf")
      ? [{ label: t(TRANSLATION_KEY.document), value: "document" }]
      : []),
    ...(data.some(
      (item) => item.extension === "jpg" || item.extension === "jpeg" || item.extension === "png",
    )
      ? [{ label: t(TRANSLATION_KEY.image), value: "image" }]
      : []),
  ];

  // Filters and search
  let filtredData: IFile[] = data;
  if (search) filtredData = data?.filter((x) => x.search.includes(search));
  if (filters.length) filtredData = filterFilesByExtension(filtredData, filters);

  return (
    <div>
      <div className="spaceBetweenRow" style={{ paddingTop: 10 }}>
        {/* Title */}
        <Typography.Title level={5}>{t(TRANSLATION_KEY.materials)}</Typography.Title>

        {/* CTA row */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          {/* Share materials */}
          {asset.files.length ? (
            <Button
              shape="circle"
              icon={<ShareAltOutlined />}
              style={{ marginRight: 16 }}
              onClick={() => set_modalVisible(true)}
            />
          ) : null}

          {/* Add materials */}
          <RequirePermission
            requiredPermissionCode={["manage_asset"]}
            children={
              <Upload
                showUploadList={false}
                beforeUpload={beforeUpload}
                accept={MATERIAL_EXTENSIONS}
                multiple
              >
                <Button loading={loading} type="primary" shape="circle" icon={<UploadOutlined />} />
              </Upload>
            }
          />
        </div>

        {/* <div>
          <Checkbox.Group 
            options={options} 
            onChange={onChange} 
          />
          <Input.Search
            allowClear
            onSearch={(v) => set_search(v.toLowerCase())}
            style={{ marginLeft: 18, marginRight: 18, maxWidth: 200 }}
          />
        </div> */}
      </div>

      <Divider style={{ marginTop: 10 }} />

      {data.length > 0 ? (
        <FilesComponent
          size="small"
          files={data}
          onEdit={(file: IFile | undefined) => {
            set_updateVisible(file);
            set_title(asset.files.find((item) => item.id === file?.id)?.name);
          }}
          onDelete={onDeleteFile}
        />
      ) : (
        <Empty style={{ marginTop: 70 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <Modal
        confirmLoading={loadingUpdate}
        onCancel={() => {
          set_updateVisible(undefined);
          set_title("");
        }}
        cancelText={t(TRANSLATION_KEY.cancel)}
        visible={!!updateVisible}
        closable={true}
        okText={t(TRANSLATION_KEY.save)}
        onOk={onUpdateTitle}
        title={t(TRANSLATION_KEY.edit)}
      >
        <div>
          <Input value={title} onChange={({ target: { value } }) => set_title(value)} />
        </div>
      </Modal>

      <Modal
        onCancel={() => set_modalVisible(false)}
        visible={modalVisible}
        closable={true}
        footer={null}
        centered
        destroyOnClose
        width={640}
        title={t(TRANSLATION_KEY.shareMaterials)}
      >
        <ShareMaterials
          data={asset.files}
          close={() => set_modalVisible(false)}
          currentAsset={asset}
        />
      </Modal>
    </div>
  );
};

export default Materials;
