import { useState } from "react";

// UI
import { motion } from "framer-motion";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { hasPermission } from "../../helpers/functions";

type IProps = {
  title: string | undefined;
  url: string;
  id: string | number | undefined;
  isActive?: boolean;
  openInNewTab?: boolean;
  linkStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  permissionsRequired?: string[];
  onClick: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

const PreviewButton = ({
  title,
  url,
  isActive = true,
  linkStyle,
  containerStyle,
  openInNewTab = false,
  permissionsRequired = [],
  onClick,
}: IProps) => {
  // Variables
  const [hovered, set_hovered] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.userReducer);

  if (permissionsRequired.length && !hasPermission(user.account.permissions, permissionsRequired)) {
    return <div>{title}</div>;
  } else if (!isActive) {
    return <div>{title}</div>;
  }

  return (
    <motion.div
      onHoverStart={() => set_hovered(true)}
      onHoverEnd={() => set_hovered(false)}
      style={{
        display: "flex",
        width: "100%",
        minWidth: (title?.length || 0) * 8 + 30,
        ...containerStyle,
      }}
    >
      {/* Title - link to page */}
      {openInNewTab ? (
        <a href={url}>{title}</a>
      ) : (
        <Link to={url} type="link" style={{ padding: 0, height: "unset", ...linkStyle }}>
          {title}
        </Link>
      )}
      <Tooltip
        style={{ textAlign: "center" }}
        title={`${t(TRANSLATION_KEY.preview)} ${title} ${t(TRANSLATION_KEY.inModal)}`}
      >
        <motion.div
          variants={variants}
          animate={hovered ? "visible" : "hidden"}
          initial="hidden"
          exit="hidden"
          transition={{ duration: 0.3, ease: "anticipate" }}
        >
          <InfoCircleOutlined onClick={(e) => onClick(e)} />
        </motion.div>
      </Tooltip>
    </motion.div>
  );
};

export default PreviewButton;

const variants = {
  visible: { x: 12, opacity: 1 },
  hidden: { x: 0, opacity: 0 },
};
