import AppLayout from "../layouts/App";
import AuthLayout from "../layouts/Auth";

import { Navigate, useLocation } from "react-router-dom";
import RegistrationWithToken from "../pages/auth/RegistrationWithToken";
import Login from "../pages/auth/Login";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../pages/dashboard";
import HumanResources from "../pages/humanresources";
import Asset from "../pages/asset";
import Maintenances from "../pages/maintenances";
import Suppliers from "../pages/suppliers";
import Supplier from "../pages/supplier";
import Warehouse from "../pages/warehouse";
import Settings from "../pages/settings";
import UserLoggedInRoute from "./UserLoggedInRoute";
import Logout from "../pages/auth/Logout";
import NotFound from "../pages/NotFound";
import Profile from "../pages/profile";
import Registration from "../pages/auth/Registration";

import {
  BankOutlined,
  BarChartOutlined,
  BarcodeOutlined,
  BulbOutlined,
  DashboardOutlined,
  FileProtectOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SolutionOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import Account from "../pages/account";
import AssetDetails from "../pages/assetdetails";
import Spec from "../pages/assetdetails/pages/Spec";
import AssetMaintenances from "../pages/assetdetails/pages/Maintenances";
import Parts from "../pages/assetdetails/pages/Parts";
import AccountSpec from "../pages/account/pages/Spec";
import AccountMaintenances from "../pages/account/pages/Maintenances";
import Modeling from "../pages/settings/pages/Modeling";
import Company from "../pages/settings/pages/Company";
import Categories from "../pages/settings/pages/Categories";
import Partner from "../pages/partner";
import PartSnerpecification from "../pages/partner/pages/partnerSpec";
import SharedAsset from "../pages/partner/pages/sharedAsset";
import PartnerReport from "../pages/partner/pages/partnerreport";
import Maintenance from "../pages/maintenance";
import MaintenancesSpec from "../pages/maintenance/pages/MaintenanceSpec";
import TableMaintenances from "../pages/maintenances/table";

import CalendarMaintenances from "../pages/maintenances/Calendar";
import SetNewPassword from "../pages/auth/SetNewPassword";
import PartProfile from "../pages/part";
import PartSpec from "../pages/part/pages/Spec";
import EntryHistory from "../pages/part/pages/EntryHistory";
import SpendHistory from "../pages/part/pages/SpendHistory";
import Costs from "../pages/maintenance/pages/Costs";
import OpenOrderLayout from "../layouts/OpenOrder";
import RequestPanelLayout from "../layouts/RequestPanel";
import OpenOrder from "../pages/openorder";
import Templates from "../pages/templates";
import TemplateDetails from "../pages/templatedetails";
import Analytics from "../pages/analytics";
import Maintenanceanalytics from "../pages/analytics/pages/maintenance";
import Assetanalytics from "../pages/analytics/pages/asset";
import Reportone from "../pages/analytics/pages/asset/reportone";
import Reportonemaintenance from "../pages/analytics/pages/maintenance/Reportonemaintenance";
import Reporttwomaintenance from "../pages/analytics/pages/maintenance/Reporttwomaintenance";
import Reportthreemaintenance from "../pages/analytics/pages/maintenance/Reportthreemaintenance";
import PartAnalytics from "../pages/analytics/pages/parts";
import ItemQuantity from "../pages/analytics/pages/parts/ItemQuantity";
import Reportfourmaintenance from "../pages/analytics/pages/maintenance/Reportfourmaintenance";
import PartConsumption from "../pages/analytics/pages/parts/PartConsumption";
import PartPurchase from "../pages/analytics/pages/parts/PartPurchase";
import RequestPanel from "../pages/requestpanel";
import Clients from "../pages/Clients";
import ClientsList from "../pages/Clients/pages/ClientsList";
import AllRents from "../pages/Clients/pages/AllRents";
import { IModul } from "../models";
import Client from "../pages/Client";
import AssetClients from "../pages/assetdetails/pages/AssetClients";
import ClientMaintenances from "../pages/Client/pages/Maintenances";
import AssetClientPage from "../pages/Client/pages/Asset";
import Rents from "../pages/Client/pages/Rents";
import RentOrder from "../pages/RentORder";

export interface IRoute {
  path: string;
  element: JSX.Element;
  children: Array<IRoute>;
  icon: any;
  additionalItem?: JSX.Element;
  permissionCode?: string;
}

export function AppRoutes(permissionCode: string[] | string, modules?: IModul[]): IRoute {
  let tmpRoutes = {
    path: "/app",
    element: <AppLayout />,
    icon: null,
    children: [
      {
        path: "",
        element: <Navigate replace to="dashboard" />,
        children: [],
        icon: null,
      },
      {
        path: "dashboard",
        element: <PrivateRoute childern={<Dashboard />} />,
        children: [],
        icon: <DashboardOutlined />,
      },
      {
        path: "maintenances",
        element: <PrivateRoute childern={<Maintenances />} />,
        children: [
          {
            path: "",
            element: <PrivateRoute childern={<TableMaintenances />} />,
            children: [],
            icon: null,
          },
          {
            path: "calendar",
            element: <PrivateRoute childern={<CalendarMaintenances />} />,
            children: [],
            icon: null,
          },
        ],
        icon: <ToolOutlined />,
      },
      {
        path: "templates",
        element: <PrivateRoute childern={<Templates />} />,
        icon: <ScheduleOutlined />,
        children: [],
        permissionCode: "manage_wo",
      },
      {
        path: "asset",
        element: <PrivateRoute childern={<Asset />} />,
        children: [],
        icon: <BulbOutlined />,
        permissionCode: "view_asset",
      },
      {
        path: "items",
        element: <PrivateRoute childern={<Warehouse />} />,
        icon: <BarcodeOutlined />,
        children: [],
        permissionCode: "manage_warehouse",
      },
      {
        path: "humanresources",
        element: <PrivateRoute childern={<HumanResources />} />,
        children: [],
        icon: <UsergroupAddOutlined />,
        permissionCode: "manage_user",
      },
      {
        path: "humanresources/:id",
        element: <PrivateRoute childern={<Account />} />,
        children: [
          {
            path: "",
            icon: null,
            element: <AccountSpec />,
            children: [],
          },
          {
            path: "maintenances",
            icon: null,
            element: <AccountMaintenances />,
            children: [],
          },
        ],
        icon: null,
        permissionCode: "manage_user",
      },

      {
        path: "asset-details/:id",
        element: <PrivateRoute childern={<AssetDetails />} />,
        children: [
          {
            path: "",
            icon: null,
            element: <Spec />,
            children: [],
          },
          {
            path: "maintenances",
            icon: null,
            element: <AssetMaintenances />,
            children: [],
          },
          {
            path: "items",
            icon: null,
            element: <Parts />,
            children: [],
          },
          {
            path: "rentOrders",
            icon: null,
            element: <AssetClients />,
            children: [],
            permissionCode: "manage_client",
          },
        ],
        icon: <BulbOutlined />,
        permissionCode: "view_asset",
      },

      {
        path: "maintenances/:id",
        element: <PrivateRoute childern={<Maintenance />} />,
        children: [
          {
            path: "",
            element: <MaintenancesSpec />,
            icon: null,
            children: [],
          },
          {
            path: "costs",
            element: <Costs />,
            icon: null,
            permissionCode: "add_part_on_wo",
            children: [],
          },
        ],
        icon: null,
      },

      {
        path: "templates/:id",
        element: <PrivateRoute childern={<TemplateDetails />} />,
        icon: <ScheduleOutlined />,
        children: [],
        permissionCode: "manage_wo",
      },

      {
        path: "item-details/:id",
        element: <PrivateRoute childern={<PartProfile />} />,
        icon: null,
        children: [
          {
            path: "",
            element: <EntryHistory />,
            icon: null,
            children: [],
          },
          {
            path: "spendhistory",
            element: <SpendHistory />,
            icon: null,
            children: [],
          },
        ],
        permissionCode: "manage_warehouse",
      },
      {
        path: "partners/:id",
        element: <PrivateRoute childern={<Partner />} />,
        icon: null,
        children: [
          {
            path: "",
            element: <PartSnerpecification />,
            icon: null,
            children: [],
          },
          {
            path: "asset",
            element: <SharedAsset />,
            icon: null,
            children: [],
          },
          {
            path: "report",
            element: <PartnerReport />,
            icon: null,
            children: [],
          },
        ],
      },
      {
        path: "suppliers",
        element: <PrivateRoute childern={<Suppliers />} />,
        children: [],
        icon: <FileProtectOutlined />,
        permissionCode: "manage_supplier",
      },
      {
        path: "suppliers/:id",
        element: <PrivateRoute childern={<Supplier />} />,
        icon: null,
        children: [],
        permissionCode: "manage_supplier",
      },
      {
        path: "rentorder/:id",
        element: <PrivateRoute childern={<RentOrder />} />,
        icon: null,
        children: [],
        permissionCode: "manage_client",
      },
      {
        path: "clients",
        element: <PrivateRoute childern={<Clients />} />,
        icon: <BankOutlined />,
        children: [
          {
            path: "",
            icon: null,
            element: <ClientsList />,
            children: [],
          },
          {
            path: "rents",
            icon: null,
            element: <AllRents />,
            children: [],
          },
        ],
        permissionCode: "manage_client",
      },
      {
        path: "clients/:id",
        element: <PrivateRoute childern={<Client />} />,
        icon: null,
        permissionCode: "manage_client",
        children: [
          {
            path: "",
            icon: null,
            element: <Rents />,
            children: [],
          },
          {
            path: "maintenances",
            icon: null,
            element: <ClientMaintenances />,
            children: [],
          },
          {
            path: "asset",
            icon: null,
            element: <AssetClientPage />,
            children: [],
          },
        ],
      },
      {
        path: "analytics",
        element: <PrivateRoute childern={<Analytics />} />,
        children: [
          {
            path: "",
            element: <Maintenanceanalytics />,
            icon: null,
            children: [
              {
                path: "",
                element: <Reportonemaintenance />,
                icon: null,
                children: [],
              },
              {
                path: "wo-time",
                element: <Reporttwomaintenance />,
                icon: null,
                children: [],
              },
              {
                path: "wo-costs",
                element: <Reportthreemaintenance />,
                icon: null,
                children: [],
              },
              {
                path: "wo-success",
                element: <Reportfourmaintenance />,
                icon: null,
                children: [],
              },
            ],
          },
          {
            path: "asset-analytics",
            element: <Assetanalytics />,
            icon: null,
            children: [
              {
                path: "",
                element: <Reportone />,
                icon: null,
                children: [],
              },
            ],
          },

          {
            path: "item-analytics",
            element: <PartAnalytics />,
            icon: null,
            children: [
              {
                path: "",
                element: <ItemQuantity />,
                icon: null,
                children: [],
              },
              {
                path: "item-consumption",
                element: <PartConsumption />,
                icon: null,
                children: [],
              },
              {
                path: "item-purchase",
                element: <PartPurchase />,
                icon: null,
                children: [],
              },
            ],
          },
        ],
        icon: <BarChartOutlined />,
        permissionCode: "manage_analytics",
      },
      {
        path: "settings",
        element: <PrivateRoute childern={<Settings />} />,
        children: [
          {
            path: "",
            element: <Modeling />,
            icon: null,
            children: [],
          },
          {
            path: "categories",
            element: <Categories />,
            icon: null,
            children: [],
          },
          {
            path: "company",
            element: <Company />,
            icon: null,
            children: [],
          },
        ],
        icon: <SettingOutlined />,
        permissionCode: "manage_settings",
      },
      {
        path: "profile",
        element: <Profile />,
        children: [],
        icon: null,
      },
      {
        path: "logout",
        element: <Logout />,
        children: [],
        icon: null,
      },
      {
        path: "*",
        element: <NotFound />,
        children: [],
        icon: null,
      },
    ],
  };

  if (permissionCode === "allroutes") {
    return tmpRoutes;
  }

  tmpRoutes.children = tmpRoutes.children.filter((x) => {
    if (x.permissionCode === undefined) {
      // ako ruti nije dodana permisija moze
      return true;
    }
    return permissionCode.includes(x.permissionCode);
  });

  return tmpRoutes;
}
export const AuthRoutes = (auth: boolean): IRoute => {
  return {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Navigate replace to={auth ? "/app" : "/login"} />,
        children: [],
        icon: null,
      },
      {
        path: "registrationwithtoken",
        children: [],
        element: <UserLoggedInRoute childern={<RegistrationWithToken />} />,
        icon: null,
      },
      {
        path: "login",
        children: [],
        element: <UserLoggedInRoute childern={<Login />} />,
        icon: null,
      },
      {
        path: "registration",
        children: [],
        element: <UserLoggedInRoute childern={<Registration />} />,
        icon: null,
      },
      {
        path: "reset_password",
        children: [],
        element: <UserLoggedInRoute childern={<SetNewPassword />} />,
        icon: null,
      },
      {
        path: "*",
        children: [],
        element: <UserLoggedInRoute childern={<NotFound />} />,
        icon: null,
      },
    ],
    icon: null,
  };
};

export const OpenOrderRoutes = (): IRoute => {
  return {
    path: "open-order",
    element: <OpenOrderLayout />,
    children: [
      {
        path: ":token",
        element: <OpenOrder />,
        children: [],
        icon: null,
      },
    ],
    icon: null,
  };
};

export const RequestPanelRoutes = (): IRoute => {
  return {
    path: "requestpanel",
    element: <RequestPanelLayout />,
    children: [
      {
        path: "",
        element: <RequestPanel />,
        children: [],
        icon: null,
      },
    ],
    icon: null,
  };
};
