import { DownOutlined } from "@ant-design/icons/lib/icons";
import { Button, Dropdown, List, Menu } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import ReactDragListView from "react-drag-listview";
import { TRANSLATION_KEY } from "../helpers/consts";

const Columns = (props) => {
  // Props
  const { columns = [], onChange, memorizedName, title = t(TRANSLATION_KEY.columnTitle) } = props;
  const setColumns = async () => {
    const _columns = await localStorage.getItem(memorizedName);
    let tmp = JSON.parse(_columns);

    if (tmp && tmp.length === columns.length) {
      let new_columns = [];
      let shouldBreak = false; // Add a flag to control whether to break the function

      tmp.forEach((item) => {
        let column = columns.find(
          (column) => column.dataIndex === item.dataIndex && column.title === item.title,
        );

        if (column === undefined) {
          // Ukoliko je dataIndex kolone izgubljen ili ukoliko je prijevod različit, resetuj memorisane kolone
          setNewColumns();
          shouldBreak = true; // Set the flag to break the function
          return;
        }

        new_columns.push({ ...column, title: item.title, visible: item.visible });
      });

      if (shouldBreak) {
        return; // Exit the function if shouldBreak is true
      }

      await localStorage.setItem(
        memorizedName,
        JSON.stringify(
          new_columns.map((x) => ({ visible: x.visible, dataIndex: x.dataIndex, title: x.title })),
        ),
      );

      onChange(new_columns);
    } else {
      setNewColumns();
    }
  };

  const setNewColumns = async () => {
    onChange([...columns]);
    localStorage.setItem(
      memorizedName,
      JSON.stringify(
        columns.map((x) => ({ visible: x.visible, dataIndex: x.dataIndex, title: x.title })),
      ),
    );
  };

  useEffect(() => {
    setColumns();
  }, []);

  const onDragEnd = async (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area
    const items = [...columns];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);

    await localStorage.setItem(
      memorizedName,
      JSON.stringify(
        items.map((x) => ({ visible: x.visible, dataIndex: x.dataIndex, title: x.title })),
      ),
    );

    onChange(items);
  };

  const onChangeVisibility = async (index) => {
    const items = [...columns];
    const item = { ...items[index] };
    item.visible = !item.visible;
    items[index] = item;

    await localStorage.setItem(
      memorizedName,
      JSON.stringify(
        items.map((x) => ({ visible: x.visible, dataIndex: x.dataIndex, title: x.title })),
      ),
    );

    onChange(items);
  };

  if (!columns.length) {
    return null;
  }

  const menu = (
    <Menu>
      <ReactDragListView nodeSelector=".ant-list-item.draggble" onDragEnd={onDragEnd}>
        <List
          style={{
            padding: "0 6px",
            borderRadius: 3,
            width: 240,
          }}
          // header={
          // <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
          //     <div />
          //     {/* <Button shape="circle" size="small" onClick={() => set_listOpen(false)}>
          //       <CloseOutlined />
          //     </Button> */}
          // </div>
          // }
          dataSource={columns}
          renderItem={(item, index) => {
            if (item.visible === "always") {
              return <span></span>;
            }
            return (
              <List.Item
                className="ListItemCustomClass draggble"
                actions={[
                  <Checkbox checked={item.visible} onChange={() => onChangeVisibility(index)} />,
                ]}
              >
                <List.Item.Meta description={t(item.title)} />
              </List.Item>
            );
          }}
        />
      </ReactDragListView>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      // visible={listOpen}
      arrow={{ pointAtCenter: true }}
      placement="bottomLeft"
    >
      <Button>
        {title} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default Columns;
