import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFile } from "../../../components/Files";
import { IApiResponse } from "../../../models";
import {
  IFlow,
  IMaintenanceCategory,
  IMaintenanceDetails,
  IUserReport,
} from "../../../models/maintenances";
import { ITask } from "../../../models/settings";
import { IDeleteSupplierResponse, ISupplier, ISupplierCategory } from "../../../models/supplier";

interface ISupplierReducer {
  sendSupplierEmailStatus: string;
  // Suppliers
  getSuppliersStatus: string;
  suppliers: ISupplier[];
  // Supplier categories
  getSupplierCategoriesStatus: string;
  supplierCategories: ISupplierCategory[];
  // Add supplier
  addSupplierStatus: string;
  // Get supplier
  getSupplierStatus: string;
  supplier: ISupplier;
  // Update supplier
  updateSupplierStatus: string;
  openOrder?: IMaintenanceDetails;
  getOpenOrderStatus: string;
  startOpenOrderStatus: string;
  finishOpenOrderStatus: string;
  addTaskToOpenOrderStatus: string;
  addTaskResultOnOpenOrderStatus: string;
  addWorkingTImeToOpenOrderStatus: string;
  deleteWorkingTimeOnOpenOrderStatus: string;
  addReportToOpenOrderStatus: string;
  updateReportToOpenOrderStatus: string;
}

const initialState = {
  sendSupplierEmailStatus: "",
  // Suppliers
  getSuppliersStatus: "",
  suppliers: [],
  // Supplier categories
  getSupplierCategoriesStatus: "",
  supplierCategories: [],
  // Add supplier
  addSupplierStatus: "",
  // Get supplier
  getSupplierStatus: "",
  supplier: {
    custom_fields_v2: {},
    address: "",
    category: {
      id: 0,
      name: "",
    },
    custom_fields: [],
    description: "",
    email: "",
    id: 0,
    name: "",
    phone_number: "",
    files: [],
    custom_id: null,
  },
  // Update supplier
  updateSupplierStatus: "",
  getOpenOrderStatus: "",
  startOpenOrderStatus: "",
  finishOpenOrderStatus: "",
  addTaskToOpenOrderStatus: "",
  addTaskResultOnOpenOrderStatus: "",
  addWorkingTImeToOpenOrderStatus: "",
  deleteWorkingTimeOnOpenOrderStatus: "",
  addReportToOpenOrderStatus: "",
  updateReportToOpenOrderStatus: "",
} as ISupplierReducer;

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    getSuppliers(state) {
      state.getSuppliersStatus = "loading";
    },
    getSuppliersSuccess(state, action: PayloadAction<IApiResponse<ISupplier[]>>) {
      state.getSuppliersStatus = "";
      if (action.payload.results) {
        state.suppliers = action.payload.results;
      }
    },
    getSuppliersFail(state, action: PayloadAction<string>) {
      state.getSuppliersStatus = action.payload;
    },

    // -----

    getSupplierCategories(state) {
      state.getSupplierCategoriesStatus = "loading";
    },
    getSupplierCategoriesSuccess(state, action: PayloadAction<IApiResponse<ISupplierCategory[]>>) {
      state.getSupplierCategoriesStatus = "";
      if (action.payload.results) {
        state.supplierCategories = action.payload.results;
      }
    },
    getSupplierCategoriesFail(state, action: PayloadAction<string>) {
      state.getSupplierCategoriesStatus = action.payload;
    },

    // -----

    addSupplier(state) {
      state.addSupplierStatus = "loading";
    },
    addSupplierSuccess(state, action: PayloadAction<IApiResponse<ISupplier>>) {
      if (action.payload.results) {
        const arr = [...state.suppliers];
        arr.push(action.payload.results);
        state.suppliers = arr;
      }
      state.addSupplierStatus = "";
    },
    addSupplierFail(state, action: PayloadAction<string>) {
      state.addSupplierStatus = action.payload;
    },

    // -----

    getSupplier(state) {
      state.getSupplierStatus = "loading";
    },
    getSupplierSuccess(state, action: PayloadAction<IApiResponse<ISupplier>>) {
      state.getSupplierStatus = "";
      if (action.payload.results) {
        state.supplier = action.payload.results;
      }
    },
    getSupplierFail(state, action: PayloadAction<string>) {
      state.getSupplierStatus = "error";
    },
    resetSupplierStatus(state) {
      state.getSupplierStatus = "";
    },

    // -----

    updateSupplier(state) {
      state.updateSupplierStatus = "loading";
    },
    updateSupplierSuccess(state, action: PayloadAction<IApiResponse<ISupplier>>) {
      state.updateSupplierStatus = "";
      if (action.payload.results) {
        const results = action.payload.results;
        state.supplier = results;
        // Update suppliers
        let arr = [...state.suppliers];
        let index = arr.findIndex((item) => item.id === results.id);
        arr.splice(index, 1, results);
        state.suppliers = arr;
      }
    },
    updateSupplierFail(state, action: PayloadAction<string>) {
      state.updateSupplierStatus = action.payload;
    },

    // -----

    getOpenOrder(state) {
      state.getOpenOrderStatus = "loading";
    },
    getOpenOrderSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        state.openOrder = action.payload.results;
      }
      state.getOpenOrderStatus = "";
    },
    getOpenOrderFail(state, action: PayloadAction<string>) {
      state.getOpenOrderStatus = action.payload;
    },

    // -----

    startOpenOrder(state) {
      state.startOpenOrderStatus = "loading";
    },
    startOpenOrderSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        if (state.openOrder) {
          state.openOrder = action.payload.results;
        }
      }
      state.startOpenOrderStatus = "";
    },
    startOpenOrderFail(state, action: PayloadAction<string>) {
      state.startOpenOrderStatus = action.payload;
    },

    finishOpenOrder(state) {
      state.finishOpenOrderStatus = "loading";
    },
    finishOpenOrderSuccess(state, action: PayloadAction<IApiResponse<IMaintenanceDetails>>) {
      if (action.payload.results) {
        if (state.openOrder) {
          state.openOrder = action.payload.results;
        }
      }
      state.finishOpenOrderStatus = "";
    },
    finishOpenOrderFail(state, action: PayloadAction<string>) {
      state.finishOpenOrderStatus = action.payload;
    },

    // -----

    addTaskToOpenOrder(state) {
      state.addTaskToOpenOrderStatus = "loading";
    },
    addTaskToOpenOrderSuccess(state, action: PayloadAction<IApiResponse<ITask[]>>) {
      if (action.payload.results) {
        if (!state.openOrder) {
          return;
        }
        state.openOrder = {
          ...state.openOrder,
          tasks: action.payload.results,
        };
      }
      state.addTaskToOpenOrderStatus = "";
    },
    addTaskToOpenOrderFail(state, action: PayloadAction<string>) {
      state.addTaskToOpenOrderStatus = action.payload;
    },

    // -----

    addTaskResultOnOpenOrder(state) {
      state.addTaskResultOnOpenOrderStatus = "loading";
    },
    addTaskResultOnOpenOrderSuccess(state, action: PayloadAction<IApiResponse<ITask[]>>) {
      if (action.payload.results) {
        if (state.openOrder) {
          state.openOrder = {
            ...state.openOrder,
            tasks: action.payload.results,
          };
        }
      }

      state.addTaskResultOnOpenOrderStatus = "";
    },
    addTaskResultOnOpenOrderFail(state, action: PayloadAction<string>) {
      state.addTaskResultOnOpenOrderStatus = action.payload;
    },

    // -----

    addWorkingTImeToOpenOrder(state) {
      state.addWorkingTImeToOpenOrderStatus = "loading";
    },
    addWorkingTImeToOpenOrderSuccess(state, action: PayloadAction<IApiResponse<IFlow[]>>) {
      if (action.payload.results) {
        if (state.openOrder) {
          state.openOrder = {
            ...state.openOrder,
            flows: action.payload.results,
          };
        }
      }
      state.addWorkingTImeToOpenOrderStatus = "";
    },
    addWorkingTImeToOpenOrderFail(state, action: PayloadAction<string>) {
      state.addWorkingTImeToOpenOrderStatus = action.payload;
    },

    // -----

    deleteWorkingTimeOnOpenOrder(state, action: PayloadAction<string>) {
      state.deleteWorkingTimeOnOpenOrderStatus = action.payload;
    },
    deleteWorkingTimeOnOpenOrderSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.deleteWorkingTimeOnOpenOrderStatus = "";
    },
    deleteWorkingTimeOnOpenOrderFail(state, action: PayloadAction<string>) {
      state.deleteWorkingTimeOnOpenOrderStatus = action.payload;
    },

    // -----

    addReportToOpenOrder(state) {
      state.addReportToOpenOrderStatus = "loading";
    },
    addReportToOpenOrderSuccess(state, action: PayloadAction<IApiResponse<IUserReport>>) {
      state.addReportToOpenOrderStatus = "";
    },
    addReportToOpenOrderFail(state, action: PayloadAction<string>) {
      state.addReportToOpenOrderStatus = action.payload;
    },

    // -----

    updateReportToOpenOrder(state) {
      state.updateReportToOpenOrderStatus = "loading";
    },
    updateReportToOpenOrderSuccess(state, action: PayloadAction<IApiResponse<IUserReport>>) {
      state.updateReportToOpenOrderStatus = "";
    },
    updateReportToOpenOrderFail(state, action: PayloadAction<string>) {
      state.updateReportToOpenOrderStatus = action.payload;
    },

    // -----

    sendSupplierEmail(state) {
      state.sendSupplierEmailStatus = "loading";
    },
    sendSupplierEmailSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.sendSupplierEmailStatus = "";
    },
    sendSupplierEmailFail(state, action: PayloadAction<string>) {
      state.sendSupplierEmailStatus = action.payload;
    },
  },
});

export default supplierSlice.reducer;
