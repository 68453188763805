import React, { useEffect, useState } from "react";
import { CustomFieldV2 } from "../../../../../models/settings";
import { BasicObject } from "../../../../../models";
import { Button, Popconfirm, Space, Table, TableColumnType, Tag, message } from "antd";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { t } from "i18next";
import { FormModal, FormType } from "../../../../../componentsform/CustomFieldsFormV2";
import { deleteCustomFieldXHR } from "../../../../../store/reducers/settings/actionCreator";
import { useAppDispatch } from "../../../../../hooks";
import { meXHR } from "../../../../../store/reducers/user/actionCreators";
import PreviewButtonGroup from "../../../../../components/PreviewButton/Group";

interface IProps {
  condition: Array<BasicObject & { trans_key: string | undefined }> | undefined;
  onEdit: (data: FormModal) => void;
  conditionTitle: string | undefined;
  customFieldsData: CustomFieldV2[];
  formType: FormType;
}

const CustomFieldsTable: React.FC<IProps> = ({
  condition,
  conditionTitle,
  onEdit,
  customFieldsData,
  formType,
}) => {
  const dispatch = useAppDispatch();
  const [deleteLoading, set_deleteLoading] = useState("");

  const [itemsPerRow, setItemsPerRow] = useState(getItemsPerRow());
  const onDelete = (cf_id: string) => {
    set_deleteLoading(cf_id);
    deleteCustomFieldXHR(
      {
        body: {
          cf_category: formType,
          cf_id,
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnDeleteData));
          set_deleteLoading("");
        },
        successCallback: () => {
          meXHR(
            {
              loading: "refresh",
            },
            dispatch,
          );
          set_deleteLoading("");
        },
      },
      dispatch,
    );
  };

  const [COLUMNS, set_COLUMNS] = useState<TableColumnType<CustomFieldV2>[]>([
    {
      title: t(TRANSLATION_KEY.name),
      dataIndex: "name",
    },
    {
      title: t(TRANSLATION_KEY.fieldType),
      dataIndex: "type",
      render: (text: string, value: CustomFieldV2) => {
        if (!value.data) {
          return t(text);
        }
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {t(text)}-
            <PreviewButtonGroup title={""} limit={2}>
              {value.data?.map((v, i) => (
                <React.Fragment key={i}>
                  <Tag style={{ marginBottom: 6 }}>{v}</Tag>
                  {(i + 1) % itemsPerRow === 0 && i !== 0 && <div style={{ width: "100%" }} />}
                </React.Fragment>
              ))}
            </PreviewButtonGroup>
          </div>
        );
      },
    },
    {
      title: conditionTitle,
      dataIndex: "condition",
      render: (text: string, value: CustomFieldV2) => {
        if (!value.condition) {
          return t(TRANSLATION_KEY.all);
        }
        return (
          <Space>
            {condition
              ?.filter((x) => value.condition?.includes(x.id))
              .map((x) => t(x.trans_key || x.name))
              .join(", ")}
          </Space>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.column_unique),
      render: (text: string, value: CustomFieldV2) => {
        return value.unique ? (
          <Tag color="green">{t(TRANSLATION_KEY.yes)}</Tag>
        ) : (
          <Tag color="red">{t(TRANSLATION_KEY.no)}</Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text: string, value: CustomFieldV2, index?: number) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                onEdit({ type: formType, customField: value });
              }}
            >
              {t(TRANSLATION_KEY.edit)}
            </Button>
            <Popconfirm
              onConfirm={() => onDelete(value.cf_id)}
              title={t(TRANSLATION_KEY.continueWithActionDynColumnDeleting)}
            >
              <Button
                loading={deleteLoading === value.cf_id}
                style={{ marginLeft: 9 }}
                danger
                type="link"
              >
                {t(TRANSLATION_KEY.delete)}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  useEffect(() => {
    set_COLUMNS([...COLUMNS]);
  }, [customFieldsData, condition, conditionTitle, deleteLoading]);

  return (
    <Table
      pagination={{
        pageSize: 12,
      }}
      columns={
        condition
          ? COLUMNS
          : COLUMNS.filter((x) => x.title !== conditionTitle || x.dataIndex === "actions")
      }
      size="small"
      dataSource={customFieldsData}
    />
  );
};

export default CustomFieldsTable;

const getItemsPerRow = () => {
  // Determine the number of items per row based on screen size
  const screenWidth = window.innerWidth;
  return screenWidth >= 768 ? 5 : 3; // If screen width is greater than or equal to 768px, show 5 items per row, otherwise show 3 items per row
};
