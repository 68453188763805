import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Image,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import Upload from "antd/lib/upload";
import { t } from "i18next";
import { useState } from "react";
import TermsOfService from "../../../../components/TermsOfService";
import { LANGUAGES, Languages, TRANSLATION_KEY } from "../../../../helpers/consts";
import { hasModul, parseCosts } from "../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IApiResponse } from "../../../../models";
import { ICompanySettings } from "../../../../models/settings";
import api, { failedQueue, isRefreshing } from "../../../../services";
import { settingsSlice } from "../../../../store/reducers/settings";
import Role from "../Role";
import useAvatar from "./components/useAvatar";

const DEFAULT_IMAGE = require("../../../../assets/icons/default.jpg");

const Company: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { isLoading, setAvatar, deleteAvatar } = useAvatar();

  // Selectors
  const { user } = useAppSelector((state) => state.userReducer);
  const company = useAppSelector((state) => state.userReducer.user.account.company);
  const { companySettings } = useAppSelector((state) => state.settingsReducer);
  const [loading, set_loading] = useState(false);
  const [termsVisible, setTermsVisible] = useState<boolean>(false);

  // Methods
  async function updateCompanySettings(data: Partial<ICompanySettings>) {
    set_loading(true);
    try {
      let token = await localStorage.getItem("token");

      let response = await api.put<IApiResponse<ICompanySettings>>(
        `settings/company/`,
        { ...companySettings, ...data },
        { headers: { Authorization: "Bearer " + token } },
      );
      dispatch(
        settingsSlice.actions.getCompanySettingsSuccess({
          message: "",
          results: response.data.results,
        }),
      );
      set_loading(false);
    } catch (error: any) {
      //lkl
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => updateCompanySettings(data));
        }
        return;
      }
      set_loading(false);
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
  }

  return (
    <>
      {/* Page header */}
      <PageHeader style={{ padding: 0 }} className="custom-page-header" title={company.name}>
        <div style={{ display: "flex" }}>
          <Space direction="vertical">
            {/* Avatar */}
            <div
              style={{
                backgroundColor: "white",
                position: "relative",
                marginRight: 24,
                height: 132,
              }}
            >
              <Spin spinning={isLoading} style={{ width: 240, height: 132 }}>
                {company.avatar ? (
                  <Image
                    style={{ width: 240, height: 132, objectFit: "contain" }}
                    src={company.avatar}
                  />
                ) : (
                  <Image
                    src="error"
                    style={{ width: 240, height: 132, objectFit: "contain" }}
                    fallback={DEFAULT_IMAGE}
                  />
                )}
              </Spin>
              <div style={{ position: "absolute", bottom: 10, right: 10 }}>
                <Upload showUploadList={false} beforeUpload={setAvatar} accept=".jpeg,.jpg,.png">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={company.avatar ? <EditOutlined /> : <PlusOutlined />}
                  />
                </Upload>

                {company.avatar && (
                  <Popconfirm
                    onConfirm={deleteAvatar}
                    title={t(TRANSLATION_KEY.continueWithAction)}
                    cancelText={t(TRANSLATION_KEY.no)}
                    okText={t(TRANSLATION_KEY.yes)}
                    placement="top"
                  >
                    <Button
                      danger
                      type="primary"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      style={{ marginLeft: 12 }}
                    />
                  </Popconfirm>
                )}
              </div>
            </div>
          </Space>

          <Spin spinning={loading}>
            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}>
              {/* ID_PDV */}
              <Descriptions.Item span={1} label={t(TRANSLATION_KEY.idpdv)}>
                {company.id_pdv}
              </Descriptions.Item>
              {/* Address */}
              <Descriptions.Item span={1} label={t(TRANSLATION_KEY.address)}>
                {company.address}
              </Descriptions.Item>
              {/* Phone number */}
              <Descriptions.Item span={1} label={t(TRANSLATION_KEY.phoneNumber)}>
                {company.phone_number || "-"}
              </Descriptions.Item>
              {/* Web */}
              <Descriptions.Item span={1} label={t(TRANSLATION_KEY.web)}>
                {company.web || "-"}
              </Descriptions.Item>
              {/* Global hour price */}
              <Descriptions.Item span={1} label={t(TRANSLATION_KEY.globalHourPrice)}>
                <Space align="center">
                  <Typography.Text
                    editable={{
                      onChange: (value) => {
                        let price: number | string = value.includes(",")
                          ? value
                          : parseFloat(value).toString();
                        price = parseFloat(
                          price
                            .replace(",", ".")
                            .toString()
                            .replace(/[^0-9.]/g, ""),
                        );
                        updateCompanySettings({ global_hour_price: price.toFixed(2).toString() });
                      },
                    }}
                  >
                    {parseCosts(companySettings.global_hour_price) || ""}
                  </Typography.Text>
                  <Typography.Text style={{ marginLeft: 12 }}>{company.currency}</Typography.Text>
                </Space>
              </Descriptions.Item>
              {/* Global company currency */}
              <Descriptions.Item span={1} label={t(TRANSLATION_KEY.globalCompanyCurrency)}>
                {companySettings.currency}
              </Descriptions.Item>
              {/* User report required */}
              <Descriptions.Item
                className="select"
                span={1}
                label={
                  <Space>
                    {t(TRANSLATION_KEY.userReportRequired)}
                    <Tooltip title={t(TRANSLATION_KEY.infoReportRequired)}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Space>
                }
              >
                <Checkbox
                  checked={companySettings.report_required}
                  onChange={({ target: { checked } }) =>
                    updateCompanySettings({ ...companySettings, report_required: checked })
                  }
                />
              </Descriptions.Item>

              {/* Language */}
              <Descriptions.Item className="select" span={1} label={t(TRANSLATION_KEY.language)}>
                <Select
                  bordered={false}
                  style={{ width: "100%" }}
                  showArrow={false}
                  onSelect={(language: Languages) => {
                    updateCompanySettings({ ...companySettings, language });
                  }}
                  value={companySettings.language}
                >
                  {LANGUAGES.map((item, index) => (
                    <Select.Option key={index} value={item.alpha2Code}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Descriptions.Item>

              {/* {hasModul(user.account.company, "electronic_signature") && (
                <Descriptions.Item
                  className="select"
                  span={1}
                  label={t(TRANSLATION_KEY.autoSendSignedDoc)}
                >
                  <Checkbox
                    checked={true}
                    onChange={({ target: { checked } }) =>
                      updateCompanySettings({
                        ...companySettings,
                        send_document_after_signing: checked,
                      })
                    }
                  />
                </Descriptions.Item>
              )} */}

              {hasModul(user.account.company, "electronic_signature") && (
                <Descriptions.Item
                  className="select"
                  span={1}
                  label={t(TRANSLATION_KEY.requestSmsAuthorization)}
                >
                  <Checkbox
                    checked={companySettings.el_signature_sms_otp}
                    onChange={({ target: { checked } }) =>
                      updateCompanySettings({
                        ...companySettings,
                        el_signature_sms_otp: checked,
                      })
                    }
                  />
                </Descriptions.Item>
              )}

              {/* Terms of service */}
              <Descriptions.Item span={2} label={t(TRANSLATION_KEY.termsOfService)}>
                <Typography.Link onClick={() => setTermsVisible(true)}>
                  {t(TRANSLATION_KEY.metrikonTerms)}
                </Typography.Link>
              </Descriptions.Item>

              {/* <Descriptions.Item
                span={1}
                label={t(TRANSLATION_KEY.showMaintenanceBefore)}
              >
                {companySettings.show_preventive_maintenance_before
                  ? moment
                      .duration(companySettings.show_preventive_maintenance_before)
                      .humanize()
                  : "-"}
              </Descriptions.Item> */}
            </Descriptions>
          </Spin>
        </div>
      </PageHeader>

      {/* Terms of service */}
      <TermsOfService
        visible={termsVisible}
        footerVisible={false}
        onCancel={() => setTermsVisible(false)}
        title={t(TRANSLATION_KEY.termsOfService)}
      />

      <Divider />

      <Row>
        <Col span={24}>
          <div className="white-container" style={{ minHeight: 240 }}>
            <Role />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Company;
