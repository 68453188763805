import { Bar, BarConfig, Datum } from "@ant-design/charts";
import { Typography } from "antd";
import { t } from "i18next";
import React, { useEffect, useMemo } from "react";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { parseCosts } from "../../../helpers/functions";
import { useAppSelector } from "../../../hooks";

interface IProps {}

const CostsByAsset: React.FC<IProps> = () => {
  const { top_costs } = useAppSelector((state) => state.dashboardReducer);
  const currency = useAppSelector((state) => state.userReducer.user.account.company.currency);

  const config: BarConfig = {
    data: top_costs.map((x) => ({
      total_cost: x.total_cost,
      asset_name: x.asset_name || t(TRANSLATION_KEY.withoutAsset),
    })),
    xField: "total_cost",
    yField: "asset_name",
    seriesField: "type",
    animation: { appear: { delay: 750 } },
    tooltip: {
      formatter: (datum: Datum) => {
        return {
          name: t(TRANSLATION_KEY.cost),
          value: `${parseCosts(datum.total_cost)} ${currency}`,
        };
      },
    },
    color: () => "#FAAD14",
  };

  const memed = useMemo(() => {
    return <Bar {...config} />;
  }, [top_costs]);

  return (
    <div>
      <div style={{ marginBottom: 12 }} className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.costsByAsset)}
        </Typography.Title>
      </div>

      <div style={{ marginTop: 12, marginBottom: 12 }} />

      {memed}
    </div>
  );
};

export default CostsByAsset;
