import React, { useState, useEffect, useRef } from "react";
import { t } from "i18next";

// Antd
import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Typography, List, Modal, Input, message, Form, Divider } from "antd";

// Rest
import api, { failedQueue, isRefreshing } from "../../../../../services";
import { ICON_SIZE, TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IMaintenanceOnHoldType } from "../../../../../models/maintenances";
import { IApiResponse } from "../../../../../models";
import { maintenanceSlice } from "../../../../../store/reducers/maintenance";
import Card from "./MaintenanceCategoryCard";
import { unstable_batchedUpdates } from "react-dom";
import { getStopWorkingReasonsXHR } from "../../../../../store/reducers/maintenance/actionCreator";

const MaintenancesOnHoldTypes: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [loading, set_loading] = useState<boolean>(false);
  const [categoryName, set_categoryName] = useState<string>("");
  const [category, set_category] = useState<IMaintenanceOnHoldType>();

  const inputRef = useRef<Input>(null);

  // Methods
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus({ cursor: "end" });
    }, 300);
  }, [category]);

  const { onHoldMaintenanceStatus, onHoldTypes } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  const addCategory = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.post<IApiResponse<IMaintenanceOnHoldType>>(
        `/maintenance/stop_working_reason/`,
        { name: categoryName },
        { headers: { Authorization: "Bearer " + token } },
      );
      if (response.data.results) {
        let arr = [...onHoldTypes];
        arr.push(response.data.results);
        dispatch(
          maintenanceSlice.actions.getStopWorkingReasonsSuccess({
            results: arr,
            message: "",
          }),
        );
        set_categoryName("");
        set_category(undefined);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => addCategory());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onActionUpdate = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.put<IApiResponse<IMaintenanceOnHoldType>>(
        `/maintenance/stop_working_reason/${category?.id}/`,
        { name: categoryName },
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        let arr: IMaintenanceOnHoldType[] = [...onHoldTypes];
        let index = arr.findIndex((item) => item.id === response.data.results?.id);
        arr.splice(index, 1, response.data.results);

        dispatch(
          maintenanceSlice.actions.getStopWorkingReasonsSuccess({
            results: arr,
            message: "",
          }),
        );
        set_category(undefined);
        set_categoryName("");
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onActionUpdate());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onDelete = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/maintenance/stop_working_reason/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr: IMaintenanceOnHoldType[] = [...onHoldTypes];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      dispatch(
        maintenanceSlice.actions.getStopWorkingReasonsSuccess({
          results: arr,
          message: "",
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  return (
    <div>
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.maintenanceOnHoldTypes)}
        </Typography.Title>
        <Button
          onClick={() => {
            set_category({ name: "", id: 0, static: false, trans_key: "" });
          }}
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
        />
      </div>

      <Divider style={{ marginTop: 10 }} />

      <List
        dataSource={onHoldTypes}
        loading={loading || onHoldMaintenanceStatus === "loading"}
        renderItem={(x: IMaintenanceOnHoldType) => (
          <Card
            item={x}
            onDelete={onDelete}
            onUpdate={() =>
              unstable_batchedUpdates(() => {
                set_category(x);
                console.log(x.name || x.trans_key || "");
                set_categoryName(t(x.trans_key || x.name || ""));
              }, [])
            }
          />
        )}
      />

      <Modal
        destroyOnClose={true}
        title={
          category?.id === 0
            ? t(TRANSLATION_KEY.newPauseReason)
            : t(TRANSLATION_KEY.editPauseReason)
        }
        okButtonProps={{ disabled: !categoryName }}
        okText={t(TRANSLATION_KEY.save)}
        cancelText={t(TRANSLATION_KEY.cancel)}
        visible={!!category}
        onCancel={() => {
          set_category(undefined);

          set_categoryName("");
        }}
        footer={null}
      >
        <Form
          onFinish={() => {
            if (category?.id === 0) {
              addCategory();
            } else {
              onActionUpdate();
            }
          }}
        >
          <Input
            ref={inputRef}
            value={categoryName}
            onChange={({ target: { value } }) => {
              set_categoryName(value);
            }}
          />
          <Button style={{ marginTop: 24 }} type="primary" htmlType="submit">
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default MaintenancesOnHoldTypes;
