import { InfoCircleTwoTone } from "@ant-design/icons";
import { Avatar, Space, Typography } from "antd";

type IProps = {
  label: string | number | any;
  value: string | number | any;
};

const AnalyticsCard = ({ label = "", value = "" }: IProps) => {
  return (
    <div
      style={{
        width: "100%",
        minWidth: 180,
        padding: 24,
        border: "1px solid #dedede",
        backgroundColor: "white",
        borderRadius: 4,
      }}
    >
      <Space size="large">
        {/* Icon */}
        <Avatar
          style={{ backgroundColor: "#e6f7ff" }}
          size={{ xs: 40, sm: 52, md: 52, lg: 52, xl: 52, xxl: 52 }}
          icon={<InfoCircleTwoTone />}
        />
        {/* Informations */}
        <Space direction="vertical" size={0}>
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            {label}
          </Typography.Text>
          <Typography.Text strong style={{ fontSize: 20, marginTop: -4 }}>
            {value}
          </Typography.Text>
        </Space>
      </Space>
    </div>
  );
};

export default AnalyticsCard;
