import { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Popconfirm,
  Spin,
  Typography,
} from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";
import {
  addSubAssetXHR,
  editSubAssetXHR,
  getSubAssetsXHR,
} from "../../../store/reducers/asstes/actionCreators";
import { useDispatch } from "react-redux";
import { ISubAsset } from "../../../models/asset";
import api, { failedQueue, isRefreshing } from "../../../services";

import { useParams } from "react-router-dom";
import { assetSlice } from "../../../store/reducers/asstes";
import RequirePermission from "../../../components/RequirePermission";
import SubAssetForm from "../../../componentsform/SubAssetForm";
import { PlusOutlined } from "@ant-design/icons";
import { hasPermission } from "../../../helpers/functions";

type IProps = {};

export interface IAddSubAsset {
  name: string;
  asset: number;
}

const SubAssets: React.FC<IProps> = () => {
  // Hooks
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [loading, set_loading] = useState<boolean>(false);
  const [selectedSubAsset, set_selectedSubAsset] = useState<ISubAsset | undefined>(undefined);
  const { subAssets, getSubAssetsStatus } = useAppSelector((state) => state.assetReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const [canEditDeleteSubasset] = useState<boolean>(
    hasPermission(user?.account?.permissions || [], ["manage_asset"]),
  );

  // Methods
  useEffect(() => {
    getSubAssetsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          asset: id,
        },
      },
      dispatch,
    );
  }, []);

  const onDelete = async (item: ISubAsset) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/assets/sub_asset/${item.id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr: ISubAsset[] = [...subAssets];
      const index = arr.findIndex((x) => x.id === item.id);
      arr.splice(index, 1);

      dispatch(
        assetSlice.actions.getSubAssetsSuccess({
          results: arr,
          message: "",
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(item));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const closeModal = () => set_modalVisible(false);

  return (
    <Spin spinning={getSubAssetsStatus === "loading" || loading}>
      {/* Header */}
      <div className="spaceBetweenRow" style={{ paddingTop: 10 }}>
        <Typography.Title level={5}>{t(TRANSLATION_KEY.assetMarks)}</Typography.Title>
        <div style={{ marginBottom: 12 }}>
          <RequirePermission
            requiredPermissionCode={["manage_asset"]}
            children={
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => {
                  set_selectedSubAsset({ id: 0, name: "", asset: { id: 0, name: "" } });
                }}
              />
            }
          />
        </div>
      </div>

      {/* Divider */}
      <Divider style={{ marginTop: 10 }} />

      {/* List of sub assets */}
      <List
        itemLayout="horizontal"
        dataSource={subAssets}
        renderItem={(item) => (
          <List.Item
            actions={
              canEditDeleteSubasset
                ? ([
                    <Button onClick={() => set_selectedSubAsset(item)} type="link">
                      {t(TRANSLATION_KEY.edit)}
                    </Button>,
                    <Popconfirm
                      onConfirm={() => onDelete(item)}
                      title={t(TRANSLATION_KEY.continueWithAction)}
                      cancelText={t(TRANSLATION_KEY.no)}
                      okText={t(TRANSLATION_KEY.yes)}
                      placement="top"
                    >
                      <Button type="link" danger>
                        {t(TRANSLATION_KEY.delete)}
                      </Button>
                    </Popconfirm>,
                  ] as ReactNode[])
                : undefined
            }
          >
            <List.Item.Meta title={item.name} />
          </List.Item>
        )}
      />

      {/* Modal */}
      <Modal
        visible={!!selectedSubAsset}
        onCancel={() => {
          set_selectedSubAsset(undefined);
          closeModal();
        }}
        footer={null}
        centered
        width="600px"
        closable
        destroyOnClose
        title={t(TRANSLATION_KEY.addAssetMark)}
      >
        <SubAssetForm
          asset_id={id ? +id : 0}
          close={() => set_selectedSubAsset(undefined)}
          subAsset={selectedSubAsset}
        />
      </Modal>
    </Spin>
  );
};

export default SubAssets;
