import { Button, Divider, Form, Input, message, Modal, Select, SelectProps, Spin } from "antd";
import { t } from "i18next";
import React, { RefObject, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { TRANSLATION_KEY } from "../helpers/consts";
import { hasPermission } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { EXECUTOR_TYPES } from "../models";
import { getPotentialExecutorsXHR } from "../store/reducers/maintenance/actionCreator";
import { createExecutorsObject, IFormExecutors } from "./MaintenanceForm";
import { AnimatePresence, motion } from "framer-motion";
import { DefaultOptionType } from "antd/lib/select";

const { OptGroup, Option } = Select;

interface IProps {
  onAdd: (value: IFormExecutors) => void;
  loading: boolean;
  asset_id: number;
  order_info: number | undefined;
  skipGetPotentialExecutors?: boolean;
}

const AddExecutorToOrder: React.FC<IProps> = ({
  onAdd,
  loading,
  asset_id,
  order_info,
  skipGetPotentialExecutors = false,
}) => {
  // Refs
  const executorSelectRef = React.useRef<any>(null);

  // Hooks
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();

  // Variables
  const [executorType, set_executorType] = useState<EXECUTOR_TYPES>("employee");
  const [selectedExecutor, set_selectedExecutor] = useState<string>("");
  const [supplierEmailVisible, set_supplierEmailVisible] = useState<boolean>(false);
  const { potentialExecutors, getPotentialExecutorsStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const { user } = useAppSelector((state) => state.userReducer);

  // Methods
  useEffect(() => {
    //override enter button and return prevent default

    if (!selectedExecutor) {
      return;
    }
    if (executorType === "employee") {
      form.setFieldsValue({ executors: [selectedExecutor] });
    } else {
      form.setFieldsValue({ executors: selectedExecutor });
    }
  }, [executorType]);

  useEffect(() => {
    if (!skipGetPotentialExecutors) {
      getPotentialExecutorsXHR(
        {
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
          queryParams: {
            order_info,
            asset: asset_id,
          },
        },
        dispatch,
      );
    }
  }, []);

  if (getPotentialExecutorsStatus === "loading") {
    return <Spin />;
  }

  const onFinish = (values: { executors: string[] | string }) => {
    onAdd(
      createExecutorsObject(
        executorType,
        values.executors || "",
        emailForm.getFieldValue("sendEmail"),
        emailForm.getFieldValue("emailNote"),
      ) || null,
    );
  };

  return (
    <>
      {" "}
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          label={t(TRANSLATION_KEY.executors)}
          name={"executors"}
        >
          <Select
            ref={executorSelectRef as any}
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (typeof option?.children === "string") {
                const str: string = option.children;
                return str.toLowerCase().includes(input.toLowerCase());
              }
              return false;
            }}
            showSearch={true}
            mode={executorType === "employee" ? "multiple" : undefined}
            onSelect={(val: string) => {
              let type = val.split("|").pop();
              unstable_batchedUpdates(() => {
                set_selectedExecutor(val);
                if (type === "e") {
                  set_executorType("employee");
                } else if (type === "s") {
                  // set_supplierEmailVisible(true);
                  let email: string =
                    potentialExecutors.suppliers.find((x) => x.id === +val.split("|")[0])?.email ||
                    "";
                  emailForm.setFieldsValue({ sendEmail: email });
                  set_executorType("supplier");
                  executorSelectRef.current?.blur();
                } else {
                  set_executorType("partner");
                }
              }, []);
            }}
          >
            <OptGroup label={t(TRANSLATION_KEY.employee)}>
              {potentialExecutors.employees.map((x) => (
                <Option key={x.id + "|e"} value={x.id + "|e"}>
                  {x.name}
                </Option>
              ))}
            </OptGroup>
            {hasPermission(user.account.permissions, ["manage_wo"]) && (
              <OptGroup label={t(TRANSLATION_KEY.supplier)}>
                {potentialExecutors.suppliers.map((x) => (
                  <Option key={x.id + "|s"} value={x.id + "|s"}>
                    {x.name}
                  </Option>
                ))}
              </OptGroup>
            )}
            {/* <OptGroup label={TRANSLATION_KEY.partner}>
              {potentialExecutors.partners.map((x) => (
                <Option key={x.id + "|p"} value={x.id + "|p"}>
                  {x.name}
                </Option>
              ))}
            </OptGroup> */}
          </Select>
        </Form.Item>

        {/* Supplier Email */}
        <AnimatePresence>
          {executorType === "supplier" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={`${executorType}`}
            >
              <Divider style={{ fontSize: 12, fontWeight: 400, color: "#777" }}>
                {t(TRANSLATION_KEY.emailForSendOrderToSupplier)}
              </Divider>
              <Form form={emailForm} onFinish={() => undefined} layout="vertical">
                <Form.Item name="sendEmail" label={t(TRANSLATION_KEY.email)}>
                  <Input />
                </Form.Item>
                <Form.Item name="emailNote" label={t(TRANSLATION_KEY.note)}>
                  <Input.TextArea />
                </Form.Item>
                <Form.Item style={{ display: "none" }}>
                  <Button htmlType="submit" type="primary">
                    {t(TRANSLATION_KEY.save)}
                  </Button>
                </Form.Item>
              </Form>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Supplier Email */}

        {/* CTA */}
        <Form.Item>
          <Button loading={loading} htmlType="submit" type="primary">
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form.Item>
      </Form>
      {/* <Modal
        title={t(TRANSLATION_KEY.emailForSendOrderToSupplier)}
        footer={null}
        visible={supplierEmailVisible}
        closable
        onCancel={() => {
          unstable_batchedUpdates(() => {
            set_supplierEmailVisible(false);
          }, []);
        }}
      >
        <Form
          form={emailForm}
          layout="vertical"
          onFinish={() => {
            set_supplierEmailVisible(false);
          }}
        >
          <Form.Item name="sendEmail" label={t(TRANSLATION_KEY.email)}>
            <Input />
          </Form.Item>
          <Form.Item name="emailNote" label={t(TRANSLATION_KEY.note)}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {t(TRANSLATION_KEY.save)}
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  );
};

export default AddExecutorToOrder;
