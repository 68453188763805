import { useEffect, useState } from "react";
// UI
import styles from "./styles.module.css";
import { AnimatePresence } from "framer-motion";
import Verification from "./components/Verification";
import Header from "./components/Header";
import Order from "./components/Order";
import i18next from "i18next";
import { Spin } from "antd";
import { useAppDispatch } from "../../hooks";
import { userSlice } from "../../store/reducers/user";
import moment from "moment";
import { I18N_LANGUAGES, Languages, MOMENT_LANGUAGES } from "../../helpers/consts";

type IProps = {};

const OpenOrder = (props: IProps) => {
  // Variables
  const [verified, set_verified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Methods
  useEffect(() => {
    if (!i18next.isInitialized) {
      i18next.on("initialized", () => setIsLoading(false));
    }
  }, [i18next.isInitialized]);

  const dispatch = useAppDispatch();

  const params = new URLSearchParams(window.location.search);

  let lang: any = params.get("lang") as Languages;
  if (!lang) {
    lang = i18next.language || navigator.language?.split("-")[0] || "en";
  }

  useEffect(() => {
    dispatch(userSlice.actions.setLoadingLanguage(true));
    moment.locale(MOMENT_LANGUAGES[lang]);
    i18next
      .changeLanguage(I18N_LANGUAGES[lang] as Languages)
      .then(() => {
        dispatch(userSlice.actions.setLoadingLanguage(false));
      })
      .catch(() => {
        dispatch(userSlice.actions.setLoadingLanguage(false));
      });
  }, []);

  return (
    <div className={styles.mainContainer} id="openOrder">
      <Spin spinning={isLoading} className={styles.flexCenter} style={{ maxWidth: 960 }}>
        {/* Header */}
        <Header />

        <div className={styles.flexCenter} style={{ maxWidth: 960 }}>
          <AnimatePresence>
            {!verified ? (
              <Verification
                key={`not-verified-${!verified}`}
                verified={verified}
                set_verified={set_verified}
              />
            ) : (
              <Order key={`verified-${verified}`} />
            )}
          </AnimatePresence>
        </div>
      </Spin>
    </div>
  );
};

export default OpenOrder;
