import moment from "moment";
import { useEffect, useState } from "react";
import { EnterOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { useForm } from "antd/lib/form/Form";

type IProps = {
  defaultValue: moment.Duration;
  onClick?: (time: string) => void;
  disabled: boolean;
  style?: React.CSSProperties;
};

interface IForm {
  hours: number;
  minutes: number;
}

const DurationInput = ({ defaultValue, onClick, disabled, style }: IProps) => {
  // Hooks
  const [form] = useForm();
  // Methods
  async function onFinish(values: IForm) {
    let f_hours = values.hours || 0;
    let f_minutes = values.minutes || 0;
    let time = `${f_hours}:${f_minutes}:00`;

    if (onClick && moment.duration(time).toISOString() !== defaultValue.toIsoString()) {
      onClick(time);
    }
  }

  useEffect(() => {
    let hoursFromDays = defaultValue.days() * 24;
    form.setFieldsValue({
      hours: defaultValue.hours() + hoursFromDays,
      minutes: defaultValue.minutes(),
    });
  }, [defaultValue]);
  if (!style?.width) {
    style = { width: 180, ...style };
  }
  return (
    <Form
      style={{ ...style }}
      onBlur={() => onFinish(form.getFieldsValue())}
      form={form}
      layout="vertical"
      initialValues={{
        hours: defaultValue ? defaultValue.hours() : 0,
        minutes: defaultValue ? defaultValue.minutes() : 0,
      }}
    >
      <div id="duration-input" style={{ display: "flex", overflowX: "hidden", width: "100%" }}>
        {/* Hours input */}
        <motion.div initial={{ width: "50%" }}>
          <Form.Item name="hours">
            <InputNumber
              size="middle"
              disabled={disabled}
              // key={`hours:${defaultHours}`}
              // defaultValue={defaultHours}
              bordered={false}
              addonAfter={` h`}
              style={{ width: "100%" }}
              controls={false}
            />
          </Form.Item>
        </motion.div>

        {/* Minutes input */}
        <motion.div initial={{ width: "50%" }}>
          <Form.Item name="minutes">
            <InputNumber
              disabled={disabled}
              // key={`minutes:${defaultMinutes}`}
              // defaultValue={defaultMinutes}
              bordered={false}
              addonAfter={` m`}
              min={0}
              max={59}
              style={{ width: "100%" }}
              controls={false}
            />
          </Form.Item>
        </motion.div>
      </div>
    </Form>
  );
};

export default DurationInput;
