import { Button, Form, Input, message } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IApiResponse } from "../models";
import { IChecklist } from "../models/settings";
import api, { failedQueue, isRefreshing } from "../services";
import { settingsSlice } from "../store/reducers/settings";

interface IProps {
  checklist: IChecklist;
  onClose: (c: IChecklist) => void;
}

const ChecklistForm: React.FC<IProps> = ({ checklist, onClose }) => {
  const { checklists } = useAppSelector((state) => state.settingsReducer);

  const [loading, set_loading] = useState(false);
  const dispatch = useAppDispatch();

  const addChecklist = async (name: string) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.post<IApiResponse<IChecklist>>(
        `maintenance/checklist/`,
        { name },
        { headers: { Authorization: "Bearer " + token } },
      );
      if (response.data.results) {
        const arr: IChecklist[] = [...checklists];
        arr.push(response.data.results);
        dispatch(settingsSlice.actions.getChecklistsSuccess({ results: arr, message: "" }));
        onClose(response.data.results);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => addChecklist(name));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onUpdate = async (name: string) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.put<IApiResponse<IChecklist>>(
        `maintenance/checklist/${checklist?.id}/`,
        { name },
        { headers: { Authorization: "Bearer " + token } },
      );
      if (response.data.results) {
        const arr: IChecklist[] = [...checklists];
        const index = arr.findIndex((item) => item.id === response.data.results?.id);
        arr.splice(index, 1, response.data.results);
        dispatch(settingsSlice.actions.getChecklistsSuccess({ results: arr, message: "" }));
        onClose(response.data.results);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onUpdate(name));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onFinish = (value: { name: string }) => {
    if (checklist?.id !== 0) {
      onUpdate(value.name);
    } else {
      addChecklist(value.name);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} initialValues={checklist}>
      {/* Input */}
      <Form.Item
        rules={[{ message: t(TRANSLATION_KEY.filedRequired), required: true }]}
        name="name"
      >
        <Input />
      </Form.Item>

      {/* Submit button */}
      <Form.Item>
        <Button loading={loading} htmlType="submit" type="primary">
          {t(TRANSLATION_KEY.save)}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChecklistForm;
