import { Spin } from "antd";
import { AnimatePresence } from "framer-motion";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Verification from "./components/Verification";
import Panel from "./Panel";
import styles from "./styles.module.css";

const RequestPanel: React.FC = ({}) => {
  // Variables
  const [verified, set_verified] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Methods
  useEffect(() => {
    if (!i18next.isInitialized) {
      i18next.on("initialized", () => setIsLoading(false));
    } else {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [i18next.isInitialized]);

  return (
    <div className={styles.mainContainer} id="requestPanel">
      <Spin spinning={isLoading} className={styles.flexCenter} style={{ maxWidth: 960 }}>
        {/* Header */}
        <Header />

        <div className={styles.flexCenter} style={{ maxWidth: 960 }}>
          <AnimatePresence>
            {!verified ? (
              <Verification
                key={`not-verified-${!verified}`}
                verified={verified}
                set_verified={set_verified}
              />
            ) : (
              <Panel key={`verified-${verified}`} />
            )}
          </AnimatePresence>
        </div>
      </Spin>
    </div>
  );
};

export default RequestPanel;
