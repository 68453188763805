import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { List, Button, Checkbox, Typography, message, Modal, Space, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PreviewButton from "../../../components/PreviewButton";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { debounce, hasPermission } from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse } from "../../../models";
import { IAccount } from "../../../models/user";
import {
  accountsXHR,
  removeAssetFromAccountXHR,
} from "../../../store/reducers/accounts/actionCreators";
import { assetSlice } from "../../../store/reducers/asstes";
import {
  getAssetAccountsXHR,
  joinAccountsToAssetXHR,
} from "../../../store/reducers/asstes/actionCreators";
import AccountModalPreview from "../../account/components/AccountModalPreview";
import RequirePermission from "../../../components/RequirePermission";

interface IAccountsProps {
  close: () => void;
}

const Accounts: React.FC<IAccountsProps> = ({ close }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { assetAccounts, asset, joinAccountsToAssetStatus } = useAppSelector(
    (state) => state.assetReducer,
  );

  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [checkedItems, set_checkedItems] = useState<number[]>([]);
  const [addAccountVisible, set_addAccountVisible] = useState<boolean>(false);
  const [search, set_search] = useState<string>("");
  const { user } = useAppSelector((state) => state.userReducer);
  const { accounts, removeAssetFromAccountStatus } = useAppSelector(
    (state) => state.accountsReducer,
  );

  useEffect(() => {
    set_checkedItems([]);
  }, [addAccountVisible]);

  useEffect(() => {
    if (hasPermission(user.account.permissions, ["manage_user"])) {
      accountsXHR(
        {
          queryParams: {
            ordering: "asc",
            limit: 9999,
            offset: 0,
            order_by: "id",
          },
        },
        dispatch,
      );
    }

    getAssetAccountsXHR({ id }, dispatch);
  }, []);

  const onSaveData = () => {
    joinAccountsToAssetXHR(
      {
        body: { asset: parseInt(id || ""), accounts: checkedItems },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => set_addAccountVisible(false),
      },
      dispatch,
    );
  };

  function checkAll(e: CheckboxChangeEvent) {
    const arr: number[] = [
      ...accounts
        .filter((acc) => !assetAccounts.filter((x) => x.id === acc.id).length)
        .map((x) => x.id),
    ];
    set_checkedItems(e.target.checked ? arr : []);
  }

  const onChangeCheck = (id: number) => {
    const index = checkedItems.findIndex((x) => x === id);
    const arr = [...checkedItems];
    if (index === -1) {
      arr.push(id);
    } else {
      arr.splice(index, 1);
    }
    set_checkedItems(arr);
  };

  const onRemoveAssetFromAccount = (account: IAccount) => {
    removeAssetFromAccountXHR(
      {
        body: { asset: parseInt(id || ""), account: account.id },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          if (data.results) {
            const arr: IAccount[] = [...assetAccounts];
            let index = arr.findIndex((x) => x.id === account.id);
            arr.splice(index, 1);
            const fakeRes: IApiResponse<IAccount[]> = {
              results: arr,
              message: "",
            };
            dispatch(assetSlice.actions.getAssetAccountsSuccess(fakeRes));
          } else {
            message.warning(t(data.message));
          }
        },
      },
      dispatch,
    );
  };

  // Search
  const debounceOnSearch = (value: string) => set_search(value);
  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);

  let data: IAccount[] = [
    ...accounts.filter((acc) => !assetAccounts.filter((x) => x.id === acc.id).length),
  ];
  let filteredAccounts = useMemo(() => {
    if (search === "") {
      return data;
    }
    let arr: IAccount[] = [...data];
    arr = arr.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
    return arr;
  }, [data, search]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          marginTop: -8,
          marginBottom: 20,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <RequirePermission
          requiredPermissionCode={["manage_user"]}
          children={
            <Button
              type="primary"
              block
              icon={<PlusOutlined />}
              onClick={() => set_addAccountVisible(true)}
            />
          }
        />
      </div>
      <List
        dataSource={assetAccounts}
        renderItem={(item: IAccount) => (
          <List.Item
            style={{ padding: 6 }}
            className="listItemHoverColor"
            extra={
              item.role.name !== "Admin" && (
                <RequirePermission
                  requiredPermissionCode={["manage_user"]}
                  children={
                    <Button
                      size="small"
                      shape="circle"
                      style={{ marginRight: 12 }}
                      loading={+removeAssetFromAccountStatus === item.id}
                      onClick={() => onRemoveAssetFromAccount(item)}
                    >
                      <CloseOutlined />
                    </Button>
                  }
                />
              )
            }
          >
            <List.Item.Meta
              title={
                <Space direction="vertical">
                  <Space>
                    <Typography.Text strong>#{item.custom_id}</Typography.Text>
                    <Typography.Text type="secondary">{t(item.role.name)}</Typography.Text>
                  </Space>
                  {hasPermission(user.account.permissions, ["manage_user"]) ? (
                    <PreviewButton
                      isActive={item.is_active}
                      title={item.name}
                      id={item.id}
                      url={`/app/humanresources/${item.id}`}
                      onClick={() => {
                        set_selectedAccount(item.id);
                        set_modalVisible(true);
                      }}
                    />
                  ) : (
                    <Typography.Text>{item.name}</Typography.Text>
                  )}
                </Space>
              }
              description={item.phone_number}
            />
          </List.Item>
        )}
      />

      <Modal
        onCancel={() => set_addAccountVisible(false)}
        footer={null}
        visible={addAccountVisible}
        title={t(TRANSLATION_KEY.accounts)}
      >
        <List
          dataSource={filteredAccounts}
          header={
            <div style={{ marginTop: -12, marginBottom: 12 }}>
              <Input
                placeholder={t(TRANSLATION_KEY.searchByName)}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: 24 }}
              />
              <Checkbox
                checked={filteredAccounts.length === checkedItems.length}
                indeterminate={
                  checkedItems.length !== 0 && checkedItems.length !== filteredAccounts.length
                }
                onChange={checkAll}
              >
                {t(TRANSLATION_KEY.selectAll)}
              </Checkbox>
            </div>
          }
          renderItem={(item: IAccount) => (
            <List.Item
              style={{ padding: "6px 8px" }}
              className="listItemHoverColor"
              extra={
                <>
                  <div style={{ display: "inline-block", height: 32 }} />
                  <Checkbox
                    onChange={() => onChangeCheck(item.id)}
                    checked={checkedItems.includes(item.id)}
                  />
                </>
              }
            >
              <List.Item.Meta
                title={
                  <PreviewButton
                    isActive={item.is_active}
                    title={item.name}
                    id={item.id}
                    url={`/app/humanresources/${item.id}`}
                    onClick={() => {
                      set_selectedAccount(item.id);
                      set_modalVisible(true);
                    }}
                  />
                }
              />
            </List.Item>
          )}
        />
        <Button
          type="primary"
          style={{ marginTop: 16 }}
          onClick={onSaveData}
          disabled={!checkedItems.length}
          loading={joinAccountsToAssetStatus === "loading"}
        >
          {t(TRANSLATION_KEY.save)}
        </Button>
      </Modal>

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => set_modalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AccountModalPreview id={selectedAccount} />
      </Modal>
    </div>
  );
};

export default Accounts;
