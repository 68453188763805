import { message } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../helpers/consts";

interface IProps {
  childern: JSX.Element;
}

const PrivateRoute: React.FC<IProps> = ({ childern }) => {
  const auth: boolean = useAppSelector((state) => !!state.userReducer.user?.token.access);

  if (!auth) {
    message.warning(t(TRANSLATION_KEY.toContiniuePleaseLogin));
  }

  return auth ? childern : <Navigate replace to="/login" />;
};

export default PrivateRoute;
