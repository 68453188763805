import { useEffect, useState } from "react";

// Antd
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";

// Translation
import { t } from "i18next";
import { CHECKLIST_TASK_TYPES, TRANSLATION_KEY } from "../helpers/consts";
import { ITask, TaskTypes } from "../models/settings";

// Components
import TagInput from "../pages/settings/pages/CheckList/components/TagInput";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { IFile } from "../models/user";
import FilesComponent from "../components/Files";
import api, { failedQueue, isRefreshing } from "../services";
import { useDispatch } from "react-redux";
import { getChecklistsXHR } from "../store/reducers/settings/actionCreator";
import {
  addSingleTaskXHR,
  addTaskToTemplateXHR,
} from "../store/reducers/maintenance/actionCreator";
import { useAppSelector } from "../hooks";
import { maintenanceSlice } from "../store/reducers/maintenance";
import { addTaskToOpenOrderXHR } from "../store/reducers/supplier/actionCreator";
import { useParams } from "react-router-dom";
import moment from "moment";
import { IApiResponse } from "../models";

interface IProps {
  checklist: number | null;
  order_info?: number;
  onClose: (tasks?: ITask) => void; // na response close da nosi taskove imore biti undefinde pa tamo updateat
  addingType: "order" | "template" | "openOrder";
}

export interface ITaskBody {
  template?: string;
  description: string;
  planned_duration: string | null;
  type: TaskTypes;
  properties?: string[];
  checklist: number | null;
  order_info?: number;
}

type ITaskForm = Omit<ITaskBody, "planned_duration"> & { hh: string; mm: string };

export default function TaskForm({ checklist, onClose, order_info, addingType }: IProps) {
  // Hooks
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const open_order_token = useParams().token;
  const { id } = useParams();

  // Variables
  const [loading, set_loading] = useState<boolean>(false);
  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);

  const { maintenanceDetails, addSingleTaskStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  // Methods
  async function onFinish(values: ITaskForm) {
    let code = await localStorage.getItem(open_order_token || "");
    if (addingType === "openOrder" && !code) {
      return;
    }

    if (values.mm && !values.hh) {
      values.hh = "0";
    } else if (!values.mm && values.hh) {
      values.mm = "0";
    }

    let form_values: ITaskBody = {
      ...values,
      type: values.type || null,
      properties: values.type === "select" ? values.properties : [],
      planned_duration: !values.mm && !values.hh ? null : values.hh + ":" + values.mm + ":" + "0",
      checklist,
      order_info,
    };

    let form_values_open_order: (ITaskBody & { code: string; token: string }) | null = null;

    if (open_order_token) {
      form_values_open_order = { ...form_values, code: code || "", token: open_order_token };
    } else if (addingType === "template") {
      form_values.template = id;
    }

    let token = await localStorage.getItem("token");
    let formData = new FormData();

    if (open_order_token) {
      formData.append("data", JSON.stringify(form_values_open_order));
    } else {
      formData.append("data", JSON.stringify(form_values));
    }

    files.forEach((element: RcFile) => {
      formData.append("files", element);
    });

    if (addingType === "template") {
      addTaskToTemplateXHR(
        {
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData), 3),
          successCallback: () => onClose(),
          body: formData,
        },
        dispatch,
      );

      return;
    }

    if (addingType === "openOrder") {
      if (!open_order_token) {
        message.error(t(TRANSLATION_KEY.openOrderTokenError));
        return;
      }
      addTaskToOpenOrderXHR(
        {
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData), 3),
          successCallback: (data) => (onClose(), console.log("DATA => ", data)),
          body: formData,
        },
        dispatch,
      );

      return;
    }

    if (!!order_info) {
      addSingleTaskXHR(
        {
          body: formData,
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData), 3),
          successCallback: (data) => {
            if (data.results) {
              let tmp = { ...maintenanceDetails };
              tmp.tasks = data.results;
              dispatch(
                maintenanceSlice.actions.getMaintenanceSuccess({ message: "", results: tmp }),
              );
              onClose();
            }
          },
        },
        dispatch,
      );
      return;
    }

    try {
      set_loading(true);
      api
        .post<IApiResponse<ITask>>("/maintenance/checklist_task/", formData, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          onClose(res.data.results);
          message.success(t(TRANSLATION_KEY.successOnSaveData));
          set_loading(false);
        })
        .catch((err) => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData), 3);
          set_loading(false);
        });
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onFinish(values));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
      set_loading(false);
    }
  }

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  return (
    <div id="taskForm">
      {/* Form */}
      <Form layout="vertical" form={form} onFinish={onFinish}>
        {/* Description */}
        <Form.Item
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          label={t(TRANSLATION_KEY.description)}
          name="description"
        >
          <Input.TextArea rows={5} maxLength={512} />
        </Form.Item>

        {/* Planned duration */}

        <Space>
          <Form.Item label={t(TRANSLATION_KEY.duration)} style={{ marginBottom: 0 }}>
            <Space>
              <Form.Item name="hh">
                <InputNumber style={{ width: 99 }} addonAfter="h" min={0} />
              </Form.Item>
              <Form.Item name="mm">
                <InputNumber style={{ width: 99 }} addonAfter="m" max={59} min={0} />
              </Form.Item>
            </Space>
          </Form.Item>
          <div style={{ width: 45 }} />
          <Form.Item
            // rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
            label={t(TRANSLATION_KEY.type)}
            name="type"
          >
            <Select style={{ width: 180 }} allowClear>
              {CHECKLIST_TASK_TYPES.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {t(item)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Space>

        {/* Type */}

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
        >
          {({ getFieldValue }) => (
            <Form.Item
              rules={[
                {
                  required: getFieldValue("type") === "select",
                  message: t(TRANSLATION_KEY.filedRequired),
                },
              ]}
              label={t(TRANSLATION_KEY.properties)}
              name="properties"
              style={{ display: getFieldValue("type") === "select" ? "block" : "none" }}
            >
              <TagInput />
            </Form.Item>
          )}
        </Form.Item>

        <Divider />

        <Form.Item>
          <Upload
            multiple={true}
            beforeUpload={beforeUpload}
            showUploadList={false}
            // styled in index.css
          >
            <div className="spaceBetweenRow">
              {t(TRANSLATION_KEY.documents)}
              <Button icon={<UploadOutlined />} shape="circle" type="primary" />
            </div>
          </Upload>
        </Form.Item>

        {/* Files list */}
        <FilesComponent
          files={previewFiles}
          onDelete={onRemove}
          hideEditButton
          size="small"
          layout="list"
        />

        <Divider />

        {/* Submit button */}
        <Form.Item>
          <Button
            loading={loading || addSingleTaskStatus === "loading"}
            htmlType="submit"
            type="primary"
          >
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
