import { Button, Form, message, Select, Spin } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IApiResponse } from "../models";
import { IMaintenance, IMaintenanceDetails, ITemplateDetails } from "../models/maintenances";
import { ITask } from "../models/settings";
import api, { failedQueue, isRefreshing } from "../services";
import { maintenanceSlice } from "../store/reducers/maintenance";
import { getChecklistsXHR } from "../store/reducers/settings/actionCreator";

interface IProps {
  close: () => void;
  template?: ITemplateDetails;
  maintenance?: IMaintenanceDetails;
}

const AddCheckListToOrder: React.FC<IProps> = ({ close, template, maintenance }) => {
  const dispatch = useAppDispatch();
  const { getChecklistsStatus, checklists } = useAppSelector((state) => state.settingsReducer);
  const { templateDetails } = useAppSelector((state) => state.maintenanceReducer);

  const [selected, set_selected] = useState<number | null>(null);
  const [loading, set_loading] = useState<boolean>(false);

  useEffect(() => {
    getChecklistsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  const onSave = async () => {
    set_loading(true);
    let token = localStorage.getItem("token");
    let data = template
      ? { order_template: templateDetails?.id, checklist: selected }
      : { checklist: selected, order_info: maintenance?.id };
    try {
      let response = await api.post<IApiResponse<ITask[]>>(
        `/maintenance/${template ? "add_checklist_to_order_template" : "add_checklist_to_order"}/`,
        data,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (response.data.results) {
        if (template) {
          dispatch(
            maintenanceSlice.actions.getTemplateDetailsSuccess({
              message: "",
              results: {
                ...template,
                tasks: response.data.results,
              },
            }),
          );
        } else if (maintenance) {
          dispatch(
            maintenanceSlice.actions.getMaintenanceSuccess({
              results: {
                ...maintenance,
                tasks: response.data.results,
              },
              message: "",
            }),
          );
        }

        close();
      }
    } catch (error: any | undefined) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onSave());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_loading(false);
  };

  return (
    <>
      <Spin spinning={getChecklistsStatus === "loading"}>
        <div className="spaceBetweenRow">
          <Form
            layout="inline"
            onFinish={() => {
              onSave();
              console.log(2);
            }}
          >
            <Form.Item noStyle>
              <Select
                style={{ width: 180 }}
                onChange={(value) => {
                  set_selected(value);
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
              >
                {checklists.map((x) => (
                  <Select.Option value={x.id} key={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle>
              <Button
                loading={loading}
                style={{ marginLeft: 12 }}
                htmlType="submit"
                disabled={!selected}
                type="primary"
              >
                {t(TRANSLATION_KEY.save)}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default AddCheckListToOrder;
