import { useAppSelector } from "../../../hooks";
import { IMaintenanceDetails } from "../../../models/maintenances";
import UserReportsComp from "../components/userreports";

interface IProps {
  fromModal?: boolean;
  maintenanceDetails: IMaintenanceDetails;
  isOpenOrder: boolean;
}

const UserReports: React.FC<IProps> = ({ isOpenOrder, maintenanceDetails, fromModal = false }) => {
  return (
    <div>
      <UserReportsComp
        isOpenOrder={isOpenOrder}
        maintenance={maintenanceDetails}
        fromModal={fromModal}
      />
    </div>
  );
};

export default UserReports;
