import { Avatar, List } from "antd";
import moment from "moment";
import { getAvatarColor, getInitials } from "../../helpers/functions";
import { useMemo, useState } from "react";
import { IUserNotification } from "../../models/user";
import { useNavigate } from "react-router-dom";

type IProps = {
  item: IUserNotification;
  onClose: () => void;
};

const NotificationItem = ({ item, onClose }: IProps) => {
  // Hooks
  const navigate = useNavigate();

  // Variables
  const [canNavigate] = useState(
    item.notification.data?.type === "order_info" && item.notification.data.id,
  );

  // Methods
  function handleOnClick() {
    if (canNavigate) {
      navigate(`/app/maintenances/${item.notification.data?.id}`, { replace: true });
      onClose();
    }
  }

  // Content
  const avatarColor = useMemo(() => {
    return getAvatarColor(item.notification.sender?.name.toUpperCase() || "");
  }, [item.notification.sender?.avatar]);

  const avatarInitials = useMemo(() => {
    return getInitials(item.notification.sender?.name);
  }, [item.notification.sender?.avatar]);

  return (
    <List.Item
      onClick={handleOnClick}
      style={{
        backgroundColor: item.seen ? "#ffffff00" : "#91d5ff20",
        marginBottom: 8,
        border: "none",
        padding: "16px",
        borderRadius: 4,
        cursor: canNavigate ? "pointer" : "default",
      }}
    >
      <List.Item.Meta
        style={{ width: "100%" }}
        avatar={<Avatar style={{ backgroundColor: avatarColor }}>{avatarInitials}</Avatar>}
        title={
          <div style={{ display: "flex", flexDirection: "column", marginTop: -4 }}>
            <div style={{ display: "flex", gap: 4, fontSize: 14 }}>
              <div style={{ opacity: 0.5 }}>{item.notification.sender?.name}</div>
              <div
                style={{ color: "#0353ef", fontSize: 12, display: "flex", gap: 4, marginLeft: 8 }}
              >
                <div style={{ fontSize: 5 }}>●</div>
                {moment(item.notification.created_at).format()}
              </div>
            </div>
            <div>{item.notification.header}</div>
          </div>
        }
        description={item.notification.text}
      />
    </List.Item>
  );
};

export default NotificationItem;
