import { DatePicker, Select } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useEffectOnce } from "react-use";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppSelector } from "../hooks";

export type CalendarTypes = "month" | "date" | "quarter" | "year" | "week";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  onChange: (start: string, end: string, type?: CalendarTypes) => void;
  start?: string;
  end?: string;
  type: CalendarTypes;
  wraperStyle?: React.CSSProperties;
  pickerStyle?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  skipInitial: boolean;
  allowClear?: boolean;
}

const DatePickerCustom: React.FC<IProps> = ({
  onChange,
  start,
  end,
  type,
  wraperStyle,
  pickerStyle,
  selectStyle,
  dropdownStyle,
  skipInitial,
  allowClear,
}) => {
  // Variables
  const [initial, setInitial] = useState(skipInitial);
  const { user } = useAppSelector((state) => state.userReducer);
  const [calendarType, set_calendarType] = useState<CalendarTypes>(type);
  const [calendarValue, set_calendarValue] = useState<{
    start: string;
    end: string;
  }>({
    start: start === undefined ? moment().startOf(type).toISOString(true) : start,
    end: end === undefined ? moment().endOf(type).toISOString(true) : end,
  });
  const [rangePickerTypes, setRangePickerTypes] = useState([
    { text: t(TRANSLATION_KEY.week), value: "week" },
    { text: t(TRANSLATION_KEY.month), value: "month" },
    { text: t(TRANSLATION_KEY.year), value: "year" },
    { text: t(TRANSLATION_KEY.quarter), value: "quarter" },
    { text: t(TRANSLATION_KEY.date), value: "date" },
  ]);

  useEffect(() => {
    setRangePickerTypes([
      { text: t(TRANSLATION_KEY.week), value: "week" },
      { text: t(TRANSLATION_KEY.month), value: "month" },
      { text: t(TRANSLATION_KEY.year), value: "year" },
      { text: t(TRANSLATION_KEY.quarter), value: "quarter" },
      { text: t(TRANSLATION_KEY.date), value: "date" },
    ]);
  }, [user.account.language]);

  useEffectOnce(() => {
    setTimeout(() => {
      setInitial(false);
    }, 500);
  });

  const validateDate = (date: string) => {
    if (date === "Invalid date") {
      return "";
    }
    return date;
  };

  useEffect(() => {
    // Start date
    let start: string =
      calendarType === "date"
        ? validateDate(moment(calendarValue.start).toISOString())
        : validateDate(moment(calendarValue.start).startOf(calendarType).toISOString(true));
    // End date
    let end: string =
      calendarType === "date"
        ? validateDate(moment(calendarValue.end).toISOString())
        : validateDate(moment(calendarValue.end).endOf(calendarType).toISOString(true));
    // Calling onChange callback

    if (!initial) {
      onChange(start, end, calendarType);
    }
  }, [calendarValue, calendarType]);

  return (
    <div style={wraperStyle}>
      <Select
        dropdownStyle={dropdownStyle}
        placeholder="Tip kalendara"
        style={selectStyle}
        value={calendarType}
        onClear={() => set_calendarType("date")}
        onSelect={(x: CalendarTypes) => {
          unstable_batchedUpdates(() => {
            if (x === "date") {
              set_calendarValue({
                start: moment().subtract(30, "day").toISOString(true) || "",
                end: moment().add(1, "day").toISOString(true) || "",
              });
            } else {
              set_calendarValue({
                start: moment().startOf(x).toISOString(true) || "",
                end: moment().endOf(x).toISOString(true) || "",
              });
            }

            set_calendarType(x);
          }, []);
        }}
      >
        {rangePickerTypes.map((x) => (
          <Option key={x.value} value={x.value}>
            {x.text}
          </Option>
        ))}
      </Select>

      {calendarType === "date" ? (
        <RangePicker
          allowClear={allowClear}
          format={user.account.date_format || "DD.MM.YYYY - HH:mm"}
          value={
            calendarValue.start
              ? [moment(calendarValue.start), moment(calendarValue.end)]
              : undefined
          }
          style={{ ...pickerStyle, width: 360 }}
          placeholder={undefined}
          picker={calendarType}
          onChange={(a, b) => {
            console.log(a, b);
            if (!a) {
              set_calendarValue({
                start: "",
                end: "",
              });
              return;
            }
            set_calendarValue({
              start: a[0]?.toISOString(true) || "",
              end: a[1]?.toISOString(true) || "",
            });
          }}
        />
      ) : (
        <DatePicker
          allowClear={allowClear}
          style={{ ...pickerStyle, width: 360 }}
          picker={calendarType}
          placeholder={undefined}
          value={calendarValue.start ? moment(calendarValue.start) : undefined}
          onChange={(a) => {
            set_calendarValue({
              start: a?.startOf(calendarType).toISOString(true) || "",
              end: a?.endOf(calendarType).toISOString(true) || "",
            });
          }}
        />
      )}
    </div>
  );
};

export default DatePickerCustom;
