import { FC, useState, useCallback, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

// Components
import Grid from "./Grid";
import SortableItem from "./Item";
import Item from "./Item";

// Types
interface IProps {
  data: any[];
  renderItem: (item: any, index: number) => JSX.Element;
  onDragEnd: (id: number, index: number) => void;
  gridColumns?: number;
  gridColumnSize?: number;
  gridGap?: number;
  itemStyle?: React.CSSProperties;
}

const SortableList: FC<IProps> = ({
  data,
  gridColumns,
  gridColumnSize,
  gridGap = 0,
  itemStyle,
  onDragEnd,
  renderItem,
}) => {
  // Variables
  const [items, setItems] = useState<any[]>(data);
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor),
  );

  // Methods
  useEffect(() => {
    console.log("data changed");
    setItems(data);
  }, [data]);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      let newIndex: number = over?.data && over.data.current?.sortable.index;
      onDragEnd(Number(active.id), newIndex);
    }
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Grid columnSize={gridColumnSize} gridGap={gridGap}>
          {items.map((item, index) => (
            <SortableItem key={item.id} id={item.id}>
              {renderItem(item, index)}
            </SortableItem>
          ))}
        </Grid>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
        {activeId ? (
          <Item id={activeId.toString()} isDragging style={{ ...itemStyle }}>
            {renderItem(
              items.find((item) => item.id === activeId),
              0,
            )}
          </Item>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SortableList;
