import { TreeSelect, TreeSelectProps, message } from "antd";
import React, { useEffect } from "react";
import { createTreeSelect, setItemsWithoutParentToHighestNode } from "../helpers/functions";
import { IAsset } from "../models/asset";
import { TRANSLATION_KEY } from "../helpers/consts";
import { t } from "i18next";
import { getAssetXHR } from "../store/reducers/asstes/actionCreators";
import { useAppDispatch, useAppSelector } from "../hooks";
import { SelectTreeItem } from "../models";

interface IProps {
  treeProps: TreeSelectProps;
  onChange: (value: number[] | number | undefined) => void;
  disabled?: boolean;
  includeWithoutAsset: boolean;
}

export const TREE_SELECT_LOADING = "treeSelectLoading";

const AssetTreeSelect: React.FC<IProps> = ({
  treeProps,
  onChange,
  disabled,
  includeWithoutAsset,
}) => {
  const dispatch = useAppDispatch();

  const [value, set_value] = React.useState<number[] | number>(treeProps.value || []);

  const { assetList } = useAppSelector((state) => state.assetReducer);

  const [loading, set_loading] = React.useState<boolean>(false);
  const [tree, set_tree] = React.useState<SelectTreeItem[]>([]);

  useEffect(() => {
    console.log(assetList);
    setTree(assetList, includeWithoutAsset);
  }, [assetList]);

  useEffect(() => {
    set_value(treeProps.value);
  }, [treeProps.value]);

  const getAsset = () => {
    // search za sada lokalno dok nema load mora
    if (assetList.length > 0) {
      return;
    }
    set_loading(true);
    getAssetXHR(
      {
        loading: TREE_SELECT_LOADING,
        errorCallback: (error) => {
          console.log(error);
          set_loading(false);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback: (res) => {
          if (!res.results) {
            return;
          }
          set_loading(false);
        },
      },
      dispatch,
    );
  };

  const setTree = (data: IAsset[], includeWithoutAsset: boolean) => {
    let ready_data: IAsset[] = setItemsWithoutParentToHighestNode(data);
    let tmp = [...ready_data];
    if (includeWithoutAsset) {
      tmp.push({
        id: 0,
        name: t(TRANSLATION_KEY.withoutAsset),
        parent_id: null,
        path: t(TRANSLATION_KEY.withoutAsset),
      } as IAsset);
    }
    let tree = createTreeSelect(
      tmp.map((x) => ({
        title: x.name,
        value: x.id.toString(),
        parent_id: x.parent_id?.toString() || null,
        disabled: false,
      })),
      null,
    );

    set_tree(tree);
  };

  useEffect(() => {
    getAsset();
  }, []);

  return (
    <TreeSelect
      disabled={disabled}
      filterTreeNode={(search: string, item: any) => {
        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      }}
      onChange={(a, b, c) => {
        if (treeProps.multiple === false) {
          onChange(c.triggerValue ? +c.triggerValue : undefined);
          return;
        }
        if (!Array.isArray(value)) {
          return;
        }
        let tmpArr: number[] = [...value];

        if (c.triggerValue === undefined) {
          set_value([]);
          onChange([]);
          return;
        }
        let index = tmpArr.findIndex((x) => x === Number(c.triggerValue));

        if (index === -1) {
          tmpArr.push(+c.triggerValue);
        } else {
          if (index > -1) {
            tmpArr.splice(index, 1);
          }
        }
        set_value(tmpArr);
        onChange(tmpArr);
      }}
      value={Array.isArray(value) ? value.map((x) => x.toString()) : value?.toString()}
      loading={loading}
      treeData={tree}
      style={{
        minWidth: 210,
        ...treeProps.style,
      }}
      allowClear={treeProps.allowClear}
      placeholder={treeProps.placeholder}
      treeCheckable={treeProps.treeCheckable}
      showCheckedStrategy={"SHOW_ALL"}
      treeCheckStrictly={treeProps.treeCheckStrictly}
      treeDefaultExpandAll={treeProps.treeDefaultExpandAll}
      maxTagCount={treeProps.maxTagCount}
      multiple={treeProps.multiple}
      bordered={treeProps.bordered}
      {...treeProps}
    />
  );
};

export default AssetTreeSelect;
