import React from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { AppRoutes, AuthRoutes, OpenOrderRoutes, RequestPanelRoutes } from "./routes";
const RoutesGenerator = (): React.ReactElement<
  any,
  string | React.JSXElementConstructor<any>
> | null => {
  const auth: boolean = useAppSelector((state) => !!state.userReducer.user?.token.access);
  const { user } = useAppSelector((state) => state.userReducer);

  return useRoutes(
    [
      AppRoutes(user.account?.permissions?.map((x) => x.codename) || "allroutes"),
      AuthRoutes(auth),
      OpenOrderRoutes(),
      RequestPanelRoutes(),
    ],
    "",
  );
};

export default RoutesGenerator;
