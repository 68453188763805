import { useEffect, useState } from "react";

// Antd
import { ClockCircleOutlined, DeleteOutlined, FileOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Tag, Tooltip } from "antd";
import styles from "../checklist.module.css";

// Translation
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";

// Rest
import { useDispatch } from "react-redux";
import { IChecklist, ITask } from "../../../../../models/settings";
import api from "../../../../../services";
import { getChecklistsXHR } from "../../../../../store/reducers/settings/actionCreator";
import { motion } from "framer-motion";
import { CardVariant } from "../";
import FilesComponent from "../../../../../components/Files";
import { parseTaskDuration } from "../../../../../helpers/functions";

type IProps = {
  task: ITask;
  index: number;
  onDelete: () => void;
};

export default function TaskCard({ task, index, onDelete }: IProps) {
  // Variables
  const [variant, set_variant] = useState<CardVariant>("hidden");

  function onCardClick() {
    if ((task.files && task.files.length > 0) || (task.properties && task.properties.length > 0)) {
      set_variant((previousState) => (previousState === "hidden" ? "visible" : "hidden"));
    }
  }

  // Content
  const properties = task.properties?.length ? (
    <div style={{ marginTop: 20, maxWidth: 200 }}>{task.properties.join(", ")}</div>
  ) : null;

  return (
    <motion.div
      onClick={onCardClick}
      id="taskCard"
      className={styles.cardContainer}
      initial="hidden"
      animate={variant}
      variants={containerVariants}
      transition={{ ease: "anticipate", duration: 0.5 }}
    >
      {/* Title */}
      <div className="spaceBetweenRow">
        <h3 className={styles.cardTitle}>
          {/* Type */}
          <span className={styles.cardType}>{index + 1}. </span>
          {/* Description */}
          {task.description}
        </h3>
        <div onClick={(event) => event.stopPropagation()}>
          <Popconfirm
            onConfirm={(event) => (event?.stopPropagation(), onDelete())}
            title={t(TRANSLATION_KEY.continueWithAction)}
            cancelText={t(TRANSLATION_KEY.no)}
            okText={t(TRANSLATION_KEY.yes)}
            placement="top"
          >
            <Tooltip title={t(TRANSLATION_KEY.delete)} placement="bottom">
              <Button type="link" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </div>
      </div>

      <Space size="small">
        {task.type && (
          <Tag
            style={{
              padding: "2px 8px",
              border: "none",
              borderRadius: 40,
              backgroundColor: "#ddd",
              color: "#333",
              fontWeight: 500,
              fontSize: 10,
            }}
          >
            {t(TRANSLATION_KEY[task.type])}
          </Tag>
        )}
        {task.planned_duration && (
          <Tag
            color="blue"
            style={{
              padding: "2px 8px",
              border: "none",
              borderRadius: 40,
              fontWeight: 500,
              fontSize: 10,
            }}
          >
            {parseTaskDuration(task.planned_duration)}
          </Tag>
        )}
      </Space>

      {/* Properties */}
      {task.properties && (
        <motion.div
          initial="hidden"
          animate={variant}
          variants={propertyVariants}
          transition={{ ease: "anticipate", duration: 0.5 }}
          style={{ overflowY: "hidden", y: -10 }}
        >
          {properties}
        </motion.div>
      )}

      {/* Files */}
      <motion.div
        initial="hidden"
        animate={variant}
        variants={filesVariants}
        transition={{ ease: "anticipate", duration: 0.5 }}
        style={{ y: 4, marginTop: 10 }}
        onClick={(event) => event.stopPropagation()}
      >
        <FilesComponent
          size="tiny"
          files={task.files || []}
          onDelete={() => console.log("On delete")}
          hideButtons
        />
      </motion.div>
    </motion.div>
  );
}

const propertyVariants = {
  visible: {
    height: "max-content",
    opacity: 1,
  },
  hidden: {
    height: 0,
    opacity: 0,
  },
};

const containerVariants = {
  visible: {
    paddingBottom: 4,
  },
  hidden: {
    paddingBottom: 8,
  },
};

const filesVariants = {
  visible: {
    height: "max-content",
    opacity: 1,
  },
  hidden: {
    height: 0,
    opacity: 0,
  },
};
