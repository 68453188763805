import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import CustomFieldFormV2, {
  FormModal,
  FormType,
  getFormSetup,
} from "../../../../../componentsform/CustomFieldsFormV2";
import { useAppSelector } from "../../../../../hooks";
import { CustomFieldV2 } from "../../../../../models/settings";
import Asset from "./Asset";

const { TabPane } = Tabs;

const Clients: React.FC = () => {
  const [modalVisible, set_modalVisible] = useState<FormModal>();

  const { categories } = useAppSelector((state) => state.assetReducer);

  const [activeKey, set_activeKey] = useState<FormType>("asset");

  let formSetup = useMemo(
    () => getFormSetup(modalVisible?.type as FormType, modalVisible?.customField, categories),
    [activeKey, modalVisible, categories],
  );

  return (
    <div>
      <CustomFieldFormV2
        modalProps={{
          visible: modalVisible !== undefined,
          onCancel: () => set_modalVisible(undefined),
          title: formSetup.title,
          destroyOnClose: true,
        }}
        formType={modalVisible?.type || "asset"}
        data={modalVisible?.customField}
        condition={formSetup.condition}
      />
      <Tabs
        size="small"
        tabPosition="top"
        defaultActiveKey="asset"
        tabBarExtraContent={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              set_modalVisible({
                type: activeKey,
                customField: {} as CustomFieldV2,
              });
            }}
            type="primary"
            shape="circle"
            style={{ marginBottom: 8, float: "right" }}
          />
        }
      >
        <TabPane tab={t(TRANSLATION_KEY.asset)} key="asset">
          <Asset onEdit={set_modalVisible} assetCategories={categories} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Clients;
