import {
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Tree,
  Typography,
  message,
} from "antd";
import { t } from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../components/SelectTagAdd";
import { TRANSLATION_KEY } from "../helpers/consts";
import { debounce, parseBaseAssetListParents } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IApiResponse, ILocation } from "../models";
import { BaseListItem, ClientAssetType } from "../models/asset";
import { IClient } from "../models/clients";
import { TreeNodeType } from "../pages/account/pages/Asset";
import { getAvailableClientAssetXHR, rentAssetXHR } from "../store/reducers/clients/actionCreators";
import { settingsSlice } from "../store/reducers/settings";
import AssetModalPreview from "../pages/assetdetails/components/AssetModalPreview";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { CustomFieldValues } from "../models/settings";

interface IProps {
  close: () => void;
  client: IClient;
}

interface useClientAssetQueryParams {
  client: number;
  search: string;
}

export interface IRentBody {
  client: number;
  rent_assets: {
    asset: number;
    location: number | null | string;
    description?: string;
  }[];
  ownership_assets: {
    asset: number;
    location: number | null | string;
  }[];
}

export interface IRentBody_V2 {
  asset: number;
  client: number;
  note: string | undefined;
  location: number | null;
  rent_order_custom_fields_v2: CustomFieldValues;
}

const RentForm: React.FC<IProps> = ({ close, client }) => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const { locations } = useAppSelector((state) => state.settingsReducer);
  const { rentAssetStatus } = useAppSelector((state) => state.clientsReducer);
  const { availableClientAsset, getAvailableClientAssetStatus } = useAppSelector(
    (state) => state.clientsReducer,
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedKeys, set_expandedKeys] = useState<React.Key[]>([]);
  const [location, set_location] = useState<string | number | undefined>(undefined);
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [checkedKeys, set_checkedKeys] = useState<React.Key[]>([]);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [addLocationVisible, set_addLocationVisible] = useState<boolean>(false);
  const [assetModalVisible, setAssetModalVisible] = useState<boolean>(false);
  const [oneDescription, set_oneDescription] = useState<
    { nodeId: string; text: string } | undefined
  >();

  const [form, setForm] = useState<IRentBody>({
    client: client.id,
    rent_assets: [],
    ownership_assets: [],
  });
  const [queryParams, setQueryParams] = useState<useClientAssetQueryParams>({
    client: client.id,
    search: "",
  });
  const buttonDisabled = useMemo(() => {
    return form.rent_assets.length === 0 && form.ownership_assets.length === 0;
  }, [form]);

  // Functions
  function handleOpenModal(id: string | number | undefined) {
    unstable_batchedUpdates(() => {
      set_modalVisible(true);
      set_selectedAsset(id);
    }, []);
  }

  useEffect(() => {
    fetchClientAsset(queryParams);
  }, [client]);

  useEffect(() => {
    set_expandedKeys([]);
  }, [availableClientAsset]);

  const onCheck: any = (checkedKeysValue: any) => {
    set_checkedKeys(checkedKeysValue.checked);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    set_expandedKeys(expandedKeysValue);
  };

  function onChange(clientAssetType: ClientAssetType, nodeData: TreeNodeType) {
    const { rent_assets, ownership_assets } = form;
    const asset = Number(nodeData.id);

    if (clientAssetType === "ownership") {
      // If the selected value is "ownership", remove the asset from rent_assets
      const rentAssetIndex = rent_assets.findIndex((item) => item.asset === asset);
      const ownershipAssetIndex = ownership_assets.findIndex((item) => item.asset === asset);
      if (rentAssetIndex !== -1) {
        rent_assets.splice(rentAssetIndex, 1);
      }
      if (ownershipAssetIndex !== -1) {
        ownership_assets.splice(ownershipAssetIndex, 1);
      } else {
        ownership_assets.push({ asset, location: location || null });
      }

      // Add the asset to ownership_assets
    } else {
      // If the selected value is not "ownership", remove the asset from ownership_assets
      const ownershipAssetIndex = ownership_assets.findIndex((item) => item.asset === asset);
      const rentAssetIndex = rent_assets.findIndex((item) => item.asset === asset);
      if (ownershipAssetIndex !== -1) {
        ownership_assets.splice(ownershipAssetIndex, 1);
      }
      if (rentAssetIndex !== -1) {
        rent_assets.splice(rentAssetIndex, 1);
      } else {
        rent_assets.push({ asset, location: location || null });
      }

      // Add the asset to rent_assets
    }

    // Update the form state with the modified arrays
    setForm({
      ...form,
      rent_assets: [...rent_assets],
      ownership_assets: [...ownership_assets],
    });
  }

  function fetchClientAsset(queryParams: useClientAssetQueryParams) {
    setLoading(true);
    console.log(queryParams);
    getAvailableClientAssetXHR(
      {
        queryParams: {
          available_search: queryParams.search,
          ...queryParams,
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          setLoading(false);
        },
        successCallback: () => {
          setLoading(false);
        },
      },
      dispatch,
    );
  }

  const debouncedGetClientAsset = useCallback(
    debounce((queryParams: useClientAssetQueryParams) => {
      fetchClientAsset(queryParams);
    }, 600),
    [],
  );

  function onSearch(value: string) {
    debouncedGetClientAsset({
      ...queryParams,
      search: value,
    });
  }

  const onRentAsset = () => {
    const body: IRentBody = {
      client: form.client,
      ownership_assets: form.ownership_assets.map((item) => ({
        ...item,
        location: location || null,
      })),
      rent_assets: form.rent_assets.map((item) => ({
        ...item,
        location: location || null,
      })),
    };

    rentAssetXHR(
      {
        body,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          alert("Refreš liste naloga potrebno odradit");
          // refreshRentOrders([
          //   ...form.ownership_assets.map((x) => x.asset),
          //   ...form.rent_assets.map((x) => x.asset),
          // ]);
          close();
        },
      },
      dispatch,
    );
  };

  const tree = useMemo(
    () =>
      createCheckableBaseAssetTree(
        parseBaseAssetListParents(availableClientAsset),
        null,
        [],
        handleOpenModal,
      ),
    [availableClientAsset, form],
  );

  return (
    <>
      {/* Input */}
      <div
        style={{
          height: "60vh",
          width: "100%",
          position: "relative",
        }}
      >
        {/* Data */}
        <div style={{ width: "100%" }}>
          <Space
            direction="horizontal"
            align="center"
            style={{ justifyContent: "space-between", width: "100%", position: "relative" }}
          >
            <Select
              value={location}
              style={{ width: 240 }}
              allowClear={true}
              onChange={(value) => set_location(value)}
              optionFilterProp="children"
              filterOption={(input, option) => {
                if (typeof option?.children === "string") {
                  const str: string = option.children;
                  return str.toLowerCase().includes(input.toLowerCase());
                }
                return false;
              }}
              placeholder={t(TRANSLATION_KEY.location)}
              showSearch={true}
              notFoundContent={
                <ButtonAddSelectTag
                  addingVisible={addLocationVisible}
                  set_addingVisible={set_addLocationVisible}
                />
              }
            >
              {locations.map((x) => (
                <Select.Option value={x.id} key={x.id}>
                  {x.name}
                </Select.Option>
              ))}
              <Select.OptGroup
                label={
                  <ButtonAddSelectTag
                    addingVisible={addLocationVisible}
                    set_addingVisible={set_addLocationVisible}
                  />
                }
              />
            </Select>
            <Input
              placeholder={t(TRANSLATION_KEY.searchByName)}
              style={{ marginBottom: 0, width: 240 }}
              onChange={({ target: { value } }) => onSearch(value.toLowerCase())}
              allowClear
            />
          </Space>
        </div>
        <Divider />
        <Spin
          spinning={getAvailableClientAssetStatus === "loading" || loading}
          style={{ width: "100%" }}
        >
          <div style={{ width: "100%", overflowY: "auto", maxHeight: "calc(60vh - 100px)" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                paddingRight: 12,
                marginBottom: 12,
              }}
            >
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t(TRANSLATION_KEY.asset) }}
                style={{ textAlign: "left", flex: 1, marginLeft: 28 }}
              >
                {t(TRANSLATION_KEY.asset)}
              </Typography.Text>
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t(TRANSLATION_KEY.rent) }}
                style={{ textAlign: "right" }}
              >
                {t(TRANSLATION_KEY.rent)}
              </Typography.Text>
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t(TRANSLATION_KEY.ownership) }}
                style={{ width: 120, paddingLeft: 8, textAlign: "right" }}
              >
                {t(TRANSLATION_KEY.ownership)}
              </Typography.Text>
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t(TRANSLATION_KEY.ownership) }}
                style={{ width: 244, paddingLeft: 8, textAlign: "right" }}
              >
                {t(TRANSLATION_KEY.note)}
              </Typography.Text>
            </div>
            <Tree
              // key={`tree-${availableClientAsset.length}`}
              checkStrictly={true}
              className="userAssetCheckTree"
              style={{
                backgroundColor: "transparent !important",
                width: "100%",
              }}
              onCheck={onCheck}
              onExpand={onExpand}
              checkedKeys={checkedKeys}
              expandedKeys={expandedKeys}
              checkable={false}
              selectable={false}
              defaultExpandAll={true}
              blockNode
              treeData={tree}
              titleRender={(nodeData) => {
                let item = form.rent_assets.find((y) => y.asset === Number(nodeData.id));
                let nodeRentChecked = !!item;
                return (
                  <div
                    className="spaceBetweenRow"
                    style={{
                      marginBottom: 8,
                      justifyContent: "space-between",
                      boxSizing: "border-box",
                    }}
                  >
                    {/* Additional informations */}
                    <Space size="middle" align="baseline">
                      <Typography.Text type="secondary">
                        #{nodeData.custom_id || "-"}
                      </Typography.Text>

                      <Link to={`/app/asset-details/${nodeData.id}/`} style={{ marginBottom: 8 }}>
                        {nodeData.title}
                      </Link>
                      <Typography.Text type="secondary" style={{ color: "#333" }}>
                        {t(nodeData.category || "")}
                      </Typography.Text>

                      {nodeData.location && (
                        <Typography.Text
                          type="secondary"
                          style={{
                            transform: "translateY(5px)",
                            opacity: 65,
                          }}
                        >
                          ({nodeData.location})
                        </Typography.Text>
                      )}
                    </Space>
                    <Space style={{ position: "relative" }}>
                      <Checkbox
                        checked={nodeRentChecked}
                        onClick={(e) => {
                          onChange("rent", nodeData);
                        }}
                        value={"rent" as ClientAssetType}
                      />
                      <Checkbox
                        checked={
                          form.ownership_assets.filter((y) => y.asset === Number(nodeData.id))
                            .length > 0
                        }
                        onClick={(e) => {
                          onChange("ownership", nodeData);
                        }}
                        style={{ marginLeft: 94, marginRight: 16 }}
                        value={"ownership" as ClientAssetType}
                      />

                      <Typography.Text
                        ellipsis={{ tooltip: item?.description || "" }}
                        style={{
                          width: 200,
                          visibility: nodeRentChecked ? undefined : "hidden",
                          display: "block",
                        }}
                      >
                        {item?.description || ""}
                      </Typography.Text>
                      <Button
                        type="link"
                        style={{ padding: 0, margin: 0, width: 24 }}
                        icon={<EditOutlined />}
                        onClick={() => {
                          set_oneDescription({
                            nodeId: nodeData.id || "noID",
                            text: item?.description || "",
                          });
                        }}
                        disabled={!nodeRentChecked}
                      />
                    </Space>
                  </div>
                );
              }}
            />
          </div>
        </Spin>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          disabled={buttonDisabled}
          type="primary"
          style={{ marginTop: 1 }}
          loading={rentAssetStatus === "loading"}
          onClick={() => onRentAsset()}
        >
          {t(TRANSLATION_KEY.save)}
        </Button>
      </div>
      {/* Description MOdal */}
      <Modal
        title={t(TRANSLATION_KEY.note)}
        visible={!!oneDescription}
        onCancel={() => {
          let rents = [...form.rent_assets];
          let index = rents.findIndex((x) => x.asset === Number(oneDescription?.nodeId));
          if (index !== -1) {
            rents[index].description = oneDescription?.text || "";
          }
          setForm({
            ...form,
            rent_assets: rents,
          });
          set_oneDescription(undefined);
        }}
        centered
        width={600}
        closable={true}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        okText={t(TRANSLATION_KEY.save)}
        onOk={() => {
          let rents = [...form.rent_assets];
          let index = rents.findIndex((x) => x.asset === Number(oneDescription?.nodeId));
          if (index !== -1) {
            rents[index].description = oneDescription?.text || "";
          }
          setForm({
            ...form,
            rent_assets: rents,
          });
          set_oneDescription(undefined);
        }}
      >
        <Input.TextArea
          autoFocus
          rows={6}
          value={oneDescription?.text}
          onChange={(v) => {
            set_oneDescription({
              nodeId: oneDescription?.nodeId || "noID",
              text: v.target.value || "",
            });
          }}
        />
      </Modal>
      {/* Asset preview modal */}
      <Modal
        visible={assetModalVisible}
        onCancel={() => setAssetModalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={true}
        destroyOnClose
      >
        <AssetModalPreview id={selectedAsset} />
      </Modal>

      <SelectTagAdd
        title={t(TRANSLATION_KEY.addLocation)}
        url="settings/locations/"
        set_visible={set_addLocationVisible}
        visible={addLocationVisible}
        successCallback={(tag: Tag) => {
          const data = [...locations];
          data.push({ id: tag.id, name: tag.name });
          const resFake: IApiResponse<ILocation[]> = {
            message: "",
            results: data,
          };
          dispatch(settingsSlice.actions.getLocationsSuccess(resFake));

          set_location(tag.id);
        }}
      />
    </>
  );
};

export function createCheckableBaseAssetTree(
  data: BaseListItem[],
  parentValue: number | null,
  userAsset: number[],
  handleOpenModal: (id: string | number | undefined) => void,
) {
  const treeData: TreeNodeType[] = [];
  let uniqueKey = 0;
  data.forEach((item) => {
    uniqueKey += 1;
    if (parentValue === item.parent_id) {
      treeData.push({
        children: createCheckableBaseAssetTree(
          data.filter((x) => x.parent_id !== parentValue),
          item.id,
          userAsset,
          handleOpenModal,
        ),
        custom_id: item.custom_id,
        title: (
          <Button
            type="link"
            style={{ padding: 0, marginBottom: -4 }}
            onClick={() => handleOpenModal(item.id)}
          >
            {item.name}
          </Button>
        ),
        category: item.category?.name || null,
        id: item.id.toString(),
        location: item.location?.name || null,
        key: `${item.id.toString()}-${uniqueKey}`,
        disableCheckbox: userAsset.includes(item.id),
      });
    }
  });
  return treeData;
}

export default RentForm;
