import React, { useEffect, useState } from "react";

import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Descriptions,
  Divider,
  List,
  Select,
  Space,
  message,
  Drawer,
  PageHeader,
  Tag,
} from "antd";
import i18next, { t } from "i18next";
import { DATE_FORMATS, Languages, LANGUAGES, TRANSLATION_KEY } from "../../helpers/consts";
import ChangePasword from "../../componentsform/ChangePassword";
import { useAppSelector } from "../../hooks";
import { useDispatch } from "react-redux";
import { updateUserXHR } from "../../store/reducers/user/actionCreators";
import AssetNode from "../asset/tree/asset";
import { getAssetXHR } from "../../store/reducers/asstes/actionCreators";
import { NewTreeItem } from "../../models";
import { IAsset } from "../../models/asset";
import {
  createTree,
  hasPermission,
  setItemsWithoutParentToHighestNode,
} from "../../helpers/functions";
import AssetModalPreview from "../assetdetails/components/AssetModalPreview";
import { unstable_batchedUpdates } from "react-dom";
import { useNavigate } from "react-router-dom";
import { userSlice } from "../../store/reducers/user";

const Profile: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Variables
  const [accountAssetTree, set_accountAssetTree] = useState<NewTreeItem<IAsset>[]>([]);
  const [asset, set_asset] = useState(false);
  const {
    user: { account },
  } = useAppSelector((state) => state.userReducer);
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [changePasswordVisible, set_changePasswordVisible] = useState<boolean>(false);
  const { removeAssetFromAccountStatus } = useAppSelector((state) => state.accountsReducer);

  useEffect(() => {
    getAssetXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data) => {
          if (data.results) {
            let highestNode = setItemsWithoutParentToHighestNode(data.results);
            let tree = createTree(highestNode, null);
            set_accountAssetTree(tree);
          }
        },
      },
      dispatch,
    );
  }, []);

  // Methods

  const handleDateFormatChange = (date_format: string) => {
    updateUserXHR(
      {
        id: account.id,
        body: { date_format },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => message.success(t(TRANSLATION_KEY.successOnSaveData)),
      },
      dispatch,
    );
  };

  function handleOpenModal(id: string | number | undefined) {
    unstable_batchedUpdates(() => {
      set_modalVisible(true);
      set_selectedAsset(id);
    }, []);
  }

  function setLanguage(language: Languages) {
    dispatch(userSlice.actions.setLoadingLanguage(true));
    i18next.changeLanguage(language === "bs" ? "ba" : language).then(() => {
      dispatch(userSlice.actions.setLoadingLanguage(false));
    });
    updateUserXHR(
      {
        id: account.id,
        body: { language },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: (data) => {
          message.success(t(TRANSLATION_KEY.successOnSaveData));
        },
      },
      dispatch,
    );
  }

  return (
    <>
      <PageHeader
        style={{ padding: 0, paddingTop: 10 }}
        className="custom-page-header"
        onBack={() => navigate(-1)}
        subTitle={
          <div>
            <Typography.Text type="secondary">{t(TRANSLATION_KEY.user)} </Typography.Text>
            <Typography.Text strong>#{account?.custom_id}</Typography.Text>
          </div>
        }
        extra={[
          <Button key="1" onClick={() => set_changePasswordVisible(true)}>
            {t(TRANSLATION_KEY.changePassword)}
          </Button>,
          <Button key="2" onClick={() => set_asset(true)}>
            {t(TRANSLATION_KEY.asset)}
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <Space size="large" align="center">
            <Typography.Title level={3}>{account.name}</Typography.Title>
            <Tag className="main-tag">{t(account.role.name)}</Tag>
          </Space>
          <Descriptions size="small" column={2}>
            {/* Phone number */}
            <Descriptions.Item label={t(TRANSLATION_KEY.phoneNumber)}>
              {account.phone_number || "-"}
            </Descriptions.Item>
            <Descriptions.Item label={t(TRANSLATION_KEY.email)}>
              {account.email || "-"}
            </Descriptions.Item>
            {/* Date format */}
            <Descriptions.Item
              className={"select"}
              // span={1}
              label={t(TRANSLATION_KEY.selectDateFormat)}
            >
              <Select
                bordered={false}
                style={{ width: "100%" }}
                showArrow={false}
                defaultValue={account.date_format}
                onChange={handleDateFormatChange}
              >
                {DATE_FORMATS.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Descriptions.Item>
            {/* Language */}
            {/* Date format */}
            <Descriptions.Item
              className={"select"}
              // span={1}
              label={t(TRANSLATION_KEY.selectLanguage)}
            >
              <Select
                bordered={false}
                style={{ width: "100%" }}
                showArrow={false}
                defaultValue={account.language}
                onChange={setLanguage}
              >
                {LANGUAGES.map((item, index) => (
                  <Select.Option key={index} value={item.alpha2Code}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Descriptions.Item>
          </Descriptions>
        </Space>
      </PageHeader>

      <Divider />

      <Drawer
        width={540}
        visible={asset}
        onClose={() => set_asset(false)}
        destroyOnClose={true}
        title={t(TRANSLATION_KEY.userAsset)}
      >
        <List
          dataSource={accountAssetTree}
          renderItem={(item) => (
            <AssetNode
              loading={+removeAssetFromAccountStatus}
              canDeleteAsset
              user={account}
              item={item}
              deep={0}
              handleOpenModal={handleOpenModal}
              viewAsset={hasPermission(account.permissions, ["view_asset"])}
            />
          )}
        />
      </Drawer>
      <Modal
        title={t(TRANSLATION_KEY.changePassword)}
        footer={null}
        visible={changePasswordVisible}
        onCancel={() => set_changePasswordVisible(false)}
      >
        <ChangePasword close={() => set_changePasswordVisible(false)} />
      </Modal>
      <Modal
        visible={modalVisible}
        onCancel={() => set_modalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AssetModalPreview id={selectedAsset} />
      </Modal>
    </>
  );
};

export default Profile;
