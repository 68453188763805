import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IApiResponse, IUsualExpense } from "../../../../../models";
import api, { failedQueue, isRefreshing } from "../../../../../services";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";

// Antd
import { Button, Divider, Form, Input, message, Spin, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";

// Imports
import { settingsSlice } from "../../../../../store/reducers/settings";
import { getUsualExpensesXHR } from "../../../../../store/reducers/settings/actionCreator";
import Card from "./../components/card";
import { PlusOutlined } from "@ant-design/icons";

const UsualExpenses: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [isModalVisible, set_isModalVisible] = useState<boolean>(false);
  const [search, set_search] = useState<string>("");
  const [update, set_update] = useState<IUsualExpense>();
  const [loading, set_loading] = useState<boolean>(false);
  const [usualExpenseName, set_usualExpenseName] = useState("");
  const { usualExpenses } = useAppSelector((state) => state.settingsReducer);
  const [isUpdating, set_isUpdating] = useState(false);
  const [newUsualExpense, set_newUsualExpense] = useState<string>("");

  useEffect(() => {
    getUsualExpensesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  const onDelete = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/maintenance/usual_expense/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr = [...usualExpenses];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      dispatch(settingsSlice.actions.getUsualExpensesSuccess({ results: arr, message: "" }));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onActionUpdate = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.put<IApiResponse<IUsualExpense>>(
        `/maintenance/usual_expense/${update?.id}/`,
        { name: usualExpenseName },
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        const arr = [...usualExpenses];
        const index = arr.findIndex((x) => x.id === update?.id);
        arr[index] = response.data.results;

        dispatch(settingsSlice.actions.getUsualExpensesSuccess({ results: arr, message: "" }));
        set_update(undefined);
        set_isModalVisible(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onActionUpdate());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const addUsualExpense = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.post<IApiResponse<IUsualExpense>>(
        `/maintenance/usual_expense/`,
        { name: newUsualExpense },
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        const arr = [...usualExpenses];
        arr.push(response.data.results);

        dispatch(settingsSlice.actions.getUsualExpensesSuccess({ results: arr, message: "" }));
        set_search("");
        set_newUsualExpense("");
        set_isModalVisible(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => addUsualExpense());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  let filtredData: IUsualExpense[] = usualExpenses;
  if (search)
    filtredData = usualExpenses.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div>
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 10 }}>
          {t(TRANSLATION_KEY.expenses)}
        </Typography.Title>

        {/* Search input */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <Input.Search
            value={search}
            onChange={({ target: { value } }) => set_search(value)}
            allowClear
            style={{ marginRight: 18 }}
            placeholder={t(TRANSLATION_KEY.searchExpenses)}
          />
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => {
              set_newUsualExpense("");
              set_isModalVisible(true);
              set_isUpdating(false);
            }}
            // disabled={!search}
            loading={loading}
            shape="circle"
            icon={<PlusOutlined />}
          />
        </div>
      </div>
      <Divider style={{ marginTop: 18 }} />
      <Spin spinning={loading}>
        {/* Usual expenses list */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {filtredData.map((item, i) => (
            <div key={i} style={{ marginRight: 12 }}>
              <Card
                item={item}
                onUpdate={(location: IUsualExpense) => {
                  unstable_batchedUpdates(() => {
                    set_isModalVisible(true);
                    set_update(location);
                    set_usualExpenseName(location.name);
                    set_isUpdating(true);
                  }, []);
                }}
                onDelete={onDelete}
              />
            </div>
          ))}
        </div>
      </Spin>

      {/* Update modal */}
      <Modal
        confirmLoading={loading}
        footer={null}
        visible={isModalVisible}
        closable={true}
        title={isUpdating ? t(TRANSLATION_KEY.editExpenseName) : t(TRANSLATION_KEY.addExpense)}
        onCancel={() => (set_update(undefined), set_newUsualExpense(""), set_isModalVisible(false))}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input
            value={isUpdating ? usualExpenseName : newUsualExpense}
            onChange={({ target: { value } }) => {
              if (isUpdating) {
                set_usualExpenseName(value);
              } else {
                set_newUsualExpense(value);
              }
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 24,
            }}
          >
            <Button
              type="primary"
              loading={loading}
              onClick={() => (isUpdating ? onActionUpdate() : addUsualExpense())}
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
            <Button
              onClick={() => (
                set_update(undefined), set_newUsualExpense(""), set_isModalVisible(false)
              )}
              type="dashed"
            >
              {t(TRANSLATION_KEY.return)}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UsualExpenses;
