import { Button, Popconfirm, Space, Table, TableColumnType } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { CustomFieldV2 } from "../../../../../models/settings";
import { useAppSelector } from "../../../../../hooks";
import { FormModal } from "../../../../../componentsform/CustomFieldsFormV2";
import { IPartCategory } from "../../../../../models/parts";
import CustomFieldsTable from "../Components/CustomFieldsTable";

interface IProps {
  onEdit: (data: FormModal) => void;
  categories: IPartCategory[];
}

const Items: React.FC<IProps> = ({ onEdit, categories }) => {
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  return (
    <div>
      <CustomFieldsTable
        formType="items"
        condition={categories.map((x) => ({
          ...x,
          trans_key: undefined,
        }))}
        onEdit={onEdit}
        conditionTitle={t(TRANSLATION_KEY.partsCategories)}
        customFieldsData={companyCustomFieldsV2.items}
      />
    </div>
  );
};

export default Items;
