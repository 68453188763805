import { useEffect, useState } from "react";
import { IApiResponse } from "../../../models";
import { IMaintenanceDetails } from "../../../models/maintenances";
import api from "../../../services";
import { Yousign, YousignEvent } from "./types";
import { useAppDispatch } from "../../../hooks";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { message } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";

declare global {
  interface Window {
    Yousign: Yousign;
  }
}

function useDigitalSignature(id: number) {
  // Hooks
  const dispatch = useAppDispatch();

  // State
  const [signatureLink, setSignatureLink] = useState<string | undefined>(undefined);
  const [state, setState] = useState<YousignEvent["event"] | undefined>(undefined);

  useEffect(() => {
    let yousign: Yousign | undefined = undefined;

    if (signatureLink) {
      // Instantiate Yousign object
      yousign = new window.Yousign({
        signatureLink: signatureLink,
        iframeContainerId: "iframe-container",
        isSandbox: true,
      });

      // Event handlers
      const handleEvent = async (event: YousignEvent) => {
        switch (event.event) {
          // ========================================================================= STARTED
          case "started":
            console.log("Signer has opened the signature");
            console.log(event);
            setState(event.event);
            break;
          // ========================================================================= SUCCESS
          case "success":
            console.log("Signer has successfully signed");
            // Call request
            const token = await localStorage.getItem("token");
            try {
              let response = await api.post<IApiResponse<IMaintenanceDetails>>(
                `/electronic_signature/signature_requests/sign/`,
                {
                  is_success: true,
                  signature_request_id: event.signature_request_id,
                  signer_id: event.signer_id,
                  order_info: id,
                },
                { headers: { Authorization: "Bearer " + token } },
              );
              dispatch(maintenanceSlice.actions.getMaintenanceSuccess(response.data));
            } catch (error: any) {
              console.log("🚧 - handleEvent - error:", error);
            }
            setSignatureLink(undefined);
            setState(event.event);
            break;
          // ========================================================================= ERROR
          case "error":
            console.log("Signer encountered an error when signing");
            console.log(event);
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
            break;
          // ========================================================================= PING
          case "ping":
            console.log(event);
            break;
          // ========================================================================= DECLINED
          case "declined":
            console.log(event);
            break;
          default:
            break;
        }
      };

      // Add event listeners
      yousign.onStarted(handleEvent);
      yousign.onSuccess(handleEvent);
      yousign.onError(handleEvent);
      yousign.onPing(handleEvent);
      yousign.onDeclined(handleEvent);

      // Navigate to signatureLink
      //   window.location.href = signatureLink;
    }

    // Remove event listeners when the component unmounts
    return () => {
      if (yousign) {
        yousign.removeMessageListener();
      }
    };
  }, [signatureLink]); // Re-run effect when signatureLink changes

  return {
    signatureLink,
    state,
    setSignatureLink,
  };
}

export type UseDigitalSignature = ReturnType<typeof useDigitalSignature>;
export default useDigitalSignature;
