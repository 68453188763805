import { DeleteOutlined, EditOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Drawer, List, message, Modal, Popconfirm, Typography } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";
import FilesComponent, { IFile } from "../../../components/Files";
import PreviewButton from "../../../components/PreviewButton";
import RequirePermission from "../../../components/RequirePermission";
import UserReportForm from "../../../componentsform/UserReportForm";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { hasPermission } from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IMaintenanceDetails, IUserReport } from "../../../models/maintenances";
import api, { failedQueue, isRefreshing } from "../../../services";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { supplierSlice } from "../../../store/reducers/supplier";

import AccountModalPreview from "../../account/components/AccountModalPreview";
import { MOB_SIZE } from "../../openorder/components/Order";
import moment from "moment";

interface IProps {
  maintenance: IMaintenanceDetails;
  fromModal?: boolean;
  isOpenOrder: boolean;
}

const UserReportsComp: React.FC<IProps> = ({ maintenance, isOpenOrder, fromModal = false }) => {
  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [addVisible, set_addVisible] = useState<IUserReport>();
  const [loading, set_loading] = useState(0);
  const { user } = useAppSelector((state) => state.userReducer);
  const open_order_token = useParams().token;
  const [materialLoading, set_materialLoading] = useState(0);
  const dispatch = useAppDispatch();
  const { user: currentUser } = useAppSelector((state) => state.userReducer);

  // Methods
  const deleteReport = async (id: number) => {
    set_loading(id);
    let token = await localStorage.getItem("token");
    let code = await localStorage.getItem(open_order_token || "");
    try {
      let response = api.delete<string>(
        isOpenOrder ? `maintenance/supplier_report/${id}/` : `maintenance/report/${id}/`,
        {
          data: isOpenOrder ? { code, token: open_order_token } : undefined,
          headers: isOpenOrder ? undefined : { Authorization: "Bearer " + token },
        },
      );
      let data = [...maintenance.reports];
      let index = data.findIndex((x) => x.id === id);
      data.splice(index, 1);
      if (isOpenOrder) {
        dispatch(
          supplierSlice.actions.getOpenOrderSuccess({
            message: "",
            results: { ...maintenance, reports: data },
          }),
        );
      } else {
        dispatch(
          maintenanceSlice.actions.getMaintenanceSuccess({
            message: "",
            results: { ...maintenance, reports: data },
          }),
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => deleteReport(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }

    set_loading(0);
  };

  const deleteMaterial = async (id: number, report_id: number) => {
    set_materialLoading(id);
    let token = await localStorage.getItem("token");
    let code = await localStorage.getItem(open_order_token || "");
    try {
      let response = api.delete<string>(
        `maintenance/${isOpenOrder ? "open_order_materials" : "materials"}/${id}/`,
        {
          data: isOpenOrder
            ? {
                code,
                token: open_order_token,
              }
            : undefined,
          headers: isOpenOrder ? undefined : { Authorization: "Bearer " + token },
        },
      );

      let current_maintenance: IMaintenanceDetails = { ...maintenance };
      let reports: IUserReport[] = [...current_maintenance.reports];
      let current_report: IUserReport = current_maintenance.reports.find(
        (report) => report.id === report_id,
      )!;
      let files: IFile[] = [...current_report.files];
      let current_file_index = files.findIndex((file) => file.id === id);
      let report_index = reports.findIndex((item) => item.id === current_report.id);

      // Update data
      files.splice(current_file_index, 1);
      current_report = { ...current_report, files };
      reports[report_index] = current_report;

      if (isOpenOrder) {
        dispatch(
          supplierSlice.actions.getOpenOrderSuccess({
            message: "",
            results: {
              ...maintenance,
              reports,
            },
          }),
        );
      } else {
        dispatch(
          maintenanceSlice.actions.getMaintenanceSuccess({
            message: "",
            results: {
              ...maintenance,
              reports,
            },
          }),
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => deleteMaterial(id, report_id));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }

    set_loading(0);
  };

  return (
    <>
      <List
        header={
          !fromModal ? (
            <div className="spaceBetweenRow">
              <Typography.Title level={5} style={{ paddingTop: 4 }}>
                {t(TRANSLATION_KEY.userReports)}
              </Typography.Title>
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() =>
                  set_addVisible({
                    text: "",
                    id: 0,
                    created_at: "",
                    files: [],
                    created_by_account: null,
                    created_by_supplier: null,
                  })
                }
              />
            </div>
          ) : undefined
        }
        dataSource={maintenance.reports}
        renderItem={(item) => {
          let canEditDelete =
            (item.created_by_supplier && isOpenOrder) ||
            currentUser.account.id === item.created_by_account?.id;

          if (user.account.id && hasPermission(user.account.permissions, ["manage_wo"])) {
            canEditDelete = true;
          }
          return (
            <List.Item
              actions={
                !fromModal
                  ? [
                      <div className="spaceBetweenRow">
                        {canEditDelete && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <Button
                              type="link"
                              onClick={() => set_addVisible(item)}
                              icon={<EditOutlined />}
                            />
                            <Popconfirm
                              onConfirm={() => deleteReport(item.id)}
                              title={t(TRANSLATION_KEY.continueWithAction)}
                            >
                              <Button
                                danger
                                type="link"
                                loading={loading === item.id}
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </div>
                        )}
                      </div>,
                    ]
                  : undefined
              }
            >
              <List.Item.Meta
                avatar={!isOpenOrder && <Avatar icon={<UserOutlined />} />}
                title={
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ width: isOpenOrder ? 120 : 180, fontSize: 12, opacity: 0.75 }}>
                      {moment(item.created_at).format()}
                    </div>
                    {item.created_by_supplier ? (
                      item.created_by_supplier.name
                    ) : isOpenOrder ? (
                      item.created_by_account?.name
                    ) : (
                      <PreviewButton
                        isActive={item.created_by_account?.is_active}
                        title={item.created_by_account?.name}
                        id={item.created_by_account?.id}
                        url={`/app/humanresources/${item.created_by_account?.id}`}
                        onClick={() => {
                          set_modalVisible(true);
                          set_selectedAccount(item.created_by_account?.id);
                        }}
                      />
                    )}
                  </div>
                }
                description={
                  <div>
                    <div style={{ color: "#333", fontWeight: 500 }}>{item.text}</div>
                    <div style={{ marginTop: 8 }}>
                      {!!item.files.length ? (
                        <FilesComponent
                          hideButtons={!canEditDelete}
                          onDelete={
                            !canEditDelete
                              ? () => {
                                  message.warning(t(TRANSLATION_KEY.noPerrmission));
                                }
                              : (id: number) => deleteMaterial(id, item.id)
                          }
                          files={item.files}
                          hideEditButton
                          size="tiny"
                          layout="grid"
                        />
                      ) : null}
                    </div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <Drawer
        width={window.innerWidth < MOB_SIZE ? "100%" : 540}
        title={
          addVisible?.id === 0
            ? t(TRANSLATION_KEY.newMaintenanceReport)
            : t(TRANSLATION_KEY.editMaintenanceReport)
        }
        visible={!!addVisible}
        onClose={() => set_addVisible(undefined)}
        destroyOnClose={true}
      >
        <UserReportForm
          report={addVisible}
          isOpenOrder={isOpenOrder}
          maintenance={maintenance}
          close={() => set_addVisible(undefined)}
        />
      </Drawer>

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedAccount(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AccountModalPreview id={selectedAccount} />
      </Modal>
    </>
  );
};

export default UserReportsComp;
