import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Tag } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { ILocation } from "../../../../../models";
import { motion } from "framer-motion";
import { useState } from "react";
import { CardVariant } from "../../CheckList";

interface IProps {
  item: ILocation;
  onDelete: (id: number) => void;
  onUpdate: (location: ILocation) => void;
}

const Card: React.FC<IProps> = ({ item, onDelete, onUpdate }) => {
  // Variables
  const [variant, set_variant] = useState<CardVariant>("hidden");

  return (
    <motion.div
      onHoverStart={() => set_variant("visible")}
      onHoverEnd={() => set_variant("hidden")}
      className="spaceBetweenRow"
      style={{ marginBottom: 12 }}
    >
      <Tag
        onClick={() => onUpdate(item)}
        onClose={() => {}}
        closable
        style={{
          padding: "7px 20px",
          paddingRight: 8,
          border: "none",
          borderRadius: 40,
          backgroundColor: "#ddd",
          color: "#333",
          fontWeight: 500,
          fontSize: 14,
          cursor: "pointer",
        }}
        closeIcon={
          <Popconfirm
            onConfirm={(e) => (e?.preventDefault(), onDelete(item.id))}
            title={t(TRANSLATION_KEY.continueWithAction)}
            cancelText={t(TRANSLATION_KEY.no)}
            onCancel={(e) => e?.preventDefault()}
            okText={t(TRANSLATION_KEY.yes)}
          >
            <div
              style={{
                display: "inline-block",
                width: 24,
                height: 24,
                borderRadius: 50,
                // backgroundColor: "#aaa",
                marginLeft: 12,
              }}
            >
              <DeleteOutlined style={{ fontSize: 14, marginLeft: 5, marginTop: 4 }} />
            </div>
          </Popconfirm>
        }
      >
        {item.name}
      </Tag>
      {/* <div>{item.name}</div> */}
      {/* <motion.div
        className="spaceBetweenRow"
        initial="hidden"
        animate={variant}
        variants={variants}
        transition={{ ease: 'anticipate', duration: .35 }}
       >
        <Button onClick={() => onUpdate(item)}>
          <EditOutlined />
        </Button>
        <div style={{ width: 6 }} />
        <Popconfirm
          onConfirm={() => onDelete(item.id)}
          title={t(TRANSLATION_KEY.continueWithAction)}
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </motion.div> */}
    </motion.div>
  );
};

export default Card;

const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
