import { Button, Form, Input, message, Select, Spin } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IMaintenanceDetails } from "../../../../models/maintenances";
import {
  getStopWorkingReasonsXHR,
  onHoldMaintenanceXHR,
} from "../../../../store/reducers/maintenance/actionCreator";

interface IProps {
  maintenance: IMaintenanceDetails;
  onClose: () => void;
}

const Pause: React.FC<IProps> = ({ maintenance, onClose }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { onHoldMaintenanceStatus, onHoldTypes } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  const onFinish = (values: { reason: number; reason_text: string }) => {
    onHoldMaintenanceXHR(
      {
        body: { ...values, order_info: maintenance.id },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          onClose();
        },
      },
      dispatch,
    );
  };

  useEffect(() => {
    getStopWorkingReasonsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  return (
    <>
      <div>
        <Spin spinning={onHoldMaintenanceStatus === "loading"}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
              name="reason"
              rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
              label={t(TRANSLATION_KEY.reason)}
            >
              <Select>
                {onHoldTypes.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {t(x.trans_key || x.name)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="reason_text" label={t(TRANSLATION_KEY.description)}>
              <Input.TextArea></Input.TextArea>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t(TRANSLATION_KEY.save)}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  );
};

export default Pause;
