import React, { useState } from "react";
import { FormModal } from "../../../../../componentsform/CustomFieldsFormV2";
import CustomFieldsTable from "../Components/CustomFieldsTable";
import { useAppSelector } from "../../../../../hooks";

interface IProps {
  onEdit: (data: FormModal) => void;
}

const Spend: React.FC<IProps> = ({ onEdit }) => {
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  return (
    <div>
      <CustomFieldsTable
        formType="spend"
        condition={undefined}
        onEdit={onEdit}
        conditionTitle={""}
        customFieldsData={companyCustomFieldsV2.spend}
      />
    </div>
  );
};

export default Spend;
