import { Descriptions, message, Modal, Select, Spin, Tag as Chip, Typography, Space } from "antd";
import { t, use } from "i18next";
import React, { useEffect, useState } from "react";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../../../components/SelectTagAdd";
import { STATUSES, TRANSLATION_KEY } from "../../../helpers/consts";
import { hasModul, hasPermission, sumWorkingTimes } from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse, ILayoutGrids, ILocation } from "../../../models";
import {
  IMaintenanceDetails,
  IUpdateMaintenance,
  MaintenanceStatusTypes,
} from "../../../models/maintenances";
import { IUser } from "../../../models/user";
import {
  updateMaintenanceXHR,
  updateOrerAssetXHR,
} from "../../../store/reducers/maintenance/actionCreator";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import SubAssetForm from "../../../componentsform/SubAssetForm";
import { getSubAssetsXHR } from "../../../store/reducers/asstes/actionCreators";
import { settingsSlice } from "../../../store/reducers/settings";
import Pause from "./comands/pause";

import { ISubAsset } from "../../../models/asset";
import ConfirmForm from "../../../componentsform/ConfirmForm";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { dashboardSlice } from "../../../store/reducers/dashboard";
import { OpenOrderColumns } from "../../openorder/components/Order";
import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import ClientsSelect from "../../../componentsform/ClientsSelect";
import AssetTreeSelect from "../../../componentsform/AssetTreeSelect";
import CustomFieldsSpecGenerator from "../../../components/CustomFieldsSpecGenerator";

interface IProps {
  maintenanceDetails: IMaintenanceDetails;
  isOpenOrder: boolean;
  columns?: OpenOrderColumns;
  grids: ILayoutGrids;
  user: IUser | null;
  block?: boolean;
}

const NEVER_VISIBLE_STATUSES: MaintenanceStatusTypes[] = [
  // 'shared',
  "assigned",
  "cancelled",
];

const MaintenanceInfo: React.FC<IProps> = ({
  maintenanceDetails,
  isOpenOrder,
  columns,
  user,
  block,
}) => {
  const [addLocationVisible, set_addLocationVisible] = useState<boolean>(false);
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [confirmOrderModalVisible, set_confirmOrderModalVisible] = useState<number>();
  const [hasManageWOPermission, set_hasManageWOPermission] = useState<boolean>(
    hasPermission(user?.account.permissions || [], ["manage_wo"]),
  );
  const [pauseMaintenanceVisible, set_pauseMaintenanceVisible] = useState<boolean>(false);
  const { locations } = useAppSelector((state) => state.settingsReducer);
  const [addSubAssetVisible, set_addSubAssetVisible] = useState<ISubAsset>();

  const [work_woStatuses] = useState<MaintenanceStatusTypes[]>(["held", "completed", "running"]);
  const { updateMaintenanceStatus, maintenanceCategories, maintenancesTypes, liveMaintenances } =
    useAppSelector((state) => state.maintenanceReducer);

  let statuses = STATUSES.filter((x) => !NEVER_VISIBLE_STATUSES.includes(x));
  const dispatch = useAppDispatch();

  const updateMaintenance = (data: IUpdateMaintenance) => {
    updateMaintenanceXHR(
      {
        body: data,
        id: maintenanceDetails.id,
        errorCallback: (err) => {
          if (err.response.data?.message?.custom_field) {
            message.error(
              t(err.response.data.message.message || "").replace(
                "$_dynamic_column",
                err.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          if (data.results) {
            let maintenances = [...liveMaintenances];
            let index = maintenances.findIndex((m) => m.id === data.results?.id);
            maintenances.splice(index, 1, data.results);
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: { data: maintenances, cursor: null },
              }),
            );
            dispatch(dashboardSlice.actions.set_categoriesTypes(maintenances));
          }
        },
      },
      dispatch,
    );
  };

  const { subAssets } = useAppSelector((state) => state.assetReducer);

  useEffect(() => {
    if (maintenanceDetails.asset && subAssets.length === 0 && !isOpenOrder) {
      getSubAssetsXHR(
        {
          errorCallback: (error) => {
            message.error(t(TRANSLATION_KEY.errorOnGetData));
          },
          queryParams: {
            asset: maintenanceDetails.asset?.id,
          },
        },
        dispatch,
      );
    }
  }, [maintenanceDetails]);

  let descriptionClassname = hasManageWOPermission ? "select" : "";
  let _hasClientsPermission = user?.account?.permissions?.filter(
    (x) => x.codename === "manage_client",
  ).length;

  console.log(maintenanceDetails.workorder_custom_fields_v2);

  return (
    <div style={{ width: block ? "calc(100vw - 20px)" : "100%" }}>
      <Spin spinning={updateMaintenanceStatus === "loading"}>
        <Descriptions
          size="small"
          column={columns || { xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
        >
          {/* Asset */}
          <Descriptions.Item label={t(TRANSLATION_KEY.asset)}>
            {isOpenOrder ? (
              <Typography.Text
                style={{ maxWidth: 240, width: 240 }}
                ellipsis={{ tooltip: maintenanceDetails.asset?.name }}
              >
                {maintenanceDetails.asset?.name}
              </Typography.Text>
            ) : (
              <Space size={0}>
                {maintenanceDetails.asset?.id && (
                  <Link
                    to={`/app/asset-details/${maintenanceDetails.asset?.id}`}
                    type="link"
                    style={{
                      padding: 0,
                      height: "unset",
                      fontSize: 16,
                      fontWeight: 500,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 210,
                      marginRight: -4,
                    }}
                  >
                    <LinkOutlined />
                  </Link>
                )}
                <AssetTreeSelect
                  includeWithoutAsset={false}
                  onChange={(e) => {
                    console.log(e);
                    if (Array.isArray(e)) {
                      return;
                    }
                    updateOrerAssetXHR(
                      {
                        body: { asset: e || null, order_info: maintenanceDetails.id },
                        errorCallback: (error) => {
                          message.error(t(TRANSLATION_KEY.errorOnSaveData));
                        },
                      },
                      dispatch,
                    );
                  }}
                  treeProps={{
                    bordered: false,
                    allowClear: true,
                    showSearch: true,
                    maxTagCount: undefined,
                    style: { width: 224 },
                    value: maintenanceDetails.asset?.id,
                    placeholder: t(TRANSLATION_KEY.selectAssets),
                    multiple: false,
                  }}
                />
              </Space>
            )}
          </Descriptions.Item>

          {/* Location */}
          <Descriptions.Item className={descriptionClassname} label={t(TRANSLATION_KEY.location)}>
            {!isOpenOrder ? (
              <Select
                bordered={false}
                allowClear
                style={{ width: "100%" }}
                showArrow={false}
                placeholder={t(TRANSLATION_KEY.selectLocation)}
                key={`location:${maintenanceDetails.location?.id}`}
                defaultValue={maintenanceDetails.location?.id}
                optionFilterProp="children"
                onChange={(location) => {
                  updateMaintenance({ location: location || null });
                }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
                notFoundContent={
                  <ButtonAddSelectTag
                    addingVisible={addLocationVisible}
                    set_addingVisible={set_addLocationVisible}
                  />
                }
              >
                {locations.map((x) => (
                  <Select.Option value={x.id} key={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
                <Select.OptGroup
                  label={
                    <ButtonAddSelectTag
                      addingVisible={addLocationVisible}
                      set_addingVisible={set_addLocationVisible}
                    />
                  }
                />
              </Select>
            ) : (
              maintenanceDetails.location?.name || "-"
            )}
          </Descriptions.Item>

          {/* Types */}
          <Descriptions.Item
            className={descriptionClassname}
            label={t(TRANSLATION_KEY.maintenanceTypes)}
          >
            {!isOpenOrder ? (
              <Select
                allowClear
                bordered={false}
                style={{ width: "100%" }}
                showArrow={false}
                placeholder={t(TRANSLATION_KEY.selectType)}
                key={`maintenance_type:${maintenanceDetails.maintenance_type?.id}`}
                defaultValue={maintenanceDetails.maintenance_type?.id}
                onChange={(maintenance_type) => {
                  if (maintenance_type) {
                    updateMaintenance({
                      maintenance_type,
                      update_type: "this",
                    });
                  }
                }}
                onClear={() => {
                  updateMaintenance({
                    maintenance_type: null,
                    update_type: "this",
                  });
                }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
              >
                {maintenancesTypes.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {t(type.trans_key || type.name)}
                  </Select.Option>
                ))}
              </Select>
            ) : maintenanceDetails.maintenance_type ? (
              t(
                maintenanceDetails?.maintenance_type?.trans_key ||
                  maintenanceDetails?.maintenance_type?.name ||
                  "",
              )
            ) : (
              "-"
            )}
          </Descriptions.Item>

          {/* Status */}
          <Descriptions.Item className={descriptionClassname} label={t(TRANSLATION_KEY.status)}>
            {hasPermission(user?.account.permissions || [], ["work_wo"]) && !isOpenOrder ? (
              <Select
                bordered={false}
                style={{ width: "100%" }}
                showArrow={false}
                placeholder={t(TRANSLATION_KEY.selectStatus)}
                key={`status:${maintenanceDetails.status}`}
                defaultValue={t(maintenanceDetails.status)}
                onChange={(status: MaintenanceStatusTypes | "") => {
                  if (status === "held") {
                    set_pauseMaintenanceVisible(true);
                    return;
                  }
                  if (status === "closed") {
                    set_confirmOrderModalVisible(maintenanceDetails.id);
                    return;
                  }
                  updateMaintenance({ status });
                }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
              >
                {statuses
                  .filter((x) => x !== maintenanceDetails.status)
                  .map((status) => (
                    <Select.Option
                      disabled={
                        hasManageWOPermission
                          ? false
                          : hasPermission(user?.account.permissions || [], ["confirm_wo"]) &&
                            status === "closed"
                          ? false
                          : hasPermission(user?.account.permissions || [], ["work_wo"]) &&
                            !work_woStatuses.includes(status)
                      }
                      key={status}
                      value={status}
                    >
                      {t(status)}
                    </Select.Option>
                  ))}
              </Select>
            ) : (
              t(maintenanceDetails.status)
            )}
          </Descriptions.Item>

          {/* Maintenance categories */}
          <Descriptions.Item className={descriptionClassname} label={t(TRANSLATION_KEY.category)}>
            {true ? (
              <Select
                tagRender={({ label }) => <Chip className="tag-chip">{label}</Chip>}
                bordered={false}
                placeholder={t(TRANSLATION_KEY.selectCategory)}
                key={`maintenance_categories:${maintenanceDetails.maintenance_categories?.map(
                  (item) => item.id,
                )}`}
                mode="tags"
                maxTagCount="responsive"
                style={{ minWidth: "100%" }}
                value={maintenanceDetails.maintenance_categories?.map((item) => item.id) || []}
                onChange={(maintenance_categories) => {
                  updateMaintenance({
                    maintenance_categories,
                    update_type: "this",
                  });
                }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
              >
                {maintenanceCategories.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {t(category.trans_key || category.name)}
                  </Select.Option>
                ))}
              </Select>
            ) : maintenanceDetails.maintenance_categories?.length ? (
              maintenanceDetails?.maintenance_categories?.map((item) => item.name).join(", ")
            ) : (
              "-"
            )}
          </Descriptions.Item>

          {/* Asset marks */}
          <Descriptions.Item
            span={1}
            className={isOpenOrder ? "" : "select"}
            label={t(TRANSLATION_KEY.assetMarks)}
          >
            {isOpenOrder || !maintenanceDetails.asset ? (
              maintenanceDetails.subassets.map((x) => x.name).join(" ")
            ) : (
              <>
                <Select
                  tagRender={({ label }) => <Chip className="tag-chip">{label}</Chip>}
                  bordered={false}
                  // disabled={!hasManageWOPermission}
                  placeholder={t(TRANSLATION_KEY.assetMarks)}
                  key={`subassets:${maintenanceDetails?.subassets.map((item) => item.id)}`}
                  mode="tags"
                  style={{ minWidth: "100%" }}
                  maxTagCount="responsive"
                  value={maintenanceDetails?.subassets.map((item) => item.id)}
                  onChange={(subassets) => {
                    updateMaintenance({ subassets });
                  }}
                  filterOption={(input, option) => {
                    if (typeof option?.children === "string") {
                      const str: string = option.children;
                      return str.toLowerCase().includes(input.toLowerCase());
                    }
                    return false;
                  }}
                  showSearch={true}
                >
                  {subAssets.map((subAssets) => (
                    <Select.Option key={subAssets.id} value={subAssets.id}>
                      {subAssets.name}
                    </Select.Option>
                  ))}
                  {hasPermission(user?.account?.permissions || [], ["manage_asset"]) && (
                    <Select.OptGroup
                      label={
                        <ButtonAddSelectTag
                          addingVisible={!!addSubAssetVisible}
                          set_addingVisible={() =>
                            set_addSubAssetVisible({
                              id: 0,
                              name: "",
                              asset: { id: 0, name: "" },
                            })
                          }
                        />
                      }
                    />
                  )}
                </Select>
                <Modal
                  visible={!!addSubAssetVisible}
                  onCancel={() => {
                    set_addSubAssetVisible(undefined);
                  }}
                  footer={null}
                  centered
                  width="600px"
                  closable
                  destroyOnClose
                  title={t(TRANSLATION_KEY.addAssetMark)}
                >
                  {addSubAssetVisible && (
                    <SubAssetForm
                      callback={(id: number) => {
                        if (!maintenanceDetails) {
                          return;
                        }
                        updateMaintenance({
                          subassets: [...maintenanceDetails.subassets.map((x) => x.id), id],
                        });
                      }}
                      asset_id={maintenanceDetails?.asset?.id || 0}
                      close={() => set_addSubAssetVisible(undefined)}
                      subAsset={addSubAssetVisible}
                    />
                  )}
                </Modal>
              </>
            )}
          </Descriptions.Item>

          {user?.account.company && hasModul(user?.account.company, "clients") && (
            <Descriptions.Item label={t(TRANSLATION_KEY.client)}>
              {isOpenOrder || !_hasClientsPermission ? (
                maintenanceDetails.client?.name
              ) : (
                <Space size={0}>
                  {maintenanceDetails.client && (
                    <Link
                      to={`/app/clients/${maintenanceDetails.client.id}`}
                      type="link"
                      style={{
                        padding: 0,
                        height: "unset",
                        fontSize: 16,
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 210,
                        marginRight: -4,
                      }}
                    >
                      <LinkOutlined />
                    </Link>
                  )}
                  <ClientsSelect
                    includeInactive={false}
                    bordered={false}
                    includeArchived={false}
                    value={maintenanceDetails.client?.id}
                    onChange={(e: number | undefined | number[]) => {
                      if (Array.isArray(e)) {
                        return;
                      }
                      updateMaintenance({ client: e || null });
                    }}
                    name=""
                    label=""
                    form={undefined}
                    style={{ width: 210 }}
                    mode={undefined}
                  />
                </Space>
              )}
            </Descriptions.Item>
          )}

          {CustomFieldsSpecGenerator({
            onChange: (value) => {
              updateMaintenance({ workorder_custom_fields_v2: value });
            },
            condition: maintenanceDetails.maintenance_type?.id,
            formType: "workorder",
            data: maintenanceDetails.workorder_custom_fields_v2,
            alowUpdate: true,
          })}

          {CustomFieldsSpecGenerator({
            condition: undefined,
            formType: "orderconfirm",
            data: maintenanceDetails.orderconfirm_custom_fields_v2,
            alowUpdate: false,
            hide: maintenanceDetails.status !== "closed",
          })}
        </Descriptions>
      </Spin>

      {/* Add location tag */}
      <SelectTagAdd
        title={t(TRANSLATION_KEY.addLocation)}
        url="settings/locations/"
        set_visible={set_addLocationVisible}
        visible={addLocationVisible}
        successCallback={(tag: Tag) => {
          const data = [...locations];
          data.push({ id: tag.id, name: tag.name });
          const resFake: IApiResponse<ILocation[]> = {
            message: "",
            results: data,
          };

          dispatch(settingsSlice.actions.getLocationsSuccess(resFake));
          updateMaintenance({ location: tag.id, update_type: "this" });
        }}
      />

      {/* Asset Modal Preview */}
      <Modal
        visible={modalVisible}
        onCancel={() => set_modalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AssetModalPreview id={maintenanceDetails.asset?.id} />
      </Modal>

      <Modal
        destroyOnClose
        title={t(TRANSLATION_KEY.pauseMaintenance)}
        visible={pauseMaintenanceVisible}
        onCancel={() => set_pauseMaintenanceVisible(false)}
        footer={null}
      >
        <Pause
          maintenance={maintenanceDetails}
          onClose={() => set_pauseMaintenanceVisible(false)}
        />
      </Modal>

      <Modal
        visible={!!confirmOrderModalVisible}
        onCancel={() => {
          set_confirmOrderModalVisible(undefined);
        }}
        footer={null}
        centered
        width="600px"
        destroyOnClose
        title={t(TRANSLATION_KEY.closeMaintenance)}
      >
        {confirmOrderModalVisible && (
          <ConfirmForm
            selectedMaintenance={confirmOrderModalVisible}
            close={() => {
              set_confirmOrderModalVisible(undefined);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default MaintenanceInfo;
