import {
  Button,
  Drawer,
  message,
  PageHeader,
  Popconfirm,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, Outlet, Link } from "react-router-dom";
import AssetForm from "../../componentsform/AssetForm";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse } from "../../models";
import { IAsset } from "../../models/asset";
import api, { failedQueue, isRefreshing } from "../../services";
import { assetSlice } from "../../store/reducers/asstes";
import { getSingleAssetXHR } from "../../store/reducers/asstes/actionCreators";

import { AppRoutes } from "../../navigation/routes";
import { colors } from "../../styles/colors";
import {
  CalendarOutlined,
  KeyOutlined,
  LeftOutlined,
  ShopOutlined,
  StopOutlined,
} from "@ant-design/icons";
import RequirePermission from "../../components/RequirePermission";
import Spec from "./pages/Spec";
import AssetInfo from "./pages/AssetInfo";
import Accounts from "./pages/Accounts";
const { TabPane } = Tabs;

const AssetDetails = ({}) => {
  const { id } = useParams();
  const [deleteLoading, set_deleteLoading] = useState<boolean>(false);
  const location = useLocation();
  const [accountsVisible, set_accountsVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  const { getSingleAssetStatus, asset, assetList, getAssetAccountsStatus } = useAppSelector(
    (state) => state.assetReducer,
  );

  const { user } = useAppSelector((state) => state.userReducer);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getSingleAssetXHR(
      {
        id,
        errorCallback: (error: any) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          if (error?.response?.status === 404) {
            navigate("/app/not-found");
          }
        },
      },
      dispatch,
    );
  }, []);

  const removeAsset = async () => {
    const token = localStorage.getItem("token");
    api
      .delete<string, string>(`/assets/assets/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res: any) => {
        if (res.data.results) {
          const _assetList = [...assetList];
          if (typeof id !== undefined) {
            const index = _assetList.findIndex((x) => x.id.toString() === id);
            _assetList.splice(index, 1);
            const resFake: IApiResponse<IAsset[]> = {
              message: "",
              results: _assetList,
            };
            dispatch(assetSlice.actions.getAssetSuccess(resFake));
            navigate("/app/asset", { replace: true });
          }
        } else {
          message.warning(t(res.data.message));
        }
      })
      .catch((error: any) => {
        if (error.response.data?.message?.custom_field) {
          message.error(
            t(error.response.data.message.message || "").replace(
              "$_dynamic_column",
              error.response.data.message.custom_field,
            ),
          );
          return;
        }
        if (error?.response?.status === 401) {
          if (isRefreshing) {
            failedQueue.push(() => removeAsset());
          }
          return;
        }
        message.error(t(TRANSLATION_KEY.errorOnDeleteData));
      });
  };

  const [editVisible, set_editVisible] = useState<boolean>(false);

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  function onBackClick(): void {
    navigate("/app/asset");
  }

  return (
    <>
      <div>
        {/* Page header */}
        <PageHeader
          style={{ padding: 0, paddingTop: 10 }}
          className="custom-page-header"
          onBack={() => navigate("/app/asset")}
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.asset)} </Typography.Text>
              <Typography.Text strong>#{asset.custom_id}</Typography.Text>
            </div>
          }
          extra={[
            <RequirePermission
              requiredPermissionCode={["manage_asset"]}
              children={
                <div className="spaceBetweenRow">
                  <Button style={{ marginRight: 12 }} onClick={() => set_accountsVisible(true)}>
                    {t(TRANSLATION_KEY.accountsOnAsset)}
                  </Button>
                  <Popconfirm
                    onConfirm={removeAsset}
                    title={t(TRANSLATION_KEY.continueWithAction)}
                    cancelText={t(TRANSLATION_KEY.no)}
                    okText={t(TRANSLATION_KEY.yes)}
                  >
                    <Button type="primary" danger loading={deleteLoading}>
                      {t(TRANSLATION_KEY.delete)}
                    </Button>
                  </Popconfirm>
                  <div style={{ width: 12 }} />
                  <Button onClick={() => set_editVisible(true)} type="primary">
                    {t(TRANSLATION_KEY.edit)}
                  </Button>
                </div>
              }
            />,
          ]}
        >
          <Space direction="vertical">
            <Space size="large" align="center">
              <Typography.Title level={3}>{asset.name}</Typography.Title>
              <Space size="small">
                {asset.type === "wrh" && (
                  <Tooltip title={t(TRANSLATION_KEY.storage)}>
                    <Tag className="main-tag">
                      <ShopOutlined />
                    </Tag>
                  </Tooltip>
                )}
                {!asset.can_report_order && (
                  <Tooltip title={t(TRANSLATION_KEY.cantReportOrder)}>
                    <Tag className="main-tag" style={{ backgroundColor: "#d4282a" }}>
                      <StopOutlined style={{ color: "white" }} />
                    </Tag>
                  </Tooltip>
                )}
                {asset.client_owner && (
                  <Tooltip title={`${t(TRANSLATION_KEY.ownedBy)}: ${asset.client_owner.name}`}>
                    <Tag className="main-tag" style={{ backgroundColor: "#ccc" }}>
                      <KeyOutlined style={{ color: "#000" }} />
                    </Tag>
                  </Tooltip>
                )}
                {asset.rented_to_client && (
                  <Tooltip title={`${t(TRANSLATION_KEY.rentedBy)}: ${asset.rented_to_client.name}`}>
                    <Tag className="main-tag" style={{ backgroundColor: "#ccc" }}>
                      <CalendarOutlined style={{ color: "#000" }} />
                    </Tag>
                  </Tooltip>
                )}
              </Space>
            </Space>
            {/* Specifications */}
            <AssetInfo />
          </Space>
        </PageHeader>

        {/* Tabs */}
        <Tabs id="bordered-tabs" activeKey={location.pathname.split("/")[4]} onChange={onTabChange}>
          {AppRoutes("allroutes")
            .children?.find((x) => x.path === "asset-details/:id")
            ?.children?.filter((x) => {
              //provjeriti ukoliko nije definirana permisija more
              //ukoliko ima permisija viditi dali ima korisnik
              if (x.permissionCode === undefined) {
                return true;
              }
              return user.account.permissions.map((x) => x.codename).includes(x.permissionCode);
            })
            .map((x) => (
              <TabPane
                tab={
                  <Link style={{ color: colors.globalFontColor }} to={x.path}>
                    {t(x.path || "spec")}
                  </Link>
                }
                key={x.path}
              />
            ))}
        </Tabs>
      </div>

      <Spin spinning={getSingleAssetStatus === "loading" || getAssetAccountsStatus === "loading"}>
        <Outlet />
      </Spin>

      <Drawer
        width={540}
        visible={accountsVisible}
        onClose={() => set_accountsVisible(false)}
        title={t(TRANSLATION_KEY.accountsOnAsset)}
        destroyOnClose={true}
      >
        <Accounts close={() => set_accountsVisible(false)} />
      </Drawer>

      <Drawer
        width={540}
        visible={editVisible}
        onClose={() => set_editVisible(false)}
        title={t(TRANSLATION_KEY.editAsset)}
        destroyOnClose={true}
      >
        <AssetForm addingFromOrder={false} close={() => set_editVisible(false)} asset={asset} />
      </Drawer>
    </>
  );
};

export default AssetDetails;
