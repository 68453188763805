import React from "react";

// Styles
import { List, Tooltip, Popconfirm, Button, message } from "antd";
import {
  FileImageFilled,
  FilePdfFilled,
  VideoCameraFilled,
  DeleteOutlined,
  FileExclamationFilled,
  EditOutlined,
} from "@ant-design/icons";
import styles from "../../index.module.css";

// Components
import Image from "../Image";
import Video from "../Video";
import Document from "../Document";
import Invalid from "../Invalid";
import { IFile } from "../../../../models/user";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { MOB_SIZE } from "../../../../pages/openorder/components/Order";

type CardSize = "tiny" | "small" | "medium" | "large";

interface IProps {
  name: string;
  mediaType: string;
  size: CardSize;
  index: number;
  title: string;
  id: number;
  hideEditButton?: boolean;
  hideButtons?: boolean;
  hideMeta?: boolean;
  hideMetaType?: boolean;
  hideMetaIcon?: boolean;
  openFileViewer: (v: number) => void;
  onDelete: (id: number) => void;
  onEdit?: (file: IFile | undefined) => void;
}

const ListItemComponent: React.FC<IProps> = (props) => {
  // Props
  const {
    name,
    title,
    id,
    hideMeta,
    hideMetaIcon,
    hideMetaType,
    hideEditButton = false,
    hideButtons = false,
    mediaType,
    size,
    index,
    openFileViewer,
    onDelete,
    onEdit,
  } = props;

  const _type = mediaType as keyof typeof cover;
  const _size = size as keyof typeof sizes.cover;

  function handleOnPress(index: number) {
    if (_type === "document") {
      window.open(name, "_blank");
      return;
    }
    openFileViewer(index);
  }

  function handleOnDelete(
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    id: number,
  ): void {
    event?.stopPropagation();
    onDelete(id);
  }

  function handleOnEdit(
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    file: IFile,
  ): void {
    event?.stopPropagation();
    onEdit && onEdit(file);
  }

  // Constants
  const sizes = {
    meta: {
      tiny: window.innerWidth < MOB_SIZE ? 40 : 140,
      small: window.innerWidth < MOB_SIZE ? 40 : 140,
      medium: 140,
      large: 140,
    },
    cover: {
      tiny: 24,
      small: 32,
      medium: 52,
      large: 72,
    },
    document: {
      tiny: 24,
      small: 52,
      medium: 72,
      large: 92,
    },
  };

  // Content
  const cover = {
    image: <Image src={name} size={sizes.cover[_size]} layout="list" />,
    video: <Video src={name} size={sizes.cover[_size]} layout="list" />,
    invalid: <Invalid size={sizes.cover[_size]} layout="list" small={size === "small"} />,
    document:
      window.innerWidth < MOB_SIZE ? (
        <Image
          src={require("../../../../assets/icons/mobile-pdf.png")}
          size={sizes.cover[_size]}
          layout="list"
        />
      ) : (
        <Document src={name} size={sizes.cover[_size]} width={sizes.document[_size]} />
      ),
  };

  const metaIcon = {
    image: <FileImageFilled style={{ color: "#e50a0a" }} />,
    document: <FilePdfFilled style={{ color: "#0353ef" }} />,
    video: <VideoCameraFilled style={{ color: "#02b111" }} />,
    invalid: <FileExclamationFilled style={{ color: "#f09001" }} />,
  };

  const meta = hideMeta ? null : (
    <div className={styles.metaContainer}>
      {!hideMetaType && (
        <div className={styles.metaName} style={{ marginRight: 10 }}>
          {mediaType}
        </div>
      )}
      {!hideMetaIcon && metaIcon[_type]}
    </div>
  );

  return (
    <List.Item
      style={{ cursor: "pointer" }}
      actions={
        hideButtons
          ? []
          : [
              <Tooltip title={t(TRANSLATION_KEY.delete)} placement="bottom">
                <Popconfirm
                  onConfirm={(e) => handleOnDelete(e, id)}
                  title={t(TRANSLATION_KEY.continueWithAction)}
                  cancelText={t(TRANSLATION_KEY.no)}
                  okText={t(TRANSLATION_KEY.yes)}
                  placement="top"
                >
                  <Button type="default" shape="circle" icon={<DeleteOutlined />} size="middle" />
                </Popconfirm>
              </Tooltip>,
              ...(!hideEditButton
                ? [
                    <Button
                      onClick={(e) =>
                        handleOnEdit(e, { name: name, id: id, file: "", extension: "" })
                      }
                      type="default"
                      shape="circle"
                      icon={<EditOutlined />}
                      size="middle"
                    />,
                  ]
                : []),
            ]
      }
    >
      <List.Item.Meta
        style={{
          width: window.innerWidth < MOB_SIZE ? 64 : "100%",
          flex: window.innerWidth < MOB_SIZE ? 0 : "1 1 0%",
        }}
        avatar={<div onClick={() => handleOnPress(index)}>{cover[_type]}</div>}
      />
      <div className={styles.listFlex}>
        <div className={styles.listTitle}>{title}</div>
        {meta}
      </div>
    </List.Item>
  );
};

export default ListItemComponent;
