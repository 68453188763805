import { useEffect, useRef, useState } from "react";
import PinField from "react-pin-field";

// UI
import styles from "../styles.module.css";
import { Button, Divider, message, Space, Typography } from "antd";
import OpenOrderSkeleton from "./Skeleton";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { t } from "i18next";
import { getOpenOrderXHR } from "../../../store/reducers/supplier/actionCreator";
import { useAppDispatch } from "../../../hooks";
import { MOB_SIZE } from "./Order";

type IProps = {
  verified: boolean;
  set_verified: React.Dispatch<React.SetStateAction<boolean>>;
};

const Verification = ({ verified, set_verified }: IProps) => {
  // Constants
  const CODE_LENGTH: number = 3;

  // Hooks
  const dispatch = useAppDispatch();
  const { token } = useParams();

  // Refs
  const ref = useRef<any>(null);

  // Variables
  const [code, set_code] = useState<string>("");
  const [loading, set_loading] = useState<boolean>(false);

  // Methods
  useEffect(() => {
    if (token) {
      let codeFromStorage: string | null = localStorage.getItem(token);
      if (codeFromStorage) {
        verify();
      }
    }
  }, []);

  const verify = async (shouldSetCodeInLocalStorage: boolean = false) => {
    set_loading(true);
    let c = await localStorage.getItem(token || "");
    if (c?.length) {
      set_code(c);
    }
    let _code = c || code;

    if (token) {
      getOpenOrderXHR(
        {
          queryParams: {
            open_order_token: token,
            open_order_code: _code,
          },
          errorCallback: (error) => {
            console.log("ERROR => ", error);
            message.error(t(TRANSLATION_KEY.errorOnGetData));
          },
          successCallback: () => {
            if (shouldSetCodeInLocalStorage && token) {
              localStorage.setItem(token, code);
            }
            set_verified(true);
          },
        },
        dispatch,
      );
    }
    set_loading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Space direction="vertical" align="center" size={20} style={{ paddingBottom: 10 }}>
        <Typography.Title
          level={4}
          className={styles.enterVerificationCode}
          style={{ width: "100vw" }}
        >
          {t(TRANSLATION_KEY.enterVerificationCode)}
        </Typography.Title>

        {/* Pin field */}
        <PinField
          ref={ref}
          className={`pin-field ${verified ? "pin-field-complete" : ""}`}
          length={CODE_LENGTH}
          validate={/^[0-9]$/} // Numbers only
          onChange={set_code}
          format={(k) => k.toUpperCase()}
        />

        <Button
          onClick={() => verify(true)}
          type="primary"
          size="large"
          loading={loading}
          block
          disabled={code.length !== CODE_LENGTH}
          style={{
            marginTop: 14,
            width: window.innerWidth < MOB_SIZE ? undefined : 330,
          }}
        >
          {t(TRANSLATION_KEY.verify)}
        </Button>
      </Space>

      {/* <Divider />

            <div style={{ width: "100%" }}>
                <OpenOrderSkeleton />
            </div> */}
    </motion.div>
  );
};

export default Verification;
