import {
  Button,
  Drawer,
  message,
  PageHeader,
  Popconfirm,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import PartForm from "../../componentsform/PartForm";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AppRoutes } from "../../navigation/routes";
import api, { failedQueue, isRefreshing } from "../../services";

import { retrievePartXHR } from "../../store/reducers/warehouse/actionCreator";
import { colors } from "../../styles/colors";
import PartSpec from "./pages/Spec";
import AssetManagement from "./components/AssetManagment";

type Props = {};

const PartProfile = (props: Props) => {
  // Hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  // Variables
  const [editVisible, set_editVisible] = useState<boolean>(false);
  const { retrievePartStatus, part } = useAppSelector((state) => state.warehouseReducer);
  const [delLoading, set_delLoading] = useState(false);
  const [asset, set_asset] = useState(false);

  // Methods
  useEffect(() => {
    retrievePartXHR(
      {
        id,
        errorCallback: (error: any) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          if (error?.response?.status === 404) {
            navigate("/app/not-found");
          }
        },
      },
      dispatch,
    );
  }, []);

  const removePart = async () => {
    set_delLoading(true);
    let token = await localStorage.getItem("token");
    try {
      await api.delete(`warehouse/parts/${id}/`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      navigate("/app/items");
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => removePart());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_delLoading(false);
  };

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  return (
    <Spin spinning={retrievePartStatus === "loading"}>
      <div>
        {/* Page header */}
        <PageHeader
          style={{ padding: 0, paddingTop: 10 }}
          className="custom-page-header"
          onBack={() => navigate("/app/items")}
          footer={
            <Tabs
              id="bordered-tabs"
              activeKey={location.pathname.split("/")[4]}
              onChange={onTabChange}
            >
              {AppRoutes("allroutes")
                .children?.find((x) => x.path === "item-details/:id")
                ?.children?.map((x) => (
                  <Tabs.TabPane
                    tab={
                      <Link style={{ color: colors.globalFontColor, fontSize: 14 }} to={x.path}>
                        {t(x.path || "entryhistory")}
                      </Link>
                    }
                    key={x.path}
                  />
                ))}
            </Tabs>
          }
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.part)} </Typography.Text>
              <Typography.Text strong>#{part.code}</Typography.Text>
            </div>
          }
          extra={[
            <Button onClick={() => set_asset(true)}>{t(TRANSLATION_KEY.asset)}</Button>,
            <Button onClick={() => set_editVisible(true)} type="primary">
              {t(TRANSLATION_KEY.edit)}
            </Button>,
            <Popconfirm
              onConfirm={removePart}
              title={t(TRANSLATION_KEY.continueWithAction)}
              cancelText={t(TRANSLATION_KEY.no)}
              okText={t(TRANSLATION_KEY.yes)}
            >
              <Button type="primary" danger loading={delLoading}>
                {t(TRANSLATION_KEY.delete)}
              </Button>
            </Popconfirm>,
          ]}
        >
          <Space direction="vertical">
            <Space size="large" align="center">
              <Typography.Title level={3}>{part.name}</Typography.Title>
              {part.category && <Tag className="main-tag">{part.category.name}</Tag>}
            </Space>
            {/* Specifications */}
            <PartSpec />
          </Space>
        </PageHeader>
      </div>

      <Spin spinning={retrievePartStatus === "loading"}>
        <Outlet />
      </Spin>

      <Drawer
        width={540}
        visible={asset}
        onClose={() => set_asset(false)}
        destroyOnClose={true}
        title={t(TRANSLATION_KEY.userAsset) + " " + part.name}
      >
        <AssetManagement part={part} asset={part.assets || []} />
      </Drawer>

      {/* Form */}
      <Drawer
        width={540}
        visible={editVisible}
        onClose={() => set_editVisible(false)}
        title={t(TRANSLATION_KEY.editPart)}
        destroyOnClose={true}
      >
        <PartForm close={() => set_editVisible(false)} part={part} />
      </Drawer>
    </Spin>
  );
};

export default PartProfile;
