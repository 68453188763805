import React, { useState } from "react";
import { Tabs } from "antd";
import { t } from "i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../navigation/routes";

const { TabPane } = Tabs;

interface IProps {}

const PartAnalytics: React.FC<IProps> = () => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Routes
  const [routes] = useState(
    AppRoutes("allroutes").children.find((x) => x.path === "analytics")?.children[2],
  );

  // Methods
  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  return (
    <div>
      {/* Tabs */}
      <Tabs activeKey={location.pathname.split("/")[4]} onChange={onTabChange}>
        {routes &&
          routes.children.map((x) => (
            <TabPane key={x.path} tab={<Link to={x.path}>{t(x.path || "itemQuantity")}</Link>} />
          ))}
      </Tabs>

      {/* Rendering routes */}
      <Outlet />
    </div>
  );
};

export default PartAnalytics;
