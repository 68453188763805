import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Typography, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FilesComponent, { MediaFile } from "../components/Files";
import { IMAGE_EXTENSIONS, MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../helpers/consts";
import { compressImage } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IMaintenanceDetails, IUserReport } from "../models/maintenances";
import { IFile } from "../models/user";
import { maintenanceSlice } from "../store/reducers/maintenance";
import { updateUserReportXHR, writeReportXHR } from "../store/reducers/maintenance/actionCreator";
import { supplierSlice } from "../store/reducers/supplier";
import {
  addReportToOpenOrderXHR,
  updateReportToOpenOrderXHR,
} from "../store/reducers/supplier/actionCreator";

interface IProps {
  maintenance: IMaintenanceDetails;
  close: () => void;
  report?: IUserReport;
  isOpenOrder: boolean;
}

const UserReportForm: React.FC<IProps> = ({ isOpenOrder, maintenance, close, report }) => {
  const [text, set_text] = useState("");
  const [files, set_files] = useState<Array<RcFile>>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const open_order_token = useParams().token;

  const dispatch = useAppDispatch();
  const { addReportToOpenOrderStatus, updateReportToOpenOrderStatus } = useAppSelector(
    (state) => state.supplierReducer,
  );
  const { writeReportStatus, updateUserReportStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  useEffect(() => {
    if (report) {
      set_text(report.text);
    }
  }, []);

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const updateReport = async () => {
    let fd = new FormData();
    let code = localStorage.getItem(open_order_token || "");

    for (let index = 0; index < files.length; index++) {
      try {
        const extension = files[index].type.split("/")[1];
        if (IMAGE_EXTENSIONS.includes(extension)) {
          const f = await compressImage(files[index]);
          fd.append("files", f);
        } else {
          fd.append("files", files[index]);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (isOpenOrder) {
      fd.append("data", JSON.stringify({ text, code, token: open_order_token }));
    } else {
      fd.append("data", JSON.stringify({ text }));
    }

    if (isOpenOrder) {
      // update izvjestaja na open order
      updateReportToOpenOrderXHR(
        {
          successCallback: (data) => {
            if (data.results) {
              let tmp = [...maintenance.reports];
              let index = tmp.findIndex((x) => x.id === report?.id);
              tmp[index] = data.results;
              dispatch(
                supplierSlice.actions.getOpenOrderSuccess({
                  message: "",
                  results: { ...maintenance, reports: tmp },
                }),
              );
              close();
            }
          },
          errorCallback: () => {
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          id: report?.id,
          body: fd,
        },
        dispatch,
      );

      return;
    }

    updateUserReportXHR(
      {
        successCallback: (data) => {
          if (data.results) {
            let tmp = [...maintenance.reports];
            let index = tmp.findIndex((x) => x.id === report?.id);
            tmp[index] = data.results;
            dispatch(
              maintenanceSlice.actions.getMaintenanceSuccess({
                message: "",
                results: { ...maintenance, reports: tmp },
              }),
            );
            close();
          }
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        id: report?.id,
        body: fd,
      },
      dispatch,
    );
  };

  const saveReport = async () => {
    let fd = new FormData();
    let code = localStorage.getItem(open_order_token || "");

    for (let index = 0; index < files.length; index++) {
      try {
        const extension = files[index].type.split("/")[1];
        if (IMAGE_EXTENSIONS.includes(extension)) {
          const f = await compressImage(files[index]);
          fd.append("files", f);
        } else {
          fd.append("files", files[index]);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (isOpenOrder) {
      fd.append("data", JSON.stringify({ text, code, token: open_order_token }));
    } else {
      fd.append("data", JSON.stringify({ text, order_info: maintenance.id }));
    }

    if (isOpenOrder) {
      // dodavanje izvjestaja na open order
      addReportToOpenOrderXHR(
        {
          successCallback: (data) => {
            if (data.results) {
              let tmp = [...maintenance.reports];
              tmp.push(data.results);
              dispatch(
                supplierSlice.actions.getOpenOrderSuccess({
                  message: "",
                  results: { ...maintenance, reports: tmp },
                }),
              );
              close();
            }
          },
          errorCallback: () => {
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          body: fd,
        },
        dispatch,
      );
      return;
    }

    writeReportXHR(
      {
        successCallback: (data) => {
          if (data.results) {
            let tmp = [...maintenance.reports];
            tmp.push(data.results);
            dispatch(
              maintenanceSlice.actions.getMaintenanceSuccess({
                message: "",
                results: { ...maintenance, reports: tmp },
              }),
            );
            close();
          }
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        body: fd,
      },
      dispatch,
    );
  };

  const onRemove = (id: number): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  return (
    <>
      <div>
        <Input.TextArea
          rows={6}
          value={text}
          onChange={({ target: { value } }) => {
            set_text(value);
          }}
        />
        <div style={{ height: 45 }} />
        <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
          <Typography.Title level={5}>{t(TRANSLATION_KEY.documents)}</Typography.Title>
          <Upload
            multiple={true}
            accept={MATERIAL_EXTENSIONS}
            beforeUpload={beforeUpload}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />} shape="circle" type="primary" />
          </Upload>
        </div>

        <FilesComponent
          files={previewFiles}
          onDelete={onRemove}
          hideEditButton
          size="small"
          layout="list"
        />

        <div style={{ height: 45 }} />

        <Button
          type="primary"
          disabled={!text}
          loading={
            writeReportStatus === "loading" ||
            updateUserReportStatus === "loading" ||
            addReportToOpenOrderStatus === "loading" ||
            updateReportToOpenOrderStatus === "loading"
          }
          onClick={() => {
            if (report?.id !== 0) {
              updateReport();
            } else {
              saveReport();
            }
          }}
        >
          {t(TRANSLATION_KEY.save)}
        </Button>
      </div>
    </>
  );
};

export default UserReportForm;
