import React, { useEffect, useRef, useState } from "react";

// Antd
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Tooltip,
  TreeSelect,
  Typography,
} from "antd";

// Rest
import { IFile } from "../models/user";
import {
  IMAGE_TYPES,
  MATERIAL_EXTENSIONS,
  TRANSLATION_KEY,
  PART_UOM,
  INPUT_NUMBER_FORMATTER,
} from "../helpers/consts";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IPart, ITreeSelectItem } from "../models/parts";
import {
  createPartXHR,
  getPartsCategoriesXHR,
  updatePartCHR,
} from "../store/reducers/warehouse/actionCreator";
import { getAssetXHR } from "../store/reducers/asstes/actionCreators";
import { getSuppliersXHR } from "../store/reducers/supplier/actionCreator";
import Upload, { RcFile } from "antd/lib/upload";
import { InfoCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import FilesComponent from "../components/Files";
import {
  compressImage,
  createTreeSelect,
  setItemsWithoutParentToHighestNode,
} from "../helpers/functions";

import PartCategoryForm from "./PartCategoryForm";
import { CustomFieldValues, ICompanyCustomFields, ICustomValue } from "../models/settings";

import { ButtonSaveType, IApiResponse } from "../models";

import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";

export interface IPartForm {
  category: number | null;
  description: string;
  code: string;
  uom: string;
  name: string;
  critical_qty: string | number | null;
  spend?: any;
  part_storage?: IPartFormStorage;
  custom_fields: ICustomValue[];
  assets: number[] | undefined;
  custom_fields_v2: CustomFieldValues;
}

export interface IPartFormStorage {
  qty: number;
  location: number;
  supplier?: any;
  price: number;
  qty_uom: string;
  price_uom: string;
  custom_fields_v2: CustomFieldValues;
}

export type IPartFormFinal = Omit<IPartForm, "part_storage" | "category" | "critical_qty"> &
  IPartFormStorage & {
    category: number | null;
    assets: number[] | undefined;
    critical_qty: string | null | number;
    custom_fields: ICustomValue[];
  };

interface IProps {
  part?: null | IPart;
  close: () => void;
}

const PartForm: React.FC<IProps> = ({ part, close }) => {
  // Hooks
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  // Variables
  const [addCategoryVisible, set_addCategoryVisible] = useState<boolean>(false);
  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const { categories, createPartStatus, updatePartStatus } = useAppSelector(
    (state) => state.warehouseReducer,
  );
  const { assetList } = useAppSelector((state) => state.assetReducer);
  const { suppliers } = useAppSelector((state) => state.supplierReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const { companyCustomFieldsV2, currencies } = useAppSelector((state) => state.settingsReducer);
  const [selectedCategory, set_selectedCategory] = useState<number>();
  const [addQuantity, set_addQuantity] = useState<boolean>(false);
  const updating = part?.id;

  const [loadingSave, set_loadingSave] = useState<ButtonSaveType>();
  const inputCodeRef = useRef<Input>(null);

  // Methods
  useEffect(() => {
    getPartsCategoriesXHR({}, dispatch);
    getSuppliersXHR({}, dispatch);
    getAssetXHR({ errorCallback: (data: any) => message.error(t("errorOnFetchData")) }, dispatch);
  }, []);

  const onFinish = async (values: IPartFormFinal & { [key: string]: ICustomValue[] }) => {
    // Custom fields

    // Form object
    const _form: IPartForm = {
      description: values.description || "",
      code: values.code,
      name: values.name,
      spend: null,
      category: values.category || null,
      uom: values.uom || "",
      critical_qty: values.critical_qty?.toString() || null,
      custom_fields: [],
      assets: values.assets?.map((x) => Number(x)) || [],
      custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.items),
    };

    if (values.qty) {
      _form.part_storage = {
        qty: values.qty >= 1000000 ? 999999 : values.qty,
        qty_uom: values.uom,
        price: values.price,
        price_uom: values.price_uom,
        location: values.location,
        custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.entry),
      };
    }

    // Form data

    if (updating) {
      // Update part
      updatePartCHR(
        {
          id: part.id.toString(),
          body: _form,
          errorCallback: (error: any) => {
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
            if (error.response.data?.message?.custom_field) {
              message.error(
                t(error.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  error.response.data.message.custom_field,
                ),
              );
              return;
            }
          },
          successCallback: (data: any) => close(),
        },
        dispatch,
      );
      return;
    }
    // Add part
    let formData = new FormData();
    formData.append("data", JSON.stringify(_form));

    //? Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    //? Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    //? Call axios after all requests
    requests.then(() =>
      createPartXHR(
        {
          body: formData,
          errorCallback: (error: any) => {
            if (error?.response?.data?.message === "article_with_code_exists") {
              message.error(t(TRANSLATION_KEY.article_with_code_exists));
              return;
            }
            if (error.response.data?.message?.custom_field) {
              message.error(
                t(error.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  error.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          successCallback: (data: any) => {
            if (loadingSave === "save") {
              close();
            }

            if (loadingSave === "saveAndAdd") {
              set_loadingSave(undefined);
              let drawer = document.querySelector(".ant-drawer-body");
              if (drawer) {
                drawer.scrollTop = 0;
              }
              form.resetFields();
              set_files([]);
              set_previewFiles([]);
              inputCodeRef.current?.focus();
            }
          },
        },
        dispatch,
      ),
    );
  };

  let el = document.getElementsByClassName("ant-drawer-body")[0];
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // Scroll to the first error field
    if (errorInfo.errorFields.length > 0) {
      form.scrollToField(errorInfo.errorFields[0].name);
    }
    // Alternatively, scroll to the top of the page
    if (el) {
      el.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (part) {
      set_selectedCategory(part.category?.id);
    }
  }, [part]);

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  const initialValues: Partial<IPartFormFinal> = {
    category: undefined,
    description: "",
    code: "",
    uom: "",
    name: "",
    critical_qty: undefined,
    spend: undefined,
    qty: undefined,
    location: undefined,
    supplier: undefined,
    price: undefined,
    qty_uom: "",
    price_uom: user.account.company.currency,
    custom_fields: [],
    custom_fields_v2: {},
  };

  if (part) {
    initialValues.category = part.category?.id || null;
    initialValues.description = part.description || "";
    initialValues.code = part.code;
    initialValues.uom = part.uom;
    initialValues.name = part.name;
    initialValues.critical_qty = part.critical_qty;
    initialValues.qty = part.total_current_qty;
    initialValues.custom_fields = part.custom_fields;
  }

  // Warehouses tree select

  let warehouses = assetList.filter((item) => item.type === "wrh");

  let parsedWarehouses = setItemsWithoutParentToHighestNode(warehouses);

  const tree = createTreeSelect(
    parsedWarehouses.map((x) => ({
      value: x.id.toString(),
      title: x.name,
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  let assetTree = createTreeSelect(
    setItemsWithoutParentToHighestNode(assetList).map((x) => ({
      title: x.path,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  return (
    <>
      <Modal
        footer={null}
        destroyOnClose
        title={t(TRANSLATION_KEY.newItemCategory)}
        visible={addCategoryVisible}
        onCancel={() => set_addCategoryVisible(false)}
      >
        <PartCategoryForm
          successCallback={(id: number) => {
            form.setFieldsValue({ category: id });
            set_selectedCategory(id);
          }}
          close={() => set_addCategoryVisible(false)}
          category={{
            parent: null,
            name: "",
            id: 0,
          }}
          categories={categories}
        />
      </Modal>
      <Form
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="code"
          label={t(TRANSLATION_KEY.code)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <Input ref={inputCodeRef} />
        </Form.Item>

        <Form.Item
          name="name"
          label={t(TRANSLATION_KEY.name)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <Input />
        </Form.Item>

        {!updating && (
          <Form.Item name="assets" label={t(TRANSLATION_KEY.asset)}>
            <TreeSelect
              // treeNodeFilterProp="title"
              showSearch
              multiple
              // treeCheckable
              filterTreeNode={(search, item: any) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
              }}
              maxTagCount={3}
              treeData={assetTree}
            />
          </Form.Item>
        )}

        <Form.Item
          name="category"
          label={
            <div className="spaceBetweenRow">
              <div>{t(TRANSLATION_KEY.category)}</div>
              <div style={{ marginLeft: 12 }}>
                <Button
                  size="small"
                  icon={<PlusOutlined />}
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    set_addCategoryVisible(true);
                  }}
                />{" "}
              </div>
            </div>
          }
        >
          <Select
            allowClear
            onChange={(val) => {
              set_selectedCategory(val || undefined);
            }}
          >
            {categories.map((x, index) => (
              <Select.Option key={index} value={x.id}>
                {x.name || ""}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div style={{ width: "100%", display: "flex", gap: 24 }}>
          <div style={{ width: "50%" }}>
            <Form.Item name="critical_qty" label={t(TRANSLATION_KEY.criticalQty)}>
              <InputNumber {...INPUT_NUMBER_FORMATTER} style={{ width: "100%" }} />
            </Form.Item>
          </div>

          <div style={{ width: "50%" }}>
            <Form.Item
              name="uom"
              label={t(TRANSLATION_KEY.uom)}
              rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
            >
              <Select>
                {PART_UOM.map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {t(x || "")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>

        <Form.Item name="description" label={t(TRANSLATION_KEY.description)}>
          <Input.TextArea rows={6} />
        </Form.Item>
        {/* Dynamic columns */}
        {companyCustomFieldsV2 && (
          <GenerateForCustomFieldsV2
            values={part?.custom_fields_v2}
            condition={selectedCategory || undefined}
            customFields={companyCustomFieldsV2.items}
            form={form}
          />
        )}

        {!updating && (
          <>
            <div style={{ padding: "9px 0", textAlign: "center" }}>
              <Button
                loading={false}
                onClick={() => set_addQuantity(!addQuantity)}
                block
                type="dashed"
              >
                {addQuantity ? t(TRANSLATION_KEY.close) : t(TRANSLATION_KEY.addQuantity)}
              </Button>
            </div>

            <div>
              {addQuantity && (
                <>
                  <Divider plain>
                    <div style={{ opacity: 0.65, fontSize: 12 }}>
                      {t(TRANSLATION_KEY.partStorage)}
                    </div>
                  </Divider>

                  <Form.Item
                    name="qty"
                    label={t(TRANSLATION_KEY.qty)}
                    rules={[
                      {
                        required: true,
                        message: t(TRANSLATION_KEY.filedRequired),
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      // max={100000}
                      decimalSeparator=","
                      onChange={(value: number) => {
                        if (value >= 999999) {
                          form.setFieldsValue({ qty: 999999 });
                        }
                      }}
                      formatter={(value: any) => {
                        let formattedValue = Number(value) >= 1000000 ? 999999 : value;
                        return `${formattedValue}`.replace(".", ",");
                      }}
                      onBlur={(event) => {
                        const { value } = event.target;
                        let _value = Number(value) >= 1000000 ? 999999 : value;
                        let formattedValue = Number(_value.toString().replace(",", "."));
                        form.setFieldsValue({ qty: formattedValue.toFixed(2) });
                      }}
                    />
                  </Form.Item>

                  <div style={{ width: "100%", display: "flex", gap: 24 }}>
                    <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                      <Form.Item
                        name="price"
                        label={t(TRANSLATION_KEY.price)}
                        rules={[
                          {
                            required: true,
                            message: t(TRANSLATION_KEY.filedRequired),
                          },
                        ]}
                      >
                        <InputNumber {...INPUT_NUMBER_FORMATTER} style={{ width: "100%" }} />
                      </Form.Item>
                      <Tooltip
                        style={{ textAlign: "center" }}
                        title={t(TRANSLATION_KEY.unitPriceInfo)}
                      >
                        <InfoCircleOutlined style={{ marginTop: 6, marginLeft: 8 }} />
                      </Tooltip>
                    </div>

                    <div style={{ width: "50%" }}>
                      <Form.Item
                        name="price_uom"
                        label={t(TRANSLATION_KEY.priceUom)}
                        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
                      >
                        <Select>
                          {currencies.map((x, index) => (
                            <Select.Option key={index} value={x}>
                              {x}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <Form.Item
                    name="location"
                    label={t(TRANSLATION_KEY.warehouse)}
                    rules={[
                      {
                        required: true,
                        message: t(TRANSLATION_KEY.filedRequired),
                      },
                    ]}
                  >
                    {/* <Select>
                      {assetList
                        .filter((item) => item.type === "wrh")
                        .map((x, index) => (
                          <Select.Option key={index} value={x.id}>
                            {x.name || ""}
                          </Select.Option>
                        ))}
                    </Select> */}
                    <TreeSelect
                      treeData={tree}
                      showSearch
                      filterTreeNode={(search, item: any) => {
                        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="supplier"
                    label={t(TRANSLATION_KEY.supplier)}
                    // rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
                  >
                    <Select>
                      {suppliers.map((x, index) => (
                        <Select.Option key={index} value={x.id}>
                          {x.name || ""}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {companyCustomFieldsV2 && (
                    <GenerateForCustomFieldsV2
                      values={{}}
                      condition={undefined}
                      customFields={companyCustomFieldsV2.entry}
                      form={form}
                    />
                  )}

                  <Divider plain>
                    <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
                  </Divider>

                  <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
                    <Typography.Title level={5}>{t(TRANSLATION_KEY.documents)}</Typography.Title>
                    <Upload
                      multiple={true}
                      accept={MATERIAL_EXTENSIONS}
                      beforeUpload={beforeUpload}
                      showUploadList={false}
                    >
                      <Button type="primary" shape="circle" icon={<UploadOutlined />} />
                    </Upload>
                  </div>

                  {/* Files list */}
                  <FilesComponent
                    files={previewFiles}
                    onDelete={onRemove}
                    hideEditButton
                    size="small"
                    layout="list"
                  />
                </>
              )}
            </div>
          </>
        )}

        <Form.Item>
          <Space>
            <Button
              loading={
                (createPartStatus === "loading" || updatePartStatus === "loading") &&
                loadingSave === "save"
              }
              onClick={() => {
                set_loadingSave("save");
              }}
              htmlType="submit"
              type="primary"
              style={{ marginTop: 24 }}
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
            <Button
              onClick={() => {
                set_loadingSave("saveAndAdd");
              }}
              loading={
                (createPartStatus === "loading" || updatePartStatus === "loading") &&
                loadingSave === "saveAndAdd"
              }
              htmlType="submit"
              type="primary"
              style={{ marginTop: 24, display: updating ? "none" : "block" }}
            >
              {t(TRANSLATION_KEY.saveAndAdd)}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default PartForm;
