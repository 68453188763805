import { DeleteOutlined, EditOutlined, PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Modal, Popconfirm, Row, Space, Spin, Table, Tabs } from "antd";
import { t, use } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import CustomFieldForm from "../../../../../componentsform/CustomFieldForm";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IApiResponse, ITableColumn } from "../../../../../models";
import {
  CustomFieldV2,
  ICompanyCustomFields,
  ICompanySettings,
  ICustomField,
} from "../../../../../models/settings";
import { updateCompanyCustomFieldsXHR } from "../../../../../store/reducers/settings/actionCreator";
import WorkOrder from "./WorkOrder";
import ConfirmOrder from "./ConfirmOrder";
import CustomFieldFormV2, {
  FormModal,
  FormType,
  getFormSetup,
} from "../../../../../componentsform/CustomFieldsFormV2";

const { TabPane } = Tabs;

const Maintenance: React.FC = () => {
  const [modalVisible, set_modalVisible] = useState<FormModal>();
  const dispatch = useAppDispatch();

  const { maintenancesTypes } = useAppSelector((state) => state.maintenanceReducer);

  const [activeKey, set_activeKey] = useState<FormType>("workorder");

  const onDelete = (cf_id: string, form: FormType) => {};

  const updateFields = () => {};

  const closeModal = () => {
    set_modalVisible(undefined);
  };

  const onChange = (key: string) => {
    set_activeKey(key as FormType);
  };

  let formSetup = useMemo(
    () =>
      getFormSetup(
        modalVisible?.type as FormType,
        modalVisible?.customField,
        activeKey === "workorder" ? [...maintenancesTypes] : undefined,
      ),
    [activeKey, modalVisible, maintenancesTypes],
  );

  return (
    <div>
      <Spin spinning={false}>
        <CustomFieldFormV2
          modalProps={{
            visible: modalVisible !== undefined,
            onCancel: closeModal,
            title: formSetup.title,
            destroyOnClose: true,
          }}
          formType={modalVisible?.type || "workorder"}
          data={modalVisible?.customField}
          condition={formSetup.condition}
        />
        <Tabs
          size="small"
          tabPosition="top"
          defaultActiveKey="workorder"
          onChange={onChange}
          tabBarExtraContent={
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                set_modalVisible({
                  type: activeKey,
                  customField: {} as CustomFieldV2,
                })
              }
              type="primary"
              shape="circle"
              style={{ marginBottom: 8, float: "right" }}
            />
          }
        >
          <TabPane tab={t(TRANSLATION_KEY.maintenance)} key="workorder">
            <WorkOrder maintenancesTypes={maintenancesTypes} onEdit={set_modalVisible} />
          </TabPane>
          <TabPane tab={t(TRANSLATION_KEY.confirmOrder)} key="orderconfirm">
            <ConfirmOrder onEdit={set_modalVisible} />
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

export default Maintenance;
