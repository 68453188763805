import { Col, Row } from "antd";
import AssetCategories from "./components/AssetCategories";
import MaintenanceCategory from "./components/MaintenanceCategory";
import MaintenancesOnHoldTypes from "./components/MaintenancesOnHoldTypes";
import MaintenancesTypes from "./components/MaintenancesTypes";
import PartsCategories from "./components/PartsCategories";
import SupplierCategory from "./components/SupplierCategory";

const Categories: React.FC = () => {
  return (
    <Row gutter={[24, 24]}>
      {/* Left */}
      <Col lg={24} xl={16}>
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={12} xl={12}>
            <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
              <AssetCategories />
            </div>
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
              <MaintenanceCategory />
            </div>
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
              <MaintenancesOnHoldTypes />
            </div>
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
              <MaintenancesTypes />
            </div>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 12, offset: 12 }}
          >
            <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
              <SupplierCategory />
            </div>
          </Col>
        </Row>
      </Col>

      {/* Right */}
      <Col xs={24} lg={24} xl={8}>
        <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
          <PartsCategories />
        </div>
      </Col>
    </Row>
  );
};

export default Categories;
