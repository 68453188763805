import { Button, message } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IMaintenanceDetails } from "../../../../models/maintenances";
import { maintenanceSlice } from "../../../../store/reducers/maintenance";
import { takeMaintenanceXHR } from "../../../../store/reducers/maintenance/actionCreator";

interface IProps {
  maintenance: IMaintenanceDetails;
  onClose: () => void;
}

const Take: React.FC<IProps> = ({ maintenance, onClose }) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      onClick={() => {
        takeMaintenanceXHR(
          {
            body: { order_info: maintenance.id },
            successCallback: () => {
              onClose();
            },
            errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
          },
          dispatch,
        );
      }}
    >
      {t(TRANSLATION_KEY.takeMaintenance)}
    </Button>
  );
};

export default Take;
