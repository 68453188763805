import React, { useEffect, useState } from "react";

// Antd
import { Button, Form, Input, message, Select, TreeSelect, Typography } from "antd";

// Rest
import { TRANSLATION_KEY } from "../helpers/consts";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IPart, IPartDetails, IPartStorage, ITreeSelectItem, IPartSpend } from "../models/parts";
import { getAssetXHR } from "../store/reducers/asstes/actionCreators";
import { spendPartWithoutOrderXHR, spendPartXHR } from "../store/reducers/warehouse/actionCreator";
import warehouse, { warehouseSlice } from "../store/reducers/warehouse";
import { IApiResponse, ICursor } from "../models";
import {
  createTreeSelect,
  getAllPartStoreagesForSelect,
  setItemsWithoutParentToHighestNode,
} from "../helpers/functions";
import { IAsset } from "../models/asset";
import { unstable_batchedUpdates } from "react-dom";
import { ISpendPart } from "./SpendPartForm";
import EventBus from "../EventBus";
import { spendHistoryRefresh } from "../pages/part/pages/SpendHistory";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";

interface IProps {
  cursor?: ICursor;
  part?: IPart;
  partStorage?: null | IPartStorage;
  close: () => void;
  type: "parts_list" | "part_details";
}

const SpendPart: React.FC<IProps> = ({ part, partStorage, close, type, cursor }) => {
  // Hooks
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  // Variables
  const { spendPartStatus, parts } = useAppSelector((state) => state.warehouseReducer);
  const { assetList } = useAppSelector((state) => state.assetReducer);
  const partFromStore = useAppSelector((state) => state.warehouseReducer.part);
  const [avaliableQuantity, set_avaliableQuantity] = useState<number>(partStorage?.qty || 0);

  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);

  // Methods
  useEffect(() => {
    getAssetXHR({ errorCallback: (data: any) => message.error(t("errorOnFetchData")) }, dispatch);
  }, []);

  const onFinish = async (values: ISpendPart) => {
    const body: ISpendPart = {
      ...values,
      qty: Number(values.qty),
      name: "",
      order_info: null,
      part: part?.id!,
      note: values.note || "",
      custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.spend),
    };

    // Form data
    let formData = new FormData();
    formData.append("data", JSON.stringify(body));
    formData.append("files", "");

    spendPartWithoutOrderXHR(
      {
        body: formData,
        errorCallback: (err: any) => {
          if (err.response.data?.message?.custom_field) {
            message.error(
              t(err.response.data.message.message || "").replace(
                "$_dynamic_column",
                err.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data: IApiResponse<IPartDetails>) => {
          if (type === "parts_list") {
            let tmpParts = parts.data.map((item) => {
              if (item.id === data.results?.id) {
                return data.results!;
              }
              return item;
            });
            if (!cursor) {
              return;
            }
            dispatch(
              warehouseSlice.actions.getPartsSuccess({
                message: "",
                mergeData: false,
                results: {
                  data: tmpParts,
                  cursor,
                },
              }),
            );
          } else {
            EventBus.emit(spendHistoryRefresh);
          }
          close();
        },
      },
      dispatch,
    );
  };

  const initialValues: Partial<ISpendPart> = {
    location: partStorage ? partStorage.storage.id : undefined,
  };

  //select mora biti stablo jer skladista takodjer mogu biti stabla, sto je ujedno imovina stoga prvo uzmemo imovinu koju dio ima kao skladiste
  //potom ukolnimo parent id (postavimo na null) ukoliko nema parenta u nizu

  let _warehouses = assetList.filter((item) => item.type === "wrh");
  let parsedWarehouses = setItemsWithoutParentToHighestNode(_warehouses);

  let warehouses: ITreeSelectItem[] = getAllPartStoreagesForSelect(parsedWarehouses, part);

  const tree = createTreeSelect(
    warehouses.map((wrh) => ({ ...wrh, title: wrh.path || wrh.title })),
    null,
  );

  useEffect(() => {
    if (warehouses.length === 1) {
      unstable_batchedUpdates(() => {
        let item = partStorage
          ? partStorage
          : part?.part_storage.find((x) => x.storage.id === +warehouses[0].value);

        set_avaliableQuantity(item?.qty || 0);
        form.setFieldsValue({ location: +warehouses[0].value });
      }, []);
    }
  }, [assetList]);

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
      {partStorage ? (
        <Form.Item
          name="location"
          label={t(TRANSLATION_KEY.location)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <Select defaultValue={partStorage.storage.name} disabled>
            <Select.Option value={partStorage.storage.id}>
              {partStorage.storage.name || ""}
            </Select.Option>
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          name="location"
          label={t(TRANSLATION_KEY.location)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <TreeSelect
            onChange={(id: string) => {
              if (id) {
                let item = part?.part_storage.find((x) => x.storage.id === +id);
                set_avaliableQuantity(item?.qty || 0);
              }
            }}
            treeData={tree}
            showSearch
            filterTreeNode={(search, item: any) => {
              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
          />
        </Form.Item>
      )}
      <Form.Item
        name="qty"
        label={
          <div className="spaceBetweenRow">
            <div>{t(TRANSLATION_KEY.qty)}</div>
            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
              {avaliableQuantity || ""} {avaliableQuantity && (part?.uom ? t(part.uom) : "")}
            </Typography.Text>
          </div>
        }
        hasFeedback
        rules={[
          {
            required: true,
            message: t(TRANSLATION_KEY.filedRequired),
          },
          () => ({
            validator(_, value) {
              if (value <= avaliableQuantity) return Promise.resolve();
              return Promise.reject(new Error(t(TRANSLATION_KEY.spendPartQtyError)));
            },
          }),
        ]}
      >
        <Input disabled={!form.getFieldValue("location")} type="number" />
      </Form.Item>

      <Form.Item name="note" label={t(TRANSLATION_KEY.note)}>
        <Input.TextArea />
      </Form.Item>

      {/* Dynamic columns */}
      {companyCustomFieldsV2 && (
        <GenerateForCustomFieldsV2
          values={{}}
          condition={undefined}
          customFields={companyCustomFieldsV2.spend}
          form={form}
        />
      )}

      <Form.Item>
        <Button
          loading={spendPartStatus === "loading"}
          htmlType="submit"
          type="primary"
          style={{ marginTop: 24 }}
        >
          {t(TRANSLATION_KEY.save)}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SpendPart;
