import { DoubleRightOutlined, LogoutOutlined, ShopTwoTone } from "@ant-design/icons";
import { Avatar, Layout, Menu, Space, Tooltip, Typography } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Header } from "antd/lib/layout/layout";
import { motion } from "framer-motion";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { TRANSLATION_KEY } from "../helpers/consts";
import { getInitials, hasPermission } from "../helpers/functions";
import { useAppSelector } from "../hooks";
import HeaderComponent from "../navigation/Header";
import SiderComponent from "../navigation/Sider";

const { Content } = Layout;

const App: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    if (user.token.access === "") {
      navigate("/login");
    }
  }, [user.token.access]);

  // Hooks
  const { currentTheme, themes } = useThemeSwitcher();

  // Variables
  const [collapsed, setCollapsed] = useState<boolean>(
    localStorage.getItem("sidebarCollapsed") === "true",
  );

  // Methods
  function setSidebarCollapsed() {
    setCollapsed((prevState) => !prevState);
    localStorage.setItem("sidebarCollapsed", JSON.stringify(!collapsed));
  }

  return (
    <Layout id="app-layout" style={{ minHeight: "100vh" }} hasSider>
      {/* Sidebar */}
      <Sider
        theme={currentTheme === themes.dark ? "dark" : "light"}
        collapsible
        collapsed={collapsed}
        onCollapse={setSidebarCollapsed}
        id="app-sider"
        trigger={null}
        style={{
          borderColor: currentTheme === themes.dark ? "#303030" : "#eee",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            height: "100%",
          }}
        >
          <div>
            <Link
              to={
                hasPermission(user.account.permissions, ["manage_settings"])
                  ? "settings/company"
                  : "/"
              }
              className="sider-company-container"
              style={{
                borderColor: currentTheme === themes.dark ? "#303030" : "#eee",
              }}
            >
              <Tooltip
                title={user.account.company.name}
                placement="right"
                zIndex={collapsed ? 1001 : -1}
              >
                <motion.div
                  className="sider-company-wrapper"
                  whileTap={{
                    scale: 0.98,
                    backgroundColor: currentTheme === themes.dark ? "#141414" : "#ddd",
                  }}
                  whileHover={{
                    backgroundColor: currentTheme === themes.dark ? "#1f1f1f" : "#f1f1f1",
                  }}
                  style={{ backgroundColor: currentTheme === themes.dark ? "#141414" : "#fff" }}
                >
                  <motion.div
                    animate={collapsed ? { x: "10px" } : { x: "10%" }}
                    transition={{ ease: "anticipate" }}
                  >
                    {user.account.company.avatar ? (
                      <img
                        src={user.account.company.avatar}
                        alt="Company avatar"
                        style={{
                          width: 36,
                          height: 36,
                          borderRadius: 100,
                          objectFit: "contain",
                          border: `1px solid ${currentTheme === themes.dark ? "#0353ef" : "#eee"}`,
                        }}
                      />
                    ) : (
                      <ShopTwoTone
                        twoToneColor="#0353ef"
                        style={{
                          fontSize: 18,
                          padding: 8,
                          borderRadius: 100,
                          border: `1px solid ${currentTheme === themes.dark ? "#0353ef" : "#eee"}`,
                        }}
                      />
                    )}
                  </motion.div>
                  <motion.div
                    animate={collapsed ? { opacity: 0, x: 40 } : { opacity: 1, x: 0 }}
                    transition={{ ease: "anticipate" }}
                    className="sider-company-name"
                  >
                    <Typography.Text ellipsis={{ tooltip: user.account.company.name || "" }}>
                      {user.account.company.name || ""}
                    </Typography.Text>
                  </motion.div>
                  <motion.div
                    animate={collapsed ? { opacity: 0, x: 60 } : { opacity: 1, x: 0 }}
                    transition={{ ease: "anticipate" }}
                  >
                    <DoubleRightOutlined style={{ opacity: 0.5, marginRight: 4 }} />
                  </motion.div>
                </motion.div>
              </Tooltip>
            </Link>

            {/* Routes */}
            <SiderComponent collapsed={collapsed} authed={false} />
          </div>

          <div style={{ flex: 1 }} />

          <Menu
            id="sider"
            theme="light"
            mode="inline"
            selectable={false}
            inlineCollapsed={collapsed}
            style={{ marginBottom: 10, marginTop: 60, border: "none !important" }}
          >
            <Menu.Item style={{ position: "relative", fontWeight: 500 }} icon={<LogoutOutlined />}>
              <Link to={"logout"} style={{ color: collapsed ? "#fff" : "#333" }}>
                {t(TRANSLATION_KEY.logout)}
              </Link>
            </Menu.Item>
          </Menu>

          {/* User informations */}
          <Link to="profile">
            <motion.div
              whileTap={{
                scale: 0.98,
                backgroundColor: currentTheme === themes.dark ? "#141414" : "#ddd",
              }}
              whileHover={{ backgroundColor: currentTheme === themes.dark ? "#1f1f1f" : "#f1f1f1" }}
              style={{
                padding: "8px 6px",
                backgroundColor: currentTheme === themes.dark ? "#141414" : "#fff",
                margin: "0 6px",
                borderRadius: 4,
                cursor: "pointer",
                marginBottom: 20,
              }}
            >
              <Tooltip title={user.account.name} placement="topLeft" zIndex={collapsed ? 1001 : -1}>
                <Space>
                  <motion.div
                    animate={collapsed ? { x: "10px" } : { x: 0 }}
                    transition={{ ease: "anticipate" }}
                  >
                    <Avatar style={{ backgroundColor: "#0353ef" }} size="default">
                      {getInitials(user.account.name)}
                    </Avatar>
                  </motion.div>
                  <motion.div
                    animate={collapsed ? { opacity: 0 } : { opacity: 1 }}
                    transition={{ ease: "anticipate" }}
                  >
                    <Space direction="vertical">
                      <div style={{ marginBottom: -10, color: "#333" }}>{user.account.name}</div>
                      <Tooltip title={user.account.email} zIndex={collapsed ? -1 : 1001}>
                        <div className="sider-user-mail">{user.account.email}</div>
                      </Tooltip>
                    </Space>
                  </motion.div>
                </Space>
              </Tooltip>
            </motion.div>
          </Link>
        </div>
      </Sider>

      <Layout>
        {/* Header */}
        <motion.div
          animate={{ paddingLeft: collapsed ? 80 : 200 }}
          initial={{ paddingLeft: collapsed ? 80 : 200 }}
          transition={{ ease: "easeInOut", duration: 0.15 }}
          style={{ zIndex: 120 }}
        >
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <HeaderComponent
              authed={true}
              collapsed={collapsed}
              setCollapsed={setSidebarCollapsed}
            />
          </Header>
        </motion.div>

        {/* Content */}
        <motion.div
          animate={{ marginLeft: collapsed ? 80 : 200 }}
          initial={{ marginLeft: collapsed ? 80 : 200 }}
          transition={{ ease: "easeInOut", duration: 0.15 }}
        >
          <Content>
            <div
              className="site-layout-background"
              style={{ padding: 24, paddingTop: 0, minHeight: "calc(100vh - 32px - 64px)" }}
            >
              <Outlet />
            </div>
          </Content>
        </motion.div>
      </Layout>
    </Layout>
  );
};

export default App;
