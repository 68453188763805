import { UploadOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Form,
  Input,
  message,
  Select,
  Spin,
  Upload,
  Row,
  Col,
  Button,
  TreeSelect,
  Typography,
  Divider,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import FilesComponent from "../components/Files";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../components/SelectTagAdd";
import {
  CUSTOM_FIELD_HALF_KEY,
  IMAGE_TYPES,
  MATERIAL_EXTENSIONS,
  PREVENTIVE_TYPE_ID,
  TRANSLATION_KEY,
} from "../helpers/consts";
import { compressImage, createTreeSelect, hasPermission } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IApiResponse, ILocation } from "../models";
import { IMaintenance, MaintenanceStatusTypes } from "../models/maintenances";
import { IFile } from "../models/user";
import api, { failedQueue, isRefreshing } from "../services";
import { getAssetXHR, getSubAssetsXHR } from "../store/reducers/asstes/actionCreators";
import { dashboardSlice } from "../store/reducers/dashboard";
import { maintenanceSlice } from "../store/reducers/maintenance";
import {
  getMaintenancesCategoriesXHR,
  getMaintenancesTypesXHR,
  getPotentialExecutorsXHR,
} from "../store/reducers/maintenance/actionCreator";
import { settingsSlice } from "../store/reducers/settings";
import {
  getCompanyCusstomFieldsXHR,
  getCompanySettingsXHR,
  getLocationsXHR,
} from "../store/reducers/settings/actionCreator";
import GenerateForCustomFields from "./GenerateForCustomFields";
import { ICustomValue } from "../models/settings";
import { IFormExecutors, createExecutorsObject } from "./MaintenanceForm";
import RequirePermission from "../components/RequirePermission";

interface IProps {
  onClose?: () => void;
}

interface ISimpleReportOrder {
  description: string;
  asset: number | null;
  maintenance_type: number | null;
  maintenance_categories: number[];
  location: number | null;
  subassets: number[];
  executors: IFormExecutors | null;
  status: MaintenanceStatusTypes;
}
const { OptGroup, Option } = Select;

const SimpleReportOrder: React.FC<IProps> = ({ onClose }) => {
  // Hooks
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  // Variables
  const [assetSelected, set_assetSelected] = useState<number>(0);
  const [loading, set_loading] = useState<boolean>(false);
  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const [addLocationVisible, set_addLocationVisible] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.userReducer);
  const { locations, getLocationsStatus, companyCustomFields } = useAppSelector(
    (state) => state.settingsReducer,
  );
  const { assetList, getAssetStatus, subAssets, getSubAssetsStatus } = useAppSelector(
    (state) => state.assetReducer,
  );
  const {
    maintenancesTypes,
    maintenanceCategories,
    getMaintenancesCategoriesStatus,
    getMaintenancesTypesStatus,
    liveMaintenances,
    maintenanceList,
    getPotentialExecutorsStatus,
    potentialExecutors,
  } = useAppSelector((state) => state.maintenanceReducer);

  const getData = () => {
    getLocationsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
    getAssetXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
    getMaintenancesCategoriesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
    getMaintenancesTypesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
    getCompanyCusstomFieldsXHR({}, dispatch);
  };

  useEffect(() => {
    getData();
  }, []);

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  const resetForm = () => {
    form.resetFields();
    set_files([]);
    set_previewFiles([]);
  };

  const onFinish = async (
    values: Omit<ISimpleReportOrder, "executors"> & { executors: string | string[] | null },
  ) => {
    set_loading(true);

    let custom_fields: ICustomValue[] = [];
    Object.keys(values).map((key) => {
      if (key.includes(CUSTOM_FIELD_HALF_KEY)) {
        let tmp = companyCustomFields?.order_custom_fields.find((x) => x.key === key);
        custom_fields.push({
          key: key,
          name: tmp?.name || "",
          value: values[key]?.toString() || "",
        });
      }
    });

    let asset = assetList.find((x) => x.id === values?.asset);

    const token = await localStorage.getItem("token");
    values.location =
      values.location ||
      assetList.find((x) => x.id === +form.getFieldValue("asset"))?.location?.id ||
      null;

    values.maintenance_type = values.maintenance_type || null;
    values.maintenance_categories = values.maintenance_categories
      ? values.maintenance_categories
      : [];
    values.subassets = values.subassets ? values.subassets : [];

    let body: ISimpleReportOrder = {
      description: values.description,
      asset: values.asset ? +values.asset : null,
      location:
        values.location ||
        assetList.find((x) => x.id === +form.getFieldValue("asset"))?.location?.id ||
        null,
      executors: createExecutorsObject("employee", values.executors || "", "", "") || null,
      maintenance_type: values.maintenance_type || null,
      maintenance_categories: values.maintenance_categories ? values.maintenance_categories : [],
      subassets: values.subassets ? values.subassets : [],
      status: asset && asset.autopilot ? "open" : "requested",
    };

    // Appending data
    let formData = new FormData();
    formData.append("data", JSON.stringify({ ...body, custom_fields }));

    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    // Call axios after all requests
    requests.then(() =>
      api
        .post<IApiResponse<IMaintenance>>("/maintenance/create_basic_order/", formData, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          if (res.data.results) {
            message.success(t(TRANSLATION_KEY.successOnSaveData));
            let _tmpAfterAdd = {
              liveMaintenances: [...liveMaintenances],
              maintenanceList: {
                cursor: maintenanceList.cursor,
                data: [...maintenanceList.data],
              },
            };

            _tmpAfterAdd.liveMaintenances.push(res.data.results);
            _tmpAfterAdd.maintenanceList.data.unshift(res.data.results);
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                results: { data: _tmpAfterAdd.liveMaintenances, cursor: null },
                message: "",
              }),
            );
            dispatch(dashboardSlice.actions.set_categoriesTypes(_tmpAfterAdd.liveMaintenances));
            resetForm();
            set_loading(false);
            onClose && onClose();
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            if (isRefreshing) {
              failedQueue.push(() => onFinish(values));
            }
            return;
          }
          console.log(error);
          message.error(t(TRANSLATION_KEY.errorOnSaveData), 3);
          set_loading(false);
        }),
    );
  };

  const tree = createTreeSelect(
    assetList.map((x) => ({
      title: x.path,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: !x.can_report_order,
    })),
    null,
  );

  //ukoliko imovina ima lokaciju sakrij select lokacija za nalog
  let showLocationInput = !assetList.find((x) => x.id === +form.getFieldValue("asset"))?.location;

  const setPotentialExecutors = (asset_id?: number) => {
    if (
      !asset_id ||
      user.account.permissions?.filter((x) => x.codename === "manage_wo_executors").length === 0
    ) {
      return;
    }
    form.setFieldsValue({ executor_type: "employee" });
    getPotentialExecutorsXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          asset: asset_id,
        },
      },
      dispatch,
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Spin
            spinning={
              getLocationsStatus === "loading" ||
              getMaintenancesCategoriesStatus === "loading" ||
              getMaintenancesTypesStatus === "loading" ||
              getAssetStatus === "loading" ||
              loading
            }
          >
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <Form.Item
                rules={[{ required: false, message: t(TRANSLATION_KEY.filedRequired) }]}
                label={t(TRANSLATION_KEY.asset)}
                name={"asset"}
              >
                <TreeSelect
                  treeNodeFilterProp="title"
                  showSearch
                  filterTreeNode={(search, item: any) => {
                    return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                  }}
                  onChange={(id: string) => {
                    if (id) {
                      let asset = assetList.find((x) => x.id === +id);

                      unstable_batchedUpdates(() => {
                        set_assetSelected(+id);
                        setPotentialExecutors(+form.getFieldValue("asset"));

                        getSubAssetsXHR(
                          {
                            queryParams: { asset: id },
                          },
                          dispatch,
                        );

                        form.setFieldsValue({
                          location: asset?.location?.id || null,
                        });
                      }, []);
                    }
                  }}
                  treeData={tree}
                />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
                label={t(TRANSLATION_KEY.description)}
                name={"description"}
              >
                <Input.TextArea rows={3} maxLength={300} />
              </Form.Item>

              <Row>
                <Col style={{ paddingRight: 3 }} span={12}>
                  {showLocationInput && (
                    <Form.Item name={"location"} label={t(TRANSLATION_KEY.location)}>
                      <Select
                        disabled={!assetSelected}
                        allowClear={true}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          if (typeof option?.children === "string") {
                            const str: string = option.children;
                            return str.toLowerCase().includes(input.toLowerCase());
                          }
                          return false;
                        }}
                        showSearch={true}
                        notFoundContent={
                          <ButtonAddSelectTag
                            addingVisible={addLocationVisible}
                            set_addingVisible={set_addLocationVisible}
                          />
                        }
                      >
                        {locations.map((x) => (
                          <Select.Option value={x.id} key={x.id}>
                            {x.name}
                          </Select.Option>
                        ))}
                        <Select.OptGroup
                          label={
                            <ButtonAddSelectTag
                              addingVisible={addLocationVisible}
                              set_addingVisible={set_addLocationVisible}
                            />
                          }
                        />
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item label={t(TRANSLATION_KEY.maintenanceTypes)} name={"maintenance_type"}>
                    <Select allowClear={true}>
                      {maintenancesTypes.map((x) => (
                        <Select.Option key={x.id} value={x.id}>
                          {t(x.trans_key || x.name)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col style={{ paddingLeft: 3 }} span={12}>
                  <Form.Item
                    label={t(TRANSLATION_KEY.maintenanceCategory)}
                    name="maintenance_categories"
                  >
                    <Select mode="multiple" allowClear>
                      {maintenanceCategories.map((x) => (
                        <Select.Option key={x.id} value={x.id}>
                          {t(x.trans_key || x.name)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="subassets" label={t(TRANSLATION_KEY.assetMarks)}>
                    <Select
                      allowClear
                      mode="multiple"
                      disabled={!assetSelected}
                      loading={getSubAssetsStatus === "loading"}
                    >
                      {subAssets.map((x) => (
                        <Select.Option key={x.id} value={x.id}>
                          {x.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {companyCustomFields && (
                    <GenerateForCustomFields
                      customFields={companyCustomFields.order_custom_fields}
                    />
                  )}
                </Col>
              </Row>
              <RequirePermission
                requiredPermissionCode={["manage_wo_executors"]}
                children={
                  <Spin spinning={getPotentialExecutorsStatus === "loading"}>
                    <Form.Item label={t(TRANSLATION_KEY.executors)} name={"executors"}>
                      <Select
                        allowClear
                        disabled={!assetSelected}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          if (typeof option?.children === "string") {
                            const str: string = option.children;
                            return str.toLowerCase().includes(input.toLowerCase());
                          }
                          return false;
                        }}
                        showSearch={true}
                        mode={"multiple"}
                      >
                        <OptGroup label={t(TRANSLATION_KEY.employee)}>
                          {potentialExecutors.employees.map((x) => (
                            <Option key={x.id + "|e"} value={x.id + "|e"}>
                              {x.name}
                            </Option>
                          ))}
                        </OptGroup>
                        {/* <OptGroup label={TRANSLATION_KEY.partner}>
  {potentialExecutors.partners.map((x) => (
     <Option key={x.id + "|p"} value={x.id + "|p"}>
       {x.name}
     </Option>
   ))}
   </OptGroup> */}
                      </Select>
                    </Form.Item>
                  </Spin>
                }
              />

              <Divider plain>
                <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
              </Divider>
              <div className="spaceBetweenRow" style={{ alignItems: "flex-start" }}>
                <Typography.Paragraph style={{ marginBottom: 0, marginRight: 12 }}>
                  {t(TRANSLATION_KEY.documents)}
                </Typography.Paragraph>
                <Upload
                  multiple={true}
                  accept={MATERIAL_EXTENSIONS}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                >
                  <Button type="primary" shape="circle" icon={<UploadOutlined />} />
                </Upload>
              </div>

              {/* Files list */}
              <FilesComponent
                files={previewFiles}
                onDelete={onRemove}
                hideEditButton
                size="small"
                layout="list"
              />

              {/* Submit button */}
              <Form.Item>
                <Button loading={false} htmlType="submit" type="primary">
                  {t(TRANSLATION_KEY.save)}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>
      </Row>
      {addLocationVisible && (
        <SelectTagAdd
          title={t(TRANSLATION_KEY.addLocation)}
          url="settings/locations/"
          set_visible={set_addLocationVisible}
          visible={addLocationVisible}
          successCallback={(tag: Tag) => {
            const data = [...locations];
            data.push({ id: tag.id, name: tag.name });
            const resFake: IApiResponse<ILocation[]> = {
              message: "",
              results: data,
            };

            dispatch(settingsSlice.actions.getLocationsSuccess(resFake));

            form.setFieldsValue({ location: tag.id });
          }}
        />
      )}
    </>
  );
};

export default SimpleReportOrder;
