import { Button, Drawer, PageHeader, Tabs } from "antd";
import { t } from "i18next";
import { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import MaintenanceForm, { IMaintenanceForm } from "../../componentsform/MaintenanceForm";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { AppRoutes } from "../../navigation/routes";
import { colors } from "../../styles/colors";
import { OrderType } from "../../models";
import { useAppSelector } from "../../hooks";
import RequirePermission from "../../components/RequirePermission";

const { TabPane } = Tabs;

const Maintenances: React.FC = () => {
  // Variables
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerVisible, set_drawerVisible] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.userReducer);
  const [addMaintenance, set_addMaintenance] = useState<IMaintenanceForm>();
  const orderType: OrderType = user.account.permissions.some(
    (permission) => permission.codename === "manage_wo",
  )
    ? "full"
    : "simple";

  // Methods
  useEffect(() => {
    if (location.pathname !== "/app/maintenances/calendar") {
      set_drawerVisible(false);
    }
  }, [location]);

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  return (
    <div
      style={{
        position: "relative",
        height: location.pathname === "/app/maintenances/calendar" ? "calc(100vh - 88px)" : "100%",
        overflow: location.pathname === "/app/maintenances/calendar" ? "hidden" : "auto",
      }}
    >
      <PageHeader
        style={{ padding: 0, paddingTop: 10, marginBottom: 2 }}
        title={t(TRANSLATION_KEY.maintenances)}
        extra={[
          <RequirePermission
            requiredPermissionCode={["manage_wo"]}
            children={
              <Button
                onClick={() =>
                  set_addMaintenance({
                    workorder_custom_fields_v2: {},
                    orderconfirm_custom_fields_v2: {},
                    client: null,
                    work_request: null,
                    subassets: [],
                    description: "",
                    asset: null,
                    maintenance_type: null,
                    maintenance_categories: [],
                    location: null,
                    planned_start: null,
                    time_needed: "",
                    deadline: null,
                    executors: [],
                    executor_type: "employee",
                    custom_fields: [],
                    checklist: null,
                    status: null,
                  })
                }
                type="primary"
              >
                {t(TRANSLATION_KEY.add)}
              </Button>
            }
          />,
        ]}
        footer={
          <Tabs
            id="bordered-tabs"
            activeKey={location.pathname.split("/")[3]}
            onChange={onTabChange}
          >
            {AppRoutes("allroutes")
              .children?.find((x) => x.path === "maintenances")
              ?.children?.map((x) => (
                <TabPane
                  tab={
                    <Link style={{ fontSize: 14 }} to={x.path}>
                      {t(x.path || "maintenancelist")}
                    </Link>
                  }
                  key={x.path}
                />
              ))}
          </Tabs>
        }
      />

      <Outlet />

      {/* Form Drawer */}
      <RequirePermission
        requiredPermissionCode={["manage_wo"]}
        children={
          <Drawer
            title={t(TRANSLATION_KEY.addOrder)}
            width={540}
            destroyOnClose={true}
            children={
              <MaintenanceForm redirectTo="profile" onClose={() => set_addMaintenance(undefined)} />
            }
            visible={!!addMaintenance}
            onClose={() => set_addMaintenance(undefined)}
          />
        }
      />
    </div>
  );
};

export default Maintenances;

// Creating Drop Events Context
type DropEventContext = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

export function useDropEventsDrawer() {
  return useOutletContext<DropEventContext>();
}
