// Antd
import {
  Row,
  Col,
  Drawer,
  Descriptions,
  Divider,
  Button,
  Tooltip,
  Modal,
  Image,
  Upload,
  Spin,
  message,
  Table,
  Popconfirm,
} from "antd";

// Rest
import PartMaterials from "../components/Materials";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";
import PartStorageCard from "../components/PartStorageCard";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AddPartToStorage from "../../../componentsform/AddPartToStorage";
import { IPartDetails, IPartStorage } from "../../../models/parts";
import SpendPart from "../../../componentsform/SpendPart";
import DefaultPlaceholder from "../../../assets/icons/default-placeholder.png";
import { RcFile } from "antd/lib/upload";
import api, { failedQueue, isRefreshing } from "../../../services";
import { IApiResponse, ITableColumn } from "../../../models";
import { useDispatch } from "react-redux";

import { warehouseSlice } from "../../../store/reducers/warehouse";
import { parseCosts, parseDecimalDots } from "../../../helpers/functions";
import WarehouseList from "../components/WarehouseList";
import { getCompanyCusstomFieldsXHR } from "../../../store/reducers/settings/actionCreator";
import CustomFieldsSpecGenerator from "../../../components/CustomFieldsSpecGenerator";

type Props = {};

const PartSpec = (props: Props) => {
  // Hooks
  const dispatch = useDispatch();

  // Variables
  const { part } = useAppSelector((state) => state.warehouseReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const { companyCustomFields } = useAppSelector((state) => state.settingsReducer);
  const [currentPartStorage, set_currentPartStorage] = useState<IPartStorage | null>(null);
  const [drawerVisible, set_drawerVisible] = useState<boolean>(false);
  const [spendPartModalVisible, set_spendPartModalVisible] = useState<boolean>(false);
  const [avatarLoading, set_avatarLoading] = useState<boolean>(false);

  function handleOnCardClick(item: IPartStorage) {
    set_spendPartModalVisible(true);
    set_currentPartStorage(item);
  }

  // Methods
  useEffect(() => {
    getCompanyCusstomFieldsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  const validate = (value: string | number | null | undefined) => value || "";

  const beforeUpload = async (file: RcFile) => {
    set_avatarLoading(true);
    const token = await localStorage.getItem("token");
    const formData = new FormData();
    formData.append("files", file);

    try {
      let response = api.post<IApiResponse<IPartDetails>>(
        `/warehouse/parts/${part.id}/set_avatar/`,
        formData,
        { headers: { Authorization: "Bearer " + token } },
      );

      const fakeRes: IApiResponse<IPartDetails> = {
        message: "",
        results: (await response).data.results,
      };
      dispatch(warehouseSlice.actions.retrievePartSuccess(fakeRes));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => beforeUpload(file));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_avatarLoading(false);
    return false;
  };

  const handleDelete = async () => {
    set_avatarLoading(true);
    const token = await localStorage.getItem("token");
    const formData = new FormData();
    // formData.append("files", file);
    try {
      let response = api.post<IApiResponse<{ avatar: string }>>(
        `/warehouse/parts/${part.id}/remove_avatar/`,
        formData,
        { headers: { Authorization: "Bearer " + token } },
      );

      const data = { ...part };
      data.avatar = null;
      const fakeRes: IApiResponse<IPartDetails> = {
        message: "",
        results: data,
      };
      dispatch(warehouseSlice.actions.retrievePartSuccess(fakeRes));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => handleDelete());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_avatarLoading(false);
    return false;
  };

  // Content
  const QTYLabel = (
    <div className="spaceBetweenRow">
      <div style={{ opacity: 0.6 }}>{t(TRANSLATION_KEY.qty)}</div>
      <Tooltip title={t(TRANSLATION_KEY.addPartToStorage)}>
        <Button
          onClick={() => set_drawerVisible(true)}
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          size="small"
          style={{ marginLeft: 10, opacity: 1 }}
        />
      </Tooltip>
    </div>
  );

  let total_current_qty = 0;
  part.part_storage.forEach((item) => {
    total_current_qty += item.qty;
  });

  return (
    <div style={{ paddingBottom: 40 }}>
      <Row gutter={24}>
        <Col span={24}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                backgroundColor: "white",
                position: "relative",
                marginRight: 24,
                height: 132,
              }}
            >
              <Spin spinning={avatarLoading} style={{ width: 240, height: 132 }}>
                <Image
                  width="100%"
                  height="100%"
                  src={part.avatar || "error"}
                  style={{ width: 240, height: 132, objectFit: "contain" }}
                  fallback={require("../../../assets/icons/default.jpg")}
                />
                <div style={{ position: "absolute", bottom: 10, right: 10 }}>
                  <Upload
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept=".jpeg,.jpg,.png"
                  >
                    <Button type="primary" shape="circle">
                      {part.avatar ? <EditOutlined /> : <PlusOutlined />}
                    </Button>
                  </Upload>
                  {part.avatar && (
                    <Popconfirm
                      onConfirm={handleDelete}
                      title={t(TRANSLATION_KEY.continueWithAction)}
                      cancelText={t(TRANSLATION_KEY.no)}
                      okText={t(TRANSLATION_KEY.yes)}
                      placement="top"
                    >
                      <Button
                        danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        style={{ marginLeft: 12 }}
                      />
                    </Popconfirm>
                  )}
                </div>
              </Spin>
            </div>

            <Descriptions bordered={false} column={3}>
              <Descriptions.Item
                labelStyle={{
                  opacity: 1,
                }}
                label={QTYLabel}
              >
                {`${parseDecimalDots(total_current_qty)} ${t(part.uom) || "-"}`}
              </Descriptions.Item>
              {part.critical_qty && (
                <Descriptions.Item label={t(TRANSLATION_KEY.criticalQty)}>
                  {`${parseDecimalDots(part.critical_qty)} ${t(part.uom) || "-"}`}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t(TRANSLATION_KEY.totalPrice)}>
                {`${parseCosts(part.total_price?.toString() || "0")} ${
                  user.account.company.currency
                }`}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.avgPrice)}>
                {`${parseCosts(part.avg_price?.toString() || "")} ${user.account.company.currency}`}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.category)}>
                {part.category?.name.toString() || "-"}
              </Descriptions.Item>
              {/* Custom fields */}
              {part.custom_fields &&
                part.custom_fields // ukoliko je neka kolona izbrisana u settingsu da ne prikazuje je
                  .filter(
                    (x) =>
                      !!companyCustomFields?.part_custom_fields.filter((y) => y.key === x.key)
                        .length,
                  )
                  .map((x, i) => (
                    <Descriptions.Item key={i} label={x.name}>
                      {validate(x.value)}
                    </Descriptions.Item>
                  ))}
              <Descriptions.Item span={4} label={t(TRANSLATION_KEY.description)}>
                {validate(part.description)}
              </Descriptions.Item>
              {CustomFieldsSpecGenerator({
                condition: part.category?.id,
                formType: "items",
                data: part.custom_fields_v2,
              })}
            </Descriptions>
          </div>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[24, 12]}>
        {/* List */}
        <Col md={24} lg={12}>
          <WarehouseList data={part.part_storage} onClick={handleOnCardClick} />
        </Col>

        {/* List */}
        <Col md={24} lg={12}>
          <PartMaterials />
        </Col>
      </Row>

      {/* Form */}
      <Drawer
        visible={drawerVisible}
        width={540}
        title={t(TRANSLATION_KEY.addPartToStorage)}
        onClose={() => set_drawerVisible(false)}
        destroyOnClose={true}
      >
        <AddPartToStorage part={part} close={() => set_drawerVisible(false)} />
      </Drawer>

      {/* Modal */}
      <Modal
        visible={spendPartModalVisible}
        title={t(TRANSLATION_KEY.spendPart)}
        footer={null}
        centered
        destroyOnClose
        onCancel={() => {
          set_currentPartStorage(null);
          set_spendPartModalVisible(false);
        }}
      >
        <SpendPart
          type="part_details"
          close={() => set_spendPartModalVisible(false)}
          part={part}
          partStorage={currentPartStorage}
        />
      </Modal>
    </div>
  );
};

export default PartSpec;
