import { Button, Divider, Input, Result, Space, Typography } from "antd";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { t } from "i18next";
import { IMaintenanceDetails } from "../../../models/maintenances";
import moment from "moment";

type Props = {
  maintenance: IMaintenanceDetails;
};

const DigitalSignaturePreview = ({ maintenance }: Props) => {
  return (
    <Space style={{ width: "100%", paddingBottom: 12 }} direction="vertical" size={24}>
      <Result
        status="success"
        title={
          <Typography.Text style={{ fontSize: 20 }}>{t(TRANSLATION_KEY.success)}</Typography.Text>
        }
        subTitle={
          <Typography.Paragraph
            style={{ opacity: 0.5, fontSize: 13 }}
            ellipsis={{ rows: 2, expandable: true }}
          >
            {`${t(TRANSLATION_KEY.maintenance)} #${maintenance.order_number} ${
              maintenance.description
            } ${t(TRANSLATION_KEY.signedSuccessfully)}!`}
          </Typography.Paragraph>
        }
        style={{ marginTop: -52, marginBottom: -32 }}
      />
      <div
        style={{
          width: "100%",
          position: "relative",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ width: "calc(50% - 10px)", display: "flex", flexDirection: "column", gap: 8 }}
        >
          <Typography.Title level={5}>{t(TRANSLATION_KEY.signatory)}</Typography.Title>
          <Typography.Text ellipsis style={{ opacity: 0.75 }}>
            {`${maintenance.signer?.signer_data.first_name} ${maintenance.signer?.signer_data.last_name}`}
          </Typography.Text>
          <Typography.Text ellipsis style={{ opacity: 0.75 }}>
            {maintenance.signer?.signer_data.email}
          </Typography.Text>
          <Typography.Text ellipsis style={{ opacity: 0.75 }}>
            {maintenance.signer?.signer_data.phone_number || "-"}
          </Typography.Text>
          <Typography.Text ellipsis style={{ opacity: 0.75 }}>
            {moment(maintenance.signed_at).format()}
          </Typography.Text>
        </div>

        <div
          style={{ width: "calc(50% - 10px)", display: "flex", flexDirection: "column", gap: 8 }}
        >
          <Typography.Title level={5} style={{ textAlign: "right" }}>
            {t(TRANSLATION_KEY.signatureInitiator)}
          </Typography.Title>
          <Typography.Text style={{ textAlign: "right", opacity: 0.75 }} ellipsis>
            {`#${maintenance.sign_init_by?.id} ${maintenance.sign_init_by?.role.name}`}
          </Typography.Text>
          <Typography.Text style={{ textAlign: "right", opacity: 0.75 }} ellipsis>
            {maintenance.sign_init_by?.email}
          </Typography.Text>
          <Typography.Text style={{ textAlign: "right", opacity: 0.75 }} ellipsis>
            {maintenance.sign_init_by?.phone_number}
          </Typography.Text>
        </div>
      </div>

      {/* <Divider style={{ margin: 0 }} />

      <Space style={{ width: "100%" }} direction="vertical">
        <Typography.Text style={{ opacity: 0.75 }} ellipsis>
          {t(TRANSLATION_KEY.sendSignedDocument)}
        </Typography.Text>

        <Input.Group style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Input style={{ width: "calc(100% - 140px)" }} />
          <Button style={{ width: 120 }} type="primary">
            {t(TRANSLATION_KEY.send)}
          </Button>
        </Input.Group>
      </Space> */}
    </Space>
  );
};

export default DigitalSignaturePreview;
