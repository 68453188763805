import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Antd
import {
  Descriptions,
  Button,
  Spin,
  PageHeader,
  Typography,
  Space,
  Tooltip,
  Tag,
  Result,
} from "antd";

// Translation
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { getSingleAssetXHR } from "../../../store/reducers/asstes/actionCreators";
import { ShopOutlined, StopOutlined } from "@ant-design/icons";
import { assetSlice } from "../../../store/reducers/asstes";

interface IProps {
  id: string | number | undefined;
}

const AssetModalPreview = ({ id }: IProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getSingleAssetStatus, asset } = useAppSelector((state) => state.assetReducer);

  // Methods
  useEffect(() => {
    getSingleAssetXHR({ id }, dispatch);
    return () => {
      dispatch(assetSlice.actions.resetSingleAssetStatus());
    };
  }, []);

  const validate = (value: string | null | undefined) => value || "-";
  const handleNavigate = () => navigate(`/app/asset-details/${asset?.id}`);

  if (getSingleAssetStatus === "error") {
    return <Result status="warning" title={t(TRANSLATION_KEY.dataNotFound)} />;
  }

  return (
    <Spin spinning={getSingleAssetStatus === "load#ing"}>
      <div style={{ width: "100%", padding: 12 }}>
        {/* Page header */}
        <PageHeader
          style={{ padding: 0, marginBottom: 20 }}
          className="custom-page-header"
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.asset)} </Typography.Text>
              {asset.custom_id && <Typography.Text strong>#{asset.custom_id}</Typography.Text>}
            </div>
          }
          extra={[
            <Button type="primary" onClick={handleNavigate}>
              {t(TRANSLATION_KEY.seeMore)}
            </Button>,
          ]}
        >
          <Space direction="vertical" style={{ width: "100%", marginTop: -40 }}>
            <Space size="large" align="center">
              <Typography.Title level={3}>{asset.name}</Typography.Title>
              <Space size="small">
                {asset.type === "wrh" && (
                  <Tooltip title={t(TRANSLATION_KEY.storage)}>
                    <Tag className="main-tag">
                      <ShopOutlined />
                    </Tag>
                  </Tooltip>
                )}
                {!asset.can_report_order && (
                  <Tooltip title={t(TRANSLATION_KEY.cantReportOrder)}>
                    <Tag className="main-tag" style={{ backgroundColor: "#d4282a" }}>
                      <StopOutlined style={{ color: "white" }} />
                    </Tag>
                  </Tooltip>
                )}
              </Space>
            </Space>
            <Descriptions size="small" column={2}>
              <Descriptions.Item label={t(TRANSLATION_KEY.category)}>
                {validate(t(asset?.category.name))}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.location)}>
                {validate(asset?.location?.name)}
              </Descriptions.Item>
              {/* Asset route */}
              {asset.path && (
                <Descriptions.Item label={t(TRANSLATION_KEY.assetRoute)}>
                  <Typography.Text style={{ maxWidth: 180 }} ellipsis={{ tooltip: asset.path }}>
                    {validate(asset.path)}
                  </Typography.Text>
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t(TRANSLATION_KEY.autopilot)}>
                {asset.autopilot ? t(TRANSLATION_KEY.enabled) : t(TRANSLATION_KEY.disabled)}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={t(TRANSLATION_KEY.description)}>
                {validate(asset?.description)}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </PageHeader>
      </div>
    </Spin>
  );
};

export default AssetModalPreview;
