import React, { useState } from "react";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { t } from "i18next";
import { Button, Popconfirm, Space, Table, TableColumnType } from "antd";
import { CustomFieldV2 } from "../../../../../models/settings";
import { FormModal } from "../../../../../componentsform/CustomFieldsFormV2";
import CustomFieldsTable from "../Components/CustomFieldsTable";
import { useAppSelector } from "../../../../../hooks";

interface IProps {
  onEdit: (data: FormModal) => void;
}

const ConfirmOrder: React.FC<IProps> = ({ onEdit }) => {
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  return (
    <div>
      <CustomFieldsTable
        formType="orderconfirm"
        condition={undefined}
        onEdit={onEdit}
        conditionTitle={""}
        customFieldsData={companyCustomFieldsV2.orderconfirm}
      />
    </div>
  );
};
export default ConfirmOrder;
