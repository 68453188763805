import { UseDigitalSignature } from "../useDigitalSignature";

type Props = {
  digitalSignature: UseDigitalSignature;
};

const DigitalSignatureIFrame = ({ digitalSignature }: Props) => {
  return (
    <div>
      {digitalSignature.signatureLink && (
        <div>
          <iframe
            id="iframe-container"
            src={digitalSignature.signatureLink}
            title="YouSign Signature Flow"
            width="600px"
            height="600px"
            style={{
              width: "100vw",
              height: "100vh",
              position: "fixed",
              zIndex: digitalSignature.signatureLink ? 99999999 : -1,
              top: 0,
              left: 0,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DigitalSignatureIFrame;
