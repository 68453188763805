import React from "react";
import { ICriticalQty } from "../../../models/parts";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { t } from "i18next";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

type Props = {
  item: ICriticalQty;
};

const PartItem = ({ item }: Props) => {
  return (
    <motion.div
      whileHover={{ backgroundColor: "#efefef" }}
      style={{
        backgroundColor: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "space-between",
        padding: "10px 4px",
        borderRadius: 4,
      }}
    >
      {/* Left side */}
      <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
        <Typography.Text strong>#{item.code}</Typography.Text>
        <Link
          to={`/app/item-details/${item.id}/`}
          style={{ fontSize: 16, fontWeight: 500, padding: "4px 0" }}
        >
          {item.name}
        </Link>
        <Typography.Text type="secondary">{item.category?.name}</Typography.Text>
      </div>

      {/* Right side */}
      <div
        style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "flex-end" }}
      >
        {/* Kritična zaliha */}
        <div style={{ textAlign: "right" }}>
          <Typography.Text type="secondary">{`${t(
            TRANSLATION_KEY.criticalQty,
          )}: `}</Typography.Text>
          <Typography.Text strong style={{ textTransform: "lowercase" }}>{`${item.critical_qty} ${t(
            item.uom,
          )}`}</Typography.Text>
        </div>

        {/* Trenutna zaliha */}
        <div style={{ textAlign: "right" }}>
          <Typography.Text type="secondary">{`${t(TRANSLATION_KEY.currentQty)}: `}</Typography.Text>
          <Typography.Text strong style={{ textTransform: "lowercase" }}>{`${
            item.total_current_qty
          } ${t(item.uom)}`}</Typography.Text>
        </div>
      </div>
    </motion.div>
  );
};

export default PartItem;
