import { measureTextWidth, Pie, PieConfig } from "@ant-design/charts";
import { Typography } from "antd";
import { t } from "i18next";
import React, { useEffect, useMemo } from "react";
import { DONUT_CHART_CONFIG, TRANSLATION_KEY } from "../../../helpers/consts";
import { renderStatistic } from "../../../helpers/functions";
import { useAppSelector } from "../../../hooks";
import { useWindowSize } from "react-use";

interface IProps {}

const MaintenancesByType: React.FC<IProps> = () => {
  // Hooks
  const { width } = useWindowSize();
  const { liveMaintenances } = useAppSelector((state) => state.maintenanceReducer);

  const {
    maintenancesByCategoryType: { types },
  } = useAppSelector((state) => state.dashboardReducer);

  const config: PieConfig = {
    ...DONUT_CHART_CONFIG,
    radius: width < 1400 ? 0.7 : 0.85,
    angleField: "count",
    colorField: "name",
    meta: {
      value: {
        formatter: (v) => `${v}`,
      },
    },
    data: types.map((x) => ({
      ...x,
      name: `${t(x.name || TRANSLATION_KEY.other)} ${x.percentage}%`,
    })),
    animation: { appear: { delay: 1000 } },
    color: ({ name }) => {
      let color = "";
      color = types.find((x) => t(x.name || "") === name.split(" ")[0])?.color || "";
      return color;
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : t(TRANSLATION_KEY.total);
          return renderStatistic(d, text, {
            fontSize: 22,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "32px",
        },
        customHtml: (container, view, datum, data: any) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? `${datum.count}` : `${data.reduce((r, d) => r + d.count, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
  };

  const content = useMemo(
    () => (
      <div>
        <div className="spaceBetweenRow">
          {/* Title */}
          <Typography.Title level={5} style={{ paddingTop: 12 }}>
            {t(TRANSLATION_KEY.maintenanceByType)}
          </Typography.Title>
        </div>

        {/* Divider */}
        <div style={{ marginTop: 12, marginBottom: 12 }} />

        {/* Chart */}
        <Pie {...config} />
      </div>
    ),
    [types, liveMaintenances],
  );

  return content;
};

export default MaintenancesByType;
