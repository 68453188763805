import React from "react";

// Styles
import { Card, Button, Tooltip, Popconfirm } from "antd";
import {
  FileImageFilled,
  FilePdfFilled,
  FileExclamationFilled,
  VideoCameraFilled,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import styles from "../../index.module.css";

// Components
import Image from "../Image";
import Video from "../Video";
import Document from "../Document";
import Invalid from "../Invalid";
import { IFile } from "../../../../models/user";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { MOB_SIZE } from "../../../../pages/openorder/components/Order";

type CardSize = "tiny" | "small" | "medium" | "large";

interface IProps {
  name: string;
  mediaType: string;
  size: CardSize;
  index: number;
  id: number;
  title: string;
  hideEditButton?: boolean;
  hideButtons?: boolean;
  openFileViewer: (v: number) => void;
  onDelete: (id: number) => void;
  onEdit?: (file: IFile | undefined) => void;
}

const CardComponent: React.FC<IProps> = ({
  name,
  title,
  id,
  onDelete,
  onEdit,
  hideEditButton = false,
  hideButtons = false,
  mediaType,
  size,
  index,
  openFileViewer,
}) => {
  const _type = mediaType as keyof typeof cover;
  const _size = size as keyof typeof sizes.wrapper;

  // Constants
  const sizes = {
    wrapper: {
      tiny: 70,
      small: 180,
      medium: 260,
      large: 340,
    },
    meta: {
      tiny: 0,
      small: 140,
      medium: 220,
      large: 300,
    },
    cover: {
      tiny: 68,
      small: 122,
      medium: 202,
      large: 282,
    },
    document: {
      tiny: 120,
      small: 200,
      medium: 300,
      large: 400,
    },
  };

  // Content
  const cover = {
    image: <Image src={name} size={sizes.cover[_size]} tiny={size === "tiny"} />,
    video: <Video src={name} size={sizes.cover[_size]} />,
    invalid: <Invalid size={sizes.cover[_size]} small={size === "small"} tiny={size === "tiny"} />,
    document:
      window.innerWidth < MOB_SIZE ? (
        <Image
          src={require("../../../../assets/icons/mobile-pdf.png")}
          size={sizes.cover[_size]}
          tiny={size === "tiny"}
        />
      ) : (
        <Document src={name} size={sizes.cover[_size]} width={sizes.document[_size]} />
      ),
  };

  const metaIcon = {
    image: <FileImageFilled style={{ color: "#e50a0a" }} />,
    video: <VideoCameraFilled style={{ color: "#02b111" }} />,
    document: <FilePdfFilled style={{ color: "#0353ef" }} />,
    invalid: <FileExclamationFilled style={{ color: "#f09001" }} />,
  };

  function handleOnPress(index: number) {
    if (_type === "document") {
      window.open(name, "_blank");
      return;
    }
    openFileViewer(index);
  }

  function handleOnDelete(
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    id: number,
  ): void {
    event?.stopPropagation();
    onDelete(id);
  }

  const meta = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: sizes.meta[_size],
      }}
    >
      {metaIcon[_type]}
      <div className={styles.metaName}>{title}</div>
    </div>
  );

  function handleOnEdit(
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    file: IFile,
  ): void {
    event?.stopPropagation();
    onEdit && onEdit(file);
  }

  return (
    <div
      className={styles.cardWrapper}
      style={{
        width: sizes.wrapper[_size],
        height: sizes.wrapper[_size],
        padding: size === "tiny" ? 0 : 7,
        borderRadius: size === "tiny" ? 6 : 20,
      }}
    >
      {size !== "tiny" ? (
        <Card
          onClick={() => openFileViewer(index)}
          className={styles.card}
          cover={cover[_type]}
          bodyStyle={{
            height: 48,
            padding: "0 10px",
            display: "flex",
            alignItems: "center",
            fontSize: 10,
            position: "relative",
          }}
        >
          <Card.Meta title={meta} />
        </Card>
      ) : (
        <div
          onClick={() => handleOnPress(index)}
          style={{
            width: 48,
            height: 48,
            display: "grid",
            placeItems: "center",
            border: "1px solid #ccc",
            borderRadius: 6,
          }}
        >
          {cover[_type]}
        </div>
      )}

      <div>
        {!hideButtons && (
          <>
            {!hideEditButton && (
              <Button
                onClick={(e) => handleOnEdit(e, { name: name, id: id, file: "", extension: "" })}
                type="default"
                shape="circle"
                icon={<EditOutlined />}
                size="middle"
                style={{
                  position: "absolute",
                  top: 14,
                  right: 54,
                }}
              />
            )}

            <Tooltip title={t(TRANSLATION_KEY.delete)} placement="bottom">
              <Popconfirm
                onConfirm={(e) => handleOnDelete(e, id)}
                title={t(TRANSLATION_KEY.continueWithAction)}
                cancelText={t(TRANSLATION_KEY.no)}
                okText={t(TRANSLATION_KEY.yes)}
                placement="top"
              >
                <Button
                  type="default"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="middle"
                  style={{
                    transform: size === "tiny" ? "scale(.85)" : "unset",
                    position: "absolute",
                    zIndex: 999,
                    top: size === "tiny" ? "unset" : 14,
                    bottom: size === "tiny" ? 0 : "unset",
                    right: size === "tiny" ? 4 : 14,
                  }}
                />
              </Popconfirm>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default CardComponent;
