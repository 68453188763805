import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { LANGUAGES, TRANSLATION_KEY } from "../../helpers/consts";

// Styles
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";

// Redux
import { useForm } from "antd/lib/form/Form";
import { isMobile } from "react-device-detect";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IRegistration } from "../../models/user";
import { registrationXHR } from "../../store/reducers/user/actionCreators";
import TermsOfService from "../../components/TermsOfService";

const currencies = ["BAM", "EUR", "USD", "RSD", "SEK", "DKK", "NOK", "GBP"];

const Registration: React.FC = () => {
  // Hooks
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  // Variables
  const { registrationStatus } = useAppSelector((state) => state.userReducer);
  const [emailInUse, set_emailInUse] = useState<string | null>(null);
  const [currencyWarning, set_currencyWarning] = useState(false);
  const [termsVisible, setTermsVisible] = useState<boolean>(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     getCurrenciesXHR({
  //       successCallback: (data) => {
  //         set_currencies(data.results || []); // potrebno jer kada nije prijavljen korisnik state se refresha pa ne mozemo imati valute iz statea
  //       },
  //       errorCallback: (e: any)=> {
  //         console.log(e);
  //         message.error(t(TRANSLATION_KEY.errorOnGetData));
  //       }
  //     }, dispatch);
  //   }, 369) // pojma nemam zasto ne radi bez timeout nikola kada se refresha page na regstraciji ne radi bez timeout bit ce dadodje konflikt sa refrsherom statea

  // },[])

  // Methods
  const validateEmail = (value: string) => {
    if (value === emailInUse) {
      return Promise.reject(t(TRANSLATION_KEY.emailAlreadyExists));
    }
    return Promise.resolve();
  };

  const onFinish = (values: IRegistration) => {
    registrationXHR(
      {
        body: values,
        errorCallback: (error: any) => {
          if (error.message === "email_exists") {
            message.error(t(TRANSLATION_KEY.emailAlreadyExists));
            set_emailInUse(form.getFieldValue("email"));
            form.validateFields();
          } else {
            message.error(t(TRANSLATION_KEY.errorRegistration));
            set_emailInUse(null);
          }
        },
        successCallback: () => {
          message.success(t(TRANSLATION_KEY.registrationSuccessCheckYourMail), 15);
          set_emailInUse(null);
          navigate("/login");
        },
      },
      dispatch,
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        minWidth: "100vw",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: "#fff",
        overflowX: "hidden",
      }}
    >
      <Row style={{ width: "100%", height: "100%", minHeight: "100vh" }}>
        <Col
          xs={24}
          lg={24}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            paddingTop: 72,
            paddingBottom: 40,
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "calc(100vw - 40px)" : 450,
              width: isMobile ? "calc(100vw - 40px)" : 450,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* Info */}
            <img
              style={{ width: 64, height: 64, marginBottom: 12, marginTop: -32 }}
              src={require("../../assets/icons/auth_logo.png")}
            />
            <Typography.Title
              level={1}
              style={{ height: 20, color: "#434343", fontWeight: 500, textAlign: "center" }}
            >
              {t(TRANSLATION_KEY.register)}
            </Typography.Title>
            <Typography.Title
              level={5}
              style={{
                paddingBottom: 24,
                color: "#434343aa",
                fontWeight: 300,
                textAlign: "center",
              }}
            >
              {t(TRANSLATION_KEY.enterYourInformations)}
            </Typography.Title>

            <Form layout="vertical" onFinish={onFinish} form={form} autoComplete="off">
              {/* Name */}
              <Form.Item
                label={t(TRANSLATION_KEY.nameAndSurname)}
                name="name"
                rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label={t(TRANSLATION_KEY.email)}
                name="email"
                rules={[
                  { required: true, message: t(TRANSLATION_KEY.filedRequired) },
                  { type: "email", message: t(TRANSLATION_KEY.enterCorrectEmail) },
                  { validator: (_, value) => validateEmail(value) },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              {/* Company name */}
              <Form.Item
                label={t(TRANSLATION_KEY.companyName)}
                name="company_name"
                rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
              >
                <Input size="large" />
              </Form.Item>

              {/* PDV ID */}
              <Form.Item
                label={t(TRANSLATION_KEY.pdvId)}
                name="pdv_id"
                rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
              >
                <Input size="large" />
              </Form.Item>

              {/* Language */}
              <Form.Item
                label={t(TRANSLATION_KEY.language)}
                name="lang"
                rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
              >
                <Select showSearch size="large">
                  {LANGUAGES.map((x) => (
                    <Select.Option value={x.alpha2Code}>{x.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Currency */}
              <Form.Item
                label={t(TRANSLATION_KEY.globalCompanyCurrency)}
                name="currency"
                rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
              >
                <Select
                  showSearch
                  size="large"
                  onSelect={() => {
                    set_currencyWarning(true);
                  }}
                >
                  {currencies.map((currency) => (
                    <Select.Option value={currency}>{currency}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {currencyWarning && (
                <Alert
                  type="warning"
                  message={t(TRANSLATION_KEY.currencyWarningTitle)}
                  description={t(TRANSLATION_KEY.currencyWarning)}
                  banner
                  style={{ marginBottom: 20 }}
                />
              )}

              <Form.Item
                name="accept_terms"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) => {
                      return value
                        ? Promise.resolve()
                        : Promise.reject(new Error(t(TRANSLATION_KEY.filedRequired)));
                    },
                  },
                ]}
              >
                <Space size={0}>
                  <Checkbox style={{ marginRight: -8 }}>{t(TRANSLATION_KEY.iAgreeToThe)}</Checkbox>
                  <Typography.Link
                    onClick={() => setTermsVisible(true)}
                    style={{ padding: 0, textTransform: "lowercase", marginLeft: 6 }}
                  >
                    {t(TRANSLATION_KEY.registrationTermsOfService)}
                  </Typography.Link>
                  {i18n.language === "de" && (
                    <Typography.Text style={{ marginLeft: 6 }}>zu</Typography.Text>
                  )}
                </Space>
              </Form.Item>

              {/* Divider */}
              <Divider />

              {/* Submit */}
              <Form.Item style={{ position: "relative" }}>
                <Button
                  loading={registrationStatus === "loading"}
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                >
                  {t(TRANSLATION_KEY.register)}
                </Button>
              </Form.Item>

              {/* Already have an account? */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  width: isMobile ? "calc(100vw - 40px)" : 480,
                }}
              >
                <Typography.Text style={{ marginRight: 4 }}>
                  {t(TRANSLATION_KEY.alreadyAMember)}?
                </Typography.Text>
                <Link to="/login">
                  <Typography.Link>{t(TRANSLATION_KEY.signIn)}</Typography.Link>
                </Link>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      {/* <Modal footer={null} visible={currencyWarning} closable={false} onCancel={() => set_currencyWarning(false)} >
        <Alert type="warning" message={t(TRANSLATION_KEY.currencyWarningTitle)}  description={t(TRANSLATION_KEY.currencyWarning)} />
      </Modal> */}

      {/* Terms of service */}
      <TermsOfService
        visible={termsVisible}
        onAccept={() => {}}
        onCancel={() => setTermsVisible(false)}
        footerVisible={false}
        title={t(TRANSLATION_KEY.termsOfService)}
      />
    </div>
  );
};

export default Registration;
