import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, List, Popconfirm } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { PartCategoryTreeItem } from "../../../../../models";
import { IPartCategory } from "../../../../../models/parts";
import { motion } from "framer-motion";
import { CardVariant } from "../../CheckList";
import { useState } from "react";

interface IProps {
  onDelete: (id: number) => void;
  onEdit: (x: IPartCategory) => void;
  item: IPartCategory;
  deep: number;
}

const PartCategoryCard: React.FC<IProps> = ({ onEdit, onDelete, item, deep }) => {
  // Variables
  const [variant, set_variant] = useState<CardVariant>("hidden");

  return (
    <div>
      <motion.div
        onHoverStart={() => set_variant("visible")}
        onHoverEnd={() => set_variant("hidden")}
        style={{ padding: "4px 8px", paddingRight: 4, minHeight: 40 }}
        className="spaceBetweenRow locationCard"
      >
        <div>{item.name}</div>
        <motion.div
          className="spaceBetweenRow"
          initial="hidden"
          animate={variant}
          variants={variants}
          transition={{ ease: "anticipate", duration: 0.35 }}
        >
          <Button onClick={() => onEdit(item)} type="link" icon={<EditOutlined />} />
          <div style={{ width: 6 }} />
          <Popconfirm
            onConfirm={() => onDelete(item.id)}
            title={t(TRANSLATION_KEY.continueWithAction)}
          >
            <Button danger type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default PartCategoryCard;

const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
