import { DeleteOutlined, EditOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Drawer, List, message, Modal, Popconfirm, Typography } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import api, { failedQueue, isRefreshing } from "../../../services";
import { supplierSlice } from "../../../store/reducers/supplier";

import { IRentDetails, RentNote } from "../../../models/clients";
import { clientsSlice } from "../../../store/reducers/clients";
import moment from "moment";
import PreviewButton from "../../../components/PreviewButton";
import RentNoteForm from "../../../componentsform/RentNoteForm";
import AccountModalPreview from "../../account/components/AccountModalPreview";

interface IProps {
  rentDetails: IRentDetails;
}

const RentNotes: React.FC<IProps> = ({ rentDetails }) => {
  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [addVisible, set_addVisible] = useState<RentNote>();
  const [selectedAccount, set_selectedAccount] = useState<number | undefined>();
  const [loading, set_loading] = useState(0);
  const dispatch = useAppDispatch();

  // Methods
  const deleteReport = async (id: number) => {
    set_loading(id);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`clients/rent_order/v2/${id}/delete_note/`, {
        headers: { Authorization: "Bearer " + token },
      });
      let data = [...rentDetails.notes];
      let index = data.findIndex((x) => x.id === id);
      data.splice(index, 1);

      dispatch(
        clientsSlice.actions.getRentOrderDetailsSuccess({
          message: "",
          results: { ...rentDetails, notes: data },
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => deleteReport(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }

    set_loading(0);
  };

  return (
    <>
      <List
        header={
          <div className="spaceBetweenRow">
            <Typography.Title level={5} style={{ paddingTop: 4 }}>
              {t(TRANSLATION_KEY.notes)}
            </Typography.Title>
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              onClick={() =>
                set_addVisible({
                  note: "",
                  id: 0,
                  created_at: "",
                  created_by: null,
                })
              }
            />
          </div>
        }
        dataSource={rentDetails.notes}
        renderItem={(item) => {
          return (
            <List.Item
              actions={[
                <div className="spaceBetweenRow">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      type="link"
                      onClick={() => set_addVisible(item)}
                      icon={<EditOutlined />}
                    />
                    <Popconfirm
                      onConfirm={() => deleteReport(item.id)}
                      title={t(TRANSLATION_KEY.continueWithAction)}
                    >
                      <Button
                        danger
                        type="link"
                        loading={loading === item.id}
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </div>
                </div>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title={
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ width: 180, fontSize: 12, opacity: 0.75 }}>
                      {moment(item.created_at).format()}
                    </div>

                    <PreviewButton
                      isActive={item.created_by?.is_active}
                      title={item.created_by?.name}
                      id={item.created_by?.id}
                      url={`/app/humanresources/${item.created_by?.id}`}
                      onClick={() => {
                        set_modalVisible(true);
                        set_selectedAccount(item.created_by?.id);
                      }}
                    />
                  </div>
                }
                description={
                  <div>
                    <div style={{ color: "#333", fontWeight: 500 }}>{item.note}</div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <Modal
        title={addVisible?.id === 0 ? t(TRANSLATION_KEY.add) : t(TRANSLATION_KEY.edit)}
        visible={!!addVisible}
        onCancel={() => set_addVisible(undefined)}
        destroyOnClose={true}
        centered
        footer={null}
      >
        <RentNoteForm
          rentDetails={rentDetails}
          close={() => set_addVisible(undefined)}
          note={addVisible}
        />
      </Modal>

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedAccount(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AccountModalPreview id={selectedAccount} />
      </Modal>
    </>
  );
};

export default RentNotes;
