import { Button, List, Modal, Popconfirm, message } from "antd";
import React, { useEffect, useState } from "react";
import AssetNode from "../../../asset/tree/asset";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IAsset } from "../../../../models/asset";
import { NewTreeItem } from "../../../../models";
import {
  createTree,
  hasPermission,
  setItemsWithoutParentToHighestNode,
} from "../../../../helpers/functions";
import AssetModalPreview from "../../../assetdetails/components/AssetModalPreview";
import { removeAssetFromPartXHR } from "../../../../store/reducers/accounts/actionCreators";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import AddAsset from "./ManageAsset";
import { IPart } from "../../../../models/parts";
import { joinAssetsToPartXHR } from "../../../../store/reducers/warehouse/actionCreator";
import { warehouseSlice } from "../../../../store/reducers/warehouse";
// import { getAssetXHR } from "../../../../store/reducers/asstes/actionCreators";

interface IProps {
  asset: IAsset[];
  part: IPart;
}
const AssetManagement: React.FC<IProps> = ({ part, asset }) => {
  const [localAsset, set_localAsset] = useState<IAsset[]>([...asset]);
  const [assetTree, set_assetTree] = useState<NewTreeItem<IAsset>[]>([]);
  const [loading, set_loading] = useState<number>(-1);

  const { parts } = useAppSelector((state) => state.warehouseReducer);

  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userReducer);
  const [assetModal, set_assetModal] = useState<number>();
  const [modalAssetsVisible, set_modalAssetsVisible] = useState<boolean>(false);

  useEffect(() => {
    set_assetTree(createTree(setItemsWithoutParentToHighestNode(localAsset), null));
  }, [localAsset]);

  const removeAsset = (asset: number[]) => {
    joinAssetsToPartXHR(
      {
        body: {
          part: part.id,
          assets: asset,
        },
        successCallback: (res) => {
          if (!window.location.pathname.includes("item-details")) {
            let _parts = [...parts.data];
            let _part: IPart | undefined = _parts.find((x) => x.id === part.id);
            if (_part) {
              _part = { ..._part };
              _part.assets = res.results;
              let index = _parts.findIndex((x) => x.id === _part?.id);
              _parts[index] = _part!;
              dispatch(
                warehouseSlice.actions.getPartsSuccess({
                  message: "",
                  results: {
                    ...parts,
                    data: _parts,
                  },
                  mergeData: false,
                }),
              );
              //update ukoliko se imovina dodaje sa page-a liste dijelova
            }
          }
          set_localAsset(res.results || []);
          set_assetTree(createTree(setItemsWithoutParentToHighestNode(res.results || []), null));
        },
        errorCallback: (e) => {
          console.log(e);
          message.error(t(TRANSLATION_KEY.errorOnDeleteData));
        },
      },
      dispatch,
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          marginTop: -8,
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Add button */}
        <Button
          type="primary"
          block
          style={{ marginRight: 6 }}
          onClick={() => {
            set_modalAssetsVisible(true);
          }}
        >
          {t(TRANSLATION_KEY.assignAssets)}
        </Button>
        <Popconfirm
          onConfirm={removeAsset.bind(null, [])}
          title={t(TRANSLATION_KEY.continueWithAction)}
          cancelText={t(TRANSLATION_KEY.no)}
          okText={t(TRANSLATION_KEY.yes)}
        >
          <Button loading={false} type="text" block danger style={{ marginLeft: 6 }}>
            {t(TRANSLATION_KEY.removeAssets)}
          </Button>
        </Popconfirm>
      </div>
      <div>
        <List
          dataSource={assetTree}
          renderItem={(item) => (
            <AssetNode
              loading={loading}
              canDeleteAsset={true}
              onRemoveAssetFromAccount={(id) =>
                removeAsset(localAsset.filter((x) => x.id !== id).map((x) => x.id))
              }
              item={item}
              deep={0}
              handleOpenModal={(id) => {
                set_assetModal(Number(id));
              }}
              viewAsset={hasPermission(user.account.permissions, ["view_asset"])}
            />
          )}
        />
      </div>

      <Modal
        visible={modalAssetsVisible}
        onCancel={() => {
          set_modalAssetsVisible(false);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AddAsset
          setPickedAsset={(asset) => {
            set_localAsset([...asset]);
          }}
          part_id={part.id}
          _alreadyHavedAsset={localAsset.map((x) => x.id)}
          handleOpenModal={(id) => {
            set_modalAssetsVisible(false);
          }}
        />
      </Modal>

      <Modal
        visible={!!assetModal}
        onCancel={() => {
          set_assetModal(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {assetModal && <AssetModalPreview id={assetModal} />}
      </Modal>
    </>
  );
};

export default AssetManagement;
