import { assetSlice } from ".";
import { AppDispatch, store } from "../..";
import { IAssetForm } from "../../../componentsform/AssetForm";
import { IApiResponse, IDataToApi } from "../../../models";
import {
  IAsset,
  IAssetList,
  ICategory,
  ICopyFilesToAssetsStatus,
  ISubAsset,
} from "../../../models/asset";
import { IPart } from "../../../models/parts";
import { IAccount } from "../../../models/user";
import { IAddSubAsset } from "../../../pages/assetdetails/pages/SubAssets";
import api, { failedQueue, isRefreshing } from "../../../services";
import { clientsSlice } from "../clients";

export const getAssetCategoriesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ICategory[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.getAssetCategories());
    let response = await api.get<IApiResponse<ICategory[]>>(`assets/categories/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.getAssetCategoriesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getAssetCategoriesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.getAssetCategoriesFail(error.message));
  }
};

export const getAssetXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IAsset[]>>>,
  dispatch: AppDispatch,
) => {
  const asset = store.getState().assetReducer.assetList;
  if (asset.length > 0) {
    return;
  }
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.getAsset({ loading: data.loading || "loading" }));
    let response = await api.get<IApiResponse<IAsset[]>>(`assets/assets/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      assetSlice.actions.getAssetSuccess({ ...response.data, loading: data.loading || "loading" }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.getAssetFail(error.message));
  }
};

export const addAssetXHR = async (
  data: Partial<IDataToApi<IAssetForm, IApiResponse<IAsset>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.addAsset());
    let response = await api.post<IApiResponse<IAsset>>(
      `assets/assets/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    dispatch(assetSlice.actions.addAssetSuccess(response.data));
    let clientAsset = [...store.getState().clientsReducer.assetToRent];
    clientAsset.push(response.data.results as IAsset);
    dispatch(
      clientsSlice.actions.getAssetToRentXHRSuccess({
        message: "",
        results: clientAsset,
      }),
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.addAssetFail(error.message));
  }
};

export const getSingleAssetXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IAsset>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.getSingleAsset());
    let response = await api.get<IApiResponse<IAsset>>(`assets/assets/${data.id}`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.getSingleAssetSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getSingleAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.getSingleAssetFail(error.message));
  }
};

export const editSingleAssetXHR = async (
  data: Partial<IDataToApi<IAssetForm, IApiResponse<IAsset>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.editSingleAsset());
    let response = await api.put<IApiResponse<IAsset>>(
      `assets/assets/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.editSingleAssetSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => editSingleAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.editSingleAssetFail(error.message));
  }
};

export const getAssetAccountsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IAccount[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.getAssetAccounts());
    let response = await api.get<IApiResponse<IAccount[]>>(`assets/assets/${data.id}/accounts/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.getAssetAccountsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getAssetAccountsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.getAssetAccountsFail(error.message));
  }
};

export const joinAccountsToAssetXHR = async (
  data: Partial<IDataToApi<{ asset: number; accounts: number[] }, IApiResponse<IAccount[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.joinAccountsToAsset());
    let response = await api.post<IApiResponse<IAccount[]>>(`/assets/join_accounts/`, data.body, {
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.joinAccountsToAssetSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => joinAccountsToAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.joinAccountsToAssetFail(error.message));
  }
};

export const copyFilesToAssetsXHR = async (
  data: Partial<IDataToApi<ICopyFilesToAssetsStatus, IApiResponse<ICopyFilesToAssetsStatus>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(assetSlice.actions.copyFilesToAssets());
    let response = await api.post<IApiResponse<ICopyFilesToAssetsStatus>>(
      `assets/files/copy_to_assets/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.copyFilesToAssetsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => copyFilesToAssetsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.copyFilesToAssetsSuccess(error.message));
  }
};

export const addSubAssetXHR = async (
  data: Partial<IDataToApi<IAddSubAsset, IApiResponse<ISubAsset>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.addSubAsset());
    let response = await api.post<IApiResponse<ISubAsset>>(`assets/sub_asset/`, data.body, {
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.addSubAssetSuccess(response.data));
  } catch (error: any) {
    console.log(error);
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addSubAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.addAssetFail(error.message));
  }
};

export const getSubAssetsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ISubAsset[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(assetSlice.actions.getSubAssets());
    let response = await api.get<IApiResponse<ISubAsset[]>>(`assets/sub_asset/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.getSubAssetsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getSubAssetsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(assetSlice.actions.getSubAssetsFail(error.message));
  }
};

export const editSubAssetXHR = async (
  data: Partial<IDataToApi<IAddSubAsset, IApiResponse<ISubAsset>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(assetSlice.actions.editSubAsset());
    let response = await api.put<IApiResponse<ISubAsset>>(
      `assets/sub_asset/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(assetSlice.actions.editSubAssetSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => editSubAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(assetSlice.actions.editSubAssetFail(error.message));
  }
};

export const getConnectedPartsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPart[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.get<IApiResponse<IPart[]>>(
      `assets/assets/${data.id}/connected_parts/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getConnectedPartsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};
