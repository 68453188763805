import { PageHeader, Tabs } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { AppRoutes } from "../../navigation/routes";
import { getAssetXHR } from "../../store/reducers/asstes/actionCreators";

interface IProps {}

const { TabPane } = Tabs;

const Analytics: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  let activeKey = location.pathname.split("/")[3] || "";

  if (["wo-time", "wo-costs", "wo-success"].includes(activeKey)) {
    activeKey = "";
  }

  return (
    <div>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10, marginBottom: 20 }}
        title={t(TRANSLATION_KEY.analytics)}
        footer={
          <Tabs id="bordered-tabs" activeKey={activeKey} onChange={onTabChange}>
            {AppRoutes("allroutes")
              .children?.find((x) => x.path === "analytics")
              ?.children?.map((x) => (
                <TabPane
                  tab={
                    <Link to={x.path} style={{ fontSize: 14 }}>
                      {t(x.path || "maintenancesanalytics")}
                    </Link>
                  }
                  key={x.path}
                />
              ))}
          </Tabs>
        }
      />

      {/* Rendering routes */}
      <Outlet />
    </div>
  );
};

export default Analytics;
