import { Button, Form, Input, TreeSelect, message } from "antd";
import { t } from "i18next";
import React from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { createTreeSelect, setItemsWithoutParentToHighestNode } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { createWorkRequestXHR } from "../store/reducers/maintenance/actionCreator";
import { IAsset } from "../models/asset";

export interface IWorkRequestFormValues {
  description: string;
  phone_number: string;
  asset: number | null;
  request_panel: number;
  token: string;
}

interface IProps {
  assetList: IAsset[];
  panelId: number;
  token: string;
}

const RequestPanelForm: React.FC<IProps> = ({ assetList, token, panelId }) => {
  const dispatch = useAppDispatch();

  const { createWorkRequestStatus } = useAppSelector((state) => state.maintenanceReducer);
  const [form] = Form.useForm();

  const onFinish = (values: IWorkRequestFormValues) => {
    let fd = new FormData();
    fd.append("data", JSON.stringify({ ...values, asset: values.asset || null }));
    fd.append("files", "");
    createWorkRequestXHR(
      {
        body: fd,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: () => {
          message.success(t(TRANSLATION_KEY.successOnSaveData));
          form.resetFields();
        },
      },
      dispatch,
    );
  };

  const tree = createTreeSelect(
    setItemsWithoutParentToHighestNode(assetList).map((x) => ({
      title: x.path,
      value: x.id.toString(),
      parent_id: x.parent?.toString() || null,
      disabled: !x.can_report_order,
    })),
    null,
  );

  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical" size="large">
        <Form.Item name="request_panel" hidden={true} initialValue={panelId} />
        <Form.Item name="token" hidden={true} initialValue={token} />
        <Form.Item
          rules={[
            {
              required: true,
              message: t(TRANSLATION_KEY.filedRequired),
            },
          ]}
          label={t(TRANSLATION_KEY.description)}
          name="description"
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t(TRANSLATION_KEY.filedRequired),
            },
          ]}
          label={t(TRANSLATION_KEY.phoneNumber)}
          name={"phone_number"}
        >
          <Input type="numeric" />
        </Form.Item>
        <Form.Item name="assets" label={t(TRANSLATION_KEY.asset)}>
          <TreeSelect
            treeNodeFilterProp="title"
            showSearch
            filterTreeNode={(search, item: any) => {
              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
            treeData={tree}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={createWorkRequestStatus === "loading"} htmlType="submit" type="primary">
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RequestPanelForm;
