import React, { useEffect, useState } from "react";

// Antd
import {
  FileDoneOutlined,
  FileExclamationOutlined,
  FileSyncOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Button, Col, Drawer, Modal, PageHeader, Row, Space, Spin, Tabs } from "antd";

// Rest
import { t } from "i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { TRANSLATION_KEY } from "../../helpers/consts";
import PartForm from "../../componentsform/PartForm";
import AllParts from "./pages/AllParts";
import CriticalQty from "./components/CriticalQty";
import { useAppSelector } from "../../hooks";

const Warehouse: React.FC = () => {
  // Hooks

  // Variables
  const [editVisible, set_editVisible] = useState<boolean>(false);
  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 500);
  }, []);

  // Include components here instead of divs

  return (
    <>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10, marginBottom: 2 }}
        title={t(TRANSLATION_KEY.items)}
        extra={[
          <Button onClick={() => set_editVisible(true)} type="primary">
            {t(TRANSLATION_KEY.add)}
          </Button>,
        ]}
      />

      {/* Modal CTA-s */}
      <Row gutter={[24, 24]}>
        <Col xs={12} sm={6}>
          <CriticalQty />
        </Col>
      </Row>

      {/* List modal */}

      {/* Main component */}
      {render ? (
        <AllParts />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "36vh",
          }}
        >
          {" "}
          <Spin />
        </div>
      )}

      {/* All parts */}

      {/* Form */}
      <Drawer
        width={540}
        visible={editVisible}
        onClose={() => set_editVisible(false)}
        title={t(TRANSLATION_KEY.addPart)}
        destroyOnClose={true}
      >
        <PartForm close={() => set_editVisible(false)} />
      </Drawer>
    </>
  );
};

export default Warehouse;
