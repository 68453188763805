import {
  message,
  Spin,
  Form,
  Input,
  TreeSelect,
  Select,
  Radio,
  DatePicker,
  InputNumber,
  Button,
  Divider,
  Typography,
  Upload,
  Modal,
  Space,
  Row,
  Col,
} from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useNavigate } from "react-router-dom";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../components/SelectTagAdd";
import { IMAGE_TYPES, MATERIAL_EXTENSIONS, STATUSES, TRANSLATION_KEY } from "../helpers/consts";
import {
  compressImage,
  createTreeSelect,
  hasPermission,
  setItemsWithoutParentToHighestNode,
} from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ButtonSaveType, EXECUTOR_TYPES, IApiResponse, ILocation, SelectTreeItem } from "../models";
import { getAssetXHR, getSubAssetsXHR } from "../store/reducers/asstes/actionCreators";
import { settingsSlice } from "../store/reducers/settings";
import { getChecklistsXHR } from "../store/reducers/settings/actionCreator";
import { CustomFieldValues, ICustomValue } from "../models/settings";
import FilesComponent from "../components/Files";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { IFile } from "../models/user";
import AssetForm from "./AssetForm";
import RequirePermission from "../components/RequirePermission";
import {
  getClientAssetXHR,
  rentAssetXHR,
  rentAssetXHR_v2,
} from "../store/reducers/clients/actionCreators";
import ClientsSelect from "./ClientsSelect";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";
import { IClient, IRent } from "../models/clients";
import { IRentBody_V2 } from "./RentForm";
import { ClientAssetType, IAsset } from "../models/asset";
import ClientForm from "./ClientForm";
import { store } from "../store";

type RedirectOptions = "profile" | "list";

interface IRentForm {
  client: number | null;
  note: string;
  asset: number | null;
  location: number | null;
  custom_fields: ICustomValue[];
  rent_order_custom_fields_v2: CustomFieldValues;
}

interface IProps {
  onClose: () => void;
  redirectTo?: RedirectOptions;
  asset?: number;
  client?: number;
  rentOrder?: IRent;
}

type GetAssetQueryParams = {
  client: number | undefined;
  search: string;
  client_asset_type: "available";
};

const { OptGroup, Option } = Select;

const RentForm_v2: React.FC<IProps> = ({
  onClose,
  redirectTo,
  asset, // ukoliko se kreira iz zahtjeva za radom
  client,
  rentOrder,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addLocationVisible, set_addLocationVisible] = useState<boolean>(false);
  const { rents, rentAssetStatus } = useAppSelector((state) => state.clientsReducer);
  const { assetList } = useAppSelector((state) => state.assetReducer);
  const [assetToRent, set_assetToRent] = useState<IAsset[]>([]);
  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const { locations, getLocationsStatus, checklists, companyCustomFieldsV2 } = useAppSelector(
    (state) => state.settingsReducer,
  );

  const [form] = Form.useForm<IRentForm>();
  const [assetTree, set_assetTree] = useState<SelectTreeItem[]>([]);
  const [assetModalVisible, set_assetModalVisible] = useState<boolean>(false);
  const [loadingSave, set_loadingSave] = useState<ButtonSaveType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<GetAssetQueryParams>({
    client: undefined,
    search: "",
    client_asset_type: "available",
  });

  const [addNewClient, set_addNewClient] = useState<IClient | undefined>(undefined);

  // Functions

  const inputDescriptionRef = useRef<Input>(null);

  const clientsSelectRef = useRef<any>(null);

  function fetchAvalibleAssetToRent(queryParams: GetAssetQueryParams) {
    setLoading(true);
    getClientAssetXHR(
      {
        queryParams: {
          ...queryParams,
          rent_search: queryParams.search,
          ownership_search: queryParams.search,
          available_search: queryParams.search,
          client_asset_type: queryParams.client_asset_type,
        },
        errorCallback: (error) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          console.log(error);
          setLoading(false);
        },
        successCallback: (data) => {
          if (!data.results) {
            return;
          }
          let _data = [...data.results] as any;
          set_assetToRent(_data);
          setLoading(false);
        },
      },
      dispatch,
    );
  }

  useEffect(() => {
    let tmp: any = assetToRent;
    tmp = setItemsWithoutParentToHighestNode(tmp);
    set_assetTree(
      createTreeSelect(
        tmp.map((x) => ({
          title: x.path,
          value: x.id.toString(),
          parent_id: x.parent_id?.toString() || null,
          disabled: false,
        })),
        null,
      ),
    );
  }, [assetToRent]);

  useEffect(() => {
    fetchAvalibleAssetToRent(queryParams);
    if (asset) {
      setClientIfHasModule(asset);
    }
  }, [asset]);

  useEffect(() => {
    getAssetXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (res) => {},
      },
      dispatch,
    );
    if (client) {
      clientsSelectRef.current?.callMissingClient(client);
    }
    form.setFieldsValue({ client: client });
  }, []);

  const onFinish = (values: IRentForm) => {
    let formData = new FormData();

    let formatedData: IRentBody_V2 = {
      client: values.client || 0,
      note: values.note || undefined,
      asset: values.asset || 0,
      location: values.location,
      rent_order_custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.rent_order),
    };

    formData.append("data", JSON.stringify(formatedData));

    // Appending materials
    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    requests.then(() =>
      rentAssetXHR_v2(
        {
          body: formData,
          successCallback: (data) => {
            fetchAvalibleAssetToRent(queryParams);
            if (data.results) {
              if (loadingSave === "save") {
                onClose && onClose();
              } else {
                form.resetFields();
                if (client) {
                  form.setFieldsValue({ client: client });
                }
                set_previewFiles([]);
                set_files([]);
                let drawer = document.querySelector(".ant-drawer-body");
                if (drawer) {
                  drawer.scrollTop = 0;
                }
                inputDescriptionRef.current?.focus();
                set_loadingSave(undefined);
              }
            }
          },
          errorCallback: (err) => {
            if (err.response.data?.message?.custom_field) {
              message.error(
                t(err.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  err.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
        },
        dispatch,
      ),
    );
  };

  const memedAddingAsset = useMemo(
    () => (
      <Modal
        visible={assetModalVisible}
        onCancel={() => {
          set_assetModalVisible(false);
        }}
        footer={null}
        title={t(TRANSLATION_KEY.addAsset)}
        centered
        width="600px"
        closable
        destroyOnClose
      >
        <AssetForm
          hideSaveAndAdd={true}
          addingFromOrder={true}
          asset={undefined}
          close={(id?: number, autopilot?: boolean, location?: number) => {
            form.setFieldsValue({ asset: id?.toString() as any });
            if (location) {
              form.setFieldsValue({ location: location });
            }
            let asset = store.getState().assetReducer.assetList.find((x) => x.id === id);
            console.log(asset, "HJKHJK", assetList, id);
            if (asset) {
              set_assetToRent([...assetToRent, asset]);
            }
            set_assetModalVisible(false);
          }}
        />
      </Modal>
    ),
    [assetModalVisible],
  );

  const memedAddingClient = useMemo(
    () => (
      <Modal
        bodyStyle={{
          maxHeight: "calc(100vh - 240px)",
          overflowY: "auto",
        }}
        style={{ paddingBottom: 15 }}
        visible={!!addNewClient}
        onCancel={() => {
          set_addNewClient(undefined);
        }}
        footer={null}
        title={t(TRANSLATION_KEY.addClient)}
        centered
        width="600px"
        closable
        destroyOnClose
      >
        {addNewClient && (
          <ClientForm
            showAddMore={false}
            client={addNewClient}
            close={(client: IClient | undefined) => {
              clientsSelectRef.current?.callMissingClient(client?.id);
              set_addNewClient(undefined);
              form.setFieldsValue({ client: client?.id });
            }}
          />
        )}
      </Modal>
    ),

    [addNewClient],
  );

  // Materials
  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  const setClientIfHasModule = (asset_id: number) => {
    return; // nisam siguran treba li ovo TESTIRAM
    let assTmp = assetList.find((x) => x.id === asset_id);
    let client_id = assTmp?.client_owner?.id || assTmp?.rented_to_client?.id || null;
    if (!client_id) {
      return;
    }
    if (!assTmp?.client_owner?.is_active && !assTmp?.rented_to_client?.is_active) {
      return;
    }
    clientsSelectRef.current?.callMissingClient(client_id);
    form.setFieldsValue({ client: client_id });
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Space align="center">
          <ClientsSelect
            includeArchived={false}
            includeInactive={false}
            ref={clientsSelectRef}
            style={{
              width: 450,
            }}
            mode={undefined}
            value={form.getFieldValue("client")}
            label={t(TRANSLATION_KEY.client)}
            name={"client"}
            onChange={(a) => {}}
            form={undefined}
            required={true}
            excludeWithoutClient={true}
          />
          {!client && (
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                set_addNewClient({
                  id: 0,
                  name: "",
                  phone_number: "",
                  address: "",
                  email: "",
                  custom_fields_v2: {},
                  custom_id: "",
                  description: "",
                  is_archived: false,
                  is_active: true,
                })
              }
              type="primary"
              shape="circle"
              style={{ marginTop: 3, float: "right" }}
            />
          )}
        </Space>
        <Space align="center">
          <Form.Item
            rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
            name={"asset"}
            label={t(TRANSLATION_KEY.asset)}
          >
            <TreeSelect
              style={{ width: 450 }}
              treeNodeFilterProp="title"
              showSearch
              allowClear
              filterTreeNode={(search, item: any) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
              }}
              onChange={(id: string) => {
                if (id) {
                  setClientIfHasModule(+id);
                  let asset = assetList.find((x) => x.id == +id);
                  console.log(asset);
                  if (asset) {
                    form.setFieldsValue({ location: asset.location?.id });
                  }
                }
              }}
              treeData={assetTree}
            />
          </Form.Item>

          {!asset && (
            <RequirePermission
              requiredPermissionCode={["manage_asset"]}
              children={
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => set_assetModalVisible(true)}
                  type="primary"
                  shape="circle"
                  style={{ marginTop: 3, float: "right" }}
                />
              }
            />
          )}
        </Space>

        <Form.Item
          rules={[{ required: false, message: t(TRANSLATION_KEY.filedRequired) }]}
          label={t(TRANSLATION_KEY.note)}
          name={"note"}
        >
          <Input.TextArea ref={inputDescriptionRef} disabled={false} rows={3} />
        </Form.Item>

        <Form.Item name={"location"} label={t(TRANSLATION_KEY.location)}>
          <Select
            disabled={false}
            allowClear={true}
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (typeof option?.children === "string") {
                const str: string = option.children;
                return str.toLowerCase().includes(input.toLowerCase());
              }
              return false;
            }}
            showSearch={true}
            notFoundContent={
              <ButtonAddSelectTag
                addingVisible={addLocationVisible}
                set_addingVisible={set_addLocationVisible}
              />
            }
          >
            {locations.map((x) => (
              <Select.Option value={x.id} key={x.id}>
                {x.name}
              </Select.Option>
            ))}
            <Select.OptGroup
              label={
                <ButtonAddSelectTag
                  addingVisible={addLocationVisible}
                  set_addingVisible={set_addLocationVisible}
                />
              }
            />
          </Select>
        </Form.Item>
        <GenerateForCustomFieldsV2
          values={{}}
          condition={undefined}
          customFields={companyCustomFieldsV2.rent_order}
          form={form}
        />

        <div>
          <div style={{ height: 24 }}></div>

          <Divider plain>
            <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
          </Divider>

          <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
            <Typography.Title level={5}>{t(TRANSLATION_KEY.documents)}</Typography.Title>
            <Upload
              multiple={true}
              accept={MATERIAL_EXTENSIONS}
              beforeUpload={beforeUpload}
              showUploadList={false}
            >
              <Button type="primary" shape="circle" icon={<UploadOutlined />} />
            </Upload>
          </div>

          {/* Files list */}
          <FilesComponent
            files={previewFiles}
            onDelete={onRemove}
            hideEditButton
            size="small"
            layout="list"
          />

          <div style={{ height: 24 }}></div>
        </div>
        <Form.Item>
          <Space>
            <Button
              onClick={() => {
                set_loadingSave("save");
              }}
              loading={rentAssetStatus === "loading" && loadingSave === "save"}
              htmlType="submit"
              type="primary"
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
            <Button
              onClick={() => {
                set_loadingSave("saveAndAdd");
              }}
              loading={rentAssetStatus === "loading" && loadingSave === "saveAndAdd"}
              htmlType="submit"
              type="primary"
            >
              {t(TRANSLATION_KEY.saveAndAdd)}
            </Button>
          </Space>
        </Form.Item>
      </Form>

      {memedAddingAsset}
      {memedAddingClient}

      <SelectTagAdd
        title={t(TRANSLATION_KEY.addLocation)}
        url="settings/locations/"
        set_visible={set_addLocationVisible}
        visible={addLocationVisible}
        successCallback={(tag: Tag) => {
          const data = [...locations];
          data.push({ id: tag.id, name: tag.name });
          const resFake: IApiResponse<ILocation[]> = {
            message: "",
            results: data,
          };

          dispatch(settingsSlice.actions.getLocationsSuccess(resFake));

          form.setFieldsValue({ location: tag.id });
        }}
      />
    </div>
  );
};

export default RentForm_v2;

//kada treba slati na backend
