import moment from "moment";
import { useStopwatch } from "react-timer-hook";

type IProps = {
  startTime: string;
  scale?: number;
  transformOrigin?: string;
};

const Timer = ({ startTime, scale = 1, transformOrigin = "center" }: IProps) => {
  // Variables
  let now = moment(new Date());
  let duration = moment.duration(now.diff(startTime));
  let secondsFromStartTime = Number(duration.asSeconds().toFixed(0)) || 0;
  let startOffset = new Date();

  // Stopwatch
  const { seconds, minutes, hours, days } = useStopwatch({
    autoStart: true,
    offsetTimestamp: moment(startOffset).add(secondsFromStartTime, "seconds").toDate(),
  });

  return (
    <div style={{ transform: `scale(${scale})`, transformOrigin }}>
      <div className="timer-wrapper">
        <span>{days}d</span>
        <div>:</div>
        <span>{hours}h</span>
        <div>:</div>
        <span>{minutes}m</span>
        <div>:</div>
        <span>{seconds}s</span>
      </div>
    </div>
  );
};

export default Timer;
