import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { IMaintenance } from "../models/maintenances";
import MaintenancesTable from "./MaintenancesTable";
import RUNNING_ICON from "../assets/icons/runningIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../helpers/consts";

interface IProps {
  maintenances: IMaintenance[];
}

const RunningOrders: React.FC<IProps> = ({ maintenances }) => {
  // Variables
  const [runingOrders, set_runingOrders] = useState<boolean>(false);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    set_runingOrders(false);
  }, [location.pathname]);

  return (
    <div>
      {maintenances.length > 0 && (
        <Button
          onClick={() => {
            if (maintenances.length === 1) {
              navigate(`/app/maintenances/${maintenances[0].id}`);
            } else {
              set_runingOrders(true);
            }
          }}
          style={{ borderWidth: 0, marginRight: 26, marginLeft: 6, padding: 0 }}
        >
          <img style={{ width: 22, height: 22 }} src={RUNNING_ICON} />
        </Button>
      )}
      <Modal
        title={t(TRANSLATION_KEY.runningOrders)}
        width="80vw"
        visible={runingOrders}
        onCancel={() => set_runingOrders(false)}
        footer={null}
      >
        <MaintenancesTable
          hideHeader={true}
          data={maintenances}
          loading={false}
          showOpenCalendarButton={false}
          handleOpenCalendar={null}
          onDateChanged={(start, end) => {}}
          onLoadMore={() => {}}
          onSearch={(search) => {}}
          start={""}
          end={""}
          working_data_show={false}
        />
      </Modal>
    </div>
  );
};

export default RunningOrders;
