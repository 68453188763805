import { message, PageHeader, Spin, Tabs } from "antd";
import { t } from "i18next";
import { useEffect } from "react";
import { useNavigate, useLocation, Outlet, Link } from "react-router-dom";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { AppRoutes } from "../../navigation/routes";
import { getCompanySettingsXHR, getRolesXHR } from "../../store/reducers/settings/actionCreator";
const { TabPane } = Tabs;

const Settings = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { getCompanySettingsStatus } = useAppSelector((state) => state.settingsReducer);

  useEffect(() => {
    getRolesXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
      },
      dispatch,
    );
    getCompanySettingsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  return (
    <>
      <div>
        {/* Page header */}
        <PageHeader
          style={{ padding: 0, paddingTop: 10, marginBottom: 20 }}
          title={t(TRANSLATION_KEY.settings)}
          footer={
            <Tabs
              id="bordered-tabs"
              activeKey={location.pathname.split("/")[3] || ""}
              onChange={onTabChange}
            >
              {AppRoutes("allroutes")
                .children?.find((x) => x.path === "settings")
                ?.children?.map((x) => (
                  <TabPane
                    tab={
                      <Link style={{ fontSize: 14 }} to={x.path}>
                        {t(x.path || "modeling")}
                      </Link>
                    }
                    key={x.path || ""}
                  />
                ))}
            </Tabs>
          }
        />

        <Spin spinning={getCompanySettingsStatus === "loading"}>
          <div>
            <Outlet />
          </div>
        </Spin>
      </div>
    </>
  );
};

export default Settings;
