import { supplierSlice } from ".";
import { AppDispatch } from "../..";
import { IApiResponse, IDataToApi } from "../../../models";
import {
  IAddWorkingTimeToOpenOrder,
  IFlow,
  IMaintenanceDetails,
  IUserReport,
} from "../../../models/maintenances";
import { ITask } from "../../../models/settings";
import { ISupplier, ISupplierCategory } from "../../../models/supplier";
import api, { failedQueue, isRefreshing } from "../../../services";

export const getSupplierCategoriesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ISupplierCategory[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(supplierSlice.actions.getSupplierCategories());
    let response = await api.get<IApiResponse<ISupplierCategory[]>>(`suppliers/categories/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.getSupplierCategoriesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getSupplierCategoriesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(supplierSlice.actions.getSupplierCategoriesFail(error.message));
  }
};

export const getSuppliersXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ISupplier[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(supplierSlice.actions.getSuppliers());
    let response = await api.get<IApiResponse<ISupplier[]>>(`suppliers/suppliers/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.getSuppliersSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getSuppliersXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.getSuppliersFail(error.message));
  }
};

export const addSupplierXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<ISupplier>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.addSupplier());
    let response = await api.post<IApiResponse<ISupplier>>(
      `suppliers/suppliers/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.addSupplierSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addSupplierXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.addSupplierFail(error.message));
  }
};

export const getSupplierXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ISupplier>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(supplierSlice.actions.getSupplier());
    let response = await api.get<IApiResponse<ISupplier>>(`suppliers/suppliers/${data.id}/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.getSupplierSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getSupplierXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(supplierSlice.actions.getSupplierFail(error.message));
  }
};

export const updateSupplierXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<ISupplier>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(supplierSlice.actions.updateSupplier());
    let response = await api.put<IApiResponse<ISupplier>>(
      `suppliers/suppliers/${data.id}/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.updateSupplierSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateSupplierXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(supplierSlice.actions.updateSupplierFail(error.message));
  }
};

export const getOpenOrderXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IMaintenanceDetails>>>,
  dispatch: AppDispatch,
) => {
  // const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.getOpenOrder());
    let response = await api.get<IApiResponse<IMaintenanceDetails>>(`maintenance/open_order/`, {
      params: data.queryParams,
      // headers: { Authorization: "Bearer " + token }
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.getOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.getOpenOrderFail(error.message));
  }
};

export const startOpenOrderXHR = async (
  data: Partial<
    IDataToApi<
      {
        order_info: number;
        token: string;
        code: string;
      },
      IApiResponse<IMaintenanceDetails>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.startOpenOrder());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/start_open_order/`,
      data.body,

      //  {headers: { Authorization: "Bearer " + token }}
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.startOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.startOpenOrderFail(error.message));
  }
};

export const finishOpenOrderXHR = async (
  data: Partial<
    IDataToApi<
      {
        order_info: number;
        token: string;
        code: string;
      },
      IApiResponse<IMaintenanceDetails>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.finishOpenOrder());
    let response = await api.post<IApiResponse<IMaintenanceDetails>>(
      `maintenance/complete_open_order/`,
      data.body,

      //{headers: { Authorization: "Bearer " + token }}
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.finishOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.finishOpenOrderFail(error.message));
  }
};

export const addTaskToOpenOrderXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<ITask[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.addTaskToOpenOrder());
    let response = await api.post<IApiResponse<ITask[]>>(
      `maintenance/open_order_task/`,
      data.body,

      // {headers: { Authorization: "Bearer " + token }}
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.addTaskToOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.addTaskToOpenOrderFail(error.message));
  }
};

export const addTaskResultOnOpenOrderXHR = async (
  data: Partial<
    IDataToApi<
      { result: string | string[] | number; code: string; token: string },
      IApiResponse<ITask[]>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.addTaskResultOnOpenOrder());
    let response = await api.put<IApiResponse<ITask[]>>(
      `maintenance/open_order_task_result/${data.id}/`,
      data.body,

      //{headers: { Authorization: "Bearer " + token }}
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.addTaskResultOnOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.addTaskResultOnOpenOrderFail(error.message));
  }
};

export const addWorkingTImeToOpenOrderXHR = async (
  data: Partial<IDataToApi<IAddWorkingTimeToOpenOrder, IApiResponse<IFlow[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.addWorkingTImeToOpenOrder());
    let response = await api.post<IApiResponse<IFlow[]>>(
      `maintenance/open_order_workflow/`,
      data.body,

      // {headers: { Authorization: "Bearer " + token }}
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.addWorkingTImeToOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addWorkingTImeToOpenOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.addWorkingTImeToOpenOrderFail(error.message));
  }
};

export const deleteWorkingTimeOnOpenOrderXHR = async (
  data: Partial<IDataToApi<{ token: string; code: string }, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.deleteWorkingTimeOnOpenOrder(data.id?.toString() || ""));
    let response = await api.delete<IApiResponse<string>>(
      `maintenance/open_order_workflow/${data.id}/`,
      { data: data.body },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.deleteWorkingTimeOnOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => deleteWorkingTimeOnOpenOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.deleteWorkingTimeOnOpenOrderFail(error.message));
  }
};

export const addReportToOpenOrderXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IUserReport>>>,
  dispatch: AppDispatch,
) => {
  try {
    dispatch(supplierSlice.actions.addReportToOpenOrder());
    let response = await api.post<IApiResponse<IUserReport>>(
      `maintenance/supplier_report/`,
      data.body,
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.addReportToOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addReportToOpenOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.addReportToOpenOrderFail(error.message));
  }
};

export const updateReportToOpenOrderXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IUserReport>>>,
  dispatch: AppDispatch,
) => {
  try {
    dispatch(supplierSlice.actions.updateReportToOpenOrder());
    let response = await api.put<IApiResponse<IUserReport>>(
      `maintenance/supplier_report/${data.id}/`,
      data.body,
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.updateReportToOpenOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateReportToOpenOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.updateReportToOpenOrderFail(error.message));
  }
};

export const sendSupplierEmailXHR = async (
  data: Partial<
    IDataToApi<{ executor: number; note: string | null; email: string }, IApiResponse<string>>
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(supplierSlice.actions.sendSupplierEmail());
    let response = await api.post<IApiResponse<string>>(
      `maintenance/send_open_order_link/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(supplierSlice.actions.sendSupplierEmailSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => sendSupplierEmailXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(supplierSlice.actions.sendSupplierEmailFail(error.message));
  }
};
