import React, { useEffect, useState } from "react";
import { IAsset } from "../../../../models/asset";
import { List, message } from "antd";
import AssetNode from "../../../asset/tree/asset";
import { NewTreeItem } from "../../../../models";
import {
  createTree,
  createTreePanelAsset,
  setItemsWithoutParentToHighestNode,
} from "../../../../helpers/functions";
import { IPanelAsset } from "../../../../models/settings";
import { getRequestPanelDetailsXHR } from "../../../../store/reducers/settings/actionCreator";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import AssetModalPreview from "../../../assetdetails/components/AssetModalPreview";

interface IProps {
  panelID: number;
}

const RequestPanelAsset: React.FC<IProps> = ({ panelID }) => {
  const [panelAssets, set_panelAssets] = useState<NewTreeItem<IAsset>[]>([]);

  const dispatch = useAppDispatch();
  const { requestPanelDetails, getRequestPanelStatus } = useAppSelector(
    (state) => state.settingsReducer,
  );
  const [selectedAsset, set_selectedAsset] = useState<number | undefined>(undefined);

  useEffect(() => {
    getRequestPanelDetailsXHR(
      {
        id: panelID,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
  }, []);

  React.useEffect(() => {
    set_panelAssets(
      createTree(setItemsWithoutParentToHighestNode(requestPanelDetails?.assets || []), null),
    );
  }, [requestPanelDetails]);

  return (
    <>
      <List
        loading={getRequestPanelStatus === "loading"}
        dataSource={panelAssets}
        renderItem={(item) => (
          <AssetNode
            loading={"noLoading"}
            canDeleteAsset={false}
            item={item}
            deep={0}
            handleOpenModal={(id) => {
              set_selectedAsset(Number(id));
            }}
            viewAsset={true}
          />
        )}
      />

      {selectedAsset && <AssetModalPreview id={selectedAsset} />}
    </>
  );
};

export default RequestPanelAsset;
