import { Alert, Button, Checkbox, Col, Form, Input, message, Row, Spin } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Languages, PHONE_NUMBER_PLACEHOLDERS, TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse } from "../../../models";
import { IMaintenanceDetails } from "../../../models/maintenances";
import api, { failedQueue, isRefreshing } from "../../../services";
import { UseDigitalSignature } from "../useDigitalSignature";
import { CreateSignatureRequest } from "../useDigitalSignature/types";
import { getCompanySettingsXHR } from "../../../store/reducers/settings/actionCreator";

type Props = {
  maintenance: IMaintenanceDetails;
  digitalSignature: UseDigitalSignature;
};

type SignatureForm = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  sms_otp: boolean;
  send_document_after_signing: boolean;
};

const DigitalSignatureForm = ({ maintenance, digitalSignature }: Props) => {
  // Hooks
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  // State
  const { user } = useAppSelector((state) => state.userReducer);
  const { companySettings } = useAppSelector((state) => state.settingsReducer);
  const [smsOTP, setSmsOTP] = useState(companySettings.el_signature_sms_otp || false);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Partial<SignatureForm>>({
    email: maintenance.client?.email || undefined,
    sms_otp: companySettings.el_signature_sms_otp || false,
  });
  // Effects
  useEffect(() => {
    getCompanySettingsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback(data) {
          setInitialValues({
            email: maintenance.client?.email || undefined,
            sms_otp: data.results?.el_signature_sms_otp,
          });
          setSmsOTP(data.results?.el_signature_sms_otp || false);
        },
      },
      dispatch,
    );
  }, []);
  useEffect(() => {
    setInitialValues({
      email: maintenance.client?.email || undefined,
      sms_otp: companySettings.el_signature_sms_otp,
    });
  }, [maintenance.client]);

  // Functions
  const onFinish = async (values: SignatureForm) => {
    // Parse data
    const body: CreateSignatureRequest = {
      order_info: maintenance.id,
      send_document_after_signing: false,
      // send_document_after_signing: values.send_document_after_signing || false,
      sms_otp: smsOTP,
      signer_data: {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number || null,
      },
      order_data: {
        avatar: user.account.company.avatar,
        language: user.account.language || Languages.English,
        maintenance,
      },
    };

    // Call request
    setLoading(true);
    const token = await localStorage.getItem("token");
    try {
      let response = await api.post<IApiResponse<{ signature_link: string }>>(
        `/electronic_signature/signature_requests/`,
        body,
        { headers: { Authorization: "Bearer " + token } },
      );
      if (response.data.results?.signature_link) {
        digitalSignature.setSignatureLink(response.data.results.signature_link);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onFinish(values));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        {/* First and last name */}
        <Input.Group>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label={t(TRANSLATION_KEY.firstName)}
                rules={[
                  {
                    required: true,
                    message: t(TRANSLATION_KEY.filedRequired),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label={t(TRANSLATION_KEY.lastName)}
                rules={[
                  {
                    required: true,
                    message: t(TRANSLATION_KEY.filedRequired),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>

        {/* E-mail address */}
        <Form.Item
          name="email"
          label={t(TRANSLATION_KEY.email)}
          rules={[
            {
              type: "email",
              message: t(TRANSLATION_KEY.enterCorrectEmail),
            },
            {
              required: true,
              message: t(TRANSLATION_KEY.filedRequired),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone_number"
          label={t(TRANSLATION_KEY.phoneNumber)}
          rules={[
            () => ({
              validator(_, value) {
                if (!value && smsOTP) {
                  return Promise.reject(new Error(t(TRANSLATION_KEY.filedRequired)));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            placeholder={PHONE_NUMBER_PLACEHOLDERS[companySettings.language || Languages.Croatian]}
          />
        </Form.Item>

        {/* OTP Checkbox */}
        <Checkbox
          value={smsOTP}
          key={`otp-${smsOTP}`}
          checked={smsOTP}
          onChange={(e) => setSmsOTP(e.target.checked)}
          style={{ margin: "8px 0" }}
        >
          {t(TRANSLATION_KEY.smsAuthorization)}
        </Checkbox>

        {/* Send document after signing Checkbox */}
        {/* <Form.Item name="send_document_after_signing" valuePropName="checked">
          <Checkbox>{ t(TRANSLATION_KEY.sendDocumentAfterSignature) }</Checkbox>
        </Form.Item> */}

        {maintenance.status !== "closed" && (
          <Alert
            message={t(TRANSLATION_KEY.completeBeforeSigning)}
            type="warning"
            showIcon
            style={{ marginBottom: 24 }}
          />
        )}

        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button disabled={maintenance.status !== "closed"} type="primary" htmlType="submit">
              {t(TRANSLATION_KEY.sign)}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default DigitalSignatureForm;
