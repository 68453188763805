import React, { useState, useEffect, useRef } from "react";
import { t } from "i18next";

// Antd
import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Typography, List, Modal, Input, message, Row, Col, Form, Divider } from "antd";
import { ICON_SIZE, TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IMaintenanceType } from "../../../../../models/maintenances";
import { getMaintenancesTypesXHR } from "../../../../../store/reducers/maintenance/actionCreator";
import { unstable_batchedUpdates } from "react-dom";
import Card from "./MaintenanceTypeCard";
import { IApiResponse } from "../../../../../models";
import api, { failedQueue, isRefreshing } from "../../../../../services";
import { maintenanceSlice } from "../../../../../store/reducers/maintenance";

const MaintenancesTypes: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [loading, set_loading] = useState<boolean>(false);
  const [typeName, set_typeName] = useState<string>("");
  const [typeColor, set_typeColor] = useState<string>("#000000");
  const [type, set_type] = useState<IMaintenanceType>();

  const inputRef = useRef<Input>(null);

  // Methods
  useEffect(() => {
    getMaintenancesTypesXHR({}, dispatch);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus({ cursor: "end" });
    }, 300);
  }, [type]);

  const { getMaintenancesTypesStatus, maintenancesTypes } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  const addType = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.post<IApiResponse<IMaintenanceType[]>>(
        `/maintenance/types/`,
        { name: typeName, color: typeColor, is_halt: false },
        { headers: { Authorization: "Bearer " + token } },
      );
      dispatch(
        maintenanceSlice.actions.getMaintenancesTypesSuccess({
          results: response.data.results,
          message: "",
        }),
      );
      set_typeName("");
      set_type(undefined);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => addType());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onActionUpdate = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.put<IApiResponse<IMaintenanceType[]>>(
        `/maintenance/types/${type?.id}/`,
        { name: typeName, color: typeColor },
        { headers: { Authorization: "Bearer " + token } },
      );

      dispatch(
        maintenanceSlice.actions.getMaintenancesTypesSuccess({
          results: response.data.results,
          message: "",
        }),
      );
      set_type(undefined);
      set_typeName("");
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onActionUpdate());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onDelete = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/maintenance/types/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr: IMaintenanceType[] = [...maintenancesTypes];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      dispatch(
        maintenanceSlice.actions.getMaintenancesTypesSuccess({
          results: arr,
          message: "",
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  return (
    <div>
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.maintenanceTypes)}
        </Typography.Title>
        <Button
          onClick={() => {
            set_type({ name: "", id: 0, color: "", static: false, trans_key: "" });
          }}
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
        />
      </div>

      <Divider style={{ marginTop: 10 }} />

      <List
        dataSource={maintenancesTypes}
        loading={loading || getMaintenancesTypesStatus === "loading"}
        renderItem={(x: IMaintenanceType) => (
          <Card
            item={x}
            onDelete={onDelete}
            onUpdate={() =>
              unstable_batchedUpdates(() => {
                set_type(x);
                set_typeName(t(x.trans_key || x.name || ""));
                set_typeColor(x.color);
              }, [])
            }
          />
        )}
      />

      <Modal
        destroyOnClose={true}
        title={
          type?.id === 0
            ? t(TRANSLATION_KEY.newMaintenanceType)
            : t(TRANSLATION_KEY.editMaintenanceType)
        }
        okButtonProps={{ disabled: !typeName }}
        okText={t(TRANSLATION_KEY.save)}
        cancelText={t(TRANSLATION_KEY.cancel)}
        visible={!!type}
        onCancel={() => {
          set_type(undefined);
          set_typeName("");
          set_typeColor("#000000");
        }}
        footer={null}
      >
        <Form
          onFinish={() => {
            if (type?.id === 0) {
              addType();
            } else {
              onActionUpdate();
            }
          }}
        >
          <Input.Group size="large">
            <Row gutter={8}>
              <Col span={20}>
                <Input
                  ref={inputRef}
                  value={typeName}
                  onChange={({ target: { value } }) => {
                    set_typeName(value);
                  }}
                />
              </Col>
              <Col span={4}>
                <Input
                  value={typeColor}
                  type="color"
                  onChange={({ target: { value } }) => {
                    set_typeColor(value);
                  }}
                />
              </Col>
            </Row>
            <Button style={{ marginTop: 24 }} type="primary" htmlType="submit">
              {t(TRANSLATION_KEY.save)}
            </Button>
          </Input.Group>
        </Form>
      </Modal>
    </div>
  );
};

export default MaintenancesTypes;
