import { FC } from "react";

type GridProps = {
  columns?: number;
  columnSize?: number;
  gridGap: number;
};

const SortableGrid: FC<GridProps> = ({ children, columns, columnSize, gridGap }) => {
  let gridTemplateColumns: string = columns
    ? `repeat(${columns}, 1fr)`
    : `repeat(auto-fit, minmax(${columnSize || 100}px, 1fr))`;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns,
        gridGap,
      }}
    >
      {children}
    </div>
  );
};

export default SortableGrid;
