import { message } from "antd";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { debounce } from "../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IApiResponse } from "../../../../models";
import { BaseListItem, ClientAssetType } from "../../../../models/asset";
import { IClientDetails } from "../../../../models/clients";
import api, { failedQueue, isRefreshing } from "../../../../services";
import { clientsSlice } from "../../../../store/reducers/clients";
import {
  closeRentOrderXHR,
  getClientAssetXHR,
} from "../../../../store/reducers/clients/actionCreators";

interface useClientAssetQueryParams {
  client: number;
  search: string;
  client_asset_type: ClientAssetType[];
}

const useAsset = (client: IClientDetails) => {
  // Hooks
  const dispatch = useAppDispatch();

  // State
  const { clientAsset } = useAppSelector((state) => state.clientsReducer);

  // Variables
  const [rentData, setRentData] = useState<BaseListItem[]>([]);
  const [ownershipData, setOwnershipData] = useState<BaseListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<number>(-1);
  const [clientAssetType, setClientAssetType] = useState<ClientAssetType>("rent");
  const [queryParams, setQueryParams] = useState<useClientAssetQueryParams>({
    client: client.id,
    search: "",
    client_asset_type: ["ownership", "rent"],
  });

  // Functions
  function fetchClientAsset(queryParams: useClientAssetQueryParams) {
    setLoading(true);
    getClientAssetXHR(
      {
        queryParams: {
          ...queryParams,
          rent_search: queryParams.search,
          ownership_search: queryParams.search,
          available_search: queryParams.search,
          client_asset_type: queryParams.client_asset_type.join("|"),
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          setLoading(false);
        },
        successCallback: () => {
          setLoading(false);
        },
      },
      dispatch,
    );
  }

  function initialFetch(client: number) {
    fetchClientAsset({ ...queryParams, client });
  }

  useEffect(() => {
    unstable_batchedUpdates(() => {
      // Filter data by client asset type
      const rentData = clientAsset.filter((x) => x.client_asset_type === "rent");
      const ownershipData = clientAsset.filter((x) => x.client_asset_type === "ownership");

      // Set data
      setRentData(rentData);
      setOwnershipData(ownershipData);
    }, []);
  }, [clientAsset]);

  const debouncedGetClients = useCallback(
    debounce((queryParams: useClientAssetQueryParams) => {
      fetchClientAsset(queryParams);
    }, 600),
    [],
  );

  function onSearch(value: string, client: number) {
    debouncedGetClients({ ...queryParams, search: value, client });
  }

  async function removeAsset(id: number) {
    setDeleteLoading(id);
    const token = await localStorage.getItem("token");
    try {
      let response = await api.delete<IApiResponse<string>>(`/clients/rent_order/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      let results: BaseListItem[] = [...clientAsset];
      let index = results.findIndex((x) => x.rent_order_id === id);
      results.splice(index, 1);
      const fakeRes: IApiResponse<BaseListItem[]> = {
        results,
        message: "",
      };

      dispatch(clientsSlice.actions.getClientAssetSuccess(fakeRes));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => removeAsset(id));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    setDeleteLoading(-1);
  }

  return {
    rentData,
    ownershipData,
    loading,
    queryParams,
    clientAssetType,
    deleteLoading,
    // Setters
    initialFetch,
    onSearch,
    setClientAssetType,
    removeAsset,
  };
};

export default useAsset;
