import { settingsSlice } from ".";
import { AppDispatch, store } from "../..";
import { FormType } from "../../../componentsform/CustomFieldsFormV2";
import { IRequestPanelFormValues } from "../../../componentsform/WorkRequestPanel";
import { IApiResponse, IDataToApi, ILocation, IUsualExpense } from "../../../models";
import {
  CompanyCustomFieldsV2,
  CustomFieldV2,
  IChecklist,
  ICompanyCustomFields,
  ICompanySettings,
  ICurrency,
  IPermission,
  IRequestPanel,
  IRequestPanelDetails,
  IRole,
  ITask,
} from "../../../models/settings";
import api, { failedQueue, isRefreshing } from "../../../services";
import { sendPartnerInvitationXHR } from "../partner/actionCreators";

export const getLocationsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ILocation[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getLocations());
    let response = await api.get<IApiResponse<ILocation[]>>(`settings/locations/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getLocationsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getChecklistsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getLocationsFail(error.message));
  }
};

export const getUsualExpensesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IUsualExpense[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getUsualExpenses());
    let response = await api.get<IApiResponse<IUsualExpense[]>>(`maintenance/usual_expense/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getUsualExpensesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getUsualExpensesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getUsualExpensesFail(error.message));
  }
};

export const getCompanySettingsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ICompanySettings>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getCompanySettings());
    let response = await api.get<IApiResponse<ICompanySettings>>(`/settings/company/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getCompanySettingsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getCompanySettingsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getCompanySettingsFail(error.message));
  }
};

export const updateCompanyCustomFieldsXHR = async (
  data: Partial<IDataToApi<ICompanyCustomFields, IApiResponse<ICompanyCustomFields>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.updateCompanySettings());
    let response = await api.put<IApiResponse<ICompanyCustomFields>>(
      `settings/company/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.updateCompanySettingsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateCompanyCustomFieldsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.updateCompanySettingsFail(error.message));
  }
};

export const getRolesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IRole[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getRoles());
    let response = await api.get<IApiResponse<IRole[]>>(`settings/roles/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getRolesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getRolesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getRolesFail(error.message));
  }
};

export const getPermisionsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPermission[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getPermisions());
    let response = await api.get<IApiResponse<IPermission[]>>(`settings/permissions/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getPermisionsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPermisionsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getPermisionsFail(error.message));
  }
};

export const createRoleXHR = async (
  data: Partial<
    IDataToApi<
      { rolename: string; permissions: number[]; notification_types: number[] },
      IApiResponse<IRole[]>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.createRole());
    let response = await api.post<IApiResponse<IRole[]>>(
      `settings/roles/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.createRoleSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createRoleXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.createRoleFail(error.message));
  }
};

export const updateRoleXHR = async (
  data: Partial<
    IDataToApi<
      { rolename: string; permissions: number[]; notification_types: number[] },
      IApiResponse<IRole[]>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.updateRole());
    let response = await api.put<IApiResponse<IRole[]>>(
      `settings/roles/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.updateRoleSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateRoleXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.updateRoleFail(error.message));
  }
};

export const getChecklistsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IChecklist[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getChecklists());
    let response = await api.get<IApiResponse<IChecklist[]>>(`maintenance/checklist/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getChecklistsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getChecklistsXHR(data, dispatch));
      }
      console.log(getChecklistsXHR, "getChecklistsXHR");
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getChecklistsFail(error.message));
  }
};

export const setChecklistTaskRankXHR = async (
  data: Partial<
    IDataToApi<{ rank: number; task?: number }, IApiResponse<Omit<ITask, "checklist">[]>> & {
      url: string;
    }
  >,
  dispatch: AppDispatch,
) => {
  //if url exsits that means that template tasks rank are updatenig
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.setTaskRank());
    let response: any = null;
    if (data.url) {
      response = await api.put<IApiResponse<Omit<ITask, "checklist">[]>>(
        data.url,
        data.body,

        { headers: { Authorization: "Bearer " + token } },
      );
    } else {
      response = await api.put<IApiResponse<ITask[]>>(
        `maintenance/checklist_task/${data.id}/`,
        data.body,

        { headers: { Authorization: "Bearer " + token } },
      );
    }

    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.setTaskRankSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => setChecklistTaskRankXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.setTaskRankFail(error.message));
  }
};

export const getCompanyCusstomFieldsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ICompanyCustomFields>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getCompanyCusstomFields());
    let response = await api.get<IApiResponse<ICompanyCustomFields>>(
      `settings/company_custom_fields/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getCompanyCusstomFieldsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getCompanyCusstomFieldsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getCompanyCusstomFieldsFail(error.message));
  }
};

export const getCurrenciesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<string[]>>>,
  dispatch: AppDispatch,
) => {
  // Token
  try {
    dispatch(settingsSlice.actions.getCurrencies());
    const response = await api.get<IApiResponse<ICurrency[]>>(`companies/currency/`, {
      params: data.queryParams,
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getCurrenciesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getCurrenciesFail(error.message));
  }
};

export const createRequestPanelXHR = async (
  data: Partial<IDataToApi<IRequestPanelFormValues, IApiResponse<IRequestPanel>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.createRequestPanel());
    let response = await api.post<IApiResponse<IRequestPanel>>(
      `settings/request_panel/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.createRequestPanelSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createRequestPanelXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.createRequestPanelFail(error.message));
  }
};

export const getRequestPanelXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IRequestPanel[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getRequestPanel());
    let response = await api.get<IApiResponse<IRequestPanel[]>>(`settings/request_panel/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getRequestPanelSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getRequestPanelXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getRequestPanelFail(error.message));
  }
};

export const updateRequestPanelXHR = async (
  data: Partial<IDataToApi<IRequestPanelFormValues, IApiResponse<IRequestPanel>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.updateRequestPanel());
    let response = await api.put<IApiResponse<IRequestPanel>>(
      `settings/request_panel/${data.id}/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.updateRequestPanelSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateRequestPanelXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.updateRequestPanelFail(error.message));
  }
};

export const getRequestPanelDetailsXHR = async (
  data: Partial<{ token: string } & IDataToApi<null, IApiResponse<IRequestPanelDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getRequestPanelDetails());
    let response = !data.id
      ? await api.get<IApiResponse<IRequestPanelDetails>>(`settings/request_panel/`, {
          params: data.queryParams,
          headers: { Authorization: data.token || "" },
        })
      : await api.get<IApiResponse<IRequestPanelDetails>>(`settings/request_panel/${data.id}/`, {
          params: data.queryParams,
          headers: { Authorization: "Bearer " + token },
        });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getRequestPanelDetailsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getRequestPanelDetailsFail(error.message));
  }
};

// -----

export const getExcelFileXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<{ file_content: string }>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    let response = await api.get<IApiResponse<{ file_content: string }>>(`/settings/get_file/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getExcelFileXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};

export const createCustomFieldV2XHR = async (
  data: Partial<
    IDataToApi<
      { data: Omit<CustomFieldV2, "cf_id">; cf_category: FormType },
      IApiResponse<CompanyCustomFieldsV2>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.createCustomFieldV2());
    let response = await api.post<IApiResponse<CompanyCustomFieldsV2>>(
      `settings/company_custom_fields/v2/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.createCustomFieldV2Success(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createCustomFieldV2XHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.createCustomFieldV2Fail(error.message));
  }
};

export const getCustomFieldsV2XHR = async (
  data: Partial<IDataToApi<null, IApiResponse<CompanyCustomFieldsV2>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.getCustomFieldsV2());
    let response = await api.get<IApiResponse<CompanyCustomFieldsV2>>(
      `settings/company_custom_fields/v2/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.getCustomFieldsV2Success(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getCustomFieldsV2XHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.getCustomFieldsV2Fail(error.message));
  }
};

export const updateCustomFieldXHR = async (
  data: Partial<
    IDataToApi<{ cf_category: FormType; data: CustomFieldV2 }, IApiResponse<CompanyCustomFieldsV2>>
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(settingsSlice.actions.updateCustomField());
    let response = await api.post<IApiResponse<CompanyCustomFieldsV2>>(
      `settings/company_custom_fields/v2/update/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.updateCustomFieldSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateCustomFieldXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(settingsSlice.actions.updateCustomFieldFail(error.message));
  }
};

export const deleteCustomFieldXHR = async (
  data: Partial<
    IDataToApi<
      {
        cf_category: FormType;
        cf_id: string;
      },
      IApiResponse<CompanyCustomFieldsV2>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.post<IApiResponse<CompanyCustomFieldsV2>>(
      `settings/company_custom_fields/v2/delete/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(settingsSlice.actions.updateCustomFieldSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => deleteCustomFieldXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};
