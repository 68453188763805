import { Button, Input, List, Modal, Tabs } from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppSelector } from "../../../../hooks";
import { ClientAssetType } from "../../../../models/asset";
import AssetModalPreview from "../../../assetdetails/components/AssetModalPreview";
import AssetNode from "./AssetNode";
import useAsset from "./useAsset";
import { IClient } from "../../../../models/clients";

type IProps = {
  client_id: number;
  openModal: () => void;
};

const DrawerAsset: React.FC<IProps> = ({ client_id, openModal }) => {
  // State
  const { client } = useAppSelector((state) => state.clientsReducer);

  // Hooks
  const clientAsset = useAsset(client);

  // Variables
  const [assetModalVisible, setAssetModalVisible] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<string | number | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  // Functions
  useEffect(() => {
    if (client_id === -1) {
      return;
    }
    clientAsset.initialFetch(client_id);
  }, [client_id]);

  function handleOpenModal(id: string | number | undefined) {
    unstable_batchedUpdates(() => {
      setAssetModalVisible(true);
      setSelectedAsset(id);
    }, []);
  }

  // Content
  const OwnershipList = useMemo(
    () => (
      <List
        loading={clientAsset.loading}
        dataSource={clientAsset.ownershipData}
        size="small"
        renderItem={(item) => (
          <AssetNode
            loading={clientAsset.deleteLoading}
            canDeleteAsset={true}
            handleOnRemove={clientAsset.removeAsset}
            clientAssetType={clientAsset.clientAssetType}
            item={item}
            handleOpenModal={handleOpenModal}
            canViewAsset={true}
          />
        )}
      />
    ),
    [clientAsset.ownershipData, clientAsset.loading, clientAsset.clientAssetType],
  );

  const RentList = useMemo(
    () => (
      <List
        loading={clientAsset.loading}
        dataSource={clientAsset.rentData}
        size="small"
        renderItem={(item) => (
          <AssetNode
            loading={clientAsset.deleteLoading}
            canDeleteAsset={true}
            handleOnRemove={clientAsset.removeAsset}
            clientAssetType={clientAsset.clientAssetType}
            item={item}
            handleOpenModal={handleOpenModal}
            canViewAsset={true}
          />
        )}
      />
    ),
    [clientAsset.rentData, clientAsset.loading, clientAsset.clientAssetType],
  );

  return (
    <div className="asset-modal" style={{ maxWidth: 1200 }}>
      {/* Open modal CTA */}
      <Tabs
        defaultActiveKey="rent"
        onChange={(activeKey) => clientAsset.setClientAssetType(activeKey as ClientAssetType)}
      >
        {/* Rent */}
        <Tabs.TabPane tab={t(TRANSLATION_KEY.rent)} key="rent">
          {/* Search */}
          <Input.Search
            value={search}
            onChange={({ target: { value } }) => {
              clientAsset.onSearch(value?.toLowerCase() || "", client_id);
              setSearch(value?.toLowerCase() || "");
            }}
            placeholder={t(TRANSLATION_KEY.searchByName)}
            style={{ marginBottom: 12 }}
            allowClear
          />
          {RentList}
        </Tabs.TabPane>
        {/* Ownership */}
        <Tabs.TabPane tab={t(TRANSLATION_KEY.ownership)} key="ownership">
          {/* Search */}
          <Input.Search
            value={search}
            onChange={({ target: { value } }) => {
              clientAsset.onSearch(value?.toLowerCase() || "", client_id);
              setSearch(value?.toLowerCase() || "");
            }}
            placeholder={t(TRANSLATION_KEY.searchByName)}
            style={{ marginBottom: 12 }}
            allowClear
          />
          {OwnershipList}
        </Tabs.TabPane>
      </Tabs>

      {/* Asset preview modal */}
      <Modal
        visible={assetModalVisible}
        onCancel={() => setAssetModalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={true}
        destroyOnClose
      >
        <AssetModalPreview id={selectedAsset} />
      </Modal>
    </div>
  );
};

export default DrawerAsset;
