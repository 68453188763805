import React from "react";

import { Button, List, Popconfirm, Spin, Typography } from "antd";

import { CheckOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { t } from "i18next";
import PreviewButton from "../../../../components/PreviewButton";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { BaseListItem, ClientAssetType } from "../../../../models/asset";

interface IProps {
  item: BaseListItem;
  canViewAsset: boolean;
  canDeleteAsset: boolean;
  loading: string | number;
  clientAssetType: ClientAssetType;
  handleOpenModal?: (id: string | number | undefined) => void;
  handleOnRemove?: (asset_id: number) => void;
}

const AssetNode: React.FC<IProps> = ({
  item,
  handleOpenModal,
  handleOnRemove,
  canViewAsset,
  canDeleteAsset,
  clientAssetType,
  loading,
}) => {
  // Content
  const deleteBtn =
    handleOnRemove && canDeleteAsset ? (
      loading === +item.id ? (
        <Spin style={{ marginRight: 12 }} size="small" />
      ) : (
        <Popconfirm
          onConfirm={() => item.rent_order_id && handleOnRemove(item.rent_order_id)}
          title={
            clientAssetType === "rent"
              ? t(TRANSLATION_KEY.clientAssetRentDeleteMessage)
              : t(TRANSLATION_KEY.continueWithAction)
          }
          cancelText={t(TRANSLATION_KEY.no)}
          okText={t(TRANSLATION_KEY.yes)}
          placement="left"
          style={{ marginRight: 12 }}
        >
          {clientAssetType === "rent" ? null : ( // </Button> //   <CheckOutlined /> // <Button loading={loading === item.id} type="link" shape="circle">
            <Button
              style={{ padding: 0, margin: "-1px 0" }}
              loading={loading == item.id}
              danger
              type="link"
              shape="circle"
              size="small"
            >
              <DeleteOutlined />
            </Button>
          )}
        </Popconfirm>
      )
    ) : null;

  return (
    <List.Item
      style={{ padding: "8px 0" }}
      className={"listTreeItem listItemHoverColor"}
      extra={deleteBtn}
    >
      <div className="spaceBetweenRow" style={{ width: "100%", padding: "0 8px", borderRadius: 4 }}>
        <div style={{ paddingLeft: 0 }}>
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <Typography.Text type="secondary">#{item.custom_id || "-"}</Typography.Text>

            {handleOpenModal && canViewAsset ? (
              <PreviewButton
                isActive={item?.is_active}
                title={item.name}
                id={item.id}
                url={`/app/asset-details/${item.id}/`}
                onClick={() => handleOpenModal(item.id)}
                containerStyle={{ width: "none !important" }}
              />
            ) : (
              item.name
            )}
            {/* <Typography.Text type="secondary" style={{ color: "#333", fontWeight: 500 }}> */}
            {/* {t(item.category?.name || "")} */}
            {/* </Typography.Text> */}
            <div style={{ marginLeft: -32 }}>
              {handleOpenModal
                ? item.location && (
                    <Typography.Text type="secondary">({item.location.name})</Typography.Text>
                  )
                : null}
            </div>
          </div>
        </div>
      </div>
    </List.Item>
  );
};

export default AssetNode;
