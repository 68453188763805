import { Button, Form, Input, message, Spin } from "antd";
import { t, use } from "i18next";
import { useEffect, useRef, useState } from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch } from "../hooks";
import { IApiResponse } from "../models";
import { IPartCategory } from "../models/parts";
import api, { failedQueue, isRefreshing } from "../services";
import { warehouseSlice } from "../store/reducers/warehouse";

export interface IPartCategoryForm {
  parent: number | null;
  name: string;
  id: number;
}

interface IProps {
  category: IPartCategoryForm | undefined;
  close: () => void;
  categories: IPartCategory[];
  successCallback?: (id: number) => void;
}

const PartCategoryForm: React.FC<IProps> = ({ category, close, categories, successCallback }) => {
  const [form] = Form.useForm();
  const [loading, set_loading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const inputRef = useRef<Input>(null);

  const onFinish = async (values: IPartCategoryForm) => {
    let token = await localStorage.getItem("token");
    set_loading(true);

    let data = [...categories];

    try {
      if (category?.id === 0) {
        let response = await api.post<IApiResponse<IPartCategory[]>>(
          `/warehouse/categories/`,
          { ...values, parent: values.parent || null },
          { headers: { Authorization: "Bearer " + token } },
        );
        if (response.data.results) {
          if (successCallback) {
            successCallback(response.data.results[response.data.results.length - 1].id);
          }

          data = response.data.results;
        }
      } else {
        //update
        let response = await api.put<IApiResponse<IPartCategory[]>>(
          `/warehouse/categories/${category?.id}/`,
          { ...values, parent: values.parent || null },
          { headers: { Authorization: "Bearer " + token } },
        );
        if (response.data.results) {
          let index = data.findIndex((x) => x.id === category?.id);
          data = response.data.results;
        }
      }

      dispatch(warehouseSlice.actions.getPartsCategoriesSuccess({ results: data, message: "" }));
      close();
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onFinish(values));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_loading(false);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div>
      <Spin spinning={loading}>
        <Form layout="vertical" initialValues={category} onFinish={onFinish} form={form}>
          <Form.Item
            rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
            name="name"
            label={t(TRANSLATION_KEY.name)}
          >
            <Input ref={inputRef} />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} htmlType="submit" type="primary">
              {t(TRANSLATION_KEY.save)}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default PartCategoryForm;
