import { Col, Row, Typography } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";

const PartSnerpecification: React.FC = () => {
  return (
    <>
      <Row>
        <Col span={9}> fewfwef</Col>
        <Col span={15}>
          <Row>
            <Col span={12}>
              <Typography.Title level={5}>{t(TRANSLATION_KEY.userOnPartners)}</Typography.Title>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>{t(TRANSLATION_KEY.restUsers)}</Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PartSnerpecification;
