import React from "react";

// UI
import { Select, TreeSelect } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import css from "../styles.module.css";

// Redux
import { useAppSelector } from "../../../../hooks";

// Rest
import {
  getAssets,
  getCategories,
  getExecutors,
  getLocations,
  getTypes,
} from "../../../../helpers/functions";
import { createTreeSelect } from "../../../../helpers/functions";
import { IAsset } from "../../../../models/asset";
import { IMaintenanceCategory, IMaintenanceType } from "../../../../models/maintenances";
import { ILocation } from "../../../../models";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import AssetTreeSelect from "../../../../componentsform/AssetTreeSelect";

interface IFilters {
  assets: number[];
  types: number[];
  executors: number[];
  locations: number[];
  categories: number[];
}

interface IProps {
  filters: IFilters;
  executor_accounts: number[] | undefined;
  set_filters: React.Dispatch<React.SetStateAction<IFilters>>;
}

const Filters: React.FC<IProps> = ({ filters, executor_accounts, set_filters }) => {
  // Variables
  const { calendarMaintenances } = useAppSelector((state) => state.maintenanceReducer);

  // Methods
  const setFilters = (value: any, key: string) => {
    const _key = key as keyof typeof filters;
    const _filters: IFilters = { ...filters };
    _filters[_key] = value;
    set_filters(_filters);
  };

  // Data
  const types = getTypes(calendarMaintenances);
  const categories = getCategories(calendarMaintenances);
  const assets = getAssets(calendarMaintenances);
  const locations = getLocations(calendarMaintenances);
  const executors = getExecutors(calendarMaintenances);
  const tree = createTreeSelect(
    assets.map((item: IAsset) => ({
      title: item.name,
      value: item.id.toString(),
      parent_id: item.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  return (
    <div className={css.buttonsContainer} id="calendar-filters">
      <AssetTreeSelect
        includeWithoutAsset={true}
        onChange={(e) => {
          setFilters(e, "assets");
        }}
        treeProps={{
          allowClear: true,
          maxTagCount: "responsive",
          style: { width: 224 },
          value: filters.assets.map((item) => item.toString()),
          placeholder: t(TRANSLATION_KEY.selectAssets),
          treeCheckable: true,
          showCheckedStrategy: TreeSelect.SHOW_ALL,
          treeCheckStrictly: true,
          treeDefaultExpandAll: true,
          multiple: true,
        }}
      />
      <Select
        onChange={(e) => setFilters(e, "types")}
        placeholder={t(TRANSLATION_KEY.maintenanceTypes)}
        defaultValue={[]}
        style={{ overflow: "auto" }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        className={css.filterDropdown}
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {types.map((item: IMaintenanceType, index: number) => (
          <Select.Option key={index} value={item.id}>
            {t(item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) => setFilters(e, "categories")}
        placeholder={t(TRANSLATION_KEY.selectCategory)}
        defaultValue={[]}
        style={{ overflow: "auto" }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        className={css.filterDropdown}
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {categories.map((item: IMaintenanceCategory, index: number) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) => setFilters(e, "locations")}
        placeholder={t(TRANSLATION_KEY.selectLocation)}
        defaultValue={[]}
        style={{ overflow: "auto", height: 34 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        className={css.filterDropdown}
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {locations.map((item: ILocation, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>

      {!executor_accounts && (
        <Select
          style={{ overflow: "auto", height: 34 }}
          allowClear
          placeholder={t(TRANSLATION_KEY.selectExecutor)}
          mode="multiple"
          showSearch
          className={css.filterDropdown}
          optionFilterProp="name"
          maxTagCount="responsive"
          filterOption={(input, option: DefaultOptionType | any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(e) => setFilters(e, "executors")}
        >
          <Select.OptGroup label={t(TRANSLATION_KEY.employee)}>
            {executors
              .filter((item) => item.account)
              .map((item: any, index: number) => (
                <Select.Option key={index} value={item.account.id}>
                  {item.account.name}
                </Select.Option>
              ))}
          </Select.OptGroup>
          <Select.OptGroup label={t(TRANSLATION_KEY.supplier)}>
            {executors
              .filter((item) => item.supplier)
              .map((item: any, index: number) => (
                <Select.Option key={index} value={item.supplier.id}>
                  {item.supplier.name}
                </Select.Option>
              ))}
          </Select.OptGroup>
        </Select>
      )}
    </div>
  );
};

export default Filters;
