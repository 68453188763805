import { Button, Form, Input, message } from "antd";
import { t } from "i18next";
import React from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IExecutor } from "../models/maintenances";
import { ISupplier } from "../models/supplier";
import { sendSupplierEmailXHR } from "../store/reducers/supplier/actionCreator";

interface IProps {
  close: () => void;
  maintenance_id: number | undefined | string;
  supplier: IExecutor | undefined;
}
interface IForm {
  email: string;
  note: string | null;
}

const SupplierEmail: React.FC<IProps> = ({ close, supplier }) => {
  const { sendSupplierEmailStatus } = useAppSelector((state) => state.supplierReducer);

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const onFinish = (values: IForm) => {
    sendSupplierEmailXHR(
      {
        body: {
          email: values.email,
          note: values.note || null,
          executor: supplier?.id || 0,
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.sendInvitationFailed)),
        successCallback: () => {
          close();
          message.success(t(TRANSLATION_KEY.sendInvitationSuccessful));
          close();
        },
      },
      dispatch,
    );
  };

  return (
    <Form
      initialValues={{
        email: supplier?.supplier?.email || "",
      }}
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item name="email" label={t(TRANSLATION_KEY.email)}>
        <Input />
      </Form.Item>
      <Form.Item name="note" label={t(TRANSLATION_KEY.note)}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Button
          loading={sendSupplierEmailStatus === "loading"}
          disabled={sendSupplierEmailStatus === "loading"}
          htmlType="submit"
          type="primary"
        >
          {t(TRANSLATION_KEY.save)}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SupplierEmail;
