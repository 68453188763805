import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import moment from "moment";

// Antd
import { message, Modal, Typography } from "antd";

// Rest
import MaintenancesTable from "../../../components/MaintenancesTable";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";
import { getMaintenanceListXHR } from "../../../store/reducers/maintenance/actionCreator";
import CalendarMaintenances from "../../maintenances/Calendar";
import { ICursor } from "../../../models";
import { unstable_batchedUpdates } from "react-dom";
import { useParams } from "react-router-dom";
import { debounce, filter } from "../../../helpers/functions";
interface ILocalFilters {
  from: string;
  to: string;
  search: string;
}

const Maintenances: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();

  // Variables
  const [showCalendar, set_showCalendar] = useState<boolean>(false);
  const { id } = useParams();
  const { maintenanceList, getMaintenanceListStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const { account } = useAppSelector((state) => state.accountsReducer);
  const [localQueryParams, set_localQueryParams] = useState<ICursor>(maintenanceList.cursor);
  const [filters, set_filters] = useState<ILocalFilters>({
    search: "",
    from: moment().startOf("month").toISOString(true),
    to: moment().endOf("month").toISOString(true),
  });

  const getMaintenanceList = (queryParams: ICursor, filters: ILocalFilters, mergeData: boolean) => {
    if (queryParams.cursor === null && !!maintenanceList.data.length) {
      return;
    }

    getMaintenanceListXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          ...queryParams,
          start: filters.from.toString(),
          end: filters.to.toString(),
          search: filters.search,
          executor_account: id,
          status: "open|assigned|running|held|completed|requested|closed",
        },
        mergeData,
        successCallback: (data) => {
          if (data.results) {
            unstable_batchedUpdates(() => {
              set_filters(filters);
              set_localQueryParams(data.results?.cursor!);
            }, []);
          }
        },
      },
      dispatch,
    );
  };

  function handleOnDateChanged(start: string, end: string) {
    getMaintenanceList(
      maintenanceList.cursor,
      {
        ...filters,
        from: start,
        to: end,
      },
      false,
    );
  }

  function handleLoadMore() {
    getMaintenanceList(localQueryParams, filters, true);
  }

  const debounceOnSearch = (obj: ILocalFilters) => {
    getMaintenanceList(maintenanceList.cursor, obj, false); // if any filter paramaters change strat load more from start
  };

  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);

  function handleOnSearch(search: string) {
    let obj: ILocalFilters = { ...filters, search: search };
    setSearch(obj);
  }

  return (
    <div className="white-container">
      {/* Title */}
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 8, marginBottom: -2 }}>
          {t(TRANSLATION_KEY.maintenances)}
        </Typography.Title>
      </div>

      {/* Table */}
      <MaintenancesTable
        start={filters.from}
        end={filters.to}
        data={maintenanceList.data}
        cursor={localQueryParams}
        loading={getMaintenanceListStatus === "loading"}
        onDateChanged={handleOnDateChanged}
        onLoadMore={handleLoadMore}
        onSearch={handleOnSearch}
        showOpenCalendarButton={false}
        handleOpenCalendar={() => set_showCalendar(true)}
        working_data_show={true}
      />

      {/* Calendar */}
      <Modal
        title={account?.name}
        visible={showCalendar}
        onCancel={() => set_showCalendar(false)}
        footer={null}
        width="90vw"
        centered
        style={{ overflow: "hidden" }}
      >
        <CalendarMaintenances executor_accounts={[account?.id || -1]} fromModal />
      </Modal>
    </div>
  );
};

export default Maintenances;
