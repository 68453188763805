import { FileTextOutlined } from "@ant-design/icons";
import { Card, Drawer, Statistic } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import AddReportToOrder from "../../../componentsform/AddReportToOrder";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";

interface IProps {}

const OrdersWithoutReprots: React.FC = ({}: IProps) => {
  // Variables
  const [ordersWithoutReportsDrawerVisible, set_ordersWithoutReportsDrawerVisible] =
    useState<boolean>(false);
  const { orders_without_report } = useAppSelector((state) => state.dashboardReducer);

  return (
    <>
      {/* Card */}
      <Card
        className="dashboard-card"
        onClick={() => set_ordersWithoutReportsDrawerVisible(true)}
        style={{ width: "100%", cursor: "pointer" }}
      >
        <Statistic
          title={t(TRANSLATION_KEY.mainWithoutReports)}
          value={orders_without_report.length}
          prefix={<FileTextOutlined />}
        />
      </Card>

      {/* Add reports */}
      <Drawer
        width={700}
        visible={ordersWithoutReportsDrawerVisible}
        onClose={() => set_ordersWithoutReportsDrawerVisible(false)}
        title={t(TRANSLATION_KEY.addReports)}
        destroyOnClose={true}
      >
        <AddReportToOrder close={() => set_ordersWithoutReportsDrawerVisible(false)} />
      </Drawer>
    </>
  );
};

export default OrdersWithoutReprots;
