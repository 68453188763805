import { Row, Col } from "antd";

import Materials from "./Materials";
import SubAssets from "./SubAssets";

const Spec: React.FC = () => {
  return (
    <div id="asset-details-spec" style={{ paddingTop: 8 }}>
      <Row gutter={[24, 12]}>
        {/* Materials */}
        <Col md={24} xl={12}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <Materials />
          </div>
        </Col>

        {/* Sub assets */}
        <Col md={24} xl={12}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <SubAssets />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Spec;
