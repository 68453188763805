import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";

// Antd
import {
  Descriptions,
  Button,
  Spin,
  PageHeader,
  Typography,
  Space,
  Tooltip,
  Tag,
  Result,
} from "antd";

// Translation
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { ShopOutlined, StopOutlined } from "@ant-design/icons";
import { retrievePartXHR } from "../../../store/reducers/warehouse/actionCreator";
import { parseCosts, parseDecimalDots } from "../../../helpers/functions";
import { warehouseSlice } from "../../../store/reducers/warehouse";
import { IUser } from "../../../models/user";
import { RootState } from "../../../store";
import CustomFieldsSpecGenerator from "../../../components/CustomFieldsSpecGenerator";

interface IProps {
  id: string | number | undefined;
  user: IUser | null;
}

const PartModalPreview = ({ id, user }: IProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { part, retrievePartStatus } = useAppSelector((state) => state.warehouseReducer);

  // Methods
  useEffect(() => {
    retrievePartXHR({ id }, dispatch);
    return () => {
      dispatch(warehouseSlice.actions.resetPartStatus());
    };
  }, []);

  const validate = (value: string | null | undefined) => value || "-";
  const handleNavigate = () => navigate(`/app/item-details/${part?.id}`);

  // Values
  const total_current_qty = useMemo(() => {
    if (part?.part_storage) {
      return part.part_storage.reduce((acc, cur) => acc + cur.qty, 0);
    }
    return 0;
  }, [part]);

  if (retrievePartStatus === "error") {
    return <Result status="warning" title={t(TRANSLATION_KEY.dataNotFound)} />;
  }

  return (
    <Spin spinning={retrievePartStatus === "loading"}>
      <div style={{ width: "100%", padding: 12 }}>
        {/* Page header */}
        <PageHeader
          style={{ padding: 0, marginBottom: 20 }}
          className="custom-page-header"
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.part)} </Typography.Text>
              <Typography.Text strong>#{part.code}</Typography.Text>
            </div>
          }
          extra={[
            <Button type="primary" onClick={handleNavigate}>
              {t(TRANSLATION_KEY.seeMore)}
            </Button>,
          ]}
        >
          <Space direction="vertical" style={{ width: "100%", marginTop: -40 }}>
            <Space size="large" align="center">
              <Typography.Title level={3}>{part.name}</Typography.Title>
            </Space>
            <Descriptions size="small" column={2}>
              <Descriptions.Item label={t(TRANSLATION_KEY.qty)}>
                {`${parseDecimalDots(total_current_qty)} ${t(part.uom) || "-"}`}
              </Descriptions.Item>
              {part.critical_qty && (
                <Descriptions.Item label={t(TRANSLATION_KEY.criticalQty)}>
                  {`${parseDecimalDots(part.critical_qty)} ${t(part.uom) || "-"}`}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t(TRANSLATION_KEY.totalPrice)}>
                {`${parseCosts(part.total_price?.toString() || "0")} ${user?.account.company
                  .currency}`}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.avgPrice)}>
                {`${parseCosts(part.avg_price?.toString() || "")} ${user?.account.company
                  .currency}`}
              </Descriptions.Item>
              <Descriptions.Item span={4} label={t(TRANSLATION_KEY.description)}>
                {validate(part.description)}
              </Descriptions.Item>
              {CustomFieldsSpecGenerator({
                condition: part.category?.id,
                formType: "workorder",
                data: part.custom_fields_v2,
              })}
            </Descriptions>
          </Space>
        </PageHeader>
      </div>
    </Spin>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(PartModalPreview);
