import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Outlet } from "react-router-dom";

const RequestPanel: React.FC = ({}) => {
  return (
    <Layout>
      <Content style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default RequestPanel;
