import React, { useState } from "react";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppSelector } from "../../../../../hooks";
import { FormModal } from "../../../../../componentsform/CustomFieldsFormV2";
import { IMaintenanceType } from "../../../../../models/maintenances";
import CustomFieldsTable from "../Components/CustomFieldsTable";

interface IProps {
  onEdit: (values: FormModal) => void;
  maintenancesTypes: IMaintenanceType[];
}

const WorkOrder: React.FC<IProps> = ({ onEdit, maintenancesTypes }) => {
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  return (
    <div>
      <CustomFieldsTable
        formType="workorder"
        condition={maintenancesTypes}
        onEdit={onEdit}
        conditionTitle={t(TRANSLATION_KEY.maintenanceTypes)}
        customFieldsData={companyCustomFieldsV2.workorder}
      />
    </div>
  );
};
export default WorkOrder;
