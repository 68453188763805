import { useEffect, useState } from "react";

// Antd
import { Descriptions, Spin, Button, Tabs, PageHeader, Typography, Space, Tag, Select } from "antd";

// Translation
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { getMaintenanceXHR } from "../../../store/reducers/maintenance/actionCreator";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import { AppRoutes } from "../../../navigation/routes";
import UserReports from "../pages/UserReports";
import Executors from "../pages/MaintenanceSpec/Executors";
import Times from "../pages/Times";

interface IProps {
  id: string | number | undefined;
}

const MaintenanceModalPreview = ({ id }: IProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getMaintenanceStatus, maintenanceDetails } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const tabsToShow = ["", "userreports", "executors"];
  const [currentTab, set_currentTab] = useState<string>("time");
  const _key = currentTab as keyof typeof TABS;

  // Methods
  useEffect(() => {
    getMaintenanceXHR({ id, loading: "loading" }, dispatch);
  }, []);

  function handleNavigate() {
    navigate(
      `/app/maintenances/${maintenanceDetails?.id}/${currentTab === "time" ? "" : currentTab}`,
    );
  }

  function onTabChange(activeKey: string) {
    set_currentTab(activeKey);
  }

  const TABS = {
    time: <Times maintenanceDetails={maintenanceDetails} fromModal={true} />,
    userreports: (
      <UserReports isOpenOrder={false} maintenanceDetails={maintenanceDetails} fromModal={true} />
    ),
    executors: (
      <Executors isOpenOrder={false} maintenanceDetails={maintenanceDetails} fromModal={true} />
    ),
  };

  return (
    <Spin spinning={getMaintenanceStatus === "loading"}>
      <div
        style={{
          width: "100%",
          minHeight: 520,
          padding: 12,
          paddingTop: 0,
          maxHeight: 200,
          overflowY: "auto",
        }}
      >
        {/* Page header */}
        <PageHeader
          style={{ padding: 0, marginBottom: 20 }}
          className="custom-page-header"
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.maintenance)} </Typography.Text>
              <Typography.Text strong>#{maintenanceDetails.order_number}</Typography.Text>
            </div>
          }
          extra={[
            <Button type="primary" onClick={handleNavigate}>
              {t(TRANSLATION_KEY.seeMore)}
            </Button>,
          ]}
          footer={
            <Tabs
              id="bordered-tabs"
              activeKey={currentTab}
              onChange={onTabChange}
              style={{ marginBottom: 12 }}
            >
              {AppRoutes("allroutes")
                .children?.find((x) => x.path === "maintenances/:id")
                ?.children?.filter((x) => tabsToShow.includes(x.path))
                ?.map((x) => (
                  <Tabs.TabPane tab={<div>{t(x.path || "time")}</div>} key={x.path || "time"} />
                ))}
            </Tabs>
          }
        >
          <Space direction="vertical" style={{ width: "100%", marginTop: -40 }}>
            <Typography.Title level={3}>{maintenanceDetails.description}</Typography.Title>
            <Descriptions size="small" column={2}>
              <Descriptions.Item label={t(TRANSLATION_KEY.asset)}>
                {maintenanceDetails?.asset?.name || "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.status)}>
                {t(maintenanceDetails?.status || "") || "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.location)}>
                {maintenanceDetails.location?.name || "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.maintenanceTypes)}>
                {t(maintenanceDetails.maintenance_type?.name || "") || "-"}
              </Descriptions.Item>
              <Descriptions.Item className="select" label={t(TRANSLATION_KEY.category)}>
                <Select
                  disabled
                  tagRender={({ label }) => <Tag className="tag-chip">{label}</Tag>}
                  bordered={false}
                  placeholder="-"
                  key={`maintenance_categories:${
                    maintenanceDetails.maintenance_categories &&
                    maintenanceDetails.maintenance_categories?.map((item) => item.id)
                  }`}
                  mode="tags"
                  maxTagCount="responsive"
                  style={{ minWidth: "100%", cursor: "default !important" }}
                  value={maintenanceDetails.maintenance_categories?.map((item) => item.id) || []}
                >
                  {maintenanceDetails.maintenance_categories?.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item className="select" label={t(TRANSLATION_KEY.assetMarks)}>
                <Select
                  disabled
                  tagRender={({ label }) => <Tag className="tag-chip">{label}</Tag>}
                  bordered={false}
                  placeholder="-"
                  key={`subassets:${
                    maintenanceDetails.subassets &&
                    maintenanceDetails.subassets?.map((item) => item.id)
                  }`}
                  mode="tags"
                  maxTagCount="responsive"
                  style={{ minWidth: "100%", cursor: "default !important" }}
                  value={maintenanceDetails.subassets?.map((item) => item.id) || []}
                >
                  {maintenanceDetails.subassets?.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Descriptions.Item>
              {/* Custom fields */}
              {maintenanceDetails.custom_fields &&
                maintenanceDetails.custom_fields?.map((item, index) => (
                  <Descriptions.Item
                    key={index}
                    span={1}
                    className="ellipsis"
                    label={
                      <Typography.Text style={{ maxWidth: 160 }} ellipsis={{ tooltip: item.name }}>
                        {item.name}
                      </Typography.Text>
                    }
                  >
                    <Typography.Text style={{ maxWidth: 160 }} ellipsis={{ tooltip: item.value }}>
                      {item.value || "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                ))}
            </Descriptions>
          </Space>
        </PageHeader>

        {/* Content */}
        <div style={{ maxHeight: 452, overflowY: "auto" }}>{TABS[_key]}</div>
      </div>
    </Spin>
  );
};

export default MaintenanceModalPreview;
