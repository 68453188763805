import React from "react";

// Styles
import styles from "../../index.module.css";
import { Image } from "antd";

type LayoutType = "grid" | "list";

interface IProps {
  src: string;
  size: number;
  tiny?: boolean;
  layout?: LayoutType;
}

const ImageComponent: React.FC<IProps> = ({ src, tiny, size, layout = "grid" }) => {
  if (layout === "grid") {
    return (
      <div
        style={{ height: size, borderRadius: tiny ? 6 : "auto" }}
        className={styles.cardContainer}
      >
        <Image
          style={{ height: size, objectFit: "cover" }}
          className={styles.cardHoverStyle}
          src="error"
          fallback={src}
        />
      </div>
    );
  }

  return (
    <div
      style={{ height: size, width: size, borderRadius: 4, backgroundColor: "#cdcdcd" }}
      className={styles.cardContainer}
    >
      <Image
        style={{ height: size, objectFit: "cover" }}
        className={styles.cardHoverStyle}
        src="error"
        fallback={src}
      />
    </div>
  );
};

export default ImageComponent;
