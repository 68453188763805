import {
  Button,
  Drawer,
  List,
  Typography,
  message,
  Spin,
  Modal,
  Popconfirm,
  Tag,
  Divider,
} from "antd";
import { t } from "i18next";
import { useState } from "react";
import FilesComponent from "../../../../components/Files";
import AddCheckListToOrder from "../../../../componentsform/AddCheckListToOrder";

import TaskForm from "../../../../componentsform/TaskForm";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IMaintenanceDetails } from "../../../../models/maintenances";
import { ITask } from "../../../../models/settings";
import {
  addTaskResulXHR,
  setTaskRankXHR,
} from "../../../../store/reducers/maintenance/actionCreator";
import { RadioResult, SelectResult, StringResult } from "./Results";
import { groupChecklistFromTasks, hasPermission } from "../../../../helpers/functions";
import api, { failedQueue, isRefreshing } from "../../../../services";
import { IApiResponse } from "../../../../models";

import {
  CloseCircleOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { maintenanceSlice } from "../../../../store/reducers/maintenance";
import Zoom from "../../../../components/Zoom";

import ReactDragListView from "react-drag-listview";
import RequirePermission from "../../../../components/RequirePermission";
import { useParams } from "react-router-dom";
import { supplierSlice } from "../../../../store/reducers/supplier";
import {
  addTaskResultOnOpenOrderXHR,
  addTaskToOpenOrderXHR,
} from "../../../../store/reducers/supplier/actionCreator";
import TaskCard from "./TaskCard";

interface IProps {
  maintenance: IMaintenanceDetails;
  isOpenOrder;
}

const CheckList: React.FC<IProps> = ({ maintenance, isOpenOrder }) => {
  // Variables
  const dispatch = useAppDispatch();
  const [addSingleTaskVisible, set_addSingleTaskVisible] = useState(false);
  const [loading, set_loading] = useState<boolean>(false);
  const { addTaskResulStatus } = useAppSelector((state) => state.maintenanceReducer);
  const open_order_token = useParams().token;
  const { user } = useAppSelector((state) => state.userReducer);
  const [addChecklistVisible, set_addChecklistVisible] = useState<boolean>(false);

  const { setTaskRankStatus } = useAppSelector((state) => state.maintenanceReducer);

  // Deleting tasks
  const [tasksChecked, setTasksChecked] = useState<number[]>([]);
  const allTasks = maintenance.tasks.map((item) => item.id);

  function onTaskCheck(e: CheckboxChangeEvent) {
    let id: number = +e.target.value;
    let selected: boolean = e.target.checked;
    let arr = [...tasksChecked];
    if (selected) arr.push(id);
    else {
      let index = arr.findIndex((item) => item === id);
      arr.splice(index, 1);
    }
    setTasksChecked(arr);
  }

  function checkAllTasks(e: CheckboxChangeEvent) {
    setTasksChecked(e.target.checked ? allTasks : []);
  }

  const saveResult = async (result: string | number | string[], task_id: number) => {
    if (isOpenOrder) {
      let code = await localStorage.getItem(open_order_token || "");
      if (!code || !open_order_token) {
        return;
      }
      addTaskResultOnOpenOrderXHR(
        {
          id: task_id,
          body: { result, code, token: open_order_token }, // ovde task id sluzi za loader
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        },
        dispatch,
      );
      return;
    }

    addTaskResulXHR(
      {
        id: task_id,
        body: { result, task: task_id }, // ovde task id sluzi za loader
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
      },
      dispatch,
    );
  };

  const onDragEnd = async (fromIndex: number, toIndex: number) => {
    console.log(fromIndex, toIndex);
    if (toIndex < 0) return; // Ignores if outside designated area

    setTaskRankXHR(
      {
        id: maintenance.tasks[fromIndex].id,
        body: { rank: toIndex + 1 },
        successCallback: (data) => {
          if (data.results) {
            dispatch(
              maintenanceSlice.actions.getMaintenanceSuccess({
                message: "",
                results: { ...maintenance, tasks: data.results },
              }),
            );
          }
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
      },
      dispatch,
    );
  };

  const onDeleteTask = async (ids?: number[]) => {
    set_loading(true);
    let tasksToRemove: number[] = ids || tasksChecked;
    let token = await localStorage.getItem("token");

    //open order staff
    let code = await localStorage.getItem(open_order_token || "");

    try {
      let response = await api.delete<IApiResponse<string>>(
        `maintenance/${isOpenOrder ? "open_order_task" : "task"}/`,
        {
          headers: !isOpenOrder ? { Authorization: "Bearer " + token } : undefined,
          data: {
            tasks: tasksToRemove,
            token: open_order_token,
            code,
          },
        },
      );

      let currentMaintenance: IMaintenanceDetails = { ...maintenance };
      let tasks: ITask[] = [...currentMaintenance.tasks];
      tasks = tasks.filter((task) => !tasksToRemove.includes(task.id));

      const fakeRes: IApiResponse<IMaintenanceDetails> = {
        results: {
          ...currentMaintenance,
          tasks,
        },
        message: "",
      };

      if (isOpenOrder) {
        dispatch(supplierSlice.actions.getOpenOrderSuccess(fakeRes));
      } else {
        dispatch(maintenanceSlice.actions.getMaintenanceSuccess(fakeRes));
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDeleteTask(ids));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    setTasksChecked([]);
    set_loading(false);
  };

  const getResultBlock = (task: ITask) => {
    let noPermission =
      user?.account?.permissions?.filter((x) => ["work_wo", "manage_wo"].includes(x?.codename))
        .length === 0;

    console.log(noPermission, user?.account?.permissions, !isOpenOrder);
    switch (task.type) {
      case "flag":
        return (
          <RadioResult
            disabled={!isOpenOrder && noPermission}
            task_id={task?.id}
            value={typeof task.result === "string" ? task.result : ""}
            saveResult={saveResult}
          />
        );
      case "number":
      case "string":
        return (
          <StringResult
            disabled={!isOpenOrder && noPermission}
            type={task.type}
            task_id={task?.id}
            value={
              task.type === "number" || task.type === "string" ? task.result?.toString() || "" : ""
            }
            saveResult={saveResult}
          />
        );
      case "select":
        return (
          <SelectResult
            disabled={!isOpenOrder && noPermission}
            properties={task.properties || []}
            task_id={task.id}
            value={Array.isArray(task.result) ? task.result : []}
            saveResult={saveResult}
          />
        );
      default:
        return null;
    }
  };

  const removeChecklistFromTask = (id: number) => {
    let taskIds: number[] = maintenance.tasks
      .filter((x) => x.checklist?.id === id)
      .map((x) => x.id);
    onDeleteTask(taskIds);
  };

  // Sorting tasks by rank(ascending) - tasks with rank null come last
  const sortedTasks: ITask[] = [...maintenance.tasks].sort((a: ITask, b: ITask) => {
    if (a.rank === null) return 1;
    if (b.rank === null) return -1;
    if (a.rank === b.rank) return 0;
    return a < b ? -1 : 1;
  });

  return (
    <>
      <ReactDragListView
        nodeSelector={
          hasPermission(user.account.permissions, ["manage_wo"]) ? ".ant-list-item.draggble" : ""
        }
        onDragEnd={onDragEnd}
      >
        <List
          className="checklist-list"
          header={
            <>
              <div>
                <div className="spaceBetweenRow">
                  <Typography.Title level={5}>{t(TRANSLATION_KEY.tasks)}</Typography.Title>
                  <Spin
                    size="small"
                    spinning={setTaskRankStatus === "loading" || loading || !!addTaskResulStatus}
                  />
                  <RequirePermission
                    requiredPermissionCode={["manage_wo"]}
                    children={
                      <div className="spaceBetweenRow">
                        {!isOpenOrder && (
                          <Button
                            onClick={() => set_addChecklistVisible(true)}
                            disabled={false} //admin moze kad hoce sta hoce
                            shape="circle"
                            icon={<FileDoneOutlined />}
                          />
                        )}
                        <div style={{ width: 12 }} />
                        {!isOpenOrder && (
                          <Button
                            onClick={() => set_addSingleTaskVisible(true)}
                            disabled={false} //admin moze kad hoce sta hoce
                            icon={<PlusOutlined />}
                            type="primary"
                            shape="circle"
                          />
                        )}
                      </div>
                    }
                  />
                </div>
                {/* Divider */}
                {sortedTasks.length > 0 && !isOpenOrder && (
                  <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                )}

                {/* Subheader */}
                {!isOpenOrder && (
                  <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
                    <div style={{ width: "100%" }} className="spaceBetweenRow">
                      <RequirePermission
                        requiredPermissionCode={["manage_wo"]}
                        anywayIgnorePermission={isOpenOrder}
                        children={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 4,
                              height: sortedTasks.length > 0 ? 60 : 6,
                              width: 180,
                            }}
                          >
                            {sortedTasks.length > 0 && (
                              <Checkbox
                                checked={
                                  maintenance.tasks.length === tasksChecked.length &&
                                  tasksChecked.length > 0
                                }
                                indeterminate={
                                  tasksChecked.length !== 0 &&
                                  tasksChecked.length !== maintenance.tasks.length
                                }
                                onChange={checkAllTasks}
                              >
                                {t(TRANSLATION_KEY.selectAll) + " " + tasksChecked.length || null}
                              </Checkbox>
                            )}
                            <Zoom visible={tasksChecked.length !== 0}>
                              <Popconfirm
                                onConfirm={() => onDeleteTask()}
                                title={t(TRANSLATION_KEY.continueWithAction)}
                                cancelText={t(TRANSLATION_KEY.no)}
                                okText={t(TRANSLATION_KEY.yes)}
                                placement="top"
                              >
                                <Button
                                  danger
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                  style={{ marginTop: 0 }}
                                />
                              </Popconfirm>
                            </Zoom>
                          </div>
                        }
                      />
                      <RequirePermission
                        requiredPermissionCode={["manage_wo"]}
                        children={
                          <div className="spaceBetweenRow" style={{ flexWrap: "wrap" }}>
                            {groupChecklistFromTasks(maintenance.tasks).map((x) => (
                              // <Button
                              //   style={{ marginLeft: 6 }}
                              //   type="ghost"
                              //   danger
                              //   icon={<DeleteOutlined />}
                              //   onClick={() => removeChecklistFromTask(x.id)}
                              // >
                              //   {x.name}
                              // </Button>
                              <Tag
                                onClick={() => removeChecklistFromTask(x.id)}
                                icon={<CloseCircleOutlined />}
                                color="error"
                                style={{
                                  cursor: "pointer",
                                  padding: "3px 10px",
                                  borderRadius: 20,
                                }}
                              >
                                {x.name}
                              </Tag>
                            ))}
                          </div>
                        }
                      />
                    </div>
                  </div>
                )}
                {/* <RequirePermission
                    requiredPermissionCode="manage_wo"
                    children={
                     
                    }
                  /> */}
              </div>
            </>
          }
          dataSource={sortedTasks}
          bordered={false}
          renderItem={(item, index) => (
            <TaskCard
              onTaskCheck={onTaskCheck}
              account={user.account}
              getResultBlock={getResultBlock}
              index={index}
              tasksChecked={tasksChecked}
              isOpenOrder={isOpenOrder}
              task={item}
              loading={false}
            />
          )}
        />
      </ReactDragListView>

      <Modal
        title={t(TRANSLATION_KEY.checklist)}
        visible={addChecklistVisible}
        destroyOnClose
        onCancel={() => set_addChecklistVisible(false)}
        footer={null}
      >
        <AddCheckListToOrder
          maintenance={maintenance}
          close={() => set_addChecklistVisible(false)}
        />
      </Modal>
      <Drawer
        width={540}
        title={t(TRANSLATION_KEY.newTask)}
        visible={addSingleTaskVisible}
        onClose={() => set_addSingleTaskVisible(false)}
        destroyOnClose
        children={
          <TaskForm
            checklist={null}
            addingType={isOpenOrder ? "openOrder" : "order"}
            order_info={maintenance.id}
            onClose={() => set_addSingleTaskVisible(false)}
          />
        }
      />
      <div style={{ height: 33 }} />
    </>
  );
};

export default CheckList;
