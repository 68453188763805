import React, { useRef } from "react";
import { IClient } from "../models/clients";
import { Button, Form, Input, message, Space } from "antd";
import { TRANSLATION_KEY } from "../helpers/consts";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { createClientXHR, updateClientXHR } from "../store/reducers/clients/actionCreators";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";
import { ButtonSaveType } from "../models";

interface IProps {
  close: (id?: IClient) => void;
  client: IClient;
  showAddMore?: boolean;
}

export type CreateClient = Omit<IClient, "id">;

const ClientForm: React.FC<IProps> = ({ close, client, showAddMore }) => {
  const [form] = Form.useForm();
  const { createClientStatus, updateClientStatus } = useAppSelector(
    (state) => state.clientsReducer,
  );
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  const dispatch = useAppDispatch();
  const [loadingSave, set_loadingSave] = React.useState<ButtonSaveType>();
  const inputNameRef = useRef<Input>(null);
  const onFinish = (values: CreateClient) => {
    if (client.id !== 0) {
      updateClientXHR(
        {
          body: {
            ...values,
            custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.clients),
          },
          id: client.id,
          errorCallback: (err) => {
            if (err.response.data?.message?.custom_field) {
              message.error(
                t(err.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  err.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          successCallback: () => {
            close();
          },
        },
        dispatch,
      );
      // update
    } else {
      // create
      createClientXHR(
        {
          body: {
            ...values,
            custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.clients),
          },
          errorCallback: (e) => {
            if (e.response.data?.message?.custom_field) {
              message.error(
                t(e.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  e.response.data.message.custom_field,
                ),
              );
              return;
            }

            if (e?.response?.data?.message === "invalid_email") {
              message.warning(t(TRANSLATION_KEY.emailAlreadyExists));
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          successCallback: (res) => {
            if (loadingSave === "saveAndAdd") {
              form.resetFields();
              inputNameRef.current?.focus();
              set_loadingSave(undefined);
            } else {
              close(res.results);
            }
          },
        },
        dispatch,
      );
    }
  };
  return (
    <div>
      <Form
        form={form}
        initialValues={{
          custom_id: client.custom_id,
          name: client.name,
          email: client.email,
          address: client.address,
          phone_number: client.phone_number,
          description: client.description,
        }}
        layout="vertical"
        onFinish={onFinish}
      >
        {client.id !== 0 && (
          <Form.Item
            name="custom_id"
            label={t(TRANSLATION_KEY.code)}
            // rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label={t(TRANSLATION_KEY.name)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <Input ref={inputNameRef} />
        </Form.Item>
        <Form.Item
          name="email"
          label={t(TRANSLATION_KEY.email)}
          rules={[{ type: "email", message: t(TRANSLATION_KEY.enterCorrectEmail) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="address" label={t(TRANSLATION_KEY.address)}>
          <Input />
        </Form.Item>

        <Form.Item name="phone_number" label={t(TRANSLATION_KEY.phoneNumber)}>
          <Input />
        </Form.Item>

        <Form.Item name="description" label={t(TRANSLATION_KEY.description)}>
          <Input.TextArea rows={6} />
        </Form.Item>
        {companyCustomFieldsV2 && ( // uvijetovano selected rolom zato sto je obavezan podatak stoga tek onda mogu prikazati dinamicne
          <GenerateForCustomFieldsV2
            values={client?.custom_fields_v2}
            condition={undefined}
            customFields={companyCustomFieldsV2.clients}
            form={form}
          />
        )}
        <Form.Item>
          <Space>
            <Button
              onClick={() => {
                set_loadingSave("save");
              }}
              loading={
                (createClientStatus === "loading" || updateClientStatus === "loading") &&
                loadingSave === "save"
              }
              htmlType="submit"
              type="primary"
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
            {showAddMore && (
              <Button
                style={{
                  display: client.id === 0 ? "block" : "none",
                }}
                onClick={() => {
                  set_loadingSave("saveAndAdd");
                }}
                loading={
                  (createClientStatus === "loading" || updateClientStatus === "loading") &&
                  loadingSave === "saveAndAdd"
                }
                htmlType="submit"
                type="primary"
              >
                {t(TRANSLATION_KEY.saveAndAdd)}
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ClientForm;
