import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../models";
import { IAsset, ICategory } from "../../../models/asset";
import { IAccount, IUserAccount } from "../../../models/user";

// Define a type for the slice state
interface AccountState {
  accounts: IAccount[];
  account: IUserAccount | null;
  accountStatus: string;
  accountsStatus: string;
  addUserStatus: string;
  getAccountStatus: string;
  updateAccountStatus: string;
  getAccountAssetStatus: string;
  joinAssetToAccountStatus: string;
  accountAsset: Array<IAsset>;
  removeAssetFromAccountStatus: string;
  removeAllUserAssetsStatus: string;
  adminChangePasswordStatus: string;
}

// Define the initial state using that type
const initialState = {
  accountAsset: [],
  accounts: [],
  account: null,
  accountStatus: "",
  accountsStatus: "loading",
  addUserStatus: "",
  getAccountStatus: "loading",
  updateAccountStatus: "",
  getAccountAssetStatus: "",
  joinAssetToAccountStatus: "",
  removeAssetFromAccountStatus: "",
  removeAllUserAssetsStatus: "",
  adminChangePasswordStatus: "",
} as AccountState;

export const accountsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },

    accounts(state) {
      state.accountsStatus = "loading";
    },
    accountsSucceess(
      state,
      action: PayloadAction<IApiResponse<IAccount[]> & { mergeData: boolean }>,
    ) {
      if (action.payload.results) {
        if (action.payload.mergeData) {
          state.accounts = [...state.accounts, ...action.payload.results];
        } else {
          state.accounts = action.payload.results;
        }
      }
      state.accountsStatus = "";
    },
    accountsFail(state, action: PayloadAction<string>) {
      state.accountsStatus = action.payload;
    },

    // -----
    addUser(state) {
      state.addUserStatus = "loading";
    },
    addUserSucceess(state, action: PayloadAction<IApiResponse<IAccount>>) {
      if (action.payload.results) {
        const arr = [...state.accounts];
        arr.push(action.payload.results);

        state.accounts = arr;
      }
      state.addUserStatus = "";
    },
    addUserFail(state, action: PayloadAction<string>) {
      state.addUserStatus = action.payload;
    },

    // -----

    getAccount(state) {
      state.getAccountStatus = "loading";
    },
    getAccountSuccess(state, action: PayloadAction<IApiResponse<IUserAccount>>) {
      if (action.payload.results) {
        state.account = action.payload.results;
      }
      state.getAccountStatus = "";
    },
    getAccountFail(state, action: PayloadAction<string>) {
      state.getAccountStatus = "error";
    },
    resetAccountStatus(state) {
      state.getAccountStatus = "";
    },

    // -----

    updateAccount(state) {
      state.updateAccountStatus = "loading";
    },
    updateAccountSuccess(state, action: PayloadAction<IApiResponse<IUserAccount>>) {
      if (action.payload.results) {
        let arr = state.accounts;
        let index = arr.findIndex((x) => x.id === action.payload.results?.id);
        let role: Omit<ICategory, "static" | "trans_key"> = {
          id: action.payload.results.role.id,
          name: action.payload.results.role.name,
        };
        arr[index] = {
          id: action.payload.results.id,
          accepted_terms: action.payload.results.accepted_terms,
          is_active: action.payload.results.is_active,
          name: action.payload.results.name,
          phone_number: action.payload.results.phone_number,
          price_per_hour: action.payload.results.price_per_hour,
          email: action.payload.results.email,
          created_at: action.payload.results.created_at,
          avatar: action.payload.results.avatar,
          topic: action.payload.results.topic,
          date_format: action.payload.results.date_format,
          language: action.payload.results.language,
          custom_id: action.payload.results.custom_id,
          role,
          currency: action.payload.results.currency,
          custom_fields_v2: action.payload.results.custom_fields_v2,
        };
        state.accounts = arr;
        state.account = action.payload.results;
      }
      state.updateAccountStatus = "";
    },
    updateAccountFail(state, action: PayloadAction<string>) {
      state.updateAccountStatus = action.payload;
    },

    // -----

    getAccountAsset(state) {
      state.getAccountAssetStatus = "loading";
    },
    getAccountAssetSuccess(state, action: PayloadAction<IApiResponse<IAsset[]>>) {
      if (action.payload.results) {
        state.accountAsset = action.payload.results;
      }
      state.getAccountAssetStatus = "";
    },
    getAccountAssetFail(state, action: PayloadAction<string>) {
      state.getAccountAssetStatus = action.payload;
    },

    // -----

    joinAssetToAccount(state) {
      state.joinAssetToAccountStatus = "loading";
    },
    joinAssetToAccountSuccess(state, action: PayloadAction<IApiResponse<IAsset[]>>) {
      if (action.payload.results) {
        state.accountAsset = action.payload.results;
      }
      state.joinAssetToAccountStatus = "";
    },
    joinAssetToAccountFail(state, action: PayloadAction<string>) {
      state.joinAssetToAccountStatus = action.payload;
    },

    // -----

    removeAssetFromAccount(state, action: PayloadAction<number>) {
      state.removeAssetFromAccountStatus = action.payload.toString();
    },
    removeAssetFromAccountSuccess(state, action: PayloadAction<IApiResponse<IAsset[]>>) {
      if (action.payload.results) {
        state.accountAsset = action.payload.results;
      }
      state.removeAssetFromAccountStatus = "";
    },
    removeAssetFromAccountFail(state, action: PayloadAction<string>) {
      state.removeAssetFromAccountStatus = "";
    },

    // -----

    removeAllUserAssets(state) {
      state.removeAllUserAssetsStatus = "loading";
    },
    removeAllUserAssetsSuccess(state, action: PayloadAction<IApiResponse<IAsset[]>>) {
      if (action.payload.results) {
        state.accountAsset = []; // Reset account asset to empty array
      }
      state.removeAllUserAssetsStatus = "";
    },
    removeAllUserAssetsFail(state, action: PayloadAction<string>) {
      state.removeAllUserAssetsStatus = "";
    },

    // -----

    adminChangePassword(state) {
      state.adminChangePasswordStatus = "loading";
    },
    adminChangePasswordSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.adminChangePasswordStatus = "";
    },
    adminChangePasswordFail(state, action: PayloadAction<string>) {
      state.adminChangePasswordStatus = action.payload;
    },
  },
});

// Thunk function

export default accountsSlice.reducer;
