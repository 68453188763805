import { TOPICS } from "./helpers/functions";
import { IApiResponse, MqttMessage } from "./models";
import { IUser, IUserAccount, IUserNotification, MqttCreds } from "./models/user";
import { ICriticalQty } from "./models/parts";
import { store } from "./store";
import {
  getLiveMaintenancesXHR,
  getMaintenanceXHR,
} from "./store/reducers/maintenance/actionCreator";
import { userSlice } from "./store/reducers/user";
import { MqttClient } from "mqtt";
import { warehouseSlice } from "./store/reducers/warehouse";
import { IMaintenance } from "./models/maintenances";
import { dashboardSlice } from "./store/reducers/dashboard";
import { ILoggedTechnician, ITopCosts } from "./models/dashboard";
import { openNotification } from "./components/Notifications/NotificationToast";

const _mqtt = require("mqtt/dist/mqtt");

export let G_MQTT_CLIENT: MqttClient | null = null;

async function onMessageArrived(message: string, account: IUserAccount, topic) {
  console.log(TOPICS(account.topic, account.company.topic).notifications, "TOPIC");
  switch (topic) {
    case TOPICS(account.topic, account.company.topic).me:
      let data: IUserAccount = JSON.parse(message);
      let tmp = await localStorage.getItem("user");

      let storageUser: IApiResponse<IUser> = JSON.parse(tmp || "");
      if (storageUser) {
        if (storageUser.results) {
          storageUser.results.account = data;
        }
        store.dispatch(userSlice.actions.loginSucceess(storageUser));
      }
      break;

    case TOPICS(account.topic, account.company.topic).liveMaintenances:
      getLiveMaintenancesXHR({ loading: "mqttSignalLoading" }, store.dispatch);
      break;
    case TOPICS(account.topic, account.company.topic).liveMaintenance:
      if (+message === store.getState().maintenanceReducer.maintenanceDetails?.id) {
        getMaintenanceXHR({ id: message, loading: "mqttSignalLoading" }, store.dispatch);
      }
      break;
    case TOPICS(account.topic, account.company.topic).critical_qty_list:
      let tmp_criticalQty: ICriticalQty[] | null = JSON.parse(message);
      if (tmp_criticalQty) {
        store.dispatch(warehouseSlice.actions.set_criticalQty(tmp_criticalQty));
      }
      break;

    case TOPICS(account.topic, account.company.topic).notifications:
      console.log("NOTIFIKACIJE");

      // getNotificationsXHR({}, store.dispatch);
      let tmpNotifi: IUserNotification = JSON.parse(message);
      let tmpNotifications = [...store.getState().userReducer.userNotifications];
      let tmpUnreadedNotificationsCount = store.getState().userReducer.unreadedNotificationsCount;
      tmpUnreadedNotificationsCount++;
      tmpNotifications.unshift(tmpNotifi);
      store.dispatch(
        userSlice.actions.unreadedNotificationsCountSuccess({
          results: { count: tmpUnreadedNotificationsCount },
          message: "",
        }),
      );
      store.dispatch(
        userSlice.actions.getNotificationsSuccess({
          results: tmpNotifications,
          mergeData: false,
          message: "",
        }),
      );
      try {
        openNotification(tmpNotifi);
      } catch (error) {
        console.log(error, "--> error");
      }
      break;
    case TOPICS(account.topic, account.company.topic).orders_without_report:
      let tmp_orders_without_report: IMaintenance[] | null = JSON.parse(message);

      if (tmp_orders_without_report) {
        store.dispatch(dashboardSlice.actions.set_orders_without_report(tmp_orders_without_report));
      }
      break;
    case TOPICS(account.topic, account.company.topic).top_costs:
      let tmp_top_costs: ITopCosts[] | null = JSON.parse(message);
      if (tmp_top_costs) {
        store.dispatch(dashboardSlice.actions.set_top_costs(tmp_top_costs));
      }
      break;
    case TOPICS(account.topic, account.company.topic).logged_users:
      let tmp_loggedTechnicians: ILoggedTechnician[] | null = JSON.parse(message);
      if (tmp_loggedTechnicians) {
        store.dispatch(dashboardSlice.actions.set_loggedTechnicians(tmp_loggedTechnicians));
      }
      break;
    default:
      break;
  }
}
export const connectToMqtt = async (account: IUserAccount, mqttCreds: MqttCreds) => {
  try {
    const client: MqttClient = _mqtt.connect(process.env.REACT_APP_MQTT_URL, {
      reconnectPeriod: 3000,
      username: mqttCreds.mqtt_user,
      password: mqttCreds.mqtt_password,
    });
    client.on("connect", (paclet) => {
      console.log("SPOJENO");
      G_MQTT_CLIENT = client;
    });
    client.on("reconnect", () => {
      console.log("Reconnect");
    });
    client.on("error", (error: any) => {
      console.log("ER", error);
    });
    client.on("disconnect", (packet) => {
      console.log(packet);
    });
    client.on("message", (topic, message) => {
      console.log("message", topic);
      onMessageArrived(message.toString(), account, topic);
    });

    let _t = TOPICS(account.topic, account.company.topic);
    Object.keys(_t).forEach((x, i) => {
      client.subscribe(_t[x]);
    });
  } catch (e) {
    console.log(e);
  }
};
