import React, { useMemo } from "react";
import FilesComponent from "../../../../components/Files";
import { ITask } from "../../../../models/settings";
import { Checkbox, List, Spin, Tag } from "antd";
import {
  formatTaskDuration,
  hasPermission,
  parseTaskDuration,
} from "../../../../helpers/functions";
import { IUserAccount } from "../../../../models/user";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";
import DurationInput from "../DurationInput";

interface IProps {
  task: ITask;
  loading: boolean;
  isOpenOrder: boolean;
  tasksChecked: number[];
  account: IUserAccount;
  onTaskCheck: (e: CheckboxChangeEvent) => void;
  index: number;
  getResultBlock: (task: ITask) => any;
}

const TaskCard: React.FC<IProps> = ({
  task,
  loading,
  isOpenOrder,
  tasksChecked,
  account,
  onTaskCheck,
  index,
  getResultBlock,
}) => {
  const memedFiles = useMemo(
    () => (
      <FilesComponent
        onDelete={() => {}}
        files={task.files || []}
        hideButtons={true}
        size="tiny"
        layout="grid"
      />
    ),
    [task.files?.length],
  );

  return (
    <List.Item
      className="draggble"
      style={{
        border: "1px solid #80808038",
        borderWidth: 0,
        borderBottomWidth: 1,
        display: "flex",
        alignItems: "flex-start",
        cursor: "move",
      }}
    >
      <Spin wrapperClassName="stoposto" spinning={loading}>
        <div className="spaceBetweenRow">
          <List.Item.Meta
            title={
              <div
                className="spaceBetweenRow"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {!isOpenOrder &&
                    (hasPermission(account.permissions, ["manage_wo"]) || isOpenOrder) && (
                      <Checkbox
                        checked={tasksChecked.includes(task.id)}
                        value={task.id}
                        onChange={onTaskCheck}
                        style={{ marginRight: 12 }}
                      />
                    )}
                  <div>{`${index + 1}. ${task.description}`}</div>
                </div>
                <div className="spaceBetweenRow">
                  {task.planned_duration &&
                    parseTaskDuration(task.planned_duration) !== "00h:00m" && (
                      <Tag
                        color="blue"
                        style={{ padding: "3px 10px", borderRadius: 20, border: "none" }}
                      >
                        {parseTaskDuration(task.planned_duration)}
                      </Tag>
                    )}
                </div>
              </div>
            }
            description={
              <div>
                {/* Result block */}
                <div style={{ marginTop: 8 }}>{getResultBlock(task)}</div>

                {/* Materials */}
                <div style={{ marginTop: 12 }}>{!!task.files?.length ? memedFiles : null}</div>
              </div>
            }
          />
        </div>
      </Spin>
    </List.Item>
  );
};

export default TaskCard;
