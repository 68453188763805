import React from "react";
import { t } from "i18next";

// Antd
import { Descriptions, Typography } from "antd";

// Rest
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";
import CustomFieldsSpecGenerator from "../../../components/CustomFieldsSpecGenerator";

const SupplierSpec: React.FC = () => {
  // Hooks
  const { supplier } = useAppSelector((state) => state.supplierReducer);
  const { companyCustomFields } = useAppSelector((state) => state.settingsReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  return (
    <Descriptions column={3}>
      <Descriptions.Item label={t(TRANSLATION_KEY.email)}>{supplier?.email}</Descriptions.Item>
      <Descriptions.Item label={t(TRANSLATION_KEY.description)}>
        <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: supplier.description }}>
          {supplier?.description}
        </Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label={t(TRANSLATION_KEY.category)}>
        {supplier?.category?.name}
      </Descriptions.Item>
      <Descriptions.Item label={t(TRANSLATION_KEY.phoneNumber)}>
        {supplier?.phone_number}
      </Descriptions.Item>
      <Descriptions.Item label={t(TRANSLATION_KEY.address)}>{supplier?.address}</Descriptions.Item>
      {/* Custom fields */}

      {CustomFieldsSpecGenerator({
        condition: supplier.category?.id,
        formType: "suppliers",
        data: supplier.custom_fields_v2,
      })}
    </Descriptions>
  );
};

export default SupplierSpec;
