import React, { useEffect, useMemo, useState } from "react";
import api, { failedQueue, isRefreshing } from "../../../../services";
import { IApiResponse } from "../../../../models";
import { Col, Row, Typography, message } from "antd";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { t } from "i18next";
import Loader from "../../components/Loader";
import AnalyticsCard from "../../../../components/Cards/AnalyticsCard";
import { parseAnalyticsInfo, parseCosts } from "../../../../helpers/functions";
import Filters, { IFilters } from "./components/Filters";
import { useAppSelector } from "../../../../hooks";
import { Datum, Line, LineConfig } from "@ant-design/charts";

type Props = {};

const ItemQuantity = (props: Props) => {
  const currency = useAppSelector((state) => state.userReducer.user.account.company.currency);

  // Variables
  const [loading, set_loading] = useState(false);
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [reportData, set_reportData] = useState<IItemQuantity>({
    total_part_count: 0,
    total_part_price: 0,
    percentage: 0,
  });

  // Methods
  const getItemQuantity = async (filters: IFilters) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await api.get<IApiResponse<IItemQuantity>>(
        "/warehouse/analytics/part_count_and_price",
        {
          headers: { Authorization: "Bearer " + token },
          params: {
            date: filters.date,
            category: filters.category.join("|"),
            warehouse: filters.warehouse.join("|"),
          },
        },
      );
      if (res.data.results) {
        set_reportData(res.data.results);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getItemQuantity(filters));
        }
        return;
      }
      console.log(error);
      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_loading(false);
  };

  const getChartData = async (filters: IFilters) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await api.get<IApiResponse<IChartData[]>>("/warehouse/analytics/part_price_graph", {
        headers: { Authorization: "Bearer " + token },
        params: {
          date: filters.date,
          part_category: filters.category.join("|"),
          location: filters.warehouse.join("|"),
        },
      });
      if (res.data.results) {
        setChartData(res.data.results as IChartData[]);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getChartData(filters));
        }
        return;
      }
      console.log(error);
      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_loading(false);
  };

  // Chart config
  const lineConfig: LineConfig = {
    data: chartData.map((x) => ({
      ...x,
      day: `${t(x.day || TRANSLATION_KEY.other)}`,
    })),
    xField: "day",
    yField: "price",
    // seriesField: 'price',
    xAxis: {
      type: "category",
    },
    yAxis: {
      label: {
        formatter: (v: string) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: {
      formatter: (datum: Datum) => {
        return { name: t(TRANSLATION_KEY.price), value: `${parseCosts(datum.price)} ${currency}` };
      },
    },
    lineStyle: {
      lineWidth: 5,
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 2000,
      },
      update: {
        animation: "path-in",
        duration: 750,
      },
    },
  };

  let lineChart = useMemo(
    () => (
      <>
        {/* Title */}
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.partPriceTrend)}
        </Typography.Title>
        {/* Divider */}
        <div style={{ marginTop: 12, marginBottom: 12 }} />
        {/* Chart */}
        <div style={{ maxHeight: 572 }}>
          <Line {...lineConfig} />
        </div>
      </>
    ),
    [chartData, reportData],
  );

  return (
    <>
      {/* Spinner */}
      <Loader isLoading={loading} />

      {/* Filters */}
      <Filters onChange={(f) => (getItemQuantity(f), getChartData(f))} />

      {/* Cards */}
      <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }}>
        <Col xs={12} md={8}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.totalPartCount)}
            value={reportData.total_part_count}
          />
        </Col>
        <Col xs={12} md={8}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.totalPartPrice)}
            value={`${parseCosts(reportData?.total_part_price.toString()) || 0} ${currency}`}
          />
        </Col>
        <Col xs={12} md={8}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.percentage)}
            value={`${parseAnalyticsInfo(reportData?.percentage?.toString() || "0", 2, true)}%`}
          />
        </Col>
      </Row>

      {/* Chart */}
      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col span={24}>
          <div className="white-container" style={{ height: "100%", maxHeight: 480 }}>
            {lineChart}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ItemQuantity;

interface IItemQuantity {
  total_part_count: number;
  total_part_price: number;
  percentage: number;
}

interface IChartData {
  day: string;
  price: number;
}
