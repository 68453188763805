import React, { useState } from "react";

import { Button, List, Popconfirm, Spin, Typography } from "antd";

import { TreeItem } from "../../../models";
import { IAsset } from "../../../models/asset";
import { CloseOutlined } from "@ant-design/icons";
import PreviewButton from "../../../components/PreviewButton";
import { IUserAccount } from "../../../models/user";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";

interface IProps {
  item: TreeItem<IAsset>;
  deep: number;
  user?: IUserAccount;
  handleOpenModal?: (id: string | number | undefined) => void;
  onRemoveAssetFromAccount?: (asset_id: number) => void;
  viewAsset: boolean;
  canDeleteAsset: boolean;
  loading: string | number;
}

const Asset: React.FC<IProps> = ({
  item,
  deep,
  handleOpenModal,
  onRemoveAssetFromAccount,
  user,
  viewAsset,
  canDeleteAsset,
  loading,
}) => {
  const childs = item.children.length && (
    <List
      itemLayout="horizontal"
      dataSource={item.children}
      renderItem={(item: TreeItem<IAsset>) => (
        <Asset
          canDeleteAsset={canDeleteAsset}
          loading={loading}
          deep={deep + 1}
          item={item}
          onRemoveAssetFromAccount={onRemoveAssetFromAccount}
          handleOpenModal={handleOpenModal}
          user={user}
          viewAsset={viewAsset}
        />
      )}
    />
  );

  const deleteBtn =
    onRemoveAssetFromAccount && canDeleteAsset ? (
      loading === +item.id ? (
        <Spin style={{ marginRight: 12 }} size="small" />
      ) : (
        <Popconfirm
          onConfirm={() => onRemoveAssetFromAccount(item.id)}
          title={t(TRANSLATION_KEY.continueWithAction)}
          cancelText={t(TRANSLATION_KEY.no)}
          okText={t(TRANSLATION_KEY.yes)}
          placement="left"
          style={{ marginRight: 12 }}
        >
          <Button size="small" shape="circle">
            <CloseOutlined />
          </Button>
        </Popconfirm>
      )
    ) : null;

  return (
    <>
      <List.Item
        style={{ padding: "8px 0" }}
        className={"listTreeItem listItemHoverColor"}
        extra={deleteBtn}
      >
        <div
          className="spaceBetweenRow"
          style={{ width: "100%", padding: "0 8px", borderRadius: 4 }}
        >
          <div style={{ paddingLeft: 24 * deep + 8 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <Typography.Text type="secondary">#{item.custom_id || "-"}</Typography.Text>
              <Typography.Text type="secondary" style={{ color: "#333", fontWeight: 500 }}>
                {t(item.category?.name || "")}
              </Typography.Text>
              {handleOpenModal && viewAsset ? (
                <PreviewButton
                  isActive={item?.is_active}
                  title={item.name}
                  id={item.id}
                  url={`/app/asset-details/${item.id}/`}
                  onClick={() => handleOpenModal(item.id)}
                  containerStyle={{ width: "none !important" }}
                />
              ) : (
                item.name
              )}
              <div style={{ marginLeft: -32 }}>
                {handleOpenModal
                  ? item.location && (
                      <Typography.Text type="secondary">({item.location.name})</Typography.Text>
                    )
                  : null}
              </div>
            </div>
          </div>
        </div>
      </List.Item>

      {/* ---------------------  CHILDS --------------- */}

      {childs !== 0 ? childs : null}
    </>
  );
};

export default Asset;
