import { companySlice } from ".";
import { AppDispatch } from "../..";
import { IApiResponse, IDataToApi } from "../../../models";
import { IInvitation, IPartner, IPartnerDetails } from "../../../models/partner";
import { IDenyPartner, IPartnerRequest } from "../../../pages/partners/PartnersRequests";
import api, { failedQueue, isRefreshing } from "../../../services";

export const sendPartnerInvitationXHR = async (
  data: Partial<IDataToApi<{ company: number }, IApiResponse<IPartner>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(companySlice.actions.sendPartnerInvitation());
    let response = await api.post<IApiResponse<IPartner>>(
      `companies/send_invitation/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(companySlice.actions.sendPartnerInvitationSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => sendPartnerInvitationXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(companySlice.actions.sendPartnerInvitationFail(error.message));
  }
};

export const sendEmailXHR = async (
  data: Partial<IDataToApi<{ email: string }, IApiResponse<number>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(companySlice.actions.sendEmail());
    let response = await api.post<IApiResponse<number>>(
      `companies/send_email/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(companySlice.actions.sendEmailSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => sendEmailXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(companySlice.actions.sendEmailFail(error.message));
  }
};

export const getPartnersXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPartner[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(companySlice.actions.getPartners());
    let response = await api.get<IApiResponse<IPartner[]>>(`companies/partners/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(companySlice.actions.getPartnersSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartnersXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(companySlice.actions.getPartnersFail(error.message));
  }
};

export const getPartnersRequestsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPartnerRequest[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(companySlice.actions.getPartnersRequests());
    let response = await api.get<IApiResponse<IPartnerRequest[]>>(`companies/partner_requests/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(companySlice.actions.getPartnersRequestsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartnersRequestsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(companySlice.actions.getPartnersRequestsFail(error.message));
  }
};

export const getPartnerDetailsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPartnerDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(companySlice.actions.getPartnerDetails());
    let response = await api.get<IApiResponse<IPartnerDetails>>(`companies/partners/${data.id}/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(companySlice.actions.getPartnerDetailsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartnerDetailsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(companySlice.actions.getPartnerDetailsFail(error.message));
  }
};

export const confirmDenyPartnerXHR = async (
  data: Partial<IDataToApi<{ request_id: number; confirmed: boolean }, IApiResponse<IPartner>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(companySlice.actions.confirmDenyPartner());
    let response = await api.put<IApiResponse<IPartner>>(
      `companies/partnership_response/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(companySlice.actions.confirmDenyPartnerSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => confirmDenyPartnerXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(companySlice.actions.confirmDenyPartnerFail(error.message));
  }
};

export const removePartnerXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(companySlice.actions.removePartner(data.id?.toString() || ""));
    let response = await api.delete<IApiResponse<string>>(`companies/partners/${data.id}/`, {
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(companySlice.actions.removePartnerSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => removePartnerXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(companySlice.actions.removePartnerFail(error.message));
  }
};

export const getListOfSendedInvitationsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IInvitation[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(companySlice.actions.getListOfSendedInvitations());
    let response = await api.get<IApiResponse<IInvitation[]>>(`companies/sent_emails/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(companySlice.actions.getListOfSendedInvitationsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getListOfSendedInvitationsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(companySlice.actions.getListOfSendedInvitationsFail(error.message));
  }
};
