import { CheckOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, InputNumber, message, Select, Spin, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { INPUT_NUMBER_FORMATTER, TRANSLATION_KEY } from "../../../../helpers/consts";
import { TaskTypes } from "../../../../models/settings";

interface IPropsResult {
  saveResult?: (result: string | number | string[], task_id: number) => void;
  value: string;
  task_id: number;
  disabled: boolean;
  type?: TaskTypes;
}

export const StringResult: React.FC<IPropsResult> = ({
  value,
  saveResult,
  task_id,
  disabled,
  type,
}) => {
  const [res, set_res] = useState<string>(value);

  useEffect(() => {
    set_res(value);
  }, [value]);

  return (
    <Form
      onFinish={() => {
        if (saveResult) saveResult(res, task_id);
      }}
    >
      {type === "number" ? (
        <InputNumber
          {...INPUT_NUMBER_FORMATTER}
          disabled={disabled}
          bordered={false}
          onBlur={() => {
            if (res.toString() !== value?.toString() && saveResult) saveResult(res, task_id);
          }}
          style={{ width: "100%" }}
          placeholder={`${t(TRANSLATION_KEY.addNumberValue)}...`}
          onChange={(value) => {
            let c = +value;
            if (isNaN(c)) {
              message.warn(t(TRANSLATION_KEY.wrongInput));
              return;
            }
            if (saveResult) {
              saveResult(value, task_id);
            }
          }}
          value={res}
        />
      ) : (
        <TextArea
          autoSize
          style={{ width: "100%" }}
          placeholder={`${t(TRANSLATION_KEY.addStringValue)}...`}
          disabled={disabled}
          bordered={false}
          onBlur={() => {
            if (res !== value && saveResult) saveResult(res, task_id);
          }}
          onChange={({ target: { value: v } }) => {
            set_res(v);
          }}
          value={res}
          {...INPUT_NUMBER_FORMATTER}
        />
      )}
    </Form>
  );
};

export const RadioResult: React.FC<IPropsResult> = ({ value, saveResult, task_id, disabled }) => {
  const [res, set_res] = useState<string>(value);

  useEffect(() => {
    set_res(value);
  }, [value]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      {/* <div style={{ paddingLeft: 14, opacity: .5, marginRight: 16 }}>
        { res === 'pass' ? t(TRANSLATION_KEY.yes) : t(TRANSLATION_KEY.no) }
      </div> */}
      <Checkbox
        disabled={disabled}
        onChange={() =>
          saveResult ? saveResult(res === "pass" ? "fail" : "pass", task_id) : undefined
        }
        checked={res === "pass"}
      />
    </div>
  );
};

export const SelectResult: React.FC<
  Omit<IPropsResult, "value"> & { value: string[]; properties: string[] }
> = ({ value, saveResult, task_id, properties, disabled }) => {
  const [res, set_res] = useState<string[]>(value);

  useEffect(() => {
    set_res(value);
  }, [value]);
  return (
    <Select
      value={res}
      disabled={disabled}
      onChange={(v: string[]) => {
        if (saveResult) {
          saveResult(v, task_id);
        }
      }}
      mode="multiple"
      placeholder={`${t(TRANSLATION_KEY.addSelectableValue)}...`}
      bordered={false}
      style={{ width: "100%" }}
    >
      {properties.map((x) => (
        <Select.Option value={x} key={x}>
          {x}
        </Select.Option>
      ))}
    </Select>
  );
};
