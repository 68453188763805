import { UploadOutlined } from "@ant-design/icons";
import { Button, Spin, Upload, message, Typography, Empty, Divider } from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FilesComponent, { IFile, MediaType } from "../../components/Files";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IMAGE_EXTENSIONS, MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../../helpers/consts";
import { compressImage } from "../../helpers/functions";
import { IApiResponse } from "../../models";
import api, { failedQueue, isRefreshing } from "../../services";
import { clientsSlice } from "../../store/reducers/clients";
import { IClientDetails } from "../../models/clients";

// Search data
export type Data = IFile & { search: string };
const mapData = (materials: IFile[]): Data[] => {
  return materials.map((x) => ({
    ...x,
    search: x.name.toLowerCase(),
  }));
};

const Materials: React.FC = () => {
  // Variables
  const { id } = useParams();
  const [search, set_search] = useState<string>("");
  const [filters, set_filters] = useState<MediaType[]>([]);
  const [loading, set_loading] = useState<boolean>(false);
  const [data, set_data] = useState<Data[]>([]);
  const [filesFD, set_filesFD] = useState<FormData>();
  // Fetch data
  const { client } = useAppSelector((state) => state.clientsReducer);

  const dispatch = useAppDispatch();

  const beforeUpload = async (file: RcFile, files: RcFile[]) => {
    let fd = new FormData();

    for (let index = 0; index < files.length; index++) {
      try {
        const extension = files[index].type.split("/")[1];
        if (IMAGE_EXTENSIONS.includes(extension)) {
          const f = await compressImage(files[index]);
          fd.append("files", f);
        } else {
          fd.append("files", files[index]);
        }
      } catch (error: any) {
        console.log(error);
      }
    }

    set_filesFD(fd);

    return false;
  };

  useEffect(() => {
    if (filesFD) {
      onUpload();
    }
  }, [filesFD]);

  const onUpload = async () => {
    set_loading(true);
    const token = await localStorage.getItem("token");
    try {
      const res = await api.post<IApiResponse<IClientDetails>>(
        `clients/clients/${id}/add_materials/`,
        filesFD,
        { headers: { Authorization: "Bearer " + token } },
      );

      if (res.data.results) {
        dispatch(
          clientsSlice.actions.getClientSuccess({
            message: "",
            results: res.data.results,
          }),
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onUpload());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    // Request
    set_filesFD(undefined);
    set_loading(false);
    return false;
  };

  const onDeleteFile = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");

    let resp = api.delete<IApiResponse<string>>(`clients/clients/${id}/delete_material/`, {
      headers: { Authorization: "Bearer " + token },
    });
    let index = client.materials.findIndex((x) => x.id === id);
    let tmp = [...client.materials];
    tmp.splice(index, 1);

    dispatch(
      clientsSlice.actions.getClientSuccess({
        message: "",
        results: {
          ...client,
          materials: tmp,
        },
      }),
    );
    // Request

    set_loading(false);
  };

  // Checkboxes
  function onChange(checkedValues: any) {
    set_filters(checkedValues);
  }

  const options = [
    ...(data.some((item) => item.extension === "mp4")
      ? [{ label: t(TRANSLATION_KEY.video), value: "video" }]
      : []),
    ...(data.some((item) => item.extension === "pdf")
      ? [{ label: t(TRANSLATION_KEY.document), value: "document" }]
      : []),
    ...(data.some(
      (item) => item.extension === "jpg" || item.extension === "jpeg" || item.extension === "png",
    )
      ? [{ label: t(TRANSLATION_KEY.image), value: "image" }]
      : []),
  ];

  // Filters and search
  let filtredData: IFile[] = client.materials;
  if (search) filtredData = client.materials?.filter((x) => x.name.includes(search));
  // if (filters.length) filtredData = filterFilesByExtension(filtredData, filters)

  return (
    <div className="white-container">
      <div className="spaceBetweenRow">
        <div style={{ paddingTop: 8 }}>
          <Typography.Title level={5} style={{ marginBottom: -2 }}>
            {t(TRANSLATION_KEY.materials)}
          </Typography.Title>
        </div>

        <div>
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            accept={MATERIAL_EXTENSIONS}
            multiple
          >
            <Button loading={loading} icon={<UploadOutlined />} shape="circle" type="primary" />
          </Upload>
        </div>
      </div>

      <Divider />

      <Spin spinning={loading}>
        {client.materials.length > 0 ? (
          <FilesComponent
            size="small"
            files={client.materials}
            hideEditButton
            onEdit={() => console.log("On edit")}
            onDelete={onDeleteFile}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </div>
  );
};

export default Materials;
