import React, { useEffect, useState } from "react";

// Antd
import { message, Spin } from "antd";

// Rest
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { getPartSpendListXHR } from "../../../store/reducers/warehouse/actionCreator";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import { ICursor } from "../../../models";
import { warehouseSlice } from "../../../store/reducers/warehouse";
import moment, { Moment } from "moment";
import SpendHistoryTable from "../components/SpendHistoryTable";
import { debounce } from "../../../helpers/functions";
import { useParams } from "react-router-dom";
import EventBus from "../../../EventBus";

export interface ILocalFilters {
  start: string;
  end: string;
  search: string;
  warehouse: string[];
}

export const spendHistoryRefresh = "spendHistoryRefresh";

const SpendHistory: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { id } = useParams();

  // Variables
  const { part, partSpendList, getPartSpendListStatus } = useAppSelector(
    (state) => state.warehouseReducer,
  );
  const [localFilters, set_localFilters] = useState<ILocalFilters>({
    start: moment().subtract(30, "day").toISOString(true),
    end: moment().add(1, "day").toISOString(true),
    search: "",
    warehouse: [],
  });

  useEffect(() => {
    EventBus.on(spendHistoryRefresh, () => {
      getPartSpendList(partSpendList.cursor, localFilters, false);
    });
    return () => {
      EventBus.off(spendHistoryRefresh);
    };
  }, [localFilters]);

  const [localQueryParams, set_localQueryParams] = useState<ICursor>(partSpendList.cursor);

  function getPartSpendList(queryParams: ICursor, filters: ILocalFilters, mergeData: boolean) {
    if (localQueryParams.cursor === null && mergeData) {
      return;
    }
    getPartSpendListXHR(
      {
        id,
        mergeData,
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data) => {
          if (data.results) {
            set_localQueryParams(data.results.cursor);
            set_localFilters(filters);
          }
        },
        queryParams: {
          ...queryParams,
          start: filters.start,
          end: filters.end,
          search: filters.search,
          warehouse: filters.warehouse.join("|"),
        },
      },
      dispatch,
    );
  }

  const debounceOnSearch = (obj: ILocalFilters) => {
    getPartSpendList(partSpendList.cursor, obj, false); // if any filter paramaters change strat load more from start
  };

  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);

  return (
    <SpendHistoryTable
      getPartSpendList={(filters: ILocalFilters) => {
        getPartSpendList(localQueryParams, filters, false);
      }}
      setFilters={set_localFilters}
      filters={localFilters}
      data={partSpendList.data}
      cursor={localQueryParams}
      loading={getPartSpendListStatus === "loading"}
      onCalendarChange={(start: string, end: string) => {
        if (start) {
          getPartSpendList(
            partSpendList.cursor,
            {
              ...localFilters,
              start,
              end,
            },
            false,
          );
        }
      }}
      onLoadMore={() => {
        getPartSpendList(localQueryParams, localFilters, true);
      }}
      onSearch={(search) => {
        let tmp: ILocalFilters = { ...localFilters, search };
        setSearch(tmp);
      }}
    />
  );
};

export default SpendHistory;
