// UI
import styles from "../styles.module.css";
import { Divider, Typography } from "antd";

type IProps = {};

const OpenOrderHeader = (props: IProps) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.flexCenter} style={{ maxWidth: 960, width: 960 }}>
        {/* Logo */}
        <img
          style={{ width: 48, height: 48, marginBottom: 12 }}
          src={require("../../../assets/icons/auth_logo.png")}
        />

        {/* Name */}
        <Typography.Title
          level={2}
          style={{ height: 20, color: "#434343", fontWeight: 500, textAlign: "center" }}
        >
          Metrikon
        </Typography.Title>
      </div>
    </div>
  );
};

export default OpenOrderHeader;
