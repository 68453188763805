import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Form, Input, message, Typography, Row, Col, Modal } from "antd";
import {
  loginXHR,
  resetPasswordEmailXHR,
  setMqttClient,
} from "../../store/reducers/user/actionCreators";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import i18next from "i18next";
import { userSlice } from "../../store/reducers/user";
import { getCurrenciesXHR } from "../../store/reducers/settings/actionCreator";

export interface IResetPassword {
  email: string;
}

const Login: React.FC = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Variables
  const [isModalVisible, set_isModalVisible] = useState<boolean>(false);
  const [resetPasswordMail, set_resetPasswordMail] = useState<string>("");
  const { loginStatus } = useAppSelector((state) => state.userReducer);

  // Methods
  const onFinish = (values: { email: string; password: string }) => {
    loginXHR(
      {
        body: values,
        successCallback: (data) => {
          if (data.results) {
            //body zato sto genercki tip podatka za moje actioncreatore tako zahtjeva...
            setMqttClient({ body: data.results.account }, dispatch);
            dispatch(userSlice.actions.setLoadingLanguage(true));
            i18next
              .changeLanguage(
                data.results.account.language === "bs" ? "ba" : data.results.account.language,
              )
              .then(() => {
                dispatch(userSlice.actions.setLoadingLanguage(false));
              });
            getCurrenciesXHR({}, dispatch);
          }
        },
        errorCallback: (error) => {
          if (error && error.response?.status !== 400) {
            message.error(t(TRANSLATION_KEY.credentialsNotCorrect));
            return;
          }
          message.warning(t(TRANSLATION_KEY.credentialsNotCorrect));
        },
      },
      dispatch,
    );
  };

  async function handleOk() {
    resetPasswordEmailXHR(
      {
        body: { email: resetPasswordMail },
        errorCallback: (error: any) => message.warning(t(TRANSLATION_KEY.loginError)),
        successCallback: () => {
          message.success(t(TRANSLATION_KEY.recoveryEmailHasBeenSuccessfullySent));
          set_resetPasswordMail("");
          set_isModalVisible(false);
        },
      },
      dispatch,
    );
  }

  function handleCancel() {
    set_isModalVisible(false);
    set_resetPasswordMail("");
  }

  function showModal() {
    set_isModalVisible(true);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        minWidth: "100vw",
        height: "100%",
        minHeight: "calc(100vh - 64px)",
        backgroundColor: "#fff",
      }}
    >
      <Row style={{ width: "100%", height: "100%" }}>
        <Col xs={0} lg={0}>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#001529",
            }}
          />
        </Col>
        <Col
          xs={24}
          lg={24}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "calc(100vw - 40px)" : 450,
              width: isMobile ? "calc(100vw - 40px)" : 450,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: 64, height: 64, marginBottom: 12, marginTop: -32 }}
              src={require("../../assets/icons/auth_logo.png")}
            />
            <Typography.Title
              level={1}
              style={{ height: 20, color: "#434343", fontWeight: 500, textAlign: "center" }}
            >
              {t(TRANSLATION_KEY.login)}
            </Typography.Title>

            <Typography.Title
              level={5}
              style={{
                paddingBottom: 24,
                color: "#434343aa",
                fontWeight: 300,
                textAlign: "center",
              }}
            >
              {t(TRANSLATION_KEY.enterLoginInfo)}
            </Typography.Title>
            <Form layout="vertical" onFinish={onFinish} autoComplete="off">
              <Form.Item
                label={t(TRANSLATION_KEY.email)}
                name="email"
                rules={[
                  { required: true, message: t(TRANSLATION_KEY.filedRequired) },
                  { type: "email", message: t(TRANSLATION_KEY.enterCorrectEmail) },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              <div style={{ position: "relative" }}>
                <Form.Item
                  label={t(TRANSLATION_KEY.password)}
                  name="password"
                  rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
                >
                  <Input.Password size="large" />
                </Form.Item>

                {/* Forgot password */}
                <Typography.Link
                  onClick={showModal}
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  {t(TRANSLATION_KEY.forgotPassword)}?
                </Typography.Link>
              </div>

              {/* Divider */}
              <Divider />

              <Form.Item style={{ position: "relative" }}>
                <Button
                  loading={loginStatus === "loading"}
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                >
                  {t(TRANSLATION_KEY.login)}
                </Button>
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  width: isMobile ? "calc(100vw - 40px)" : 480,
                }}
              >
                <Typography.Text style={{ marginRight: 4 }}>
                  {t(TRANSLATION_KEY.notAMember)}?
                </Typography.Text>
                <Link to="/registration">
                  <Typography.Link>{t(TRANSLATION_KEY.signUp)}</Typography.Link>
                </Link>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      {/* Forgot password modal */}
      <Modal
        title={t(TRANSLATION_KEY.enterEmailToResetYourPassword)}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t(TRANSLATION_KEY.ok)}
        cancelText={t(TRANSLATION_KEY.cancel)}
        width={640}
      >
        <Input
          size="large"
          value={resetPasswordMail}
          onChange={(e) => set_resetPasswordMail(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleOk()} // Submitting on enter
        />
      </Modal>
    </div>
  );
};

export default Login;
