import { Button, Drawer, message, PageHeader, Space, Spin, Tabs } from "antd";
import { t, use } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import RequirePermission from "../../components/RequirePermission";
import AssetForm from "../../componentsform/AssetForm";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { hasPermission } from "../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAssetCategoriesXHR, getAssetXHR } from "../../store/reducers/asstes/actionCreators";
import AssetTreePage from "./tree";

const ACTIVE_TAB_KEY = "activeTabAssets";

const Asset: React.FC = () => {
  const dispatch = useAppDispatch();
  const [addAssetVisible, set_addAssetVisible] = useState<boolean>(false);
  const [activeTabKey, set_activeTabKey] = useState<string>("table");
  const navigate = useNavigate();
  const { getAssetStatus, assetList } = useAppSelector((state) => state.assetReducer);
  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 500);
  }, []);

  const getAsset = () => {
    getAssetXHR(
      {
        errorCallback: (data: any) => message.error(t("errorOnFetchData")),
      },
      dispatch,
    );
  };

  const getCategories = () => {
    getAssetCategoriesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  };
  useEffect(() => {
    getCategories();
    getAsset();
  }, []);

  return (
    <div>
      <PageHeader
        style={{ padding: 0, paddingTop: 10, marginBottom: 20 }}
        title={t(TRANSLATION_KEY.asset)}
        extra={[
          <RequirePermission
            requiredPermissionCode={["manage_asset"]}
            children={
              <Button onClick={() => set_addAssetVisible(true)} type="primary">
                {t(TRANSLATION_KEY.add)}
              </Button>
            }
          />,
        ]}
      />
      <div>
        {render ? (
          <AssetTreePage />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin style={{ paddingTop: 169 }} />
            </div>
          </div>
        )}
      </div>

      <Drawer
        title={t(TRANSLATION_KEY.addAsset)}
        width={540}
        destroyOnClose={true}
        children={
          <AssetForm
            addingFromOrder={false}
            close={() => set_addAssetVisible(false)}
            asset={undefined}
          />
        }
        visible={addAssetVisible}
        onClose={() => set_addAssetVisible(false)}
      />
    </div>
  );
};

export default Asset;
