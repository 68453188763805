import { LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AccountForm from "../../componentsform/AccountForm";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAccountXHR } from "../../store/reducers/accounts/actionCreators";
import Maintenances from "./pages/Maintenances";
import Spec from "./pages/Spec";
import Asset from "./pages/Asset";
import api, { failedQueue, isRefreshing } from "../../services";
import AdminChangePassword from "./components/AdminChangePassword";
import RequirePermission from "../../components/RequirePermission";

const Account: React.FC = () => {
  // Hooks
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Variables
  const [asset, set_asset] = useState(false);
  const [editVisible, set_editVisible] = useState<boolean>(false);
  const [delLoading, set_delLoading] = useState<boolean>(false);
  const [changePasswordVisible, set_changePasswordVisible] = useState<boolean>(false);
  const { getAccountStatus, account } = useAppSelector((state) => state.accountsReducer);

  // Methods
  const deleteUser = async () => {
    set_delLoading(true);
    try {
      let token = localStorage.getItem("token");
      let url = `/accounts/accounts/${id}`;
      let res = await api.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      navigate("/app/humanresources");
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => deleteUser());
        }
        return;
      }
      set_delLoading(false);
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
  };

  useEffect(() => {
    getAccountXHR(
      {
        id,
        errorCallback: (error: any) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          if (error?.response?.status === 404) {
            navigate("/app/not-found");
          }
        },
      },
      dispatch,
    );
  }, [id]);

  // manage_user

  return (
    <div>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10 }}
        className="custom-page-header"
        onBack={() => navigate(-1)}
        subTitle={
          <div>
            <Typography.Text type="secondary">{t(TRANSLATION_KEY.user)} </Typography.Text>
            <Typography.Text strong>#{account?.custom_id}</Typography.Text>
          </div>
        }
        extra={[
          <RequirePermission
            requiredPermissionCode={["manage_user"]}
            children={
              <Button key="1" onClick={() => set_changePasswordVisible(true)}>
                {t(TRANSLATION_KEY.changePassword)}
              </Button>
            }
          />,
          <Button onClick={() => set_asset(true)}>{t(TRANSLATION_KEY.asset)}</Button>,
          <Popconfirm
            onCancel={() => {}}
            onConfirm={() => {
              if (account?.company.admin_user_id === account?.id) {
                message.warning(t(TRANSLATION_KEY.cantDelUserBecauseHeIsMasterAdmin), 6);
                return;
              }
              deleteUser();
            }}
            title={t(TRANSLATION_KEY.continueWithAction)}
            cancelText={t(TRANSLATION_KEY.no)}
            okText={t(TRANSLATION_KEY.yes)}
          >
            <Button loading={delLoading} type="primary" danger>
              {t(TRANSLATION_KEY.delete)}
            </Button>
          </Popconfirm>,
          <Button onClick={() => set_editVisible(true)} type="primary">
            {t(TRANSLATION_KEY.edit)}
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <Space size="large" align="center">
            <Typography.Title level={3}>{account?.name}</Typography.Title>
            <Tag className="main-tag">{t(account?.role.name || "")}</Tag>
          </Space>
          {/* Specifications */}
          <Spec />
        </Space>
      </PageHeader>

      {/* Divider */}
      <Divider />

      {/* Maintenances */}
      <Spin spinning={getAccountStatus === "loading"}>
        <Maintenances />
      </Spin>

      {/* Set asset drawer */}
      <Drawer
        width={720}
        visible={asset}
        onClose={() => set_asset(false)}
        destroyOnClose={true}
        title={t(TRANSLATION_KEY.userAsset)}
        forceRender={true}
      >
        <Asset />
      </Drawer>

      {/* Edit user drawer */}
      <Drawer
        width={540}
        visible={editVisible}
        onClose={() => set_editVisible(false)}
        title={t(TRANSLATION_KEY.editUserAccount)}
        destroyOnClose={true}
        forceRender={true}
      >
        <AccountForm close={() => set_editVisible(false)} account={account} />
      </Drawer>

      {/* Change password modal */}
      <Modal
        title={t(TRANSLATION_KEY.changePassword)}
        footer={null}
        visible={changePasswordVisible}
        onCancel={() => set_changePasswordVisible(false)}
      >
        <AdminChangePassword id={id} close={() => set_changePasswordVisible(false)} />
      </Modal>
    </div>
  );
};

export default Account;
