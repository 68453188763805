import React, { useCallback, useEffect, useMemo, useState } from "react";
import api, { failedQueue, isRefreshing } from "../../../../services";
import Filters, { IFilters } from "./components/Filters";
import { IApiResponse, ITableColumn } from "../../../../models";
import { Col, Modal, Row, Space, Table, Typography, message } from "antd";
import { t } from "i18next";
import { LAYOUT_GRIDS, TRANSLATION_KEY } from "../../../../helpers/consts";
import Loader from "../../components/Loader";
import AnalyticsCard from "../../../../components/Cards/AnalyticsCard";
import { parseAnalyticsInfo } from "../../../../helpers/functions";
import { Column, ColumnConfig } from "@ant-design/charts";
import AssetModalPreview from "../../../assetdetails/components/AssetModalPreview";
import MaintenanceModalPreview from "../../../maintenance/components/ModalPreview";
import AccountModalPreview from "../../../account/components/AccountModalPreview";
import PreviewButton from "../../../../components/PreviewButton";
import { unstable_batchedUpdates } from "react-dom";
import PreviewButtonGroup from "../../../../components/PreviewButton/Group";
import { IItemTranslatetProps } from "./Reportonemaintenance";

type Props = {};

const Reportfourmaintenance = (props: Props) => {
  // Variables
  const [loading, set_loading] = useState(false);
  const [data, set_data] = useState<IOrderStats | null>(null);
  const [selectedMaintenance, set_selectedMaintenance] = useState<number>(-1);
  const [selectedAsset, set_selectedAsset] = useState<number>(-1);
  const [selectedAccount, set_selectedAccount] = useState<number>(-1);

  // Methods
  async function getData(filters: IFilters) {
    set_loading(true);
    let token = await localStorage.getItem("token");

    try {
      let res = await api.get<IApiResponse<IOrderStats>>(
        "/maintenance/analytics/maintenance_success_analytics/",
        {
          headers: { Authorization: "Bearer " + token },
          params: {
            assets: filters.assets.join("|"),
            asset_categories: filters.asset_categories.join("|"),
            end: filters.end,
            start: filters.start,
            executor_account: filters.executor_account?.join("|"),
            executor_supplier: filters.executor_supplier?.join("|"),
          },
        },
      );
      set_data(res.data.results![0] as IOrderStats);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getData(filters));
        }
        return;
      }
      console.log("error :>> ", error);
      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_loading(false);
  }

  // Chart config
  const successData = useMemo(() => {
    if (data && data.per_executor && data.per_executor?.length > 0) {
      return data.per_executor.map((executor) => ({
        name: executor.account_id?.name || executor.supplier_id?.name || "Unknown",
        data: executor.successful_orders,
        type: t(TRANSLATION_KEY.successful),
      }));
    }
    return [];
  }, [data]);

  const failData = useMemo(() => {
    if (data && data.per_executor && data.per_executor?.length > 0) {
      return data.per_executor.map((executor) => ({
        name: executor.account_id?.name || executor.supplier_id?.name || "Unknown",
        data: executor.unsuccessful_orders,
        type: t(TRANSLATION_KEY.unsuccessful),
      }));
    }
    return [];
  }, [data]);

  const annotations = useMemo(() => {
    if (data && data.per_executor && data.per_executor?.length > 0) {
      return data.per_executor.map((executor, index) => {
        let x = index;
        let y = executor.successful_orders + executor.unsuccessful_orders;
        return {
          type: "text",
          position: [x, y],
          content: `${executor.success_percentage}%`,
          style: {
            textAlign: "center",
            fontSize: 14,
            fontWeight: "bold",
            fill: "rgba(0,0,0,0.75)",
          },
          offsetY: -10,
        };
      });
    }
    return [];
  }, [data]);

  const config: ColumnConfig = {
    data: [...successData, ...failData],
    xField: "name",
    yField: "data",
    isStack: true,
    seriesField: "type",
    maxColumnWidth: 80,
    color: (value: any) => {
      let color: string = value.type === t(TRANSLATION_KEY.successful) ? "#61e093" : "#e06161";
      return color;
    },
    label: {
      position: "middle",
      layout: [{ type: "interval-adjust-position" }, { type: "adjust-color" }],
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
    connectedArea: {
      style: (oldStyle, element) => {
        return {
          fill: "rgba(0,0,0,0.25)",
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        };
      },
    },
    legend: {
      position: "top-left",
    },
    annotations: annotations as any,
  };

  // Columns
  const COLUMNS: ITableColumn<IFailedOrders>[] = [
    {
      title: t(TRANSLATION_KEY.maintenanceId),
      dataIndex: "id",
      visible: true,
      render: (text: string, value: IFailedOrders) => `#${value.order_number}`,
    },
    {
      title: t(TRANSLATION_KEY.description),
      dataIndex: "description",
      visible: true,
      render: (text: string, value: IFailedOrders) => {
        return (
          <PreviewButton
            // isActive={value.is_active}
            title={value.description}
            id={value.id}
            url={`/app/maintenances/${value.id}`}
            containerStyle={{ minWidth: "unset" }}
            onClick={() => {
              unstable_batchedUpdates(() => {
                set_selectedMaintenance(value.id);
              }, []);
            }}
          />
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.maintenanceCategory),
      dataIndex: "maintenance_category",
      visible: true,
      render: (text: string, value: IFailedOrders) =>
        value.maintenance_category?.map((c) => t(c.trans_key || c.name || "")).join(" ") || "-",
    },
    {
      title: t(TRANSLATION_KEY.maintenanceTypes),
      dataIndex: "maintenance_type",
      visible: true,
      render: (text: string, value: IFailedOrders) =>
        t(value.maintenance_type.trans_key || value.maintenance_type.name || "") || "-",
    },
    {
      title: t(TRANSLATION_KEY.asset),
      dataIndex: "asset",
      visible: true,
      render: (text: string, value: IFailedOrders) => {
        if (!value.asset)
          return <Typography.Text>{t(TRANSLATION_KEY.withoutAsset)}</Typography.Text>;
        return (
          <PreviewButton
            isActive={value.asset.is_active}
            title={value.asset.name}
            id={value.asset.id}
            url={`/app/asset-details/${value.asset.id}`}
            containerStyle={{ minWidth: "unset" }}
            onClick={() => {
              unstable_batchedUpdates(() => {
                set_selectedAsset(value.asset?.id || -1);
              }, []);
            }}
          />
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.executors),
      dataIndex: "executors",
      visible: true,
      render: (text: string, value: IFailedOrders) => {
        return (
          value.executors.length > 0 && (
            <Space size={4}>
              <PreviewButtonGroup title={t(TRANSLATION_KEY.executors)} limit={2}>
                {value.executors.map((executor) => (
                  <PreviewButton
                    isActive={
                      executor?.account_id
                        ? executor?.account_id?.is_active
                        : executor?.supplier_id?.is_active
                    }
                    title={
                      executor?.account_id
                        ? executor?.account_id?.name
                        : executor?.supplier_id?.name
                    }
                    id={executor?.account_id ? executor?.account_id?.id : executor?.supplier_id?.id}
                    url={`/app/humanresources/${
                      executor?.account_id ? executor?.account_id?.id : executor?.supplier_id?.id
                    }`}
                    onClick={() => {
                      unstable_batchedUpdates(() => {
                        let id = executor?.account_id
                          ? executor?.account_id?.id
                          : (executor?.supplier_id?.id as number);
                        set_selectedAccount(id);
                      }, []);
                    }}
                  />
                ))}
              </PreviewButtonGroup>
            </Space>
          )
        );
      },
    },
  ];

  return (
    <div>
      {/* Spinner */}
      <Loader isLoading={loading} />

      {/* Filters */}
      <Filters onChange={(filters: IFilters) => getData(filters)} />

      {/* Cards */}
      <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }}>
        <Col xs={12} xxl={8}>
          <AnalyticsCard label={t(TRANSLATION_KEY.successCount)} value={data?.success_count} />
        </Col>
        <Col xs={12} xxl={8}>
          <AnalyticsCard label={t(TRANSLATION_KEY.failCount)} value={data?.fail_count} />
        </Col>
        <Col xs={24} xxl={8}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.successPercentage)}
            value={`${parseAnalyticsInfo(data?.success_percentage?.toString() || "0", 2, true)}%`}
          />
        </Col>
      </Row>

      {/* Charts */}
      <Row gutter={[24, 24]}>
        {/* Column chart */}
        <Col {...{ ...LAYOUT_GRIDS, xxl: 24, xl: 24 }}>
          <div className="white-container" style={{ height: "100%", maxHeight: 480 }}>
            {/* Title */}
            <Typography.Title level={5} style={{ paddingTop: 12 }}>
              {t(TRANSLATION_KEY.woSuccessGraph)}
            </Typography.Title>
            {/* Divider */}
            <div style={{ marginTop: 12, marginBottom: 12 }} />
            {/* Chart */}
            <div style={{ maxHeight: 572 }}>
              <Column {...(config as any)} />
            </div>
          </div>
        </Col>
      </Row>

      {/* Table */}
      <div style={{ marginTop: 24 }}>
        {/* Title */}
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.unsuccessfulWO)}
        </Typography.Title>
        <Table dataSource={data?.failed_orders || []} columns={COLUMNS} />
      </div>

      {/* Preview modal */}
      <Modal
        visible={selectedAsset !== -1 || selectedMaintenance !== -1 || selectedAccount !== -1}
        onCancel={() => {
          set_selectedAsset(-1);
          set_selectedMaintenance(-1);
          set_selectedAccount(-1);
        }}
        footer={null}
        centered
        width="800px"
        closable={false}
        destroyOnClose
      >
        {selectedAsset !== -1 && <AssetModalPreview id={selectedAsset} />}
        {selectedMaintenance !== -1 && <MaintenanceModalPreview id={selectedMaintenance} />}
        {selectedAccount !== -1 && <AccountModalPreview id={selectedAccount} />}
      </Modal>
    </div>
  );
};

export default Reportfourmaintenance;

interface IOrderStats {
  success_count: number;
  fail_count: number;
  success_percentage: number;
  per_executor: IPerExecutor[];
  failed_orders: IFailedOrders[];
}

interface IPerExecutor {
  account_id: {
    id: number;
    name: string;
    is_active: boolean;
  } | null;
  supplier_id: {
    id: number;
    name: string;
    is_active: boolean;
  } | null;
  successful_orders: number;
  unsuccessful_orders: number;
  success_percentage: number;
}

interface IFailedOrders {
  id: number;
  order_number: string;
  description: string;
  maintenance_type: IItemTranslatetProps;
  asset: {
    id: number;
    name: string;
    path: string;
    is_active: boolean;
  } | null;
  maintenance_category: IItemTranslatetProps[] | null;
  executors: {
    account_id: {
      id: number;
      name: string;
      is_active: boolean;
    } | null;
    supplier_id: {
      id: number;
      name: string;
      is_active: boolean;
    } | null;
  }[];
}
