import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import moment, { Moment } from "moment";

// Antd
import { message, Modal, Typography } from "antd";

// Rest
import MaintenancesTable from "../../../components/MaintenancesTable";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";
import { getMaintenanceListXHR } from "../../../store/reducers/maintenance/actionCreator";
import CalendarMaintenances from "../../maintenances/Calendar";
import { ICursor } from "../../../models";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { unstable_batchedUpdates } from "react-dom";

interface ILocalFilters {
  start: string;
  end: string;
  search: string;
}

const Maintenances: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();

  // Variables
  const [showCalendar, set_showCalendar] = useState<boolean>(false);
  const { supplier } = useAppSelector((state) => state.supplierReducer);
  const { maintenanceList, getMaintenanceListStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  const [localQueryParams, set_localQueryParams] = useState<ICursor>(maintenanceList.cursor);
  const [filters, set_filters] = useState<ILocalFilters>({
    search: "",
    start: moment().startOf("month").toISOString(true),
    end: moment().endOf("month").toISOString(true),
  });

  function getMaintenances(queryParams: ICursor, filters: ILocalFilters, mergeData: boolean) {
    if (queryParams.cursor === null && !!maintenanceList.data.length) return;

    getMaintenanceListXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          ...queryParams,
          start: filters.start,
          end: filters.end,
          search: filters.search,
          executor_supplier: supplier.id,
          status: "open|assigned|running|held|completed|requested|closed",
        },
        mergeData,
        successCallback: (data) => {
          if (data.results) {
            unstable_batchedUpdates(() => {
              set_filters(filters);
              set_localQueryParams(data.results?.cursor!);
            }, []);
          }
        },
      },
      dispatch,
    );
  }

  function handleOnDateChanged(start: string, end: string) {
    getMaintenances(
      localQueryParams,
      {
        ...filters,
        start,
        end,
      },
      false,
    );
  }

  function handleLoadMore() {
    getMaintenances(localQueryParams, filters, true);
  }

  function handleOnSearch(search: string) {
    set_filters({
      ...filters,
      search,
    });
    getMaintenances(
      localQueryParams,
      {
        ...filters,
        search,
      },
      false,
    );
  }

  return (
    <div>
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 8, marginBottom: -2 }}>
          {t(TRANSLATION_KEY.maintenances)}
        </Typography.Title>
      </div>

      {/* Table */}
      <MaintenancesTable
        start={filters.start}
        end={filters.end}
        data={maintenanceList.data}
        cursor={localQueryParams}
        loading={getMaintenanceListStatus === "loading"}
        onDateChanged={handleOnDateChanged}
        onLoadMore={handleLoadMore}
        onSearch={handleOnSearch}
        showOpenCalendarButton
        handleOpenCalendar={() => set_showCalendar(true)}
      />

      {/* Calendar */}
      <Modal
        title={supplier?.name}
        visible={showCalendar}
        onCancel={() => set_showCalendar(false)}
        footer={null}
        width="90vw"
        centered
      >
        <CalendarMaintenances executor_suppliers={[supplier?.id || -1]} fromModal />
      </Modal>
    </div>
  );
};

export default Maintenances;
