import React from "react";
import { useAppDispatch } from "../../hooks";
import { new_way_logoutXHR } from "../../store/reducers/user/actionCreators";

const Logout = () => {
  const dispatch = useAppDispatch();

  new_way_logoutXHR(null, dispatch);

  // window.location.href = "../login";
  // return <Navigate to="/login" />;
  return <></>;
};

export default Logout;
