import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Empty, message, Spin, Typography, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FilesComponent, { MediaType } from "../../../components/Files";
import RequirePermission from "../../../components/RequirePermission";
import { MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../../../helpers/consts";
import { calculateTimeout, filterFilesByExtension } from "../../../helpers/functions";
import { useAppDispatch } from "../../../hooks";
import { IApiResponse } from "../../../models";
import { IFile } from "../../../models/user";
import api, { failedQueue, isRefreshing } from "../../../services";
import { IRentDetails } from "../../../models/clients";
import { clientsSlice } from "../../../store/reducers/clients";

type IProps = {
  rentDetails: IRentDetails;
};

// Search data
export type Data = IFile & { search: string };
const mapData = (materials: IFile[]): Data[] => {
  return materials.map((x) => ({
    ...x,
    search: x.name.toLowerCase(),
  }));
};

const Materials: React.FC<IProps> = ({ rentDetails }) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { id } = useParams();

  // Variables
  const [search, set_search] = useState<string>("");
  const [filters, set_filters] = useState<MediaType[]>([]);
  const [loading, set_loading] = useState<boolean>(false);
  const [data, set_data] = useState<Data[]>([]);
  const [files, set_files] = useState<RcFile[]>([]);

  // Methods
  useEffect(() => {
    set_data(mapData(rentDetails.materials));
  }, [rentDetails.materials]);

  useEffect(() => {
    // If uploadTrigger is true, call onUpload function
    if (files.length > 0) {
      onUpload();
    }
  }, [files]);

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    return false;
  };

  // Upload function, called in useEffect by uploadTrigger
  const onUpload = async () => {
    // Reseting uploadTrigger
    set_loading(true);
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    files.forEach((element: RcFile) => {
      formData.append("files", element);
    });

    try {
      let timeout = calculateTimeout(files);
      let response = await api.post<IApiResponse<IRentDetails>>(
        `clients/rent_order/v2/${rentDetails.id}/add_materials/`,
        formData,
        {
          timeout,
          headers: { Authorization: "Bearer " + token },
        },
      );

      if (response.data.results) {
        const fakeRes: IApiResponse<IRentDetails> = {
          results: response.data.results,
          message: "",
        };

        dispatch(clientsSlice.actions.getRentOrderDetailsSuccess(fakeRes));
        set_files([]);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onUpload());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onDeleteFile = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.delete<IApiResponse<string>>(
        `/clients/rent_order/v2/${id}/delete_material/`,
        {
          data: undefined,
          headers: { Authorization: "Bearer " + token },
        },
      );

      let arr: IFile[] = [...rentDetails.materials];

      let index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);
      const fakeRes: IApiResponse<IRentDetails> = {
        results: { ...rentDetails, materials: arr },
        message: "",
      };

      dispatch(clientsSlice.actions.getRentOrderDetailsSuccess(fakeRes));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDeleteFile(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_loading(false);
  };

  // Checkboxes
  function onChange(checkedValues: any) {
    set_filters(checkedValues);
  }

  // Filters and search
  let filtredData: IFile[] = data;
  if (search) filtredData = data?.filter((x) => x.search.includes(search));
  if (filters.length) filtredData = filterFilesByExtension(filtredData, filters);

  return (
    <div style={{ paddingTop: 12, paddingBottom: 12 }}>
      {/* Header */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="spaceBetweenRow">
          <Typography.Title level={5}>{t(TRANSLATION_KEY.materials)}</Typography.Title>

          <div className="spaceBetweenRow">
            <Upload
              showUploadList={false}
              beforeUpload={beforeUpload}
              accept={MATERIAL_EXTENSIONS}
              multiple
            >
              <Button loading={loading} type="primary" shape="circle" icon={<UploadOutlined />} />
            </Upload>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "stretch" }}></div>
      </div>

      <Divider style={{ marginTop: 18 }} />

      {/* Files */}
      <Spin style={{ width: "100%" }} spinning={loading}>
        {filtredData.length > 0 ? (
          <FilesComponent
            size={"small"}
            layout="grid"
            files={data}
            onEdit={() => {}}
            hideEditButton
            hideMetaType
            onDelete={onDeleteFile}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </div>
  );
};

export default Materials;
