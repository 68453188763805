import { PlayCircleOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm } from "antd";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { canStartOrder } from "../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IMaintenanceDetails } from "../../../../models/maintenances";
import { startMaintenanceXHR } from "../../../../store/reducers/maintenance/actionCreator";
import { startOpenOrderXHR } from "../../../../store/reducers/supplier/actionCreator";

interface IProps {
  maintenance: IMaintenanceDetails;
  onClose?: () => void;
  isOpenOrder: boolean;
}

const Start: React.FC<IProps> = ({ maintenance, onClose, isOpenOrder }) => {
  const dispatch = useAppDispatch();
  const { token } = useParams();

  const startMaintenance = () => {
    startMaintenanceXHR(
      {
        body: {
          order_info: maintenance.id,
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          if (onClose) {
            onClose();
          }
        },
      },
      dispatch,
    );
  };

  const startOpenOrder = async () => {
    let code = await localStorage.getItem(token || "");
    startOpenOrderXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        body: {
          order_info: maintenance.id,
          token: token || "",
          code: code || "",
        },
      },
      dispatch,
    );
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          isOpenOrder ? startOpenOrder() : startMaintenance();
        }}
      >
        {t(TRANSLATION_KEY.start)}
      </Button>
    </>
  );
};

export default Start;
