import { Column, Datum } from "@ant-design/charts";
import { Divider, Typography } from "antd";
import { t } from "i18next";
import React, { useEffect, useMemo } from "react";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";

interface IProps {}

const MaintenancesByCategory: React.FC<IProps> = () => {
  const { liveMaintenances } = useAppSelector((state) => state.maintenanceReducer);
  const {
    maintenancesByCategoryType: { categories },
  } = useAppSelector((state) => state.dashboardReducer);

  const content = useMemo(
    () => (
      <div>
        <div className="spaceBetweenRow">
          <Typography.Title level={5} style={{ paddingTop: 12 }}>
            {t(TRANSLATION_KEY.maintenancesByCategory)}
          </Typography.Title>
        </div>

        <div style={{ marginTop: 12, marginBottom: 12 }} />

        <Column
          data={categories.map((item) => ({
            ...item,
            name: item.name === "other" ? t(TRANSLATION_KEY.other) : item.name,
          }))}
          maxColumnWidth={60}
          xField={"name"}
          yField="count"
          animation={{ appear: { delay: 500 } }}
          tooltip={{
            formatter: (datum: Datum) => {
              return {
                name: t(TRANSLATION_KEY.count),
                value: datum.count,
              };
            },
          }}
        />
      </div>
    ),
    [categories, liveMaintenances],
  );

  return content;
};

export default MaintenancesByCategory;
