import React from "react";
import { useAppSelector } from "../hooks";
import { Typography } from "antd";
import { Link } from "react-router-dom";

interface IProps {
  is_active: boolean;
  name: string | undefined;
  id: number | undefined;
}

const ClientTableItem: React.FC<IProps> = ({ is_active, name, id }) => {
  const { user } = useAppSelector((state) => state.userReducer);

  if (id === undefined) {
    return <Typography.Text>-</Typography.Text>;
  }

  if (
    !is_active ||
    !user.account.permissions.some((permission) => permission.codename === "manage_client")
  ) {
    return <Typography.Text>{name}</Typography.Text>;
  }

  return <Link to={`/app/clients/${id}/`}>{name}</Link>;
};

export default ClientTableItem;
