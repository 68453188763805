import React, { useState, useEffect } from "react";
import { IFile } from "../../../models/user";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { copyFilesToAssetsXHR, getAssetXHR } from "../../../store/reducers/asstes/actionCreators";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { useAppSelector } from "../../../hooks";

// Antd
import { Button, Checkbox, Divider, List, message, Spin, Steps, Typography, Input } from "antd";
import styles from "./styles.module.css";
import { IAsset, ICopyFilesToAssetsStatus } from "../../../models/asset";
import { TRANSLATION_KEY } from "../../../helpers/consts";

const { Step } = Steps;
const { Search } = Input;

type IProps = {
  data: IFile[];
  currentAsset: IAsset;
  close: () => void;
};

type IStepOne = {
  data: IFile[];
  checked: number[];
  onChange: ((e: CheckboxChangeEvent) => void) | undefined;
  checkAll: ((e: CheckboxChangeEvent) => void) | undefined;
};

type IStepTwo = {
  data: IAsset[];
  checked: number[];
  onChange: ((e: CheckboxChangeEvent) => void) | undefined;
  checkAll: ((e: CheckboxChangeEvent) => void) | undefined;
};

const ShareMaterials = ({ data, currentAsset, close }: IProps) => {
  // Constants
  const steps = [
    { title: t(TRANSLATION_KEY.selectMaterials), key: 1 },
    { title: t(TRANSLATION_KEY.selectAssets), key: 2 },
  ];

  // Hooks
  const dispatch = useDispatch();

  // Variables
  const [current, setCurrent] = useState<number>(0);
  const [materialsChecked, setMaterialsChecked] = useState<number[]>([]);
  const [assetsChecked, setAssetsChecked] = useState<number[]>([]);
  const { getAssetStatus, assetList, copyFilesToAssetsStatus } = useAppSelector(
    (state) => state.assetReducer,
  );
  const allMaterials = data.map((item) => item.id);
  const allAssets = assetList.map((item) => item.id).filter((item) => item !== currentAsset.id);
  const _key = steps[current].key as keyof typeof percentage;

  const percentage = {
    1: (materialsChecked.length / allMaterials.length) * 100,
    2: (assetsChecked.length / allAssets.length) * 100,
  };

  // Methods
  useEffect(() => {
    getAssetXHR(
      {
        errorCallback: (data: any) => message.error(t("errorOnFetchData")),
      },
      dispatch,
    );
  }, []);

  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  //* Material methods
  function onMaterialsChange(e: CheckboxChangeEvent) {
    let id: number = +e.target.value;
    let selected: boolean = e.target.checked;
    let arr = [...materialsChecked];
    if (selected) arr.push(id);
    else {
      let index = arr.findIndex((item) => item === id);
      arr.splice(index, 1);
    }
    setMaterialsChecked(arr);
  }

  function checkAllMaterials(e: CheckboxChangeEvent) {
    setMaterialsChecked(e.target.checked ? allMaterials : []);
  }

  //* Asset methods
  function onAssetsChange(e: CheckboxChangeEvent) {
    let id: number = +e.target.value;
    let selected: boolean = e.target.checked;
    let arr = [...assetsChecked];
    if (selected) arr.push(id);
    else {
      let index = arr.findIndex((item) => item === id);
      arr.splice(index, 1);
    }
    setAssetsChecked(arr);
  }

  function checkAllAssets(e: CheckboxChangeEvent) {
    setAssetsChecked(e.target.checked ? allAssets : []);
  }

  function handleSubmit() {
    const body: ICopyFilesToAssetsStatus = {
      files: materialsChecked,
      assets: assetsChecked,
    };
    copyFilesToAssetsXHR(
      {
        body,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => close(),
      },
      dispatch,
    );
  }

  return (
    <Spin spinning={getAssetStatus === "loading" || copyFilesToAssetsStatus === "loading"}>
      <div className={styles.container}>
        {/* Steps */}
        <Steps type="navigation" size="small" current={current} percent={percentage[_key]}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>

        {/* Content */}
        <div className={styles.stepsContent}>
          {/* Step one */}
          <div style={{ display: steps[current].key === 1 ? "block" : "none" }}>
            <StepOne
              data={data}
              onChange={onMaterialsChange}
              checkAll={checkAllMaterials}
              checked={materialsChecked}
            />
          </div>

          {/* Step two */}
          <div style={{ display: steps[current].key === 2 ? "block" : "none" }}>
            <StepTwo
              data={assetList.filter((item) => item.id !== currentAsset.id)}
              onChange={onAssetsChange}
              checkAll={checkAllAssets}
              checked={assetsChecked}
            />
          </div>
        </div>

        {/* Actions */}
        <div className={styles.stepsAction}>
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              {t(TRANSLATION_KEY.previous)}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button disabled={materialsChecked.length === 0} type="primary" onClick={() => next()}>
              {t(TRANSLATION_KEY.next)}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button disabled={assetsChecked.length === 0} type="primary" onClick={handleSubmit}>
              {t(TRANSLATION_KEY.done)}
            </Button>
          )}
        </div>
      </div>
    </Spin>
  );
};

const StepOne = ({ data, checked, onChange, checkAll }: IStepOne) => {
  return (
    <div>
      <div className={styles.stepOneHeader}>
        <div>
          {t(TRANSLATION_KEY.materialsSelected)}: {checked.length}
        </div>
        <Checkbox
          checked={data.length === checked.length}
          indeterminate={checked.length !== 0 && checked.length !== data.length}
          onChange={checkAll}
        >
          {t(TRANSLATION_KEY.selectAll)}
        </Checkbox>
      </div>
      <Divider />
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item className={styles.stepOneListItem}>
            <Checkbox
              checked={checked.includes(item.id)}
              value={item.id}
              className={styles.stepOneListItemCheckbox}
              onChange={onChange}
            >
              <div className={styles.stepOneListItemCheckboxWrapper}>
                <Typography.Text className={styles.stepOneListItemText}>
                  {item.name}
                </Typography.Text>
                {/* <img src={item.file} className={styles.stepOneListItemImage} /> */}
              </div>
            </Checkbox>
          </List.Item>
        )}
      />
    </div>
  );
};

const StepTwo = ({ data, checked, onChange, checkAll }: IStepTwo) => {
  // Variables
  const [search, set_search] = useState<string>("");
  let filteredData: IAsset[] = data;

  if (search) filteredData = data.filter((item) => item.name.toLowerCase().includes(search));

  return (
    <div>
      <div className={styles.stepOneHeader}>
        <div>
          {t(TRANSLATION_KEY.assetsSelected)}: {checked.length}
        </div>
        <Checkbox
          checked={data.length === checked.length}
          indeterminate={checked.length !== 0 && checked.length !== data.length}
          onChange={checkAll}
        >
          {t(TRANSLATION_KEY.selectAll)}
        </Checkbox>
      </div>
      <Divider />
      <Search
        placeholder={t(TRANSLATION_KEY.searchAssetsByName)}
        allowClear
        enterButton
        style={{ marginBottom: 24 }}
        onChange={(e) => set_search(e.target.value.toLowerCase())}
      />
      <List
        className={styles.listContainer}
        dataSource={filteredData}
        renderItem={(item) => (
          <List.Item className={styles.stepOneListItem}>
            <Checkbox
              checked={checked.includes(item.id)}
              value={item.id}
              className={styles.stepOneListItemCheckbox}
              onChange={onChange}
            >
              <div className={styles.stepOneListItemCheckboxWrapper}>
                <Typography.Text className={styles.stepOneListItemText}>
                  {item.name}
                </Typography.Text>
              </div>
            </Checkbox>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ShareMaterials;
