import React, { useEffect, useState } from "react";

import { Badge, Button, Drawer, Layout, Modal, Space, Typography, Divider, message } from "antd";

import {
  AndroidOutlined,
  AppleOutlined,
  BellOutlined,
  CalendarOutlined,
  FileAddOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MobileOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { t } from "i18next";
import MaintenanceForm from "../../componentsform/MaintenanceForm";
import SimpleReportOrder from "../../componentsform/SimpleReportOrder";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Notifications from "../../components/Notifications";
import { OrderType } from "../../models";
import { hasPermission } from "../../helpers/functions";
import { motion } from "framer-motion";
import { useThemeSwitcher } from "react-css-theme-switcher";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import RunningOrders from "../../components/RunningOrders";
import CalendarMaintenances from "../../pages/maintenances/Calendar";
import WorkRequests from "./WorkRequests";
import {
  getWorkCostsXHR,
  getWorkRequestsXHR,
} from "../../store/reducers/maintenance/actionCreator";
import { unreadedNotificationsCountXHR } from "../../store/reducers/user/actionCreators";
import PlayStoreModal from "./PlayStoreModal";
import AppStoreModal from "./AppStoreModal";

const { Header } = Layout;

interface IProps {
  authed: boolean;
  collapsed?: boolean;
  setCollapsed?: () => void;
}

const _Header: React.FC<IProps> = ({ collapsed, setCollapsed }) => {
  // Constants

  // Hooks
  const { switcher, currentTheme, themes } = useThemeSwitcher();

  // Variables
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [formVisible, set_formVisible] = useState<boolean>(false);
  const [PSQRCodeModalVisible, setPSQRCodeModalVisible] = useState<boolean>(false);
  const [ASQRCodeModalVisible, setASQRCodeModalVisible] = useState<boolean>(false);
  const [notificationsVisible, set_notificationsVisible] = useState<boolean>(false);
  const { user, userNotifications, unreadedNotificationsCount } = useAppSelector(
    (state) => state.userReducer,
  );
  const [calendarVisible, set_calendarVisible] = useState(false);
  const [workRequestVisibel, set_workRequestVisibel] = useState(false);
  const orderType: OrderType = hasPermission(user.account.permissions, ["manage_wo"])
    ? "full"
    : "simple";
  const runningOrders =
    useAppSelector((state) =>
      state.maintenanceReducer.liveMaintenances.filter((x) => x.status === "running"),
    ) || [];
  const { workRequests } = useAppSelector((state) => state.maintenanceReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // Methods
  useEffect(() => {
    unreadedNotificationsCountXHR(
      {
        successCallback: (data) => {
          console.log(data);
        },
        errorCallback: (e) => {
          console.log(e);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
    if (hasPermission(user.account.permissions, ["manage_wo"])) {
      getWorkRequestsXHR(
        {
          successCallback: (data) => {
            console.log(data);
          },
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        },
        dispatch,
      );
    }
  }, []);

  const toggleTheme = (isChecked: boolean) => {
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };

  // Avoid theme change flicker
  // if (status === "loading") {
  //   return null;
  // }

  return (
    <>
      <Header
        id="header"
        className="site-layout-background"
        style={{
          padding: 0,
          position: "fixed",
          right: 0,
          zIndex: 1,
          width: "100%",
          backgroundColor: currentTheme === themes.dark ? "#141414" : "#fff",
          borderBottom: `1px solid ${currentTheme === themes.dark ? "#303030" : "#eee"}`,
        }}
      >
        <motion.div
          animate={{ paddingLeft: collapsed ? 80 : 200 }}
          initial={{ paddingLeft: collapsed ? 80 : 200 }}
          transition={{ ease: "easeInOut", duration: 0.15 }}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ paddingLeft: 20 }}>
            {/* Custom sidebar trigger */}
            {setCollapsed && (
              <Button type="text" shape="circle" onClick={setCollapsed}>
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: "trigger",
                })}
              </Button>
            )}
          </div>
          <div style={{ padding: "0 20px" }}>
            <div className="spaceBetweenRow">
              {/* Theme switcher */}
              {/* <Switch checked={isDarkMode} onChange={toggleTheme} /> */}

              {/* AS QR Code */}
              <Button
                type="text"
                shape="circle"
                onClick={() => setASQRCodeModalVisible(true)}
                style={{ marginLeft: 24 }}
                icon={<AppleOutlined style={{ fontSize: 20 }} />}
              />

              {/* PS QR Code */}
              <Button
                type="text"
                shape="circle"
                onClick={() => setPSQRCodeModalVisible(true)}
                style={{ marginLeft: 18 }}
                icon={<AndroidOutlined style={{ fontSize: 20 }} />}
              />

              <div style={{ marginRight: 8, marginLeft: 24, marginBottom: -7 }}>
                <CalendarOutlined
                  onClick={() => set_calendarVisible(true)}
                  style={{ fontSize: 22 }}
                />
              </div>

              <div style={{ marginRight: 8, marginLeft: 22, marginBottom: -4 }}>
                <Button
                  onClick={() => {
                    set_workRequestVisibel(true);
                  }}
                  type="text"
                  shape="circle"
                  icon={
                    <Badge count={workRequests.length} offset={[1, -1]}>
                      <SolutionOutlined style={{ fontSize: 22 }} />
                    </Badge>
                  }
                />
              </div>

              {/* Notifications */}
              <div style={{ marginRight: 16, marginLeft: 16 }}>
                <Button
                  onClick={() => {
                    set_notificationsVisible(true);
                  }}
                  type="text"
                  shape="circle"
                  icon={
                    <Badge count={unreadedNotificationsCount} offset={[1, -1]}>
                      <BellOutlined style={{ fontSize: 22 }} />
                    </Badge>
                  }
                />
              </div>

              <RunningOrders maintenances={runningOrders} />

              {/* Report order */}
              <Button type="primary" onClick={() => set_formVisible(true)}>
                {t(TRANSLATION_KEY.reportOrder)}
                <FileAddOutlined />
              </Button>
            </div>
          </div>

          {/* Report order forms */}
          <Drawer
            width={540}
            visible={formVisible}
            onClose={() => set_formVisible(false)}
            title={
              orderType === "full" ? t(TRANSLATION_KEY.addOrder) : t(TRANSLATION_KEY.reportOrder)
            }
            destroyOnClose={true}
          >
            {orderType === "full" ? (
              <MaintenanceForm onClose={() => set_formVisible(false)} />
            ) : (
              <SimpleReportOrder onClose={() => set_formVisible(false)} />
            )}
          </Drawer>

          {/* Notifications drawer */}
          <Drawer
            width={540}
            visible={notificationsVisible}
            onClose={() => set_notificationsVisible(false)}
            title={null}
            closable={false}
            destroyOnClose={true}
          >
            <Notifications onClose={() => set_notificationsVisible(false)} />
          </Drawer>

          {/* PS QR Code modal */}
          <PlayStoreModal visible={PSQRCodeModalVisible} setVisible={setPSQRCodeModalVisible} />
          {/* AS QR Code modal */}
          <AppStoreModal visible={ASQRCodeModalVisible} setVisible={setASQRCodeModalVisible} />
        </motion.div>
      </Header>
      <Drawer
        width={540}
        visible={workRequestVisibel}
        onClose={() => set_workRequestVisibel(false)}
        footer={null}
        closable={false}
      >
        <WorkRequests onClose={() => set_workRequestVisibel(false)} />
      </Drawer>

      {/* Calendar */}
      <Modal
        footer={null}
        destroyOnClose
        onCancel={() => set_calendarVisible(false)}
        centered
        visible={calendarVisible}
        width={"100%"}
      >
        <CalendarMaintenances fromModal={true} />
      </Modal>
    </>
  );
};

export default _Header;
