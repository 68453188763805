import { Button, Form, Input, message } from "antd";
import { t } from "i18next";
import React, { useEffect, useRef } from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ISubAsset } from "../models/asset";
import { addSubAssetXHR, editSubAssetXHR } from "../store/reducers/asstes/actionCreators";

interface IProps {
  subAsset: ISubAsset | undefined;
  asset_id: number;
  close: () => void;
  callback?: (id: number) => void;
}

export interface IAddSubAsset {
  name: string;
  asset: number;
}

const SubAssetForm: React.FC<IProps> = ({ subAsset, asset_id, close, callback }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { addSubAssetStatus } = useAppSelector((state) => state.assetReducer);

  const inputRef = useRef<Input>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const onFinish = async (values: IAddSubAsset) => {
    const final: IAddSubAsset = {
      ...values,
      asset: asset_id,
    };

    // Edit sub asset
    if (subAsset?.id !== 0) {
      editSubAssetXHR(
        {
          body: final,
          id: subAsset?.id,
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
          successCallback: () => {
            message.success(t(TRANSLATION_KEY.successOnSaveData));
            close();
          },
        },
        dispatch,
      );
    }

    // Add sub asset
    else {
      addSubAssetXHR(
        {
          body: final,
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
          successCallback: (data) => {
            if (data.results) {
              message.success(t(TRANSLATION_KEY.successOnSaveData));
              form.setFieldsValue({ name: "" });
              if (callback) {
                callback(data.results.id);
              }
              close();
            }
          },
        },
        dispatch,
      );
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ name: subAsset?.name || "" }}
      >
        <Form.Item
          name="name"
          label={t(TRANSLATION_KEY.name)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <Input ref={inputRef} />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={addSubAssetStatus === "loading"}
            style={{ marginTop: 24, float: "right" }}
          >
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SubAssetForm;
