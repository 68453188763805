import {
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  TableColumnType,
  Tooltip,
  Typography,
} from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import PreviewButton from "../../../components/PreviewButton";
import { STATUSES, TRANSLATION_KEY } from "../../../helpers/consts";
import { ITableColumn } from "../../../models";
import { IMaintenance, MaintenanceStatusTypes } from "../../../models/maintenances";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import MaintenanceModalPreview from "../../maintenance/components/ModalPreview";
import AccountModalPreview from "../../account/components/AccountModalPreview";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import { InfoCircleOutlined } from "@ant-design/icons";
import { filterAllMineMaintenances } from "../../../helpers/functions";
import RequirePermission from "../../../components/RequirePermission";
import ClientsSelect from "../../../componentsform/ClientsSelect";
import ClientTableItem from "../../../components/ClientTableItem";

type AllMine = "all" | "mine";

interface IProps {
  maintenances: IMaintenance[];
}

interface ITableData {
  id: number;
  rowSpan: number;
  asset: JSX.Element;
  order_number: JSX.Element;
  description: string;
  status: string;
  location: string;
  created_by: JSX.Element;
  search: string;
  client: JSX.Element | null;
}

interface IFilters {
  statuses: MaintenanceStatusTypes[];
  clients: number[];
}

const LOCALSTORAGE_KEY = "liveMaintenances_dashboard_new_filters";

const LiveMaintenances: React.FC<IProps> = ({ maintenances }) => {
  const dispatch = useAppDispatch();
  // Variables
  const [selectedMaintenance, set_selectedMaintenance] = useState<number>();
  const [selectedAsset, set_selectedAsset] = useState<number>();
  const [selectedAccount, set_selectedAccount] = useState<number>();
  const { user } = useAppSelector((state) => state.userReducer);
  const [search, set_search] = useState("");
  const [filtredData, set_filtredData] = useState<ITableData[]>([]);
  const [height, set_height] = useState(0);
  const [render, set_redner] = useState(false);
  const [allMine, setAllMine] = useState<AllMine>("mine");
  const [firstCall, set_firstCall] = useState(true);

  const [hasClients, setHasClients] = useState(false);

  const setAllMineFromLocalStorage = async () => {
    const allMine = await localStorage.getItem("liveMaintenances_all/mine");
    if (
      allMine &&
      user.account.permissions.filter((x) => x.codename === "view_all_wo").length > 0
    ) {
      setAllMine(allMine as AllMine);
    }
  };

  const [filters, set_fitlers] = useState<IFilters>({
    statuses: [],
    clients: [],
  });

  const { clients, getClientsStatus } = useAppSelector((state) => state.clientsReducer);

  // Methods
  useEffect(() => {
    setAllMineFromLocalStorage();
    setTimeout(() => {
      set_redner(true);
    }, 639);

    setStatusesFiltersFromLocalStorage();
  }, []);

  const setStatusesFiltersFromLocalStorage = async () => {
    const tmp: string | null = await localStorage.getItem(LOCALSTORAGE_KEY);
    if (tmp) {
      let parsed: IFilters = JSON.parse(tmp);
      console.log(parsed, "parsed");
      set_fitlers(parsed);
    }
  };

  const _columns = [
    {
      title: t(TRANSLATION_KEY.asset),
      dataIndex: "asset",
      // onCell: (value, index) => ({ rowSpan: value.rowSpan }),
    },
    {
      title: t(TRANSLATION_KEY.orderNumber),
      dataIndex: "order_number",
    },
    {
      title: t(TRANSLATION_KEY.description),
      dataIndex: "description",
      render(text, value, index) {
        return (
          <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: text }}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.status),
      dataIndex: "status",
    },
    {
      title: t(TRANSLATION_KEY.location),
      dataIndex: "location",
    },
    {
      title: t(TRANSLATION_KEY.reportedBy),
      dataIndex: "created_by",
    },
  ] as ITableColumn<ITableData>[];

  const [COLUMNS, set_COLUMNS] = useState<TableColumnType<ITableData>[]>(_columns);

  useEffect(() => {
    let _hasClients =
      user?.account?.company?.modules?.filter((x) => x.name === "clients").length > 0;
    setHasClients(_hasClients);
    if (_hasClients && COLUMNS.findIndex((x) => x.dataIndex === "clients") === -1) {
      _columns.push({
        title: t(TRANSLATION_KEY.client),
        dataIndex: "client",
        visible: true,
      });
      set_COLUMNS([..._columns]);
    }
  }, [user.account, hasClients]);

  let parseData = (data: IMaintenance[]): ITableData[] => {
    // Helper variables
    let tmp: ITableData[] = [];
    let proccesed: number[] = [];

    // Parsing data
    data.forEach((x) => {
      let maintenancesLength = data.filter((y) => y.asset?.id === x.asset?.id).length || 0;
      let hasClient =
        user.account.permissions.filter((x) => x.codename === "manage_client").length > 0;
      tmp.push({
        id: x.id,
        rowSpan: 1, // ukidamo zbog sortiranja po datumu prijave naloga !proccesed.includes(x.asset?.id || -1) ? maintenancesLength || 1 : 0,
        asset: x.asset ? (
          <Space direction="vertical" size={0}>
            <PreviewButton
              isActive={x.asset?.is_active}
              title={x.asset?.name || "-"}
              id={x.asset?.id || 0}
              url={`/app/asset-details/${x.asset?.id || 0}`}
              permissionsRequired={["view_asset"]}
              onClick={() => {
                set_selectedAsset(x.asset?.id);
              }}
            />
            {x.asset?.parent_id && (
              <Typography.Text
                type="secondary"
                style={{ maxWidth: 320 }}
                ellipsis={{ tooltip: x.asset.path }}
              >
                {x.asset?.path}
              </Typography.Text>
            )}
          </Space>
        ) : (
          t(TRANSLATION_KEY.withoutAsset)
        ),
        order_number: (
          <PreviewButton
            isActive={x.is_active}
            title={x.order_number}
            id={x.id}
            url={`/app/maintenances/${x.id}`}
            onClick={() => {
              set_selectedMaintenance(x.id);
            }}
          />
        ),
        description: x.description,
        status: t(x.status),
        location: x.location?.name || "-",
        created_by: (
          <div>
            <PreviewButton
              isActive={x.reported_by.is_active}
              title={x.reported_by.name}
              id={x.reported_by.id}
              url={`/app/humanresources/${x.reported_by.id}`}
              permissionsRequired={["manage_user"]}
              onClick={() => {
                set_selectedAccount(x.reported_by.id);
              }}
            />
            <Typography.Text type="secondary">
              {moment(x.created_at).format(user.account.date_format || undefined)}
            </Typography.Text>
          </div>
        ),
        search: (x.asset?.name || "") + x.description + (x.location?.name || "") + x.order_number,
        //dodan klijent na listu
        client: hasClient ? (
          <ClientTableItem
            is_active={x.client?.is_active || false}
            name={x.client?.name}
            id={x.client?.id}
          />
        ) : null,
      });
      proccesed.push(x.asset?.id || -1);
    });

    return tmp;
  };

  useEffect(() => {
    let temp = [...maintenances];

    // Filter by search
    if (search) {
      temp = temp.filter((x) =>
        `${x.description.toLowerCase()}${
          x.asset?.name.toLowerCase() || t(TRANSLATION_KEY.withoutAsset).toLowerCase()
        }${x.location?.name}${x.order_number.toLowerCase()}`.includes(search.toLowerCase()),
      );
    }

    // Filter by all/mine
    if (allMine === "mine") {
      temp = filterAllMineMaintenances(temp, user.account.id);
    }
    if (filters.statuses.length > 0) {
      temp = temp.filter((x) => filters.statuses.includes(x.status as any));
    }
    if (filters.clients.length > 0) {
      temp = temp.filter(
        (x) =>
          filters.clients.filter((y) => y === x.client?.id || (y === 0 && !x.client)).length > 0,
      );
    }

    set_filtredData(parseData(temp));
  }, [search, maintenances, allMine, filters]);

  useEffect(() => {
    set_height(maintenances.length * 61);
  }, [maintenances]);

  function handleAllMine(value: AllMine) {
    localStorage.setItem("liveMaintenances_all/mine", value);
    setAllMine(value);
  }

  console.log(filters, "filters");

  return (
    <div>
      {/* Search */}
      <div style={{ marginBottom: 12 }} className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.assetInMaintenances)}
        </Typography.Title>

        <Space size="large">
          {hasClients && (
            <>
              <ClientsSelect
                includeArchived={true}
                includeInactive={true}
                placeholder={t(TRANSLATION_KEY.clients)}
                style={{ width: 240 }}
                mode="multiple"
                value={filters.clients}
                onChange={(a) => {
                  if (!Array.isArray(a)) return;
                  localStorage.setItem(
                    LOCALSTORAGE_KEY,
                    JSON.stringify({ ...filters, clients: a }),
                  );
                  set_fitlers({ ...filters, clients: a });
                }}
                name=""
                label=""
                form={undefined}
              />
            </>
          )}
          <Select
            value={filters.statuses}
            placeholder={t(TRANSLATION_KEY.statuses)}
            style={{ width: 240 }}
            maxTagCount={2}
            allowClear
            mode="multiple"
            onChange={(a) => {
              localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({ ...filters, statuses: a }));
              set_fitlers({ ...filters, statuses: a });
            }}
          >
            {STATUSES.map((x) => (
              <Select.Option key={x} value={x}>
                {t(x)}
              </Select.Option>
            ))}
          </Select>
          {/* All / Mine */}
          <RequirePermission
            requiredPermissionCode={["manage_wo", "view_all_wo"]}
            children={
              <Space align="center">
                <Tooltip title={t(TRANSLATION_KEY.mineAllFilterInfo)}>
                  <InfoCircleOutlined />
                </Tooltip>
                <Radio.Group
                  buttonStyle="solid"
                  value={allMine}
                  onChange={({ target: { value } }) => handleAllMine(value)}
                >
                  <Radio.Button value="all">{t(TRANSLATION_KEY.allOrders)}</Radio.Button>
                  <Radio.Button value="mine">{t(TRANSLATION_KEY.myOrders)}</Radio.Button>
                </Radio.Group>
              </Space>
            }
          />

          {/* Search */}
          <Space>
            <Tooltip title={t(TRANSLATION_KEY.searchGoesByNameCodeAsset)}>
              <InfoCircleOutlined />
            </Tooltip>
            <Input.Search
              allowClear
              style={{ width: 210 }}
              onChange={({ target: { value } }) => {
                if (!value) {
                  set_search("");
                } else {
                  set_search(value.toLowerCase());
                }
              }}
            />
          </Space>
        </Space>
      </div>

      <div style={{ marginTop: 12, marginBottom: 12 }} />

      {/* Table */}
      {render && (
        <Table
          // style={{height: height}}
          size="small"
          pagination={false}
          dataSource={filtredData}
          columns={COLUMNS.map((item) => ({ ...item, title: _toString(item.title) }))}
          rowKey={(item) => item.id}
        />
      )}

      {/* Modals */}
      <Modal
        visible={!!selectedAccount || !!selectedMaintenance || !!selectedAsset}
        onCancel={() => {
          set_selectedMaintenance(undefined);
          set_selectedAccount(undefined);
          set_selectedAsset(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedMaintenance && <MaintenanceModalPreview id={selectedMaintenance} />}
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
      </Modal>

      {/* Padding bottom */}
      <div style={{ height: 120 }} />
    </div>
  );
};

export default LiveMaintenances;

const _toString = (title: any): string => {
  return t(title).toString();
};
