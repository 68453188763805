import { Tooltip } from "antd";
import { useAppSelector } from "../hooks";

interface IProps {
  children: JSX.Element;
  requiredPermissionCode: string[];
  anywayIgnorePermission?: boolean; // if some permision depends if the user is curent user ignore permisssion
}

const RequirePermission: React.FC<IProps> = ({
  children,
  requiredPermissionCode,
  anywayIgnorePermission = false,
}) => {
  const currentUser = useAppSelector((state) => state.userReducer.user);

  if (
    !!currentUser.account.permissions.filter((x) => requiredPermissionCode.includes(x.codename))
      .length ||
    anywayIgnorePermission
  ) {
    return children;
  }
  return null;
};

export default RequirePermission;
