import { Spin, Typography, message } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";
import RequestPanelForm from "../../componentsform/RequestPanelForm";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { getRequestPanelDetailsXHR } from "../../store/reducers/settings/actionCreator";
import { useAppDispatch, useAppSelector } from "../../hooks";

const Panel: React.FC = ({}) => {
  const { requestPanelDetails, getRequestPanelDetailsStatus } = useAppSelector(
    (state) => state.settingsReducer,
  );
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const panel = urlParams.get("panel");
  useEffect(() => {
    getPanelDetails();
  }, []);

  const getPanelDetails = () => {
    getRequestPanelDetailsXHR(
      {
        token: token!,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
  };
  return (
    <div>
      <Spin spinning={getRequestPanelDetailsStatus === "loading"}>
        <Typography.Title level={5}>{t(TRANSLATION_KEY.reportWorkRequest)}</Typography.Title>
        {requestPanelDetails && (
          <RequestPanelForm
            panelId={requestPanelDetails.id}
            token={token || ""}
            assetList={requestPanelDetails.assets}
          />
        )}
      </Spin>
    </div>
  );
};

export default Panel;
