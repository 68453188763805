import {
  Button,
  Drawer,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  TableColumnType,
  Tabs,
  Typography,
} from "antd";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useDrawerToggle } from "..";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  saveTableDefinition,
} from "../../../components/ViewTabs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { TableView } from "../../../models/user";
import { IClient, IRent, IRentStatus, RentNote } from "../../../models/clients";
import PreviewButton from "../../../components/PreviewButton";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import AccountModalPreview from "../../account/components/AccountModalPreview";
import moment from "moment";
import {
  closeRentOrderXHR,
  deleteRemtOrderXHR,
  getRentedAssetsXHR,
} from "../../../store/reducers/clients/actionCreators";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { clientsSlice } from "../../../store/reducers/clients";
import Columns from "../../../components/ViewTabs/Columns";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { ILoadMoreParams } from "../../../models";
import { debounce, getLastItem } from "../../../helpers/functions";
import { ILodadMoreParams } from "./ClientsList";
import RentForm_v2 from "../../../componentsform/RentForm_v2";
import { Link } from "react-router-dom";
import ClientTableItem from "../../../components/ClientTableItem";
import { store } from "../../../store";

type TableRenders =
  | "RentOrderCustomId"
  | "AssetName"
  | "RentOrderStatus"
  | "RentoOrderLocation"
  | "RentOrderDescription"
  | "RentOrderCreatedBy"
  | "RentOrderConfirmedBy"
  | "RentOrderAction"
  | "ClientName";

type IFilters = {
  status: IRentStatus | "all";
  search: string;
};

const RENTS_SIZE_LIMIT = 33;

const AllRents: React.FC = () => {
  const { addNew, set_addNew } = useDrawerToggle();
  const { user } = useAppSelector((state) => state.userReducer);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [filters, setFilters] = useState<IFilters>({
    status: "active",
    search: "",
  });

  const [hasMore, set_hasMore] = useState(false);
  const [closeLoading, set_closeLoading] = useState(-1);
  const [delLoading, set_delLoading] = useState(-1);
  const [firstMount, set_firstMount] = useState(true);
  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<IRent>>
  >([]);
  const dispatch = useAppDispatch();

  const { rents, getRentedAssetsStatus } = useAppSelector((state) => state.clientsReducer);

  useEffect(() => {
    let activeView = tableDefinition.find((x) => x.active);
    if (activeView) {
      setColumns(activeView.table_structure, 0);
    }
    getRents(filters, false);
  }, []);

  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user.account.views?.rent_order?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.rent_order?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);

  const getRents = async (_filters: IFilters, mergeData: boolean) => {
    getRentedAssetsXHR(
      {
        errorCallback: (data: any) => {
          setFilters(_filters);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback: (res) => {
          setFilters(_filters);
          if (res.results && res.results.length < RENTS_SIZE_LIMIT) {
            set_hasMore(false);
          } else {
            set_hasMore(true);
          }
        },
        mergeData,
        queryParams: {
          limit: RENTS_SIZE_LIMIT,
          offset: mergeData ? rents.length : 0,
          clients: undefined,
          search: _filters.search,
          status: _filters.status !== "all" ? _filters.status : undefined,
          order_type: "rent",
        },
      },
      dispatch,
    );
  };

  const deleteRentOrder = (order: IRent) => {
    set_delLoading(order.id);

    deleteRemtOrderXHR(
      {
        id: order.id,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          set_delLoading(-1);
        },
        successCallback: () => {
          let _rents = store.getState().clientsReducer.rents.filter((r) => r.id !== order.id);
          dispatch(
            clientsSlice.actions.getAssetRentOrdersSuccess({
              results: _rents,
              message: "",
            }),
          );
          set_delLoading(-1);
        },
      },
      dispatch,
    );
  };

  const closeRentOrder = (order: IRent) => {
    set_closeLoading(order.id);
    const fd = new FormData();
    fd.append("files", JSON.stringify([]));
    fd.append("data", JSON.stringify({ description: "" }));

    closeRentOrderXHR(
      {
        body: fd,
        id: order.id,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          set_closeLoading(-1);
        },
        successCallback: () => {
          set_closeLoading(-1);
        },
      },
      dispatch,
    );
  };

  const render = (key: TableRenders) => {
    let tmp: Record<TableRenders, any> = {
      ClientName: (text, record) => {
        return (
          <ClientTableItem
            is_active={record.client?.is_active || false}
            name={record.client?.name}
            id={record.client?.id}
          />
        );
      },
      RentOrderCustomId: (text: string, record: IRent) => {
        return <Link to={`/app/rentorder/${record.id}/`}>#{record.custom_id}</Link>;
      },
      RentOrderDescription: (_text: string, record: IRent) => {
        let text = getLastItem<RentNote>(record.notes)?.note || "-";
        return (
          <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: text }}>
            {text}
          </Typography.Text>
        );
      },
      AssetName: (text: string, record: IRent) => {
        if (record.asset?.is_active === false) {
          return <Typography.Text>{record.asset.name}</Typography.Text>;
        }
        return (
          <PreviewButton
            isActive={record.asset?.is_active}
            title={record.asset?.name}
            id={record.asset?.id}
            url={`/app/asset-details/${record.asset?.id}/`}
            permissionsRequired={["view_asset"]}
            onClick={() => {
              set_selectedAsset(record.asset?.id);
            }}
          />
        );
      },
      RentOrderStatus: (text: string, record: IRent) => {
        return <Typography.Text>{t(TRANSLATION_KEY[record.status])}</Typography.Text>;
      },
      RentoOrderLocation: (text: string, record: IRent) => {
        return <Typography.Text>{record.location?.name || "-"}</Typography.Text>;
      },
      RentOrderCreatedBy: (text: string, record: IRent) => {
        return (
          <div>
            {record.created_by ? (
              <>
                <PreviewButton
                  isActive={record.created_by.is_active}
                  title={record.created_by.name}
                  id={record.created_by.id}
                  url={`/app/humanresources/${record.created_by.id}`}
                  permissionsRequired={["manage_user"]}
                  onClick={() => {
                    record.created_by && set_selectedAccount(record.created_by.id);
                  }}
                />
                <Typography.Text type="secondary">
                  {moment(record.created_at).format(user.account.date_format || "LLL")}
                </Typography.Text>
              </>
            ) : (
              <Typography.Text>-</Typography.Text>
            )}
          </div>
        );
      },
      RentOrderConfirmedBy: (text: string, record: IRent) => {
        if (!record.closed_by) {
          return <Typography.Text type="secondary">-</Typography.Text>;
        }
        return (
          <div>
            <PreviewButton
              isActive={record.closed_by.is_active}
              title={record.closed_by.name}
              id={record.closed_by.id}
              url={`/app/humanresources/${record.closed_by.id}`}
              permissionsRequired={["manage_user"]}
              onClick={() => {
                set_selectedAccount(record.closed_by?.id);
              }}
            />
            <Typography.Text type="secondary">
              {moment(record.closed_at).format(user.account.date_format || "LLL")}
            </Typography.Text>
          </div>
        );
      },
      RentOrderAction: (text: string, record: IRent) => {
        return (
          <Space>
            <Popconfirm
              onConfirm={() => {
                closeRentOrder(record);
              }}
              title={t(TRANSLATION_KEY.clientAssetRentDeleteMessage)}
            >
              <Button
                style={{ visibility: record.closed_at ? "hidden" : undefined }}
                loading={closeLoading === record.id}
                type="link"
              >
                <CheckOutlined style={{ fontSize: 18 }} />
              </Button>
            </Popconfirm>

            <Popconfirm
              onConfirm={() => {
                deleteRentOrder(record);
              }}
              title={t(TRANSLATION_KEY.continueWithAction)}
            >
              <Button danger loading={delLoading === record.id} type="link">
                <DeleteOutlined style={{ fontSize: 18 }} />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    };
    return tmp[key];
  };

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    // ostaje unutar komponente za koju se prave views-ovi iz razloga jer će trebati filtere okidati
    let tmp: Array<CustomTableColumn & TableColumnType<IRent>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              "rent_order_custom_fields_v2",
            ),
        onCell: (record: IRent) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  const debouncedGetClients = useCallback(
    debounce((filters: IFilters, _mergeData: boolean) => {
      getRents(filters, _mergeData);
    }, 600),
    [],
  );

  function onSearch(value: string) {
    // When searching by name, we need to reset the offset and limit
    debouncedGetClients({ ...filters, search: value }, false);
  }

  return (
    <>
      {/* Tabs */}
      <ViewTabs
        hideDivider
        onChange={(activeKey) => {
          let index = tableDefinition.findIndex((view) => view.name === activeKey);
          let tmpList = [...tableDefinition];
          if (index === -1) {
            message.error("view_onSaveColumnsDefinition");
            return;
          }
          tmpList = tmpList.map((x) => ({ ...x, active: false }));
          tmpList[index] = { ...tmpList[index], active: true };
          console.log("Dohvati klijente");
          setColumns(tmpList[index].table_structure, index);
          set_tableDefinition(tmpList);
        }}
        setColumns={setColumns}
        views={tableDefinition}
        viewCategory="clients"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Columns
            columns={COLUMNS}
            set_COLUMNS={(columns) => {
              let index = tableDefinition.findIndex((view) => view.active === true);
              if (index === -1) {
                message.error("view");
                return;
              }
              let tmp = tableDefinition[index];
              if (!tmp) {
                message.error("view2");
                return;
              }

              let inerTmp: CustomTableColumn[] = [];
              columns.forEach((x) => {
                inerTmp.push({
                  title: x.title || "",
                  dataIndex: x.dataIndex || "",
                  visible: x.visible === undefined ? 1 : x.visible,
                  onCellFlag: x.onCellFlag || false,
                  filterComponent: x.filterComponent || null,
                  columnRenderComponent: x.columnRenderComponent || null,
                  objectKey: x.objectKey || null,
                });
              });
              tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
              let tmpTableDefinition = [...tableDefinition].map((x) => ({ ...x, active: false }));
              tmpTableDefinition[index] = tmp;
              setColumns(inerTmp, index);
              set_tableDefinition(tmpTableDefinition);
            }}
          />

          <Button
            style={{ marginLeft: 18 }}
            type="link"
            onClick={() => {
              let activeView = tableDefinition.find((x) => x.active);
              saveTableDefinition(
                tableDefinition,
                user.account.views?.rent_order?.find((x) => x.id === activeView?.id),
                "rent_order",
              );
            }}
          >
            {t(TRANSLATION_KEY.saveChanges)}
          </Button>
        </div>

        {!firstMount && (
          <div
            style={{
              display: "flex",
            }}
          >
            <Select
              style={{ marginRight: 18, minWidth: 120 }}
              defaultValue={filters.status}
              onChange={(s) => {
                getRents(
                  {
                    ...filters,
                    status: s,
                  },
                  false,
                );
              }}
            >
              <Select.Option value="all">{t(TRANSLATION_KEY.all)}</Select.Option>
              <Select.Option value="active">{t(TRANSLATION_KEY.active)}</Select.Option>
              <Select.Option value="inactive">{t(TRANSLATION_KEY.inactive)}</Select.Option>
            </Select>
            <Input.Search
              defaultValue={filters.search}
              onChange={({ target: { value } }) => {
                let search = value?.toLocaleLowerCase() || "";
                onSearch(search);
              }}
              allowClear
            />
          </div>
        )}
      </div>

      <Table
        style={{ marginTop: 18 }}
        size="small"
        rowKey={(item) => item.id}
        dataSource={rents}
        columns={filterColumns<IRent>(COLUMNS)}
        pagination={false}
        loading={getRentedAssetsStatus === "loading"}
      />

      {hasMore && (
        <LoadMoreButton
          loading={getRentedAssetsStatus === "loading"}
          onClick={() => {
            getRents(filters, true);
          }}
        >
          {t(TRANSLATION_KEY.loadMore)}
        </LoadMoreButton>
      )}

      <Drawer
        destroyOnClose
        title={t(TRANSLATION_KEY.newRentOrder)}
        width={540}
        onClose={() => {
          set_addNew(undefined);
        }}
        visible={!!addNew}
      >
        <RentForm_v2
          onClose={() => {
            set_addNew(undefined);
          }}
        />
      </Drawer>

      <Modal
        visible={selectedAsset !== undefined || selectedAccount !== undefined}
        onCancel={() => {
          set_selectedAsset(undefined);
          set_selectedAccount(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
      </Modal>
    </>
  );
};

export default AllRents;
