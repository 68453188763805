import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  TreeSelect,
  Typography,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import FilesComponent, { IFile } from "../components/Files";
import {
  IMAGE_TYPES,
  INPUT_NUMBER_FORMATTER,
  MATERIAL_EXTENSIONS,
  TRANSLATION_KEY,
} from "../helpers/consts";
import {
  compressImage,
  createTreeSelect,
  getAllPartStoreagesForSelect,
} from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IPart, ITreeSelectItem } from "../models/parts";
import { maintenanceSlice } from "../store/reducers/maintenance";
import { warehouseSlice } from "../store/reducers/warehouse";
import { spendPartXHR } from "../store/reducers/warehouse/actionCreator";
import { CustomFieldValues } from "../models/settings";
import form from "antd/lib/form";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";

export interface ISpendPart {
  order_info: number | null;
  part: number;
  location: number;
  qty: number;
  note: string;
  name: string;
  custom_fields_v2: CustomFieldValues;
}

interface IProps {
  selectedPart: IPart;
  maintenance: number | null;
  close: () => void;
}

interface IForm {
  name: string;
  qty: number;
  note: string;
  location: number;
}

const SpendPartForm: React.FC<IProps> = ({ selectedPart, maintenance, close }) => {
  const [partSpendForm] = Form.useForm();

  const dispatch = useAppDispatch();
  const { maintenanceDetails } = useAppSelector((state) => state.maintenanceReducer);
  const [selectedLocation, set_selectedLocation] = useState<number | null>(null);
  const { parts, spendPartStatus } = useAppSelector((state) => state.warehouseReducer);
  const { assetList } = useAppSelector((state) => state.assetReducer);
  const [spendWithPartFiles, set_spendWithPartFiles] = useState<RcFile[]>([]);
  const [spendWithPartPreviewFiles, set_spendWithPartPreviewFiles] = useState<IFile[]>([]);
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);

  useEffect(() => {
    if (selectedPart.part_storage.length === 1) {
      partSpendForm.setFieldsValue({ location: selectedPart.part_storage[0].storage.id });
      set_avaliableQuantity(selectedPart.part_storage[0].qty || 0);
      set_selectedLocation(selectedPart.part_storage[0].storage.id);
    }
  }, []);

  let warehouses: ITreeSelectItem[] = getAllPartStoreagesForSelect(assetList, selectedPart);

  const tree = createTreeSelect(
    warehouses.map((wrh) => ({ ...wrh, title: wrh.path || wrh.title })),
    null,
  );

  const [avaliableQuantity, set_avaliableQuantity] = useState<number>(0);

  function spendWithPartBeforeUpload(file: RcFile, files: RcFile[]) {
    // Setting files
    set_spendWithPartFiles(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_spendWithPartPreviewFiles(tmpfiles);

    return false;
  }

  function spendWithPartOnRemove(id: any): void {
    // Find index
    let index = spendWithPartFiles.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...spendWithPartFiles];
    files_tmp.splice(index, 1);
    set_spendWithPartFiles(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...spendWithPartFiles];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_spendWithPartPreviewFiles(tmpfiles);
  }

  function onSpendWithPart(values: IForm) {
    // Form object
    const form: ISpendPart = {
      location: values.location,
      part: selectedPart.id,
      order_info: maintenance,
      name: values.name,
      note: values.note,
      qty: values.qty,
      custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.spend),
    };

    // Form data
    let formData = new FormData();
    formData.append("data", JSON.stringify(form));

    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = spendWithPartFiles.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    // Call axios after all requests
    requests.then(() =>
      spendPartXHR(
        {
          body: formData,
          errorCallback: (err: any) => {
            if (err.response.data?.message?.custom_field) {
              message.error(
                t(err.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  err.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
            console.log(err);
          },
          successCallback: (data) => {
            if (data.results) {
              let tmp = maintenanceDetails;
              let arr = [...tmp.parts];
              arr.push(data.results);
              unstable_batchedUpdates(() => {
                close();
                partSpendForm.resetFields();
                dispatch(
                  maintenanceSlice.actions.getMaintenanceSuccess({
                    message: "",
                    results: {
                      ...tmp,
                      parts: arr,
                    },
                  }),
                );
                if (selectedPart) {
                  updateQuantityAfterSpendWithPart(+values.location, selectedPart, +values.qty);
                }
              }, []);
            }
          },
        },
        dispatch,
      ),
    );
  }

  const updateQuantityAfterSpendWithPart = (
    storageLocationId: number,
    part: IPart,
    qty: number,
  ) => {
    let tmp = [...parts.data];
    let part_storage_index = part.part_storage.findIndex((x) => x.storage.id === storageLocationId);
    let part_storage = part.part_storage[part_storage_index];
    if (part_storage_index === -1 || !part_storage || !part || !qty) {
      return;
    }

    let new_quantity = +part_storage.qty - qty;
    let partIndex = tmp.findIndex((x) => x.id === part.id);
    let part_storages = [...part?.part_storage];
    part_storages[part_storage_index] = {
      ...part_storage,
      qty: new_quantity,
    };

    tmp[partIndex] = {
      ...part,
      part_storage: part_storages,
    };

    dispatch(
      warehouseSlice.actions.getPartsSuccess({
        mergeData: false,
        message: "",
        results: {
          cursor: { ...parts.cursor },
          data: tmp,
        },
      }),
    );
  };

  return (
    <Form
      form={partSpendForm}
      initialValues={{ name: selectedPart.name }}
      layout="vertical"
      onFinish={onSpendWithPart}
    >
      <Form.Item
        name="location"
        label={t(TRANSLATION_KEY.warehouseLocation)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <TreeSelect
          onChange={(id: string | undefined) => {
            set_selectedLocation(id === undefined ? null : +id);
            if (id) {
              let item = selectedPart?.part_storage.find((x) => x.storage.id === +id);
              set_avaliableQuantity(item?.qty || 0);
            }
          }}
          treeData={tree}
          showSearch
          filterTreeNode={(search, item: any) => {
            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          }}
        />
      </Form.Item>
      <Form.Item style={{ width: "100%" }} name="note" label={t(TRANSLATION_KEY.note)}>
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item
        style={{ width: "100%" }}
        name="name"
        label={t(TRANSLATION_KEY.name)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <Input disabled={!!selectedPart} />
      </Form.Item>

      <Form.Item
        name="qty"
        label={
          <div className="spaceBetweenRow">
            <div>{t(TRANSLATION_KEY.qty)}</div>{" "}
            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
              {avaliableQuantity || ""}{" "}
              {avaliableQuantity && (selectedPart?.uom ? t(selectedPart?.uom) : "")}
            </Typography.Text>
          </div>
        }
        hasFeedback
        rules={[
          {
            required: true,
            message: t(TRANSLATION_KEY.filedRequired),
          },
          () => ({
            validator(_, value) {
              if (value <= +avaliableQuantity) return Promise.resolve();
              return Promise.reject(new Error(t(TRANSLATION_KEY.spendPartQtyError)));
            },
          }),
        ]}
      >
        <InputNumber
          disabled={selectedLocation === null}
          style={{ width: "100%" }}
          {...INPUT_NUMBER_FORMATTER}
        />
      </Form.Item>

      {companyCustomFieldsV2 && (
        <GenerateForCustomFieldsV2
          values={{}}
          condition={undefined}
          customFields={companyCustomFieldsV2.spend}
          form={partSpendForm}
        />
      )}

      <Form.Item>
        <Button
          style={{ float: "right" }}
          htmlType="submit"
          type="primary"
          loading={spendPartStatus === "loading"}
        >
          {t(TRANSLATION_KEY.save)}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SpendPartForm;
