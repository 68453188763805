import { clientsSlice } from ".";
import { AppDispatch } from "../..";
import { CreateClient } from "../../../componentsform/ClientForm";
import { IRentBody, IRentBody_V2 } from "../../../componentsform/RentForm";
import { IApiResponse, IDataToApi } from "../../../models";
import { BaseListItem, IAsset } from "../../../models/asset";
import { IClient, IClientDetails, IRent, IRentDetails, IRentUpdate } from "../../../models/clients";
import api, { failedQueue, isRefreshing } from "../../../services";

let isProjectsAPICallRunning = false;
const projectsRequestQueue: Function[] = [];
let projectsResponseDataCollection: IClient[] = [];

export const getClientsXHR = async (
  data: Partial<
    IDataToApi<null, IApiResponse<IClient[]>> & {
      mergeData: boolean;
      noSaveToStore: boolean | undefined;
    }
  >,
  dispatch: AppDispatch,
) => {
  const makeApiCalls = async () => {
    isProjectsAPICallRunning = true;

    const token = await localStorage.getItem("token");

    try {
      dispatch(clientsSlice.actions.getClients({ loading: data.loading || "loading" }));
      let response = await api.get<IApiResponse<IClient[]>>(`clients/clients`, {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      });

      if (response.data.results) {
        projectsResponseDataCollection = response.data.results;
      }

      if (data.successCallback) {
        data.successCallback(response.data);
      }
      if (projectsRequestQueue.length === 0) {
        dispatch(
          clientsSlice.actions.getClientsSuccess({
            message: response.data.message,
            results: response.data.results,
            mergeData: data.mergeData || false,
            noSaveToStore: data.noSaveToStore || false,
          }),
        );
      }
    } catch (error: any) {
      //lkl
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getClientsXHR(data, dispatch));
        }
        return;
      }
      if (data.errorCallback) {
        data.errorCallback(error);
      }

      dispatch(clientsSlice.actions.getClientsFail(error.message));
    } finally {
      isProjectsAPICallRunning = false;

      // Process the next request in the queue
      if (projectsRequestQueue.length > 0) {
        const nextRequest = projectsRequestQueue.shift();
        nextRequest && nextRequest();
      } else {
        // Clean up the projectsResponseDataCollection after the last request
        projectsResponseDataCollection = [];
      }
    }
  };
  if (isProjectsAPICallRunning) {
    // Add the request to the queue if an API call is already running
    projectsRequestQueue.push(() => makeApiCalls());
  } else {
    makeApiCalls(); // Execute the API call immediately
  }
};

export const createClientXHR = async (
  data: Partial<IDataToApi<CreateClient, IApiResponse<IClient>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.createClient());
    let response = await api.post<IApiResponse<IClient>>(
      `clients/clients/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.createClientSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createClientXHR(data, dispatch));
      }
      return;
    }

    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.createClientFail(error.message));
  }
};

export const getClientXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IClientDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.getClient());
    let response = await api.get<IApiResponse<IClientDetails>>(`clients/clients/${data.id}/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.getClientSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getClientXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.getClientFail(error.message));
  }
};

export const rentAssetXHR = async (
  data: Partial<IDataToApi<IRentBody | IRentBody_V2, IApiResponse<BaseListItem[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(clientsSlice.actions.rentAsset());
    let response = await api.post<IApiResponse<BaseListItem[]>>(
      `clients/rent_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.rentAssetSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => rentAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.rentAssetFail(error.message));
  }
};

export const rentAssetXHR_v2 = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IRent>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(clientsSlice.actions.rentAsset());
    let response = await api.post<IApiResponse<IRent>>(
      `clients/rent_order/v2/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.rentAssetSuccess_v2(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => rentAssetXHR_v2(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.rentAssetFail(error.message));
  }
};

let isAPICallRunning = false;
const requestQueue: Function[] = [];
let responseDataCollection: IApiResponse<IRent[]>[] = [];

export const getRentedAssetsXHR = async (
  data: Partial<
    IDataToApi<null, IApiResponse<IRent[]>> & {
      mergeData: boolean;
      noSaveToStore: boolean | undefined;
    }
  >,
  dispatch: AppDispatch,
) => {
  const makeAPICall = async () => {
    try {
      isAPICallRunning = true;
      dispatch(clientsSlice.actions.getRentedAssets(data.loading || "loading"));
      const token = await localStorage.getItem("token");
      let response = await api.get<IApiResponse<IRent[]>>(`clients/rent_order`, {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      });

      if (data.successCallback) {
        data.successCallback(response.data);
      }
      responseDataCollection.push(response.data);

      dispatch(
        clientsSlice.actions.getRentedAssetsSuccess({
          ...response.data,
          mergeData: data.mergeData || false,
          noSaveToStore: data.noSaveToStore || false,
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getRentedAssetsXHR(data, dispatch));
        }
        return;
      }
      if (data.errorCallback) {
        data.errorCallback(error);
      }
      dispatch(clientsSlice.actions.getRentedAssetsFail(error.message));
    } finally {
      isAPICallRunning = false;

      // Process the next request in the queue
      if (requestQueue.length > 0) {
        const nextRequest = requestQueue.shift();
        nextRequest && nextRequest();
      }
    }
  };

  if (isAPICallRunning) {
    // Add the request to the queue if an API call is already running
    requestQueue.push(() => makeAPICall());
  } else {
    makeAPICall(); // Execute the API call immediately
  }
};

export const closeRentOrderXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IRentDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.closeRentOrder());
    let response = await api.put<IApiResponse<IRentDetails>>(
      `clients/rent_order/${data.id}/close_rent_order/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.closeRentOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => closeRentOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.closeRentOrderFail(error.message));
  }
};

export const getAssetToRentXHRXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IAsset[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.getAssetToRentXHR());
    let response = await api.get<IApiResponse<IAsset[]>>(`assets/assets/unrented_assets/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.getAssetToRentXHRSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getAssetToRentXHRXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.getAssetToRentXHRFail(error.message));
  }
};

export const updateClientXHR = async (
  data: Partial<IDataToApi<Partial<CreateClient>, IApiResponse<IClientDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.updateClient());
    let response = await api.put<IApiResponse<IClientDetails>>(
      `clients/clients/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.updateClientSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateClientXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.updateClientFail(error.message));
  }
};

export const getAssetRentOrdersXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IRent[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.getAssetRentOrders());
    let response = await api.get<IApiResponse<IRent[]>>(
      `assets/assets/${data.id}/asset_rent_orders/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.getAssetRentOrdersSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getAssetRentOrdersXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.getAssetRentOrdersFail(error.message));
  }
};

export const getClientAssetXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<BaseListItem[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.getClientAsset());
    let response = await api.get<IApiResponse<BaseListItem[]>>(`clients/list_assets`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.getClientAssetSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getClientAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.getClientAssetFail(error.message));
  }
};

export const getAvailableClientAssetXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<BaseListItem[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.getAvailableClientAsset());
    let response = await api.get<IApiResponse<BaseListItem[]>>(`clients/list_assets`, {
      params: {
        ...data.queryParams,
        client_asset_type: "available",
      },
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.getAvailableClientAssetSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getClientAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.getAvailableClientAssetFail(error.message));
  }
};

export const deleteRemtOrderXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.delete<IApiResponse<string>>(
      `/clients/rent_order/${data.id}/`,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => deleteRemtOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};

// -----

export const updateRentOrderDescriptionXHR = async (
  data: Partial<IDataToApi<{ description: string }, IApiResponse<IRent>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.updateRentOrderDescription());
    let response = await api.put<IApiResponse<IRent>>(
      `clients/rent_order/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.updateRentOrderDescriptionSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateRentOrderDescriptionXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.updateRentOrderDescriptionFail(error.message));
  }
};

export const getRentOrderDetailsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IRentDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.getRentOrderDetails());
    let response = await api.get<IApiResponse<IRentDetails>>(`clients/rent_order/${data.id}/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.getRentOrderDetailsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getRentOrderDetailsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.getRentOrderDetailsFail(error.message));
  }
};

export const createRentNoteXHR = async (
  data: Partial<
    IDataToApi<
      {
        note: string;
      },
      IApiResponse<IRentDetails>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.createRentNote());
    let response = await api.post<IApiResponse<IRentDetails>>(
      `clients/rent_order/v2/${data.id}/add_note/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.createRentNoteSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createRentNoteXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.createRentNoteFail(error.message));
  }
};

export const updateRentNoteXHR = async (
  data: Partial<
    IDataToApi<
      {
        note: string;
      },
      IApiResponse<IRentDetails>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.updateRentNote());
    let response = await api.put<IApiResponse<IRentDetails>>(
      `clients/rent_order/v2/${data.id}/update_note/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.updateRentNoteSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateRentNoteXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.updateRentNoteFail(error.message));
  }
};

export const updateRentOrderXHR = async (
  data: Partial<IDataToApi<Partial<IRentUpdate>, IApiResponse<IRentDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(clientsSlice.actions.updateRentOrder());
    let response = await api.put<IApiResponse<IRentDetails>>(
      `clients/rent_order/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(clientsSlice.actions.updateRentOrderSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateRentOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(clientsSlice.actions.updateRentOrderFail(error.message));
  }
};
