import { useEffect, useState } from "react";
// UI
import { Button, Divider, message, Modal, Space, Tag, Typography } from "antd";
import Commands from "../components/Comands";
import i18next, { t } from "i18next";
import {
  I18N_LANGUAGES,
  Languages,
  MOMENT_LANGUAGES,
  TRANSLATION_KEY,
} from "../../../helpers/consts";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import MaintenanceSpecContent from "../../maintenance/pages/MaintenanceSpec/MaintenanceSpecContent";
import { getOpenOrderXHR } from "../../../store/reducers/supplier/actionCreator";
import { useParams } from "react-router-dom";
import Timer from "../../maintenance/components/Timer";
import { FieldTimeOutlined } from "@ant-design/icons";
import AddWorkingTime from "../../maintenance/components/AddWorkingTime";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { settingsSlice } from "../../../store/reducers/settings";
import { assetSlice } from "../../../store/reducers/asstes";
import MaintenanceInfo from "../../maintenance/components/MaintenanceInfo";
import StartStop from "../../maintenance/components/StartStop";
import { Breakpoint } from "antd/es/_util/responsiveObserve";
import { userSlice } from "../../../store/reducers/user";
import moment from "moment";

type IProps = {};

export type OpenOrderColumns = {
  [breakpoint in Breakpoint]: number;
};

export const MOB_SIZE = 426;

const Order = (props: IProps) => {
  // Constants
  const OPEN_ORDER_GRIDS = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
    xl: 12,
    xxl: 12,
  };

  const OPEN_ORDER_COLUMNS: OpenOrderColumns = {
    xxl: 3,
    xl: 3,
    lg: 3,
    md: 2,
    sm: 2,
    xs: 1,
  };

  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [commandsVisible, set_commandsVisible] = useState<boolean>(false);
  const { token } = useParams();
  const { openOrder } = useAppSelector((state) => state.supplierReducer);
  const [addWorkingTime, set_addWorkingTime] = useState<boolean>();

  const getOrder = async () => {
    if (!token) return;
    let c = await localStorage.getItem(token);
    getOpenOrderXHR(
      {
        queryParams: {
          open_order_token: token,
          open_order_code: c,
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback: ({ results }) => {
          if (results) {
            dispatch(
              maintenanceSlice.actions.getMaintenancesCategoriesSuccess({
                message: "",
                results: results.maintenance_categories || [],
              }),
            );
            if (results.maintenance_type) {
              dispatch(
                maintenanceSlice.actions.getMaintenancesTypesSuccess({
                  message: "",
                  results: [results.maintenance_type],
                }),
              );
            }
            if (results.location) {
              dispatch(
                settingsSlice.actions.getLocationsSuccess({
                  message: "",
                  results: [results.location],
                }),
              );
            }
            dispatch(
              assetSlice.actions.getSubAssetsSuccess({ message: "", results: results.subassets }),
            );
          }
        },
      },
      dispatch,
    );
  };

  useEffect(() => {
    getOrder();
  }, []);

  const supplierStartTime = openOrder?.flows.find((x) => x.start && !!x.executor?.supplier)?.start;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ paddingBottom: 60 }}
    >
      {/* Header */}
      <div
        className={window.innerWidth < MOB_SIZE ? "" : "spaceBetweenRow"}
        style={{
          display: "flex",
          flexDirection: window.innerWidth < MOB_SIZE ? "column" : "row",
          width: "100%",
        }}
      >
        {/* Left side */}
        <Space direction="vertical">
          <div>
            <Typography.Text type="secondary">{t(TRANSLATION_KEY.maintenance)} </Typography.Text>
            <Typography.Text strong>#{openOrder?.order_number}</Typography.Text>
          </div>
          <Typography.Title
            style={{
              marginBottom: 0,
              maxWidth: window.innerWidth < MOB_SIZE ? window.innerWidth - 20 : "unset",
            }}
            level={3}
          >
            {openOrder?.description}
          </Typography.Title>
        </Space>

        {/* Right side */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: window.innerWidth < MOB_SIZE ? "space-between" : "center",
            width: window.innerWidth < MOB_SIZE ? "100%" : "max-content",
            marginTop: window.innerWidth < MOB_SIZE ? 12 : 0,
          }}
        >
          {window.innerWidth < MOB_SIZE && <div style={{ height: 24 }} />}
          {supplierStartTime && openOrder.status === "running" && (
            <Timer startTime={supplierStartTime} scale={0.8} transformOrigin="left" />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {/* Commands */}
            <Button
              style={{ margin: "0 6px" }}
              onClick={() => set_addWorkingTime(true)}
              icon={<FieldTimeOutlined />}
            />

            <div style={{ marginBottom: -12 }}>
              {openOrder &&
                openOrder?.status !== "cancelled" &&
                openOrder?.status !== "closed" &&
                openOrder?.status !== "completed" && (
                  <StartStop maintenanceDetails={openOrder} isOpenOrder={true} />
                )}
            </div>
            {window.innerWidth >= MOB_SIZE && <div style={{ width: 30 }} />}
          </div>
        </div>
      </div>

      <Divider />

      {openOrder && (
        <div className="custom-page-header">
          {/* Basic informations */}
          <MaintenanceInfo
            user={null}
            columns={OPEN_ORDER_COLUMNS}
            grids={OPEN_ORDER_GRIDS}
            isOpenOrder={true}
            maintenanceDetails={openOrder}
            block={window.innerWidth < MOB_SIZE}
          />
          {/* Detailed informations */}
          <MaintenanceSpecContent
            isOpenOrder={true}
            grids={OPEN_ORDER_GRIDS}
            maintenanceDetails={openOrder}
            block={window.innerWidth < MOB_SIZE}
          />
        </div>
      )}

      <Modal
        footer={null}
        width={700}
        destroyOnClose={true}
        visible={addWorkingTime}
        onCancel={() => set_addWorkingTime(undefined)}
        title={t(TRANSLATION_KEY.workingTime)}
      >
        {openOrder && (
          <AddWorkingTime
            maintenanceDetails={openOrder}
            isOpenOrder={true}
            close={() => set_addWorkingTime(undefined)}
          />
        )}
      </Modal>

      {/* Commands modal */}
      <Modal
        footer={null}
        width={540}
        destroyOnClose={true}
        visible={commandsVisible}
        onCancel={() => set_commandsVisible(false)}
        title={t(TRANSLATION_KEY.comands)}
      >
        <Commands onClose={() => set_commandsVisible(false)} />
      </Modal>
    </motion.div>
  );
};

export default Order;
