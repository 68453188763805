import {
  Form,
  InputNumber,
  Button,
  Space,
  Select,
  message,
  Spin,
  List,
  DatePicker,
  Typography,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons/lib/icons";
import { useEffect, useState } from "react";
import {
  addWorkingTimeXHR,
  getPotentialExecutorsXHR,
  getWorkingCostsXHR,
} from "../../../store/reducers/maintenance/actionCreator";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { EXECUTOR_TYPES } from "../../../models";
import { unstable_batchedUpdates } from "react-dom";
import {
  IAddWorkingTime,
  IAddWorkingTimeToOpenOrder,
  IMaintenanceDetails,
  IPotentialExecutors,
} from "../../../models/maintenances";
import AddWorkingTimeCard from "./AddWorkingTimeCard";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { useParams } from "react-router-dom";
import {
  addWorkingTImeToOpenOrderXHR,
  deleteWorkingTimeOnOpenOrderXHR,
} from "../../../store/reducers/supplier/actionCreator";
import { supplierSlice } from "../../../store/reducers/supplier";
import moment, { Moment } from "moment";
import api, { failedQueue, isRefreshing } from "../../../services";
import { MOB_SIZE } from "../../openorder/components/Order";
import { hasPermission } from "../../../helpers/functions";

const { OptGroup, Option } = Select;

interface IProps {
  close: () => void;
  isOpenOrder: boolean;
  maintenanceDetails: IMaintenanceDetails;
}
interface IForm {
  executors: string[] | string;
  hours: number;
  minutes: number;
  start: Moment;
}
const AddWorkingTime: React.FC<IProps> = ({ close, isOpenOrder, maintenanceDetails }) => {
  const dispatch = useAppDispatch();

  const { potentialExecutors, getPotentialExecutorsStatus, addWorkingTimeStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  const { user } = useAppSelector((state) => state.userReducer);

  const [selectedExecutor, set_selectedExecutor] = useState<string>(); // kada se odabere executor ukoliko je drugog tipa mora se resetirat input i postavit zadnji odabrani
  const [executorType, set_executorType] = useState<EXECUTOR_TYPES>("employee");
  const open_order_token = useParams().token;
  const [localPotentialExecutors, set_localPotentialExecutors] = useState<IPotentialExecutors>();
  const [hasManageWOPermission, set_hasManageWOPermission] = useState<boolean>(
    hasPermission(user?.account.permissions || [], ["manage_wo"]),
  );

  const [form] = Form.useForm();

  const [delLoading, set_delLoading] = useState(-1);

  const [flows, set_flows] = useState(maintenanceDetails.flows || []); // flows su working time-ovi

  console.log(maintenanceDetails, "KKK");

  useEffect(() => {
    set_flows(maintenanceDetails.flows || []);
  }, [maintenanceDetails]);

  const onDelete = async (id: number) => {
    set_delLoading(id);

    if (isOpenOrder) {
      let code = await localStorage.getItem(open_order_token || "");
      if (!code || !open_order_token) {
        return;
      }

      deleteWorkingTimeOnOpenOrderXHR(
        {
          errorCallback: () => {
            message.error(t(TRANSLATION_KEY.errorOnDeleteData));
            set_delLoading(-1);
          },
          id: id,
          body: { code, token: open_order_token },
          successCallback: (data) => {
            let arr = [...flows];
            let index = arr.findIndex((x) => x.id === id);
            arr.splice(index, 1);
            dispatch(
              supplierSlice.actions.getOpenOrderSuccess({
                message: "",
                results: {
                  ...maintenanceDetails,
                  flows: arr,
                },
              }),
            );
            set_delLoading(-1);
          },
        },
        dispatch,
      );

      return;
    }

    try {
      let arr = [...flows];
      let index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);
      let userToken = await localStorage.getItem("token");
      await api.delete<string>(`maintenance/working_time/${id}/`, {
        headers: { Authorization: "Bearer " + userToken },
      });
      dispatch(
        maintenanceSlice.actions.getMaintenanceSuccess({
          message: "",
          results: {
            ...maintenanceDetails,
            flows: arr,
          },
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }

    set_delLoading(-1);
  };

  const onFinish = async (values: IForm) => {
    let supplier: null | number = null;
    let partner: null | number = null;
    let employees: null | number[] = null;

    switch (executorType) {
      case "employee":
        let e_executors = values.executors as string[] | undefined;
        employees = e_executors?.map((x) => +x.split("|")[0]) || [];
        break;
      case "partner":
        let p_executor = values.executors as string | undefined;
        partner = Number(p_executor?.split("|")[0]) || null;
        break;
      case "supplier":
        let s_executors = values.executors as string | undefined;
        supplier = Number(s_executors?.split("|")[0]) || null;
    }

    let body: IAddWorkingTime = {
      order_info: maintenanceDetails.id,
      duration: values.hours + ":" + values.minutes + ":" + "00",
      start: values.start.toISOString(true),
      executors: {
        supplier,
        employees,
        partner,
      },
    };

    if (isOpenOrder) {
      let code = await localStorage.getItem(open_order_token || "");
      if (!code || !open_order_token) {
        return;
      }

      let bodyOpenOrder: IAddWorkingTimeToOpenOrder = {
        duration: values.hours + ":" + values.minutes + ":" + "00",
        start: values.start.toISOString(true),
        executors: {
          supplier: null,
          employees: [],
          partner: null,
        },
        code,
        token: open_order_token,
      };

      addWorkingTImeToOpenOrderXHR(
        {
          body: bodyOpenOrder,
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
          successCallback: () => close(),
        },
        dispatch,
      );

      return;
    }

    addWorkingTimeXHR(
      {
        body,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          getWorkingCostsXHR(
            {
              id: maintenanceDetails.id,
              successCallback: () => {},
              errorCallback: () => {
                message.error(t(TRANSLATION_KEY.errorOnGetData));
              },
            },
            dispatch,
          );
          close();
        },
      },
      dispatch,
    );
  };

  useEffect(() => {
    if (isOpenOrder) {
      return;
    }
    getPotentialExecutorsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data) => {
          let tmp = data.results;
          //merge sa vec ostojecim ljudima na nalogu treba napravit
          set_localPotentialExecutors(tmp);
        },
        queryParams: {
          order_info: maintenanceDetails.id,
          asset: maintenanceDetails.asset?.id,
          include_executors: 1,
        },
      },
      dispatch,
    );
  }, []);

  useEffect(() => {
    if (!selectedExecutor) {
      return;
    }
    form.resetFields(["executors"]);
    if (executorType === "employee") {
      form.setFieldsValue({ executors: [selectedExecutor] });
    } else {
      form.setFieldsValue({ executors: selectedExecutor });
    }
  }, [executorType]);

  return (
    <>
      <Spin spinning={getPotentialExecutorsStatus === "loading"}>
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          initialValues={{ start: moment(), hours: 0, minutes: 0 }}
          autoComplete="off"
        >
          <>
            <Space
              direction={window.innerWidth < MOB_SIZE ? "vertical" : "horizontal"}
              style={{ display: "flex", marginBottom: 0, alignItems: "center" }}
              align="baseline"
            >
              <Form.Item name="start">
                <DatePicker format={user.account.date_format || "DD.MM.YYYY - HH:mm"} />
              </Form.Item>
              <Space direction="horizontal">
                <Form.Item
                  name={"hours"}
                  rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
                >
                  <InputNumber style={{ width: 99 }} addonAfter="h" min={0} />
                </Form.Item>
                <Form.Item
                  name={"minutes"}
                  rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
                >
                  <InputNumber style={{ width: 99 }} addonAfter="m" max={59} min={0} />
                </Form.Item>
              </Space>
              <Space direction="horizontal">
                <Form.Item name={"executors"}>
                  <Select
                    disabled={isOpenOrder}
                    maxTagCount="responsive"
                    style={{ width: 240 }}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (typeof option?.children === "string") {
                        const str: string = option.children;
                        return str.toLowerCase().includes(input.toLowerCase());
                      }
                      return false;
                    }}
                    showSearch={true}
                    mode={executorType === "employee" ? "multiple" : undefined}
                    onSelect={(val: string) => {
                      let type = val.split("|").pop();
                      unstable_batchedUpdates(() => {
                        set_selectedExecutor(val);
                        if (type === "e") {
                          set_executorType("employee");
                        } else if (type === "s") {
                          set_executorType("supplier");
                        } else {
                          set_executorType("partner");
                        }
                      }, []);
                    }}
                  >
                    <OptGroup label={t(TRANSLATION_KEY.employee)}>
                      {potentialExecutors.employees.map((x) => (
                        <Option key={x.id + "|e"} value={x.id + "|e"}>
                          {x.name}
                        </Option>
                      ))}
                    </OptGroup>

                    <OptGroup label={t(TRANSLATION_KEY.supplier)}>
                      {potentialExecutors.suppliers.map((x) => (
                        <Option key={x.id + "|s"} value={x.id + "|s"}>
                          {x.name}
                        </Option>
                      ))}
                    </OptGroup>

                    {/* <OptGroup label={TRANSLATION_KEY.partner}>
                        {potentialExecutors.partners.map((x) => (
                          <Option key={x.id + "|p"} value={x.id + "|p"}>
                            {x.name}
                          </Option>
                        ))}
                      </OptGroup> */}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={addWorkingTimeStatus === "loading"}
                    type="primary"
                    htmlType="submit"
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              </Space>
            </Space>
          </>
        </Form>
      </Spin>

      <List
        header={
          <div
            style={{
              display: flows.length > 0 ? "flex" : "none",
              justifyContent: "flex-start",
              gap: 8,
              alignItems: "center",
              marginTop: 12,
            }}
          >
            <Typography.Text strong style={{ width: 240 }}>
              {" "}
              {t(TRANSLATION_KEY.workingTime)}
            </Typography.Text>
            <Typography.Text strong style={{ width: 240 }}>
              {t(TRANSLATION_KEY.createdDate)}
            </Typography.Text>
            <Typography.Text strong style={{ width: 400 }}>
              {t(TRANSLATION_KEY.users)}
            </Typography.Text>
          </div>
        }
        dataSource={flows}
        renderItem={(item) => {
          return (
            <AddWorkingTimeCard
              isOpenOrder={isOpenOrder}
              onDelete={onDelete}
              loading={delLoading}
              item={item}
            />
          );
        }}
      />
    </>
  );
};

export default AddWorkingTime;
