import {
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  TableColumnType,
  Tooltip,
  Typography,
  message,
} from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePickerCustom, { CalendarTypes } from "../../../components/DatePickerCustom";
import PreviewButton from "../../../components/PreviewButton";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { debounce, getLastItem } from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IRent, IRentStatus, RentNote } from "../../../models/clients";
import {
  closeRentOrderXHR,
  deleteRemtOrderXHR,
  getRentedAssetsXHR,
  updateRentOrderDescriptionXHR,
} from "../../../store/reducers/clients/actionCreators";
import AccountModalPreview from "../../account/components/AccountModalPreview";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import { clientsSlice } from "../../../store/reducers/clients";
import { Link, useParams } from "react-router-dom";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { CustomTableColumn } from "../../../components/ViewTabs";
import { ILoadMoreParams } from "../../../models";

export interface IRentedAssetsFilters {
  status: IRentStatus[];
  search: string;
  pickerType: CalendarTypes;
}
export const LOCAL_STORAGE_KEY = "rentsFilters";

type TableRenders =
  | "RentOrderCustomId"
  | "AssetName"
  | "RentOrderStatus"
  | "RentoOrderLocation"
  | "RentOrderDescription"
  | "RentOrderCreatedBy"
  | "RentOrderConfirmedBy"
  | "RentOrderAction";

const LIMIT = 30;

const Rents: React.FC = () => {
  // Variables
  const { client } = useAppSelector((state) => state.clientsReducer);
  const { getRentedAssetsStatus, rents, updateRentOrderDescriptionStatus } = useAppSelector(
    (state) => state.clientsReducer,
  );
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const { user } = useAppSelector((state) => state.userReducer);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);

  const [search, set_search] = useState({
    text: "",
    rendered: false,
  });
  const [delLoading, set_delLoading] = useState(-1);
  const [closeLoading, set_closeLoading] = useState(-1);
  const [oneDescription, set_oneDescription] = useState<{ rent_id: number; text: string }>();
  const [visibleDatePickerCustom, set_visibleDatePickerCustom] = useState(false);
  const { id } = useParams();
  const [showLoadMore, set_showLoadMore] = useState(true);
  const [filters, set_filters] = useState<IRentedAssetsFilters>({
    status: [],
    search: "",
    pickerType: "month",
  });

  const textAreaRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  const onLoadMore = () => {
    getRentedAssetsXHR(
      {
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback: (res) => {
          if (res.results && res.results.length < LIMIT) {
            set_showLoadMore(false);
          } else {
            set_showLoadMore(true);
          }
        },
        id: client.id,
        mergeData: true,
        queryParams: {
          search: filters.search,
          status: filters.status.join("|"),
          limit: LIMIT,
          offset: rents.length,
          clients: client.id,
        },
      },
      dispatch,
    );
  };

  const getRentAsset = async (_filters: IRentedAssetsFilters, mergeData: boolean) => {
    if (client.id === 0) {
      return;
    }
    await localStorage.setItem(id + LOCAL_STORAGE_KEY + user.account.id, JSON.stringify(_filters));

    getRentedAssetsXHR(
      {
        errorCallback: (data: any) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          set_filters(_filters);
          set_visibleDatePickerCustom(true);
        },
        successCallback: (res) => {
          if (res.results && res.results.length < LIMIT) {
            set_showLoadMore(false);
          } else {
            set_showLoadMore(true);
          }
          set_visibleDatePickerCustom(true);
          set_filters(_filters);
        },
        mergeData,
        id: client.id,
        queryParams: {
          search: _filters.search,
          status: _filters.status.join("|"),
          limit: LIMIT,
          offset: 0,
          clients: client.id,
        },
      },
      dispatch,
    );
  };

  const deleteRentOrder = (order: IRent) => {
    set_delLoading(order.id);
    deleteRemtOrderXHR(
      {
        id: order.id,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          set_delLoading(-1);
        },
        successCallback: () => {
          let _rents = rents.filter((r) => r.id !== order.id);
          dispatch(
            clientsSlice.actions.getAssetRentOrdersSuccess({
              results: _rents,
              message: "",
            }),
          );
          set_delLoading(-1);
        },
      },
      dispatch,
    );
  };

  const closeRentOrder = (order: IRent) => {
    set_closeLoading(order.id);
    const fd = new FormData();
    fd.append("files", JSON.stringify([]));
    fd.append("data", JSON.stringify({ description: "" }));

    closeRentOrderXHR(
      {
        body: fd,
        id: order.id,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          set_closeLoading(-1);
        },
        successCallback: () => {
          set_closeLoading(-1);
        },
      },
      dispatch,
    );
  };

  const onLoad = async () => {
    let tmp: string | null | IRentedAssetsFilters = await localStorage.getItem(
      id + LOCAL_STORAGE_KEY + user.account.id,
    );
    if (tmp) {
      let tmpf = JSON.parse(tmp) as IRentedAssetsFilters;
      set_search({ text: tmpf.search, rendered: true });
      getRentAsset(tmpf, false);
    } else {
      getRentAsset(filters, false);
    }
  };

  useEffect(() => {
    onLoad();
  }, [client]);

  const _columns: TableColumnType<IRent>[] = [
    {
      title: t(TRANSLATION_KEY.rentCode),
      dataIndex: "custom_id",
      render: (text, record) => (
        <Link to={`/app/rentorder/${record.id}/`}>#{record.custom_id}</Link>
      ),
    },
    {
      title: t(TRANSLATION_KEY.asset),
      dataIndex: "asset",
      render: (text, record) => {
        if (record.asset?.is_active === false) {
          return <Typography.Text>{record.asset.name}</Typography.Text>;
        }
        return (
          <PreviewButton
            isActive={record.asset?.is_active}
            title={record.asset?.name}
            id={record.asset?.id}
            url={`/app/asset-details/${record.asset?.id}/`}
            permissionsRequired={["view_asset"]}
            onClick={() => {
              set_selectedAsset(record.asset?.id);
            }}
          />
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.status),
      dataIndex: "status",
      render: (text, record) => (
        <Typography.Text>{t(TRANSLATION_KEY[record.status])}</Typography.Text>
      ),
    },
    {
      title: t(TRANSLATION_KEY.location),
      dataIndex: "location",
      render: (text, record) => record.location?.name || "-",
    },
    {
      title: t(TRANSLATION_KEY.note),
      dataIndex: "description",
      render: (_text, record) => {
        let text = getLastItem<RentNote>(record.notes)?.note || "-";
        return (
          <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: text }}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.createdBy),
      dataIndex: "created_by",
      render: (text, value) => (
        <div>
          {value.created_by ? (
            <>
              <PreviewButton
                isActive={value.created_by.is_active}
                title={value.created_by.name}
                id={value.created_by.id}
                url={`/app/humanresources/${value.created_by.id}`}
                permissionsRequired={["manage_user"]}
                onClick={() => {
                  value.created_by && set_selectedAccount(value.created_by.id);
                }}
              />
              <Typography.Text type="secondary">
                {moment(value.created_at).format(user.account.date_format || "LLL")}
              </Typography.Text>{" "}
            </>
          ) : (
            <Typography.Text>-</Typography.Text>
          )}
        </div>
      ),
    },

    {
      title: t(TRANSLATION_KEY.confirmedBy),
      dataIndex: "closed_by",
      render: (text, value) => {
        if (!value.closed_by) {
          return <Typography.Text type="secondary">-</Typography.Text>;
        }
        return (
          <div>
            <PreviewButton
              isActive={value.closed_by.is_active}
              title={value.closed_by.name}
              id={value.closed_by.id}
              url={`/app/humanresources/${value.closed_by.id}`}
              permissionsRequired={["manage_user"]}
              onClick={() => {
                set_selectedAccount(value.closed_by?.id);
              }}
            />
            <Typography.Text type="secondary">
              {moment(value.closed_at).format(user.account.date_format || "LLL")}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <Space>
          <Popconfirm
            onConfirm={() => {
              closeRentOrder(record);
            }}
            title={t(TRANSLATION_KEY.clientAssetRentDeleteMessage)}
          >
            <Button
              style={{ visibility: record.closed_at ? "hidden" : undefined }}
              loading={closeLoading === record.id}
              type="link"
            >
              <CheckOutlined style={{ fontSize: 18 }} />
            </Button>
          </Popconfirm>

          <Popconfirm
            onConfirm={() => {
              deleteRentOrder(record);
            }}
            title={t(TRANSLATION_KEY.continueWithAction)}
          >
            <Button danger loading={delLoading === record.id} type="link">
              <DeleteOutlined style={{ fontSize: 18 }} />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const debouncedsGetRents = debounce(getRentAsset, 600);

  if (!client.id) return null;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <div style={{ width: 21 }} />
        <Tooltip title={t(TRANSLATION_KEY.serachByAssetOrRentalCode)}>
          <InfoCircleOutlined />
        </Tooltip>
        <div style={{ width: 12 }} />
        {search.rendered && (
          <Input.Search
            allowClear
            defaultValue={search.text}
            style={{ width: 210 }}
            onChange={(e) => {
              let _s = e.target.value?.toLocaleLowerCase() || "";
              // set_search(_s);
              debouncedsGetRents({ ...filters, search: _s }, false);
            }}
          />
        )}
      </div>
      <Table
        size="small"
        dataSource={rents}
        columns={_columns}
        loading={getRentedAssetsStatus === "loading"}
        pagination={false}
      />
      {showLoadMore && (
        <LoadMoreButton loading={getRentedAssetsStatus === "loading"} onClick={onLoadMore} />
      )}

      <Modal
        title={t(TRANSLATION_KEY.note)}
        visible={!!oneDescription}
        onCancel={() => {
          set_oneDescription(undefined);
        }}
        centered
        width={600}
        closable={true}
        okButtonProps={{ loading: updateRentOrderDescriptionStatus === "loading" }}
        destroyOnClose
        okText={t(TRANSLATION_KEY.save)}
        forceRender
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          updateRentOrderDescriptionXHR(
            {
              id: oneDescription?.rent_id || -1,
              body: {
                description: oneDescription?.text || "",
              },
              errorCallback: () => {
                message.error(t(TRANSLATION_KEY.errorOnSaveData));
              },
              successCallback: () => {
                set_oneDescription(undefined);
              },
            },
            dispatch,
          );
        }}
      >
        <Input.TextArea
          ref={textAreaRef}
          rows={6}
          value={oneDescription?.text}
          onChange={(v) => {
            set_oneDescription({
              rent_id: oneDescription?.rent_id || -1,
              text: v.target.value || "",
            });
          }}
        />
        <div />
      </Modal>

      <Modal
        visible={selectedAsset !== undefined || selectedAccount !== undefined}
        onCancel={() => {
          set_selectedAsset(undefined);
          set_selectedAccount(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
      </Modal>
    </div>
  );
};

export default Rents;
