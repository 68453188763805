import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse, ICursor } from "../../../models";
import {
  IAsset,
  IAssetList,
  ICategory,
  ICopyFilesToAssetsStatus,
  ISubAsset,
} from "../../../models/asset";
import { IAccount } from "../../../models/user";

interface IAssetReducer {
  categories: Array<ICategory>;
  getAssetCategoriesStatus: string;
  getAssetStatus: string;
  assetList: IAsset[];
  asset: IAsset;
  addAssetStatus: string;
  getSingleAssetStatus: string;
  editSingleAssetStatus: string;
  getAssetAccountsStatus: string;
  assetAccounts: IAccount[];
  joinAccountsToAssetStatus: string;
  copyFilesToAssetsStatus: string;
  subAssets: ISubAsset[];
  getSubAssetsStatus: string;
  addSubAssetStatus: string;
  editSubAssetStatus: string;
}

const initialState = {
  assetList: [],
  categories: [],
  getAssetCategoriesStatus: "",
  getAssetStatus: "",
  addAssetStatus: "",
  asset: {
    custom_fields_v2: {},
    base_buy_price: 0,
    base_currency: "",
    avatar: "",
    path: "",
    created_at: "",
    id: 0,
    custom_id: "",
    name: "",
    parent: null,
    description: "",
    autopilot: false,
    type: null,
    is_active: false,
    parent_id: 0,
    // hour_price: 0,
    // hour_price_currency: "",
    buy_price: 0,
    relevant_links: [],
    supplier: null,
    category: { id: 0, name: "", static: false, trans_key: "" },
    location: null,
    buy_price_currency: "",
    status: "",
    files: [],
    custom_fields: [],
    can_report_order: false,
    client_owner: null,
    rented_to_client: null,
    has_rent_orders: false,
  },
  assetAccounts: [],
  getSingleAssetStatus: "",
  editSingleAssetStatus: "",
  getAssetAccountsStatus: "",
  joinAccountsToAssetStatus: "",
  copyFilesToAssetsStatus: "",
  subAssets: [],
  getSubAssetsStatus: "",
  addSubAssetStatus: "",
  editSubAssetStatus: "",
} as IAssetReducer;

export const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    // -----
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    getAssetCategories(state) {
      state.getAssetCategoriesStatus = "loading";
    },
    getAssetCategoriesSuccess(state, action: PayloadAction<IApiResponse<ICategory[]>>) {
      if (action.payload.results) {
        state.categories = action.payload.results;
      }
      state.getAssetCategoriesStatus = "";
    },
    getAssetCategoriesFail(state, action: PayloadAction<string>) {
      state.getAssetCategoriesStatus = action.payload;
    },

    // -----

    getAsset(state, action: PayloadAction<{ loading: string }>) {
      state.getAssetStatus = action.payload.loading || "loading";
    },
    getAssetSuccess(state, action: PayloadAction<IApiResponse<IAsset[]> & { loading?: string }>) {
      if (action.payload.loading && action.payload.loading !== "loading") {
        // da se izbjegne refresh kod aktiviranja API-a za tražilicu
        state.getAssetStatus = "";
        if (action.payload.results) {
          state.assetList = action.payload.results;
        }
        return;
      }
      if (action.payload.results) {
        state.assetList = action.payload.results;
      }
      state.getAssetStatus = "";
    },
    getAssetFail(state, action: PayloadAction<string>) {
      state.getAssetStatus = action.payload;
    },
    // -----
    addAsset(state) {
      state.addAssetStatus = "loading";
    },
    addAssetSuccess(state, action: PayloadAction<IApiResponse<IAsset>>) {
      if (action.payload.results) {
        const tmp = [...state.assetList];
        tmp.unshift(action.payload.results);
        state.assetList = tmp;
      }
      state.addAssetStatus = "";
    },

    addAssetFail(state, action: PayloadAction<string>) {
      state.addAssetStatus = action.payload;
    },

    // -----

    getSingleAsset(state) {
      state.getSingleAssetStatus = "loading";
    },
    getSingleAssetSuccess(state, action: PayloadAction<IApiResponse<IAsset>>) {
      if (action.payload.results) {
        state.asset = action.payload.results;
      }
      state.getSingleAssetStatus = "";
    },
    getSingleAssetFail(state, action: PayloadAction<string>) {
      state.getSingleAssetStatus = "error";
    },
    resetSingleAssetStatus(state) {
      state.getSingleAssetStatus = "";
    },

    // -----

    editSingleAsset(state) {
      state.editSingleAssetStatus = "loading";
    },
    editSingleAssetSuccess(state, action: PayloadAction<IApiResponse<IAsset>>) {
      if (action.payload.results) {
        state.asset = action.payload.results;
      }
      state.editSingleAssetStatus = "";
    },
    editSingleAssetFail(state, action: PayloadAction<string>) {
      state.editSingleAssetStatus = action.payload;
    },

    // -----

    getAssetAccounts(state) {
      state.getAssetAccountsStatus = "loading";
    },
    getAssetAccountsSuccess(state, action: PayloadAction<IApiResponse<IAccount[]>>) {
      if (action.payload.results) {
        state.assetAccounts = action.payload.results;
      }
      state.getAssetAccountsStatus = "";
    },
    getAssetAccountsFail(state, action: PayloadAction<string>) {
      state.getAssetAccountsStatus = action.payload;
    },

    // -----

    joinAccountsToAsset(state) {
      state.joinAccountsToAssetStatus = "loading";
    },
    joinAccountsToAssetSuccess(state, action: PayloadAction<IApiResponse<IAccount[]>>) {
      if (action.payload.results) {
        state.assetAccounts = action.payload.results;
      }
      state.joinAccountsToAssetStatus = "";
    },
    joinAccountsToAssetFail(state, action: PayloadAction<string>) {
      state.joinAccountsToAssetStatus = action.payload;
    },

    // -----

    copyFilesToAssets(state) {
      state.copyFilesToAssetsStatus = "loading";
    },
    copyFilesToAssetsSuccess(state, action: PayloadAction<IApiResponse<ICopyFilesToAssetsStatus>>) {
      state.copyFilesToAssetsStatus = "";
    },
    copyFilesToAssetsFail(state, action: PayloadAction<string>) {
      state.copyFilesToAssetsStatus = action.payload;
    },

    // -----

    addSubAsset(state) {
      state.addSubAssetStatus = "loading";
    },
    addSubAssetSuccess(state, action: PayloadAction<IApiResponse<ISubAsset>>) {
      if (action.payload.results) {
        const tmp = [...state.subAssets];
        tmp.unshift(action.payload.results);
        state.subAssets = tmp;
      }
      state.addSubAssetStatus = "";
    },
    addSubAssetFail(state, action: PayloadAction<string>) {
      state.addSubAssetStatus = action.payload;
    },

    // -----

    getSubAssets(state) {
      state.getSubAssetsStatus = "loading";
    },
    getSubAssetsSuccess(state, action: PayloadAction<IApiResponse<ISubAsset[]>>) {
      if (action.payload.results) {
        state.subAssets = action.payload.results;
      }
      state.getSubAssetsStatus = "";
    },
    getSubAssetsFail(state, action: PayloadAction<string>) {
      state.getSubAssetsStatus = action.payload;
    },

    // -----

    editSubAsset(state) {
      state.editSubAssetStatus = "loading";
    },
    editSubAssetSuccess(state, action: PayloadAction<IApiResponse<ISubAsset>>) {
      if (action.payload.results) {
        const arr = [...state.subAssets];
        let index = arr.findIndex((item) => item.id === action.payload.results?.id);
        arr.splice(index, 1, action.payload.results);
        state.subAssets = arr;
      }
      state.editSubAssetStatus = "";
    },
    editSubAssetFail(state, action: PayloadAction<string>) {
      state.editSubAssetStatus = action.payload;
    },
  },
});

export default assetSlice.reducer;
