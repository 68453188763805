import moment, { Moment } from "moment";

export function parseDate(date: Moment | string | null) {
  return moment(date).format("YYYY-MM-DD HH:mm");
}

export function parseStartDate(date: Moment | string | null, days_substracted = 7) {
  return moment(date).subtract(days_substracted, "d").format("YYYY-MM-DD");
}

export function parseEndDate(date: Moment | string | null, days_added = 7) {
  return moment(date).add(days_added, "d").format("YYYY-MM-DD");
}
