import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse, ILocation } from "../../../models";
import {
  ICriticalQty,
  IPart,
  IPartCategory,
  IPartDetails,
  IPartEntries,
  IPartEntry,
  IPartsList,
  IPartSpend,
  IPartSpendList,
} from "../../../models/parts";

interface IWarehouseReducer {
  criticalQty: ICriticalQty[];
  getPartsCategoriesStatus: string;
  categories: IPartCategory[];
  parts: IPartsList;
  getPartsStatus: string;
  createPartStatus: string;
  part: IPartDetails;
  retrievePartStatus: string;
  updatePartStatus: string;
  spendPartStatus: string;
  partEntries: IPartEntries;
  getPartEntriesStatus: string;
  addPartToStorageStatus: string;
  removePartEntryStatus: string;
  partSpendList: IPartSpendList;
  getPartSpendListStatus: string;
  removePartSpendStatus: string;
  confirmPartSpendStatus: string;
  partsInTransport: any;
  getPartsInTransportStatus: string;
  pairSpendWithPartStatus: string;
  getSpendPartsOnAssetStatus: string;
  spendedPartsOnAsset: IPartSpend[];
}

const initialState = {
  criticalQty: [],
  spendedPartsOnAsset: [],
  getSpendPartsOnAssetStatus: "",
  getPartsCategoriesStatus: "",
  categories: [],
  parts: {
    data: [],
    cursor: {
      next: null,
      order_by: "name|id",
      ordering: "asc",
      limit: 15,
      cursor: "name|id",
    },
  },
  getPartsStatus: "",
  createPartStatus: "",
  retrievePartStatus: "",
  part: {
    custom_fields_v2: {},
    id: 0,
    name: "",
    total_price: null,
    code: "",
    barcode: "",
    is_active: false,
    uom: "",
    critical_qty: 0,
    description: "",
    avg_price: 0,
    avg_price_uom: 0,
    total_current_qty: 0,
    category: {
      id: 0,
      name: "",
      parent_id: 0,
    },
    part_storage: [],
    part_materials: [],
    avatar: "",
    assets: [],
  },
  updatePartStatus: "",
  spendPartStatus: "",
  partEntries: {
    data: [],
    cursor: {
      next: null,
      order_by: "created_at",
      ordering: "desc",
      limit: 300,
      cursor: "created_at",
    },
  },
  getPartEntriesStatus: "",
  addPartToStorageStatus: "",
  removePartEntryStatus: "",
  partSpendList: {
    data: [],
    cursor: {
      next: null,
      order_by: "created_at",
      ordering: "desc",
      limit: 30,
      cursor: "created_at",
    },
  },
  getPartSpendListStatus: "",
  removePartSpendStatus: "",
  confirmPartSpendStatus: "",
  partsInTransport: [],
  getPartsInTransportStatus: "",
  pairSpendWithPartStatus: "",
} as IWarehouseReducer;

export const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },

    set_criticalQty(state, action: PayloadAction<ICriticalQty[]>) {
      state.criticalQty = action.payload;
    },

    // -----

    getPartsCategories(state) {
      state.getPartsCategoriesStatus = "loading";
    },
    getPartsCategoriesSuccess(state, action: PayloadAction<IApiResponse<IPartCategory[]>>) {
      if (action.payload.results) {
        state.categories = action.payload.results;
      }
      state.getPartsCategoriesStatus = "";
    },
    getPartsCategoriesFail(state, action: PayloadAction<string>) {
      state.getPartsCategoriesStatus = action.payload;
    },

    // -----

    getParts(state) {
      state.getPartsStatus = "loading";
    },
    getPartsSuccess(
      state,
      action: PayloadAction<IApiResponse<IPartsList> & { mergeData: boolean }>,
    ) {
      if (action.payload.results) {
        let tmp: IPart[] = [];

        if (action.payload.mergeData) {
          tmp = [...state.parts.data, ...action.payload.results.data];
        } else {
          tmp = action.payload.results.data;
        }
        // let tmp = [...state.parts.data, ...action.payload.results.data];
        state.parts = {
          data: tmp,
          cursor: state.parts.cursor,
        };
      }
      state.getPartsStatus = "";
    },
    getPartsFail(state, action: PayloadAction<string>) {
      state.getPartsStatus = action.payload;
    },
    clearParts(state) {
      state.parts = {
        data: [],
        cursor: state.parts.cursor,
      };
    },

    // -----

    createPart(state) {
      state.createPartStatus = "loading";
    },
    createPartSuccess(state, action: PayloadAction<IApiResponse<IPart>>) {
      if (action.payload.results) {
        let tmp = [...state.parts.data];
        tmp.push(action.payload.results);
        state.parts = {
          data: tmp,
          cursor: state.parts.cursor,
        };
      }
      state.createPartStatus = "";
    },
    createPartFail(state, action: PayloadAction<string>) {
      state.createPartStatus = action.payload;
    },

    // -----

    retrievePart(state, action: PayloadAction<string | undefined>) {
      console.log(action.payload, "action.payload");
      state.retrievePartStatus = action.payload || "loading";
    },
    retrievePartSuccess(state, action: PayloadAction<IApiResponse<IPartDetails>>) {
      if (action.payload.results) {
        state.part = action.payload.results;
      }
      state.retrievePartStatus = "";
    },
    retrievePartFail(state, action: PayloadAction<string>) {
      state.retrievePartStatus = "error";
    },
    resetPartStatus(state) {
      state.retrievePartStatus = "";
    },

    // -----

    updatePart(state) {
      state.updatePartStatus = "loading";
    },
    updatePartSuccess(state, action: PayloadAction<IApiResponse<IPartDetails>>) {
      if (action.payload.results) {
        state.part = action.payload.results;
      }
      state.updatePartStatus = "";
    },
    updatePartFail(state, action: PayloadAction<string>) {
      state.updatePartStatus = action.payload;
    },

    // -----

    spendPart(state) {
      state.spendPartStatus = "loading";
    },
    spendPartSuccess(state, action: PayloadAction<IApiResponse<IPartSpend>>) {
      state.spendPartStatus = "";
    },
    spendPartFail(state, action: PayloadAction<string>) {
      state.spendPartStatus = action.payload;
    },

    // -----

    getPartEntries(state) {
      state.getPartEntriesStatus = "loading";
    },
    getPartEntriesSuccess(
      state,
      action: PayloadAction<IApiResponse<IPartEntries> & { mergeData: boolean }>,
    ) {
      if (action.payload.results) {
        let tmp: IPartEntry[] = [];

        if (action.payload.mergeData) {
          tmp = [...state.partEntries.data, ...action.payload.results.data];
        } else {
          tmp = action.payload.results.data;
        }
        state.partEntries = {
          data: tmp,
          cursor: state.partEntries.cursor,
        };
      }
      state.getPartEntriesStatus = "";
    },
    getPartEntriesFail(state, action: PayloadAction<string>) {
      state.getPartEntriesStatus = action.payload;
    },
    clearPartEntries(state) {
      state.partEntries = {
        data: [],
        cursor: state.partEntries.cursor,
      };
    },
    updatePartEntries(state, action: PayloadAction<IPartEntry[]>) {
      state.partEntries = {
        data: action.payload,
        cursor: state.partEntries.cursor,
      };
    },

    // -----

    addPartToStorage(state) {
      state.addPartToStorageStatus = "loading";
    },
    addPartToStorageSuccess(state, action: PayloadAction<IApiResponse<IPartDetails>>) {
      if (action.payload.results) {
        state.part = action.payload.results;
      }
      state.addPartToStorageStatus = "";
    },
    addPartToStorageFail(state, action: PayloadAction<string>) {
      state.addPartToStorageStatus = action.payload;
    },

    // -----

    removePartEntry(state) {
      state.removePartEntryStatus = "loading";
    },
    removePartEntrySuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.removePartEntryStatus = "";
    },
    removePartEntryFail(state, action: PayloadAction<string>) {
      state.removePartEntryStatus = action.payload;
    },

    // -----

    getPartSpendList(state) {
      state.getPartSpendListStatus = "loading";
    },
    getPartSpendListSuccess(
      state,
      action: PayloadAction<IApiResponse<IPartSpendList> & { mergeData: boolean }>,
    ) {
      if (action.payload.results) {
        let tmp: IPartSpend[] = [];
        if (action.payload.mergeData) {
          tmp = [...state.partSpendList.data, ...action.payload.results.data];
        } else {
          tmp = action.payload.results.data;
        }
        state.partSpendList = {
          data: tmp,
          cursor: state.partSpendList.cursor,
        };
      }
      state.getPartSpendListStatus = "";
    },
    getPartSpendListFail(state, action: PayloadAction<string>) {
      state.getPartSpendListStatus = action.payload;
    },
    clearPartSpendList(state) {
      state.partSpendList = {
        data: [],
        cursor: state.partSpendList.cursor,
      };
    },
    updatePartSpendList(state, action: PayloadAction<IPartSpend[]>) {
      state.partSpendList = {
        data: action.payload,
        cursor: state.partSpendList.cursor,
      };
    },

    // -----

    removePartSpend(state) {
      state.removePartSpendStatus = "loading";
    },
    removePartSpendSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.removePartSpendStatus = "";
    },
    removePartSpendFail(state, action: PayloadAction<string>) {
      state.removePartSpendStatus = action.payload;
    },

    // -----

    confirmPartSpend(state) {
      state.confirmPartSpendStatus = "loading";
    },
    confirmPartSpendSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.confirmPartSpendStatus = "";
    },
    confirmPartSpendFail(state, action: PayloadAction<string>) {
      state.confirmPartSpendStatus = action.payload;
    },

    // -----

    getPartsInTransport(state) {
      state.getPartsInTransportStatus = "loading";
    },
    getPartsInTransportSuccess(state, action: PayloadAction<IApiResponse<any>>) {
      if (action.payload.results) {
        state.partsInTransport = action.payload.results;
      }
      state.getPartsInTransportStatus = "";
    },
    getPartsInTransportFail(state, action: PayloadAction<string>) {
      state.getPartsInTransportStatus = action.payload;
    },
    // -----

    pairSpendWithPart(state) {
      state.pairSpendWithPartStatus = "loading";
    },
    pairSpendWithPartSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.pairSpendWithPartStatus = "";
    },
    pairSpendWithPartFail(state, action: PayloadAction<string>) {
      state.pairSpendWithPartStatus = action.payload;
    },

    // -----

    getSpendPartsOnAsset(state) {
      state.getSpendPartsOnAssetStatus = "loading";
    },
    getSpendPartsOnAssetSuccess(
      state,
      action: PayloadAction<IApiResponse<IPartSpend[]> & { mergeData: boolean }>,
    ) {
      if (!action.payload.results) {
        return;
      }
      if (action.payload.mergeData) {
        state.spendedPartsOnAsset = [...state.spendedPartsOnAsset, ...action.payload.results];
      } else {
        state.spendedPartsOnAsset = action.payload.results;
      }
      state.getSpendPartsOnAssetStatus = "";
    },
    getSpendPartsOnAssetFail(state, action: PayloadAction<string>) {
      state.getSpendPartsOnAssetStatus = action.payload;
    },
  },
});

export default warehouseSlice.reducer;
