import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Input,
  message,
  Modal,
  Spin,
  Typography,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FilesComponent, { MediaType } from "../../../components/Files";
import RequirePermission from "../../../components/RequirePermission";
import { MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../../../helpers/consts";
import { filterFilesByExtension, hasPermission } from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse } from "../../../models";
import { IMaintenanceDetails, ITemplateDetails } from "../../../models/maintenances";
import { IFile } from "../../../models/user";
import api, { failedQueue, isRefreshing } from "../../../services";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { supplierSlice } from "../../../store/reducers/supplier";

type IProps = {
  template: ITemplateDetails | null;
};

// Search data
export type Data = IFile & { search: string };
const mapData = (materials: IFile[]): Data[] => {
  return materials.map((x) => ({
    ...x,
    search: x.name.toLowerCase(),
  }));
};

const TemplateMaterials: React.FC<IProps> = ({ template }) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const open_order_token = useParams().token;

  // Variables
  const { user } = useAppSelector((state) => state.userReducer);
  const [search, set_search] = useState<string>("");
  const [filters, set_filters] = useState<MediaType[]>([]);
  const [loading, set_loading] = useState<boolean>(false);
  const [data, set_data] = useState<Data[]>([]);
  const [files, set_files] = useState<RcFile[]>([]);

  // Methods
  useEffect(() => {
    if (template) {
      set_data(mapData(template.files));
    }
  }, [template?.files]);

  useEffect(() => {
    // If uploadTrigger is true, call onUpload function
    console.log(files);
    if (files.length > 0) {
      onUpload();
    }
  }, [files]);

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    return false;
  };

  // Upload function, called in useEffect by uploadTrigger
  const onUpload = async () => {
    // Reseting uploadTrigger
    set_loading(true);
    const token = await localStorage.getItem("token");
    const formData = new FormData();
    const code = await localStorage.getItem(open_order_token || "");

    files.forEach((element: RcFile) => {
      formData.append("files", element);
    });

    formData.append("data", JSON.stringify({ template: id }));

    try {
      let response = await api.post<IApiResponse<IFile>>(`/maintenance/template_files/`, formData, {
        headers: { Authorization: "Bearer " + token },
      });

      if (response.data.results) {
        let arr = template ? [...template.files] : [];
        let final = arr.concat(response.data.results); // Merge two arrays

        if (template) {
          dispatch(
            maintenanceSlice.actions.getTemplateDetailsSuccess({
              results: { ...template, files: final },
              message: "",
            }),
          );
        }

        set_files([]);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onUpload());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onDeleteFile = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    let code = await localStorage.getItem(open_order_token || "");
    if (!code) {
      code = "";
    }
    try {
      let response = await api.delete<IApiResponse<string>>(`/maintenance/template_files/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      let arr: IFile[] = template ? [...template.files] : [];

      let index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      if (template) {
        dispatch(
          maintenanceSlice.actions.getTemplateDetailsSuccess({
            results: { ...template, files: arr },
            message: "",
          }),
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDeleteFile(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_loading(false);
  };

  // Filters and search
  let filtredData: IFile[] = data;
  if (search) filtredData = data?.filter((x) => x.search.includes(search));
  if (filters.length) filtredData = filterFilesByExtension(filtredData, filters);

  return (
    <div style={{ paddingTop: 12 }}>
      {/* Header */}
      <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
        <div className="spaceBetweenRow">
          <Typography.Title level={5}>{t(TRANSLATION_KEY.materials)}</Typography.Title>
          <div className="spaceBetweenRow">
            <Upload
              showUploadList={false}
              beforeUpload={beforeUpload}
              accept={MATERIAL_EXTENSIONS}
              multiple
            >
              <Button loading={loading} type="primary" shape="circle" icon={<UploadOutlined />} />
            </Upload>
          </div>
        </div>
      </div>

      <Divider style={{ marginTop: 18 }} />

      {/* Files */}
      <Spin spinning={loading}>
        {data.length > 0 ? (
          <FilesComponent
            size={"small"}
            files={data}
            onEdit={() => console.log("On edit")}
            hideEditButton
            hideButtons={false}
            onDelete={onDeleteFile}
          />
        ) : (
          <Empty style={{ marginTop: 70 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </div>
  );
};

export default TemplateMaterials;
