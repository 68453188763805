import { UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Form, Input, message, Typography, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import { useState } from "react";
import FilesComponent, { IFile } from "../components/Files";
import { IMAGE_TYPES, MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../helpers/consts";
import { compressImage } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { dashboardSlice } from "../store/reducers/dashboard";
import { maintenanceSlice } from "../store/reducers/maintenance";
import { confirmOrderXHR } from "../store/reducers/maintenance/actionCreator";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";
import { CustomFieldValues } from "../models/settings";

interface IProps {
  selectedMaintenance: number;
  close: () => void;
}

interface IConfirmOrder {
  text: string;
  success: boolean;
  order_info: number;
  custom_fields_v2: CustomFieldValues;
}

const ConfirmForm: React.FC<IProps> = ({ selectedMaintenance, close }) => {
  const dispatch = useAppDispatch();
  const { liveMaintenances, confirmOrderStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  const [checkboxValue, set_checkboxValue] = useState<boolean>(true);

  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const [form] = Form.useForm();

  const onFinish = async (values: IConfirmOrder) => {
    if (!selectedMaintenance) {
      return;
    }
    // Form object
    const form: IConfirmOrder = {
      ...values,
      order_info: +selectedMaintenance || 0,
      custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.orderconfirm),
    };

    // Form data
    let formData = new FormData();
    formData.append("data", JSON.stringify(form));

    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    // Call axios after all requests
    requests.then(() =>
      confirmOrderXHR(
        {
          body: formData,
          successCallback: (data) => {
            if (data.results) {
              dispatch(
                maintenanceSlice.actions.getMaintenanceSuccess({
                  results: data.results,
                  message: "",
                }),
              );

              let tmp = [...liveMaintenances];
              let index = tmp.findIndex((x) => x.id === data.results?.id);
              if (index !== -1) {
                tmp.splice(index, 1);
                dispatch(
                  maintenanceSlice.actions.getLiveMaintenancesSuccess({
                    results: { data: tmp, cursor: null },
                    message: "",
                  }),
                );
                dispatch(dashboardSlice.actions.set_categoriesTypes(tmp));
              }
              close();
            }
          },
          errorCallback: (err) => {
            if (err.response.data?.message?.custom_field) {
              message.error(
                t(err.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  err.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
        },
        dispatch,
      ),
    );
  };

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ success: checkboxValue, text: "" }}
      onFinish={onFinish}
    >
      <Form.Item name="text" label={t(TRANSLATION_KEY.description)}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item name="success" valuePropName="checked">
        <Checkbox
          checked={checkboxValue}
          onChange={() => set_checkboxValue(!checkboxValue)}
          style={{ float: "left" }}
        >
          {checkboxValue ? t(TRANSLATION_KEY.successful) : t(TRANSLATION_KEY.unsuccessful)}
        </Checkbox>
      </Form.Item>

      {/* Dynamic columns */}
      <GenerateForCustomFieldsV2
        values={{}}
        condition={undefined}
        customFields={companyCustomFieldsV2.orderconfirm}
        form={form}
      />

      <Divider plain>
        <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
      </Divider>

      <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
        <Typography.Title level={5}>{t(TRANSLATION_KEY.documents)}</Typography.Title>
        <Upload
          multiple={true}
          accept={MATERIAL_EXTENSIONS}
          beforeUpload={beforeUpload}
          showUploadList={false}
        >
          <Button
            loading={confirmOrderStatus === "loading"}
            type="primary"
            shape="circle"
            icon={<UploadOutlined />}
          />
        </Upload>
      </div>

      {/* Files list */}
      <FilesComponent
        files={previewFiles}
        onDelete={onRemove}
        hideEditButton
        hideMetaType
        size="small"
        layout="list"
      />

      <Form.Item style={{ marginBottom: 0 }}>
        <Button htmlType="submit" type="primary" style={{ marginTop: 24, float: "right" }}>
          {t(TRANSLATION_KEY.save)}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ConfirmForm;
