import React, { useEffect, useRef } from "react";

import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Switch,
  TreeSelect,
} from "antd";

import { IFormAccount, IUserAccount } from "../models/user";
import { TRANSLATION_KEY } from "../helpers/consts";
import { t } from "i18next";
import { addUserXHR, updateAccountXHR } from "../store/reducers/accounts/actionCreators";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ButtonSaveType, IApiResponse } from "../models";
import { getRolesXHR } from "../store/reducers/settings/actionCreator";
import { getAssetXHR } from "../store/reducers/asstes/actionCreators";
import { createTreeSelect, getChilds, inputNumberAdvancedParser } from "../helpers/functions";
import { IAsset } from "../models/asset";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

interface IProps {
  account: null | IUserAccount;
  close: () => void;
}

const AccountForm: React.FC<IProps> = ({ account, close }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { addUserStatus, updateAccountStatus } = useAppSelector((state) => state.accountsReducer);
  const { assetList } = useAppSelector((state) => state.assetReducer);
  const { roles, companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  const [addAllAsset, set_addAllAsset] = React.useState<boolean>(true);
  const currency = useAppSelector((state) => state.userReducer.user.account.company.currency);
  const [timeoutToSee, set_timeoutToSee] = React.useState<boolean>(false);
  const [selectedRole, set_selectedRole] = React.useState<number | undefined>(undefined);

  const [loadingSave, set_loadingSave] = React.useState<ButtonSaveType>();
  const inputNameRef = useRef<Input>(null);

  useEffect(() => {
    setTimeout(() => {
      set_timeoutToSee(true);
    }, 1000);
    getRolesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
    getAssetXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);
  useEffect(() => {
    form.setFieldsValue({ assets: assetList.map((x) => x.id.toString()) });
  }, [assetList]);

  const onFinish = (values: IFormAccount) => {
    let price_per_hour = values.price_per_hour ? +values.price_per_hour.toFixed(2) : null;
    let _values: IFormAccount = {
      email: values.email,
      name: values.name,
      role: values.role,
      phone_number: values.phone_number,
      all_assets: addAllAsset,
      assets: values.assets,
      currency: values.currency,
      note: values.note,
      price_per_hour,
      custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.account),
    };

    if (account) {
      // update user

      updateAccountXHR(
        {
          id: account.id.toString(),
          body: { ..._values, custom_id: values.custom_id },
          errorCallback: (error: any) => {
            console.log(error?.response?.data?.message);
            if (error.response.data?.message?.custom_field) {
              message.error(
                t(error.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  error.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          successCallback: (data: IApiResponse<IUserAccount | null>) => {
            if (data.results) {
              close();
            } else {
              message.warning(t(data.message));
            }
          },
        },
        dispatch,
      );

      return;
    }

    // ovoga nema u update
    let childs = getChilds<IAsset>({
      ids: values.assets?.map((x) => +x) || [],
      data: assetList,
      arr: [],
    });
    let selectedAsset = values.assets?.map((x) => +x) || [];

    addUserXHR(
      {
        body: {
          ..._values,
          assets: [...childs, ...selectedAsset],
          price_per_hour,
          all_assets: addAllAsset,
        },
        errorCallback: (error) => {
          if (error.response?.data?.message === "email_exists") {
            message.warning(t(TRANSLATION_KEY.emailAlreadyExists));
            return;
          }
          if (error.response.data?.message?.custom_field) {
            message.error(
              t(error.response.data.message.message || "").replace(
                "$_dynamic_column",
                error.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: () => {
          if (loadingSave === "save") {
            close();
            return;
          }
          form.resetFields();
          inputNameRef.current?.focus();
        },
      },
      dispatch,
    );
  };

  const initialValues: Partial<IFormAccount> = {
    role: undefined,
    name: "",
    email: "",
    assets: assetList.map((x) => x.id.toString()),
    phone_number: "",
    note: "",
    price_per_hour: undefined,
    currency: currency,
  };

  useEffect(() => {
    if (account) {
      form.setFieldsValue({
        role: account.role.id,
        assets: [], // kod updeta korisnika se ne može upravljati dodijeljenom imovinom
        name: account.name,
        email: account.email,
        phone_number: account.phone_number,
        price_per_hour: account.price_per_hour || null,
        note: account.note,
        custom_id: account.custom_id,
        currency: account.currency,
      });
    }
    set_selectedRole(account?.role.id);
  }, [account]);

  const tree = createTreeSelect(
    assetList.map((x) => ({
      title: x.name,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
      {account && (
        <Form.Item
          name="custom_id"
          label={t(TRANSLATION_KEY.code)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name={"name"}
        label={t(TRANSLATION_KEY.name)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <Input ref={inputNameRef} />
      </Form.Item>
      <Form.Item
        name={"email"}
        label={t(TRANSLATION_KEY.email)}
        rules={[
          { required: true, message: t(TRANSLATION_KEY.filedRequired) },
          { type: "email", message: t(TRANSLATION_KEY.enterCorrectEmail) },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"role"}
        label={t(TRANSLATION_KEY.role)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <Select
          onChange={(r) => set_selectedRole(r || undefined)}
          // disabled={account?.role.name === "Admin"}
        >
          {roles.map((x) => (
            <Option key={x.id} value={x.id}>
              {t(x.rolename || "")}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {!account && (
        <>
          <Form.Item
            name={"assets"}
            style={{
              marginBottom: assetList.length < 1 ? 3 : undefined,
              height: addAllAsset ? 24 : undefined,
            }}
            label={
              <Space>
                {t(TRANSLATION_KEY.addAllAssetToUser)}
                <Switch
                  checked={addAllAsset}
                  onChange={() => {
                    form.setFieldsValue({ assets: [] });
                    set_addAllAsset(!addAllAsset);
                  }}
                />
              </Space>
            }
          >
            <TreeSelect
              filterTreeNode={(search: string, item: any) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
              }}
              maxTagCount={6}
              disabled={addAllAsset}
              style={{
                maxHeight: 270,
                overflow: "auto",
                display: addAllAsset ? "none" : undefined,
              }}
              // defaultValue={assetList.map((x) => x.id)}
              allowClear
              treeCheckable={true}
              showCheckedStrategy={SHOW_PARENT}
              treeData={tree}
            />
          </Form.Item>

          {assetList.length < 1 && timeoutToSee && (
            <Alert
              type="warning"
              message={t(TRANSLATION_KEY.userAssetRequired)}
              banner
              style={{ marginBottom: 20 }}
            />
          )}
        </>
      )}

      <Form.Item name={"phone_number"} label={t(TRANSLATION_KEY.phoneNumber)}>
        <Input />
      </Form.Item>

      <Form.Item name={"note"} label={t(TRANSLATION_KEY.description)}>
        <Input.TextArea rows={6} />
      </Form.Item>

      {/* Price per hour */}
      <Form.Item label={t(TRANSLATION_KEY.pricePerHour)}>
        <Form.Item name={"price_per_hour"}>
          <InputNumber
            formatter={inputNumberAdvancedParser.decimalFormatter}
            parser={inputNumberAdvancedParser.decimalParser as any}
            style={{ width: "100%" }}
            addonAfter={currency}
          />
        </Form.Item>
      </Form.Item>

      {companyCustomFieldsV2 &&
        selectedRole && ( // uvijetovano selected rolom zato sto je obavezan podatak stoga tek onda mogu prikazati dinamicne
          <GenerateForCustomFieldsV2
            values={account?.custom_fields_v2}
            condition={selectedRole}
            customFields={companyCustomFieldsV2.account}
            form={form}
          />
        )}

      <Form.Item>
        <Space>
          <Button
            onClick={() => {
              set_loadingSave("save");
            }}
            loading={
              (addUserStatus === "loading" || updateAccountStatus === "loading") &&
              loadingSave === "save"
            }
            htmlType="submit"
            type="primary"
          >
            {t(TRANSLATION_KEY.save)}
          </Button>
          <Button
            style={{
              display: account ? "none" : undefined,
            }}
            onClick={() => {
              set_loadingSave("saveAndAdd");
            }}
            loading={
              (addUserStatus === "loading" || updateAccountStatus === "loading") &&
              loadingSave === "saveAndAdd"
            }
            htmlType="submit"
            type="primary"
          >
            {t(TRANSLATION_KEY.saveAndAdd)}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AccountForm;
