import React from "react";
import { Button, Space, Typography } from "antd";
import PreviewButton from "../../../components/PreviewButton";
import { IMaintenance } from "../../../models/maintenances";
import { motion } from "framer-motion";
import { t } from "i18next";

type IProps = {
  item: IMaintenance;
  containerStyle?: React.CSSProperties;
  buttonText?: string;
  onClick?: () => any;
  onPreview?: (id: number) => any;
};

const MaintenanceListItem = ({
  item,
  containerStyle,
  buttonText = "Click",
  onClick,
  onPreview,
}: IProps) => {
  console.log("🚧 - item:", item);
  return (
    <motion.div
      whileHover={{ backgroundColor: "#efefef" }}
      style={{
        backgroundColor: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        borderRadius: 4,
        ...containerStyle,
      }}
    >
      {/* Order number and maintenance type */}
      <Space>
        <Typography.Text strong style={{ width: 32 }}>
          #{item.order_number}
        </Typography.Text>
        {item.maintenance_type ? (
          <Typography.Text type="secondary">{t(item.maintenance_type.name) || ""}</Typography.Text>
        ) : null}
        {item.location ? (
          <Typography.Text type="secondary">{item.location.name}</Typography.Text>
        ) : null}
      </Space>
      <div className="spaceBetweenRow" style={{ maxWidth: "100%" }}>
        {/* Name */}
        {onPreview ? (
          <PreviewButton
            isActive={item.is_active}
            title={item.description}
            id={item.id}
            url={`/app/maintenances/${item.id}/`}
            onClick={() => onPreview(item.id)}
            containerStyle={{ minWidth: "unset", maxWidth: "70%" }}
            linkStyle={{
              fontSize: 16,
              fontWeight: 500,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        ) : (
          <Typography.Text strong style={{ marginBottom: 12, maxWidth: "70%" }}>
            {item.description}
          </Typography.Text>
        )}
        {onClick && (
          <Button
            onClick={onClick}
            type="link"
            style={{ minWidth: "max-content", width: "30%", textAlign: "right" }}
          >
            {buttonText}
          </Button>
        )}
      </div>
      {/* Additional informations */}
      <div className="spaceBetweenRow" style={{ alignItems: "flex-start" }}>
        <Space align="start">
          {/* <SettingTwoTone  twoToneColor="#2d3b51" style={{ marginTop: 4 }} /> */}
          <Space direction="vertical" size={0}>
            <Typography.Text type="secondary" style={{ fontSize: 14, marginBottom: -6 }}>
              {item.asset?.name}
            </Typography.Text>
          </Space>
        </Space>

        <Space align="start">
          <Space direction="vertical" size={0}>
            <Typography.Text
              style={{ fontSize: 14, marginBottom: -6, marginRight: 16, textAlign: "right" }}
            >
              {t(item.status) || ""}
            </Typography.Text>
            <Typography.Text type="secondary"></Typography.Text>
          </Space>
          {/* <SyncOutlined twoToneColor="#2d3b51" style={{ marginTop: 4 }} /> */}
        </Space>
      </div>
    </motion.div>
  );
};

export default MaintenanceListItem;
