import React, { useState } from "react";

// Antd
import { Button, Checkbox, Divider, Form, Input, List, Modal, Typography, message } from "antd";

// Rest
import { IFile } from "../models/user";
import { IMAGE_TYPES, MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../helpers/consts";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import Upload, { RcFile } from "antd/lib/upload";
import { UploadOutlined } from "@ant-design/icons";
import FilesComponent from "../components/Files";
import { compressImage } from "../helpers/functions";
import { IMaintenance } from "../models/maintenances";
import Search from "antd/lib/input/Search";
import MaintenanceModalPreview from "../pages/maintenance/components/ModalPreview";
import MaintenanceListItem from "../pages/maintenance/components/MaintenanceListItem";
import { confirmOrderXHR, writeReportXHR } from "../store/reducers/maintenance/actionCreator";
import { maintenanceSlice } from "../store/reducers/maintenance";
import { dashboardSlice } from "../store/reducers/dashboard";

interface IConfirmOrder {
  text: string;
  success: boolean;
  order_info: number;
}

interface IProps {
  close: () => void;
}

const AddReportToOrder: React.FC<IProps> = ({ close }) => {
  // Hook
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  // Variables
  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const [modalVisible, set_modalVisible] = useState<number | undefined>(undefined);
  const [search, set_search] = useState<string>("");
  const [checkboxValue, set_checkboxValue] = useState<boolean>(true);
  const [maintenanecPreviewModalVisible, set_maintenanecPreviewModalVisible] =
    useState<boolean>(false);
  const [selectedMaintenance, set_selectedMaintenance] = useState<string | number | undefined>(
    undefined,
  );
  const { orders_without_report } = useAppSelector((state) => state.dashboardReducer);
  const { writeReportStatus } = useAppSelector((state) => state.maintenanceReducer);
  let filteredData: IMaintenance[] = orders_without_report;

  if (search) {
    filteredData = orders_without_report.filter(
      (item) =>
        item.description.toLowerCase().includes(search) ||
        item.asset?.name.toLowerCase().includes(search) ||
        item.location?.name.toLowerCase().includes(search),
    );
  }

  // Methods
  const onFinish = async (values: IConfirmOrder) => {
    if (!modalVisible) return;

    // Form object
    const form: IConfirmOrder = {
      ...values,
      success: true,
      order_info: +modalVisible || -1,
    };

    // Form data
    let formData = new FormData();
    formData.append("data", JSON.stringify(form));

    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    // Call axios after all requests

    writeReportXHR(
      {
        successCallback: (data) => {
          if (data.results) {
            close();
          }
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        body: formData,
      },
      dispatch,
    );
  };

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={filteredData}
        header={
          <>
            <Search
              placeholder={t(TRANSLATION_KEY.searchOrders)}
              onChange={(e) => set_search(e.target.value.toLowerCase())}
              style={{ marginBottom: 10, marginTop: -12 }}
              allowClear
              enterButton
            />
          </>
        }
        renderItem={(item) => (
          <MaintenanceListItem
            item={item}
            containerStyle={{ padding: "10px 4px" }}
            onClick={() => set_modalVisible(item.id)}
            buttonText={t(TRANSLATION_KEY.enterReport)}
            onPreview={() => {
              set_maintenanecPreviewModalVisible(true);
              set_selectedMaintenance(item.id);
            }}
          />
        )}
      />

      {/* Modal */}
      <Modal
        visible={!!modalVisible}
        onCancel={() => set_modalVisible(undefined)}
        footer={null}
        centered
        width="600px"
        closable={false}
        destroyOnClose
        title={t(TRANSLATION_KEY.addReports)}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="text">
            <Input.TextArea rows={6} />
          </Form.Item>

          {/* <Form.Item
                        name="success"
                        valuePropName="checked"
                    >
                        <Checkbox checked={checkboxValue} onChange={() => set_checkboxValue(!checkboxValue)} style={{ float: 'left' }}>
                            { checkboxValue ?
                                t(TRANSLATION_KEY.successful) :
                                t(TRANSLATION_KEY.unsuccessful)
                            }
                        </Checkbox>
                    </Form.Item> */}

          <Divider plain>
            <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
          </Divider>

          <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
            <Typography.Title level={5}>{t(TRANSLATION_KEY.uploadedFiles)}</Typography.Title>
            <Upload
              multiple={true}
              accept={MATERIAL_EXTENSIONS}
              beforeUpload={beforeUpload}
              showUploadList={false}
            >
              <Button type="primary" shape="circle" icon={<UploadOutlined />} />
            </Upload>
          </div>

          {/* Files list */}
          <FilesComponent
            files={previewFiles}
            onDelete={onRemove}
            hideEditButton
            size="small"
            layout="list"
          />

          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginTop: 24, float: "right" }}
              loading={writeReportStatus === "loading"}
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Maintenance preview */}
      <Modal
        visible={maintenanecPreviewModalVisible}
        onCancel={() => {
          set_maintenanecPreviewModalVisible(false);
          set_selectedMaintenance(undefined);
        }}
        footer={null}
        centered
        width="800px"
        closable={false}
        destroyOnClose
      >
        <MaintenanceModalPreview id={selectedMaintenance} />
      </Modal>
    </div>
  );
};

export default AddReportToOrder;
