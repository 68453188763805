import React, { useEffect, useState } from "react";

import { Menu, Layout } from "antd";

import { Link, useLocation } from "react-router-dom";
import { AppRoutes, IRoute } from "./routes";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { canSeePage } from "../helpers/functions";
import { getPartnersRequestsXHR } from "../store/reducers/partner/actionCreators";

const { Sider } = Layout;

const NO_SIDER_ROUTE: string[] = [
  "",
  "*",
  "logout",
  "profile",
  "humanresources/:id",
  "asset-details/:id",
  "partners/:id",
  "suppliers/:id",
  "maintenances/:id",
  "item-details/:id",
  "templates/:id",
  "clients/:id",
  "rentorder/:id",
];

interface IProps {
  authed: boolean;
  collapsed: boolean;
}

const _Sider: React.FC<IProps> = ({ collapsed }) => {
  // Variables
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userReducer);
  const [tabKey, setTabKey] = useState<string>(location.pathname.split("/")[2]);

  useEffect(() => {
    let key = location.pathname.split("/")[2];
    console.log(key);
    if (key === "item-details") setTabKey("items");
    else if (key === "asset-details") setTabKey("asset");
    else setTabKey(key === "rentorder" ? "clients" : key);
  }, [location]);

  return (
    <Menu
      id="sider"
      theme="light"
      mode="inline"
      inlineCollapsed={collapsed}
      activeKey={tabKey}
      selectedKeys={[tabKey]}
      defaultSelectedKeys={[tabKey]}
      style={{ marginTop: 20 }}
    >
      {AppRoutes(user.account.permissions.map((x) => x.codename))
        .children?.filter((x): boolean => !NO_SIDER_ROUTE.includes(x.path))
        .map((item) => {
          return (
            <Menu.Item style={{ position: "relative" }} key={item.path} icon={item.icon}>
              <Link style={{ width: 150, fontWeight: 500 }} to={item.path}>
                {t(item.path)}
              </Link>
              {item.additionalItem}
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

export default _Sider;
