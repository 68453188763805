import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import Items from "./Items";
import Entry from "./Entry";
import Spend from "./Spend";
import CustomFieldFormV2, {
  FormModal,
  FormType,
  getFormSetup,
} from "../../../../../componentsform/CustomFieldsFormV2";
import { useAppSelector } from "../../../../../hooks";
import { CustomFieldV2 } from "../../../../../models/settings";

type TabKey = "items" | "entry" | "spend";

const { TabPane } = Tabs;

const Parts: React.FC = () => {
  const [modalVisible, set_modalVisible] = useState<FormModal>();

  const { categories } = useAppSelector((state) => state.warehouseReducer);

  const [activeKey, set_activeKey] = useState<TabKey>("items");

  const onChange = (key: string | TabKey) => {
    set_activeKey(key as TabKey);
  };

  let formSetup = useMemo(
    () =>
      getFormSetup(
        modalVisible?.type as FormType,
        modalVisible?.customField,
        activeKey === "items"
          ? categories.map((x) => ({
              id: x.id,
              name: x.name,
            }))
          : undefined,
      ),
    [activeKey, modalVisible, categories],
  );

  return (
    <div>
      <CustomFieldFormV2
        modalProps={{
          visible: modalVisible !== undefined,
          onCancel: () => set_modalVisible(undefined),
          title: formSetup.title,
          destroyOnClose: true,
        }}
        formType={modalVisible?.type || "items"}
        data={modalVisible?.customField}
        condition={formSetup.condition}
      />
      <Tabs
        size="small"
        tabPosition="top"
        defaultActiveKey="items"
        onChange={onChange}
        tabBarExtraContent={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              set_modalVisible({
                type: activeKey,
                customField: {} as CustomFieldV2,
              });
            }}
            type="primary"
            shape="circle"
            style={{ marginBottom: 8, float: "right" }}
          />
        }
      >
        <TabPane tab={t(TRANSLATION_KEY.part)} key="items">
          <Items categories={categories} onEdit={set_modalVisible} />
        </TabPane>
        <TabPane tab={t(TRANSLATION_KEY.entryhistory)} key="entry">
          <Entry onEdit={set_modalVisible} />
        </TabPane>
        <TabPane tab={t(TRANSLATION_KEY.spendhistory)} key="spend">
          <Spend onEdit={set_modalVisible} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Parts;
