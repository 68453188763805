import { Divider, List, message, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IWorkCostDetail } from "../../../models/maintenances";
import moment from "moment";
import DurationInput from "./DurationInput";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { getWorkingCostsXHR } from "../../../store/reducers/maintenance/actionCreator";
import { useParams } from "react-router-dom";
import { parseCosts } from "../../../helpers/functions";

interface IProps {}

const WorkingCostsDetail: React.FC<IProps> = () => {
  const { user } = useAppSelector((state) => state.userReducer);
  const { id } = useParams();
  const { workingCostsDetail, getWorkingCostsStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    getWorkingCostsXHR(
      {
        id,
        successCallback: () => {},
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
  }, []);

  const getRenderItem = (item: IWorkCostDetail) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 12,
          alignItems: "center",
        }}
      >
        <div style={{ width: "33%" }}>
          <div>
            <Typography.Text>
              {item.executor.account
                ? item.executor.account?.name
                : item.executor.supplier?.name || ""}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="secondary">
              {moment(item.start).format(user?.account.date_format || undefined)}
            </Typography.Text>
          </div>
        </div>
        <div style={{ width: "33%" }}>
          <DurationInput
            style={{ width: 150 }}
            defaultValue={moment.duration(item.duration)}
            disabled={true}
          />
        </div>
        <div style={{ width: "33%", minWidth: 120 }}>
          <Typography.Text>
            {parseCosts(item.work_price)} {user.account.company.currency}
          </Typography.Text>
        </div>
      </div>
    );
  };

  return (
    <div>
      {workingCostsDetail.length > 0 && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "33%" }}>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.users)}</Typography.Text>
            </div>
            <div style={{ width: "33%" }}>
              {" "}
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.workingTime)}</Typography.Text>
            </div>
            <div style={{ width: "33%" }}>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.value)}</Typography.Text>
            </div>
          </div>
        </div>
      )}
      <List
        loading={getWorkingCostsStatus === "loading"}
        renderItem={getRenderItem}
        dataSource={workingCostsDetail}
      />
    </div>
  );
};

export default WorkingCostsDetail;
