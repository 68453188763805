import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tooltip,
  DatePicker,
  Space,
  Typography,
  TableColumnType,
} from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadMoreButton from "../../components/LoadMoreButton";
import { STATUSES, TRANSLATION_KEY } from "../../helpers/consts";
import { debounce, hasPermission, sortTableColumns } from "../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ICursor, ITableColumn } from "../../models";
import { IMaintenance } from "../../models/maintenances";
import { getMaintenanceListXHR } from "../../store/reducers/maintenance/actionCreator";
import AccountModalPreview from "../account/components/AccountModalPreview";
import { unstable_batchedUpdates } from "react-dom";
import PreviewButton from "../../components/PreviewButton";
import MaintenanceModalPreview from "../maintenance/components/ModalPreview";
import AssetModalPreview from "../assetdetails/components/AssetModalPreview";
import DatePickerCustom, { CalendarTypes } from "../../components/DatePickerCustom";
import Filters, { IMaintenanceFilters } from "../../components/MaintenanceFilters";
import { getSuppliersXHR } from "../../store/reducers/supplier/actionCreator";
import { accountsXHR } from "../../store/reducers/accounts/actionCreators";
import { IAccount, IUser, TableView } from "../../models/user";
import ClientTableItem from "../../components/ClientTableItem";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  getFilterComponent,
  saveTableDefinition,
} from "../../components/ViewTabs";
import Columns from "../../components/ViewTabs/Columns";

interface IFilter {
  start: string | null | undefined;
  end: string;
  search: string;
  calendarType: CalendarTypes | undefined;
}
// [
//   {
//     title: t(TRANSLATION_KEY.orderNumber),
//     dataIndex: "order_number",
//     visible: true,
//     render: (text: string, value: IMaintenance) => (
//       <Link to={`${value.id}`}>{value.order_number}</Link>
//     ),
//   },
//   {
//     title: t(TRANSLATION_KEY.description),
//     dataIndex: "description",
//     visible: true,
//     render(text, value, index) {
//       return (
//         <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: text }}>
//           {text}
//         </Typography.Text>
//       );
//     },
//   },
//   {
//     title: t(TRANSLATION_KEY.asset),
//     dataIndex: "asset",
//     visible: true,
//     render: (text: string, value: IMaintenance) => {
//       if (!canViewAsset || !value.asset) {
//         return (
//           <Typography.Text>
//             {value.asset?.name || t(TRANSLATION_KEY.withoutAsset)}
//           </Typography.Text>
//         );
//       }

//       return (
//         <Space direction="vertical" size={0}>
//           <PreviewButton
//             isActive={value.asset?.is_active}
//             title={value.asset?.name}
//             id={value.id}
//             url={`/app/asset-details/${value.asset?.id}`}
//             permissionsRequired={["view_asset"]}
//             onClick={() => {
//               unstable_batchedUpdates(() => {
//                 set_modalVisible(true);
//                 set_selectedAsset(value.asset?.id);
//               }, []);
//             }}
//           />
//           {value.asset?.parent_id && (
//             <Typography.Text
//               type="secondary"
//               style={{ maxWidth: 320 }}
//               ellipsis={{ tooltip: value.asset.path }}
//             >
//               {value.asset?.path}
//             </Typography.Text>
//           )}
//         </Space>
//       );
//     },
//   },
//   {
//     title: t(TRANSLATION_KEY.status),
//     dataIndex: "status",
//     visible: true,
//     render: (text: string, value) => (
//       <div>
//         <Typography.Text>{t(text)}</Typography.Text>
//         <br />
//         <Typography.Text type="secondary">
//           {moment(value.status_change_ts).format(user.account.date_format || "LLL")}
//         </Typography.Text>
//       </div>
//     ),
//   },
//   {
//     title: t(TRANSLATION_KEY.location),
//     dataIndex: "location",
//     visible: true,
//     render: (text: string, value: IMaintenance) => <span>{value.location?.name || "-"}</span>,
//   },
//   {
//     title: t(TRANSLATION_KEY.reportedBy),
//     dataIndex: "",
//     visible: true,
//     render: (text: string, value: IMaintenance) => {
//       if (!canManageUser) {
//         return (
//           <div>
//             <div>{value.reported_by.name}</div>
//             <div>
//               {" "}
//               <Typography.Text type="secondary">
//                 {" "}
//                 {moment(value.created_at).format(user.account.date_format || "LLL")}
//               </Typography.Text>
//             </div>
//           </div>
//         );
//       }

//       return (
//         <>
//           <PreviewButton
//             isActive={value.reported_by.is_active}
//             title={value.reported_by.name}
//             id={value.reported_by.id}
//             url={`/app/humanresources/${value.reported_by.id}`}
//             permissionsRequired={["manage_user"]}
//             onClick={() => {
//               set_selectedAccount(value.reported_by.id);
//               set_modalVisible(true);
//             }}
//           />
//           <Typography.Text type="secondary">
//             {moment(value.created_at).format(user.account.date_format || "LLL")}
//           </Typography.Text>
//         </>
//       );
//     },
//   },
//   {
//     title: t(TRANSLATION_KEY.confirmedBy),
//     dataIndex: "",
//     visible: true,
//     render: (text: string, value: IMaintenance) => {
//       if (!canManageUser) {
//         return (
//           <div>
//             <div>{value.closed_by?.name || "-"}</div>
//             <div>
//               {" "}
//               {value.closed_at ? (
//                 <Typography.Text type="secondary">
//                   {moment(value.closed_at).format(user.account.date_format || "LLL")}
//                 </Typography.Text>
//               ) : (
//                 ""
//               )}
//             </div>
//           </div>
//         );
//       }

//       return (
//         <>
//           <PreviewButton
//             isActive={value.closed_by?.is_active}
//             title={value.closed_by?.name}
//             id={value.closed_by?.id}
//             url={`/app/humanresources/${value.closed_by?.id}`}
//             permissionsRequired={["manage_user"]}
//             onClick={() => {
//               set_selectedAccount(value.closed_by?.id);
//               set_modalVisible(true);
//             }}
//           />
//           {value.closed_at ? (
//             <Typography.Text type="secondary">
//               {" "}
//               {moment(value.closed_at).format()}
//             </Typography.Text>
//           ) : (
//             ""
//           )}
//         </>
//       );
//     },
//   },
// ]

type TableRenders =
  | "OrderNumber"
  | "Description"
  | "Asset"
  | "Status"
  | "Location"
  | "ReportedBy"
  | "ConfirmedBy";

export const LOCAL_STORAGE_MAINTENNACE_FILTERS = "LOCAL_STORAGE_MAINTENNACE_FILTERS";

const TableMaintenances: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { getMaintenanceListStatus, maintenanceList, maintenanceCategories, maintenancesTypes } =
    useAppSelector((state) => state.maintenanceReducer);
  const { locations } = useAppSelector((state) => state.settingsReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const [canViewAsset] = useState<boolean>(hasPermission(user.account.permissions, ["view_asset"]));
  const [hasClients, setHasClients] = useState<boolean>(false);
  const [canManageUser] = useState<boolean>(
    hasPermission(user.account.permissions, ["manage_user"]),
  );
  const { suppliers } = useAppSelector((state) => state.supplierReducer);
  const { accounts } = useAppSelector((state) => state.accountsReducer);

  const [selectedMaintenance, set_selectedMaintenance] = useState<string | number | undefined>(
    undefined,
  );
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [selectedAsset, set_selectedAsset] = useState<number>();
  const [showAfterResponse, set_showAfterResponse] = useState<boolean>(false);
  const [firstMount, set_firstMount] = useState<boolean>(true);

  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user.account.views?.workorder?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );

  const [localQueryParams, set_localQueryParams] = useState<ICursor>(maintenanceList.cursor);
  const [filters, set_filters] = useState<IFilter & IMaintenanceFilters>({
    search: "",
    start: moment().startOf("month").toISOString(true),
    end: moment().endOf("month").toISOString(true),
    users: [],
    suppliers: [],
    categories: [],
    types: [],
    locations: [],
    statuses: [],
    calendarType: "month",
    clients: [],
    checklists: [],
  });

  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<IMaintenance>>
  >([]);

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.workorder?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);

  const getMaintenanceList = (
    queryParams: ICursor,
    filters: IFilter & IMaintenanceFilters,
    mergeData: boolean,
  ) => {
    if (
      ((queryParams.cursor === null && !!maintenanceList.data.length) ||
        getMaintenanceListStatus === "loading") &&
      !mergeData
    ) {
      return;
    }

    getMaintenanceListXHR(
      {
        errorCallback: (e: any) => {
          set_showAfterResponse(true);
          console.log(e);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        queryParams: {
          ...queryParams,
          start: filters.start?.toString(),
          end: filters.end?.toString(),
          search: filters.search,
          status: filters.statuses.join("|"),
          executor_account: filters.users.join("|"),
          executor_supplier: filters.suppliers.join("|"),
          maintenance_category: filters.categories.join("|"),
          maintenance_type: filters.types.join("|"),
          location: filters.locations.join("|"),
          clients: filters.clients?.join("|"),
          checklist: filters.checklists?.join("|"),
        },
        newEndpoint: "maintenance/orders/by_status",
        mergeData,
        successCallback: (data) => {
          localStorage.setItem(
            createLocalStorageKey(LOCAL_STORAGE_MAINTENNACE_FILTERS, user),
            JSON.stringify(filters),
          );
          if (data.results) {
            unstable_batchedUpdates(() => {
              set_filters(filters);
              set_localQueryParams(data.results?.cursor!);
              set_showAfterResponse(true);
            }, []);
          }
        },
      },
      dispatch,
    );
  };

  const callOnMountWithLocalStorage = async () => {
    const localFilters: string | null = await localStorage.getItem(
      createLocalStorageKey(LOCAL_STORAGE_MAINTENNACE_FILTERS, user),
    );
    if (localFilters) {
      const parsedFilters: (IFilter & IMaintenanceFilters) | null = JSON.parse(localFilters);
      if (parsedFilters) {
        getMaintenanceList(maintenanceList.cursor, parsedFilters, false);
      } else {
        getMaintenanceList(maintenanceList.cursor, filters, false);
      }
    } else {
      getMaintenanceList(maintenanceList.cursor, filters, false);
    }
  };

  const render = (type: TableRenders) => {
    switch (type) {
      case "OrderNumber":
        return (text: string, value: IMaintenance) => (
          <Link to={`${value.id}`}>{value.order_number}</Link>
        );
      case "Description":
        return (text: string, value: IMaintenance) => (
          <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: text }}>
            {text}
          </Typography.Text>
        );
      case "Asset":
        return (text: string, value: IMaintenance) => {
          if (!canViewAsset || !value.asset) {
            return (
              <Typography.Text>
                {value.asset?.name || t(TRANSLATION_KEY.withoutAsset)}
              </Typography.Text>
            );
          }

          return (
            <Space direction="vertical" size={0}>
              <PreviewButton
                isActive={value.asset?.is_active}
                title={value.asset?.name}
                id={value.id}
                url={`/app/asset-details/${value.asset?.id}`}
                permissionsRequired={["view_asset"]}
                onClick={() => {
                  unstable_batchedUpdates(() => {
                    set_modalVisible(true);
                    set_selectedAsset(value.asset?.id);
                  }, []);
                }}
              />
              {value.asset?.parent_id && (
                <Typography.Text
                  type="secondary"
                  style={{ maxWidth: 320 }}
                  ellipsis={{ tooltip: value.asset.path }}
                >
                  {value.asset?.path}
                </Typography.Text>
              )}
            </Space>
          );
        };
      case "Status":
        return (text: string, value: IMaintenance) => (
          <div>
            <Typography.Text>{t(text)}</Typography.Text>
            <br />
            <Typography.Text type="secondary">
              {moment(value.status_change_ts).format(user.account.date_format || "LLL")}
            </Typography.Text>
          </div>
        );
      case "Location":
        return (text: string, value: IMaintenance) => <span>{value.location?.name || "-"}</span>;
      case "ReportedBy":
        return (text: string, value: IMaintenance) => {
          if (!canManageUser) {
            return (
              <div>
                <div>{value.reported_by.name}</div>
                <div>
                  {" "}
                  <Typography.Text type="secondary">
                    {" "}
                    {moment(value.created_at).format(user.account.date_format || "LLL")}
                  </Typography.Text>
                </div>
              </div>
            );
          }
          return (
            <>
              <PreviewButton
                isActive={value.reported_by.is_active}
                title={value.reported_by.name}
                id={value.reported_by.id}
                url={`/app/humanresources/${value.reported_by.id}`}
                permissionsRequired={["manage_user"]}
                onClick={() => {
                  set_selectedAccount(value.reported_by.id);
                  set_modalVisible(true);
                }}
              />
              <Typography.Text type="secondary">
                {moment(value.created_at).format(user.account.date_format || "LLL")}
              </Typography.Text>
            </>
          );
        };
      case "ConfirmedBy":
        return (text: string, value: IMaintenance) => {
          if (!canManageUser) {
            return (
              <div>
                <div>{value.closed_by?.name || "-"}</div>
                <div>
                  {" "}
                  {value.closed_at ? (
                    <Typography.Text type="secondary">
                      {moment(value.closed_at).format(user.account.date_format || "LLL")}
                    </Typography.Text>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          }
          return (
            <>
              <PreviewButton
                isActive={value.closed_by?.is_active}
                title={value.closed_by?.name}
                id={value.closed_by?.id}
                url={`/app/humanresources/${value.closed_by?.id}`}
                permissionsRequired={["manage_user"]}
                onClick={() => {
                  set_selectedAccount(value.closed_by?.id);
                  set_modalVisible(true);
                }}
              />
              {value.closed_at ? (
                <Typography.Text type="secondary">
                  {" "}
                  {moment(value.closed_at).format()}
                </Typography.Text>
              ) : (
                ""
              )}
            </>
          );
        };
    }
  };

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    let tmp: Array<CustomTableColumn & TableColumnType<IMaintenance>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              c.objectKey,
            ),
        onCell: (record: IMaintenance) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  useEffect(() => {
    let activeView = tableDefinition.find((x) => x.active);
    if (activeView) {
      setColumns(activeView.table_structure, 0);
    }
    callOnMountWithLocalStorage();
    getSuppliersXHR({}, dispatch);
    accountsXHR(
      {
        queryParams: {
          limit: 9999,
          order_by: "id",
        },
      },
      dispatch,
    );
  }, []);

  const debounceOnSearch = (obj: IFilter & IMaintenanceFilters) => {
    getMaintenanceList(maintenanceList.cursor, obj, false); // if any filter paramaters change strat load more from start
  };

  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 300);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "18px 0",
        }}
      >
        <div />
        {showAfterResponse && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div>
                <DatePickerCustom
                  allowClear={true}
                  pickerStyle={{ marginRight: 12 }}
                  selectStyle={{ marginRight: 12 }}
                  start={filters.start || undefined}
                  end={filters.end}
                  type={filters.calendarType || "month"}
                  skipInitial={false}
                  onChange={(start, end, type) => {
                    getMaintenanceList(
                      maintenanceList.cursor,
                      {
                        ...filters,
                        start,
                        end,
                        calendarType: type,
                      },
                      false,
                    );
                  }}
                />
              </div>
            </div>
            <Tooltip title={t(TRANSLATION_KEY.searchGoesByNameCodeAsset)}>
              <InfoCircleOutlined />
            </Tooltip>
            <div style={{ width: 12 }} />

            <Input.Search
              onChange={({ target: { value } }) => {
                let obj: IFilter & IMaintenanceFilters = {
                  ...filters,
                  search: value.toLowerCase(),
                };
                setSearch(obj);
              }}
              defaultValue={filters.search}
              allowClear
              style={{ width: 210 }}
            />
          </div>
        )}
      </div>

      {showAfterResponse && (
        <Filters
          statuses={STATUSES.filter((item) => !["cancelled", "shared"].includes(item))}
          categories={maintenanceCategories}
          types={maintenancesTypes}
          locations={locations}
          suppliers={suppliers}
          users={accounts}
          hasClients={hasClients}
          onChange={(val) => {
            getMaintenanceList(maintenanceList.cursor, { ...filters, ...val }, false);
          }}
        />
      )}
      <div>
        <ViewTabs
          onChange={(activeKey) => {
            let index = tableDefinition.findIndex((view) => view.name === activeKey);
            let tmpList = [...tableDefinition];
            if (index === -1) {
              message.error("view_onSaveColumnsDefinition");
              return;
            }
            tmpList = tmpList.map((x) => ({ ...x, active: false }));
            tmpList[index] = { ...tmpList[index], active: true };
            setColumns(tmpList[index].table_structure, index);
            set_tableDefinition(tmpList);
          }}
          setColumns={setColumns}
          views={tableDefinition}
          viewCategory="workorder"
        />
      </div>
      <div style={{ marginBottom: 12 }}>
        <Columns
          columns={COLUMNS}
          set_COLUMNS={(columns) => {
            let index = tableDefinition.findIndex((view) => view.active === true);
            if (index === -1) {
              message.error("view");
              return;
            }
            let tmp = tableDefinition[index];
            if (!tmp) {
              return;
            }

            let inerTmp: CustomTableColumn[] = [];
            columns.forEach((x) => {
              inerTmp.push({
                title: x.title || "",
                dataIndex: x.dataIndex || "",
                visible: x.visible === undefined ? 1 : x.visible,
                onCellFlag: x.onCellFlag || false,
                filterComponent: x.filterComponent || null,
                columnRenderComponent: x.columnRenderComponent || null,
                objectKey: x.objectKey || null,
              });
            });
            tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
            let tmpTableDefinition = [...tableDefinition].map((x) => ({ ...x, active: false }));
            tmpTableDefinition[index] = tmp;
            setColumns(inerTmp, index);
            set_tableDefinition(tmpTableDefinition);
          }}
        />
        <Button
          style={{ marginLeft: 18 }}
          type="link"
          onClick={() => {
            let activeView = tableDefinition.find((x) => x.active);
            saveTableDefinition(
              tableDefinition,
              user.account.views?.workorder?.find((x) => x.id === activeView?.id),
              "workorder",
            );
          }}
        >
          {t(TRANSLATION_KEY.saveChanges)}
        </Button>
      </div>

      <Table
        loading={getMaintenanceListStatus === "loading"}
        size="small"
        pagination={false}
        dataSource={maintenanceList.data}
        columns={filterColumns<IMaintenance>(COLUMNS)}
        rowKey={(item) => item.id}
        // style={{ border: "1px solid #dedede", borderRadius: "4px", marginTop: 14 }}
      />
      {localQueryParams.next && (
        <LoadMoreButton
          loading={false}
          onClick={() => getMaintenanceList(localQueryParams, filters, true)}
        />
      )}
      <div style={{ height: 120 }} />
      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedMaintenance(undefined);
          set_selectedAccount(undefined);
          set_selectedAsset(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {!!selectedAccount && <AccountModalPreview id={selectedAccount} />}
        {!!selectedMaintenance && <MaintenanceModalPreview id={selectedMaintenance} />}
        {!!selectedAsset && <AssetModalPreview id={selectedAsset} />}
      </Modal>
    </>
  );
};

export default TableMaintenances;

export const createLocalStorageKey = (path: string, user: IUser) => {
  return `${path}|${user.account.id}`;
};
