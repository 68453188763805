import React, { useEffect, useMemo, useState } from "react";
import { getAssetXHR } from "../../../../store/reducers/asstes/actionCreators";
import { Button, Divider, Input, Space, Spin, Tree, Typography, message } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IAsset } from "../../../../models/asset";
import { unstable_batchedUpdates } from "react-dom";
import { createTreeWithChekable } from "../../../account/pages/Asset";
import { joinAssetsToPartXHR } from "../../../../store/reducers/warehouse/actionCreator";
import { warehouseSlice } from "../../../../store/reducers/warehouse";

interface IProps {
  _alreadyHavedAsset: number[];
  handleOpenModal: (id: number | string | undefined) => void;
  part_id: number;
  setPickedAsset: (asset: IAsset[]) => void;
}

const AddAsset: React.FC<IProps> = ({
  _alreadyHavedAsset,
  handleOpenModal,
  part_id,
  setPickedAsset,
}) => {
  const { assetList } = useAppSelector((state) => state.assetReducer);

  const dispatch = useAppDispatch();

  const [alreadyHavedAsset, set_alreadyHavedAsset] = useState<number[]>([..._alreadyHavedAsset]);
  const [allAsset, set_allAsset] = useState<IAsset[]>(assetList);
  const [search, set_search] = useState<string>("");
  const [loading, set_loading] = useState<boolean>(false);
  const [changeHappend, set_changeHappend] = useState<boolean>(false);
  const [expandedKeys, set_expandedKeys] = useState<React.Key[]>([]);
  const [cehckedKeys, set_checkedKeys] = useState<React.Key[]>(
    _alreadyHavedAsset.map((x) => x.toString()),
  );
  const [saveLoading, set_saveLoading] = useState<boolean>(false);
  const { parts, part } = useAppSelector((state) => state.warehouseReducer);
  const onSavePartAsset = () => {
    set_saveLoading(true);
    joinAssetsToPartXHR(
      {
        body: {
          part: part_id,
          assets: cehckedKeys.map((x) => parseInt(x.toString())),
        },
        successCallback: (res) => {
          setPickedAsset(res.results || []);
          if (window.location.pathname.includes("item-details")) {
            //update ukoliko se imovina dodaje sa page-a item-details
            set_alreadyHavedAsset(res.results?.map((x) => x.id) || []);
            //update ukoliko se imovina dodaje sa page-a item-details
          } else {
            //update ukoliko se imovina dodaje sa page-a liste dijelova
            let _parts = [...parts.data];
            let _part = _parts.find((x) => x.id === part_id);
            if (_part) {
              _part = { ..._part };
              _part.assets = res.results;
              let index = _parts.findIndex((x) => x.id === part_id);
              _parts[index] = _part!;
              dispatch(
                warehouseSlice.actions.getPartsSuccess({
                  message: "",
                  results: {
                    ...parts,
                    data: _parts,
                  },
                  mergeData: false,
                }),
              );
              //update ukoliko se imovina dodaje sa page-a liste dijelova
            }
          }
          handleOpenModal(undefined);
          message.success(t(TRANSLATION_KEY.successOnSaveData));
          set_saveLoading(false);
        },
        errorCallback: (e: any) => {
          console.log(e);
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          set_saveLoading(false);
        },
      },
      dispatch,
    );
  };

  useEffect(() => {
    set_loading(true);
    getAssetXHR(
      {
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          set_loading(false);
        },
        successCallback: (data) => {
          set_allAsset(data.results || []);
        },
      },
      dispatch,
    );
    set_loading(false);
  }, []);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    set_expandedKeys(expandedKeysValue);
  };

  const onCheck: any = (checkedKeysValue: React.Key[]) => {
    unstable_batchedUpdates(() => {
      set_checkedKeys(checkedKeysValue);
      set_changeHappend(true);
    }, []);
  };

  console.log("allAsset", allAsset);

  let filteredAssetList = useMemo(() => {
    if (search === "") {
      return [...allAsset];
    }
    let arr: IAsset[] = [...allAsset];
    arr = arr.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
    arr = arr.map((x) => ({
      ...x,
      parent_id: x.parent_id ? (arr.some((y) => y.id === x.parent_id) ? x.parent_id : null) : null,
    }));
    return arr;
  }, [allAsset, search]);

  return (
    <>
      {/* Input */}
      <Input
        placeholder={t(TRANSLATION_KEY.searchByName)}
        style={{ marginBottom: 12 }}
        onChange={(e) => set_search(e.target.value)}
      />

      <Divider style={{ marginTop: 12 }} />

      <div
        style={{
          height: "60vh",
          overflowY: "auto",
          width: "100%",
          position: "relative",
        }}
      >
        {/* Data */}
        <Spin spinning={loading} style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "center", margin: 12, marginTop: 0 }}>
              <Button
                onClick={() => {
                  set_checkedKeys(allAsset.map((x) => x.id.toString()));

                  set_changeHappend(true);
                }}
                type="dashed"
                style={{ width: "300px" }}
              >
                {t(TRANSLATION_KEY.selectAll)}
              </Button>
            </div>
            <Tree
              // checkStrictly={true}
              // treeCheckStrictly={true}
              className="userAssetCheckTree"
              style={{
                backgroundColor: "transparent !important",
                width: "100%",
              }}
              onCheck={onCheck}
              onExpand={onExpand}
              checkedKeys={cehckedKeys}
              expandedKeys={expandedKeys}
              checkable
              selectable={false}
              treeData={createTreeWithChekable(
                filteredAssetList,
                null,
                alreadyHavedAsset.map((x) => x),
                handleOpenModal,
              )}
              titleRender={(nodeData) => {
                return (
                  <div className="spaceBetweenRow" style={{ width: "100%", marginBottom: 8 }}>
                    {/* Additional informations */}
                    <Space size="middle" align="baseline">
                      <Typography.Text type="secondary">
                        #{nodeData.custom_id || "-"}
                      </Typography.Text>
                      <Typography.Text type="secondary" style={{ color: "#333", fontWeight: 500 }}>
                        {t(nodeData.category || "")}
                      </Typography.Text>
                      <Typography.Text style={{ marginBottom: 8 }}>
                        {nodeData.title}
                      </Typography.Text>
                      {nodeData.location && (
                        <Typography.Text
                          type="secondary"
                          style={{
                            transform: "translateY(5px)",
                            opacity: 65,
                          }}
                        >
                          ({nodeData.location})
                        </Typography.Text>
                      )}
                    </Space>
                  </div>
                );
              }}
            />
          </div>
        </Spin>
      </div>
      <Button
        type="primary"
        style={{ marginTop: 12 }}
        disabled={!changeHappend || saveLoading}
        onClick={() => onSavePartAsset()}
      >
        {t(TRANSLATION_KEY.save)}
      </Button>
    </>
  );
};

export default AddAsset;
