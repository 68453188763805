import React from "react";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  visible: boolean;
  children: any;
};

const Zoom = ({ visible, children }: Props) => {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ ease: "anticipate" }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Zoom;
