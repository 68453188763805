import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Typography } from "antd";
import moment from "moment";
import RequirePermission from "../../../components/RequirePermission";
import { useAppSelector } from "../../../hooks";
import { FlowExecutor, IFlow } from "../../../models/maintenances";
import { MOB_SIZE } from "../../openorder/components/Order";
import DurationInput from "./DurationInput";

interface IProps {
  item: IFlow;
  onDelete: (id: number) => void;
  loading: number;
  isOpenOrder: boolean;
}

const AddWorkingTimeCard: React.FC<IProps> = ({ item, onDelete, loading, isOpenOrder }) => {
  const { user } = useAppSelector((state) => state.userReducer);

  let wt = moment.duration(item.duration || "0:0:0");

  let name = "";
  if (item.executor) {
    if (item.executor.account) {
      name = item.executor.account.name;
    } else if (item.executor.supplier) {
      name = item.executor.supplier.name;
    }
  }

  return (
    <>
      <div
        style={{
          display: window.innerWidth < MOB_SIZE ? "block" : "flex",
          justifyContent: "flex-start",
          gap: 8,
          alignItems: "center",
          marginTop: 12,
        }}
      >
        <div style={{ width: 272, marginBottom: window.innerWidth < MOB_SIZE ? 12 : 0 }}>
          {" "}
          <DurationInput disabled={true} defaultValue={wt} />
        </div>
        <div style={{ width: 272 }}>{moment(item.start).format()}</div>
        <div style={{ width: 400 }}>{name}</div>
        <div>
          <RequirePermission
            requiredPermissionCode={["manage_wo"]}
            children={
              <Button
                onClick={() => onDelete(item.id)}
                loading={loading === item.id}
                type="default"
                htmlType="submit"
                icon={<DeleteOutlined />}
              />
            }
            anywayIgnorePermission={item.executor?.account?.id === user.account.id}
          />
        </div>
      </div>
      <Divider style={{ margin: "15px 0" }} type="horizontal" />
    </>
  );
};

export default AddWorkingTimeCard;
