import { Spin } from "antd";
import { useAppSelector } from "../../../../hooks";

// Commands
import Finish from "../../../maintenance/components/comands/finish";
import Start from "../../../maintenance/components/comands/start";
interface IProps {
  onClose: () => void;
}

const SupplierCommands: React.FC<IProps> = ({ onClose }) => {
  // Variables
  const { openOrder, startOpenOrderStatus, finishOpenOrderStatus } = useAppSelector(
    (state) => state.supplierReducer,
  );

  let showStart =
    openOrder?.status === "assigned" ||
    openOrder?.status === "held" ||
    openOrder?.status === "requested" ||
    openOrder?.status === "open";
  let showFinish =
    openOrder?.status === "assigned" ||
    openOrder?.status === "held" ||
    openOrder?.status === "requested" ||
    openOrder?.status === "running" ||
    openOrder?.status === "open";
  return (
    <Spin spinning={startOpenOrderStatus === "loading" || finishOpenOrderStatus === "loading"}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "flex-start",
          flexDirection: "row",
        }}
      >
        {openOrder && showStart && (
          <Start isOpenOrder={true} onClose={onClose} maintenance={openOrder} />
        )}
        {openOrder && showFinish && (
          <Finish isOpenOrder={true} onClose={onClose} maintenance={openOrder} />
        )}
      </div>
    </Spin>
  );
};

export default SupplierCommands;
