import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

// Antd
import {
  Button,
  Drawer,
  message,
  Popconfirm,
  Spin,
  Descriptions,
  Tabs,
  Row,
  Col,
  Modal,
  PageHeader,
  Typography,
  Space,
  Tag,
  Divider,
} from "antd";

// Rest
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getSupplierXHR } from "../../store/reducers/supplier/actionCreator";
import SupplierForm from "../../componentsform/SupplierForm";
import { ISupplier } from "../../models/supplier";
import { supplierSlice } from "../../store/reducers/supplier";

import api, { failedQueue, isRefreshing } from "../../services";
import SupplierSpec from "./pages/Spec";
import Maintenances from "./pages/Maintenances";
import Materials from "./components/Materials";

const Supplier: React.FC = () => {
  // Hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { supplier, suppliers, getSupplierStatus } = useAppSelector(
    (state) => state.supplierReducer,
  );

  // Variables
  const [editVisible, set_editVisible] = useState<boolean>(false);
  const [loading, set_loading] = useState<boolean>(false);

  // Methods

  useEffect(() => {
    getSupplierXHR(
      {
        id,
        errorCallback: (error: any) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          if (error?.response?.status === 404) {
            navigate("/app/not-found");
          }
        },
      },
      dispatch,
    );
  }, [id]);

  function onDeleteSuccessCallback(): void {
    navigate("/app/suppliers");
    message.success(t(TRANSLATION_KEY.successOnSaveData));
  }

  const onDelete = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/suppliers/suppliers/${Number(id)}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr: ISupplier[] = [...suppliers];
      const index = arr.findIndex((x) => x.id === Number(id));
      arr.splice(index, 1);

      dispatch(
        supplierSlice.actions.getSuppliersSuccess({
          results: arr,
          message: "",
        }),
      );

      onDeleteSuccessCallback();
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  function onBackClick(): void {
    navigate("/app/suppliers");
  }

  return (
    <>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10 }}
        className="custom-page-header"
        onBack={() => navigate("/app/suppliers")}
        subTitle={
          <div>
            <Typography.Text type="secondary">{t(TRANSLATION_KEY.supplier)} </Typography.Text>
            <Typography.Text strong>#{supplier?.custom_id}</Typography.Text>
          </div>
        }
        extra={[
          <Popconfirm
            onCancel={() => {}}
            onConfirm={onDelete}
            title={t(TRANSLATION_KEY.continueWithAction)}
            cancelText={t(TRANSLATION_KEY.no)}
            okText={t(TRANSLATION_KEY.yes)}
          >
            <Button loading={loading} type="primary" danger>
              {t(TRANSLATION_KEY.delete)}
            </Button>
          </Popconfirm>,
          <Button onClick={() => set_editVisible(true)} type="primary">
            {t(TRANSLATION_KEY.edit)}
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <Space size="large" align="center">
            <Typography.Title level={3}>{supplier.name}</Typography.Title>
            {supplier.category && <Tag className="main-tag">{supplier.category.name}</Tag>}
          </Space>
          {/* Specifications */}
          <SupplierSpec />
        </Space>
      </PageHeader>

      {/* Divider */}
      <Divider />

      {/* Materials */}
      <Materials />

      <div style={{ marginTop: 12 }} />

      {/* Maintenances */}
      <Spin spinning={getSupplierStatus === "loading"}>
        <div className="white-container">{supplier.id !== 0 && <Maintenances />}</div>
      </Spin>

      <Drawer
        width={540}
        visible={editVisible}
        onClose={() => set_editVisible(false)}
        title={t(TRANSLATION_KEY.editSupplier)}
        destroyOnClose={true}
      >
        <SupplierForm close={() => set_editVisible(false)} supplier={supplier} />
      </Drawer>
    </>
  );
};

export default Supplier;
