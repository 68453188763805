import { Modal, Table, TableColumnType, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { IAsset } from "../../../models/asset";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import PreviewButton from "../../../components/PreviewButton";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import useAsset from "../components/Drawer/useAsset";
import { IClientDetails } from "../../../models/clients";
import { useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";

const Asset: React.FC = () => {
  const { client, getAssetRentOrdersStatus } = useAppSelector((state) => state.clientsReducer);

  const clientAsset = useAsset(client);
  const { id } = useParams();
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [COLUMNS, setCOLUMNS] = useState<
    TableColumnType<IAsset & { client_asset_type: "rent" | "ownership" }>[]
  >([
    {
      title: t(TRANSLATION_KEY.name),
      dataIndex: "name",
      render: (text: string, record: IAsset) => {
        if (record?.is_active === false) {
          return <Typography.Text>{record.name}</Typography.Text>;
        }
        return (
          <PreviewButton
            isActive={record.is_active}
            title={record.name}
            id={record.id}
            url={`/app/asset-details/${record.id}/`}
            permissionsRequired={["view_asset"]}
            onClick={() => {
              set_selectedAsset(record?.id);
            }}
          />
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.location),
      dataIndex: "location",
      render: (text: string, record: IAsset) => {
        return record.location?.name || "-";
      },
    },
  ]);

  useEffect(() => {
    clientAsset.initialFetch(Number(id));
  }, [id]);

  return (
    <>
      <Table
        pagination={false}
        size="small"
        loading={clientAsset.loading}
        columns={COLUMNS as any}
        dataSource={clientAsset.rentData}
      />

      <Modal
        visible={selectedAsset !== undefined}
        onCancel={() => {
          set_selectedAsset(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
      </Modal>
    </>
  );
};

export default Asset;
