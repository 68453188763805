import { Button, Input, message, TreeSelect } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { IAsset } from "../../../models/asset";
import { arrayToTree } from "performant-array-to-tree";
import api, { failedQueue, isRefreshing } from "../../../services";
import { IApiResponse, NewTreeItem, TreeItem } from "../../../models";
import { useAppDispatch } from "../../../hooks";
import { createTreeSelect } from "../../../helpers/functions";
import { assetSlice } from "../../../store/reducers/asstes";

interface IProps {
  assets: IAsset[];
  close: () => void;
  assetForChange_id: number;
  assetForChange_name: string;
  assetList: IAsset[];
}

const EditParent: React.FC<IProps> = ({
  assetForChange_id,
  assets,
  close,
  assetForChange_name,
  assetList,
}) => {
  const [selected, set_selected] = useState<number | null>(null);
  const [loading, set_loading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  let tree = createTreeSelect(
    assets.map((x) => ({
      title: x.name,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  const onSave = async () => {
    set_loading(false);
    const token = await localStorage.getItem("token");

    api
      .put<IApiResponse<string>>(
        "assets/change_parent/",
        { asset: assetForChange_id, parent: selected },
        { headers: { Authorization: "Bearer " + token } },
      )
      .then((res) => {
        let tmpAssetList = [...assetList];
        let index = tmpAssetList.findIndex((x) => x.id === assetForChange_id);
        let parentIndex = tmpAssetList.findIndex((x) => x.id == selected);
        if (index !== -1) {
          tmpAssetList[index] = {
            ...assetList[index],
            parent_id: selected ? +selected : null,
            parent: selected
              ? {
                  name: assetList[parentIndex].name,
                  id: assetList[parentIndex].id,
                }
              : null,
          };

          dispatch(
            assetSlice.actions.getAssetSuccess({
              message: "",
              results: tmpAssetList,
            }),
          );
        }

        close();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          if (isRefreshing) {
            failedQueue.push(() => onSave());
          }
          return;
        }
        message.error(t(TRANSLATION_KEY.errorOnSaveData));
      });

    set_loading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
      }}
    >
      <span>{t(TRANSLATION_KEY.asset)}</span>

      <Input value={assetForChange_name} disabled={true} />
      <div style={{ height: 12 }} />
      <span>{t(TRANSLATION_KEY.parentAsset)}</span>
      <TreeSelect
        showSearch={true}
        onChange={(id) => set_selected(id)}
        treeData={tree}
        filterTreeNode={(search, item: any) => {
          return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        }}
      />
      <div style={{ height: 24 }} />
      <Button onClick={onSave} loading={loading} type="primary" style={{ width: 90 }}>
        {t(TRANSLATION_KEY.save)}
      </Button>
    </div>
  );
};

export default EditParent;
