import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Divider, List, message, Modal, Typography } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import PartCategoryForm, {
  IPartCategoryForm,
} from "../../../../../componentsform/PartCategoryForm";
import { ICON_SIZE, TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IApiResponse, PartCategoryTreeItem } from "../../../../../models";
import { IPartCategory } from "../../../../../models/parts";
import api, { failedQueue, isRefreshing } from "../../../../../services";
import { warehouseSlice } from "../../../../../store/reducers/warehouse";
import { getPartsCategoriesXHR } from "../../../../../store/reducers/warehouse/actionCreator";
import Card from "./Card";
import PartCategoryCard from "./PartCategoryCard";

const PartsCategories: React.FC = () => {
  const [category, set_category] = useState<IPartCategoryForm>();

  const dispatch = useAppDispatch();
  const [loading, set_loading] = useState<boolean>(false);

  const { categories, getPartsCategoriesStatus } = useAppSelector(
    (state) => state.warehouseReducer,
  );

  const onDelete = async (id: number) => {
    let token = await localStorage.getItem("token");
    set_loading(true);

    let data = [...categories];

    try {
      //update
      let response = await api.delete<IApiResponse<IPartCategory>>(`/warehouse/categories/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });
      let index = data.findIndex((x) => x.id === id);
      data.splice(index, 1);
      dispatch(
        warehouseSlice.actions.getPartsCategoriesSuccess({
          results: data,
          message: "",
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_loading(false);
  };

  return (
    <div>
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.partsCategories)}
        </Typography.Title>
        <Button
          onClick={() =>
            set_category({
              id: 0,
              name: "",
              parent: null,
            })
          }
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
        />
      </div>

      <Divider style={{ marginTop: 10 }} />

      <List
        dataSource={categories}
        loading={getPartsCategoriesStatus === "loading" || loading}
        renderItem={(item: IPartCategory, index: number) => (
          <PartCategoryCard
            onEdit={(x: IPartCategory) => set_category({ parent: null, name: x.name, id: x.id })}
            item={item}
            deep={1}
            onDelete={(id: number) => {
              onDelete(id);
            }}
          />
        )}
      />

      <Modal
        footer={null}
        destroyOnClose={true}
        title={
          category?.id === 0
            ? t(TRANSLATION_KEY.newItemCategory)
            : t(TRANSLATION_KEY.editItemCategory)
        }
        visible={!!category}
        onCancel={() => {
          set_category(undefined);
        }}
      >
        <PartCategoryForm
          categories={categories}
          category={category}
          close={() => set_category(undefined)}
        />
      </Modal>
    </div>
  );
};

export default PartsCategories;
