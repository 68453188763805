import { CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Modal, Popconfirm, Space, Typography, message } from "antd";
import { t, use } from "i18next";
import React, { useEffect } from "react";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { IPanelAsset, IRequestPanel } from "../../../../models/settings";
import WorkRequestPanel from "../../../../componentsform/WorkRequestPanel";
import Table, { ColumnsType } from "antd/lib/table";
import { IAsset } from "../../../../models/asset";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getRequestPanelDetailsXHR,
  getRequestPanelXHR,
} from "../../../../store/reducers/settings/actionCreator";
import moment from "moment";
import RequestPanelAsset from "./RequestPanelAsset";
import { ICreatedBy } from "../../../../models/parts";
import PreviewButton from "../../../../components/PreviewButton";
import AccountModalPreview from "../../../account/components/AccountModalPreview";
import api, { failedQueue, isRefreshing } from "../../../../services";
import { settingsSlice } from "../../../../store/reducers/settings";
import { useLocation } from "react-router-dom";

const RequestPanels: React.FC = () => {
  const [search, set_search] = React.useState("");
  const [addPanel, set_addPanel] = React.useState<IRequestPanel>();
  const [seeAsset, set_seeAsset] = React.useState<number>();
  const [delLoading, set_delLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    getRequestPanelStatus,
    requestPanels,
    requestPanelDetails,
    getRequestPanelDetailsStatus,
  } = useAppSelector((state) => state.settingsReducer);
  const [selectedAccount, set_selectedAccount] = React.useState<number | undefined>(undefined);
  useEffect(() => {
    // Get data from server
    getRequestPanelXHR(
      {
        successCallback: () => {
          // Do something
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
  }, []);

  const deletePanel = async (id: number) => {
    let token = await localStorage.getItem("token");
    try {
      let response = await api.delete<string>(`/settings/request_panel/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr: IRequestPanel[] = [...requestPanels];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      dispatch(
        settingsSlice.actions.getRequestPanelSuccess({
          results: arr,
          message: "",
        }),
      );
      set_delLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => deletePanel(id));
        }
        return;
      }
      set_delLoading(false);

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
  };

  const handleCopyOnClick = (text: string) => {
    navigator.clipboard.writeText(text);
    message.success(t(TRANSLATION_KEY.copiedToClipboard));
  };

  const columns: ColumnsType<IRequestPanel> = [
    {
      title: t(TRANSLATION_KEY.name),
      dataIndex: "name",
      key: "name",
    },
    // Add more columns for other properties you want to display

    // Example column for rendering assets
    {
      title: t(TRANSLATION_KEY.asset),
      dataIndex: "assets",
      key: "assets",
      render: (text, panel) => (
        <Button
          onClick={() => {
            set_seeAsset(panel.id);
          }}
          type="link"
        >
          {t(TRANSLATION_KEY.seeMore)}
        </Button>
      ),
    },

    {
      title: t(TRANSLATION_KEY.reportedBy),
      dataIndex: "",
      render: (text: string, value: IRequestPanel) => {
        return (
          <>
            <PreviewButton
              isActive={value.created_by?.is_active}
              title={value.created_by?.name}
              id={value.created_by?.id}
              url={`/app/humanresources/${value.created_by?.id}`}
              permissionsRequired={["manage_user"]}
              onClick={() => {
                set_selectedAccount(value.created_by?.id);
              }}
            />
            <Typography.Text type="secondary">{moment(value.created_at).format()}</Typography.Text>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      render: (text: string, value: IRequestPanel) => (
        <Space>
          <Button
            onClick={() =>
              handleCopyOnClick(
                `${window.location.origin}/requestpanel/?token=${value.token}&panel=${value.id}`,
              )
            }
            type="link"
          >
            <CopyOutlined />{" "}
          </Button>

          <Button type="text" onClick={() => set_addPanel(value)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title={t(TRANSLATION_KEY.continueWithAction)}
            onConfirm={() => {
              deletePanel(value.id);
            }}
            okText={t(TRANSLATION_KEY.yes)}
            cancelText={t(TRANSLATION_KEY.no)}
          >
            <Button type="text" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 10 }}>
          {t(TRANSLATION_KEY.requestPanels)}
        </Typography.Title>

        {/* Search input */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <Input.Search
            value={search}
            onChange={({ target: { value } }) => set_search(value)}
            allowClear
            style={{ marginRight: 18 }}
            placeholder={t(TRANSLATION_KEY.searchPanels)}
          />
          <Button
            htmlType="submit"
            type="primary"
            onClick={() =>
              set_addPanel({
                id: 0,
                name: "",
                assets: [],
                created_by: null,
                is_active: true,
                updated_at: "",
                created_at: "",
                token: "",
              })
            }
            shape="circle"
            icon={<PlusOutlined />}
          />
        </div>
      </div>
      <div style={{ marginTop: 24 }}>
        <Table
          dataSource={requestPanels.filter((x) =>
            x.name.toLowerCase().includes(search.toLowerCase()),
          )}
          loading={getRequestPanelStatus === "loading" || delLoading}
          columns={columns}
        />
      </div>
      <Modal
        title={
          addPanel === undefined || addPanel.id === 0
            ? t(TRANSLATION_KEY.addPanel)
            : t(TRANSLATION_KEY.editPanel)
        }
        visible={!!addPanel}
        onCancel={() => set_addPanel(undefined)}
        footer={null}
        destroyOnClose
      >
        {addPanel && (
          <WorkRequestPanel requestPanel={addPanel} onClose={() => set_addPanel(undefined)} />
        )}
      </Modal>

      <Modal
        visible={!!selectedAccount}
        onCancel={() => {
          set_selectedAccount(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {!!selectedAccount && <AccountModalPreview id={selectedAccount} />}
      </Modal>
      <Drawer
        title={
          t(TRANSLATION_KEY.panelAssets) + " " + requestPanels.find((x) => x.id === seeAsset)?.name
        }
        placement="right"
        closable={true}
        onClose={() => set_seeAsset(undefined)}
        visible={!!seeAsset}
        width={600}
      >
        {seeAsset && <RequestPanelAsset panelID={seeAsset} />}
      </Drawer>
    </div>
  );
};

export default RequestPanels;
