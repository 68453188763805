import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  TreeSelect,
  Typography,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../components/SelectTagAdd";
import { CUSTOM_FIELD_HALF_KEY, INPUT_NUMBER_FORMATTER, TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IAsset, IRelevantLink } from "../models/asset";
import { assetSlice } from "../store/reducers/asstes";
import {
  addAssetXHR,
  editSingleAssetXHR,
  getAssetCategoriesXHR,
  getAssetXHR,
  getConnectedPartsXHR,
  getSubAssetsXHR,
} from "../store/reducers/asstes/actionCreators";
import { ICategory } from "../models/asset";
import { ButtonSaveType, IApiResponse, ILocation } from "../models";
import {
  getCompanyCusstomFieldsXHR,
  getCompanySettingsXHR,
  getLocationsXHR,
} from "../store/reducers/settings/actionCreator";
import { settingsSlice } from "../store/reducers/settings";
import { unstable_batchedUpdates } from "react-dom";
import { Link } from "react-router-dom";
import { ASSET_TYPE } from "../helpers/consts";
import { createTreeSelect, generateCustomFieldValues, hasPermission } from "../helpers/functions";
import { ICompanyCustomFields, ICompanySettings, ICustomValue } from "../models/settings";
import GenerateForCustomFields from "./GenerateForCustomFields";
import FormItem from "antd/lib/form/FormItem";
import { accountsXHR } from "../store/reducers/accounts/actionCreators";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";
import { InfoCircleOutlined } from "@ant-design/icons";

export interface IAssetForm {
  name: string;
  parent: number | null;
  description: string;
  autopilot: boolean;
  type: string | null;
  // hour_price: number | null;
  buy_price: number | null;
  relevant_links: IRelevantLink[];
  supplier: number | null;
  category: number;
  location: number | null;
  can_report_order: boolean;
  buy_price_currency: string | null | undefined;
  custom_id?: string | null;
  accounts: number[];
  all_accounts: boolean;
}

interface IProps {
  asset: IAsset | undefined;
  addingFromOrder: boolean;
  close: (id?: number, autopilot?: boolean, location?: number) => void;
  hideSaveAndAdd?: boolean;
}

const { Option, OptGroup } = Select;

const AssetForm: React.FC<IProps> = ({ asset, close, addingFromOrder, hideSaveAndAdd }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { categories, assetList, editSingleAssetStatus, addAssetStatus } = useAppSelector(
    (state) => state.assetReducer,
  );

  const { companyCustomFields, currencies, companyCustomFieldsV2 } = useAppSelector(
    (state) => state.settingsReducer,
  );
  const { locations } = useAppSelector((state) => state.settingsReducer);
  const { accounts } = useAppSelector((state) => state.accountsReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const [connectedPartsLoading, set_connectedPartsLoading] = useState<boolean>(false);
  const [relevant_links, set_relevant_links] = useState<IRelevantLink[]>(
    asset?.relevant_links || [],
  );
  const [selectedCategory, set_selectedCategory] = useState<number>();
  const [moreDataVisible, set_moreDataVisible] = useState<boolean>(false);
  const [addCategoryVisible, set_addCategoryVisible] = useState<boolean>(false);
  const [warehouse, set_warehouse] = useState<boolean>(asset?.type === ASSET_TYPE.wrh);
  const [autopilot, set_autopilot] = useState<boolean>(!!asset?.autopilot || true);
  const [timeoutToSee, set_timeoutToSee] = useState<boolean>(false);
  const [allUsers, set_allUsers] = useState<boolean>(true);
  const [canReportOrder, set_canReportOrder] = useState<boolean>(
    asset ? !!asset?.can_report_order : true,
  );
  const [addLocationVisible, set_addLocationVisible] = useState<boolean>(false);
  const [relevantLink, set_relevantLink] = useState<IRelevantLink>();
  const [loadingSave, set_loadingSave] = useState<ButtonSaveType>();

  const inputNameRef = React.useRef<Input>(null);

  const initialValues: Partial<IAssetForm> = {
    name: asset?.name,
    parent: asset?.parent?.id || null,
    description: asset?.description,
    autopilot: autopilot,
    type: asset?.type,
    // hour_price: asset?.hour_price,
    buy_price: asset?.buy_price,
    supplier: asset?.supplier?.id,
    category: asset?.category.id,
    location: asset?.location?.id,
    can_report_order: canReportOrder,
    buy_price_currency: asset?.buy_price_currency
      ? asset?.buy_price_currency
      : user.account.company.currency,
    custom_id: asset?.custom_id,
    accounts: accounts.map((x) => x.id) || [],
  };

  useEffect(() => {
    form.setFieldsValue({ accounts: accounts.map((x) => x.id) || [] });
  }, [accounts]);

  useEffect(() => {
    setTimeout(() => {
      set_timeoutToSee(true);
    }, 1000);
    getLocationsXHR({}, dispatch);
    getAssetCategoriesXHR({}, dispatch);
    if (user.account.permissions.filter((x) => x.codename === "manage_user").length > 0) {
      accountsXHR(
        {
          queryParams: {
            limit: 9999,
          },
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        },
        dispatch,
      );
    }

    if (!assetList.length) {
      getAssetXHR({}, dispatch);
    }
    getCompanyCusstomFieldsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data: IApiResponse<ICompanyCustomFields>) => {
          // if update set custom field values
          if (!!asset) {
            form.setFieldsValue(
              generateCustomFieldValues(
                companyCustomFields?.asset_custom_fields || [],
                asset.custom_fields,
              ),
            );
          }
        },
      },
      dispatch,
    );
    if (!!asset) {
      set_selectedCategory(asset.category.id);
    }
  }, []);

  const onFinish = (
    values: Omit<IAssetForm, "relevant_links"> & {
      [key: string]: ICustomValue[];
    },
  ) => {
    let custom_fields: ICustomValue[] = [];

    Object.keys(values).map((key) => {
      if (key.includes(CUSTOM_FIELD_HALF_KEY)) {
        let tmp = companyCustomFields?.asset_custom_fields.find((x) => x.key === key);
        custom_fields.push({
          key: key,
          name: tmp?.name || "",
          value: values[key]?.toString() || "",
        });
      }
    });

    const body = {
      ...values,
      parent: values.parent || null,
      relevant_links: relevant_links,
      description: values.description || "",
      type: warehouse ? ASSET_TYPE.wrh : null,
      location: values.location || null,
      buy_price: values.buy_price || null,
      autopilot,
      can_report_order: canReportOrder,
      supplier: null,
      custom_fields,
      all_accounts: allUsers,
      custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.asset),
    };

    if (asset) {
      // update
      editSingleAssetXHR(
        {
          body: { ...body, custom_id: values.custom_id },
          id: asset?.id.toString(),
          errorCallback: (err) => {
            if (err.response.data?.message?.custom_field) {
              message.error(
                t(err.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  err.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          successCallback: (data) => {
            if (data.results) {
              message.success(t(TRANSLATION_KEY.assetSuccesfulyUpdateted));
              let tmp = [...assetList];
              let index = tmp.findIndex((x) => x.id === asset.id);
              tmp[index] = {
                ...data.results,
              };
              dispatch(
                assetSlice.actions.getAssetSuccess({
                  message: "",
                  results: tmp,
                }),
              );
              if (loadingSave === "save") {
                close();
              }
              if (loadingSave === "saveAndAdd") {
                saveAndAdd();
              }
            }
          },
        },
        dispatch,
      );
      return;
    }

    addAssetXHR(
      {
        body,
        errorCallback: (err) => {
          if (err.response.data?.message?.custom_field) {
            message.error(
              t(err.response.data.message.message || "").replace(
                "$_dynamic_column",
                err.response.data.message.custom_field,
              ),
            );
            return;
          }

          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          message.success(t(TRANSLATION_KEY.assetSuccesfulyAdded));
          unstable_batchedUpdates(() => {
            form.resetFields();
            set_relevant_links([]);
            set_autopilot(false);
            set_warehouse(false);
          }, []);

          if (loadingSave === "save") {
            close(data.results?.id, data.results?.autopilot || false, data.results?.location?.id);
            set_loadingSave(undefined);
          }
          if (loadingSave === "saveAndAdd") {
            saveAndAdd();
          }
        },
      },
      dispatch,
    );
  };

  const saveAndAdd = () => {
    form.resetFields();
    set_relevant_links([]);
    set_autopilot(true);
    set_warehouse(false);
    inputNameRef.current?.focus();
    set_loadingSave(undefined);
  };

  const addTagSwitch = (): JSX.Element | undefined => {
    if (addCategoryVisible) {
      return (
        <SelectTagAdd
          title={t(TRANSLATION_KEY.newAssetCategory)}
          url="assets/categories/"
          set_visible={set_addCategoryVisible}
          visible={addCategoryVisible}
          successCallback={(tag: any, added: string) => {
            // nije dobro, rijesiti kad bude vremena
            const resFake: IApiResponse<ICategory[]> = {
              message: "",
              results: tag,
            };

            dispatch(assetSlice.actions.getAssetCategoriesSuccess(resFake));
            set_selectedCategory(tag?.find((x) => x.name === added)?.id);
            form.setFieldsValue({ category: tag?.find((x) => x.name === added)?.id });
          }}
        />
      );
    }
    if (addLocationVisible) {
      return (
        <SelectTagAdd
          title={t(TRANSLATION_KEY.addLocation)}
          url="settings/locations/"
          set_visible={set_addLocationVisible}
          visible={addLocationVisible}
          successCallback={(tag: Tag) => {
            const data = [...locations];
            data.push({ id: tag.id, name: tag.name });
            const resFake: IApiResponse<ILocation[]> = {
              message: "",
              results: data,
            };

            dispatch(settingsSlice.actions.getLocationsSuccess(resFake));

            form.setFieldsValue({ location: tag.id });
          }}
        />
      );
    }
    return undefined;
  };

  const tree = createTreeSelect(
    assetList
      .filter((x) => x.id !== asset?.id)
      .map((x) => ({
        value: x.id.toString(),
        title: x.name,
        parent_id: x.parent_id?.toString() || null,
        disabled: false,
      })),
    null,
  );

  return (
    <>
      <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
        {asset && (
          <Form.Item
            name="custom_id"
            label={t(TRANSLATION_KEY.code)}
            // rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name={"name"}
          label={t(TRANSLATION_KEY.name)}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
        >
          <Input ref={inputNameRef} />
        </Form.Item>
        <Form.Item
          name={"category"}
          rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          label={t(TRANSLATION_KEY.assetCategory)}
        >
          <Select
            onSelect={(id: number) => set_selectedCategory(id)}
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (typeof option?.children === "string") {
                const str: string = option.children;
                return str.toLowerCase().includes(input.toLowerCase());
              }
              return false;
            }}
            showSearch={true}
            notFoundContent={
              <ButtonAddSelectTag
                addingVisible={addCategoryVisible}
                set_addingVisible={set_addCategoryVisible}
              />
            }
          >
            {categories.map((x) => (
              <Option value={x.id} key={x.id}>
                {t(x.trans_key || x.name)}
              </Option>
            ))}
            <OptGroup
              label={
                <ButtonAddSelectTag
                  addingVisible={addCategoryVisible}
                  set_addingVisible={set_addCategoryVisible}
                />
              }
            />
          </Select>
        </Form.Item>

        <Form.Item name={"parent"} label={t(TRANSLATION_KEY.parentAsset)}>
          <TreeSelect
            loading={connectedPartsLoading}
            treeData={tree}
            allowClear
            showSearch
            onSelect={(a, b) => {
              set_connectedPartsLoading(true);
              getConnectedPartsXHR(
                {
                  id: a,
                  queryParams: {
                    limit: 10,
                    offset: 0,
                  },
                  errorCallback: (error) => {
                    set_connectedPartsLoading(false);
                    message.error(TRANSLATION_KEY.errorOnGetData, 9);
                  },
                  successCallback: (res) => {
                    set_connectedPartsLoading(false);
                    if (!res.results) {
                      return;
                    }
                    if (res.results?.length > 0) {
                      message.info(t(TRANSLATION_KEY.linkedItemsMessage));
                    }
                  },
                },
                dispatch,
              );
            }}
            filterTreeNode={(search, item: any) => {
              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
          />
        </Form.Item>
        {!asset && (
          <>
            <Form.Item
              style={{
                marginBottom: accounts.length < 1 ? 3 : undefined,
                height: allUsers ? 24 : undefined,
              }}
              label={
                <Space>
                  {t(TRANSLATION_KEY.addAllUsersToAsset)}{" "}
                  <Switch
                    checked={allUsers}
                    onChange={() => {
                      form.setFieldsValue({ accounts: [] });
                      set_allUsers(!allUsers);
                    }}
                  />
                </Space>
              }
              name={"accounts"}
            >
              <Select
                maxTagCount={6}
                style={{ maxHeight: 549, display: allUsers ? "none" : undefined }}
                disabled={allUsers}
                allowClear
                showSearch
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
              >
                {accounts.map((x) => (
                  <Select.Option value={x.id} key={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {accounts.length < 1 && timeoutToSee && (
              <Alert
                type="warning"
                message={t(TRANSLATION_KEY.usersRequired)}
                banner
                style={{ marginBottom: 20 }}
              />
            )}
          </>
        )}

        <Form.Item name={"location"} label={t(TRANSLATION_KEY.location)}>
          <Select
            allowClear={true}
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (typeof option?.children === "string") {
                const str: string = option.children;
                return str.toLowerCase().includes(input.toLowerCase());
              }
              return false;
            }}
            showSearch={true}
            notFoundContent={
              <ButtonAddSelectTag
                addingVisible={addLocationVisible}
                set_addingVisible={set_addLocationVisible}
              />
            }
          >
            {locations.map((x) => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
            <OptGroup
              label={
                <ButtonAddSelectTag
                  addingVisible={addLocationVisible}
                  set_addingVisible={set_addLocationVisible}
                />
              }
            />
          </Select>
        </Form.Item>
        <div
          style={{
            marginBottom: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Button
            style={{ display: "block" }}
            block
            type="dashed"
            onClick={() => set_moreDataVisible(!moreDataVisible)}
          >
            {moreDataVisible ? t(TRANSLATION_KEY.close) : t(TRANSLATION_KEY.addMoreData)}
          </Button>
        </div>
        <div style={{ display: moreDataVisible ? "" : "none" }}>
          <Form.Item name={"description"} label={t(TRANSLATION_KEY.description)}>
            <Input.TextArea rows={3} />
          </Form.Item>

          <div className="spaceBetweenRow">
            <Form.Item
              label={
                <Space align="center">
                  <Typography.Text>{t(TRANSLATION_KEY.autopilot)}</Typography.Text>
                  <Tooltip style={{ textAlign: "center" }} title={t(TRANSLATION_KEY.infoAutopilot)}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
            >
              <Checkbox checked={autopilot} onChange={() => set_autopilot(!autopilot)} />
            </Form.Item>

            <Form.Item
              label={
                <Space align="center">
                  <Typography.Text>{t(TRANSLATION_KEY.canReportOrder)}</Typography.Text>
                  <Tooltip
                    style={{ textAlign: "center" }}
                    title={t(TRANSLATION_KEY.infoReportWorkOrder)}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
            >
              <Checkbox
                disabled={addingFromOrder}
                checked={canReportOrder}
                onChange={() => set_canReportOrder(!canReportOrder)}
              />
            </Form.Item>
            <Form.Item label={t(TRANSLATION_KEY.warehouse)}>
              <Checkbox checked={warehouse} onChange={() => set_warehouse(!warehouse)} />
            </Form.Item>
          </div>
          <FormItem label={t(TRANSLATION_KEY.buyPrice)} style={{ width: "100%" }}>
            <div className="spaceBetweenRow" style={{ width: "100%", gap: 20 }}>
              <Form.Item name={"buy_price"} style={{ width: "100%" }}>
                <InputNumber
                  style={{ width: "100%", maxWidth: "100%" }}
                  {...INPUT_NUMBER_FORMATTER}
                />
              </Form.Item>
              <Form.Item name="buy_price_currency">
                <Select>
                  {currencies.map((x, index) => (
                    <Select.Option key={index} value={x}>
                      {x}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </FormItem>

          {/* Dynamic columns */}
          {companyCustomFieldsV2 && selectedCategory && (
            <GenerateForCustomFieldsV2
              values={asset?.custom_fields_v2}
              condition={selectedCategory}
              customFields={companyCustomFieldsV2.asset}
              form={form}
            />
          )}
        </div>

        <Form.Item>
          <Space>
            <Button
              onClick={() => set_loadingSave("save")}
              loading={
                (editSingleAssetStatus === "loading" || addAssetStatus === "loading") &&
                loadingSave === "save"
              }
              htmlType="submit"
              type="primary"
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
            {!hideSaveAndAdd && (
              <Button
                style={{ display: asset ? "none" : "" }}
                onClick={() => set_loadingSave("saveAndAdd")}
                loading={
                  (editSingleAssetStatus === "loading" || addAssetStatus === "loading") &&
                  loadingSave === "saveAndAdd"
                }
                htmlType="submit"
                type="primary"
              >
                {t(TRANSLATION_KEY.saveAndAdd)}
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
      {addTagSwitch()}
    </>
  );
};

export default AssetForm;
