import { message, Modal, Spin, Typography } from "antd";
import onScan from "onscan.js";
import React, { useEffect, useRef } from "react";
import { useAppSelector } from "./hooks";
import RFID_ICON from "./assets/icons/rfid.png";
import { t } from "i18next";
import { TRANSLATION_KEY } from "./helpers/consts";
let isLocked = false;
const ScanBinder = ({ close, callback, loading, requireLogin }) => {
  useEffect(() => {
    // callback("0011304376");
    // return;
    onScan.attachTo(document, {
      suffixKeyCode: [13],
      reactToPaste: true,
      onScan: function (scanCode, iQty) {
        if (isLocked) {
          return;
        }
        callback(scanCode);
        isLocked = true;
      },
    });
    return () => {
      isLocked = false;
      onScan.detachFrom(document);
    };
  }, []);

  return (
    <>
      <Modal
        footer={null}
        closable={true}
        onCancel={() => {
          close();
          isLocked = false;
        }}
        visible={true}
        centered
      >
        <Spin size="large" spinning={loading}>
          {/* <Typography.Title
          level={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          Skeniraj svoju naruknicu
        </Typography.Title> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "40px 20px",
            }}
          >
            <img
              src={RFID_ICON}
              alt="RFID"
              style={{
                width: 72,
                height: 72,
                marginBottom: 20,
              }}
            />
            <Typography.Title level={4}>{t(TRANSLATION_KEY.scanItem)}</Typography.Title>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ScanBinder;
