import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, message, Select, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import React, { useState } from "react";
import { connect } from "react-redux";
import FilesComponent from "../components/Files";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../components/SelectTagAdd";
import { INPUT_NUMBER_FORMATTER, MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IApiResponse, IUsualExpense } from "../models";
import { IExpense } from "../models/maintenances";
import { IFile, IUser } from "../models/user";
import { RootState } from "../store";
import { addExpenseXHR, editExpenseXHR } from "../store/reducers/maintenance/actionCreator";
import { settingsSlice } from "../store/reducers/settings";

interface IProps {
  order_info: number | string | undefined;
  expense: Omit<IExpense, "order_info"> & { order_info?: string | number };
  close: () => void;
  user: IUser | null;
}

export interface IAddExpense {
  order_info: number | string | undefined;
  usual_expense: number;
  value: number;
  currency: string;
}

export interface IForm {
  tag: number;
  price: number;
  price_uom: string;
}

const AddExpenseForm: React.FC<IProps> = ({ order_info, expense, close, user }) => {
  const [expenseForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const { usualExpenses, currencies } = useAppSelector((state) => state.settingsReducer);
  const [previewFiles, set_PreviewFiles] = useState<RcFile[]>([]);

  const [addTagVisible, set_addTagVisible] = useState<boolean>(false);

  function beforeUpload(file: RcFile, files: RcFile[]) {
    set_PreviewFiles([...previewFiles, ...files]);
    return false;
  }

  function onAddExpense(values: IForm) {
    const data: IAddExpense = {
      order_info: order_info,
      usual_expense: values.tag,
      value: values.price,
      currency: values.price_uom,
    };

    let fd = new FormData();

    previewFiles.forEach((x) => {
      fd.append("files", x);
    });

    fd.append("data", JSON.stringify(data));

    if (expense.id !== 0) {
      editExpenseXHR(
        {
          body: fd,
          id: expense.id,
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
          successCallback: () => {
            message.success(t(TRANSLATION_KEY.successOnSaveData));
            close();
          },
        },
        dispatch,
      );
    } else {
      addExpenseXHR(
        {
          body: fd,
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
          successCallback: () => {
            message.success(t(TRANSLATION_KEY.successOnSaveData));
            close();
          },
        },
        dispatch,
      );
    }
  }

  return (
    <>
      {" "}
      <Form
        initialValues={{
          price_uom: expense.id === 0 ? user?.account.company.currency : expense.currency,
          price: expense.id === 0 ? undefined : expense.value,
          tag: expense.id === 0 ? undefined : expense.usual_expense.id,
        }}
        form={expenseForm}
        layout="vertical"
        onFinish={onAddExpense}
      >
        <Form.Item name="tag" label={t(TRANSLATION_KEY.tag)}>
          <Select
            notFoundContent={
              <ButtonAddSelectTag
                addingVisible={addTagVisible}
                set_addingVisible={set_addTagVisible}
              />
            }
          >
            {usualExpenses.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}

            <Select.OptGroup
              label={
                <ButtonAddSelectTag
                  addingVisible={addTagVisible}
                  set_addingVisible={set_addTagVisible}
                />
              }
            />
          </Select>
        </Form.Item>

        <div style={{ display: "flex", gap: 24 }}>
          <Form.Item
            style={{ width: "100%" }}
            name="price"
            label={t(TRANSLATION_KEY.price)}
            rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          >
            <InputNumber style={{ width: "100%" }} {...INPUT_NUMBER_FORMATTER} />
          </Form.Item>

          <Form.Item style={{ width: 120 }} name="price_uom" label={t(TRANSLATION_KEY.priceUom)}>
            <Select disabled={false}>
              {currencies.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Upload
          multiple
          showUploadList={false}
          accept={MATERIAL_EXTENSIONS}
          beforeUpload={beforeUpload}
        >
          <Button icon={<UploadOutlined />} shape="circle" type="primary" />
        </Upload>
        <div style={{ margin: "12px 0" }}>
          <FilesComponent
            files={convertRcFileToIFile(previewFiles)}
            onDelete={() => {}}
            hideEditButton
            size="small"
            layout="list"
          />
        </div>
        <Form.Item>
          <Button style={{ float: "right" }} htmlType="submit" type="primary">
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form.Item>
      </Form>
      {/* Files list */}
      <SelectTagAdd
        title={t(TRANSLATION_KEY.addExpense)}
        url="maintenance/usual_expense/"
        set_visible={set_addTagVisible}
        visible={addTagVisible}
        successCallback={(tag: Tag) => {
          const data = [...usualExpenses];
          data.push({ id: tag.id, name: tag.name });
          const resFake: IApiResponse<IUsualExpense[]> = {
            message: "",
            results: data,
          };
          dispatch(settingsSlice.actions.getUsualExpensesSuccess(resFake));
          expenseForm.setFieldsValue({ tag: tag.id });
        }}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps)(AddExpenseForm);

export const convertRcFileToIFile = (files: RcFile[]): IFile[] => {
  let tmpfiles: IFile[] = [];
  files.map((item) => {
    const _file = URL.createObjectURL(item);
    tmpfiles.push({
      id: +item.lastModified,
      name: item.name,
      file: _file,
      extension: item.type.split("/")[1],
    });
  });

  return tmpfiles;
};
