import {
  Button,
  Divider,
  Drawer,
  Empty,
  message,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IChecklist, ITask } from "../../../../models/settings";
import api from "../../../../services";
import { settingsSlice } from "../../../../store/reducers/settings";
import {
  getChecklistsXHR,
  setChecklistTaskRankXHR,
} from "../../../../store/reducers/settings/actionCreator";

// Components
import TaskForm from "../../../../componentsform/TaskForm";
import TaskCard from "./components/TaskCard";
import { DeleteOutlined, EditOutlined, FileDoneOutlined, PlusOutlined } from "@ant-design/icons";
import SortableList from "../../../../components/Sortable/List";
import ChecklistForm from "../../../../componentsform/ChecklistForm";

export type CardVariant = "hidden" | "visible";

const CheckList: React.FC = ({}) => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [loading, set_loading] = useState<boolean>(false);
  const [checklist, set_checklist] = useState<IChecklist>();
  const [currentChecklist, set_currentChecklist] = useState<number>();
  const [addTaskVisible, set_addTaskVisible] = useState<boolean>(false);
  const { checklists, getChecklistsStatus } = useAppSelector((state) => state.settingsReducer);

  // Methods
  useEffect(() => {
    getChecklistsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data) => {
          if (data.results) {
            if (data.results.length > 0) {
              set_currentChecklist(data.results[0].id);
            }
          }
        },
      },
      dispatch,
    );
  }, []);

  useEffect(() => {
    console.log(checklists, currentChecklist);
    if (checklists.length > 0) {
      // let tmp = checklists.find(x => x.id === currentChecklist);
      // if(tmp){
      // 	set_currentChecklist(tmp.id);
      // }else{
      // 	set_currentChecklist(checklists[0].id);
      // }
    }
  }, [checklists]);

  const onDelete = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`maintenance/checklist/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });
      const arr: IChecklist[] = [...checklists];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);
      dispatch(
        settingsSlice.actions.getChecklistsSuccess({
          results: arr,
          message: "",
        }),
      );
      if (arr.length > 0) {
        set_currentChecklist(arr[0].id);
      } else {
        set_currentChecklist(undefined);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onTaskDelete = async (id: number, checklist: IChecklist) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/maintenance/checklist_task/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      let taskIndex = checklist.tasks.findIndex((x) => x.id === id);
      let arr = [...checklist.tasks];
      arr.splice(taskIndex, 1);
      set_currentChecklist(checklist.id);

      let checklistIndex = checklists.findIndex((x) => x.id === checklist.id);
      let arr2 = [...checklists];
      arr2[checklistIndex] = { ...checklist, tasks: arr };
      dispatch(settingsSlice.actions.getChecklistsSuccess({ results: arr2, message: "" }));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const onTabChange = (key: string) => {
    let clickedChecklist = checklists.find((item) => item.id === Number(key));
    if (clickedChecklist) {
      set_currentChecklist(clickedChecklist.id);
    }
  };

  const onDragEnd = (
    task_id: number,
    index: number,
    checklists: IChecklist[],
    current_clist: number,
  ) => {
    setChecklistTaskRankXHR(
      {
        id: task_id,
        body: { rank: index + 1 },
        successCallback: (data) => {
          getChecklistsXHR({}, dispatch); // ugasena vatra
          // alert("success");
          // console.log(checklist, current_clist, "KKKKKurac")
          // let toUpdateChecklist = checklists.find(x => x.id === current_clist);
          // if(!toUpdateChecklist){
          // 	return;
          // }
          // toUpdateChecklist = {
          // 	...toUpdateChecklist,
          // 	tasks:  data.results.map((x) => ({ ...x, checklist: toUpdateChecklist }))
          // }
          // console.log(toUpdateChecklist, "toUpdateChecklist");
          // let arr = [...checklists];
          // let index = arr.findIndex(x => x.id === current_clist);
          // if(index > -1){
          // 	arr[index] = toUpdateChecklist;
          // }
          // dispatch(
          //   settingsSlice.actions.getChecklistsSuccess({
          // 	message: "",
          // 	results: arr,
          //   })
          // );
        },
        errorCallback: () => message.error(t("TRANSLATION_KEY.errorOnSaveData")),
      },
      dispatch,
    );
  };

  const tmpChecklist = checklists?.find((c) => c.id === currentChecklist);

  return (
    <div style={{ paddingBottom: 10, minHeight: 340 }}>
      {/* Title */}
      <div className="spaceBetweenRow" style={{ paddingTop: 10 }}>
        <Typography.Title level={5}>{t(TRANSLATION_KEY.checklists)}</Typography.Title>
        <Space size="middle">
          {currentChecklist && (
            <Button
              onClick={() => set_addTaskVisible(true)}
              shape="circle"
              icon={<PlusOutlined />}
            />
          )}
          <Button
            onClick={() => {
              set_checklist({ id: 0, name: "", tasks: [], static: false });
            }}
            type="primary"
            shape="circle"
            icon={<FileDoneOutlined />}
          />
        </Space>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <div
        className="spaceBetweenRow"
        style={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {/* Checklists */}
        <Spin spinning={getChecklistsStatus === "loading" || loading} style={{ width: "100%" }}>
          <Tabs
            tabPosition="left"
            activeKey={currentChecklist?.toString()}
            onChange={onTabChange}
            style={{ height: "100%" }}
          >
            {checklists.map((x: IChecklist) => (
              <Tabs.TabPane
                key={x.id}
                tab={
                  <Space>
                    {x.name}
                    <Button
                      size="small"
                      shape="circle"
                      icon={<EditOutlined style={{ transform: "translateX(5px)" }} />}
                      onClick={(e) => {
                        e.preventDefault();
                        set_checklist(x);
                      }}
                    />
                    <Popconfirm
                      title={t(TRANSLATION_KEY.continueWithAction)}
                      onConfirm={(e) => (e?.preventDefault(), onDelete(x.id))}
                      okText={t(TRANSLATION_KEY.yes)}
                      cancelText={t(TRANSLATION_KEY.no)}
                    >
                      <Button
                        size="small"
                        shape="circle"
                        icon={<DeleteOutlined style={{ transform: "translateX(5px)" }} />}
                      />
                    </Popconfirm>
                  </Space>
                }
              />
            ))}
          </Tabs>
        </Spin>

        {/* Tasks */}
        {checklists?.length > 0 && (
          <div style={{ width: "100%" }}>
            <Spin spinning={getChecklistsStatus === "loading" || loading}>
              {tmpChecklist && tmpChecklist?.tasks?.length > 0 ? (
                <SortableList
                  gridGap={12}
                  gridColumnSize={260}
                  itemStyle={{ borderRadius: 8 }}
                  onDragEnd={(id, index) => onDragEnd(id, index, checklists, tmpChecklist.id || -1)}
                  data={
                    tmpChecklist?.tasks
                      ? [...tmpChecklist.tasks].sort((a, b) => (a?.rank || 0) - (b?.rank || 0))
                      : []
                  }
                  renderItem={(item: ITask, index: number) => (
                    <>
                      <TaskCard
                        key={item?.description}
                        task={item}
                        onDelete={() => onTaskDelete(item.id, tmpChecklist)}
                        index={index}
                      />
                    </>
                  )}
                />
              ) : (
                <>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </>
              )}
            </Spin>
          </div>
        )}
      </div>

      <Modal
        title={
          checklist?.id !== 0
            ? t(TRANSLATION_KEY.editChecklistName)
            : t(TRANSLATION_KEY.newChecklist)
        }
        visible={!!checklist}
        onCancel={() => set_checklist(undefined)}
        footer={null}
        destroyOnClose
      >
        {checklist && (
          <ChecklistForm
            checklist={checklist}
            onClose={(c) => {
              set_currentChecklist(c.id);
              set_checklist(undefined);
            }}
          />
        )}
      </Modal>

      {/* Add task drawer */}
      <Drawer
        width={540}
        visible={addTaskVisible}
        onClose={() => set_addTaskVisible(false)}
        title={t(TRANSLATION_KEY.newTask)}
        destroyOnClose={true}
      >
        <TaskForm
          addingType="order"
          onClose={(task) => {
            if (!task) {
              return;
            }
            const arr = [...checklists];
            const checklistIndex = arr.findIndex((x) => x.id === currentChecklist);
            const _tasks = [...arr[checklistIndex].tasks];
            _tasks.push(task);

            arr[checklistIndex] = { ...arr[checklistIndex], tasks: _tasks };
            set_currentChecklist(arr[checklistIndex].id);
            dispatch(
              settingsSlice.actions.getChecklistsSuccess({
                message: "",
                results: arr,
              }),
            );

            set_addTaskVisible(false);
          }}
          checklist={currentChecklist || null}
        />
      </Drawer>
    </div>
  );
};

export default CheckList;
