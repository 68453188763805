import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
  TableColumnType,
  Tooltip,
  Typography,
  message,
} from "antd";
import { IRent, IRentStatus, RentNote } from "../../../models/clients";
import DatePickerCustom, { CalendarTypes } from "../../../components/DatePickerCustom";
import moment from "moment";
import {
  closeRentOrderXHR,
  deleteRemtOrderXHR,
  getAssetRentOrdersXHR,
  getClientsXHR,
  getRentedAssetsXHR,
  updateRentOrderDescriptionXHR,
} from "../../../store/reducers/clients/actionCreators";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import PreviewButton from "../../../components/PreviewButton";
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AssetModalPreview from "../components/AssetModalPreview";
import AccountModalPreview from "../../account/components/AccountModalPreview";
import { clientsSlice } from "../../../store/reducers/clients";
import ClientsSelect from "../../../componentsform/ClientsSelect";
import ClientTableItem from "../../../components/ClientTableItem";
import { assetSlice } from "../../../store/reducers/asstes";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { ILoadMoreParams } from "../../../models";
import { Link } from "react-router-dom";
import { getLastItem } from "../../../helpers/functions";

interface IProps {}

interface IRentedAssetsFilters {
  status: IRentStatus[];
  search: string;
  pickerType: CalendarTypes;
  clients: number[];
}

const LIMIT = 3;

const LOCAL_STORAGE_KEY = "rentsFilters" + window.location.pathname;

const AssetRents: React.FC<IProps> = () => {
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const { user } = useAppSelector((state) => state.userReducer);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [search, set_search] = useState("");
  const [delLoading, set_delloading] = useState(-1);
  const [closeLoading, set_closeLoading] = useState(-1);
  const [visibleDatePickerCustom, set_visibleDatePickerCustom] = useState(true);
  const [showLoadMore, set_showLoadMore] = useState(false);
  const { updateRentOrderDescriptionStatus, rents, getRentedAssetsStatus } = useAppSelector(
    (state) => state.clientsReducer,
  );
  const { asset } = useAppSelector((state) => state.assetReducer);
  const [oneDescription, set_oneDescription] = useState<{ rent_id: number; text: string }>();
  const textAreaRef = useRef<any>(null);

  const [filters, set_filters] = useState<IRentedAssetsFilters>({
    status: [],
    search: "",
    pickerType: "month",
    clients: [],
  });

  const dispatch = useAppDispatch();

  const getRentAsset = async (_filters: IRentedAssetsFilters, mergeData: boolean) => {
    await localStorage.setItem(LOCAL_STORAGE_KEY + user.account.id, JSON.stringify(_filters));

    getRentedAssetsXHR(
      {
        errorCallback: (data: any) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          set_filters(_filters);
          set_visibleDatePickerCustom(true);
        },
        successCallback: (res) => {
          if (res.results && res.results.length < LIMIT) {
            set_showLoadMore(false);
          } else {
            set_showLoadMore(true);
          }
          set_visibleDatePickerCustom(true);
          set_filters(_filters);
        },
        mergeData,
        queryParams: {
          limit: LIMIT,
          offset: mergeData ? rents.length : 0,
          clients: _filters.clients.join(","),
          assets: asset.id,
        },
      },
      dispatch,
    );
    set_filters(_filters);
  };

  const closeRentOrder = (order: Omit<IRent, "asset">) => {
    set_closeLoading(order.id);
    const fd = new FormData();
    fd.append("files", JSON.stringify([]));
    fd.append("data", JSON.stringify({ description: "" }));

    closeRentOrderXHR(
      {
        body: fd,
        id: order.id,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          set_closeLoading(-1);
        },
        successCallback: () => {
          removeClientFromAssetAfterCloseOrDeleteRentOrder(), set_closeLoading(-1);
        },
      },
      dispatch,
    );
  };

  const removeClientFromAssetAfterCloseOrDeleteRentOrder = () => {
    let _asset = { ...asset };
    _asset.rented_to_client = null;
    dispatch(
      assetSlice.actions.getSingleAssetSuccess({
        results: _asset,
        message: "",
      }),
    );
  };

  const onLoad = async () => {
    let tmp: string | null | IRentedAssetsFilters = await localStorage.getItem(
      LOCAL_STORAGE_KEY + user.account.id,
    );
    if (tmp) {
      let tmpf = JSON.parse(tmp) as IRentedAssetsFilters;
      set_search(tmpf.search);
      getRentAsset(tmpf, false);
    } else {
      getRentAsset(filters, false);
    }
  };

  useEffect(() => {
    if (asset.id === 0) return;
    getClientsXHR(
      {
        queryParams: {
          offset: 0,
          limit: 100,
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
    onLoad();

    return () => {
      dispatch(
        clientsSlice.actions.getRentedAssetsSuccess({
          results: [],
          message: "",
          mergeData: false,
          noSaveToStore: false,
        }),
      );
    };
  }, [asset.id]);

  const deleteRentOrder = (order: Omit<IRent, "asset">) => {
    set_delloading(order.id);
    deleteRemtOrderXHR(
      {
        id: order.id,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          set_delloading(-1);
        },
        successCallback: () => {
          let _rents = rents.filter((r) => r.id !== order.id);
          dispatch(
            clientsSlice.actions.getAssetRentOrdersSuccess({
              results: _rents,
              message: "",
            }),
          );
          removeClientFromAssetAfterCloseOrDeleteRentOrder();
          set_delloading(-1);
        },
      },
      dispatch,
    );
  };

  const _columns: TableColumnType<Omit<IRent, "asset">>[] = [
    {
      title: t(TRANSLATION_KEY.rentCode),
      dataIndex: "custom_id",
      render: (text, record) => (
        <Link to={`/app/rentorder/${record.id}/`}>#{record.custom_id}</Link>
      ),
    },
    {
      title: t(TRANSLATION_KEY.status),
      dataIndex: "status",
      render: (text, record) => (
        <Typography.Text>{t(TRANSLATION_KEY[record.status])}</Typography.Text>
      ),
    },
    {
      title: t(TRANSLATION_KEY.location),
      dataIndex: "location",
      render: (text, record) => record.location?.name || "-",
    },
    {
      title: t(TRANSLATION_KEY.note),
      dataIndex: "description",
      render: (_text, record) => {
        let text = getLastItem<RentNote>(record.notes)?.note || "-";
        return (
          <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: text }}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.createdBy),
      dataIndex: "created_by",
      render: (text, value) => (
        <div>
          {value.created_by ? (
            <>
              <PreviewButton
                isActive={value.created_by.is_active}
                title={value.created_by.name}
                id={value.created_by.id}
                url={`/app/humanresources/${value.created_by.id}`}
                permissionsRequired={["manage_user"]}
                onClick={() => {
                  value.created_by && set_selectedAccount(value.created_by.id);
                }}
              />
              <Typography.Text type="secondary">
                {moment(value.created_at).format(user.account.date_format || "LLL")}
              </Typography.Text>
            </>
          ) : (
            <Typography.Text>-</Typography.Text>
          )}
        </div>
      ),
    },

    {
      title: t(TRANSLATION_KEY.confirmedBy),
      dataIndex: "closed_by",
      render: (text, value) => {
        if (!value.closed_by) {
          return <Typography.Text type="secondary">-</Typography.Text>;
        }
        return (
          <div>
            <PreviewButton
              isActive={value.closed_by.is_active}
              title={value.closed_by.name}
              id={value.closed_by.id}
              url={`/app/humanresources/${value.closed_by.id}`}
              permissionsRequired={["manage_user"]}
              onClick={() => {
                set_selectedAccount(value.closed_by?.id);
              }}
            />
            <Typography.Text type="secondary">
              {moment(value.closed_at).format(user.account.date_format || "LLL")}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.client),
      dataIndex: "clients",
      render: (text, value) => {
        return (
          <ClientTableItem
            is_active={value.client?.is_active || false}
            name={value.client?.name}
            id={value.client?.id}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <Space>
          <Popconfirm
            onConfirm={() => {
              closeRentOrder(record);
            }}
            title={t(TRANSLATION_KEY.clientAssetRentDeleteMessage)}
          >
            <Button
              style={{ visibility: record.closed_at ? "hidden" : undefined }}
              loading={closeLoading === record.id}
              type="link"
            >
              <CheckOutlined style={{ fontSize: 18 }} />
            </Button>
          </Popconfirm>

          <Popconfirm
            onConfirm={() => {
              deleteRentOrder(record);
            }}
            title={t(TRANSLATION_KEY.continueWithAction)}
          >
            <Button danger loading={delLoading === record.id} type="link">
              <DeleteOutlined style={{ fontSize: 18 }} />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <div style={{ width: 21 }} />

        {visibleDatePickerCustom && (
          <ClientsSelect
            includeArchived={true}
            includeInactive={true}
            onChange={(e: number[] | undefined | number) => {
              if (!Array.isArray(e)) {
                return;
              }
              getRentAsset({ ...filters, clients: e || [] }, false);
            }}
            value={filters.clients}
            placeholder={t(TRANSLATION_KEY.clients)}
            style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
            mode="multiple"
            form={undefined}
            name=""
            label=""
          />
        )}
      </div>
      <Table
        size="small"
        dataSource={rents}
        columns={_columns}
        loading={getRentedAssetsStatus === "loading"}
        pagination={false}
      />
      {showLoadMore && (
        <LoadMoreButton
          loading={getRentedAssetsStatus === "loading"}
          onClick={() => {
            getRentAsset(
              {
                ...filters,
                status: filters.status,
                search: filters.search,
                pickerType: filters.pickerType,
                clients: filters.clients,
              },
              true,
            );
          }}
        />
      )}

      <Modal
        title={t(TRANSLATION_KEY.note)}
        visible={!!oneDescription}
        onCancel={() => {
          set_oneDescription(undefined);
        }}
        centered
        width={600}
        closable={true}
        okButtonProps={{ loading: updateRentOrderDescriptionStatus === "loading" }}
        destroyOnClose
        okText={t(TRANSLATION_KEY.save)}
        cancelText={t(TRANSLATION_KEY.cancel)}
        cancelButtonProps={{ style: { display: "none" } }}
        forceRender
        onOk={() => {
          updateRentOrderDescriptionXHR(
            {
              id: oneDescription?.rent_id || -1,
              body: {
                description: oneDescription?.text || "",
              },
              errorCallback: () => {
                message.error(t(TRANSLATION_KEY.errorOnSaveData));
              },
              successCallback: () => {
                set_oneDescription(undefined);
              },
            },
            dispatch,
          );
        }}
      >
        <Input.TextArea
          ref={textAreaRef}
          rows={6}
          value={oneDescription?.text}
          onChange={(v) => {
            set_oneDescription({
              rent_id: oneDescription?.rent_id || -1,
              text: v.target.value || "",
            });
          }}
        />
        <div />
      </Modal>

      <Modal
        visible={selectedAsset !== undefined || selectedAccount !== undefined}
        onCancel={() => {
          set_selectedAsset(undefined);
          set_selectedAccount(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
      </Modal>
    </>
  );
};

export default AssetRents;
