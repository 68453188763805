import React, { useEffect, useState } from "react";

// Antd
import { message } from "antd";

// Rest
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { getPartEntriesXHR } from "../../../store/reducers/warehouse/actionCreator";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import { ICursor } from "../../../models";
import { warehouseSlice } from "../../../store/reducers/warehouse";
import moment, { Moment } from "moment";
import EntryHistoryTable from "../components/EntryHistoryTable";
import { unstable_batchedUpdates } from "react-dom";
import { debounce } from "../../../helpers/functions";
import { useParams } from "react-router-dom";
import EventBus from "../../../EventBus";

export interface ILocalFilters {
  start: string;
  end: string;
  search: string;
  warehouse: string[];
}

export const enterHistoryRefresh = "enterHistoryRefresh";

const EntryHistory: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { id } = useParams();

  // Variables
  const { partEntries, getPartEntriesStatus } = useAppSelector((state) => state.warehouseReducer);
  const [localFilters, set_localFilters] = useState<ILocalFilters>({
    start: "",
    end: "",
    search: "",
    warehouse: [],
  });

  const [localQueryParams, set_localQueryParams] = useState<ICursor>(partEntries.cursor);

  // Methods
  useEffect(() => {
    EventBus.on(enterHistoryRefresh, () => {
      getPartEntries(partEntries.cursor, localFilters, false);
    });
    return () => {
      EventBus.off(enterHistoryRefresh);
    };
  }, [localFilters]);

  function getPartEntries(queryParams: ICursor, filters: ILocalFilters, mergeData: boolean) {
    if (localQueryParams.cursor === null && mergeData) {
      return;
    }
    getPartEntriesXHR(
      {
        id,
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data) => {
          if (data.results) {
            unstable_batchedUpdates(() => {
              set_localFilters(filters);
              set_localQueryParams(data.results?.cursor!);
            }, []);
          }
        },
        mergeData,
        queryParams: {
          ...queryParams,
          start: filters.start,
          end: filters.end,
          search: filters.search,
          warehouse: filters.warehouse.join("|"),
        },
      },
      dispatch,
    );
  }

  const debounceOnSearch = (obj: ILocalFilters) => {
    getPartEntries(partEntries.cursor, obj, false); // if any filter paramaters change strat load more from start
  };

  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);

  console.log(localFilters);

  return (
    <EntryHistoryTable
      getPartEntries={(filters: ILocalFilters) => {
        getPartEntries(localQueryParams, filters, false);
      }}
      filters={localFilters}
      data={partEntries.data}
      cursor={localQueryParams}
      loading={getPartEntriesStatus === "loading"}
      onCalendarChange={(start: string, end: string) => {
        if (start) {
          getPartEntries(
            partEntries.cursor,
            {
              ...localFilters,
              start,
              end,
            },
            false,
          );
        }
      }}
      onLoadMore={() => {
        getPartEntries(localQueryParams, localFilters, true);
      }}
      onSearch={(search) => {
        let tmp: ILocalFilters = { ...localFilters, search };
        setSearch(tmp);
      }}
    />
  );
};

export default EntryHistory;
