import { accountsSlice } from ".";
import { AppDispatch } from "../..";
import { IApiResponse, IDataToApi } from "../../../models";
import { IAsset } from "../../../models/asset";
import { IAccount, IFormAccount, IUserAccount } from "../../../models/user";
import { IAdminChangePasword } from "../../../pages/account/components/AdminChangePassword";
import api, { failedQueue, isRefreshing } from "../../../services";
export const accountsXHR = async (
  data: Partial<IDataToApi<undefined, IApiResponse<IAccount[]>> & { mergeData: boolean }>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.accounts());
    let response = await api.get<IApiResponse<IAccount[]>>(`accounts/accounts_v2/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      accountsSlice.actions.accountsSucceess({
        ...response.data,
        mergeData: data.mergeData || false,
      }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => accountsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.accountsFail(error.message));
  }
};

export const addUserXHR = async (
  data: Partial<IDataToApi<IFormAccount, IApiResponse<IAccount>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.addUser());
    let response = await api.post<IApiResponse<IAccount>>(
      `accounts/registration/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.addUserSucceess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addUserXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.addUserFail(error.message));
  }
};

export const getAccountXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IUserAccount>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.getAccount());
    let response = await api.get<IApiResponse<IUserAccount>>(`accounts/accounts/${data.id}/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.getAccountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getAccountXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.getAccountFail(error.message));
  }
};

export const updateAccountXHR = async (
  data: Partial<IDataToApi<IFormAccount, IApiResponse<IUserAccount>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.updateAccount());
    let response = await api.put<IApiResponse<IUserAccount>>(
      `accounts/accounts/${data.id}/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.updateAccountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateAccountXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.updateAccountFail(error.message));
  }
};

export const getAccountAssetXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IAsset[]>>>,
  dispatch: AppDispatch,
) => {
  dispatch(accountsSlice.actions.getAccountAsset());

  const token = await localStorage.getItem("token");

  try {
    let response = await api.get<IApiResponse<IAsset[]>>(`accounts/accounts/${data.id}/assets/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.getAccountAssetSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getAccountAssetXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.getAccountAssetFail(error.message));
  }
};

export const joinAssetToAccountXHR = async (
  data: Partial<IDataToApi<{ account: number; assets: number[] }, IApiResponse<IAsset[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.joinAssetToAccount());
    let response = await api.post<IApiResponse<IAsset[]>>(
      `/accounts/join_assets/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.joinAssetToAccountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => joinAssetToAccountXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.joinAssetToAccountFail(error.message));
  }
};

export const removeAssetFromAccountXHR = async (
  data: Partial<IDataToApi<{ account: number; asset: number }, IApiResponse<IAsset[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.removeAssetFromAccount(data.body?.asset || 0));
    let response = await api.post<IApiResponse<IAsset[]>>(
      `/accounts/remove_assets/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.removeAssetFromAccountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => removeAssetFromAccountXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(accountsSlice.actions.removeAssetFromAccountFail(error.message));
  }
};

export const removeAllUserAssetsXHR = async (
  data: Partial<IDataToApi<{ account: number }, IApiResponse<IAsset[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.removeAllUserAssets());
    let response = await api.post<IApiResponse<IAsset[]>>(
      `/accounts/remove_all_assets/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.removeAllUserAssetsSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => removeAllUserAssetsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(accountsSlice.actions.removeAllUserAssetsFail(error.message));
  }
};

export const adminChangePasswordXHR = async (
  data: Partial<IDataToApi<IAdminChangePasword, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(accountsSlice.actions.adminChangePassword());
    let response = await api.put<IApiResponse<string>>(
      `accounts/accounts/${data.id}/admin_change_password/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.adminChangePasswordSuccess(response.data));
  } catch (error: any) {
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => adminChangePasswordXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.adminChangePasswordFail(error.message));
  }
};

export const removeAssetFromPartXHR = async (
  data: Partial<IDataToApi<{ part: number; asset: number }, IApiResponse<IAsset[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.post<IApiResponse<IAsset[]>>(
      `/accounts/remove_assets_from_part/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => removeAssetFromPartXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};
