import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { ILocation } from "../../../../../models";
import { ICategory } from "../../../../../models/asset";
import { motion } from "framer-motion";
import { CardVariant } from "../../CheckList";
import { useState } from "react";
import { IChecklist } from "../../../../../models/settings";
import { colors } from "../../../../../styles/colors";

interface IProps {
  item: ICategory;
  onDelete: (id: number) => void;
  onUpdate: (category: ICategory) => void;
  onClick?: (id: number) => void;
  currentChecklist?: IChecklist;
}

const Card: React.FC<IProps> = ({ item, onDelete, onUpdate, onClick, currentChecklist }) => {
  // Variables
  const [variant, set_variant] = useState<CardVariant>("hidden");

  function handleOnClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (onClick) {
      event.stopPropagation();
      onClick(item.id);
    }
  }

  return (
    <motion.div
      onHoverStart={() => set_variant("visible")}
      onHoverEnd={() => set_variant("hidden")}
      style={{ padding: "4px 8px", paddingRight: 4, minHeight: 40 }}
      className="spaceBetweenRow locationCard"
      onClick={(event) => handleOnClick(event)}
    >
      {/* Name */}
      <div
        style={{
          cursor: onClick ? "pointer" : "default",
          color: currentChecklist?.id === item.id ? colors.hoverColor : colors.globalFontColor,
        }}
      >
        {t(item.trans_key || item.name)}
      </div>

      {/* CTA */}
      <motion.div
        className="spaceBetweenRow"
        initial="hidden"
        animate={variant}
        variants={variants}
        transition={{ ease: "anticipate", duration: 0.35 }}
      >
        {!item.static && (
          <Button onClick={() => onUpdate(item)} type="link" icon={<EditOutlined />} />
        )}
        <div style={{ width: 6 }} />
        {!item.static && (
          <Popconfirm
            onConfirm={() => onDelete(item.id)}
            title={t(TRANSLATION_KEY.continueWithAction)}
          >
            <Button danger type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Card;

const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
