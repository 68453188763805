import React from "react";
import { Result, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TRANSLATION_KEY } from "../helpers/consts";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t(TRANSLATION_KEY.pageNotFound)}
      extra={
        <Button
          onClick={() => {
            navigate("/login");
          }}
          type="primary"
        >
          {t(TRANSLATION_KEY.dashboard)}
        </Button>
      }
    />
  );
};

export default NotFound;
