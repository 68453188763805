import { Button, Divider, message, Modal, Space, Typography } from "antd";
import { t } from "i18next";
import React from "react";
import QRCode from "react-qr-code";
import { TRANSLATION_KEY } from "../../../helpers/consts";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const PLAY_STORE_LINK: string = "https://play.google.com/store/apps/details?id=io.metrikon";
const APP_STORE_LINK: string = "https://apps.apple.com/hr/app/metrikon/id6450926768";

const PlayStoreModal = ({ visible, setVisible }: Props) => {
  // Variables

  // Methods
  function handleCopyLink() {
    navigator.clipboard.writeText(PLAY_STORE_LINK).then(() => {
      message.success(t(TRANSLATION_KEY.copyLinkSuccess));
    });
    setVisible(false);
  }

  return (
    <Modal visible={visible} onCancel={() => setVisible(false)} footer={null}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          width: "100%",
          position: "relative",
        }}
      >
        <Space direction="vertical" align="center" style={{ width: "100%", position: "relative" }}>
          {/* QR Code */}
          <div
            style={{
              padding: 40,
              width: 218,
              height: 218,
              borderRadius: 200,
              position: "relative",
            }}
          >
            <div className="qr-code-modal" />
            <QRCode size={132} value={PLAY_STORE_LINK} />
          </div>

          {/* Text */}
          <Typography.Title
            level={4}
            style={{ textAlign: "center", marginTop: 10, fontWeight: 500, opacity: 0.85 }}
          >
            {t(TRANSLATION_KEY.scanCode)}
          </Typography.Title>
        </Space>

        {/* Divider */}
        <Divider style={{ marginBottom: 32 }}>{t(TRANSLATION_KEY.or)}</Divider>

        <Button type="primary" block size="large" onClick={handleCopyLink}>
          {t(TRANSLATION_KEY.copyLink)}
        </Button>
      </div>
    </Modal>
  );
};

export default PlayStoreModal;
