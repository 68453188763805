import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../models";
import { IInvitation, IPartner, IPartnerDetails } from "../../../models/partner";
import { IDenyPartner, IPartnerRequest } from "../../../pages/partners/PartnersRequests";

interface ICompanyReducer {
  partners: IPartner[];
  requests: IPartnerRequest[];
  sendPartnerInvitationStatus: string;
  sendEmailStatus: string;
  getPartnersStatus: string;
  getPartnersRequestsStatus: string;
  getPartnerDetailsStatus: string;
  partner: IPartnerDetails;
  confirmDenyPartnerStatus: string;
  removePartnerStatus: string;
  invitationList: IInvitation[];
  getListOfSendedInvitationsStatus: string;
}

const initialState = {
  partners: [],
  sendPartnerInvitationStatus: "",
  requests: [],
  sendEmailStatus: "",
  getPartnersStatus: "",
  getPartnersRequestsStatus: "",
  getPartnerDetailsStatus: "",
  partner: {
    name: "",
    id: 0,
    id_pdv: "",
    sharedAsset: [],
    status: "",
  },
  confirmDenyPartnerStatus: "",
  removePartnerStatus: "",
  invitationList: [],
  getListOfSendedInvitationsStatus: "",
} as ICompanyReducer;

export const companySlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // -----
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    sendPartnerInvitation(state) {
      state.sendPartnerInvitationStatus = "loading";
    },
    sendPartnerInvitationSuccess(state, action: PayloadAction<IApiResponse<IPartner>>) {
      if (action.payload.results) {
        let tmp = [...state.partners];
        tmp.push(action.payload.results);
        state.partners = tmp;
      }
      state.sendPartnerInvitationStatus = "";
    },
    sendPartnerInvitationFail(state, action: PayloadAction<string>) {
      state.sendPartnerInvitationStatus = action.payload;
    },

    // -----

    sendEmail(state) {
      state.sendEmailStatus = "loading";
    },
    sendEmailSuccess(state, action: PayloadAction<IApiResponse<number>>) {
      state.sendEmailStatus = "";
    },
    sendEmailFail(state, action: PayloadAction<string>) {
      state.sendEmailStatus = action.payload;
    },

    // -----

    getPartners(state) {
      state.getPartnersStatus = "loading";
    },
    getPartnersSuccess(state, action: PayloadAction<IApiResponse<IPartner[]>>) {
      if (action.payload.results) {
        state.partners = action.payload.results;
      }
      state.getPartnersStatus = "";
    },
    getPartnersFail(state, action: PayloadAction<string>) {
      state.getPartnersStatus = action.payload;
    },

    // -----

    getPartnersRequests(state) {
      state.getPartnersRequestsStatus = "loading";
    },
    getPartnersRequestsSuccess(state, action: PayloadAction<IApiResponse<IPartnerRequest[]>>) {
      if (action.payload.results) {
        state.requests = action.payload.results;
      }
      state.getPartnersRequestsStatus = "";
    },
    getPartnersRequestsFail(state, action: PayloadAction<string>) {
      state.getPartnersRequestsStatus = action.payload;
    },

    // -----

    confirmDenyPartner(state) {
      state.confirmDenyPartnerStatus = "loading";
    },
    confirmDenyPartnerSuccess(state, action: PayloadAction<IApiResponse<IPartner>>) {
      state.confirmDenyPartnerStatus = "";
    },
    confirmDenyPartnerFail(state, action: PayloadAction<string>) {
      state.confirmDenyPartnerStatus = action.payload;
    },

    // -----

    getPartnerDetails(state) {
      state.getPartnerDetailsStatus = "loading";
    },
    getPartnerDetailsSuccess(state, action: PayloadAction<IApiResponse<IPartnerDetails>>) {
      if (action.payload.results) {
        state.partner = action.payload.results;
      }
      state.getPartnerDetailsStatus = "";
    },
    getPartnerDetailsFail(state, action: PayloadAction<string>) {
      state.getPartnerDetailsStatus = action.payload;
    },

    removePartner(state, action: PayloadAction<string>) {
      state.removePartnerStatus = action.payload.toString();
    },
    removePartnerSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.removePartnerStatus = "";
    },
    removePartnerFail(state, action: PayloadAction<string>) {
      state.removePartnerStatus = action.payload;
    },

    // -----

    getListOfSendedInvitations(state) {
      state.getListOfSendedInvitationsStatus = "loading";
    },
    getListOfSendedInvitationsSuccess(state, action: PayloadAction<IApiResponse<IInvitation[]>>) {
      if (action.payload.results) {
        state.invitationList = action.payload.results;
      }
      state.getListOfSendedInvitationsStatus = "";
    },
    getListOfSendedInvitationsFail(state, action: PayloadAction<string>) {
      state.getListOfSendedInvitationsStatus = action.payload;
    },
  },
});

export default companySlice.reducer;
