import React, { useEffect, useState } from "react";

// Antd
import { Button, Divider, Form, InputNumber, message, Select, Tooltip, TreeSelect } from "antd";

// Rest
import { IMAGE_TYPES, TRANSLATION_KEY, INPUT_NUMBER_FORMATTER } from "../helpers/consts";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IPart, IPartDetails, IPartStorage } from "../models/parts";
import { addPartToStorageXHR } from "../store/reducers/warehouse/actionCreator";
import { getAssetXHR } from "../store/reducers/asstes/actionCreators";
import { getSuppliersXHR } from "../store/reducers/supplier/actionCreator";
import { RcFile } from "antd/lib/upload";
import { compressImage, createTreeSelect } from "../helpers/functions";
import { IAsset } from "../models/asset";
import { IApiResponse, ICursor, IParent } from "../models";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IUser } from "../models/user";
import { RootState } from "../store";
import { connect } from "react-redux";
import { warehouseSlice } from "../store/reducers/warehouse";
import EventBus from "../EventBus";
import { enterHistoryRefresh } from "../pages/part/pages/EntryHistory";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";
import { CustomFieldValues } from "../models/settings";

export interface IAddPartToStorage {
  qty: number;
  qty_uom: string;
  location: number;
  supplier: number | null;
  price: number;
  price_uom: string;
  custom_fields_v2: CustomFieldValues;
}

interface IProps {
  cursor?: ICursor;
  part: IPart;
  close: () => void;
  user: IUser | null;
}

const AddPartToStorage: React.FC<IProps> = ({ part, close, user, cursor }) => {
  // Hooks
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  // Variables
  const [files, set_files] = useState<RcFile[]>([]);
  const { addPartToStorageStatus, parts } = useAppSelector((state) => state.warehouseReducer);
  const { currencies, companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  const { assetList } = useAppSelector((state) => state.assetReducer);
  const { suppliers } = useAppSelector((state) => state.supplierReducer);

  // Methods
  useEffect(() => {
    getSuppliersXHR({}, dispatch);
    getAssetXHR({ errorCallback: (data: any) => message.error(t("errorOnFetchData")) }, dispatch);
  }, []);

  const onFinish = async (values: IAddPartToStorage) => {
    const form: IAddPartToStorage = {
      ...values,
      qty: values.qty >= 1000000 ? 999999 : values.qty,
      qty_uom: part.uom,
      custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.entry),
    };

    // Form data
    let formData = new FormData();
    formData.append("data", JSON.stringify(form));

    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    // Call axios after all requests
    requests.then(() =>
      addPartToStorageXHR(
        {
          id: part?.id,
          body: formData,
          errorCallback: (error: any) => {
            if (error.response.data?.message?.custom_field) {
              message.error(
                t(error.response.data.message.message || "") +
                  " \n" +
                  error.response.data.message.custom_field,
              );
              return;
            }
            if (error?.response?.data?.message === "qty_too_big") {
              message.error(t(TRANSLATION_KEY.quantityLimit));
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
          successCallback: (data: IApiResponse<IPartDetails>) => {
            if (cursor) {
              let tmpParts = parts.data.map((item) => {
                if (item.id === data.results?.id) {
                  return data.results!;
                }
                return item;
              });
              dispatch(
                warehouseSlice.actions.getPartsSuccess({
                  message: "",
                  mergeData: false,
                  results: {
                    data: tmpParts,
                    cursor,
                  },
                }),
              );
            } else {
              EventBus.emit(enterHistoryRefresh);
            }
            close();
          },
        },
        dispatch,
      ),
    );
  };

  const initialValues: Partial<IAddPartToStorage> = {
    location: undefined,
    supplier: undefined,
    price: undefined,
    price_uom: user?.account.company.currency,
    qty_uom: undefined,
    qty: undefined,
  };

  let warehouse = assetList.filter((x) => x.type === "wrh");

  warehouse = warehouse.map((x) => ({
    ...x,
    parent_id: x.parent_id
      ? warehouse.some((y) => y.id === x.parent_id)
        ? x.parent_id
        : null
      : null,
  }));

  let selectTreeData = createTreeSelect(
    warehouse.map((x) => ({
      title: x.name,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );
  return (
    <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="qty"
        label={t(TRANSLATION_KEY.qty)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <InputNumber
          style={{ width: "100%" }}
          // max={1000000}
          decimalSeparator=","
          onChange={(value: number) => {
            if (value >= 999999) {
              form.setFieldsValue({ qty: 999999 });
            }
          }}
          formatter={(value: any) => {
            let formattedValue = Number(value) >= 1000000 ? 999999 : value;
            return `${formattedValue}`.replace(".", ",");
          }}
          onBlur={(event) => {
            const { value } = event.target;
            let _value = Number(value) >= 1000000 ? 999999 : value;
            let formattedValue = Number(_value.toString().replace(",", "."));
            form.setFieldsValue({ qty: formattedValue.toFixed(2) });
          }}
        />
      </Form.Item>

      <div style={{ width: "100%", display: "flex", gap: 24 }}>
        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
          <Form.Item
            name="price"
            label={t(TRANSLATION_KEY.price)}
            rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          >
            <InputNumber style={{ width: "100%" }} {...INPUT_NUMBER_FORMATTER} />
          </Form.Item>
          <Tooltip style={{ textAlign: "center" }} title={t(TRANSLATION_KEY.unitPriceInfo)}>
            <InfoCircleOutlined style={{ marginTop: 6, marginLeft: 8 }} />
          </Tooltip>
        </div>

        <div style={{ width: "50%" }}>
          <Form.Item
            name="price_uom"
            label={t(TRANSLATION_KEY.priceUom)}
            rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          >
            <Select>
              {currencies.map((x, index) => (
                <Select.Option key={index} value={x}>
                  {t(x || "")}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <Form.Item
        name="location"
        label={t(TRANSLATION_KEY.location)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <TreeSelect
          treeData={selectTreeData}
          showSearch
          filterTreeNode={(search, item: any) => {
            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          }}
        />
      </Form.Item>

      <Form.Item name="supplier" label={t(TRANSLATION_KEY.supplier)}>
        <Select>
          {suppliers.map((x, index) => (
            <Select.Option key={index} value={x.id}>
              {x.name || ""}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {companyCustomFieldsV2 && (
        <GenerateForCustomFieldsV2
          values={{}}
          condition={undefined}
          customFields={companyCustomFieldsV2.entry}
          form={form}
        />
      )}

      <Divider plain>
        <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
      </Divider>

      <Form.Item>
        <Button
          loading={addPartToStorageStatus === "loading"}
          htmlType="submit"
          type="primary"
          style={{ marginTop: 24 }}
        >
          {t(TRANSLATION_KEY.save)}
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(AddPartToStorage);
