import React, { useState } from "react";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { ISupplierCategory } from "../../../../../models/supplier";
import { FormModal } from "../../../../../componentsform/CustomFieldsFormV2";
import CustomFieldsTable from "../Components/CustomFieldsTable";
import { useAppSelector } from "../../../../../hooks";

interface IProps {
  supplierCategories: ISupplierCategory[];
  onEdit: (data: FormModal) => void;
}
const Supplier: React.FC<IProps> = ({ supplierCategories, onEdit }) => {
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  return (
    <div>
      <CustomFieldsTable
        condition={supplierCategories.map((x) => ({
          ...x,
          trans_key: undefined,
        }))}
        onEdit={onEdit}
        conditionTitle={t(TRANSLATION_KEY.supplierCategories)}
        customFieldsData={companyCustomFieldsV2.suppliers}
        formType="suppliers"
      />
    </div>
  );
};

export default Supplier;
