import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, List, message, Modal, Spin, Typography } from "antd";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";

import { ICON_SIZE } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IApiResponse } from "../../../../../models";
import { ICategory } from "../../../../../models/asset";
import api, { failedQueue, isRefreshing } from "../../../../../services";
import { assetSlice } from "../../../../../store/reducers/asstes";
import Card from "./Card";

const AssetCategories: React.FC = () => {
  const { categories, getAssetCategoriesStatus } = useAppSelector((state) => state.assetReducer);

  const [loading, set_loading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [categoryName, set_categoryName] = useState<string>("");

  const [category, set_category] = useState<ICategory>();

  const inputRef = useRef<Input>(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus({ cursor: "end" });
    }, 300);
  }, [category]);

  const onDelete = async (id: number) => {
    set_loading(true);

    let token = await localStorage.getItem("token");

    try {
      let response = api.delete<string>(`/assets/categories/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr: ICategory[] = [...categories];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      dispatch(
        assetSlice.actions.getAssetCategoriesSuccess({
          results: arr,
          message: "",
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };
  const onActionUpdate = async () => {
    set_loading(true);

    let token = await localStorage.getItem("token");

    try {
      let response = await api.put<IApiResponse<ICategory[]>>(
        `/assets/categories/${category?.id}/`,
        { name: categoryName },
        {
          headers: { Authorization: "Bearer " + token },
        },
      );

      dispatch(
        assetSlice.actions.getAssetCategoriesSuccess({
          results: response.data.results,
          message: "",
        }),
      );
      set_category(undefined);
      set_categoryName("");
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onActionUpdate());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  const addCategory = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.post<IApiResponse<ICategory[]>>(
        `/assets/categories/`,
        { name: categoryName },
        {
          headers: { Authorization: "Bearer " + token },
        },
      );
      dispatch(
        assetSlice.actions.getAssetCategoriesSuccess({
          results: response.data.results,
          message: "",
        }),
      );
      set_categoryName("");
      set_category(undefined);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => addCategory());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };
  return (
    <div>
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.assetCategory)}
        </Typography.Title>

        <Button
          onClick={() => {
            set_category({ name: "", id: 0, static: false, trans_key: "" });
          }}
          shape="circle"
          icon={<PlusOutlined />}
          type="primary"
        />
      </div>

      <Divider style={{ marginTop: 10 }} />

      <List
        loading={loading || getAssetCategoriesStatus === "loading"}
        dataSource={categories}
        renderItem={(x: ICategory) => (
          <Card
            item={x}
            onDelete={onDelete}
            onUpdate={() =>
              unstable_batchedUpdates(() => {
                set_category(x);
                set_categoryName(t(x.trans_key || x.name || ""));
              }, [])
            }
          />
        )}
      />

      <Modal
        destroyOnClose={true}
        title={
          category?.id === 0
            ? t(TRANSLATION_KEY.newAssetCategory)
            : t(TRANSLATION_KEY.editAssetCategory)
        }
        footer={null}
        okButtonProps={{ disabled: !categoryName, htmlType: "submit" }}
        okText={t(TRANSLATION_KEY.save)}
        cancelText={t(TRANSLATION_KEY.cancel)}
        visible={!!category}
        onCancel={() => set_category(undefined)}
      >
        <Form
          onFinish={() => {
            if (category?.id === 0) {
              addCategory();
            } else {
              onActionUpdate();
            }
          }}
        >
          <Input
            ref={inputRef}
            value={categoryName}
            onChange={({ target: { value } }) => {
              set_categoryName(value);
            }}
          />
          <Button style={{ marginTop: 24 }} htmlType="submit" type="primary">
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default AssetCategories;
