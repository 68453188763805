import React from "react";
import { t } from "i18next";

// Antd
import { Descriptions, Typography } from "antd";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppSelector } from "../../hooks";
import CustomFieldsSpecGenerator from "../../components/CustomFieldsSpecGenerator";

// Rest

const ClientSpec: React.FC = () => {
  // Hooks
  const { client, updateClientStatus } = useAppSelector((state) => state.clientsReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  return (
    <Descriptions column={3}>
      <Descriptions.Item label={t(TRANSLATION_KEY.email)}>{client?.email}</Descriptions.Item>
      <Descriptions.Item label={t(TRANSLATION_KEY.description)}>
        <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: client.description }}>
          {client?.description}
        </Typography.Text>
      </Descriptions.Item>

      <Descriptions.Item label={t(TRANSLATION_KEY.phoneNumber)}>
        {client?.phone_number}
      </Descriptions.Item>
      <Descriptions.Item label={t(TRANSLATION_KEY.address)}>{client?.address}</Descriptions.Item>
      {CustomFieldsSpecGenerator({
        condition: undefined,
        formType: "clients",
        data: client.custom_fields_v2,
      })}
    </Descriptions>
  );
};

export default ClientSpec;
