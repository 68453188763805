import { DeleteOutlined, EditOutlined, PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  TableColumnType,
  Typography,
} from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { getAssetCategoriesXHR } from "../../../../../store/reducers/asstes/actionCreators";

import { CustomFieldV2 } from "../../../../../models/settings";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { ICategory } from "../../../../../models/asset";
import { FormModal } from "../../../../../componentsform/CustomFieldsFormV2";
import CustomFieldsTable from "../Components/CustomFieldsTable";

interface IProps {
  assetCategories: ICategory[];
  onEdit: (data: FormModal) => void;
}

const Asset: React.FC<IProps> = ({ assetCategories, onEdit }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAssetCategoriesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);

  return (
    <div>
      <CustomFieldsTable
        formType="asset"
        condition={assetCategories}
        onEdit={onEdit}
        conditionTitle={t(TRANSLATION_KEY.assetCategory)}
        customFieldsData={companyCustomFieldsV2.asset}
      />
    </div>
  );
};

export default Asset;
