import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Columns from "../../../components/Columns";
import PreviewButton from "../../../components/PreviewButton";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { debounce, formatNumber, sortTableColumns } from "../../../helpers/functions";
import { useAppSelector } from "../../../hooks";
import { ILoadMoreParams, ITableColumn } from "../../../models";
import { IPartSpend, IPartSpendList } from "../../../models/parts";
import { warehouseSlice } from "../../../store/reducers/warehouse";
import {
  getSpendPartsOnAssetXHR,
  removePartSpendXHR,
} from "../../../store/reducers/warehouse/actionCreator";
import AccountModalPreview from "../../account/components/AccountModalPreview";
import MaintenanceModalPreview from "../../maintenance/components/ModalPreview";
import AssetModalPreview from "../components/AssetModalPreview";
import PartModalPreview from "../../part/components/PartModalPreview";
import DatePickerCustom, { CalendarTypes } from "../../../components/DatePickerCustom";
import LoadMoreButton from "../../../components/LoadMoreButton";

// Constants
const STORAGE_COLUMNS_NAME = "AssetPartsTable";

type Data = IPartSpend & { search: string };

interface IFilter {
  search: string;
  start: string;
  end: string;
  calendarType: CalendarTypes;
}

const LOCAL_STORAGE_KEY = "AssetPartsTable";
const SIZE_OF_LIMIT = 10;

interface IProps {}

const Parts: React.FC<IProps> = () => {
  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const { spendedPartsOnAsset, getSpendPartsOnAssetStatus } = useAppSelector(
    (state) => state.warehouseReducer,
  );
  const { user } = useAppSelector((state) => state.userReducer);
  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [selectedMaintenance, set_selectedMaintenance] = useState<number>();
  const [loadMoreParams, set_loadMoreParams] = useState<ILoadMoreParams>({
    offset: 0,
    limit: SIZE_OF_LIMIT,
  });
  const [selectedPart, set_selectedPart] = useState<number | undefined>();
  const [showAfterResponse, set_showAfterResponse] = useState<boolean>(false);
  const [hasMore, set_hasMore] = useState<boolean>(true);
  const [filters, set_filters] = useState<IFilter>({
    search: "",
    start: "",
    end: "",
    calendarType: "month",
  });
  const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IPartSpend>[]>([
    {
      title: t(TRANSLATION_KEY.name),
      dataIndex: "name",
      visible: true,
      render: (text, value) => (
        <PreviewButton
          isActive={value.part.is_active}
          title={value.part?.name}
          id={value.part?.id}
          url={`/app/item-details/${value.part?.id}/`}
          permissionsRequired={["manage_warehouse"]}
          onClick={() => {
            set_modalVisible(true);
            set_selectedPart(value.part?.id);
          }}
        />
      ),
    },
    {
      title: t(TRANSLATION_KEY.code),
      dataIndex: "code",
      visible: true,
      render: (text, value) => <span>{value.part.code}</span>,
    },
    {
      title: t(TRANSLATION_KEY.note),
      dataIndex: "note",
      visible: true,
    },
    {
      title: t(TRANSLATION_KEY.qty),
      dataIndex: "qty",
      visible: true,
      render: (text: string, value: IPartSpend) => <span>{formatNumber(value.qty)}</span>,
    },
    {
      title: t(TRANSLATION_KEY.orderNumber),
      dataIndex: "order_number",
      visible: true,
      render: (text: string, value: IPartSpend) => (
        <PreviewButton
          isActive={value.order_info?.is_active}
          title={value.order_info?.order_number}
          id={value.order_info?.id}
          url={`/app/maintenances/${value.order_info?.id}/`}
          onClick={() => {
            set_selectedMaintenance(value.order_info?.id);
          }}
        />
      ),
    },
    {
      title: t(TRANSLATION_KEY.createdBy),
      dataIndex: "created_by",
      visible: true,
      render: (text: string, value: IPartSpend) => (
        <div>
          <PreviewButton
            isActive={value.created_by.is_active}
            title={value.created_by.name}
            id={value.created_by.id}
            url={`/app/humanresources/${value.created_by.id}`}
            permissionsRequired={["manage_user"]}
            onClick={() => {
              set_modalVisible(true);
              set_selectedAccount(value.created_by.id);
            }}
          />
          <Typography.Text type="secondary">
            {moment(value.created_at).format(user.account.date_format || undefined)}
          </Typography.Text>
        </div>
      ),
    },
  ]);

  // Methods
  useEffect(() => {
    // Get data
    getPartSpendList(undefined, loadMoreParams, false);
    // Sort columns
    sortTableColumns(COLUMNS, STORAGE_COLUMNS_NAME + location.pathname, set_COLUMNS);
  }, []);

  const getPartSpendList = async (
    filters: IFilter | undefined,
    loadMoreParams: ILoadMoreParams,
    mergeData: boolean,
  ) => {
    let tmp: null | string = await localStorage.getItem(LOCAL_STORAGE_KEY);
    let _filters = filters || JSON.parse(tmp || "null");

    if (!_filters) {
      _filters = {
        search: "",
        start: moment().startOf("month").toISOString(true),
        end: moment().endOf("month").toISOString(true),
        calendarType: "month",
      };
    }

    getSpendPartsOnAssetXHR(
      {
        mergeData,
        queryParams: {
          ...loadMoreParams,
          start_date: _filters.start,
          end_date: _filters.end,
          search: _filters.search,
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          set_showAfterResponse(true);
        },
        successCallback: (res) => {
          if (res.results && res.results?.length < SIZE_OF_LIMIT) {
            set_hasMore(false);
          } else {
            set_hasMore(true);
          }
          set_filters({ ..._filters, search: _filters.search });
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(_filters));
          set_showAfterResponse(true);
        },
        id,
      },
      dispatch,
    );
  };

  const debounceOnSearch = (obj: IFilter) => {
    getPartSpendList(obj, { limit: SIZE_OF_LIMIT, offset: 0 }, false); // if any filter paramaters change strat load more from start
  };

  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);
  return (
    <div style={{ marginTop: 6 }}>
      {/* Header */}
      <div className="spaceBetweenRow" style={{ marginBottom: 12 }}>
        {/* Columns */}
        <div>
          <Columns
            columns={COLUMNS}
            memorizedName={STORAGE_COLUMNS_NAME + location.pathname}
            onChange={set_COLUMNS}
          />
        </div>

        {showAfterResponse && (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 12 }}>
              <DatePickerCustom
                selectStyle={{ marginRight: 12 }}
                pickerStyle={{ maxWidth: 360 }}
                start={filters.start}
                end={filters.end}
                type={filters.calendarType}
                skipInitial
                onChange={(start, end, type) => {
                  if (!type) return;
                  getPartSpendList(
                    {
                      ...filters,
                      start,
                      end,
                      calendarType: type,
                    },
                    {
                      offset: 0,
                      limit: SIZE_OF_LIMIT,
                    },
                    false,
                  );
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Info */}
              <Tooltip title={t(TRANSLATION_KEY.searchByName)}>
                <InfoCircleOutlined />
              </Tooltip>
              {/* Search */}
              <Input.Search
                onChange={({ target: { value } }) => {
                  setSearch({
                    ...filters,
                    search: value.toLowerCase(),
                  });
                }}
                defaultValue={filters.search}
                style={{ marginRight: 18, marginLeft: 12, maxWidth: 320 }}
              />
            </div>
          </div>
        )}
      </div>

      {/* Table */}
      <Table
        size="small"
        dataSource={spendedPartsOnAsset}
        columns={COLUMNS.filter((x) => x.visible)}
        rowKey={(item: IPartSpend) => item.id}
        pagination={false}
        loading={getSpendPartsOnAssetStatus === "loading"}
      />
      {hasMore && (
        <LoadMoreButton
          loading={false}
          onClick={() => {
            getPartSpendList(
              filters,
              {
                offset: spendedPartsOnAsset.length,
                limit: SIZE_OF_LIMIT,
              },
              true,
            );
          }}
        >
          {t(TRANSLATION_KEY.loadMore)}
        </LoadMoreButton>
      )}

      {/* Modal */}
      <Modal
        visible={modalVisible || !!selectedMaintenance}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedAccount(undefined);
          set_selectedAsset(undefined);
          set_selectedMaintenance(undefined);
          set_selectedPart(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
        {!!selectedMaintenance && <MaintenanceModalPreview id={selectedMaintenance} />}
        {selectedPart && <PartModalPreview id={selectedPart} />}
      </Modal>
    </div>
  );
};

export default Parts;
