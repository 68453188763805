import { Spin } from "antd";
import { AnimatePresence, motion } from "framer-motion";

type IProps = {
  isLoading: boolean;
};

const Loader = ({ isLoading }: IProps) => {
  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          initial={{ opacity: 0, backgroundColor: "#00000000" }}
          animate={{ opacity: 1, backgroundColor: "#00000020" }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 1234,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Loader;
