import { MoreOutlined } from "@ant-design/icons";
import { Button, Popover, Space } from "antd";
import React, { useMemo } from "react";

type Props = {
  children: React.ReactNode[];
  title: string;
  limit?: number;
};

/**
 * Renders a group of preview buttons with a popover for additional buttons.
 * @param {React.ReactNode[]} props.children - The array of React nodes representing the preview buttons.
 * @param {string} props.title - The title of the preview button group.
 * @param {number} [props.limit=3] - The maximum number of buttons to display before showing a popover.
 * @returns {JSX.Element} The rendered component.
 */
const PreviewButtonGroup = ({ children, title, limit = 3 }: Props): JSX.Element => {
  // Variables
  const LIMIT: number = limit;
  const numberOfButtons = useMemo(() => children.length, [children]);

  // Content

  if (numberOfButtons > LIMIT) {
    return (
      <div>
        <Group limit={LIMIT} title={title}>
          {children}
        </Group>
      </div>
    );
  }

  return <div>{children}</div>;
};

export default PreviewButtonGroup;

function Group({ limit, children, title }: Props) {
  let titleWrapper = title
    ? useMemo(() => <div style={{ padding: "10px 0" }}>{title}</div>, [title])
    : undefined;
  let contentWrapper = useMemo(
    () => (
      <Space direction="vertical" size="small" style={{ minWidth: 300, paddingBottom: 4 }}>
        {children}
      </Space>
    ),
    [children],
  );
  return (
    <Space>
      {children.slice(0, limit)}
      <Popover content={contentWrapper} title={titleWrapper} trigger="click">
        <Button size="small" shape="circle" icon={<MoreOutlined />} />
      </Popover>
    </Space>
  );
}
