import React, { useState } from "react";
import { RcFile } from "antd/lib/upload";
import api, { failedQueue, isRefreshing } from "../../../../../services";
import { IApiResponse } from "../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { userSlice } from "../../../../../store/reducers/user";
import { message } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";

const useAvatar = () => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    user,
    user: {
      account,
      account: { company },
    },
  } = useAppSelector((state) => state.userReducer);

  // Methods
  const setAvatar = async (file: RcFile) => {
    setIsLoading(true);

    const token = await localStorage.getItem("token");
    const formData = new FormData();
    formData.append("files", file);

    try {
      let response = await api.put<IApiResponse<{ avatar: string }>>(
        `settings/company_avatar/`,
        formData,
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        const data = { ...company };
        data.avatar = response.data.results.avatar;
        dispatch(
          userSlice.actions.loginSucceess({
            message: "",
            results: { ...user, account: { ...account, company: data } },
          }),
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => setAvatar(file));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    setIsLoading(false);
    return false;
  };

  const deleteAvatar = async () => {
    setIsLoading(true);

    const token = await localStorage.getItem("token");

    try {
      let response = await api.put<IApiResponse<{ avatar: string }>>(
        `settings/company_avatar/`,
        null,
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        const data = { ...company };
        data.avatar = response.data.results.avatar;
        dispatch(
          userSlice.actions.loginSucceess({
            message: "",
            results: { ...user, account: { ...account, company: data } },
          }),
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => deleteAvatar());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    setIsLoading(false);
    return false;
  };

  return {
    isLoading,
    setAvatar,
    deleteAvatar,
  };
};

export default useAvatar;
