import { Button } from "antd";
import { t } from "i18next";
import React from "react";
import { TRANSLATION_KEY } from "../helpers/consts";

interface IProps {
  onClick: () => void;
  loading: boolean;
  text?: string;
}

const LoadMoreButton: React.FC<IProps> = ({ onClick, loading, text }) => {
  return (
    <div style={{ padding: "9px 0", textAlign: "center" }}>
      <Button loading={loading} onClick={onClick} type="dashed" style={{ width: "300px" }}>
        {text || t(TRANSLATION_KEY.loadMore)}
      </Button>
    </div>
  );
};

export default LoadMoreButton;
