import {
  Button,
  Col,
  Divider,
  Input,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
  Tree,
  Typography,
} from "antd";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getAccountAssetXHR,
  joinAssetToAccountXHR,
  removeAllUserAssetsXHR,
  removeAssetFromAccountXHR,
} from "../../../store/reducers/accounts/actionCreators";
import { getAssetXHR } from "../../../store/reducers/asstes/actionCreators";
import AssetNode from "../../asset/tree/asset";
import { IAsset } from "../../../models/asset";
import {
  createTree,
  debounce,
  hasPermission,
  setItemsWithoutParentToHighestNode,
} from "../../../helpers/functions";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { unstable_batchedUpdates } from "react-dom";
import { IApiResponse, NewTreeItem } from "../../../models";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import { findFirstMissingItem } from "../../settings/pages/Role";

export type TreeNodeType = {
  title: string | JSX.Element;
  id: string | null;
  category: string | null;
  location: string | null;
  custom_id: string | null;
  key: string;
  children: TreeNodeType[];
  disableCheckbox?: boolean;
};

const Asset: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { assetList } = useAppSelector((state) => state.assetReducer);
  const [accountAssetTree, set_accountAssetTree] = useState<NewTreeItem<IAsset>[]>([]);
  const { user } = useAppSelector((state) => state.userReducer);
  const [render, set_render] = useState<boolean>(false);
  const [assetListCheckable, set_assetListCheckable] = useState<TreeNodeType[]>([]);

  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [changeHappend, set_changeHappend] = useState<boolean>(false);
  const {
    accountAsset,
    getAccountAssetStatus,
    joinAssetToAccountStatus,
    account,
    removeAssetFromAccountStatus,
  } = useAppSelector((state) => state.accountsReducer);
  const [expandedKeys, set_expandedKeys] = useState<React.Key[]>([]);
  const [cehckedKeys, set_checkedKeys] = useState<React.Key[]>([]);
  const [modalAssetsVisible, set_modalAssetsVisible] = useState(false);
  const [search, set_search] = useState<string>("");

  useEffect(() => {
    getAccountAssetXHR({ id }, dispatch);
  }, []);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      const selectedAssets: React.Key[] = accountAsset.map((x) => x.id.toString());

      set_assetListCheckable(
        createTreeWithChekable(
          assetList,
          null,
          accountAsset.map((x) => x.id),
          handleOpenModal,
        ),
      );
      // set_expandedKeys(selectedAssets);
      set_checkedKeys(selectedAssets);
      set_accountAssetTree(createTree(setItemsWithoutParentToHighestNode(accountAsset), null));
    }, []);
  }, [assetList, accountAsset]);

  const onCheck: any = (checkedKeysValue: React.Key[]) => {
    unstable_batchedUpdates(() => {
      if (checkedKeysValue.length < cehckedKeys.length) {
        let checkedItem = findFirstMissingItem(
          cehckedKeys as string[],
          checkedKeysValue as string[],
        );
        set_checkedKeys(checkedKeysValue.filter((x) => x !== checkedItem));
      } else {
        set_checkedKeys([...checkedKeysValue, ...cehckedKeys]);
      }
      set_changeHappend(true);
    }, []);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    set_expandedKeys(expandedKeysValue);
  };

  const onSaveUserAsset = async (keys?: React.Key[]) => {
    const keyToStrig = (x: React.Key) => {
      if (typeof x === "string") {
        return x;
      }
      return "";
    };
    joinAssetToAccountXHR(
      {
        body: {
          account: parseInt(id || ""),
          assets: keys
            ? keys.map((x) => parseInt(keyToStrig(x)))
            : cehckedKeys.map((x) => parseInt(keyToStrig(x))),
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          set_modalAssetsVisible(false);
        },
      },
      dispatch,
    );
  };

  function removeAllAsset() {
    removeAllUserAssetsXHR(
      {
        body: { account: parseInt(id || "") },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnDeleteData)),
        successCallback: (data: IApiResponse<IAsset[] | null>) => {
          if (!data.results) {
            message.warning(t(data.message));
          }
        },
      },
      dispatch,
    );
  }

  const onRemoveAssetFromAccount = (asset_id: number) => {
    removeAssetFromAccountXHR(
      {
        body: { asset: asset_id, account: parseInt(id || "") },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnDeleteData)),
        successCallback: (data: IApiResponse<IAsset[] | null>) => {
          if (!data.results) {
            message.warning(t(data.message));
          }
        },
      },
      dispatch,
    );
  };

  function handleOpenModal(id: string | number | undefined) {
    unstable_batchedUpdates(() => {
      set_modalVisible(true);
      set_selectedAsset(id);
    }, []);
  }

  const debounceOnSearch = (value: string) => set_search(value);
  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);

  let data: IAsset[] = [...assetList];

  let filteredAssetList = useMemo(() => {
    if (search === "") {
      return data;
    }
    let arr: IAsset[] = [...data];
    arr = data.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
    arr = arr.map((x) => ({
      ...x,
      parent_id: x.parent_id ? (arr.some((y) => y.id === x.parent_id) ? x.parent_id : null) : null,
    }));
    return arr;
  }, [data, search]);

  const memedCOntent = useMemo(
    () =>
      render ? (
        <>
          {/* Input */}
          <Input
            placeholder={t(TRANSLATION_KEY.searchByName)}
            style={{ marginBottom: 12 }}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Divider style={{ marginTop: 12 }} />

          <div
            style={{
              height: "60vh",
              overflowY: "auto",
              width: "100%",
              position: "relative",
            }}
          >
            {/* Data */}
            <Spin spinning={getAccountAssetStatus === "loading"} style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <div
                  style={{ display: "flex", justifyContent: "center", margin: 12, marginTop: 0 }}
                >
                  <Button
                    onClick={() => {
                      set_checkedKeys(assetList.map((x) => x.id.toString()));

                      set_changeHappend(true);
                    }}
                    type="dashed"
                    style={{ width: "300px" }}
                  >
                    {t(TRANSLATION_KEY.selectAll)}
                  </Button>
                </div>
                <Tree
                  // checkStrictly={true}
                  // treeCheckStrictly={true}
                  className="userAssetCheckTree"
                  style={{
                    backgroundColor: "transparent !important",
                    width: "100%",
                  }}
                  onCheck={onCheck}
                  onExpand={onExpand}
                  checkedKeys={cehckedKeys}
                  expandedKeys={expandedKeys}
                  checkable
                  selectable={false}
                  defaultExpandAll={false}
                  treeData={createTreeWithChekable(
                    filteredAssetList,
                    null,
                    accountAsset.map((x) => x.id),
                    handleOpenModal,
                  )}
                  titleRender={(nodeData) => {
                    return (
                      <div className="spaceBetweenRow" style={{ width: "100%", marginBottom: 8 }}>
                        {/* Additional informations */}
                        <Space size="middle" align="baseline">
                          <Typography.Text type="secondary">
                            #{nodeData.custom_id || "-"}
                          </Typography.Text>
                          <Typography.Text type="secondary" style={{ color: "#333" }}>
                            {t(nodeData.category || "")}
                          </Typography.Text>
                          <Typography.Text style={{ marginBottom: 8 }}>
                            {nodeData.title}
                          </Typography.Text>
                          {nodeData.location && (
                            <Typography.Text
                              type="secondary"
                              style={{
                                transform: "translateY(5px)",
                                opacity: 65,
                              }}
                            >
                              ({nodeData.location})
                            </Typography.Text>
                          )}
                        </Space>
                      </div>
                    );
                  }}
                />
              </div>
            </Spin>
          </div>
          <Button
            type="primary"
            style={{ marginTop: 12 }}
            disabled={
              !changeHappend || joinAssetToAccountStatus === "loading" || cehckedKeys.length === 0
            }
            onClick={() => onSaveUserAsset()}
          >
            {t(TRANSLATION_KEY.save)}
          </Button>
        </>
      ) : (
        <Spin />
      ),
    [
      render,
      modalAssetsVisible,
      filteredAssetList,
      accountAsset,
      changeHappend,
      joinAssetToAccountStatus,
      cehckedKeys,
      expandedKeys,
    ],
  );

  const isAdmin = account?.role.name === "Admin"; // u budućnosti ćemo dodati flag u model nećemop se oslanjati na ime role-a

  const memedList = useMemo(
    () =>
      getAccountAssetStatus === "loading" ? (
        <Spin />
      ) : (
        <List
          dataSource={accountAssetTree}
          renderItem={(item) => (
            <AssetNode
              loading={+removeAssetFromAccountStatus}
              canDeleteAsset={!isAdmin && !!onRemoveAssetFromAccount}
              onRemoveAssetFromAccount={onRemoveAssetFromAccount}
              item={item}
              deep={0}
              handleOpenModal={handleOpenModal}
              viewAsset={hasPermission(user.account.permissions, ["view_asset"])}
            />
          )}
        />
      ),
    [accountAssetTree, getAccountAssetStatus],
  );

  return (
    <div className="asset-modal" style={{ maxWidth: 1200 }}>
      <div
        style={{
          width: "100%",
          marginTop: -8,
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Add button */}
        <Button
          type="primary"
          block
          disabled={getAccountAssetStatus === "loading"}
          style={{ marginRight: 6 }}
          onClick={() => {
            unstable_batchedUpdates(() => {
              set_checkedKeys(accountAsset.map((x) => x.id.toString()));
            }, []);
            set_modalAssetsVisible(true);

            setTimeout(() => {
              set_render(true);
            }, 369);
          }}
        >
          {t(TRANSLATION_KEY.assignAssets)}
        </Button>
        {!isAdmin && (
          <Popconfirm
            onConfirm={removeAllAsset}
            title={t(TRANSLATION_KEY.continueWithAction)}
            cancelText={t(TRANSLATION_KEY.no)}
            okText={t(TRANSLATION_KEY.yes)}
          >
            <Button type="text" block danger style={{ marginLeft: 6 }}>
              {t(TRANSLATION_KEY.removeAssets)}
            </Button>
          </Popconfirm>
        )}
      </div>

      <Divider style={{ marginTop: 8, marginBottom: 20 }} />

      {memedList}
      <Modal
        onCancel={() => {
          unstable_batchedUpdates(() => {
            set_modalAssetsVisible(false);
            set_search("");
            set_checkedKeys([]);
            set_render(false);
          }, []);
        }}
        footer={null}
        title={t(TRANSLATION_KEY.assets)}
        visible={modalAssetsVisible}
        width={900}
        centered
      >
        {memedCOntent}
      </Modal>

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => set_modalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AssetModalPreview id={selectedAsset} />
      </Modal>
    </div>
  );
};

export default Asset;

export function createTreeWithChekable(
  data: IAsset[],
  parentValue: number | null,
  userAsset: number[],
  handleOpenModal: (id: string | number | undefined) => void,
) {
  const treeData: TreeNodeType[] = [];
  data.forEach((item) => {
    if (parentValue === item.parent_id) {
      treeData.push({
        children: createTreeWithChekable(
          data.filter((x) => x.parent_id !== parentValue),
          item.id,
          userAsset,
          handleOpenModal,
        ),
        custom_id: item.custom_id,
        title: (
          <Button
            type="link"
            style={{ padding: 0, marginBottom: -4 }}
            onClick={() => handleOpenModal(item.id)}
          >
            {item.name}
          </Button>
        ),
        category: item.category?.name || null,
        id: item.id.toString(),
        location: item.location?.name || null,
        key: item.id.toString(),
        disableCheckbox: userAsset.includes(item.id),
      });
    }
  });
  return treeData;
}
