import {
  Button,
  Drawer,
  List,
  Typography,
  message,
  Spin,
  Modal,
  Popconfirm,
  Divider,
  Tag,
} from "antd";
import { t } from "i18next";
import { useState } from "react";
import FilesComponent from "../../../components/Files";
import AddCheckListToOrder from "../../../componentsform/AddCheckListToOrder";

import TaskForm from "../../../componentsform/TaskForm";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ITemplateDetails } from "../../../models/maintenances";
import { ITask } from "../../../models/settings";
import moment from "moment";
import {
  groupChecklistFromTasks,
  hasPermission,
  parseTaskDuration,
} from "../../../helpers/functions";
import api, { failedQueue, isRefreshing } from "../../../services";
import { IApiResponse } from "../../../models";

import {
  CloseCircleOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import Zoom from "../../../components/Zoom";

import ReactDragListView from "react-drag-listview";
import RequirePermission from "../../../components/RequirePermission";
import { useParams } from "react-router-dom";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { setTaskRankXHR } from "../../../store/reducers/maintenance/actionCreator";
import {
  RadioResult,
  SelectResult,
  StringResult,
} from "../../maintenance/components/checklist/Results";
import DurationInput from "../../maintenance/components/DurationInput";

interface IProps {
  template: ITemplateDetails;
}

const Tasks: React.FC<IProps> = ({ template }) => {
  // Variables
  const dispatch = useAppDispatch();
  const [addSingleTaskVisible, set_addSingleTaskVisible] = useState(false);
  const [loading, set_loading] = useState<boolean>(false);
  const { addTaskResulStatus } = useAppSelector((state) => state.maintenanceReducer);
  const open_order_token = useParams().token;
  const { user } = useAppSelector((state) => state.userReducer);
  const [addChecklistVisible, set_addChecklistVisible] = useState<boolean>(false);

  const { setTaskRankStatus } = useAppSelector((state) => state.maintenanceReducer);

  // Deleting tasks
  const [tasksChecked, setTasksChecked] = useState<number[]>([]);
  const allTasks = template.tasks.map((item) => item.id);

  function onTaskCheck(e: CheckboxChangeEvent) {
    let id: number = +e.target.value;
    let selected: boolean = e.target.checked;
    let arr = [...tasksChecked];
    if (selected) arr.push(id);
    else {
      let index = arr.findIndex((item) => item === id);
      arr.splice(index, 1);
    }
    setTasksChecked(arr);
  }

  function checkAllTasks(e: CheckboxChangeEvent) {
    setTasksChecked(e.target.checked ? allTasks : []);
  }
  // Deleting tasks

  const onDeleteTask = async (ids?: number[]) => {
    set_loading(true);
    let tasksToRemove: number[] = ids || tasksChecked;
    let token = await localStorage.getItem("token");

    //open order staff
    let code = await localStorage.getItem(open_order_token || "");

    try {
      let response = await api.delete<IApiResponse<string>>(`maintenance/task/`, {
        headers: { Authorization: "Bearer " + token },
        data: {
          tasks: tasksToRemove,
          token: open_order_token,
          code,
        },
      });

      let currentTemplate: ITemplateDetails = { ...template };
      let tasks: ITask[] = [...currentTemplate.tasks];
      tasks = tasks.filter((task) => !tasksToRemove.includes(task.id));

      const fakeRes: IApiResponse<ITemplateDetails> = {
        results: {
          ...currentTemplate,
          tasks,
        },
        message: "",
      };

      dispatch(maintenanceSlice.actions.getTemplateDetailsSuccess(fakeRes));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDeleteTask(ids));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    setTasksChecked([]);
    set_loading(false);
  };

  const removeChecklistFromTask = (id: number) => {
    let taskIds: number[] = template.tasks.filter((x) => x.checklist?.id === id).map((x) => x.id);
    onDeleteTask(taskIds);
  };

  // Sorting tasks by rank(ascending) - tasks with rank null come last
  const sortedTasks: ITask[] = [...template.tasks].sort((a: ITask, b: ITask) => {
    if (a.rank === null) return 1;
    if (b.rank === null) return -1;
    if (a.rank === b.rank) return 0;
    return a < b ? -1 : 1;
  });
  const onDragEnd = async (fromIndex: number, toIndex: number) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    setTaskRankXHR(
      {
        url: `maintenance/template_tasks/update_rank/`,
        body: { rank: toIndex + 1, task: template.tasks[fromIndex].id },
        successCallback: (data) => {
          if (data.results) {
            dispatch(
              maintenanceSlice.actions.getTemplateDetailsSuccess({
                message: "",
                results: { ...template, tasks: data.results },
              }),
            );
          }
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
      },
      dispatch,
    );
  };

  const getResultBlock = (task: ITask) => {
    switch (task.type) {
      case "flag":
        return (
          <RadioResult
            disabled={true}
            task_id={task?.id}
            value={typeof task.result === "string" ? task.result : ""}
          />
        );
      case "number":
      case "string":
        return (
          <StringResult
            type={task.type}
            disabled={true}
            task_id={task?.id}
            value={typeof task.result === "string" ? task.result : ""}
          />
        );
      case "select":
        return (
          <SelectResult
            disabled={true}
            properties={task.properties || []}
            task_id={task.id}
            value={Array.isArray(task.result) ? task.result : []}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Spin spinning={setTaskRankStatus === "loading" || loading}>
        <ReactDragListView
          nodeSelector={
            hasPermission(user.account.permissions, ["manage_wo"]) ? ".ant-list-item.draggble" : ""
          }
          onDragEnd={onDragEnd}
        >
          <List
            header={
              <>
                <div>
                  <div className="spaceBetweenRow">
                    <Typography.Title level={5}>{t(TRANSLATION_KEY.tasks)}</Typography.Title>
                    <div className="spaceBetweenRow">
                      <Button
                        onClick={() => set_addChecklistVisible(true)}
                        disabled={false} //admin moze kad hoce sta hoce
                        shape="circle"
                        icon={<FileDoneOutlined />}
                      />
                      <div style={{ width: 12 }} />
                      <Button
                        onClick={() => set_addSingleTaskVisible(true)}
                        disabled={false} //admin moze kad hoce sta hoce
                        icon={<PlusOutlined />}
                        type="primary"
                        shape="circle"
                      />
                    </div>
                  </div>

                  {/* Divider */}
                  {sortedTasks.length > 0 && (
                    <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                  )}

                  <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
                    <div style={{ width: "100%" }} className="spaceBetweenRow">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 4,
                          height: sortedTasks.length > 0 ? 60 : 6,
                          width: 180,
                        }}
                      >
                        {sortedTasks.length > 0 && (
                          <Checkbox
                            checked={
                              template.tasks.length === tasksChecked.length &&
                              tasksChecked.length > 0
                            }
                            indeterminate={
                              tasksChecked.length !== 0 &&
                              tasksChecked.length !== template.tasks.length
                            }
                            onChange={checkAllTasks}
                          >
                            {t(TRANSLATION_KEY.selectAll) + " " + tasksChecked.length || null}
                          </Checkbox>
                        )}
                        <Zoom visible={tasksChecked.length !== 0}>
                          <Popconfirm
                            onConfirm={() => onDeleteTask()}
                            title={t(TRANSLATION_KEY.continueWithAction)}
                            cancelText={t(TRANSLATION_KEY.no)}
                            okText={t(TRANSLATION_KEY.yes)}
                            placement="top"
                          >
                            <Button
                              danger
                              shape="circle"
                              icon={<DeleteOutlined />}
                              style={{ marginTop: 0 }}
                            />
                          </Popconfirm>
                        </Zoom>
                      </div>

                      <RequirePermission
                        requiredPermissionCode={["manage_wo"]}
                        children={
                          <div className="spaceBetweenRow" style={{ flexWrap: "wrap" }}>
                            {groupChecklistFromTasks(template.tasks).map((x) => (
                              // <Button
                              //   style={{ marginLeft: 6 }}
                              //   type="ghost"
                              //   danger
                              //   icon={<DeleteOutlined />}
                              //   onClick={() => removeChecklistFromTask(x.id)}
                              // >
                              //   {x.name}
                              // </Button>
                              <Tag
                                onClick={() => removeChecklistFromTask(x.id)}
                                icon={<CloseCircleOutlined />}
                                color="error"
                                style={{
                                  cursor: "pointer",
                                  padding: "3px 10px",
                                  borderRadius: 20,
                                }}
                              >
                                {x.name}
                              </Tag>
                            ))}
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            }
            dataSource={sortedTasks}
            renderItem={(item, index) => (
              //
              <List.Item
                className="draggble"
                style={{
                  border: "1px solid #80808038",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Spin
                  wrapperClassName="stoposto"
                  spinning={"loading" + item.id === addTaskResulStatus}
                >
                  <div className="spaceBetweenRow">
                    <List.Item.Meta
                      title={
                        <div
                          className="spaceBetweenRow"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                              checked={tasksChecked.includes(item.id)}
                              value={item.id}
                              onChange={onTaskCheck}
                              style={{ marginRight: 12 }}
                            />
                            <div>{`${index + 1}. ${item.description}`}</div>
                          </div>
                          <div className="spaceBetweenRow">
                            <Tag>{t(item.type)}</Tag>
                            {item.planned_duration &&
                              parseTaskDuration(item.planned_duration) !== "00h:00m" && (
                                <Tag
                                  color="blue"
                                  style={{ padding: "3px 10px", borderRadius: 20, border: "none" }}
                                >
                                  {parseTaskDuration(item.planned_duration)}
                                </Tag>
                              )}
                          </div>
                        </div>
                      }
                      description={
                        <div>
                          {/* Result block */}
                          <div style={{ marginTop: 8 }}>{getResultBlock(item)}</div>

                          {/* Materials */}
                          <div style={{ marginTop: 12 }}>
                            {!!item.files?.length ? (
                              <FilesComponent
                                onDelete={() => {}}
                                files={item.files}
                                hideButtons={true}
                                size="tiny"
                                layout="grid"
                              />
                            ) : null}
                          </div>
                        </div>
                      }
                    />
                  </div>
                </Spin>
              </List.Item>
              //
            )}
          />
        </ReactDragListView>
      </Spin>
      <Modal
        title={t(TRANSLATION_KEY.checklist)}
        visible={addChecklistVisible}
        destroyOnClose
        onCancel={() => set_addChecklistVisible(false)}
        footer={null}
      >
        <AddCheckListToOrder template={template} close={() => set_addChecklistVisible(false)} />
      </Modal>
      <Drawer
        width={540}
        title={t(TRANSLATION_KEY.newTask)}
        visible={addSingleTaskVisible}
        onClose={() => set_addSingleTaskVisible(false)}
        destroyOnClose
        children={
          <TaskForm
            addingType="template"
            checklist={null}
            order_info={template.id}
            onClose={() => set_addSingleTaskVisible(false)}
          />
        }
      />
      <div style={{ height: 33 }} />
    </>
  );
};

export default Tasks;
