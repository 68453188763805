import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal, Space, Typography } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../helpers/consts";
import api, { failedQueue, isRefreshing } from "../services";
import { IApiResponse } from "../models";
import { useAppDispatch } from "../hooks";
import { PlusCircleFilled } from "@ant-design/icons";

export type Tag = { id: number; name: string };

interface IProps {
  visible: boolean;
  set_visible(visible: boolean): void;
  url: string;
  successCallback(response: Tag, added?: string): void;
  title: string | JSX.Element;
}

const SelectTagAdd: React.FC<IProps> = ({ set_visible, visible, url, successCallback, title }) => {
  const dispatch = useAppDispatch();
  const [tag, set_tag] = useState<string>("");
  const [loading, set_loading] = useState<boolean>(false);

  const onClick = async () => {
    set_loading(true);
    const token = await localStorage.getItem("token");
    try {
      let response = await api.post<IApiResponse<Tag>>(
        `${url}`,
        { name: tag },
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        successCallback(response.data.results, tag);
      }
      set_visible(false);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onClick());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  return (
    <div>
      <Modal
        title={title}
        visible={visible}
        footer={null}
        onCancel={() => {
          set_visible(false);
        }}
        closable={true}
      >
        <div
          style={{
            padding: "0 15%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Input
            onChange={({ target: { value } }) => {
              set_tag(value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onClick();
              }
            }}
          />
          <Button
            style={{ marginLeft: 12 }}
            onClick={onClick}
            type="primary"
            disabled={!tag}
            loading={loading}
          >
            {t(TRANSLATION_KEY.save)}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

interface IPropsAddButton {
  addingVisible: boolean;
  set_addingVisible(visible: boolean): void;
}

export const ButtonAddSelectTag: React.FC<IPropsAddButton> = ({
  addingVisible,
  set_addingVisible,
}) =>
  addingVisible ? null : (
    <div style={{ padding: "2px auto" }} onClick={() => set_addingVisible(true)}>
      <Space
        className={`pointer ${addingVisible && ""}`}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {/* Icon */}
        <PlusCircleFilled style={{ color: "#0353ef" }} />

        {/* Text */}
        <Typography.Link style={{ fontSize: 15 }}>{t(TRANSLATION_KEY.add)}</Typography.Link>
      </Space>
    </div>
  );

export default SelectTagAdd;
