import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../models";
import { IResetPassword } from "../../../pages/auth/Login";
import { ISetNewPassword } from "../../../pages/auth/SetNewPassword";
import {
  IUserNotification,
  IUser,
  IUserAccount,
  TableViewCategories,
  CreateViewXHRResponseType,
} from "../../../models/user";
import { Languages } from "../../../helpers/consts";

const initialUser: IUser = {
  token: { access: "", refresh: "" },
  account: {
    notification_types: [],
    topic: "",
    id: 0,
    email: "",
    name: "",
    phone_number: "",
    note: "",
    created_at: "",
    avatar: "",
    price_per_hour: 0,
    accepted_terms: true,
    show_instruction: 0,
    date_format: null,
    permissions: [],
    custom_id: null,
    language: (localStorage.getItem("i18nextLng") as Languages) || Languages.English,
    is_active: false,
    role: {
      id: 1,
      name: "Admin",
    },
    currency: "",
    company: {
      id: 0,
      name: "",
      email: "",
      phone_number: "",
      currency: "",
      id_pdv: "",
      address: "",
      web: "",
      topic: "",
      admin_user_id: 0,
      modules: [],
      avatar: "",
    },
    custom_fields_v2: {},
  },
};

// Define a type for the slice state
interface UserState {
  updateViewStatus: string;
  createViewStatus: string;
  unreadedNotificationsCountStatus: string;
  unreadedNotificationsCount: number;
  user: IUser;
  loginStatus: string;
  registrationStatus: string;
  changePasswordStatus: string;
  meStatus: string;
  confirmAndSetPasswordStatus: string;
  resetPasswordEmailStatus: string;
  setNewPasswordStatus: string;
  setMqttClientStatus: string;
  updateUserStatus: string;
  getNotificationsStatus: string;
  userNotifications: IUserNotification[];
  readNotificationStatus: string;
  addCustomNotificationStatus: string;
  loadingLanguage: boolean;
}

// Define the initial state using that type
const initialState = {
  updateViewStatus: "",
  createViewStatus: "",
  unreadedNotificationsCountStatus: "",
  unreadedNotificationsCount: 0,
  user: initialUser,
  loginStatus: "",
  registrationStatus: "",
  changePasswordStatus: "",
  meStatus: "loading",
  confirmAndSetPasswordStatus: "",
  resetPasswordEmailStatus: "",
  setNewPasswordStatus: "",
  setMqttClientStatus: "",
  mqttClient: null,
  updateUserStatus: "",
  getNotificationsStatus: "",
  userNotifications: [],
  readNotificationStatus: "",
  addCustomNotificationStatus: "",
  loadingLanguage: false,
} as UserState;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        if (key !== "meStatus") {
          // zato sto je defaultni status loading
          state[key] = initialState[key];
        } else {
          state[key] = "";
        }
      });
    },
    logout(state) {
      state.user = initialUser;
    },
    login(state) {
      state.loginStatus = "loading";
    },
    loginSucceess(state, action: PayloadAction<IApiResponse<IUser>>) {
      if (action.payload.results) {
        state.user = action.payload.results;
      }
      state.loginStatus = "";
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginStatus = "";
    },
    // -----

    registration(state) {
      state.registrationStatus = "loading";
    },
    registrationSuccess(state, action: PayloadAction<IApiResponse<IUser>>) {
      state.registrationStatus = "";
    },
    registrationFail(state, action: PayloadAction<string>) {
      state.registrationStatus = action.payload;
    },
    // -----

    changePassword(state) {
      state.changePasswordStatus = "loading";
    },
    changePasswordSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.changePasswordStatus = "";
    },
    changePasswordFail(state, action: PayloadAction<string>) {
      state.changePasswordStatus = action.payload;
    },

    // -----

    me(state, action: PayloadAction<{ loading: string }>) {
      state.meStatus = action.payload.loading;
    },
    meSuccess(state, action: PayloadAction<IApiResponse<IUser>>) {
      if (action.payload.results) {
        state.user = action.payload.results;
      }
      state.meStatus = "";
    },
    meFail(state, action: PayloadAction<string>) {
      state.meStatus = "";
    },

    // -----

    confirmAndSetPassword(state) {
      state.confirmAndSetPasswordStatus = "loading";
    },
    confirmAndSetPasswordSuccess(state, action: PayloadAction<IApiResponse<IUser>>) {
      if (action.payload.results) {
        state.user = action.payload.results;
      }
      state.confirmAndSetPasswordStatus = "";
    },
    confirmAndSetPasswordFail(state, action: PayloadAction<string>) {
      state.confirmAndSetPasswordStatus = action.payload;
    },

    // -----

    resetPasswordEmail(state) {
      state.resetPasswordEmailStatus = "loading";
    },
    resetPasswordEmailSuccess(state, action: PayloadAction<IApiResponse<IResetPassword>>) {
      state.resetPasswordEmailStatus = "";
    },
    resetPasswordEmailFail(state, action: PayloadAction<string>) {
      state.resetPasswordEmailStatus = action.payload;
    },

    // -----

    setNewPassword(state) {
      state.setNewPasswordStatus = "loading";
    },
    setNewPasswordSuccess(state, action: PayloadAction<IApiResponse<ISetNewPassword>>) {
      state.setNewPasswordStatus = "";
    },
    setNewPasswordFail(state, action: PayloadAction<string>) {
      state.setNewPasswordStatus = action.payload;
      // -----
    },

    updateUser(state) {
      state.updateUserStatus = "loading";
    },
    updateUserSuccess(state, action: PayloadAction<IApiResponse<IUserAccount>>) {
      if (action.payload.results) {
        let user: IUser = { ...state.user };
        state.user = {
          token: user.token,
          account: {
            ...user.account,
            role: action.payload.results.role,
            name: action.payload.results.name,
            email: action.payload.results.email,
            phone_number: action.payload.results.phone_number,
            note: action.payload.results.note,
            price_per_hour: action.payload.results.price_per_hour,
            date_format: action.payload.results.date_format,
            language: action.payload.results.language,
          },
        };
      }
      state.updateUserStatus = "";
    },
    updateUserFail(state, action: PayloadAction<string>) {
      state.updateUserStatus = action.payload;
    },

    getNotifications(state) {
      state.getNotificationsStatus = "loading";
    },
    getNotificationsSuccess(
      state,
      action: PayloadAction<IApiResponse<IUserNotification[]> & { mergeData: boolean }>,
    ) {
      if (!action.payload.results) {
        return;
      }
      if (action.payload.mergeData) {
        state.userNotifications = [...state.userNotifications, ...action.payload.results];
      } else {
        state.userNotifications = action.payload.results;
      }

      state.getNotificationsStatus = "";
    },
    getNotificationsFail(state, action: PayloadAction<string>) {
      state.getNotificationsStatus = action.payload;
    },

    // -----

    readNotification(state) {
      state.readNotificationStatus = "loading";
    },
    readNotificationSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.readNotificationStatus = "";
    },
    readNotificationFail(state, action: PayloadAction<string>) {
      state.readNotificationStatus = action.payload;
    },

    // -----

    addCustomNotification(state) {
      state.addCustomNotificationStatus = "loading";
    },
    addCustomNotificationSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.addCustomNotificationStatus = "";
    },
    addCustomNotificationFail(state, action: PayloadAction<string>) {
      state.addCustomNotificationStatus = action.payload;
    },

    // -----
    setLoadingLanguage(state, action: PayloadAction<boolean>) {
      state.loadingLanguage = action.payload;
    },

    unreadedNotificationsCount(state) {
      state.unreadedNotificationsCountStatus = "loading";
    },
    unreadedNotificationsCountSuccess(
      state,
      action: PayloadAction<IApiResponse<{ count: number }>>,
    ) {
      state.unreadedNotificationsCount = action.payload.results?.count || 0;
      state.unreadedNotificationsCountStatus = "";
    },
    unreadedNotificationsCountFail(state, action: PayloadAction<string>) {
      state.unreadedNotificationsCountStatus = action.payload;
    },

    createView(state) {
      state.createViewStatus = "loading";
    },
    createViewSuccess(
      state,
      action: PayloadAction<
        IApiResponse<CreateViewXHRResponseType> & { category: TableViewCategories }
      >,
    ) {
      if (action.payload.results === undefined || !action.payload.category) return;
      if (!state.user.account.views) return;
      // Spread the current state to avoid direct mutation
      let tmpUser = { ...state.user };
      let tmpViews = { ...state.user.account.views };

      // Update the specific category in tmpViews with the correct data
      tmpViews[action.payload.category] = action.payload.results[action.payload.category];

      // Update the state with the new views
      let tmp: IUser = {
        ...tmpUser,
        account: {
          ...tmpUser.account,
          views: tmpViews,
        },
      };
      state.user = tmp;
      state.createViewStatus = "";
    },

    createViewFail(state, action: PayloadAction<string>) {
      state.createViewStatus = action.payload;
    },

    updateView(state) {
      state.updateViewStatus = "loading";
    },
    updateViewSuccess(
      state,
      action: PayloadAction<
        IApiResponse<CreateViewXHRResponseType> & { viewsToUpdate: TableViewCategories }
      >,
    ) {
      let tmpUser = { ...state.user };
      let views: any = { ...tmpUser.account.views };
      if (!views) {
        return;
      }
      let key = action.payload.viewsToUpdate;
      console.log(key, views, views[key]);
      if (action.payload.results === undefined) {
        alert("Error updating view key not found!");
        return;
      }
      if (!key || !views[key]) {
        alert("Error updating view key not found!");
        return;
      }
      views[key] = action.payload.results[key];
      state.user = {
        ...tmpUser,
        account: {
          ...tmpUser.account,
          views: views,
        },
      };
      state.updateViewStatus = "";
    },
    updateViewFail(state, action: PayloadAction<string>) {
      state.updateViewStatus = action.payload;
    },
    deleteViewById(
      state,
      action: PayloadAction<{ id: number; typeCategory: TableViewCategories }>,
    ) {
      let tmpUser = { ...state.user };
      if (!tmpUser.account.views) {
        return;
      }
      let views = tmpUser.account.views[action.payload.typeCategory]?.filter(
        (x) => x.id !== action.payload.id,
      );
      state.user = {
        ...tmpUser,
        account: {
          ...tmpUser.account,
          views: {
            ...tmpUser.account.views,
            [action.payload.typeCategory]: views,
          },
        },
      };
    },
  },
});

// Thunk function

export default userSlice.reducer;
