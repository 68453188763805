import { StopOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IMaintenanceDetails } from "../../../../models/maintenances";
import { finishMaintenanceXHR } from "../../../../store/reducers/maintenance/actionCreator";
import { finishOpenOrderXHR } from "../../../../store/reducers/supplier/actionCreator";

interface IProps {
  maintenance: IMaintenanceDetails;
  onClose?: () => void;
  isOpenOrder: boolean;
}

const Finish: React.FC<IProps> = ({ maintenance, onClose, isOpenOrder }) => {
  const dispatch = useAppDispatch();

  const { token } = useParams();

  const finishOpenOrder = async () => {
    if (!token) {
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
      return;
    }
    let code = await localStorage.getItem(token);

    finishOpenOrderXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          if (onClose) {
            onClose();
          }
        },
        body: {
          order_info: maintenance.id,
          token,
          code: code || "",
        },
      },
      dispatch,
    );
  };

  return (
    <>
      <Button
        onClick={() => {
          if (isOpenOrder) {
            finishOpenOrder();
            return;
          }
          finishMaintenanceXHR(
            {
              body: { order_info: maintenance.id },
              successCallback: () => {
                if (onClose) {
                  onClose();
                }
              },
              errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
            },
            dispatch,
          );
        }}
      >
        {t(TRANSLATION_KEY.stop)}
      </Button>
    </>
  );
};

export default Finish;
