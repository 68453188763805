import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function RouterScrollWrapper({ children }) {
  // Hooks
  const location = useLocation();

  // Methods
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
}
