import { warehouseSlice } from ".";
import { AppDispatch } from "../..";
import { IPartForm } from "../../../componentsform/PartForm";
import { ISpendPart } from "../../../componentsform/SpendPartForm";
import { IApiResponse, IDataToApi } from "../../../models";
import { IAsset } from "../../../models/asset";
import {
  IPart,
  IPartCategory,
  IPartDetails,
  IPartEntries,
  IPartsList,
  IPartSpend,
  IPartSpendList,
} from "../../../models/parts";
import { IPairSpendWithPart } from "../../../pages/maintenance/pages/Costs/PairSpendWithPart";
import {
  IConfirmPartSpend,
  IRemovePartSpend,
} from "../../../pages/part/components/SpendHistoryTable";
import api, { failedQueue, isRefreshing } from "../../../services";

export const getPartsCategoriesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPartCategory[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.getPartsCategories());
    let response = await api.get<IApiResponse<IPartCategory[]>>(`/warehouse/categories/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.getPartsCategoriesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartsCategoriesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.getPartsCategoriesFail(error.message));
  }
};

export const getPartsXHR = async (
  data: Partial<{ mergeData: boolean } & IDataToApi<null, IApiResponse<IPartsList>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(warehouseSlice.actions.getParts());
    let response = await api.get<IApiResponse<IPartsList>>(`/warehouse/parts/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      warehouseSlice.actions.getPartsSuccess({ ...response.data, mergeData: !!data.mergeData }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.getPartsFail(error.message));
  }
};

export const createPartXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IPart>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(warehouseSlice.actions.createPart());
    let response = await api.post<IApiResponse<IPart>>(`/warehouse/parts/`, data.body, {
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.createPartSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createPartXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.createPartFail(error.message));
  }
};

export const retrievePartXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPartDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.retrievePart(data.loading));
    let response = await api.get<IApiResponse<IPartDetails>>(`warehouse/parts/${data.id}`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.retrievePartSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => retrievePartXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.retrievePartFail(error.message));
  }
};

export const updatePartCHR = async (
  data: Partial<IDataToApi<IPartForm, IApiResponse<IPartDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.updatePart());
    let response = await api.patch<IApiResponse<IPartDetails>>(
      `warehouse/parts/${data.id}/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.updatePartSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updatePartCHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.updatePartFail(error.message));
  }
};

export const spendPartXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IPartSpend>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.spendPart());
    let response = await api.post<IApiResponse<IPartSpend>>(`warehouse/parts/spend/`, data.body, {
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.spendPartSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => spendPartXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.spendPartFail(error.message));
  }
};

export const getPartEntriesXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPartEntries>> & { mergeData: boolean }>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(warehouseSlice.actions.getPartEntries());
    let response = await api.get<IApiResponse<IPartEntries>>(
      `/warehouse/parts/${data.id}/entry_list`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      warehouseSlice.actions.getPartEntriesSuccess({
        ...response.data,
        mergeData: !!data.mergeData,
      }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartEntriesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.getPartEntriesFail(error.message));
  }
};

export const addPartToStorageXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IPartDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.addPartToStorage());
    let response = await api.post<IApiResponse<IPartDetails>>(
      `warehouse/parts/${data.id}/add_to_storage/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.addPartToStorageSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addPartToStorageXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.addPartToStorageFail(error.message));
  }
};

export const removePartEntryXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.removePartEntry());
    let response = await api.delete<IApiResponse<string>>(`warehouse/entries/${data.id}/`, {
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.removePartEntrySuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => removePartEntryXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.removePartSpendFail(error.message));
  }
};

export const getPartSpendListXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPartSpendList>> & { mergeData: boolean }>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(warehouseSlice.actions.getPartSpendList());
    let response = await api.get<IApiResponse<IPartSpendList>>(
      `/warehouse/parts/${data.id}/spend_list`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      warehouseSlice.actions.getPartSpendListSuccess({
        ...response.data,
        mergeData: !!data.mergeData,
      }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartSpendListXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.getPartSpendListFail(error.message));
  }
};

export const removePartSpendXHR = async (
  data: Partial<IDataToApi<IRemovePartSpend, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.removePartSpend());
    let response = await api.post<IApiResponse<string>>(
      `warehouse/parts/remove_spent/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );

    dispatch(warehouseSlice.actions.removePartSpendSuccess(response.data));
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => removePartSpendXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.removePartSpendFail(error.message));
  }
};

export const confirmPartSpendXHR = async (
  data: Partial<IDataToApi<IConfirmPartSpend, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.confirmPartSpend());
    let response = await api.post<IApiResponse<string>>(
      `/warehouse/parts/confirm_spend/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.confirmPartSpendSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => confirmPartSpendXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.confirmPartSpendFail(error.message));
  }
};

export const getPartsInTransportXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<any>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(warehouseSlice.actions.getPartsInTransport());
    let response = await api.get<IApiResponse<any>>(`/warehouse/transport/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.getPartsInTransportSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartsInTransportXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.getPartsInTransportFail(error.message));
  }
};

export const pairSpendWithPartXHR = async (
  data: Partial<IDataToApi<IPairSpendWithPart, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.pairSpendWithPart());
    let response = await api.post<IApiResponse<string>>(
      `warehouse/spend/connect_to_part/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.pairSpendWithPartSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(warehouseSlice.actions.pairSpendWithPartFail(error.message));
  }
};

// Initialize variables to manage the call state and buffer
let isCallInProgress = false;
const callBuffer: (() => Promise<void>)[] = [];

export const getSpendPartsOnAssetXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPartSpend[]>> & { mergeData: boolean }>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(warehouseSlice.actions.getSpendPartsOnAsset());

    // Check if there's a call in progress
    if (isCallInProgress) {
      // If yes, push the current call into the buffer
      callBuffer.push(() => getSpendPartsOnAssetXHR(data, dispatch));
      return;
    }

    // Set the flag to indicate that a call is in progress
    isCallInProgress = true;

    let response = await api.get<IApiResponse<IPartSpend[]>>(
      `assets/assets/${data.id}/spent_parts/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );

    if (data.successCallback) {
      data.successCallback(response.data);
    }

    dispatch(
      warehouseSlice.actions.getSpendPartsOnAssetSuccess({
        results: response.data.results,
        mergeData: !!data.mergeData,
        message: response.data.message,
      }),
    );

    // Reset the flag to indicate that the call is finished
    isCallInProgress = false;

    // Check if there are calls in the buffer, if yes, execute the next one
    if (callBuffer.length > 0) {
      const nextCall = callBuffer.shift();
      if (nextCall) {
        nextCall();
      }
    }
  } catch (error: any) {
    // Handle errors as before
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getSpendPartsOnAssetXHR(data, dispatch));
      }
      return;
    }
    // Reset the flag even if an error occurs
    isCallInProgress = false;

    // Check if there are calls in the buffer, if yes, execute the next one
    if (callBuffer.length > 0) {
      const nextCall = callBuffer.shift();
      if (nextCall) {
        nextCall();
      }
    }
  }
};

export const joinAssetsToPartXHR = async (
  data: Partial<
    IDataToApi<
      {
        part: number;
        assets: number[];
      },
      IApiResponse<IAsset[]>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.post<IApiResponse<IAsset[]>>(
      `/warehouse/parts/join_assets/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => joinAssetsToPartXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};

export const createPartExportFileXHR = async (
  data: Partial<
    IDataToApi<
      {
        warehouse: string;
        search: string;
        assets: string;
      },
      IApiResponse<{ uid: string }>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.post<IApiResponse<{ uid: string }>>(
      `/warehouse/part_export/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createPartExportFileXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};

export const getPartByCodeXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IPart>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.get<IApiResponse<IPart>>(`/warehouse/parts/code_scan/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getPartByCodeXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};

export const spendPartWithoutOrderXHR = async (
  data: Partial<IDataToApi<FormData, IApiResponse<IPartDetails>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.post<IApiResponse<IPartDetails>>(
      `/warehouse/parts/warehouse_spend/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(warehouseSlice.actions.retrievePartSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => spendPartWithoutOrderXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};
