import { LAYOUT_GRIDS } from "../../../../helpers/consts";
import { useAppSelector } from "../../../../hooks";
import MaintenanceSpecContent from "./MaintenanceSpecContent";

const MaintenanceSpec: React.FC = () => {
  const { maintenanceDetails } = useAppSelector((state) => state.maintenanceReducer);

  return (
    <MaintenanceSpecContent
      isOpenOrder={false}
      maintenanceDetails={maintenanceDetails}
      grids={LAYOUT_GRIDS}
    />
  );
};

export default MaintenanceSpec;
