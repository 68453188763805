import { Checkbox, Descriptions, Tag } from "antd";
import { t } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { formatNumber, parseCosts } from "../../../helpers/functions";
import { useAppSelector } from "../../../hooks";
import { getAccountXHR } from "../../../store/reducers/accounts/actionCreators";
import {
  CustomFieldV2,
  CustomFieldValue,
  CustomFieldValueType,
  CustomFieldValues,
  TypeCustomFieldV2,
} from "../../../models/settings";
import { IAccount, IUserAccount } from "../../../models/user";
import moment from "moment";
import { CustomValueType } from "framer-motion";
import CustomFieldsSpecGenerator from "../../../components/CustomFieldsSpecGenerator";

const Spec: React.FC = () => {
  // Hooks
  const { id } = useParams();
  const dispatch = useDispatch();

  // Variables
  const { account } = useAppSelector((state) => state.accountsReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  // Methods
  useEffect(() => {
    getAccountXHR({ id }, dispatch);
  }, []);

  const validate = (value: any) => (value != null ? value : "-");

  return (
    <Descriptions size="small" column={3}>
      <Descriptions.Item label={t(TRANSLATION_KEY.email)}>
        {validate(account?.email)}
      </Descriptions.Item>
      <Descriptions.Item label={t(TRANSLATION_KEY.phoneNumber)}>
        {validate(account?.phone_number)}
      </Descriptions.Item>
      <Descriptions.Item label={t(TRANSLATION_KEY.description)}>
        {validate(account?.note || "-")}
      </Descriptions.Item>
      {account?.price_per_hour && (
        <Descriptions.Item label={t(TRANSLATION_KEY.pricePerHour)}>
          {parseCosts(account?.price_per_hour.toString())} {account?.currency || ""}
        </Descriptions.Item>
      )}
      {CustomFieldsSpecGenerator({
        condition: account?.role?.id || undefined,
        formType: "account",
        data: account?.custom_fields_v2,
      })}
    </Descriptions>
  );
};

export default Spec;
