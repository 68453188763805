import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { ISupplierCategory } from "../../../../../models/supplier";
import { motion } from "framer-motion";
import { CardVariant } from "../../CheckList";
import { useState } from "react";

interface IProps {
  item: ISupplierCategory;
  onDelete: (id: number) => void;
  onUpdate: (category: ISupplierCategory) => void;
}

const Card: React.FC<IProps> = ({ item, onDelete, onUpdate }) => {
  // Variables
  const [variant, set_variant] = useState<CardVariant>("hidden");

  return (
    <motion.div
      onHoverStart={() => set_variant("visible")}
      onHoverEnd={() => set_variant("hidden")}
      style={{ padding: "4px 8px", paddingRight: 4, minHeight: 40 }}
      className="spaceBetweenRow locationCard"
    >
      <div>{item.name}</div>
      <motion.div
        className="spaceBetweenRow"
        initial="hidden"
        animate={variant}
        variants={variants}
        transition={{ ease: "anticipate", duration: 0.35 }}
      >
        <Button onClick={() => onUpdate(item)} type="link" icon={<EditOutlined />} />
        <div style={{ width: 6 }} />
        <Popconfirm
          onConfirm={() => onDelete(item.id)}
          title={t(TRANSLATION_KEY.continueWithAction)}
        >
          <Button danger type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </motion.div>
    </motion.div>
  );
};

export default Card;

const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
