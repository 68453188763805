import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { store } from "../store";
import { new_way_logoutXHR } from "../store/reducers/user/actionCreators";

export let isRefreshing = false;
export let failedQueue: any[] = [];

const setItems = async (access: string, refresh: string) => {
  await localStorage.setItem("token", access);
  await localStorage.setItem("refreshToken", refresh);
};

const refreshAuthLogic = async (failedRequest) => {
  isRefreshing = true;
  let refreshtoken = await localStorage.getItem("refreshToken");

  return axios
    .post<{ access: string; refresh: string }>(
      `${process.env.REACT_APP_HOST_BACKEND}/api/auth/token/refresh/`,
      {
        refresh: refreshtoken || "-",
      },
    )
    .then((tokenRefreshResponse) => {
      setItems(tokenRefreshResponse.data.access, tokenRefreshResponse.data.refresh);

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.access;
      console.log("PROBAM", failedQueue);
      isRefreshing = false;
      failedQueue.forEach((call: any) => {
        console.log(typeof call);
        if (typeof call === "function") {
          call();
        }
      });

      failedQueue = [];

      return Promise.resolve();
    })
    .catch((error) => {
      new_way_logoutXHR(null, store.dispatch);
      isRefreshing = false;
      failedQueue = [];
      return Promise.reject(error);
    });
};

export const DEFAULT_AXIOS_TIMEOUT: number = 12000;

const api = (timeout: number = DEFAULT_AXIOS_TIMEOUT) =>
  axios.create({
    baseURL: process.env.REACT_APP_HOST_BACKEND + "/api",
    timeout,
  });

let myInstance = api();
createAuthRefreshInterceptor(myInstance, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
});

export default myInstance;
