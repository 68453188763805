import { Spin, Tooltip } from "antd";
import { t } from "i18next";
import React from "react";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { getRunningExecutorFlow } from "../../../helpers/functions";
import { useAppSelector } from "../../../hooks";
import { IMaintenanceDetails } from "../../../models/maintenances";
import Finish from "./comands/finish";
import Start from "./comands/start";

interface IProps {
  isOpenOrder: boolean;
  maintenanceDetails: IMaintenanceDetails;
}

const StartStop: React.FC<IProps> = ({ isOpenOrder, maintenanceDetails }) => {
  const { user } = useAppSelector((state) => state.userReducer);
  const { finishMaintenanceStatus, startMaintenanceStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  let flow = getRunningExecutorFlow(user.account.id, isOpenOrder, maintenanceDetails.flows);
  return (
    <div style={{ marginBottom: 12, marginLeft: 12 }}>
      <Spin
        spinning={finishMaintenanceStatus === "loading" || startMaintenanceStatus === "loading"}
      >
        {flow?.duration !== null ? (
          <Tooltip title={t(TRANSLATION_KEY.startYourTime)}>
            <Start onClose={undefined} isOpenOrder={isOpenOrder} maintenance={maintenanceDetails} />
          </Tooltip>
        ) : (
          <Finish onClose={undefined} isOpenOrder={isOpenOrder} maintenance={maintenanceDetails} />
        )}
      </Spin>
    </div>
  );
};

export default StartStop;
