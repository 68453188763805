import React from "react";
import { FormModal } from "../../../../../componentsform/CustomFieldsFormV2";
import CustomFieldsTable from "../Components/CustomFieldsTable";
import { useAppSelector } from "../../../../../hooks";

interface IProps {
  onEdit: (data: FormModal) => void;
}
const Client: React.FC<IProps> = ({ onEdit }) => {
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);

  return (
    <div>
      <CustomFieldsTable
        formType="clients"
        condition={undefined}
        onEdit={onEdit}
        conditionTitle={""}
        customFieldsData={companyCustomFieldsV2.clients}
      />
    </div>
  );
};

export default Client;
