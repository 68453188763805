import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks";
import { Drawer, List, Typography, message } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { getWorkRequestsXHR } from "../../../store/reducers/maintenance/actionCreator";
import { useDispatch } from "react-redux";
import Card from "./Card";
import RequirePermission from "../../../components/RequirePermission";
import MaintenanceForm from "../../../componentsform/MaintenanceForm";
import api, { failedQueue, isRefreshing } from "../../../services";
import { settingsSlice } from "../../../store/reducers/settings";
import { maintenanceSlice } from "../../../store/reducers/maintenance";

interface IProps {
  onClose: () => void;
}
export interface IAddMaintenance {
  assetId: number | null;
  description: string;
  workRequestId: number;
}

const WorkRequests: React.FC<IProps> = () => {
  const { workRequests, getWorkRequestsStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const dispatch = useDispatch();

  const [addMaintenance, set_addMaintenance] = React.useState<IAddMaintenance | undefined>(
    undefined,
  );

  useEffect(() => {}, []);

  const onClose = () => {
    console.log("close");
  };

  const removeWorkRequest = async (
    workRequest: number | null,
    setLoading: (id: number) => void,
  ) => {
    console.log(workRequest);
    setLoading(workRequest || -1);
    let token = localStorage.getItem("token");
    if (!token) return;
    try {
      let res = await api.delete(`/settings/work_request/${workRequest}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);
      let index = workRequests.findIndex((item) => item.id === workRequest);
      let tmp = [...workRequests];
      if (index !== -1) {
        tmp.splice(index, 1);
        dispatch(
          maintenanceSlice.actions.getWorkRequestsSuccess({
            results: tmp,
            message: "",
          }),
        );
      }
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        if (error?.response?.status === 401) {
          if (isRefreshing) {
            failedQueue.push(() => removeWorkRequest(workRequest, setLoading));
          }
          return;
        }
        message.error(t(TRANSLATION_KEY.errorOnDeleteData));
      }
    } finally {
      setLoading(-1);
    }
  };

  return (
    <>
      <div>
        {/* Header */}
        <div className="spaceBetweenRow" style={{ marginBottom: 8 }}>
          <Typography.Title level={4}>{t(TRANSLATION_KEY.workRequests)}</Typography.Title>
          <div />
        </div>
        <div style={{ height: "calc(100vh - 164px)", overflowY: "auto" }}>
          <List
            loading={getWorkRequestsStatus === "loading"}
            dataSource={workRequests}
            itemLayout="vertical"
            rowKey={(item) => item.id}
            renderItem={(item) => (
              <Card
                onDeleteWorkRequest={removeWorkRequest}
                onGoAddMaintenance={(data: IAddMaintenance) => {
                  set_addMaintenance({
                    assetId: data.assetId,
                    description: data.description,
                    workRequestId: data.workRequestId,
                  });
                }}
                workRequests={item}
                onClose={onClose}
              />
            )}
          />
        </div>
      </div>

      <Drawer
        title={t(TRANSLATION_KEY.addOrder)}
        width={540}
        destroyOnClose={true}
        children={
          <MaintenanceForm
            asset={addMaintenance?.assetId || undefined}
            description={addMaintenance?.description}
            onClose={() => set_addMaintenance(undefined)}
            workRequestId={addMaintenance?.workRequestId || undefined}
            updateWorkRequests={() => {
              let tmp = [...workRequests];
              tmp = tmp.filter((item) => item.id !== addMaintenance?.workRequestId);
              dispatch(
                maintenanceSlice.actions.getWorkRequestsSuccess({
                  results: tmp,
                  message: "",
                }),
              );
            }}
          />
        }
        visible={!!addMaintenance}
        onClose={() => set_addMaintenance(undefined)}
      />
    </>
  );
};

export default WorkRequests;
