import React, { useEffect, useState } from "react";

// Antd
import {
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Table,
  TableColumnType,
  Tooltip,
  TreeSelect,
  Typography,
} from "antd";
import styles from "./styles.module.css";

// Rest
import { t } from "i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { IApiResponse, ICursor, ITableColumn } from "../../../models";
import { IPart, IPartDetails, IPartEntries, IPartEntry } from "../../../models/parts";
import {
  createTreeSelect,
  formatNumber,
  parseCosts,
  sortTableColumns,
} from "../../../helpers/functions";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  removePartEntryXHR,
  retrievePartXHR,
} from "../../../store/reducers/warehouse/actionCreator";
import { useDispatch } from "react-redux";
import { warehouseSlice } from "../../../store/reducers/warehouse";
import { useAppSelector } from "../../../hooks";
import AccountModalPreview from "../../account/components/AccountModalPreview";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import PreviewButton from "../../../components/PreviewButton";
import DatePickerCustom from "../../../components/DatePickerCustom";
import { ILocalFilters } from "../pages/EntryHistory";
import { getAssetXHR } from "../../../store/reducers/asstes/actionCreators";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  getFilterComponent,
  saveTableDefinition,
} from "../../../components/ViewTabs";
import { TableView } from "../../../models/user";
import Columns from "../../../components/ViewTabs/Columns";
import { store } from "../../../store";

// Constants
const STORAGE_COLUMNS_NAME = "MaintenancesTable";
const { RangePicker } = DatePicker;

// Props
interface IProps {
  data: IPartEntry[];
  loading: boolean;
  cursor: ICursor;
  filters: ILocalFilters;
  getPartEntries: (filters: ILocalFilters) => void;
  onCalendarChange: (start: string, end: string) => void;
  onLoadMore: () => void;
  onSearch: (search: string) => void;
}

type TableRenders = "PartPrice" | "PartQty" | "PartCreatedBy" | "PartLocation" | "PartAction";

const EntryHistoryTable: React.FC<IProps> = ({
  cursor,
  data,
  loading,
  filters,
  getPartEntries,
  onCalendarChange,
  onLoadMore,
  onSearch,
}) => {
  // Hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.userReducer);
  const { assetList, getAssetStatus } = useAppSelector((state) => state.assetReducer);
  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user.account.views?.entry?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );
  const { id } = useParams();

  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [firstMount, set_firstMount] = useState<boolean>(true);
  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<IPartEntry>>
  >([]);

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.entry?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);
  useEffect(() => {
    set_COLUMNS([...COLUMNS]);
  }, [data]);

  // Methods
  function handleRemovePartEntry(spend_part: number) {
    removePartEntryXHR(
      {
        id: spend_part,
        errorCallback: (data) => {
          console.log(data);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback: (res) => {
          let arr: IPartEntry[] = [...store.getState().warehouseReducer.partEntries.data];
          let index = arr.findIndex((item) => item.id === spend_part);

          arr.splice(index, 1);
          // Update store
          dispatch(warehouseSlice.actions.updatePartEntries(arr));
          retrievePartXHR({ id }, dispatch); // velika kentra sve update na frntu ovo niej frekventivno
        },
      },
      dispatch,
    );
  }

  useEffect(() => {
    let activeView = tableDefinition.find((x) => x.active);
    if (activeView) {
      setColumns(activeView.table_structure, 0);
    }
    getAssetXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  let warehouse = assetList.filter((x) => x.type === "wrh");

  warehouse = warehouse.map((x) => ({
    ...x,
    parent_id: x.parent_id
      ? warehouse.some((y) => y.id === x.parent_id)
        ? x.parent_id
        : null
      : null,
  }));

  let selectTreeData = createTreeSelect(
    warehouse.map((x) => ({
      title: x.name,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  const render = (key: TableRenders) => {
    let tmp: Record<TableRenders, any> = {
      PartPrice: (text: string, record: IPartEntry) => {
        return `${parseCosts(record.price)} ${record.price_uom} / ${parseCosts(
          record.base_price,
        )} ${record.base_currency}`;
      },
      PartQty: (text: string, value: IPartEntry) => (
        <Typography.Text>{formatNumber(value.qty)}</Typography.Text>
      ),
      PartLocation: (text: string, value: IPartEntry) => (
        <PreviewButton
          isActive={value.storage?.is_active}
          title={value.storage.name}
          id={value.storage.id}
          url={`/app/asset-details/${value.storage.id}`}
          permissionsRequired={["view_asset"]}
          onClick={() => {
            set_modalVisible(true);
            set_selectedAsset(value.storage.id);
          }}
        />
      ),
      PartCreatedBy: (text: string, value: IPartEntry) => (
        <>
          <PreviewButton
            isActive={value.created_by?.is_active}
            title={value.created_by.name}
            id={value.created_by.id}
            url={`/app/humanresources/${value.created_by.id}`}
            permissionsRequired={["manage_user"]}
            onClick={() => {
              set_modalVisible(true);
              set_selectedAccount(value.created_by.id);
            }}
          />
          <Typography.Text type="secondary">
            {moment(value.created_at).format(user.account.date_format || undefined)}
          </Typography.Text>
        </>
      ),
      PartAction: (text: string, value: IPartEntry) => (
        <Popconfirm
          onConfirm={() => handleRemovePartEntry(value.id)}
          title={t(TRANSLATION_KEY.continueWithAction)}
          cancelText={t(TRANSLATION_KEY.no)}
          okText={t(TRANSLATION_KEY.yes)}
          placement="top"
        >
          <Button type="link" danger>
            {t(TRANSLATION_KEY.remove)}
          </Button>
        </Popconfirm>
      ),
    };
    return tmp[key];
  };

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    // ostaje unutar komponente za koju se prave views-ovi iz razloga jer će trebati filtere okidati
    let tmp: Array<CustomTableColumn & TableColumnType<IPartEntry>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              "custom_fields_v2",
            ),
        onCell: (record: IPartEntry) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  console.log(COLUMNS, "COLUMNSs");

  return (
    <>
      <div>
        <ViewTabs
          hideDivider={true}
          onChange={(activeKey) => {
            let index = tableDefinition.findIndex((view) => view.name === activeKey);
            let tmpList = [...tableDefinition];
            if (index === -1) {
              message.error("view_onSaveColumnsDefinition");
              return;
            }
            tmpList = tmpList.map((x) => ({ ...x, active: false }));
            tmpList[index] = { ...tmpList[index], active: true };
            getPartEntries(filters);
            setColumns(tmpList[index].table_structure, index);
            set_tableDefinition(tmpList);
          }}
          setColumns={setColumns}
          views={tableDefinition}
          viewCategory="entry"
        />
      </div>
      {/* Header */}
      <div className={styles.headerContainer}>
        {/* Columns */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Columns
            columns={COLUMNS}
            set_COLUMNS={(columns) => {
              let index = tableDefinition.findIndex((view) => view.active === true);
              if (index === -1) {
                message.error("view");
                return;
              }
              let tmp = tableDefinition[index];
              if (!tmp) {
                return;
              }

              let inerTmp: CustomTableColumn[] = [];
              columns.forEach((x) => {
                inerTmp.push({
                  title: x.title || "",
                  dataIndex: x.dataIndex || "",
                  visible: x.visible === undefined ? 1 : x.visible,
                  onCellFlag: x.onCellFlag || false,
                  filterComponent: x.filterComponent || null,
                  columnRenderComponent: x.columnRenderComponent || null,
                  objectKey: x.objectKey || null,
                });
              });
              tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
              let tmpTableDefinition = [...tableDefinition].map((x) => ({ ...x, active: false }));
              tmpTableDefinition[index] = tmp;
              setColumns(inerTmp, index);
              set_tableDefinition(tmpTableDefinition);
            }}
          />

          <Button
            style={{ marginLeft: 18 }}
            type="link"
            onClick={() => {
              let activeView = tableDefinition.find((x) => x.active);
              saveTableDefinition(
                tableDefinition,
                user.account.views?.entry?.find((x) => x.id === activeView?.id),
                "entry",
              );
            }}
          >
            {t(TRANSLATION_KEY.saveChanges)}
          </Button>
        </div>

        <div className={styles.headerFlexSpaceBetween}>
          <div className={styles.headerFlexSpaceBetween}>
            {/* Date picker */}
            <DatePickerCustom
              wraperStyle={{ display: "flex" }}
              pickerStyle={{ marginRight: 12, minWidth: 270 }}
              selectStyle={{ marginRight: 12 }}
              onChange={onCalendarChange}
              type="month"
              end={moment().endOf("month").toISOString(true)}
              start={moment().startOf("month").toISOString(true)}
              skipInitial={false}
            />

            <TreeSelect
              onClear={() => {
                getPartEntries({ ...filters, warehouse: [] });
              }}
              onSelect={(a: unknown) => {
                let tmpArr = [...filters.warehouse];
                if (typeof a === "string") {
                  tmpArr.push(a);
                }

                getPartEntries({ ...filters, warehouse: tmpArr });
              }}
              onDeselect={(a: unknown) => {
                let tmpArr = [...filters.warehouse];
                let index = tmpArr.findIndex((x) => x === a);
                if (index !== -1) {
                  tmpArr.splice(index, 1);
                }
                getPartEntries({ ...filters, warehouse: tmpArr });
              }}
              multiple
              allowClear
              placeholder={t(TRANSLATION_KEY.selectWarehouse)}
              style={{ minWidth: 240, marginRight: 12 }}
              treeData={selectTreeData}
            />
          </div>
        </div>
      </div>

      {/* Table */}
      <Table
        loading={loading || getAssetStatus === "loading"}
        size="small"
        dataSource={data}
        columns={filterColumns<IPartEntry>(COLUMNS)}
        rowKey={(item: IPartEntry) => item.id}
        pagination={false}
      />

      {/* Load more button */}
      {cursor.next && <LoadMoreButton loading={loading} onClick={onLoadMore} />}

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedAccount(undefined);
          set_selectedAsset(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
      </Modal>
    </>
  );
};

export default EntryHistoryTable;
