import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Input,
  message,
  Modal,
  Spin,
  Typography,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FilesComponent, { MediaType } from "../../../components/Files";
import RequirePermission from "../../../components/RequirePermission";
import { MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../../../helpers/consts";
import {
  calculateTimeout,
  filterFilesByExtension,
  hasPermission,
} from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse } from "../../../models";
import { IMaintenanceDetails } from "../../../models/maintenances";
import { IFile } from "../../../models/user";
import api, { failedQueue, isRefreshing } from "../../../services";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { supplierSlice } from "../../../store/reducers/supplier";

type IProps = {
  isOpenOrder: boolean;
  maintenanceDetails: IMaintenanceDetails;
};

// Search data
export type Data = IFile & { search: string };
const mapData = (materials: IFile[]): Data[] => {
  return materials.map((x) => ({
    ...x,
    search: x.name.toLowerCase(),
  }));
};

const Materials: React.FC<IProps> = ({ isOpenOrder, maintenanceDetails }) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const open_order_token = useParams().token;

  // Variables
  const { user } = useAppSelector((state) => state.userReducer);
  const [search, set_search] = useState<string>("");
  const [filters, set_filters] = useState<MediaType[]>([]);
  const [loading, set_loading] = useState<boolean>(false);
  const [data, set_data] = useState<Data[]>([]);
  const [files, set_files] = useState<RcFile[]>([]);

  // Methods
  useEffect(() => {
    set_data(mapData(maintenanceDetails.files));
  }, [maintenanceDetails.files]);

  useEffect(() => {
    // If uploadTrigger is true, call onUpload function
    if (files.length > 0) {
      onUpload();
    }
  }, [files]);

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    return false;
  };

  // Upload function, called in useEffect by uploadTrigger
  const onUpload = async () => {
    // Reseting uploadTrigger
    set_loading(true);
    const token = await localStorage.getItem("token");
    const formData = new FormData();
    const code = await localStorage.getItem(open_order_token || "");

    files.forEach((element: RcFile) => {
      formData.append("files", element);
    });

    formData.append(
      "data",
      JSON.stringify(isOpenOrder ? { token: open_order_token, code } : { order_info: id }),
    );

    try {
      let timeout = calculateTimeout(files);
      console.log("🚧 - onUpload - timeout", timeout);
      let response = await api.post<IApiResponse<IFile>>(
        isOpenOrder ? `/maintenance/open_order_materials/` : `/maintenance/add_materials/`,
        formData,
        {
          timeout,
          headers: isOpenOrder ? undefined : { Authorization: "Bearer " + token },
        },
      );

      if (response.data.results) {
        let arr = [...maintenanceDetails.files];
        let final = arr.concat(response.data.results); // Merge two arrays

        const fakeRes: IApiResponse<IMaintenanceDetails> = {
          results: { ...maintenanceDetails, files: final },
          message: "",
        };

        if (isOpenOrder) {
          dispatch(supplierSlice.actions.getOpenOrderSuccess(fakeRes));
        } else {
          dispatch(maintenanceSlice.actions.getMaintenanceSuccess(fakeRes));
        }
        set_files([]);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onUpload());
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onDeleteFile = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    let code = await localStorage.getItem(open_order_token || "");
    if (!code) {
      code = "";
    }
    try {
      let response = await api.delete<IApiResponse<string>>(
        `/maintenance/${isOpenOrder ? "open_order_materials" : "materials"}/${id}/`,
        {
          data: isOpenOrder
            ? {
                token: open_order_token,
                code,
              }
            : undefined,
          headers: isOpenOrder ? undefined : { Authorization: "Bearer " + token },
        },
      );

      let arr: IFile[] = [...maintenanceDetails.files];

      let index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);
      const fakeRes: IApiResponse<IMaintenanceDetails> = {
        results: { ...maintenanceDetails, files: arr },
        message: "",
      };

      if (isOpenOrder) {
        dispatch(supplierSlice.actions.getOpenOrderSuccess(fakeRes));
      } else {
        dispatch(maintenanceSlice.actions.getMaintenanceSuccess(fakeRes));
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDeleteFile(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_loading(false);
  };

  // Checkboxes
  function onChange(checkedValues: any) {
    set_filters(checkedValues);
  }

  // Filters and search
  let filtredData: IFile[] = data;
  if (search) filtredData = data?.filter((x) => x.search.includes(search));
  if (filters.length) filtredData = filterFilesByExtension(filtredData, filters);

  return (
    <div style={{ paddingTop: 12, paddingBottom: 12 }}>
      {/* Header */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="spaceBetweenRow">
          <Typography.Title level={5}>{t(TRANSLATION_KEY.materials)}</Typography.Title>
          <RequirePermission
            anywayIgnorePermission={true}
            requiredPermissionCode={["manage_wo"]}
            children={
              <div className="spaceBetweenRow">
                <Upload
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept={MATERIAL_EXTENSIONS}
                  multiple
                >
                  <Button
                    loading={loading}
                    type="primary"
                    shape="circle"
                    icon={<UploadOutlined />}
                  />
                </Upload>
              </div>
            }
          />
        </div>
        <div style={{ display: "flex", justifyContent: "stretch" }}>
          <div style={{ flex: 1 }}>
            {/* <Checkbox.Group options={options} onChange={onChange} /> */}
          </div>
          {/* <Input.Search
            allowClear
            onSearch={(v) => set_search(v.toLowerCase())}
            style={{ marginLeft: 18, marginRight: 0, maxWidth: 200 }}
          /> */}
        </div>
      </div>

      <Divider style={{ marginTop: 18 }} />

      {/* Files */}
      <Spin spinning={loading}>
        {filtredData.length > 0 ? (
          <FilesComponent
            size={isOpenOrder ? "tiny" : "small"}
            layout="grid"
            files={data}
            onEdit={() => {}}
            hideEditButton
            hideMetaType
            // hideButtons={!isOpenOrder && !hasPermission(user.account.permissions, ["manage_wo"])}
            onDelete={onDeleteFile}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </div>
  );
};

export default Materials;
