import React, { useMemo, useState } from "react";
import { FileExclamationOutlined } from "@ant-design/icons";
import { Card, Drawer, Input, List, Statistic } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";
import PartItem from "./PartItem";
import { ICriticalQty } from "../../../models/parts";

interface IProps {}

const CriticalQty: React.FC<IProps> = ({}) => {
  // Variables
  const { criticalQty } = useAppSelector((state) => state.warehouseReducer);
  const [drawerVisible, set_drawerVisible] = useState(false);
  const [search, set_search] = useState<string>("");

  // Data
  let filteredData = useMemo(() => {
    if (search) {
      return criticalQty.filter(
        (item) =>
          item?.name.toLowerCase().includes(search) || item?.code.toLowerCase().includes(search),
      );
    }
    return criticalQty;
  }, [criticalQty, search]);

  return (
    <>
      <Card
        className="dashboard-card"
        onClick={() => set_drawerVisible(true)}
        style={{ width: "100%", minWidth: 240, cursor: "pointer" }}
      >
        <Statistic
          title={t(TRANSLATION_KEY.lowQtyItems)}
          value={criticalQty.length}
          prefix={<FileExclamationOutlined />}
        />
      </Card>

      <Drawer
        forceRender
        visible={drawerVisible}
        onClose={() => set_drawerVisible(false)}
        closable
        title={t(TRANSLATION_KEY.criticalQty)}
        width={700}
      >
        {/* {data} */}
        <List
          itemLayout="horizontal"
          dataSource={filteredData}
          header={
            <Input.Search
              placeholder={t(TRANSLATION_KEY.searchParts)}
              onChange={(e) => set_search(e.target.value.toLowerCase())}
              style={{ marginBottom: 10, marginTop: -12 }}
              allowClear
              enterButton
            />
          }
          renderItem={(item: ICriticalQty) => <PartItem item={item} />}
        />
      </Drawer>
    </>
  );
};

export default CriticalQty;
