import { Button, Empty, Modal, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { IApiResponse } from "../../models";
import { ITermsOfService } from "../../models/settings";
import { useAppSelector } from "../../hooks";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../helpers/consts";

const modalWidth: number = 800;

type Props = {
  visible: boolean;
  shouldScrollToAccept?: boolean;
  footerVisible?: boolean;
  title: string;
  onAccept?: () => void;
  onCancel?: () => void;
};

const TermsOfService = ({
  visible,
  shouldScrollToAccept = false,
  footerVisible = true,
  title,
  onAccept,
  onCancel,
}: Props) => {
  // Variables
  const [modalVisible, setModalVisible] = useState<boolean>(visible);
  const [termsOfService, setTermsOfService] = useState<ITermsOfService | undefined>(undefined);
  const [reachedBottom, setReachedBottom] = useState<boolean>(!shouldScrollToAccept);
  const { user } = useAppSelector((state) => state.userReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false);

  // Methods
  useEffect(() => {
    if (modalVisible) {
      fetchTermsOfService();
    }
  }, [modalVisible]);

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  async function fetchTermsOfService() {
    setLoading(true);
    let response = axios
      .get<IApiResponse<ITermsOfService>>(
        process.env.REACT_APP_HOST_BACKEND + "/api/settings/terms_of_use/",
      )
      .finally(() => setLoading(false));
    setTermsOfService((await response).data.results);
  }

  function handleScroll(e: any) {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setReachedBottom(true);
    }
  }

  async function handleOnAccept() {
    if (onAccept) {
      onAccept();
    } else {
      if (user && user.account) {
        setAcceptLoading(true);
        const token = await localStorage.getItem("token");
        axios
          .post<IApiResponse<any>>(
            `${process.env.REACT_APP_HOST_BACKEND}/api/accounts/accounts/${user.account.id}/accept_terms_of_use/`,
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            },
          )
          .finally(() => setAcceptLoading(false));
      }
    }
    setModalVisible(false);
  }

  function handleOnCancel() {
    if (onCancel) {
      onCancel();
    }
    setModalVisible(false);
  }

  return (
    <Modal
      title={title}
      visible={modalVisible}
      onCancel={handleOnCancel}
      width={modalWidth}
      bodyStyle={{ overflowY: "hidden", maxHeight: "90vh" }}
      centered
      footer={
        footerVisible
          ? [
              <Button
                key="submit"
                type="primary"
                disabled={!reachedBottom}
                loading={acceptLoading}
                onClick={handleOnAccept}
              >
                {t(TRANSLATION_KEY.accept)}
              </Button>,
            ]
          : null
      }
      destroyOnClose
    >
      <Spin spinning={loading}>
        <div style={{ position: "relative" }}>
          {/* Terms content */}
          <div
            style={{ height: "calc(90vh - 55px - 48px - 48px)", overflowY: "auto", zIndex: 2 }}
            onScroll={shouldScrollToAccept ? handleScroll : undefined}
          >
            <div
              style={{
                width: modalWidth - 52,
                height: "100%",
                backgroundColor: "#00000050",
                zIndex: 10000,
              }}
            >
              {termsOfService && termsOfService.url ? (
                <iframe
                  src={`${termsOfService?.url}#toolbar=0`}
                  width={modalWidth - 52}
                  height="98%"
                />
              ) : (
                <div style={{ paddingTop: 60 }}>
                  <Empty />
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default TermsOfService;
