import { Button, Divider, message, PageHeader, Popconfirm, Space, Typography } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import RentInfo from "./Components/RentInfo";
import {
  closeRentOrderXHR,
  deleteRemtOrderXHR,
  getRentOrderDetailsXHR,
} from "../../store/reducers/clients/actionCreators";
import Spec from "./Components/Spec";
import { clientsSlice } from "../../store/reducers/clients";

const RentOrder: React.FC = () => {
  const { rentDetails, closeRentOrderStatus } = useAppSelector((state) => state.clientsReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const [delLoading, set_delLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  console.log(rentDetails);
  useEffect(() => {
    getRentOrderDetailsXHR(
      {
        id,
        errorCallback: (error) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
  }, []);

  return (
    <>
      <div>
        <PageHeader
          style={{ padding: 0, paddingTop: 10 }}
          className="custom-page-header"
          onBack={() => navigate("/app/clients")}
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.rent)} </Typography.Text>
              <Typography.Text strong>#{rentDetails.custom_id}</Typography.Text>
            </div>
          }
          extra={[
            <div key="7">
              {rentDetails.status === "active" && (
                <Popconfirm
                  title={t(TRANSLATION_KEY.continueWithAction)}
                  onConfirm={() => {
                    const fd = new FormData();
                    fd.append("files", JSON.stringify([]));
                    fd.append("data", JSON.stringify({ description: "" }));
                    closeRentOrderXHR(
                      {
                        body: fd,
                        id: rentDetails.id,
                        errorCallback: () => {
                          message.error(t(TRANSLATION_KEY.errorOnSaveData));
                        },
                        successCallback: (data) => {},
                      },
                      dispatch,
                    );
                  }}
                >
                  <Button loading={closeRentOrderStatus === "loading"} type="primary">
                    {t(TRANSLATION_KEY.close)}
                  </Button>
                </Popconfirm>
              )}
            </div>,
            <div key="5">
              <Popconfirm
                title={t(TRANSLATION_KEY.continueWithAction)}
                onConfirm={() => {
                  set_delLoading(true);
                  deleteRemtOrderXHR(
                    {
                      id: rentDetails.id,
                      errorCallback: () => {
                        set_delLoading(false);
                        message.error(t(TRANSLATION_KEY.errorOnSaveData));
                      },
                      successCallback: (data) => {
                        navigate("/app/clients");
                      },
                    },
                    dispatch,
                  );
                }}
              >
                <Button loading={delLoading} type="primary" danger>
                  {t(TRANSLATION_KEY.delete)}
                </Button>
              </Popconfirm>
            </div>,
          ]}
        >
          <Space direction="vertical">
            <div className="spaceBetweenRow"></div>
            {/* Informations */}
            <RentInfo rentDetails={rentDetails} user={user} />
          </Space>
        </PageHeader>
      </div>
      <Divider />
      <Spec rentDetails={rentDetails} />
    </>
  );
};

export default RentOrder;
