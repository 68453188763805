import React, { useEffect, useState } from "react";

// UI
import { Button, Select, message } from "antd";
import { DefaultOptionType } from "antd/lib/select";

// Rest
import {
  IMaintenanceCategory,
  IMaintenanceType,
  MaintenanceStatusTypes,
} from "../models/maintenances";
import { BasicObject, ILocation } from "../models";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../helpers/consts";
import { ISupplier } from "../models/supplier";
import { IAccount } from "../models/user";
import {
  LOCAL_STORAGE_MAINTENNACE_FILTERS,
  createLocalStorageKey,
} from "../pages/maintenances/table";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getClientsXHR } from "../store/reducers/clients/actionCreators";
import ClientsSelect from "../componentsform/ClientsSelect";
import { DeleteOutlined } from "@ant-design/icons";
import { getChecklistsXHR } from "../store/reducers/settings/actionCreator";

export interface IMaintenanceFilters {
  types: number[];
  locations: number[];
  categories: number[];
  statuses: MaintenanceStatusTypes[];
  suppliers: number[];
  users: number[];
  clients: number[] | undefined;
  checklists: number[] | undefined;
}

interface IProps {
  types: IMaintenanceType[];
  locations: ILocation[];
  suppliers: ISupplier[];
  users: IAccount[];
  categories: IMaintenanceCategory[];
  statuses: MaintenanceStatusTypes[];
  onChange: (val: IMaintenanceFilters) => void;
  hasClients: boolean;
}

const MaintenanceFilters: React.FC<IProps> = ({
  types,
  locations,
  categories,
  statuses,
  onChange,
  users,
  suppliers,
  hasClients,
}) => {
  // Variables

  const { user } = useAppSelector((state) => state.userReducer);
  const { clients, getClientsStatus } = useAppSelector((state) => state.clientsReducer);
  const { checklists } = useAppSelector((state) => state.settingsReducer);
  const [filters, set_filters] = useState<IMaintenanceFilters>({
    types: [],
    locations: [],
    categories: [],
    statuses: [],
    suppliers: [],
    users: [],
    clients: [],
    checklists: [],
  });

  const dispatch = useAppDispatch();

  const setFromLocalStorage = async () => {
    const _filters: string | null = await localStorage.getItem(
      createLocalStorageKey(LOCAL_STORAGE_MAINTENNACE_FILTERS, user),
    );

    if (_filters) {
      let tmp: IMaintenanceFilters = JSON.parse(_filters);
      set_filters({
        types: tmp.types || [],
        locations: tmp.locations || [],
        categories: tmp.categories || [],
        statuses: tmp.statuses || [],
        suppliers: tmp.suppliers || [],
        users: tmp.users || [],
        clients: tmp.clients || [],
        checklists: tmp.checklists || [],
      });
    }
  };

  useEffect(() => {
    setFromLocalStorage();
    getChecklistsXHR({}, dispatch);
  }, []);

  // Methods
  const setFilters = (value: any, key: string) => {
    const _key = key as keyof typeof filters;
    const _filters: IMaintenanceFilters = { ...filters };
    _filters[_key] = value;
    onChange(_filters);
    set_filters(_filters);
  };

  // Content
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 20,
      }}
    >
      <Select
        onChange={(e) => setFilters(e, "types")}
        placeholder={t(TRANSLATION_KEY.selectType)}
        style={{ overflow: "auto", width: "calc(16% - 6px)" }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        value={filters.types}
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {types.map((item: IMaintenanceType, index: number) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) => setFilters(e, "categories")}
        placeholder={t(TRANSLATION_KEY.selectCategory)}
        value={filters.categories}
        style={{ overflow: "auto", width: "calc(16% - 6px)" }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {categories.map((item: IMaintenanceCategory, index: number) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) => setFilters(e, "checklists")}
        placeholder={t(TRANSLATION_KEY.selectChecklist)}
        value={filters.checklists}
        style={{ overflow: "auto", width: "calc(16% - 6px)" }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {checklists.map((item: BasicObject, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) => setFilters(e, "locations")}
        placeholder={t(TRANSLATION_KEY.selectLocation)}
        value={filters.locations}
        style={{ overflow: "auto", width: "calc(16% - 6px)", height: 34 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {locations.map((item: ILocation, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>

      <Select
        style={{ overflow: "auto", width: "calc(16% - 6px)", height: 34 }}
        allowClear
        placeholder={t(TRANSLATION_KEY.selectUser)}
        mode="multiple"
        showSearch
        value={filters.users}
        optionFilterProp="name"
        maxTagCount="responsive"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => setFilters(e, "users")}
      >
        {users.map((item: IAccount, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        style={{ overflow: "auto", width: "calc(16% - 6px)", height: 34 }}
        allowClear
        placeholder={t(TRANSLATION_KEY.selectSupplier)}
        mode="multiple"
        showSearch
        value={filters.suppliers}
        optionFilterProp="name"
        maxTagCount="responsive"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => setFilters(e, "suppliers")}
      >
        {suppliers.map((item: ISupplier, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        style={{ overflow: "auto", width: "calc(16% - 6px)", height: 34 }}
        allowClear
        placeholder={t(TRANSLATION_KEY.selectStatus)}
        mode="multiple"
        showSearch
        value={filters.statuses}
        optionFilterProp="name"
        maxTagCount="responsive"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => setFilters(e, "statuses")}
      >
        {statuses.map((item: MaintenanceStatusTypes, index: number) => (
          <Select.Option key={index} value={item}>
            {t(item)}
          </Select.Option>
        ))}
      </Select>
      {hasClients && (
        <ClientsSelect
          includeArchived={true}
          includeInactive={true}
          form={undefined}
          placeholder={t(TRANSLATION_KEY.clients)}
          label=""
          name=""
          mode="multiple"
          value={filters.clients}
          style={{ width: 240 }}
          onChange={(val) => {
            let tmp = val as number[];
            setFilters(tmp, "clients");
          }}
        />
      )}
      <Button
        style={{ marginLeft: 12 }}
        icon={<DeleteOutlined />}
        onClick={() => {
          set_filters({
            types: [],
            locations: [],
            categories: [],
            statuses: [],
            suppliers: [],
            users: [],
            clients: [],
            checklists: [],
          });
          onChange({
            types: [],
            locations: [],
            categories: [],
            statuses: [],
            suppliers: [],
            users: [],
            clients: [],
            checklists: [],
          });
        }}
      />
    </div>
  );
};

export default MaintenanceFilters;
