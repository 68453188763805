import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { useEffect } from "react";
import { getAccountXHR } from "../../../store/reducers/accounts/actionCreators";
import { useDispatch } from "react-redux";

// Antd
import { Descriptions, Button, Spin, PageHeader, Typography, Space, Result } from "antd";

// Translation
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { accountsSlice } from "../../../store/reducers/accounts";

interface IProps {
  id: string | number | undefined;
}

const AccountModalPreview = ({ id }: IProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAccountStatus, account } = useAppSelector((state) => state.accountsReducer);

  useEffect(() => {
    getAccountXHR({ id }, dispatch);
    return () => {
      dispatch(accountsSlice.actions.resetAccountStatus());
    };
  }, []);

  // Methods
  const validate = (value: string | null | undefined) => value || "-";
  const handleNavigate = () => {
    if (account?.is_active) {
      navigate(`/app/humanresources/${account?.id}`);
    } else {
      navigate(`/app/not-found`);
    }
  };

  if (account?.is_active === false || account === null || getAccountStatus === "error") {
    return <Result status="warning" title={t(TRANSLATION_KEY.dataNotFound)} />;
  }

  return (
    <Spin spinning={getAccountStatus === "loading"}>
      <div style={{ width: "100%", padding: 12 }}>
        {/* Page header */}
        <PageHeader
          style={{ padding: 0, marginBottom: 20 }}
          className="custom-page-header"
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.user)} </Typography.Text>
              <Typography.Text strong>#{account?.custom_id}</Typography.Text>
            </div>
          }
          extra={[
            <Button type="primary" onClick={handleNavigate}>
              {t(TRANSLATION_KEY.seeMore)}
            </Button>,
          ]}
        >
          <Space direction="vertical" style={{ width: "100%", marginTop: -40 }}>
            <Typography.Title level={3}>{account?.name}</Typography.Title>
            <Descriptions size="small" column={2}>
              <Descriptions.Item label={t(TRANSLATION_KEY.role)}>
                {validate(t(account?.role.name || ""))}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.email)}>
                {validate(account?.email)}
              </Descriptions.Item>
              <Descriptions.Item label={t(TRANSLATION_KEY.phoneNumber)}>
                {validate(account?.phone_number)}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </PageHeader>
      </div>
    </Spin>
  );
};

export default AccountModalPreview;
