import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Spin,
  Typography,
  Checkbox,
  Modal,
  Tree,
  Divider,
  Space,
  Tabs,
} from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { createCheckTreeSelect } from "../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IPermission, IRole } from "../../../../models/settings";
import api, { failedQueue, isRefreshing } from "../../../../services";
import { settingsSlice } from "../../../../store/reducers/settings";
import {
  createRoleXHR,
  getPermisionsXHR,
  getRolesXHR,
  updateRoleXHR,
} from "../../../../store/reducers/settings/actionCreator";

const Role: React.FC = () => {
  // State
  const { getRolesStatus, roles, permissions, createRoleStatus, updateRoleStatus } = useAppSelector(
    (state) => state.settingsReducer,
  );

  // Variables
  const dispatch = useAppDispatch();
  const [activeRole, set_activeRole] = useState<IRole | undefined>(roles[0]);
  const [selectedPermissions, set_selectedPermissions] = useState<string[]>([]);
  const [addUpdateRole, set_addUpdateRole] = useState<IRole>();
  const [roleName, set_roleName] = useState<string>("");
  const [cantUpdate, set_cantUpdate] = useState<boolean>(true);
  const [deleteLoading, set_deleteLoading] = useState<boolean>(false);

  useEffect(() => {
    getPermisionsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      set_selectedPermissions(activeRole?.permissions.map((x) => x.id.toString()) || []);
    }, []);
  }, [activeRole]);

  const deleteRole = async (id: number) => {
    set_deleteLoading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api.delete<string>(`settings/roles/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });
      let data = [...roles];
      let index = data.findIndex((x) => x.id === id);
      data.splice(index, 1);
      dispatch(settingsSlice.actions.getRolesSuccess({ message: "", results: data }));
      resetState();
      if (roles.length > 0) {
        set_activeRole(roles[0]);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => deleteRole(id));
        }
        return;
      }

      if (error?.response?.data?.message === "role_belongs_to_user") {
        message.error(t(error?.response?.data?.message));
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }

    set_deleteLoading(false);
  };

  const updateRole = (_roleName: string, newPermissions?: string[]) => {
    let _permissions = newPermissions || selectedPermissions;

    let notification_types: number[] = [];
    _permissions.forEach((x) => {
      let item = permissions.find((y) => y.id.toString() === x);
      item?.notification_types.forEach((z) => {
        notification_types.push(item?.id || 0);
      });
    });
    updateRoleXHR(
      {
        id: activeRole?.id.toString(),
        body: {
          rolename: _roleName,
          permissions: _permissions.map((x) => +x),
          notification_types,
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          unstable_batchedUpdates(() => {
            set_addUpdateRole(undefined);
            set_cantUpdate(true);
          }, []);
        },
      },
      dispatch,
    );
  };

  const resetState = () => {
    unstable_batchedUpdates(() => {
      set_addUpdateRole(undefined);
      set_selectedPermissions([]);
      set_cantUpdate(true);
    }, []);
  };

  const createRole = () => {
    let notification_types: number[] = [];
    selectedPermissions.forEach((x) => {
      let item = permissions.find((y) => y.id.toString() === x);
      item?.notification_types.forEach((z) => {
        notification_types.push(item?.id || 0);
      });
    });
    createRoleXHR(
      {
        id: activeRole?.id.toString(),
        body: {
          rolename: roleName,
          permissions: selectedPermissions.map((x) => +x),
          notification_types,
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          resetState();
        },
      },
      dispatch,
    );
  };

  const treeData = createCheckTreeSelect(
    permissions.map((x) => ({
      key: x.id.toString(),
      title: t(x.codename),
      parent_id: x.parent_id?.toString() || null,
      disabled: !activeRole || activeRole?.rolename === "Admin",
    })),
    null,
  );

  const onTabChange = (key: string) => {
    let role = roles.find((x) => x.id.toString() === key);
    set_activeRole(role);
  };

  return (
    <div style={{ paddingBottom: 20, position: "relative" }}>
      {/* Title */}
      <div className="spaceBetweenRow">
        {/* Title */}
        <Typography.Title level={5} style={{ paddingTop: 10 }}>
          {t(TRANSLATION_KEY.userRolesPermissions)}
        </Typography.Title>
        {/* CTA-s */}
        <Space size={16}>
          {/* Save button */}
          {/* <Button
            disabled={!activeRole || activeRole.static}
            onClick={() => updateRole(activeRole?.rolename || "")}
          >
            {t(TRANSLATION_KEY.save)}
          </Button> */}
          {/* Add role button */}
          <Button
            onClick={() => {
              set_addUpdateRole({
                id: 0,
                rolename: "",
                static: false,
                permissions: [],
                notificationTyps: [],
              });
              set_roleName("");
            }}
            shape="circle"
            icon={<PlusOutlined />}
            type="primary"
            style={{ marginLeft: 8 }}
          />
        </Space>
      </div>

      {/* Divider */}
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />

      <div
        className="spaceBetweenRow"
        style={{ alignItems: "flex-start", justifyContent: "flex-start", width: "100%" }}
      >
        {/* Tabs */}
        <RoleTabs
          data={roles}
          activeTab={activeRole?.id}
          onTabChange={onTabChange}
          onEdit={updateRole}
          onDelete={deleteRole}
        />

        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <Checkbox
                style={{ marginLeft: 29, userSelect: "none" }}
                disabled={!activeRole || activeRole.rolename === "Admin"}
                checked={selectedPermissions.length === permissions.length}
                onChange={(e) => {
                  let data: number[] = [];
                  permissions.forEach((x) => {
                    data.push(x.id);
                  });
                  unstable_batchedUpdates(() => {
                    if (selectedPermissions.length === permissions.length) {
                      set_selectedPermissions([]);
                      updateRole(activeRole?.rolename || "", []);
                      return;
                    }
                    set_selectedPermissions(data.map((x) => x.toString()));
                    updateRole(
                      activeRole?.rolename || "",
                      data.map((x) => x.toString()),
                    );
                    set_cantUpdate(false);
                  }, []);
                }}
              >
                {selectedPermissions.length === permissions.length
                  ? t(TRANSLATION_KEY.clearAll)
                  : t(TRANSLATION_KEY.all)}
              </Checkbox>
            </div>
          </div>
          <Divider style={{ marginTop: 8 }} />
          <div style={{ paddingLeft: 6 }}>
            <Tree
              style={{ width: "100%" }}
              expandedKeys={permissions.map((x) => x.id.toString())}
              checkStrictly={true}
              checkable
              selectable={false}
              checkedKeys={selectedPermissions}
              switcherIcon={<div />}
              onCheck={(value: any, i) => {
                if (Array.isArray(value.checked) && activeRole) {
                  //Trebam utvrditi koji item u listi je novi i ako je novi onda ide logika za dodavanje novog itema u listu u suprotnom ide logika za brisanje itema iz liste u toj logici se ne cupaju dijeca
                  if (value.checked.length < selectedPermissions.length) {
                    let checkedItem = findFirstMissingItem(
                      selectedPermissions,
                      value.checked.map((x) => x.toString()),
                    );
                    let selectedPerm = permissions.find((x) => x.id.toString() === checkedItem);
                    if (selectedPermissions.includes(selectedPerm?.parent_id?.toString() || "")) {
                      message.warning(
                        t(TRANSLATION_KEY.cantRemovePermissionBecaouseItContainsPare),
                      );
                      return;
                    }
                    //ovo je brisanje
                    updateRole(
                      activeRole?.rolename || "",
                      value.checked.map((x) => x.toString()),
                    );
                    set_selectedPermissions(value.checked.map((x) => x.toString()));
                  } else {
                    //ovo je dodavanje
                    let _permissions: string[] = [];
                    let missinItem = findFirstMissingItem(
                      value.checked.map((x) => x.toString()),
                      selectedPermissions,
                    );

                    console.log(missinItem);
                    if (!missinItem) {
                      return;
                    }
                    let _childPermissions = getAllChildren(
                      permissions,
                      missinItem ? +missinItem : null,
                    );

                    _permissions = mergeArraysWithoutDuplicates(
                      value.checked.map((x) => x.toString()),
                      _childPermissions.map((x) => x.id.toString()),
                    );

                    updateRole(activeRole?.rolename || "", _permissions);
                    set_selectedPermissions(_permissions);
                  }
                } else {
                  console.log("Error in data type Or activeRole [Component Tree]");
                }
              }}
              treeData={treeData}
            />
          </div>
        </div>
      </div>

      {/* Create Role Modal */}
      <Modal
        visible={!!addUpdateRole}
        footer={null}
        title={addUpdateRole?.id ? t(TRANSLATION_KEY.editUserRole) : t(TRANSLATION_KEY.newUserRole)}
        onCancel={() => set_addUpdateRole(undefined)}
      >
        <Spin spinning={createRoleStatus === "loading"}>
          <Input
            onChange={({ target: { value } }) => {
              set_roleName(value);
            }}
            value={roleName}
            style={{ width: "100%" }}
          />
          <Button
            onClick={createRole}
            type="primary"
            style={{ marginTop: 20 }}
            disabled={!roleName}
          >
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Spin>
      </Modal>
    </div>
  );
};

export default Role;

function getAllChildren(permissions: IPermission[], parentId: number | null): IPermission[] {
  const children: IPermission[] = [];

  for (const permission of permissions) {
    if (permission.parent_id === parentId) {
      const nestedChildren = getAllChildren(permissions, permission.id);
      children.push(permission, ...nestedChildren);
    }
  }

  return children;
}

function mergeArraysWithoutDuplicates(arr1: string[], arr2: string[]): string[] {
  const mergedArray = arr1.concat(arr2.filter((item) => !arr1.includes(item)));
  return mergedArray;
}

export function findFirstMissingItem(array1: string[], array2: string[]): string | undefined {
  for (const item of array1) {
    if (!array2.includes(item)) {
      return item;
    }
  }
  return undefined; // If no missing item is found
}

interface IRoleTabs {
  data: IRole[];
  activeTab: number | undefined;
  onTabChange: any;
  onEdit: any;
  onDelete: any;
}

export function RoleTabs({ data, activeTab, onTabChange, onEdit, onDelete }: IRoleTabs) {
  return (
    <>
      <Tabs
        className="role-tabs"
        tabPosition="left"
        defaultActiveKey={activeTab?.toString() || ""}
        onChange={onTabChange}
        // style={{ height: "100%", width: 240, minWidth: 240 }}
        // tabBarStyle={{ width: "480px !important", minWidth: "480px !important" }}
      >
        {data.map((x: IRole) => (
          <Tabs.TabPane
            key={x.id}
            tab={
              <Space style={{ height: 32, maxHeight: 32 }} align="center">
                {/* Edit */}
                <Typography.Text
                  editable={
                    !x.static
                      ? {
                          tooltip: t(TRANSLATION_KEY.editUserRole),
                          autoSize: { maxRows: 1 },
                          onChange: (text: string) => {
                            onEdit(text);
                          },
                        }
                      : false
                  }
                  style={{
                    fontSize: 14,
                    fontWeight: activeTab === x.id ? 600 : 400,
                    textAlign: "left",
                  }}
                  ellipsis={{
                    tooltip: t(x.rolename),
                  }}
                >
                  {t(x.rolename)}
                </Typography.Text>

                {/* Delete */}
                {!x.static && (
                  <Popconfirm
                    title={t(TRANSLATION_KEY.continueWithAction)}
                    onConfirm={() => onDelete(x.id)}
                    okText={t(TRANSLATION_KEY.yes)}
                    cancelText={t(TRANSLATION_KEY.no)}
                  >
                    <Button
                      size="small"
                      danger
                      shape="circle"
                      style={{ transform: "translateY(-4px) translateX(-8px)" }}
                      icon={<DeleteOutlined style={{ transform: "translateX(5px)" }} />}
                    />
                  </Popconfirm>
                )}
              </Space>
            }
          />
        ))}
      </Tabs>
    </>
  );
}
