import React from "react";
import { IWorkRequest } from "../../../models/maintenances";
import { Descriptions, DescriptionsProps } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import moment from "moment";
import { useAppSelector } from "../../../hooks";

type IProps = {
  workRequest: IWorkRequest & {
    request_panel: {
      name: string;
      id: number;
    };
  };
};

const WorkRequestDetails: React.FC<IProps> = ({ workRequest }) => {
  const { user } = useAppSelector((state) => state.userReducer);

  return (
    <div>
      <Descriptions bordered size={"small"} column={1}>
        <Descriptions.Item label={t(TRANSLATION_KEY.createdAt)}>
          {moment(workRequest.created_at).format(user.account.date_format || "LLL")}
        </Descriptions.Item>
        <Descriptions.Item label={t(TRANSLATION_KEY.createdBy)}>
          {workRequest.request_panel.name}
        </Descriptions.Item>
        <Descriptions.Item label={t(TRANSLATION_KEY.asset)}>
          {workRequest.asset?.name || t(TRANSLATION_KEY.withoutAsset)}
        </Descriptions.Item>
        <Descriptions.Item label={t(TRANSLATION_KEY.phoneNumber)}>
          {workRequest.phone_number}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default WorkRequestDetails;
