import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Select,
  Skeleton,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppSelector } from "../../hooks";
import { IUserNotification } from "../../models/user";
import { getRolesXHR } from "../../store/reducers/settings/actionCreator";
import { userSlice } from "../../store/reducers/user";
import {
  addCustomNotificationXHR,
  getNotificationsAllXHR,
  readAllNotificationsXHR,
  readNotificationXHR,
  unreadedNotificationsCountXHR,
} from "../../store/reducers/user/actionCreators";
import NotificationItem from "./Item";
import RequirePermission from "../RequirePermission";
import InfiniteScroll from "react-infinite-scroll-component";

type IProps = {
  onClose: () => void;
};

const LIMIT_SIZE = 30;

interface ILodadMoreParams {
  offset: number;
  limit: number;
}

const Notifications: React.FC<IProps> = ({ onClose }) => {
  // Hooks
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const { roles, getRolesStatus } = useAppSelector((state) => state.settingsReducer);
  const [loadMoreParams, setLoadMoreParams] = useState<ILodadMoreParams>({
    offset: 0,
    limit: LIMIT_SIZE,
  });
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { userNotifications, getNotificationsStatus, addCustomNotificationStatus } = useAppSelector(
    (state) => state.userReducer,
  );

  const onFinish = async (values: { text: string; roles: number[] }) => {
    addCustomNotificationXHR(
      {
        body: values,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: () => {
          form.resetFields();
          set_modalVisible(false);
        },
      },
      dispatch,
    );
  };

  const readNotifications = () => {
    readAllNotificationsXHR(
      {
        successCallback: () => {
          let tmpNot: IUserNotification[] = [];
          userNotifications.forEach((x) => {
            tmpNot.push({ ...x, seen: true });
          });
          dispatch(
            userSlice.actions.getNotificationsSuccess({
              results: tmpNot,
              message: "",
              mergeData: false,
            }),
          );
        },
      },
      dispatch,
    );
  };

  const getUserNotifications = (loadMoreParams: ILodadMoreParams, mergeData: boolean) => {
    getNotificationsAllXHR(
      {
        queryParams: {
          limit: loadMoreParams.limit,
          offset: loadMoreParams.offset,
        },
        mergeData,
        successCallback: (res) => {
          setLoadMoreParams({ ...loadMoreParams, offset: loadMoreParams.offset + LIMIT_SIZE });
          if (res.results && res.results?.length < LIMIT_SIZE) {
            setHasMore(false);
          }
        },
      },
      dispatch,
    );
  };

  useEffect(() => {
    getUserNotifications(loadMoreParams, false);
    return () => readNotifications();
  }, []);

  const onSelectAllRoles = () => {
    form.setFieldsValue({ roles: roles.map((item) => item.id) });
  };

  const callback = (key: string) => console.log(key);

  return (
    <div id="notifications">
      {/* Header */}
      <div className="spaceBetweenRow" style={{ marginBottom: 8 }}>
        <Typography.Title level={4}>{t(TRANSLATION_KEY.notifications)}</Typography.Title>
        <RequirePermission requiredPermissionCode={["send_custom_notification"]}>
          <Button
            onClick={() => {
              getRolesXHR(
                {
                  errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
                },
                dispatch,
              );
              set_modalVisible(true);
            }}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
          />
        </RequirePermission>
      </div>

      {/* Content */}
      <Tabs defaultActiveKey="1" onChange={callback}>
        {/* Locations */}
        <Tabs.TabPane tab={t(TRANSLATION_KEY.allNotifications)} key="all_notifications">
          <div id="scrollableDiv" style={{ height: "calc(100vh - 164px)", overflowY: "auto" }}>
            <InfiniteScroll
              dataLength={userNotifications.length}
              next={() => {
                getUserNotifications(loadMoreParams, true);
              }}
              hasMore={hasMore}
              loader={<Skeleton paragraph={{ rows: 1 }} active />}
              scrollableTarget="scrollableDiv"
            >
              <List
                loading={getNotificationsStatus === "loading"}
                dataSource={userNotifications}
                itemLayout="vertical"
                rowKey={(item) => item.notification.id}
                renderItem={(item) => <NotificationItem item={item} onClose={onClose} />}
              />
            </InfiniteScroll>
          </div>
        </Tabs.TabPane>
      </Tabs>

      {/* Add notification modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => set_modalVisible(false)}
        footer={null}
        centered
        width="600px"
        closable
        destroyOnClose
        title={t(TRANSLATION_KEY.addNotification)}
      >
        {getRolesStatus === "loading" ? (
          <Spin />
        ) : (
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="text"
              label={t(TRANSLATION_KEY.description)}
              rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item name="roles" label={t(TRANSLATION_KEY.roles)}>
              <Select
                mode="multiple"
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0", paddingTop: 0, marginTop: 0 }} />
                    <Button onClick={onSelectAllRoles} type="link" block>
                      {t(TRANSLATION_KEY.selectAll)}
                    </Button>
                  </>
                )}
              >
                {roles.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {t(item.rolename)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                loading={addCustomNotificationStatus === "loading"}
                htmlType="submit"
                type="primary"
                style={{ marginTop: 24, float: "right" }}
              >
                {t(TRANSLATION_KEY.save)}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default Notifications;
