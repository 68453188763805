import { Button, Divider, Popconfirm, Space, Table, TableColumnType, Tag } from "antd";
import React, { useMemo, useState } from "react";
import { CustomFieldV2, IRole } from "../../../../../models/settings";
import { useAppSelector } from "../../../../../hooks";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { FormModal } from "../../../../../componentsform/CustomFieldsFormV2";
import CustomFieldsTable from "../Components/CustomFieldsTable";

interface IProps {
  roles: IRole[];
  onEdit: (data: FormModal) => void;
}

const HumanResources: React.FC<IProps> = ({ roles, onEdit }) => {
  const [COLUMNS, set_COLUMNS] = useState<TableColumnType<CustomFieldV2>[]>([
    {
      title: t(TRANSLATION_KEY.name),
      dataIndex: "name",
    },
    {
      title: t(TRANSLATION_KEY.fieldType),
      dataIndex: "type",
      render: (text: string, value: CustomFieldV2) => {
        if (!value.data) {
          return t(text);
        }
        return (
          <Space>
            {t(text)}-{value.data?.join(", ")}
          </Space>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.accountRoles),
      dataIndex: "condition",
      render: (text: string, value: CustomFieldV2) => {
        if (!value.condition) {
          return t(TRANSLATION_KEY.all);
        }
        return (
          <Space>
            {roles
              ?.filter((x) => value.condition?.includes(x.id))
              .map((x) => x.rolename)
              .join(", ")}
          </Space>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.column_unique),
      render: (text: string, value: CustomFieldV2) => {
        return value.unique ? (
          <Tag color="green">{t(TRANSLATION_KEY.yes)}</Tag>
        ) : (
          <Tag color="red">{t(TRANSLATION_KEY.no)}</Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      render: (text: string, value: CustomFieldV2, index?: number) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                onEdit({ type: "account", customField: value });
              }}
            >
              {t(TRANSLATION_KEY.edit)}
            </Button>
            <Popconfirm
              onConfirm={() => onDelete(value.cf_id)}
              title={t(TRANSLATION_KEY.continueWithAction)}
            >
              <Button style={{ marginLeft: 9 }} danger type="link">
                {t(TRANSLATION_KEY.delete)}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);
  const onDelete = (key: string) => {
    alert(key);
  };

  return (
    <div>
      <CustomFieldsTable
        condition={roles.map((x) => ({
          id: x.id,
          name: x.rolename,
          trans_key: undefined,
        }))}
        onEdit={onEdit}
        formType="account"
        conditionTitle={t(TRANSLATION_KEY.accountRoles)}
        customFieldsData={companyCustomFieldsV2.account}
      />
    </div>
  );
};

export default HumanResources;
