import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Form, Input, message, Typography, Row, Col, Modal, Space } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useLocation, useNavigate } from "react-router-dom";
import { new_way_logoutXHR, setNewPasswordXHR } from "../../store/reducers/user/actionCreators";
import { isMobile } from "react-device-detect";
import { AnimatePresence, motion } from "framer-motion";

interface IPartFormFinal {
  password: string;
  confirm: string;
}

export interface ISetNewPassword {
  password: string;
  uidb64: string;
  token: string;
  login: boolean;
}

const SetNewPassword: React.FC = () => {
  // Hooks
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Variables
  const { setNewPasswordStatus } = useAppSelector((state) => state.userReducer);
  const searchParams = new URLSearchParams(search);

  // Methods
  const onFinish = (values: IPartFormFinal) => {
    const form: ISetNewPassword = {
      password: values.password,
      uidb64: searchParams.get("uidb64") || "",
      token: searchParams.get("token") || "",
      login: isMobile ? false : true,
    };
    setNewPasswordXHR(
      {
        body: form,
        errorCallback: (error: any) => message.error(t(TRANSLATION_KEY.loginError)),
        successCallback: () => {
          message.success(t(TRANSLATION_KEY.yourPasswordHasBeenSuccessfullyChanged));
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        },
      },
      dispatch,
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        minWidth: "100vw",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Row style={{ width: "100%", height: "100%", minHeight: "100vh" }}>
        <Col xs={0} lg={0}>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#001529",
            }}
          />
        </Col>
        <Col
          xs={24}
          lg={24}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "calc(100vw - 40px)" : 450,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: 64, height: 64, marginBottom: 12, marginTop: -32 }}
              src={require("../../assets/icons/auth_logo.png")}
            />
            <Typography.Title
              level={1}
              style={{ height: 20, color: "#434343", fontWeight: 500, textAlign: "center" }}
            >
              {t(TRANSLATION_KEY.newPassword)}
            </Typography.Title>

            <Typography.Title
              level={5}
              style={{
                paddingBottom: 24,
                color: "#434343aa",
                fontWeight: 300,
                textAlign: "center",
              }}
            >
              {t(TRANSLATION_KEY.enterNewPassword)}
            </Typography.Title>
            <Form style={{ width: "100%" }} form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: t(TRANSLATION_KEY.filedRequired),
                  },
                ]}
                hasFeedback
              >
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: t(TRANSLATION_KEY.pleaseConfirmPassword),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t(TRANSLATION_KEY.passwordAreNotMAtch)));
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Divider />

              <Form.Item>
                <Button
                  loading={setNewPasswordStatus === "loading"}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  {t(TRANSLATION_KEY.save)}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SetNewPassword;
