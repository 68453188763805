import { Button, Layout, Select } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import {
  I18N_LANGUAGES,
  LANGUAGES,
  Languages,
  MOMENT_LANGUAGES,
  TRANSLATION_KEY,
} from "../helpers/consts";
import i18next, { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { userSlice } from "../store/reducers/user";

const { Header } = Layout;

const AuthHeader: React.FC = ({}) => {
  const params = new URLSearchParams(window.location.search);
  const dispatch = useAppDispatch();
  const [localLang, set_localLang] = React.useState<string>(i18next.language as Languages);

  let lang: any = params.get("lang") as Languages;
  if (!lang) {
    let navigatiorLang =
      navigator.language.split("-")[0] === "ba" ? "bs" : navigator.language.split("-")[0];
    lang = i18next.language || navigatiorLang || "en";
  }

  useEffect(() => {
    if (lang) {
      dispatch(userSlice.actions.setLoadingLanguage(true));
      moment.locale(MOMENT_LANGUAGES[lang]);
      i18next
        .changeLanguage(I18N_LANGUAGES[lang] as Languages)
        .then(() => {
          dispatch(userSlice.actions.setLoadingLanguage(false));
        })
        .catch(() => {
          dispatch(userSlice.actions.setLoadingLanguage(false));
        });
      set_localLang(lang);
    }
  }, []);
  return (
    <Header className="auth-header">
      <Button shape="round" style={{ padding: 0 }} size="large">
        <Select
          bordered={false}
          showArrow={false}
          style={{ width: 120 }}
          placeholder={t(TRANSLATION_KEY.selectLanguage)}
          onSelect={(val: Languages) => {
            moment.locale(MOMENT_LANGUAGES[val]);
            i18next.changeLanguage(I18N_LANGUAGES[val]);
            set_localLang(val);
          }}
          value={localLang as Languages}
        >
          {LANGUAGES.map((item, index) => (
            <Select.Option key={index} value={item.alpha2Code}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Button>
    </Header>
  );
};

export default AuthHeader;
